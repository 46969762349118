import { useCallback } from 'react';
import {
  useModal,
  useNotificator,
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
} from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskEditController } from '../../../../../operations/controllers/task.edit.controller';
import { TASK_DELETION_MODAL } from '../../../../../operations/modals/DeletionModal/modal.config';
import { useTasksRouteActions } from '../../../../hooks';
import { ETaskStatus } from '../../../../../../../../api/models/as-fields/task/task.model';
import { useContextualLink } from '../../../../../../../shared/features/ContextualLink/hooks';
import { EContextualLinkId } from '../../../../../../constants/configs';

const useTaskHeaderSideCancel = (status: ETaskStatus, taskId: string) => {
  const { openModalByModalId } = useModal();
  const { deleteTask } = useStore(TaskEditController);
  const { setNotification } = useNotificator();
  const { goToTasks } = useTasksRouteActions();

  const contextualLink = useContextualLink();

  const isNew = status === ETaskStatus.New;

  const handleCancelClick = useCallback(() => {
    if (isNew) {
      openModalByModalId(TASK_DELETION_MODAL, {}, () => {
        deleteTask(taskId).then(() => {
          setNotification({
            message: 'Задача успешно удалена',
            style: {
              type: ENotificationType.Success,
              height: ENotificationHeight.BIG,
              placement: 'top-center',
              autoClose: 10000,
              theme: ENotificatorTheme.Dark,
            },
          });

          const link = contextualLink.getLink(EContextualLinkId.TasksTaskBackButton);

          if (link) contextualLink.onReturnClick(EContextualLinkId.TasksTaskBackButton);
          else goToTasks();
        });
      });
    } else {
      openModalByModalId('cancellationModal', {
        id: taskId,
        status: ETaskStatus.Canceled,
      });
    }
  }, [isNew, openModalByModalId, deleteTask, setNotification, goToTasks, taskId]);

  return { handleCancelClick };
};

export default useTaskHeaderSideCancel;
