import styled from 'styled-components';

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 12px;
`;

const StyledTaskOperationCreateCommonInfo = {
  Form,
};

export default StyledTaskOperationCreateCommonInfo;
