import * as turf from '@turf/turf';
import { Feature } from 'geojson';

import { IMapPolygonError } from '../../models';
import isBigAreaInHectares from '../helpers/isBigAreaInHectares';
import {
  hasSelfIntersectionError,
  isBigAreaError,
} from '../MapElements/polygons/BasePolygon/errors';
import { BasePolygon } from '../MapElements';

/**
 * Класс валидации для обычного полигона.
 * Содержит следующие валидации:
 * 1. Проверка на пересечения с другими полигонами
 * 2. Проверка площади полигона
 *
 * Класс модифицирует коллекцию ошибок всех затронутых полигонов в процессе валидации
 */
class PolygonValidator {
  private touchedPolygonsCollection: Map<number, BasePolygon> = new Map<number, BasePolygon>();
  public isCulturesOutOfZone = false;

  constructor(protected polygon: BasePolygon) {}

  // Возвращает список полигонов, которые были затронуты в процессе валидации.
  public getTouchedList(): BasePolygon[] {
    return Array.from(this.touchedPolygonsCollection.values());
  }

  public get errors() {
    return {
      get: this.polygon.errors.get,
      list: this.polygon.errors.list,
      has: this.polygon.errors.has,
    };
  }

  /**
   * Проверяет полигон из конструктора на пересечение с полигонами списка.
   * В процессе выполнения сетает/удаляет ошибки пересечения в {@link BasePolygon#errors коллекцию ошибок полигона}
   * @param polygonsList - список полигонов для проверки
   * @param skipIdsCollection - Коллекция id полигонов для пропуска валидации. В некоторых случая полезно для ускорения валидации
   */
  public checkIntersections(
    polygonsList: BasePolygon[],
    skipIdsCollection = new Set<number>()
  ): this {
    this.touchPolygon(this.polygon);

    const bufferedPolygon = this.bufferPolygon(this.polygon.toTurf());

    polygonsList.forEach(polygon => {
      if (skipIdsCollection.has(polygon.id)) {
        return;
      }

      if (polygon.id === this.polygon.id) {
        return;
      }

      const intersect = turf.intersect(bufferedPolygon, polygon.toTurf());
      const isIntersectBefore = polygon.errors.intersections.has(this.polygon.id);

      if (intersect && !isIntersectBefore) {
        this.polygon.errors.addIntersection(polygon);
        polygon.errors.addIntersection(this.polygon);

        this.touchPolygon(polygon);
      }

      if (!intersect && isIntersectBefore) {
        this.polygon.errors.removeIntersection(polygon.id);
        polygon.errors.removeIntersection(this.polygon.id);

        this.touchPolygon(polygon);
      }
    });

    return this;
  }

  /**
   * Проверяет что площадь полигона не выходит за границы константы.
   * В процессе выполнения сетает/удаляет ошибку в {@link BasePolygon#errors коллекцию ошибок полигона}
   */
  public checkIsAreaTooBig(maxArea?: number, error?: IMapPolygonError): this {
    const iBigArea = isBigAreaInHectares(this.polygon, maxArea);

    const err = error ?? isBigAreaError();
    const isAlreadyHasError = this.polygon.errors.has(err.type);

    if (iBigArea && !isAlreadyHasError) {
      this.polygon.errors.addError(err);
      this.touchPolygon(this.polygon);
    }

    if (!iBigArea && isAlreadyHasError) {
      this.polygon.errors.removeError(err.type);
      this.touchPolygon(this.polygon);
    }

    return this;
  }

  /**
   * Проверяет полигон из коструктора на самопересечение
   */
  public checkSelfIntersection(error?: IMapPolygonError): this {
    const kinks = turf.kinks(this.polygon.toTurf());

    const err = error ?? hasSelfIntersectionError();

    const isAlreadyHasError = this.polygon.errors.has(err.type);

    if (kinks.features.length > 0 && !isAlreadyHasError) {
      this.polygon.errors.addError(err);
      this.touchPolygon(this.polygon);
    }

    if (!kinks.features.length && isAlreadyHasError) {
      this.polygon.errors.removeError(err.type);
      this.touchPolygon(this.polygon);
    }

    return this;
  }

  private touchPolygon(polygon: BasePolygon) {
    this.touchedPolygonsCollection.set(polygon.id, polygon);
  }

  /**
   * Уменьшаем полигон на 1 см по всему периметру.
   * Это необходимо из-за особенностей работы функции "snap" у geoman.
   * Без этого уменьшения в половине случаев валидация не будет работать
   */
  private bufferPolygon(polygon: Feature) {
    return turf.buffer(polygon, -1, { units: 'centimeters' });
  }
}

export default PolygonValidator;
