import React, { FC } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './StoAttributeUserDictionaryRegistryItemEditIcon.styles';

interface IProps {
  dataTestId: string;
  onClick: () => void;
}

const StoAttributeUserDictionaryRegistryItemEditIcon: FC<IProps> = ({ dataTestId, onClick }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...getDataTestId()}
      onClick={onClick}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2526 4.469L19.5325 5.749C19.833 6.04918 20.0018 6.4564 20.002 6.88108C20.0021 7.30575 19.8335 7.71309 19.5334 8.01349L7.54447 20H4V16.4547L15.9881 4.469C16.2884 4.16871 16.6956 4 17.1203 4C17.545 4 17.9523 4.16871 18.2526 4.469ZM5.60596 18.3937H6.87952L15.3681 9.9067L14.0945 8.63232L5.60596 17.1193V18.3937ZM16.3903 8.88419L15.1167 7.61061L17.1202 5.60791L18.3938 6.88148L16.3903 8.88419ZM18.454 20.0001H9.20654L10.8126 18.394H18.454V20.0001Z"
        fill="#081A3E"
      />
    </Styled.Wrapper>
  );
};

StoAttributeUserDictionaryRegistryItemEditIcon.displayName =
  'StoAttributeUserDictionaryRegistryItemEditIcon';

export default StoAttributeUserDictionaryRegistryItemEditIcon;
