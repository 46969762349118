import styled from 'styled-components';
import { ESpaceSize } from '@farmlink/farmik-ui';

const Wrapper = styled('div')({
  pointerEvents: 'auto',
  display: 'flex',
  gap: ESpaceSize.S,
  overflow: 'hidden',
});

const Styles = {
  Wrapper,
};

export default Styles;
