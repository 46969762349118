import styled from 'styled-components';

interface IProps {
  $height?: number | string;
}

const Wrapper = styled('div')<IProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 16px',
    marginTop: '120px',
  },
  ({ $height }) => ({
    height: $height ?? 'auto',
  })
);

const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 12px;
`;

const Styles = {
  Wrapper,
  Description,
  Image,
  Title,
};

export default Styles;
