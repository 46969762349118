import { partial } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { OperationField } from '../../../../../api/endpoints/operations/operation.fields.get';
import { CultureList } from '../../../../../api/models/operations/operation.culture.list.model';
import { Operation } from '../../../../../api/models/operations/operation.model';
import { OperationType } from '../../../../../api/models/operations/operation.type.model';
import { TypeUser } from '../../../../../api/models/user.model';
import { provide } from '../../../../shared/utils/IoC';
import { selectAllOption } from '../../tasks/components/common/formFields/constants';
import { Field } from '../../../../../api/models/field.model';

import { PreparedTask, Task } from './tasks.store';

@provide.singleton()
export class TasksEditStore {
  cultureId = '';
  cultureList: CultureList;
  operations: Array<Operation> = [];
  selectedOperationId = '';
  operationFields: Array<OperationField> = [];
  selectedFieldId = '';
  usersList: TypeUser[] = [];
  assigneeId = '';
  task: Partial<Task> | PreparedTask = {};
  touchedFields = new Set<string>();

  presetDateFromTaskList: moment.Moment | null;

  culturesLoading = false;
  operationsLoading = false;
  fieldsLoading = false;

  private _selectedField: Field | null = null;

  private _collectionOfSelectedFieldId: Set<string> = new Set<string>();

  constructor() {
    makeAutoObservable(this);
  }

  get selectedField() {
    return this._selectedField;
  }

  get selectedFieldList(): string[] {
    return Array.from(this._collectionOfSelectedFieldId.values());
  }

  get preparedSelectedFieldList(): string[] {
    return Array.from(this._collectionOfSelectedFieldId.values()).filter(
      value => value !== selectAllOption.value
    );
  }

  setCultureList = (cultureList: CultureList): void => {
    this.cultureList = cultureList;
  };

  setOperationList = (operationList: Operation[]): void => {
    this.operations = operationList;
  };

  setSelectedField = (field: Field): void => {
    this._selectedField = field;
  };

  setSelectedFieldList = (fieldIdList: string[] = []): void => {
    const newCol: Set<string> = new Set<string>(fieldIdList);
    this._collectionOfSelectedFieldId = newCol;
  };

  clearSelectedField = (): void => {
    this._selectedField = null;
  };

  clearCollectionOfSelectedFieldId = (): void => {
    this._collectionOfSelectedFieldId.clear();
  };
}
