import { Colors, Icon } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { WeatherController, WeatherStore } from '../../../../mobx';

import Styled from './BackButton.styles';

const BackButton: FC = () => {
  // Сторы
  const store = useStore(WeatherStore);

  // Контроллеры
  const controller = useStore(WeatherController);

  const getDataTestId = useDataTestIdV2('weather');

  const isFixed = store.sliderScrollTop > 5;

  return (
    <Styled.Wrapper
      isFixed={isFixed}
      onClick={() => controller.close()}
      {...getDataTestId('back-button')}
    >
      <Icon icon={'mapList'} size={'medium'} fill={Colors.accentGreen} />
      {!isFixed && <Styled.Caption>На карту</Styled.Caption>}
    </Styled.Wrapper>
  );
};

export default observer(BackButton);
