import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

const warnBeforePhenophaseChangesModalConfig: TModalConfig = {
  name: 'warnBeforePhenophaseChanges',
  title: 'Если изменить фенофазу, внесенные ранее данные будут потеряны',
  type: EModalType.Warning_Before_Living,
  id: 'warnBeforePhenophaseChanges',
  dataTestId: 'warn-before-phenophase-changes',
  denyButton: {
    title: 'Не менять',
    color: 'default',
  },
  successButton: {
    title: 'Изменить',
    color: 'secondary',
  },
  styledProps: {
    $size: EModalSize.Medium,
  },
};

export default warnBeforePhenophaseChangesModalConfig;
