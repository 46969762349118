import { generatePath, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useMemo, useCallback } from 'react';

import { useStoParams } from '../useStoParams';
import EStoRoute from '../../routes/sto.routes';
import { IStoParams } from '../../models';
import { EStoTab } from '../../modules/Sto/models/StoCommon';
import { ApplicationRoutes } from '../../../../../routes';

interface INavigateOptions {
  isReplacePath?: boolean;
}

type TNavigateToChecklistsHandler = (
  params: Pick<IStoParams, 'stoId' | 'operationId'>,
  options?: INavigateOptions
) => void;

type TNavigateToChecklistHandler = (
  params: Pick<IStoParams, 'stoId' | 'operationId' | 'checklistId'>,
  options?: INavigateOptions
) => void;

type TNavigateToAttributeHandler = (
  params: Pick<IStoParams, 'stoId' | 'operationId' | 'checklistId' | 'stageId' | 'attributeId'>,
  options?: INavigateOptions
) => void;

interface INavigateOptions {
  query?: Record<string, string>;
  saveQuery?: boolean;
}

const useStoNavigation = () => {
  const { orgId } = useStoParams();
  const navigate = useNavigate();
  const location = useLocation();

  const basePath = `/${orgId}/sto`;

  const isSummary = Boolean(useMatch(`${basePath}${EStoRoute.StoItemSummary}`));
  const isFields = Boolean(useMatch(`${basePath}${EStoRoute.StoItemFieldList}`));
  const isRegistries = Boolean(useMatch(`${basePath}${EStoRoute.StoRegistryList}`));
  const isOperations = Boolean(useMatch(`${basePath}${EStoRoute.StoItemOperationList}`));
  const isHistory = Boolean(useMatch(`${basePath}${EStoRoute.StoItemHistory}`));

  const navigateToNotFound = () => {
    navigate(generatePath(ApplicationRoutes.PageNotFound));
  };

  const navigateToListing = () => {
    navigate(basePath, { replace: true });
  };

  const navigateToSto = (stoId: string, isReplacePath?: boolean, options?: INavigateOptions) => {
    navigate(
      { pathname: `${basePath}/${stoId}`, search: new URLSearchParams(options?.query).toString() },
      { replace: isReplacePath }
    );
  };

  const navigateToRegistries = (
    stoId: string,
    isReplacePath?: boolean,
    options?: INavigateOptions
  ) => {
    navigate(
      {
        pathname: `${basePath}/${stoId}/registries`,
        search: new URLSearchParams(options?.query).toString(),
      },
      { replace: isReplacePath }
    );
  };

  const navigateToSummary = (
    stoId: string,
    isReplacePath?: boolean,
    options?: INavigateOptions
  ) => {
    navigate(
      {
        pathname: `${basePath}/${stoId}/summary`,
        search: new URLSearchParams(options?.query).toString(),
      },
      { replace: isReplacePath }
    );
  };

  const navigateToFields = (stoId: string, isReplacePath?: boolean, options?: INavigateOptions) => {
    navigate(
      {
        pathname: `${basePath}/${stoId}/fields`,
        search: new URLSearchParams(options?.query).toString(),
      },
      { replace: isReplacePath }
    );
  };

  const navigateToOperations = (
    stoId: string,
    isReplacePath?: boolean,
    options?: INavigateOptions
  ) => {
    navigate(
      {
        pathname: `${basePath}/${stoId}/operations`,
        search: new URLSearchParams(options?.query).toString(),
      },
      { replace: isReplacePath }
    );
  };

  const navigateToOperation = (
    stoId: string,
    operationId: string,
    isReplacePath?: boolean,
    options?: INavigateOptions
  ) => {
    navigate(
      {
        pathname: `${basePath}/${stoId}/operations/${operationId}`,
        search: new URLSearchParams(options?.query).toString(),
      },
      { replace: isReplacePath }
    );
  };

  const navigateToChecklists: TNavigateToChecklistsHandler = ({ stoId, operationId }, options) => {
    navigate(
      {
        pathname: `${basePath}/${stoId}/operations/${operationId}/checklists`,
        search: new URLSearchParams(options?.query).toString(),
      },
      {
        replace: options?.isReplacePath,
      }
    );
  };

  const navigateToChecklist = useCallback<TNavigateToChecklistHandler>(
    ({ stoId, operationId, checklistId }, options) => {
      navigate(
        {
          pathname: `${basePath}/${stoId}/operations/${operationId}/checklists/${checklistId}`,
          search: new URLSearchParams(options?.query).toString(),
        },
        {
          replace: options?.isReplacePath,
        }
      );
    },
    [basePath]
  );

  const navigateToAttribute = useCallback<TNavigateToAttributeHandler>(
    (params, options) => {
      const path = generatePath(`${basePath}${EStoRoute.StoItemAttribute}`, params);

      navigate({
        pathname: path,
        search: new URLSearchParams(options?.query).toString(),
      });
    },
    [basePath]
  );

  const navigateToHistory = (
    stoId: string,
    isReplacePath?: boolean,
    options?: INavigateOptions
  ) => {
    navigate(
      {
        pathname: `${basePath}/${stoId}/history`,
        search: new URLSearchParams(options?.query).toString(),
      },
      { replace: isReplacePath }
    );
  };

  const navigateToURLByTab = (
    stoId: string,
    tab: EStoTab,
    isReplacePath?: boolean,
    options?: INavigateOptions
  ) => {
    // eslint-disable-next-line default-case
    switch (tab) {
      case EStoTab.Fields:
        navigateToFields(stoId, isReplacePath, { query: options?.query });
        break;
      case EStoTab.Summary:
        navigateToSummary(stoId, isReplacePath, { query: options?.query });
        break;
      case EStoTab.Registries:
        navigateToRegistries(stoId, isReplacePath, { query: options?.query });
        break;
      case EStoTab.Operations:
        navigateToOperations(stoId, isReplacePath, { query: options?.query });
        break;
      case EStoTab.History:
        navigateToHistory(stoId, isReplacePath, { query: options?.query });
        break;
    }
  };

  const isCreationPath = Boolean(useMatch(`${basePath}${EStoRoute.StoItemCreate}`));

  const currentTabFromURL = useMemo<EStoTab | null>(() => {
    if (isCreationPath) return EStoTab.Summary;
    if (isFields) return EStoTab.Fields;
    if (isRegistries) return EStoTab.Registries;
    if (isOperations) return EStoTab.Operations;
    if (isHistory) return EStoTab.History;

    return null;
  }, [location, isCreationPath]);

  return {
    navigateToNotFound,
    navigateToListing,
    navigateToSto,
    navigateToRegistries,
    navigateToOperations,
    navigateToOperation,
    navigateToChecklists,
    navigateToChecklist,
    navigateToAttribute,
    navigateToSummary,
    navigateToFields,
    navigateToHistory,
    isCreationPath,
    currentTabFromURL,
    navigateToURLByTab,
  };
};

export default useStoNavigation;
