const FieldSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M37.8285 15.2536C37.6472 13.1879 35.9174 11.6033 33.8438 11.6033H31.6025L30.9041 25.8016L33.3332 40.0001H35.6334C37.9806 40.0001 39.8233 37.9886 39.6181 35.6505L37.8285 15.2536Z"
      fill="#E7C994"
    />
    <path
      d="M25.8362 11.6033L24.6785 25.484L26.6667 40.0001H33.3333L31.6027 11.6033H25.8362Z"
      fill="#D19A75"
    />
    <path d="M20 11.6033L18.4363 25.1141L20 40.0001H26.6666L25.8361 11.6033H20Z" fill="#E7C994" />
    <path
      d="M14.164 11.6033L12.1851 25.8016L13.3336 40.0001H20.0001V11.6033H14.164Z"
      fill="#F3E5CB"
    />
    <path
      d="M8.39765 11.6033L6.0105 25.1141L6.66697 40.0001H13.3336L14.164 11.6033H8.39765Z"
      fill="#E7C994"
    />
    <path
      d="M6.15625 11.6031C4.08261 11.6031 2.3528 13.1877 2.17155 15.2534L0.38189 35.6503C0.176736 37.9884 2.01946 39.9999 4.36658 39.9999H6.66686L8.39754 11.6031H6.15625Z"
      fill="#F3E5CB"
    />
    <path
      d="M28.2043 3.39889C25.9387 1.13337 22.9693 0.000579834 20 0.000579834L18.4363 11.6031L20 28.0115L28.2043 19.8073C32.7353 15.2763 32.7353 7.92999 28.2043 3.39889Z"
      fill="#EB857E"
    />
    <path
      d="M11.7958 3.39889C7.26469 7.92999 7.26469 15.2763 11.7958 19.8073L20 28.0115V0.000579834C17.0306 0.000579834 14.0613 1.13337 11.7958 3.39889Z"
      fill="#F29993"
    />
    <path
      d="M27.7953 11.6033C27.7953 7.30502 24.2983 3.80812 20 3.80812L18.4363 11.6032L20 19.3985C24.2983 19.3985 27.7953 15.9015 27.7953 11.6033Z"
      fill="#EB6A61"
    />
    <path
      d="M20 3.80797C15.7017 3.80797 12.2048 7.30495 12.2048 11.6032C12.2048 15.9014 15.7017 19.3984 20 19.3984V3.80797Z"
      fill="#F9867E"
    />
    <path
      d="M25.4516 11.6033C25.4516 8.59727 23.006 6.15175 20 6.15175L18.4363 11.6032L20 17.0548C23.006 17.0548 25.4516 14.6092 25.4516 11.6033Z"
      fill="#EBEBF0"
    />
    <path
      d="M19.9999 6.15175C16.9939 6.15175 14.5483 8.59735 14.5483 11.6033C14.5483 14.6092 16.9939 17.0548 19.9999 17.0548V6.15175Z"
      fill="#FFF5F5"
    />
  </svg>
);

export default FieldSvg;
