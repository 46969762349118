import { PolylineOptions } from 'leaflet';
import { cloneDeep } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/IoC';
import { BasePolygon } from '../../../utils/MapElements';

@provide.singleton()
class MapPolygonStore {
  private _polygonsById: Map<number, BasePolygon> = new Map<number, BasePolygon>();

  private _selectedPolygon: BasePolygon | null = null;

  private _prevSelectedStyle: PolylineOptions;

  constructor() {
    makeAutoObservable(this);
  }

  get selectedPolygon() {
    return this._selectedPolygon;
  }

  set selectedPolygon(polygon: BasePolygon) {
    this._prevSelectedStyle = cloneDeep(polygon.options);
    this._selectedPolygon = polygon;
  }

  get polygonsList() {
    return [...this._polygonsById.values()];
  }

  get prevSelectedStyle() {
    return this._prevSelectedStyle;
  }

  get hasPolygons() {
    return Boolean(this._polygonsById.size);
  }

  getPolygon = (id: number): BasePolygon | undefined => {
    return this._polygonsById.get(id);
  };

  setPolygon = (polygon: BasePolygon): void => {
    this._polygonsById.set(polygon.id, polygon);
  };

  deletePolygon = (id: number): void => {
    this._polygonsById.delete(id);
  };

  clearSelectedPolygon = (): void => {
    this._selectedPolygon = null;
  };

  clearPolygons = (): void => {
    this._polygonsById.clear();
  };
}

export default MapPolygonStore;
