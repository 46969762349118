import React, { FC, memo, useCallback, useMemo } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { IStoChecklistsParamsTableChecklist } from '../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { EChecklistType } from '../../../../../../../../../../../../../../../../api/models/checklist/checklist.model';

import { ReactComponent as PointDefault } from './assets/icons/pointDefault.svg';
import { ReactComponent as PointDisabled } from './assets/icons/pointDisabled.svg';
import Styled from './StoChecklistsCard.styles';

interface IProps {
  id: string;
  name: string;
  type: IStoChecklistsParamsTableChecklist['type'];
  isActive: boolean;
  isSelected: boolean;
  onClick: (id: string) => void;
}

const StoChecklistsCard: FC<IProps> = ({ id, name, type, isActive, isSelected, onClick }) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>();

  const formattedType = useMemo(() => {
    switch (type) {
      case EChecklistType.Point:
        return 'Точка';

      case EChecklistType.Field:
        return 'Поле';

      case EChecklistType.Machinery:
        return 'Техника';

      default:
    }
  }, []);

  const handleClick = useCallback((): void => {
    onClick(id);
  }, [onClick]);

  return (
    <Styled.Wrapper onClick={handleClick} $isActive={isActive} $isSelected={isSelected}>
      <Styled.IconWrapper>{isSelected ? <PointDefault /> : <PointDisabled />}</Styled.IconWrapper>

      <Styled.Content>
        <AutoTooltip content={name} disabled={!isShowTooltip}>
          <Styled.Name ref={ref}>{name}</Styled.Name>
        </AutoTooltip>

        <Styled.Type>{formattedType}</Styled.Type>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

StoChecklistsCard.displayName = 'StoChecklistsCard';

export default memo(StoChecklistsCard);
