import { PolylineOptions } from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { IMapPolygonSelectOptions, IRemoveManyOptions } from '../../../models';
import { TPolygonGeometry } from '../../../models/PolygonElementConfig/PolygonElementConfig.model';
import { BasePolygon } from '../../../utils/MapElements';
import MapCoreService from '../../MapCore/services/MapCore.service';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapPolygonStore from '../stores/MapPolygon.store';

@provide.transient()
class MapPolygonService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapPolygonStore)
  private polygonStore: MapPolygonStore;

  @lazyInject(MapCoreService)
  private mapCoreService: MapCoreService;

  public create(geometry: TPolygonGeometry, style: PolylineOptions): BasePolygon | null {
    if (!geometry) {
      return null;
    }

    if (!this.coreStore.instance) {
      return null;
    }

    const polygon = new BasePolygon(geometry, style);
    polygon.addTo(this.coreStore.instance);

    this.polygonStore.setPolygon(polygon);

    return polygon;
  }

  public createNew(polygon: BasePolygon): BasePolygon | null {
    if (!polygon) {
      return null;
    }

    this.coreStore.instance.addLayer(polygon);

    this.polygonStore.setPolygon(polygon);

    return polygon;
  }

  /**
   * Выбор полигона. Состоит из 3 шагов
   * 1. Возвращение стандартных стилей предыдущему выбранному полигону
   * 2. Добавление полигона в переменную "selectedPolygon"
   * 3. Установка новых стилей для полигона
   */
  public select(polygon: BasePolygon, options: IMapPolygonSelectOptions): void {
    if (!polygon) {
      return;
    }

    if (polygon.id === this.polygonStore.selectedPolygon?.id) {
      return;
    }

    this.deselectSelected();

    this.polygonStore.selectedPolygon = polygon;

    polygon.setStyle(options.style);

    if (options?.closeTooltip) {
      polygon.closeTooltip();
    }

    if (!options?.skipCenter) {
      this.mapCoreService.centerMapOnBounds(polygon);
    }
  }

  public deselectSelected(): void {
    const { selectedPolygon, prevSelectedStyle } = this.polygonStore;
    if (!selectedPolygon) {
      return;
    }

    selectedPolygon.setStyle(prevSelectedStyle);

    const tooltip = selectedPolygon.getTooltip();
    if (tooltip && !tooltip.isOpen()) {
      selectedPolygon.openTooltip();
    }

    this.polygonStore.clearSelectedPolygon();
  }

  /**
   *  Удаляет полигон из карты (вместе с тултипами), а также со стора
   */
  public removeById(polyId: number): void {
    const polygonToDelete = this.polygonStore.getPolygon(polyId);
    if (!polygonToDelete) {
      return;
    }

    polygonToDelete.unbindTooltip();
    polygonToDelete.remove();

    polygonToDelete.errors.clearIntersectionLinks();

    this.polygonStore.deletePolygon(polyId);

    if (polyId === this.polygonStore.selectedPolygon?.id) {
      this.polygonStore.clearSelectedPolygon();
    }
  }

  public removeManyByIds(idList: number[], options?: IRemoveManyOptions): void {
    if (options?.isRemoveAll) {
      this.polygonStore.polygonsList.forEach(({ id }) => this.removeById(id));
      this.polygonStore.clearSelectedPolygon();
      return;
    }

    idList.forEach(id => this.removeById(id));
  }
}

export default MapPolygonService;
