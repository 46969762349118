import { FC, useState, useCallback, useRef } from 'react';
import { Icon, Accordeon } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import useOnclickOutside from 'react-cool-onclickoutside';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { WeatherStore } from '../../mobx';

import { ForecastDayPart } from './components/ForecastDayPart/ForecastDayPart';
import { ForecastWeekPart } from './components/ForecastWeekPart/ForecastWeekPart';
import {
  ForecastContent,
  ForecastContentWrapper,
  ForecastHeader,
  ForecastSelectWrapper,
  ForecastTitle,
  ForecastWrapper,
  ForecastLeftControl,
  ForecastRightControl,
  ForecastDayPartCarouselWrapper,
  ForecastDayPartCarouselWrapperLine,
  ForecastHeaderWrapper,
} from './style';

export type ForecastType = {
  title: string;
  hours: string;
  temperature: number;
  temperatureNight?: number;
  condition: string;
  conditionNight?: string;
  feelsLike: string;
  humidity: string;
  pressure?: string;
  pressureMm?: string | number;
  dew: string | number;
  changeOfRain: string | number;
  windSpeed: string;
  windGust: string;
  windDirection: string;
  precType: string;
  sunrise?: string;
  sunset?: string;
};

const getDayOfWeekByDate = (date: Date) => {
  const dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek)
    ? null
    : ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'][
        dayOfWeek
      ];
};

const getHoursByIndex = (hour: number) => {
  if (hour < 10) return `0${hour}:00`;
  else return `${hour}:00`;
};

const getDateString = (date: string) => {
  return date.split('T')[0].split('-').reverse().join('.');
};

const processDayParts = (todayHours = [], tomorrowHours = []) => {
  const currentMoment = new Date().getHours();
  if (currentMoment) {
    const slicedTodayHours = todayHours.slice(currentMoment);
    const slicedTomorrowHours = tomorrowHours.slice(0, currentMoment);

    return [...slicedTodayHours, ...slicedTomorrowHours];
  } else return todayHours;
};

export const Forecast: FC = observer(() => {
  const [selectedForecast, setSelectedForecast] = useState('day_forecast');
  const weatherStore = useStore(WeatherStore);

  const dayParts =
    weatherStore.fieldWeather?.forecasts[0]?.hours &&
    weatherStore.fieldWeatherForecasts &&
    weatherStore.fieldWeatherForecasts.length &&
    weatherStore.fieldWeatherForecasts[1]?.hours
      ? processDayParts(
          weatherStore.fieldWeather?.forecasts[0]?.hours,
          weatherStore.fieldWeatherForecasts[1]?.hours
        )
      : [];
  const scroll = useRef<HTMLDivElement>();
  const [blockedLeftArr, setBlockedLeftArr] = useState<boolean>(true);
  const [blockedRightArr, setBlockedRightArr] = useState<boolean>(false);

  const [isAccordeon, setIsAccordeon] = useState<boolean>(false);
  const accordeonWrapperRef = useOnclickOutside(() => {
    if (isAccordeon) setIsAccordeon(false);
  });

  const weekParts = weatherStore.fieldWeatherForecasts;

  const scrollHandle = e => {
    if (e.target.scrollLeft + e.target.clientWidth >= e.target.scrollWidth) {
      setBlockedLeftArr(false);
      setBlockedRightArr(true);
    } else if (e.target.scrollLeft === 0) {
      setBlockedLeftArr(true);
      setBlockedRightArr(false);
    } else if (
      e.target.scrollLeft > 0 &&
      e.target.scrollLeft + e.target.clientWidth < e.target.scrollWidth
    ) {
      setBlockedLeftArr(false);
      setBlockedRightArr(false);
    }
  };

  const clickHandle = useCallback((direction: 'left' | 'right') => {
    if (direction === 'left') {
      scroll.current.scrollLeft -= 250;
    } else {
      scroll.current.scrollLeft += 250;
    }
  }, []);

  return (
    <ForecastWrapper data-test-id="forecast-component">
      <ForecastHeaderWrapper data-test-id="forecast-component-header-wrapper">
        <ForecastHeader data-test-id="forecast-component-header">
          <ForecastTitle data-test-id="forecast-component-header-title">
            {selectedForecast === 'day_forecast' ? 'Подробный прогноз' : 'Прогноз на неделю'}
          </ForecastTitle>

          <ForecastSelectWrapper
            ref={accordeonWrapperRef}
            data-test-id="forecast-component-select-wrapper"
          >
            <Accordeon
              onOpen={() => setIsAccordeon(true)}
              onHover={() => 1}
              onChange={fc => {
                setSelectedForecast(fc);
                scroll.current.scrollLeft = 0;
              }}
              onMouseOut={() => 1}
              value={selectedForecast}
              options={[
                { label: 'День', value: 'day_forecast' },
                { label: 'Неделя', value: 'week_forecast' },
              ]}
              placeholder={''}
              isOpen={isAccordeon}
              dataTestId={'forecast-component-select-accordeon'}
            />
          </ForecastSelectWrapper>
        </ForecastHeader>
      </ForecastHeaderWrapper>

      <ForecastContentWrapper data-test-id="forecast-component-content-wrapper">
        <ForecastContent data-test-id="forecast-component-content">
          <>
            <ForecastLeftControl
              disabled={blockedLeftArr}
              onClick={() => clickHandle('left')}
              data-test-id="forecast-component-left-control"
            >
              <Icon
                icon={'arrow_left'}
                size={'small'}
                data-test-id="forecast-component-left-control-icon"
              />
            </ForecastLeftControl>
            <ForecastDayPartCarouselWrapper data-test-id="forecast-component-carousel-wrapper">
              <ForecastDayPartCarouselWrapperLine
                ref={scroll}
                onScroll={scrollHandle}
                data-test-id="forecast-component-carousel-wrapper-line"
              >
                {selectedForecast === 'day_forecast' &&
                  dayParts?.map((part, index) => (
                    <ForecastDayPart
                      key={part.hour}
                      windDirection={part.windDirection}
                      pressureMm={part.pressureMm}
                      windSpeed={part.windSpeed}
                      windGust={part.windGust}
                      humidity={part.humidity}
                      temperature={part.temp}
                      condition={part.condition}
                      dew={part.dew}
                      title={''}
                      changeOfRain={'35'}
                      feelsLike={part.feelsLike}
                      hours={getHoursByIndex(part.hour)}
                      precType={part.precType}
                      sunrise={part.forecasts && part.forecasts[0].sunrise}
                      sunset={part.forecasts && part.forecasts[0].sunset}
                      data-test-id="forecast-component-day-part"
                    />
                  ))}
                {selectedForecast === 'week_forecast' &&
                  weekParts?.map((part, index) => (
                    <ForecastWeekPart
                      key={part.date}
                      windDirection={part.parts.dayShort.windDirection}
                      pressureMm={part.parts.dayShort.pressureMm}
                      windSpeed={part.parts.dayShort.windSpeed}
                      windGust={part.parts.dayShort.windGust}
                      humidity={part.parts.dayShort.humidity}
                      temperature={part.parts.dayShort.temp}
                      temperatureNight={part.parts.nightShort.temp}
                      condition={part.parts.dayShort.condition}
                      conditionNight={part.parts.nightShort.condition}
                      dew={part.parts.day.dewPoint}
                      title={getDayOfWeekByDate(part.date)}
                      changeOfRain={'35'}
                      feelsLike={part.parts.dayShort.feelsLike}
                      hours={getDateString(part.date)}
                      precType={part.parts.dayShort.precType}
                      sunrise={part.sunrise}
                      sunset={part.sunset}
                      data-test-id="forecast-component-week-part"
                    />
                  ))}
              </ForecastDayPartCarouselWrapperLine>
            </ForecastDayPartCarouselWrapper>

            <ForecastRightControl
              disabled={blockedRightArr}
              onClick={() => clickHandle('right')}
              data-test-id="forecast-component-right-control"
            >
              <Icon
                icon={'arrow_right'}
                size={'small'}
                data-test-id="forecast-component-right-control-icon"
              />
            </ForecastRightControl>
          </>
        </ForecastContent>
      </ForecastContentWrapper>
    </ForecastWrapper>
  );
});
