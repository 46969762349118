import { forwardRef, useMemo } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import {
  ECheckListInstanceType,
  IGetChecklistInstanceByTaskId,
} from '../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { createInstanceCardId } from '../../utils/helpers';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

import completeDotSvg from './assets/images/complete-dot.svg';
import incompleteDotSvg from './assets/images/incomplete-dot.svg';
import { CardTitle, InstanceCoordType, PointImg, TextBlock, Wrapper } from './style';

const getCardTitle = (type: ECheckListInstanceType, positionNumber: number): string => {
  switch (type) {
    case ECheckListInstanceType.PlannedPoint:
      return `Точка ${positionNumber}`;
    case ECheckListInstanceType.ActualPoint:
      return `Точка ${positionNumber}`;
    case ECheckListInstanceType.Machinery:
      return `Техника ${positionNumber}`;
    case ECheckListInstanceType.Field:
      return `Поле ${positionNumber}`;
    default:
      return '';
  }
};

const getCoordsType = (instance: IGetChecklistInstanceByTaskId): string => {
  switch (instance.type) {
    case ECheckListInstanceType.PlannedPoint:
      return 'План';
    case ECheckListInstanceType.ActualPoint:
      return 'Факт';
    case ECheckListInstanceType.Machinery:
      return instance?.machineryName || '';
    case ECheckListInstanceType.Field:
      return '';
    default:
      return '';
  }
};

export interface InstanceCardProps {
  number: number;
  instance: IGetChecklistInstanceByTaskId;
  isSelected: boolean;
  onCardClick: (num) => void;
  disabled?: boolean;
}

export const InstanceCard = forwardRef<HTMLDivElement, InstanceCardProps>(
  ({ number, instance, isSelected, onCardClick, disabled }, ref) => {
    const getDataTestId = useDataTestIdV2('checklists__point-card');

    const { isShowTooltip, ref: tooltipRef } = useShowTooltip<HTMLDivElement>();

    const subTitle = useMemo(() => getCoordsType(instance), []);

    return (
      <Wrapper
        id={createInstanceCardId(instance.id)}
        disabled={disabled}
        selected={isSelected}
        onClick={() => !disabled && onCardClick(instance.id)}
        ref={ref}
        {...getDataTestId()}
      >
        {instance?.isComplete ? (
          <PointImg src={completeDotSvg} {...getDataTestId('complete-icon')} />
        ) : (
          <PointImg src={incompleteDotSvg} {...getDataTestId('incomplete-icon')} />
        )}

        <TextBlock {...getDataTestId('content')}>
          <CardTitle {...getDataTestId('title')}>{getCardTitle(instance.type, number)}</CardTitle>

          <AutoTooltip content={subTitle} disabled={!isShowTooltip} position="bottom">
            <InstanceCoordType ref={tooltipRef} {...getDataTestId('subtitle')}>
              {subTitle}
            </InstanceCoordType>
          </AutoTooltip>
        </TextBlock>
      </Wrapper>
    );
  }
);
