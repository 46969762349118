import turfDifference from '@turf/difference';

import { CultureZone } from '../../../../../../../../api/models/field.model';
import { toTurfPolygon } from '../../../../../../../shared/features/map/utils/helpers';

/**
 * Возвращает список культурных зон которые полностью перекрыты зоной из первого аргумента
 */
function getOverlappedCultureZones(createdZone: CultureZone, cultureZonesList: CultureZone[]) {
  const turfCreatedZone = toTurfPolygon(createdZone.geometry);

  return cultureZonesList.filter(zone => {
    if (zone.id === createdZone.id) {
      return false;
    }

    return turfDifference(toTurfPolygon(zone.geometry), turfCreatedZone) === null;
  });
}

export default getOverlappedCultureZones;
