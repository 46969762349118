import styled, { css } from 'styled-components';

const Status = styled.div`
  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  /* body: reg14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
`;

const Wrapper = styled.div<{ $isSelected: boolean; $isDisabled: boolean }>`
  display: flex;
  justify-content: space-between;

  align-items: center;
  gap: 8px;

  padding: 8px 12px 8px 16px;
  border-radius: 12px;

  pointer-events: all !important;

  ${({ $isSelected, $isDisabled }) =>
    $isSelected
      ? css`
          background-color: ${$isDisabled ? '#ecf8ef66' : '#ecf8ef'};
        `
      : css`
          background-color: #faf9fd;
        `};
`;

const StyledStoChecklistsChecklistSwitcher = {
  Status,
  Wrapper,
};

export default StyledStoChecklistsChecklistSwitcher;
