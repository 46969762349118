import { lazyInject, provide } from '../../../../../../../../../../shared/utils/IoC';
import { StoService } from '../../../../../../../../../../shared/mobx/services/as-fields';
import { StoStore } from '../../../../../mobx';
import { IChecklistsByTechOperationType } from '../../../../../../../../../../../api/models/as-fields/new/sto/ChecklistsByTechOperationType';
import { IStoOperationsListItem as IItem } from '../../../models';
import { StoOperationsCoreStore } from '../../stores';
import { IUpdateStoOperation } from '../../../../../../../../../../../api/models/as-fields/STO/STO.model';

@provide.transient()
class StoOperationsItemsService {
  @lazyInject(StoStore)
  protected stoStore: StoStore;

  @lazyInject(StoOperationsCoreStore)
  protected stoOperationsCoreStore: StoOperationsCoreStore;

  @lazyInject(StoService)
  protected stoService: StoService;

  public addItems = async (versionId?: string): Promise<void> => {
    const { setIsLoading, setItemList } = this.stoOperationsCoreStore;

    const checklistsByTechOperationType = await this.fetchChecklistsByTechOperationType(versionId);

    const itemList = this.createItemList(checklistsByTechOperationType);
    setItemList(itemList);

    this.addSelectedItemList(itemList);

    setIsLoading(false);
  };

  public allowAvailAbleSettingsManagement = (): void => {
    const { setItemList } = this.stoOperationsCoreStore;

    setItemList(prevItemList => {
      return prevItemList.map(item => {
        return {
          ...item,
          isSettingsManagementAllowed: item.isSelected,
        };
      });
    });
  };

  protected addSelectedItemList = (itemList: IItem[]): void => {
    const { setUpdatedSto } = this.stoStore;

    const selectedStoList = itemList.reduce<IUpdateStoOperation[]>((list, item) => {
      if (item.isSelected) {
        list.push({ techOperationTypeId: item.techOperationTypeId });
      }

      return list;
    }, []);

    setUpdatedSto(prevValue => ({
      ...prevValue,
      stoOperations: selectedStoList,
    }));
  };

  protected fetchChecklistsByTechOperationType = async (
    versionId: string
  ): Promise<IChecklistsByTechOperationType[]> => {
    const { selectedSto } = this.stoStore;
    const { getChecklistsByTechOperationType } = this.stoService;

    const checklistsByTechOperationType = await getChecklistsByTechOperationType({
      id: selectedSto.id,
      ...(versionId && { versionId }),
    });

    if (!checklistsByTechOperationType) {
      return [];
    }

    return checklistsByTechOperationType;
  };

  protected createItemList = (dataList: IChecklistsByTechOperationType[]): IItem[] => {
    const lastElemIndex = dataList.length - 1;

    return dataList.map((data, i) => this.createItem(data, i === lastElemIndex));
  };

  protected createItem = (
    { techOperationType, checklists, isSelected }: IChecklistsByTechOperationType,
    isLast: boolean
  ): IItem => {
    return {
      techOperationTypeId: techOperationType.id,
      techOperationTypeName: techOperationType.name,
      checklistCountLabel: `Чек-листы: ${checklists.length}`,
      isSelected,
      isSettingsManagementAllowed: isSelected,
      isLast,
      isViewOnly: this.stoStore?.isViewOnly,
    };
  };
}

export default StoOperationsItemsService;
