import { FC, memo } from 'react';

import userSvg from '../../../../../../../../operations/components/TaskView/assets/userSvg.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  assigneeName: string;
  dataTestId: string;
}
const TaskOperationCreateVerificationAssigneeName: FC<IProps> = ({ assigneeName, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__assignee-name`);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label color={'generalDarkHover'} {...getDataTestId('label')}>
        Исполнитель
      </Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={userSvg} />

      <Styled.Info {...getDataTestId('info')} data-test-id={'task-assignee'}>
        {assigneeName}
      </Styled.Info>
    </Styled.InfoBlock>
  );
};

TaskOperationCreateVerificationAssigneeName.displayName =
  'TaskOperationCreateVerificationAssigneeName';

export default memo(TaskOperationCreateVerificationAssigneeName);
