import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OperationsStore } from '../stores/operations.store';
import { taskLabels, TasksStore, TaskStatuses } from '../stores/tasks.store';

@provide.singleton()
export class TasksListingController {
  @lazyInject(OperationsStore)
  operationsStore: OperationsStore;

  @lazyInject(TasksStore)
  tasksStore: TasksStore;

  getDateToTasks = (): any => {
    const result = {};

    [...this.tasksStore.tasksMap]
      .map(([key, item]) => item)
      .slice()
      .forEach(task => {
        const preparedTask = {
          ...task,
          statusLabel: this.getStatusLabel(task.status),
          cultureName: task?.culture?.name || 'Культура не указана',
        };

        if (result[task.bindingDate as string]) {
          result[task.bindingDate as string].push({
            ...preparedTask,
          });
        } else {
          result[task.bindingDate as string] = [preparedTask];
        }
      });

    return result;
  };

  getStatusLabel(status: TaskStatuses): string {
    return taskLabels[status] || 'нет данных';
  }
}
