import { useContext, useEffect } from 'react';
import { History } from 'history';
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext, useLocation } from 'react-router-dom';

type ExtendedNavigator = Navigator & Pick<History, 'block'>;

const useBlocker = (
  blocker: (tx: any) => void,
  isBlockActivated: boolean,
  whiteList: string[]
): void => {
  const { navigator } = useContext(NavigationContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isBlockActivated) {
      return;
    }

    const unblock = (navigator as ExtendedNavigator).block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      if (whiteList.some(path => (tx.location.pathname as string).includes(path))) {
        autoUnblockingTx.retry();
      } else {
        blocker(autoUnblockingTx);
      }
    });

    return () => {
      unblock();
    };
  }, [navigator, blocker, isBlockActivated, pathname, whiteList]);
};

export default useBlocker;
