import React, { useState } from 'react';
import { Button, ButtonLink } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../shared/utils/IoC';
import { OperationsStore } from '../../stores/operations.store';
import { NoOperations } from '../NoOperations';
import { CreateOperationModal } from '../../modals/CreateOperationModal';
import { EditOperationModal } from '../../modals/EditOperationModal';
import { OperationItem } from '../OperationItem';
import { TasksStore } from '../../stores/tasks.store';
import { TasksController } from '../../controllers/tasks.controller';
import { ContentLoader } from '../../../../../shared/features/UI/loaders/ContentLoader';
import { useOperationsParams } from '../../hooks';
import { useOperationsParamsActions } from '../../hooks/useOperationsParamsActions';

import {
  Wrapper,
  Header,
  Body,
  Footer,
  OperationsListWrapper,
  Spinner,
  SpinnerWrapper,
} from './style';

export const OperationsList = observer(() => {
  const store = useStore(OperationsStore);
  const tasksStore = useStore(TasksStore);
  const tasksController = useStore(TasksController);

  const params = useOperationsParams();
  const paramsActions = useOperationsParamsActions();

  const [isOperationCreationModalOpen, setIsOperationCreationModalOpen] = useState<boolean>(false);
  const [isOperationEditModalId, setIsOperationEditModalId] = useState<string>('');

  let lastInterval;

  const handleOperationClick = async (operationId: string) => {
    if (params.operationId === operationId) paramsActions.selectOperation(null);
    else paramsActions.selectOperation(operationId);

    store.resetOperationsFieldsList();
    store.fetchOperationsFieldsList(operationId);
    tasksController.clear();
    tasksController.resetPageSettings();

    await tasksController.fetchTasks({
      organizationId: params.orgId,
      seassonCultureId: params.cultureId,
      operationId: params.operationId === operationId ? undefined : operationId,
      fieldId: '',
      noCulture: params.cultureId === 'emptyCulture' ? true : undefined,
    });
  };

  const loadAdditionalOperations = async () => {
    await store.getOperations(params.cultureId, true);
  };

  const updateScrollRef = ref => {
    if (lastInterval) {
      clearInterval(lastInterval);
    }
    lastInterval = setInterval(() => {
      if (
        ref &&
        store.operationPageNumber < store.operationTotalPages &&
        window.innerHeight - ref.getBoundingClientRect().top > 50
      ) {
        loadAdditionalOperations();
      }
    }, 500);
  };

  return (
    <Wrapper data-test-id={'operation-module-wrapper'}>
      <Header data-test-id={'operation-module-header'}>
        <span data-test-id={'operation-module-header-label'}>
          Операции ({store.totalOperationsNumber})
        </span>

        {store.OperationsList.length ? (
          <ButtonLink
            color={'accent'}
            dataTestId={'operation-module-header-add-operation'}
            onClick={() => {
              setIsOperationCreationModalOpen(true);
            }}
          >
            Новая операция
          </ButtonLink>
        ) : null}
      </Header>

      {store.isLoading ? (
        <ContentLoader
          overlayStyles={{
            position: 'relative',
            height: '100%',
            width: '100%',
          }}
        />
      ) : (
        <Body data-test-id={'operation-module-body'}>
          {store.OperationsList.length ? (
            <OperationsListWrapper data-test-id={'operation-module-body-operations-list-wrapper'}>
              {store.OperationsList.map(operation => (
                <OperationItem
                  onClick={handleOperationClick.bind(this, operation.id)}
                  operation={operation}
                  isActive={params.operationId === operation.id}
                  onMenuClick={() => setIsOperationEditModalId(operation.id)}
                  isDisabled={tasksStore.loading}
                />
              ))}

              {store.operationPageNumber < store.operationTotalPages ? (
                <SpinnerWrapper>
                  <Spinner ref={ref => updateScrollRef(ref)} />
                </SpinnerWrapper>
              ) : null}
            </OperationsListWrapper>
          ) : (
            <NoOperations />
          )}

          {isOperationCreationModalOpen && (
            <CreateOperationModal
              closeModal={() => {
                setIsOperationCreationModalOpen(false);
              }}
            />
          )}

          {Boolean(isOperationEditModalId) && (
            <EditOperationModal
              closeModal={() => {
                setIsOperationEditModalId('');
              }}
              operationId={isOperationEditModalId}
            />
          )}
        </Body>
      )}

      {!store.OperationsList.length && (
        <Footer data-test-id={'operation-module-footer'}>
          <Button
            color={'primary'}
            type={'button'}
            className={'add-operations-button'}
            onClick={() => {
              setIsOperationCreationModalOpen(true);
            }}
            dataTestId={'operation-module-footer-add-button'}
          >
            Добавить операцию
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
});
