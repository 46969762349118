import { makeAutoObservable } from 'mobx';
import { RefObject } from 'react';

import { ETaskAction } from '../../../../../api/models/as-fields/task/task.model';
import { CultureModel } from '../../../../../api/models/culture.model';
import { IRemoteDictionary } from '../../../../../api/models/da-dictionary';
import { provide } from '../../../../shared/utils/IoC';

export enum TaskStatuses {
  New = 'NEW',
  InWork = 'IN_WORK',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Impossible = 'IMPOSSIBLE',
}

export const StatusLinks = {
  [TaskStatuses.New]: [TaskStatuses.InWork, TaskStatuses.Canceled],
  [TaskStatuses.InWork]: [TaskStatuses.Completed, TaskStatuses.Canceled],
  [TaskStatuses.Completed]: [TaskStatuses.InWork],
};

export enum TaskPrioretees {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
}

export enum TaskFormMode {
  None = 'None',
  View = 'View',
  Edit = 'Edit',
  Create = 'Create',
}

export const taskLabels = {
  [TaskStatuses.New]: 'Новая',
  [TaskStatuses.InWork]: 'В работе',
  [TaskStatuses.Completed]: 'Завершена',
  [TaskStatuses.Canceled]: 'Отменена',
  [TaskStatuses.Impossible]: 'Невыполнима',
};

export const taskPrioreteeLabels = {
  [TaskPrioretees.Low]: 'Низкий',
  [TaskPrioretees.Medium]: 'Средний',
  [TaskPrioretees.High]: 'Высокий',
};

export interface Task {
  id: string;
  operationId: string;
  status: TaskStatuses;
  availableStatuses: TaskStatuses[];
  isExpired: boolean;
  daysLeft?: number;
  cancellationReason?: string;
  canceledDate: moment.Moment | string | null;
  priority: TaskPrioretees;
  culture?: CultureModel;
  assignee?: {
    id: string;
    fullName: string;
    fnameInitials: string;
  };
  operationInfo: {
    cultureId: string;
    endDate: string;
    id: string;
    name: string;
    operationTypeId: string;
    operationTypeInfo?: IRemoteDictionary;
    seasonYear: number;
    startDate: string;
  };
  createdBy?: {
    fullName: string;
    id: string;
  };
  assigneeId: string;
  planStartDate: moment.Moment | string | null;
  planEndDate: moment.Moment | string | null;
  bindingDate: moment.Moment | string | null;
  executedDate: moment.Moment | string | null;
  comment: string;
  field: any;
  fieldId: string;
  cultureZoneId: string;
  deletable?: boolean;
  intensityRequired?: boolean;
  checklistsAvailable?: boolean;
  checklistsMachineryAvailable?: boolean;
  availableActions?: ETaskAction[];
}

export interface PreparedTask extends Task {
  operationTypeName: string;
  operationTypeId: string;
  fieldName: string;
  statusLabel: string;
  cultureName: string;
  type?: string;
}

@provide.singleton()
export class TasksStore {
  tasksMap: Map<string, Task> = new Map([]);
  selectedTaskId = '';
  selectedTask: Partial<Task> | null = {};
  pageNumber = 0;
  totalPages = 0;
  loading = false;
  tasksLoadedByFilter = false;
  tasksLoaded = false;
  isViewMode = false;
  temporaryTask: Partial<Task> | PreparedTask = null;

  private _highlightedTaskId: string | null = null;

  constructor() {
    makeAutoObservable(this);

    // @ts-ignore;
    window.tasks = this;
  }

  public get highlightedTaskId(): string | null {
    return this._highlightedTaskId;
  }

  public setHighlightedTaskId = (taskId: string): void => {
    this._highlightedTaskId = taskId;
  };

  public clearHighlightedTaskId = (): void => {
    this._highlightedTaskId = null;
  };
}
