import { Checkbox } from '@farmlink/farmik-ui';
import { Listbox } from '@headlessui/react';
import React, { useEffect, useState } from 'react';

import ExpandedButton from './components/ExpandedButton/ExpandedButton';
import Styled from './SelectWithCustomButton.styles';
import {
  ESelectType,
  IOptionItem,
  TMultiselectProps,
  TSelectWithCustomButtonProps as TProps,
} from './interfaces/Props.interface';

const SelectWithCustomButton = <T extends IOptionItem[]>(props: TProps<T>) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const getHeader = (isOpen: boolean) => {
    if (isOpen) {
      return <ExpandedButton label={props.label} open={isOpen} />;
    }

    return props.renderButton(selectedValues);
  };

  const getOption = (option: IOptionItem, selected: boolean) => {
    if (isMulti(props)) {
      return (
        <React.Fragment>
          <Checkbox value={selected} />
          <span>{option.label}</span>
        </React.Fragment>
      );
    }

    return <Styled.Text selected={selected}>{option.label}</Styled.Text>;
  };

  const handleSelect = (values: any) => {
    setSelectedValues(values);

    props.onChange?.(values);
  };

  useEffect(() => {
    if ('value' in props) {
      setSelectedValues(props.value as any);
    }
  }, [props.value]);

  return (
    <div>
      <Listbox
        value={selectedValues}
        defaultValue={props.defaultValue}
        onChange={handleSelect}
        multiple={props.type === ESelectType.MULTISELECT}
      >
        {({ open }) => (
          <React.Fragment>
            {getHeader(open)}

            {open && <Styled.Divider />}

            <Listbox.Options as={Styled.Body} isOpen={open}>
              {props.optionList.map(option => (
                <Listbox.Option as={Styled.Option} key={option.label} value={option.value}>
                  {prop => getOption(option, prop.selected)}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </React.Fragment>
        )}
      </Listbox>
    </div>
  );
};

const isMulti = (props: TProps<any>): props is TMultiselectProps<any> =>
  props.type === ESelectType.MULTISELECT;

export default SelectWithCustomButton;
