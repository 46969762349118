import { FC, memo } from 'react';

import { IChecklistsAttrToDraw } from '../../../models';
import { IGetChecklistStage } from '../../../../../../../../../api/models/checklist/stage/checklist.stage.model';
import { Container } from '../../../../../../../../shared/features/UI/Container';
import { ChecklistsAttrByTypeContainer as AttrByTypeContainer } from '../../../containers/attributes';
import { ChecklistsContent as Content } from '../ChecklistsContent';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  stage: IGetChecklistStage;
  attrToDrawList: IChecklistsAttrToDraw[];
}

const ChecklistsStage: FC<IProps> = ({ stage, attrToDrawList }) => {
  const getDataTestId = useDataTestIdV2('checklists__stage');

  return (
    <Container
      header={{
        title: stage.name,
      }}
      dataTestId={getDataTestId()['data-test-id']}
    >
      <Content dataTestId={getDataTestId('attributes')['data-test-id']}>
        {attrToDrawList.map(attrToDraw => (
          <AttrByTypeContainer key={attrToDraw.id} attrToDraw={attrToDraw} />
        ))}
      </Content>
    </Container>
  );
};

ChecklistsStage.displayName = 'ChecklistsStage';

export default memo(ChecklistsStage);
