import { makeAutoObservable } from 'mobx';

import { IStoHistoryVersion } from '../../../../../../../../../../api/models/as-fields/STO/stoHistoryVersion.model';
import { ERequestStatus } from '../../../../../../../../../shared/constants/requests';
import { provide } from '../../../../../../../../../shared/utils/IoC';

@provide.singleton()
class StoHistoryStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _stoHistoryVersion: IStoHistoryVersion = null;

  private _historyVersionFetchStatus: ERequestStatus = null;

  get stoHistoryVersion() {
    return this._stoHistoryVersion;
  }

  get stoHistoryVersionList() {
    return this._stoHistoryVersion?.versions ?? [];
  }

  get stoHistoryVersionFetchStatus() {
    return this._historyVersionFetchStatus;
  }

  get isHistoryFetched() {
    return this._historyVersionFetchStatus === ERequestStatus.Fulfilled;
  }

  setStoHistoryVersion = (stoHistoryVersion: IStoHistoryVersion) => {
    this._stoHistoryVersion = stoHistoryVersion;
  };

  setHistoryVersionFetchStatus = (status: ERequestStatus) => {
    this._historyVersionFetchStatus = status;
  };

  clearStoHistoryVersion = () => {
    this._stoHistoryVersion = null;
  };

  clearHistoryVersionFetchStatus = () => {
    this._historyVersionFetchStatus = null;
  };

  clearStoHistoryStore = () => {
    this.clearStoHistoryVersion();
    this.clearHistoryVersionFetchStatus();
  };
}

export default StoHistoryStore;
