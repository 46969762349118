import React, { FC, memo } from 'react';

import { IStoAttributeOdzDependencyPut as IDependencyPut } from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import {
  StoAttributeOdzDependencyNumeric as Numeric,
  StoAttributeOdzDependencyDictionary as Dictionary,
} from '../../components';

interface IProps {
  groupId: string;
  hasGroupSeveralDeps: boolean;
  dependency: IDependencyPut;
}

const StoAttributeOdzDependencyContainer: FC<IProps> = ({
  groupId,
  hasGroupSeveralDeps,
  dependency,
}) => {
  if (dependency.numericValues) {
    return <Numeric groupId={groupId} hasGroupSeveralDeps={hasGroupSeveralDeps} {...dependency} />;
  }

  if (dependency.dictionaryValues) {
    return (
      <Dictionary groupId={groupId} hasGroupSeveralDeps={hasGroupSeveralDeps} {...dependency} />
    );
  }

  return <></>;
};

StoAttributeOdzDependencyContainer.displayName = 'StoAttributeOdzDependencyContainer';

export default memo(StoAttributeOdzDependencyContainer);
