import moment from 'moment/moment';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TaskOperationCreateStore as Store } from '../../stores';
import { TaskOperationCreateApiService as ApiService } from '../TaskOperationCreateApiService';
import { ProfileStore } from '../../../../../../profile/stores/ProfileStore';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { Operation } from '../../../../../../../../../api/models/operations/operation.model';
import { toDouble } from '../../../../../mobx/stores/TaskStore/Task.store';

interface IDateRangeOptions {
  minDate?: Date;
  maxDate?: Date;
}

@provide.transient()
class TaskOperationCreateDefaultValuesService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(ApiService)
  protected apiService: ApiService;

  public get cultureDefaultValue(): ISelectOption | null {
    const cultureOption = this.store.cultureSelectOptionList.find(
      ({ value }) => this.store.taskCreate?.cultureId === value
    );

    if (!cultureOption) return null;

    return cultureOption;
  }

  public get operationDefaultValue(): ISelectOption<Operation> | null {
    const operation = this.store.operationSelectOptionList.find(
      ({ value }) => this.store.taskCreate?.operationId === value
    )?.initialModel;

    if (!operation) return null;

    const option: ISelectOption<Operation> = {
      value: operation.id,
      label: operation.operationTypeInfo.name,
      initialModel: operation,
    };

    return option;
  }

  public get dateDefaultValue(): string {
    if (this.store.taskCreate?.planStartDate) {
      return moment(this.store.taskCreate.planStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
    }

    const yesterday = moment().subtract(1, 'days').format('DD.MM.YYYY');

    if (!this.store.taskCreate?.operationId) return yesterday;

    const operation = this.store.operationSelectOptionList.find(
      ({ value }) => this.store.taskCreate?.operationId === value
    )?.initialModel;

    if (!operation) return '';

    const momentStartDate = moment(operation.startDate, 'YYYY-MM-DD');
    const momentEndDate = moment(operation.endDate, 'YYYY-MM-DD');
    const momentYesterday = moment(yesterday, 'DD.MM.YYYY');

    if (momentYesterday.isBetween(momentStartDate, momentEndDate)) return yesterday;
    if (momentYesterday.isBefore(momentStartDate)) return momentStartDate.format('DD.MM.YYYY');
    if (momentYesterday.isAfter(momentEndDate)) return momentEndDate.format('DD.MM.YYYY');

    return '';
  }

  public get assigneeDefaultValue(): ISelectOption | null {
    if (!this.profileStore.user?.id) return null;

    return {
      label: this.profileStore.fullName,
      value: this.profileStore.user.id,
    };
  }

  public get configDefaultValueList(): ISelectOption[] {
    if (!this.store.config) return [];
    if (!this.store.taskCreate?.operationValues) return [];

    const configOptionList = this.store.configSelectOptionList.filter(({ value }) => {
      return this.store.taskCreate.operationValues.some(
        ({ dictionaryValueId }) => dictionaryValueId === value
      );
    });

    return configOptionList;
  }

  public get dateRangeOptions() {
    const plug: IDateRangeOptions = {
      minDate: new Date(),
      maxDate: new Date(),
    };

    if (!this.store.taskCreate) {
      return plug;
    }

    const operation = this.store.operationSelectOptionList.find(
      ({ value }) => this.store.taskCreate?.operationId === value
    )?.initialModel;

    if (!operation) {
      return plug;
    }

    const minDate = moment(operation.startDate).toDate();
    const maxDate = moment(operation.endDate).toDate();

    return {
      minDate,
      maxDate,
    };
  }
  public get activeCultureName(): string {
    const activeCulture = this.store.cultureSelectOptionList.find(
      culture => culture?.value === this.store.taskCreate?.cultureId
    );

    if (!activeCulture) return '';

    return activeCulture.label;
  }

  public get activeOperationName() {
    const activeOperation = this.store.operationSelectOptionList.find(
      operation => operation?.value === this.store.taskCreate?.operationId
    );

    if (!activeOperation) return '';

    return activeOperation.label;
  }

  public get totalAreaValue() {
    if (!this.store.fieldOptionList) return;

    const areasSum = this.store.fieldOptionList.reduce((accum, field) => {
      if (this.store.selectedFieldIdList.has(field.value)) {
        return accum + field.initialModel.area;
      }
      return accum;
    }, 0);

    return toDouble(areasSum);
  }

  public storeDefaultDate = (): void => {
    const formattedDefaultValue = moment(this.dateDefaultValue, 'DD.MM.YYYY').format('YYYY-MM-DD');

    if (this.store.taskCreate?.planStartDate === formattedDefaultValue) return;

    this.store.setTaskCreate(prev => ({
      ...prev,
      planStartDate: formattedDefaultValue,
      planEndDate: formattedDefaultValue,
    }));
  };
}

export default TaskOperationCreateDefaultValuesService;
