import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const footerHeight = '64px';

const Body = styled('div')({
  height: `calc(100% - 64px - ${footerHeight})`,
  overflow: 'auto',
  padding: '16px 12px',
  display: 'flex',
  flexDirection: 'column',
  gap: ESpaceSize.M,

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
});

const Footer = styled('div')({
  padding: '12px',
  height: footerHeight,

  '& button': {
    borderRadius: '12px',
    height: '40px',
  },
});

export default {
  Body,
  Footer,
};
