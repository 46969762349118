import { FC, memo } from 'react';

import fieldsCountSvg from '../../../assets/fieldsCount.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../../../shared/features/utils/hooks/locators';
import { useTasksTaskOperationRoutes } from '../../../../../../../hooks';

interface IProps {
  dataTestId: string;
  selectedCount: number;
  totalCount: number;
}
const TaskOperationCreateVerificationFieldsCount: FC<IProps> = ({
  dataTestId,
  selectedCount,
  totalCount,
}) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__fields-count`);
  const routes = useTasksTaskOperationRoutes();

  const handleFieldsCountClick = (): void => {
    routes.goToFields();
  };

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label color={'generalDarkHover'} {...getDataTestId('label')}>
        Количество полей
      </Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={fieldsCountSvg} />

      <Styled.FieldsCountInfo
        onClick={handleFieldsCountClick}
        variant={'title'}
        color={'accentPositive'}
        {...getDataTestId('info')}
      >
        {selectedCount} из {totalCount}
      </Styled.FieldsCountInfo>
    </Styled.InfoBlock>
  );
};

TaskOperationCreateVerificationFieldsCount.displayName =
  'TaskOperationCreateVerificationFieldsCount';

export default memo(TaskOperationCreateVerificationFieldsCount);
