import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 12px;
  background: ${Colors.generalWhite};
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${Colors.generalBlack};
  margin: 0;
`;

const ActionsWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
`;

const ActionsColorMap = {
  accent: Colors.green,
  deny: Colors.red,
};

const Action = styled.div<{ colorScheme: 'accent' | 'deny' }>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 16px;
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  p {
    margin: 0;
    color: ${({ colorScheme }) => ActionsColorMap[colorScheme]};
  }
`;

const Tail = styled.div`
  display: flex;
  width: 7px;
  height: 7px;
  position: absolute;
  bottom: -4px;
  left: 0;
  background: ${Colors.generalWhite};
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(45deg);
`;

export default {
  Wrapper,
  ActionsWrapper,
  Description,
  Action,
  Tail,
};
