import {
  ButtonLink,
  Input,
  ModalFooter,
  NewDropdown,
  Typography,
  useModal,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import * as turf from '@turf/turf';
import { useCallback, useMemo, useState } from 'react';
import { TNewDropdownConfig } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import {
  ECheckListInstanceType,
  IDrawChecklistInstance,
} from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { ISelectOption } from '../../../../../../../../types/selectOption';
import isPointInPolygon from '../../../../../../../shared/features/map/utils/helpers/isPointInPolygon';
import { validateCoordinates } from '../../../../../../../shared/utils/helpers/validateCoordinates';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { InspectionPointsStore } from '../../../../components/InspectionPoints/mobx/stores';
import { useTasksRouteActions } from '../../../../hooks';
import { TaskStore } from '../../../../mobx/stores';
import { TasksParams } from '../../../../models';

import Styled from './EditTaskPointModal.styles';

interface IEditTaskPointModalPayload {
  isNewPoint: boolean;
  coordinates: number[];
  positionNumber: number;
  type?: ECheckListInstanceType;
  instanceTypeListToCreate?: ECheckListInstanceType[];
  params?: TasksParams;
  pointInst?: IDrawChecklistInstance;
}

const getOptionList = (instanceTypeListToCrate: ECheckListInstanceType[] = []): ISelectOption[] => {
  return instanceTypeListToCrate.reduce<ISelectOption[]>((optionList, type) => {
    switch (type) {
      case ECheckListInstanceType.PlannedPoint:
        optionList.push({ label: 'Точка', value: ECheckListInstanceType.PlannedPoint });
        break;
      case ECheckListInstanceType.Machinery:
        optionList.push({ label: 'Техника', value: ECheckListInstanceType.Machinery });
        break;
      case ECheckListInstanceType.Field:
        optionList.push({ label: 'Поле', value: ECheckListInstanceType.Field });
        break;
      default:
    }

    return optionList;
  }, []);
};

const EditTaskPointModal = () => {
  const { getModalPayload, getModalRuntimeHandlers, closeModal } = useModal();
  const payload = (getModalPayload() || {}) as IEditTaskPointModalPayload;
  const { onSuccess, onCancel } = getModalRuntimeHandlers();
  const { selectedTask, selectedField } = useStore(TaskStore);
  const { setSelectedPoint } = useStore(InspectionPointsStore);

  const { instanceTypeListToCreate, coordinates, type, positionNumber } = payload;

  const [newCoordinates, setNewCoordinates] = useState('');
  const [newCoordinatesError, setNewCoordinatesError] = useState(null);
  const [isDisplayError, setIsDisplayError] = useState(false);
  const [pointType, setPointType] = useState<ECheckListInstanceType>(type);

  const { goToMapEditPoint } = useTasksRouteActions();

  const optionList = useMemo(() => {
    return getOptionList(instanceTypeListToCreate);
  }, [instanceTypeListToCreate]);

  const currentCords = coordinates.map(item => item.toFixed(7)).join(', ');
  const defaultValue = optionList.find(option => option.value === pointType);
  const isNewPoint = payload?.isNewPoint;
  const isDisplayCurrentCorsInput = true;
  const isDisplayNewCordsInput = true;

  const zone = selectedTask?.cultureZone || selectedField;

  const isPointInsidePolygon = useCallback((cords: string) => {
    if (!cords?.length) {
      return true;
    }

    const turfPoint = turf.point(cords?.split(', ').map(item => Number(item)));

    if (isPointInPolygon(turfPoint, zone.geometry)) {
      return true;
    } else {
      throw new Error('Координаты точки за пределами поля');
    }
  }, []);

  const newCoordinatesHandler = useCallback((e: string) => {
    setNewCoordinates(e);

    try {
      if (e !== '' || e !== null) {
        validateCoordinates(e);
        isPointInsidePolygon(e);
      }

      setNewCoordinatesError(null);
    } catch (error) {
      setNewCoordinatesError(error?.message);
    }
  }, []);

  const dropdownConfig: TNewDropdownConfig = {
    field: {
      onChange: (value: ECheckListInstanceType) => {
        if (value === ECheckListInstanceType.Machinery || value === ECheckListInstanceType.Field) {
          setNewCoordinates('');
          setNewCoordinatesError(null);
        }

        setPointType(value);
      },
      placeholder: 'Выберите тип осмотра',
      defaultValue,
      id: 'culture-select',
      type: {},
    },
    body: {
      optionList,
      zIndex: '10002',
    },
    visual: {
      label: 'Тип осмотра',
    },
    validation: {
      error: {
        errorList: [],
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
    other: {
      dataTestId: 'culture-select',
    },
  };

  const onSave = useCallback(() => {
    if (newCoordinatesError) {
      setIsDisplayError(true);
    } else {
      onSuccess?.({
        type: pointType,
        coordinates: newCoordinates?.length
          ? newCoordinates?.split(', ').map(item => Number(item))
          : coordinates,
      });
    }
  }, [newCoordinates, pointType, newCoordinatesError]);

  const handleGoToEditPoint = () => {
    goToMapEditPoint({ ...payload.params, skipDisplayPointsFromBack: true });

    setSelectedPoint({ ...payload.pointInst, type: pointType, positionNumber });
    closeModal();
  };

  return (
    <Styled.Wrapper>
      <Typography variant="h5">{`Координаты точки ${positionNumber}`}</Typography>
      <Styled.Hr />

      {isNewPoint && <NewDropdown config={dropdownConfig} />}

      {isDisplayCurrentCorsInput && (
        <Input label="Текущие координаты" value={currentCords} blocked />
      )}

      {isDisplayNewCordsInput && (
        <Input
          label="Новые координаты"
          value={newCoordinates}
          onChange={newCoordinatesHandler}
          error={isDisplayError ? newCoordinatesError : null}
        />
      )}

      <ModalFooter
        successButton={{
          title: 'Сохранить',
          color: 'primary',
          handler: onSave,
          disabled: !pointType,
        }}
        denyButton={{
          title: 'Отменить',
          handler: () => {
            if (isNewPoint) {
              onCancel?.();
            }
            closeModal();
          },
        }}
      />

      {!isNewPoint && (
        <Styled.ButtonLinkWrapper>
          <ButtonLink onClick={handleGoToEditPoint} color="secondary">
            Указать положение точки на карте
          </ButtonLink>
        </Styled.ButtonLinkWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default observer(EditTaskPointModal);
