import { Button, ENotificationType, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { useStoParams } from '../../../../../../../../../../../../../../hooks';
import { useStore } from '../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../mobx/controllers';
import {
  ENotificationScheme,
  getNotificationScheme,
} from '../../../../../../../../../../../../../../../../../shared/utils/helpers/getNotificationScheme';
import { StoStore } from '../../../../../../../../../../../../mobx';

import Styled from './StoAttributeControlButtons.styles';

const StoAttributeControlButtons: FC = () => {
  const stoStore = useStore(StoStore);
  const store = useStore(Store);

  const controller = useStore(Controller);

  const params = useStoParams();
  const notificatorActions = useNotificator();

  const handleButtonClick = () => {
    controller.saveChanges(params);

    if (
      store.hasErrors ||
      store.hasOdzErrors ||
      store.hasDepOdzErrors ||
      store.hasNoSelectedDependency
    )
      return;

    notificatorActions.setNotification(
      getNotificationScheme(ENotificationScheme.Dark, 'Настройки параметра сохранились', {
        type: ENotificationType.Success,
      })
    );
  };

  return (
    <Styled.Wrapper>
      <Styled.ButtonWrapper $isHidden={Boolean(params.versionId) || stoStore.isViewOnly}>
        <Button
          color={'primary'}
          type={'button'}
          onClick={handleButtonClick}
          disabled={
            !store.hasChanges ||
            store.hasErrors ||
            store.hasOdzErrors ||
            store.hasDepOdzErrors ||
            store.hasNoSelectedDependency
          }
        >
          Сохранить
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

StoAttributeControlButtons.displayName = 'StoAttributeControlButtons';

export default observer(StoAttributeControlButtons);
