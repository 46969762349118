import * as turf from '@turf/turf';

import toTurfPolygon from './toTurfPolygon';

type TPoint = turf.helpers.Feature<turf.helpers.Point>;

/**
 * Проверяет что точка находится внутри полигона
 * @param point - точка для проверки
 * @param geometry - геометрия в {@link Geometry формате geoJSON}. Нужна для создания полигона
 */
function isPointInPolygon(point: TPoint, geometry: turf.Geometry) {
  const turfPolygon = toTurfPolygon(geometry);

  return turf.booleanPointInPolygon(point, turfPolygon);
}

export default isPointInPolygon;
