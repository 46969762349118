import L, { DivIcon, DivIconOptions, LatLngExpression, MarkerOptions } from 'leaflet';

class BaseMarker extends L.Marker {
  public id: number;

  constructor(latlng: LatLngExpression, options?: MarkerOptions) {
    super(latlng, { pmIgnore: true, ...options });

    this.id = L.Util.stamp(this);
  }

  public setStyle(style: DivIconOptions) {
    const markerIcon = this.getIcon();
    const newIcon = new DivIcon({ ...markerIcon.options, ...style });

    this.setIcon(newIcon);
  }
}

export default BaseMarker;
