import { FC, useMemo, useState } from 'react';

import { IGetChecklistInstanceByTaskId } from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import { ViewingPointsRows } from './components/ViewingPointsRows';
import Styled from './TasksDrawerViewingPoints.styles';

interface IProps {
  checklistInstanceList: IGetChecklistInstanceByTaskId[];
  dataTestId: string;
}

const maxVisibleRowsNumber = 4;

const TasksDrawerViewingPoints: FC<IProps> = ({ checklistInstanceList, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__viewing-points`);
  const [isShowAllRows, setIsShowAllRows] = useState(false);

  const buttonLabel = useMemo(() => {
    return `Показать +${checklistInstanceList.length - maxVisibleRowsNumber}`;
  }, [checklistInstanceList.length]);

  const pointsCount = checklistInstanceList ? checklistInstanceList.length : null;

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Label {...getDataTestId('info-block')}>Точки осмотра: {pointsCount}</Styled.Label>

      <Styled.ViewingPointsWrapper $isShowAllRows={isShowAllRows}>
        <ViewingPointsRows
          dataTestId={getDataTestId('row')['data-test-id']}
          checklistInstanceList={checklistInstanceList}
        />
      </Styled.ViewingPointsWrapper>

      {checklistInstanceList.length > maxVisibleRowsNumber && !isShowAllRows ? (
        <Styled.ButtonWrapper>
          <Styled.Button
            onClick={() => {
              setIsShowAllRows(true);
            }}
            {...getDataTestId('button')}
          >
            {buttonLabel}
          </Styled.Button>
        </Styled.ButtonWrapper>
      ) : null}
    </Styled.Wrapper>
  );
};

TasksDrawerViewingPoints.displayName = 'TaskDrawerViewingPoints';

export default TasksDrawerViewingPoints;
