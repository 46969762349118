import { useEffect } from 'react';

import { useTasksRouteActions } from '../index';
import { useContextualLink } from '../../../../../shared/features/ContextualLink/hooks';
import { EContextualLinkId } from '../../../../constants/configs/ContextualLinkId';
import { ETasksContextualReturnBackButtonText } from '../../constants';

const useTasksBackButtonData = () => {
  const { getTasksPath } = useTasksRouteActions();

  const { getLink, logDefaultLink, onReturnClick } = useContextualLink();

  useEffect(() => {
    logDefaultLink({
      id: EContextualLinkId.TasksTaskBackButton,
      from: {
        label: ETasksContextualReturnBackButtonText.TasksListing,
        url: getTasksPath(),
      },
    });
  }, []);

  return {
    arrowText: getLink(EContextualLinkId.TasksTaskBackButton)?.from?.label,
    handler: () => onReturnClick(EContextualLinkId.TasksTaskBackButton),
  };
};

export default useTasksBackButtonData;
