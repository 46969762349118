import React, { FC, PropsWithChildren, useRef } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../shared/utils/IoC';
import StoListStore from '../StoListContainer/mobx/stores/StoListStore/StoList.store';
import { ContentLoader } from '../../../../../shared/features/UI/loaders/ContentLoader';
import { useDataTestIdV2 } from '../../../../../shared/features/utils/hooks/locators';

import Styled from './StoListPlugContainer.styles';

const StoListPlugContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { isFetchingStoList } = useStore(StoListStore);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const getDataTestId = useDataTestIdV2('sto__list__plug-container');

  return (
    <Styled.Wrapper ref={wrapperRef} {...getDataTestId()}>
      {isFetchingStoList ? (
        <ContentLoader
          parentRef={wrapperRef}
          overlayStyles={{ borderRadius: '16px', zIndex: '3' }}
          dataTestId={getDataTestId('loader')['data-test-id']}
        />
      ) : null}

      {children}
    </Styled.Wrapper>
  );
};

StoListPlugContainer.displayName = 'StoListPlugContainer';

export default observer(StoListPlugContainer);
