import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizes } from '../../../../../../constans/sizes';

function adjust(color, amount) {
  return `#${color
    .replace(/^#/, '')
    .replace(/../g, c =>
      `0${Math.min(255, Math.max(0, parseInt(c, 16) + amount)).toString(16)}`.substr(-2)
    )}`;
}

interface IProps {
  hasScrollButtons: boolean;
}

interface ICultureColorProps {
  $color: string;
  isInteractive: boolean;
  isSelected: boolean;
}

const CulturesListWrapper = styled('div')<IProps>(
  {
    display: 'flex',
    backgroundColor: Colors.generalWhite,
    borderRadius: 12,
    height: 40,
    boxShadow: '0px 2px 24px rgb(0 0 0 / 10%)',
    overflow: 'hidden',
    pointerEvents: 'auto',
  },
  ({ hasScrollButtons }) => ({
    padding: hasScrollButtons ? '0 32px' : '0 12px',
  })
);

const CultureColor = styled('div')({
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',

  marginRight: ESpaceSize.XS,

  '& *': {
    position: 'absolute',
  },

  '& path': {
    fill: 'white',
    stroke: 'white',
  },

  '&::before': {
    content: "''",
    display: 'flex',
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
});

const CultureLabel = styled.div`
  ${FontSizes.medium};
  color: ${Colors.generalBlack};
  white-space: nowrap;
`;

const CultureWrapper = styled('div')<ICultureColorProps>(
  {
    display: 'flex',
    alignItems: 'center',
    marginRight: ESpaceSize.M,
    position: 'relative',

    '&:last-of-type': {
      marginRight: 0,
    },
  },
  ({ $color, isInteractive, isSelected }) => ({
    cursor: isInteractive ? 'pointer' : 'default',

    [CultureColor]: {
      backgroundColor: `${$color}33`, // 33 = 20% opacity
      border: isSelected ? `1px solid ${$color}` : 'none',

      '&::before': {
        backgroundColor: $color,
      },
    },

    '&:hover': {
      [CultureColor]: {
        backgroundColor: `${adjust(`${$color}33`, -10)}`,

        '&::before': {
          backgroundColor: `${adjust($color, -10)}`,
        },
      },
    },
  })
);

export default {
  CulturesListWrapper,
  CultureWrapper,
  CultureColor,
  CultureLabel,
};
