import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@farmlink/farmik-ui';

import { ScrollableContainer } from '../../../../../../../../../shared/containers';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { TaskOperationCreateStore as Store } from '../../../../mobx/stores';
import { TaskOperationCreateController as Controller } from '../../../../mobx/controllers';
import { ISelectOption } from '../../../../../../../../../../types/selectOption';
import { OperationField } from '../../../../../../../../../../api/models/operations/operation.field.model';

import { TaskOperationCreateFieldsListItem as Item } from './components';

interface IProps {
  dataTestId: string;
}

const TaskOperationCreateFieldsList: FC<IProps> = ({ dataTestId }) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const entityToDisplayList = store.fieldOptionListToDisplay;

  const maxHeight = entityToDisplayList.length >= 10 ? 490 : entityToDisplayList.length * 49;
  const isDisplayNoFieldsPlaceholder = entityToDisplayList.length === 0;

  const handleSelect = useCallback((option: ISelectOption<OperationField>): void => {
    controller.changeField(option);
  }, []);

  const getDataTestId = useDataTestIdV2(dataTestId);

  if (isDisplayNoFieldsPlaceholder) {
    return (
      <Typography
        dataTestId={getDataTestId('no-data-text')['data-test-id']}
        variant="bodySubAccent"
      >
        Ничего не найдено по вашему запросу
      </Typography>
    );
  }

  return (
    <ScrollableContainer
      maxHeight={`${maxHeight}px`}
      isDisabled={entityToDisplayList.length <= 10}
      dataTestId={getDataTestId()['data-test-id']}
    >
      {entityToDisplayList.map((entity, i) => (
        <Item
          key={entity.value}
          option={entity}
          dataTestId={getDataTestId('item')['data-test-id']}
          onSelect={handleSelect}
          isLast={i === entityToDisplayList.length - 1}
          {...entity}
          isSelected={store.hasSelectedFieldId(entity.value)}
          isViewOnly={false}
        />
      ))}
    </ScrollableContainer>
  );
};

TaskOperationCreateFieldsList.displayName = 'CreateFieldsList';

export default observer(TaskOperationCreateFieldsList);
