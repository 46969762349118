import { extractCultureNames } from '../../../../../../../shared/utils/helpers/extractCultureNames';
import { IMapLayerGroupClickPayload } from '../../../../../../../shared/features/map/models';
import { FieldLayerGroup } from '../../../../../../../shared/features/map/utils/MapElements';
import { CultureModel } from '../../../../../../../../api/models/culture.model';
import { MapEventBus } from '../../../../../../../shared/features/map/modules';
import { EFieldTooltip } from '../../../../../../constants/FieldTooltip.enum';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../../constants/FieldFill.enum';
import { EFieldsMode, FIELD_POLYGON_OPTIONS } from '../../../../utils';
import { Field } from '../../../../../../../../api/models/field.model';
import APIService from '../services/MultiselectAPI.service';
import { FieldsMapCoreController } from '../../../../mobx';
import Store from '../stores/Multiselect.store';

@provide.transient()
class FieldsMultiselectController extends FieldsMapCoreController {
  @lazyInject(Store)
  private store: Store;

  @lazyInject(APIService)
  private apiService: APIService;

  public async initialize() {
    this.store.isInitialize = true;
    this.coreStore.fieldsMode = EFieldsMode.MULTISELECT;

    // Получаем список полей с бэка. Сетаем их в FieldsStore
    const fieldsList = await this.fieldsApiService.fetchFieldsList();

    // Подготовка карты. Центрирование
    this.centerMapToFieldBounds(this.fieldToCenter ?? fieldsList[0]);
    this.registerMapEvents();

    // Создаем глубокие копии списка полей
    this.store.setFieldsList(fieldsList);

    // Инициализируем фильтр и карту
    this.setSourceList();
    this.store.isInitialize = false;
  }

  /**
   * Сбрасывает sourceList у фильтр-менеджера.
   * Используется для сброса всех изменений в списке
   */
  public reset() {
    const { fieldsListSnapshot, filterManager } = this.store;

    filterManager.filters.restoreSnapshot(true);
    filterManager.updateSourceList(fieldsListSnapshot);

    this.store.reset(fieldsListSnapshot);
  }

  public destroy() {
    super.destroy();
    this.store.clear();

    this.store.filterManager?.emitter.removeAllListeners();
    this.store.filterManager?.filters.getOne('search').resetValue(true);
  }

  /**
   * Меняет культуру у выбранных полей. Локально модифицирует store.fieldsList
   * Добавляет культуру в фильтр в случае если её там нет
   */
  public changeCulturesLocally(culture: CultureModel) {
    const cultureFilter = this.store.filterManager.filters.getOne('culture');

    if (!cultureFilter.isEmptyValue && !cultureFilter.value.includes(culture.name)) {
      cultureFilter.addValue(culture.name, true);
    }

    this.store.changeSelectedFieldsCultures(culture);

    this.updateFilterManagerSource();
    this.deselectUnusedCultures();
  }

  /**
   * Удаляет выбранные поля. Удаление происходит локально из списка.
   * Удаляет зависимые значения из фильтра
   */
  public deleteFieldsLocally(fromAllSeasons: boolean) {
    const nameFilter = this.store.filterManager.filters.getOne('name');

    this.store.selectedFieldsCollection.forEach(id => {
      nameFilter.removeValue(id, true);
    });

    this.store.deleteSelectedFields(fromAllSeasons);

    this.updateFilterManagerSource();
    this.deselectUnusedCultures();
  }

  /**
   * Отправляет все изменения на бэк.
   * Подстраивает значения фильтров под новые данные
   */
  public saveAll() {
    const { changedFields, deletedFields } = this.store;

    if (changedFields) {
      return this.apiService.updateCultures(changedFields);
    }

    return this.apiService.deleteCultures(deletedFields);
  }

  public toggleField(field: Field, value: boolean, layer?: FieldLayerGroup) {
    const fieldLayer = layer ?? this.getLayerGroupByField(field);

    if (!fieldLayer) {
      return;
    }

    if (!value) {
      this.store.selectedFieldsCollection.delete(field.id);
      fieldLayer.deselect();

      return;
    }

    this.store.selectedFieldsCollection.add(field.id);

    fieldLayer.select({ ...FIELD_POLYGON_OPTIONS.selected, color: '#780BBB' });
  }

  public toggleAllFields(value: boolean) {
    this.store.activeFieldsList.forEach(field => {
      this.toggleField(field, value, null);
    });
  }

  protected registerMapEvents() {
    const listener1 = MapEventBus.on('layerGroup.click', this.handleLayerGroupClick);

    this.coreStore.setMapEventListeners([listener1]);
  }

  protected handleLayerGroupClick = (payload: IMapLayerGroupClickPayload) => {
    const field = this.getFieldByLayerGroup(payload.layerGroup);
    const selected = this.store.isSelected(field.id);

    this.toggleField(field, !selected);
  };

  /**
   * Функция переназначает sourceList для filterManager т.к мультиселект должен быть привязан к общей фильтрации
   */
  private updateFilterManagerSource() {
    this.store.filterManager.updateSourceList(this.store.fieldsList);
  }

  private setSourceList() {
    const { filterManager } = this.store;

    filterManager.filters.createSnapshot();

    filterManager.emitter.removeAllListeners('changeFilteredList');

    filterManager.emitter.on('changeFilteredList', list => {
      this.buildMap(list, this.getLayerGroupConfig).then(() => {
        this.culturesLegendStore.setCultures(list);
        this.layerFillController.setStrategy(EFieldFill.Cultures);
        this.layerTooltipController.setContent([EFieldTooltip.Name]);
      });
    });

    this.updateFilterManagerSource();
  }

  /**
   * Убирает из фильтрации все культуры для которых нет полей
   */
  private deselectUnusedCultures() {
    const { filterManager } = this.store;
    const cultureFilter = filterManager.filters.getOne('culture');
    const cultureNamesCollection = extractCultureNames(filterManager.filteredList, true);

    cultureFilter.value.forEach(cultureName => {
      if (!cultureNamesCollection.has(cultureName)) {
        cultureFilter.removeValue(cultureName, true);
      }
    });
  }
}

export default FieldsMultiselectController;
