import { Geometry } from '@turf/turf';
import L from 'leaflet';

/**
 * Преобразовывает geojson Geometry в leaflet LatLng.
 * Leaflet работает только с LatLng (за исключением L.GeoJSON). Поэтому геометрию необходимо преобразовать для работы с Leaflet
 * @param geometry - геометрия в формате {@link Geometry}
 */
function getLatLngsFromGeometry(geometry: Geometry) {
  const coordsDepth = geometry.type === 'MultiPolygon' ? 2 : 1;
  return L.GeoJSON.coordsToLatLngs(geometry.coordinates, coordsDepth);
}

export default getLatLngsFromGeometry;
