import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useMatch } from 'react-router-dom';
import { Note } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../mobx/stores';
import taskStore from '../../../../mobx/stores/TaskStore/Task.store';
import { Widget } from '../../../Widgets';
import {
  useTaskActionControl,
  useTaskPageGlobalControl,
  useTasksRouteActions,
} from '../../../../hooks';
import InspectionPoints from '../../../InspectionPoints/InspectionPoints';
import { ChecklistInstancesStore } from '../../../../../operationsAndTasks/stores/checklist.instances.store';
import { useInspectionPointsActionsHook } from '../../../InspectionPoints/hooks';
import { TasksRoute } from '../../../../tasks.route';
import { ChecklistInstancesController } from '../../../../../operationsAndTasks/controllers/checklist.instances.controller';
import {
  ETaskAction,
  ETaskStatus,
  ITask,
} from '../../../../../../../../api/models/as-fields/task/task.model';
import { ECheckListInstanceType } from '../../../../../../../../api/models/checklist/instance/checklist.instance.model';

import { LoadingSkeleton, NoPointsPlaceholder } from './components';

const getInstanceTypeListToCreate = (task: ITask): ECheckListInstanceType[] => {
  const list: ECheckListInstanceType[] = [];

  if (task?.checklistsAvailable) {
    list.push(ECheckListInstanceType.PlannedPoint);
  }

  if (task?.checklistsMachineryAvailable) {
    list.push(ECheckListInstanceType.Machinery);
  }

  if (task?.checklistsFieldAvailable) {
    list.push(ECheckListInstanceType.Field);
  }

  return list;
};

const MapContainer: FC<{ mode: TaskStore['mode'] }> = ({ mode }) => {
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const { listOfDrawPointInst } = useStore(ChecklistInstancesStore);
  const { selectedField, isViewMode, isEditMode, selectedTask } = useStore(TaskStore);
  const { clearInstances } = useStore(ChecklistInstancesController);

  const { displayPointList, displayZone } = useInspectionPointsActionsHook();
  const { goToMapCreatePoint, goToMap } = useTasksRouteActions();

  const { fetchInstanceList } = useTaskPageGlobalControl(mode, {
    isIgnoreOnMountFetch: {
      instanceList: true,
      taskData: true,
    },
    isIgnoreOnUnmountClear: true,
  });

  const controls = useTaskActionControl();
  const hasPermissionToEdit = controls.hasPermission(ETaskAction.TaskEditPoints);

  const isNoPoints = listOfDrawPointInst?.length < 1;
  const isPointCreation = Boolean(useMatch(TasksRoute.TaskMapCreatePoint));
  const isDisableAddPoints =
    selectedTask?.status === ETaskStatus.Canceled || selectedTask?.status === ETaskStatus.Completed;
  const isAllowedToCreateMarkers = hasPermissionToEdit && (isEditMode || mode === 'edit');

  const instanceTypeListToCreate = useMemo(() => {
    return getInstanceTypeListToCreate(selectedTask);
  }, [selectedTask]);

  useEffect(() => {
    if (isEditMode && !isAllowedToCreateMarkers) {
      goToMap();
    }

    setIsLoading(true);

    if (!(state as any)?.skipDisplayPointsFromBack) {
      fetchInstanceList()
        .then(pointList => {
          displayZone(selectedTask?.cultureZone || selectedField, {
            isAllowedToAddPoints: isAllowedToCreateMarkers,
          });

          displayPointList(pointList, { isCleanDisplay: true });
        })
        .finally(() => setIsLoading(false));
    }

    // return () => {
    //   clearInstances();
    // };
  }, []);

  useEffect(() => {
    displayPointList(listOfDrawPointInst, { isCleanDisplay: true });
  }, [listOfDrawPointInst]);

  useEffect(() => {
    displayZone(selectedTask?.cultureZone || selectedField, {
      isAllowedToAddPoints: isAllowedToCreateMarkers,
      isSkipRemoveMarkers: true,
    });
  }, [selectedTask?.cultureZone, selectedField, isAllowedToCreateMarkers]);

  return isNoPoints && !isPointCreation ? (
    <Widget title={'На карте'} dataTestId="no-points-placeholder">
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <NoPointsPlaceholder
          isNoChecklists={
            !selectedTask?.checklistsMachineryAvailable &&
            !selectedTask?.checklistsAvailable &&
            !selectedTask?.checklistsFieldAvailable
          }
          isDisableAddPoints={isDisableAddPoints}
          onButtonClick={() => goToMapCreatePoint()}
        />
      )}
    </Widget>
  ) : (
    <>
      {isEditMode && (
        <Note
          title={isNoPoints && 'Нет точек к осмотру'}
          bodyText="Отметьте точки на карте в границах поля"
          displayAgainToggler={{ storageId: 'task-map-note' }}
          isBlackTypography
          needCloseIcon
          needLeftBorder
          fullWidth
        />
      )}

      <InspectionPoints
        isDisplayTitleActionButton={!isNoPoints && isViewMode && !isDisableAddPoints}
        instanceTypeListToCreate={instanceTypeListToCreate}
        mode={'tasks'}
      />
    </>
  );
};

export default observer(MapContainer);
