import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';

const Wrapper = styled.div`
  margin-top: 120px;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 66px;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.black};
  letter-spacing: -0.15px;
  text-align: center;
  margin: 0 0 12px;
`;

const Description = styled.p<{ $color?: Colors }>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ $color }) => $color || Colors.black};
  text-align: center;
  letter-spacing: -0.1px;
  margin: 0;
`;

export default {
  Wrapper,
  Image,
  Title,
  Description,
};
