import { makeObservable, observable } from 'mobx';
import { cloneDeep } from 'lodash';

import { MultiListFilter, TFilterMemento } from '../../../../../shared/features/FilterManager';
import { extractCultureNames } from '../../../../../shared/utils/helpers/extractCultureNames';
import { mapToArray } from '../../../../../shared/utils/helpers/map/mapToArray';
import { IMultiListFilterOption } from '../../interfaces/Filter.interface';
import { Field } from '../../../../../../api/models/field.model';
import { sortOptionList } from '../helpers';

export type TCultureOption = IMultiListFilterOption;

/**
 * Значением являем название поля (culture.name)
 */
class FieldCultureFilter extends MultiListFilter<'culture', string, Field> {
  // Список всех названий культур из массива initialList. Нужен для внутренней калькуляции optionList
  private allCultureNamesList: string[] = [];

  // Список доступных для выбора опций. Является созависимым массивом
  public optionList: Array<TCultureOption> = [];

  constructor() {
    super('culture');

    makeObservable(this, { optionList: observable });
  }

  public createMemento(): TFilterMemento {
    const value = [...this.value];
    const allCultureNamesList = [...this.allCultureNamesList];
    const optionList = cloneDeep(this.optionList);

    return {
      restore: skipFiltering => {
        this.setValue(value, skipFiltering);
        this.allCultureNamesList = allCultureNamesList;
        this.optionList = optionList;
      },
    };
  }

  public _getFilterRule(el: Field): boolean {
    const cultureNames = el.cultureZones.map(zone => zone.culture?.name);

    return this.value.some(value => cultureNames.includes(value));
  }

  public _initState() {
    this.setAllCultureNames();
  }

  public _updateState() {
    const filteredList = this._manager.getFilteredSourceList([this.id]);
    const cultureNameCollection = extractCultureNames(filteredList as Field[], true);

    this.optionList = (this.allCultureNamesList ?? [])
      .map(cultureName => ({
        value: cultureName,
        label: cultureName,
        disabled: !cultureNameCollection.has(cultureName),
        selected: this.value.includes(cultureName),
      }))
      .sort(sortOptionList);

    cultureNameCollection.clear();
  }

  private setAllCultureNames() {
    const cultureNameCollection = extractCultureNames(this._manager.sourceList as Field[], true);
    this.allCultureNamesList = mapToArray(cultureNameCollection).sort();

    cultureNameCollection.clear();
  }
}

export default FieldCultureFilter;
