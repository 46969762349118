import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledStoRegistries = {
  Wrapper,
};

export default StyledStoRegistries;
