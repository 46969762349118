import styled, { keyframes } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import { FontSizes } from '../../../shared/constans/sizes';

const showWrapper = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.secondaryGray};
  display: grid;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 1006;
  /* animation: ${showWrapper} 0.3s ease-in; */
  grid-template-columns: minmax(1px, 1fr);
  grid-template-rows: auto minmax(1px, 1fr);
`;

export const Footer = styled.div`
  padding: 20px 24px 20px 24px;
  display: flex;
  flex-grow: 1;
  max-height: 100%;
  overflow: auto;
`;

export const OperationsListWrapper = styled.div`
  // width: 400px;
  // min-width: 264px;
`;

export const TasksListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% - 24px - 240px);
  @media (min-width: 1024px) {
    width: calc(100% - 24px - 240px);
  }
  @media (min-width: 1366px) {
    width: calc(100% - 24px - 300px);
  }
  @media (min-width: 1920px) {
    width: calc(100% - 24px - 400px);
  }
`;

export const Header = styled.div`
  width: 100%;
  min-height: 140px;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start; */
  padding: 20px 24px;
  /* margin-bottom: 16px; */
  border-bottom: 0.5px solid ${Colors.borderGray};
  background: ${Colors.generalWhite};
  z-index: 100;
`;

export const Label = styled.div`
  color: ${Colors.black};
  white-space: nowrap;
  ${FontSizes.xxlargeBold};
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 16px;
`;
