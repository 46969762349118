import { booleanPointInPolygon } from '@turf/turf';
import L from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { MapEventBus } from '../../MapCore';
import { BasePolygon, BaseLayerGroup } from '../../../utils/MapElements';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapLayerGroupStore from '../stores/MapLayerGroup.store';

@provide.transient()
class MapLayerGroupEventsService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapLayerGroupStore)
  private layerGroupStore: MapLayerGroupStore;

  public registerClickEvent(layer: BaseLayerGroup) {
    if (!this.coreStore.instance) {
      return;
    }

    layer.on('click', this.handleClickLayerGroup);
  }

  public handleClickLayerGroup = (event: L.LeafletMouseEvent) => {
    this.coreStore.preventMapClick = true;

    const layerGroup = event.target as BaseLayerGroup;
    const mainPolygon = layerGroup?.getMainPolygon();
    const clickedPolygon = event.layer as BasePolygon;

    if (!layerGroup || !clickedPolygon || !mainPolygon) {
      console.warn('Недостаточно данных для обработки клика на группу слоев');
      return;
    }

    MapEventBus.emit('layerGroup.click', {
      layerGroup,
      clickedPolygon,
      mainPolygon,
    });
  };

  /**
   * Вызывает событие отмены выделения слоя при клике на область карты без слоев
   */
  public handleDeselectLayerGroup = (event: L.LeafletMouseEvent) => {
    if (!this.layerGroupStore.selectedLayerGroup) {
      return;
    }

    const isInsideLayerGroup = this.layerGroupStore.layerGroupsList.some(layerGroup => {
      const point = [event.latlng.lng, event.latlng.lat];
      const polygon = layerGroup.getMainPolygon().toTurf();

      return booleanPointInPolygon(point, polygon);
    });

    if (!isInsideLayerGroup) {
      MapEventBus.emit('layerGroup.cancelSelected');
    }
  };
}

export default MapLayerGroupEventsService;
