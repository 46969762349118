import { createZoneName, toDouble } from '../../../mobx/stores/TaskStore/Task.store';
import { CultureZone, Field } from '../../../../../../../api/models/field.model';

const useTaskFieldName = (field: Field, cultureZone: CultureZone) => {
  const fieldNameText = createZoneName(field?.name, field?.area);

  if (cultureZone) {
    const zoneName = createZoneName(cultureZone?.name, cultureZone.area);

    return cultureZone?.name
      ? `${fieldNameText}. ${zoneName}. ${toDouble(cultureZone.area)} га`
      : `${fieldNameText}. ${toDouble(cultureZone.area)} га`;
  }

  return fieldNameText;
};

export default useTaskFieldName;
