import { observer } from 'mobx-react';
import { FC } from 'react';

import { useComparisonTableBuilderContext as useContext } from '../../../context';
import { ComparisonTableBuilderGrid as Grid } from '../../ComparisonTableBuilderGrid';
import { ComparisonTableBuilderColumnContainer as ColumnContainer } from '../../ComparisonTableBuilderColumnContainer';
import { IComparisonTableBuilderHeader as IHeader } from '../../../models/data';
import {
  ComparisonTableBuilderDashedHeader as DashedHeader,
  ComparisonTableBuilderClearedHeader as ClearedHeader,
} from '../../../components/headers';
import { ComparisonTableBuilderController } from '../../../mobx/controllers';
import { useStore } from '../../../../../utils/IoC';
import { useSortBy } from '../../../../utils/hooks/sort';

interface IProps {
  header: IHeader;
}

const ComparisonTableBuilderHeaderAutoRenderContainer: FC<IProps> = ({ header }) => {
  const controller = useStore(ComparisonTableBuilderController);

  const context = useContext();

  const fixedColumnList = useSortBy(
    controller.getColumnList(context.builderId, { isOnlyFixed: true })
  );

  const scrollableColumnList = useSortBy(controller.getColumnList(context.builderId));

  switch (header.autoRenderConfig.preset) {
    case 'cleared':
      return (
        <ClearedHeader
          columnsChildren={
            <Grid
              fixedChildren={fixedColumnList.map(column => (
                <ColumnContainer key={column.id} column={column} />
              ))}
              scrollableChildren={scrollableColumnList.map(column => (
                <ColumnContainer key={column.id} column={column} />
              ))}
              isHeader
            />
          }
        />
      );

    case 'dashed':
      return (
        <DashedHeader
          columnsChildren={
            <Grid
              fixedChildren={fixedColumnList.map(column => (
                <ColumnContainer key={column.id} column={column} />
              ))}
              scrollableChildren={scrollableColumnList.map(column => (
                <ColumnContainer key={column.id} column={column} />
              ))}
              isHeader
            />
          }
        />
      );

    default:
      return <></>;
  }
};

ComparisonTableBuilderHeaderAutoRenderContainer.displayName =
  'ComparisonTableBuilderHeaderAutoRenderContainer';

export default observer(ComparisonTableBuilderHeaderAutoRenderContainer);
