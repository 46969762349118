import { makeAutoObservable } from 'mobx';

import { BaseImageOverlay } from '../../../../../../../../../../../shared/features/map/utils/MapElements';
import { IndexModel } from '../../../../../../../../../../../../api/models/indices.model';
import { provide } from '../../../../../../../../../../utils/IoC';
import { TFetchArgs } from '../../interfaces/IndicesFill.interface';

@provide.singleton()
class IndicesStore {
  private _isLoading = false;

  private _indices: IndexModel[] = [];

  private _years: number[] = [];

  private _selectedIndex: IndexModel | null = null;

  private _selectedYear: number | null = null;

  private _displayedOverlay: BaseImageOverlay | null = null;

  private _fetchArgs: TFetchArgs | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  public get indices() {
    return this._indices;
  }

  public set indices(indicesList) {
    this._indices = indicesList;
  }

  public get years() {
    return this._years;
  }

  public set years(list) {
    this._years = list;
  }

  public get selectedIndex() {
    return this._selectedIndex;
  }

  public set selectedIndex(index) {
    this._selectedIndex = index;
  }

  public get selectedYear() {
    return this._selectedYear;
  }

  public set selectedYear(value) {
    this._selectedYear = value;
  }

  public get selectedIndexPosition() {
    return this.indices.findIndex(item => item.publicId === this.selectedIndex?.publicId);
  }

  public get displayedOverlay() {
    return this._displayedOverlay;
  }

  public set displayedOverlay(overlay) {
    this._displayedOverlay = overlay;
  }

  public get fetchArgs() {
    return this._fetchArgs;
  }

  public set fetchArgs(value) {
    this._fetchArgs = value;
  }

  public get hasIndices() {
    return Boolean(this.indices.length);
  }

  public get isLoading() {
    return this._isLoading;
  }

  public set isLoading(value: boolean) {
    this._isLoading = value;
  }

  public clear() {
    this._displayedOverlay = null;
    this._selectedIndex = null;
    this._selectedYear = null;
    this._isLoading = false;
    this._fetchArgs = null;
    this._indices = [];
    this._years = [];
  }
}

export default IndicesStore;
