import { ReactNode } from 'react';

import Styled from './ListWrapper.styles';

type Props = {
  title: string;
  footer: ReactNode;
  dataTestId: string;
  children: ReactNode;
};
const ListWrapper = (props: Props) => {
  const { title, footer, dataTestId, children } = props;

  return (
    <Styled.Wrapper data-test-id={dataTestId}>
      <Styled.Header>
        <Styled.LabelWrapper>
          <Styled.Label data-test-id={`${dataTestId}-label`}>{title}</Styled.Label>
        </Styled.LabelWrapper>
      </Styled.Header>

      <Styled.ListWrapper data-test-id={'fields-list-section-wrapper'}>
        {children}
      </Styled.ListWrapper>

      <Styled.Footer>{footer}</Styled.Footer>
    </Styled.Wrapper>
  );
};

export default ListWrapper;
