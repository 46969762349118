enum EFieldsRoutes {
  View = '/:orgId/fields',
  Add = '/:orgId/fields/add',
  Edit = '/:orgId/fields/:fieldId/edit',
  Multiselect = '/:orgId/fields/multiselect',

  Seasons = '/:orgId/fields/:fieldId/seasons',

  Import = '/:orgId/fields/import',
}

export default EFieldsRoutes;
