import { Checkbox, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';

import { FieldSeasonsModel } from '../../../../../../../../../../../../../../api/models/field.seasons.model';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { EFieldSeasonsModalName } from '../../../../../../../../modals';
import SharedStyled from '../../../../../../../../../../components/shared/styles/Shared.styles';
import {
  FieldCulturesController as CulturesController,
  FieldSeasonsViewStore as Store,
} from '../../../../../../../../mobx';

import Styled from './Header.styles';

interface IProps {
  season: FieldSeasonsModel;
  opened: boolean;
}

const SeasonCardHeader: FC<IProps> = ({ season, opened }) => {
  // Сторы
  const store = useStore(Store);

  // Контроллеры
  const culturesController = useStore(CulturesController);

  const hasFieldVersion = Boolean(season.fieldVersion);
  const [checked, setChecked] = useState<boolean>(hasFieldVersion);

  const isDisabledCheckboxLastSelectedSeason = store.isLastFieldSeasons && checked;
  const isCheckboxDisabled = !hasFieldVersion || isDisabledCheckboxLastSelectedSeason;

  const modal = useModal();
  const getDataTestId = useDataTestIdV2('field-season-card-header');

  const getAreaLabel = () => {
    return hasFieldVersion ? `${season.fieldVersion.area.toFixed(1)} га` : 'не определена';
  };

  const handleCheckbox = (value: boolean) => {
    if (hasFieldVersion && !value) {
      setChecked(false);

      const modalId = EFieldSeasonsModalName.disableField;
      modal.openModalByModalId(modalId, season, null, () => {
        setChecked(true);
      });
    }
  };

  const handleClick = () => {
    if (store.selectedSeason?.season.year !== season?.season.year) {
      void culturesController.renderField(season.fieldVersion);
    }

    store.selectedSeason = season;
  };

  return (
    <Styled.Wrapper active={opened} onClick={handleClick} {...getDataTestId('wrapper')}>
      <Checkbox
        value={checked}
        disable={isCheckboxDisabled}
        onChange={handleCheckbox}
        dataTestId={getDataTestId('checkbox')['data-test-id']}
      />

      <SharedStyled.TextWrapper {...getDataTestId('text-wrapper')}>
        <SharedStyled.Title active={opened} {...getDataTestId('title')}>
          {season.season?.label}
        </SharedStyled.Title>

        <SharedStyled.Subtitle {...getDataTestId('subtitle')}>
          Площадь: {getAreaLabel()}
        </SharedStyled.Subtitle>
      </SharedStyled.TextWrapper>
    </Styled.Wrapper>
  );
};

export default observer(SeasonCardHeader);
