import React, { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import SharedStyled from '../../../shared/styles/Shared.styles';

interface IProps {
  title: string;
  subtitle: string;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const ListCardTextWrapper: FC<IProps> = props => {
  return (
    <SharedStyled.TextWrapper {...props.getDataTestId('text-wrapper')}>
      <SharedStyled.Title {...props.getDataTestId('title')}>{props.title}</SharedStyled.Title>
      <SharedStyled.Subtitle {...props.getDataTestId('subtitle')}>
        {props.subtitle}
      </SharedStyled.Subtitle>
    </SharedStyled.TextWrapper>
  );
};

export default memo(ListCardTextWrapper);
