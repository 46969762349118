import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttribute as IAttribute,
  IGetChecklistAttributeValue as IAttributeValue,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import {
  IChecklistsAttrToDraw,
  TChecklistsChecklistAttrToDraw as TChecklistAttrToDraw,
} from '../../../../models';
import { ChecklistsStore } from '../../../stores';
import { ChecklistsAttrsFormulasService } from '../../ChecklistsAttrsFormulasService';

interface IInstanceTitles {
  title: string;
  subtitle: string;
}

@provide.transient()
class ChecklistsChecklistAttrsService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistsAttrsFormulasService)
  protected formulasService: ChecklistsAttrsFormulasService;

  createAttrToDraw = (
    groupId: string,
    attr: IAttribute,
    initialValueList: IAttributeValue[],
    options?: {
      stageId: string;
      isBlocked?: boolean;
      nestedInstanceId?: string;
    }
  ): TChecklistAttrToDraw => {
    // Формируем "рабочую" модель атрибута.
    const attrToDraw: TChecklistAttrToDraw = {
      id: attr.id,
      groupId,
      stageId: options.stageId,
      isVisible: true,
      order: attr.order,
      initialModel: attr,
      value: {
        checkListAttributeId: attr.id,
        checkListInstanceValue: [],
      },
      options: {
        nestedInstanceIdList: [],
      },
      isBlocked: false,
      validationScheme: {
        isShowError: false,
        errorTitle: '',
      },
      isEdited: false,
      nestedInstanceId: options?.nestedInstanceId,
    };

    return attrToDraw;
  };

  getInstanceTitles = (instanceId: string): IInstanceTitles => {
    const attrToDrawList = this.checklistsStore.getAttrToDrawListByGroupId(instanceId);

    const initialValue = {
      title: [],
      subtitle: [],
    };

    const tempTitles = attrToDrawList.reduce<typeof initialValue>((titles, attrToDraw) => {
      switch (attrToDraw.initialModel.attribute.type) {
        case EAttrType.Int: {
          const { initialModel, value } = attrToDraw as IChecklistsAttrToDraw<EAttrType.Int>;

          if (this.checkIfValueIsEmpty(value?.integerValue)) break;

          const formattedValue = `${initialModel.attribute.name}: ${value.integerValue}`;

          if (!initialModel.isTitle) titles.subtitle.push(formattedValue);
          else titles.title.push(formattedValue);

          break;
        }

        case EAttrType.Double: {
          const { initialModel, value } = attrToDraw as IChecklistsAttrToDraw<EAttrType.Double>;

          if (this.checkIfValueIsEmpty(value?.doubleValue)) break;

          const formattedValue = `${initialModel.attribute.name}: ${value.doubleValue}`;

          if (!initialModel.isTitle) titles.subtitle.push(formattedValue);
          else titles.title.push(formattedValue);

          break;
        }

        case EAttrType.String: {
          const { initialModel, value } = attrToDraw as IChecklistsAttrToDraw<EAttrType.String>;

          if (this.checkIfValueIsEmpty(value?.stringValue)) break;

          if (!initialModel.isTitle)
            titles.subtitle.push(`${initialModel.attribute.name}: ${value.stringValue}`);
          else titles.title.push(value.stringValue);

          break;
        }

        case EAttrType.DictionaryLink: {
          const {
            initialModel,
            options,
          } = attrToDraw as IChecklistsAttrToDraw<EAttrType.DictionaryLink>;

          const [firstValue] = options.selectedSelectOptionList;

          if (!firstValue) break;

          if (!initialModel.isTitle)
            titles.subtitle.push(`${initialModel.attribute.name}: ${firstValue.label}`);
          else titles.title.push(`${initialModel.attribute.name}: ${firstValue.label}`);

          break;
        }

        case EAttrType.Enum: {
          const { initialModel, options } = attrToDraw as IChecklistsAttrToDraw<EAttrType.Enum>;

          const [firstValue] = options.selectedSelectOptionList;

          if (!firstValue) break;

          if (!initialModel.isTitle)
            titles.subtitle.push(`${initialModel.attribute.name}: ${firstValue.label}`);
          else titles.title.push(`${initialModel.attribute.name}: ${firstValue.label}`);

          break;
        }

        case EAttrType.UserDictionaryLink: {
          const {
            initialModel,
            options,
          } = attrToDraw as IChecklistsAttrToDraw<EAttrType.UserDictionaryLink>;

          const [firstValue] = options.selectedSelectOptionList;

          if (!firstValue) break;

          if (!initialModel.isTitle)
            titles.subtitle.push(`${initialModel.attribute.name}: ${firstValue.label}`);
          else titles.title.push(`${initialModel.attribute.name}: ${firstValue.label}`);

          break;
        }

        default:
      }

      return titles;
    }, initialValue);

    return {
      title: tempTitles.title.join('. '),
      subtitle: tempTitles.subtitle.join('. '),
    };
  };

  checkIfValueIsEmpty = (value: any): boolean => {
    return value === undefined || value === null || value === '';
  };
}

export default ChecklistsChecklistAttrsService;
