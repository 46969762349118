import styled from 'styled-components';

const Header = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  grid-gap: 12px;

  padding-bottom: 12px;
  border-bottom: 1px solid #e6e9ee;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledStoAttributeToolTipSelector = {
  Header,
  Wrapper,
};

export default StyledStoAttributeToolTipSelector;
