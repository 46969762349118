import { CultureZone, Field } from '../../../../../../../../../api/models/field.model';
import { FIELD_POLYGON_OPTIONS } from '../../../../../utils';
import { arrayToMap } from '../../../../../../../../shared/utils/helpers/map/arrayToMap';
import { mapToArray } from '../../../../../../../../shared/utils/helpers/map/mapToArray';
import {
  BasePolygon,
  FieldPolygon,
} from '../../../../../../../../shared/features/map/utils/MapElements/polygons';
import { IFillCultureLayer } from '../../../../../../../../shared/features/map/models';
import BaseLayerGroup from '../../../../../../../../shared/features/map/utils/MapElements/layerGroups/BaseLayerGroup';

import HolePolygon from './HolePolygon/HolePolygon';
import CulturePolygon from './CulturePolygon/CulturePolygon';
import { calculateHolePolygonsOfField } from './utils';

/**
 * @module CulturesLayerGroup
 * Данная группа слоев используется в режиме создания/редактирования культурных зон
 *
 * Группа состоит из трех типов полигонов.
 * 1. Полигон поля (главный полигон, представляет собой контур всего поля)
 * 2. КЗ-полигоны. Могут быть как пустыми (без заливки), так и с культурой
 * 3. Полигоны-дырки. Это полигоны которые не заняты ни одной культурой
 *
 * Группа имплементит интерфейс который позволяет скрывать/показывать КЗ-полигоны.
 * Управление видимостью КЗ-полигонов {@link LayerFillController осуществляется тут}
 */
class CulturesLayerGroup extends BaseLayerGroup implements IFillCultureLayer {
  private readonly fieldPolygon: FieldPolygon;
  private readonly holePolygons = new Map<number, HolePolygon>();
  private readonly culturePolygons = new Map<number, CulturePolygon>();

  private isCulturePolygonsVisible = true;
  private highlightedPolygon: CulturePolygon | null = null;

  constructor(field: Field) {
    const fieldPolygon = new FieldPolygon(field, FIELD_POLYGON_OPTIONS.display);

    super([fieldPolygon]);

    this.fieldPolygon = fieldPolygon;
    this.culturePolygons = this.renderCulturePolygons(field.cultureZones);

    this.renderHolePolygons();

    setTimeout(() => this.updatePolygonsOrder(), 500);
  }

  public select = () => null;
  public deselect = () => null;

  public getMainPolygon(): FieldPolygon {
    return this.fieldPolygon;
  }

  public getCulturePolygonsList(): CulturePolygon[] {
    return mapToArray(this.culturePolygons);
  }

  public getCulturePolygon(cultureZoneId: string): CulturePolygon {
    return this.getCulturePolygonsList().find(polygon => polygon.dataModel.id === cultureZoneId);
  }

  /**
   * Подсвечивает полигон культурной зоны. (Может быть подсвечен только один полигон)
   */
  public highlightCulturePolygon(cultureZoneId: string) {
    const polygon = this.getCulturePolygon(cultureZoneId);

    this.highlightedPolygon?.toggleHighlight(false);

    polygon?.toggleHighlight(true);
    this.highlightedPolygon = polygon;

    polygon.bringToFront();
  }

  /**
   * Рисует КЗ-полигоны
   */
  public renderCulturePolygons(cultureZoneList: CultureZone[] = []) {
    const polygons = cultureZoneList.map(zone => {
      return new CulturePolygon(zone.geometry, zone, this.isCulturePolygonsVisible);
    });

    this.removePolygons(this.getCulturePolygonsList());
    this.addPolygons(polygons);
    this.updatePolygonsOrder();

    return arrayToMap(polygons, 'id');
  }

  /**
   * Рисует полигоны-дырки
   */
  public renderHolePolygons() {
    const holesPolygons = calculateHolePolygonsOfField(
      this.fieldPolygon,
      this.getCulturePolygonsList()
    );

    this.removePolygons(mapToArray(this.holePolygons));
    this.addPolygons(holesPolygons);
  }

  public showCulturesPolygons() {
    this.getCulturePolygonsList().forEach(polygon => polygon.restoreSavedOptions());
    this.isCulturePolygonsVisible = true;

    this.updatePolygonsOrder();
  }

  public hideCulturesPolygons() {
    this.getCulturePolygonsList().forEach(polygon => polygon.setTransparentStyle());
    this.isCulturePolygonsVisible = false;

    this.updatePolygonsOrder(['field', 'cultures', 'holes']);
  }

  private removePolygons(polygonsList: BasePolygon[]): void {
    polygonsList.forEach(polygon => {
      this.removeLayer(polygon);

      this.holePolygons.delete(polygon?.id);
      this.culturePolygons.delete(polygon?.id);
    });
  }

  private addPolygons(polygonsList: (HolePolygon | CulturePolygon)[]): void {
    polygonsList.forEach(polygon => {
      this.addLayer(polygon);

      if (polygon instanceof HolePolygon) {
        this.holePolygons.set(polygon.id, polygon);
      }

      if (polygon instanceof CulturePolygon) {
        this.culturePolygons.set(polygon.id, polygon);
      }
    });
  }

  /**
   * Выставляет zIndex полигонам согласно переданному order array
   * Первый элемент в списке будет в самом низу
   * Культуры автоматически сортируются по площади - чем больше площадь, тем выше индекс
   * Выделенный КЗ-полигон имеет самый высокий приоритет
   */
  private updatePolygonsOrder(order = ['field', 'cultures', 'holes']): void {
    const tempObj = {
      cultures: this.getCulturePolygonsList().sort(sortByArea),
      field: [this.fieldPolygon],
      holes: this.holePolygons,
    };

    order.forEach(item => {
      tempObj[item]?.forEach(polygon => polygon.bringToFront());
    });

    const selectedPolygon = this.getCulturePolygonsList().find(polygon => polygon.isSelected);

    if (selectedPolygon) {
      selectedPolygon.bringToFront();
    }
  }
}

const sortByArea = (a: CulturePolygon, b: CulturePolygon) => a.getInfo().area - b.getInfo().area;

export default CulturesLayerGroup;
