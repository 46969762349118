import { FC } from 'react';
import { observer } from 'mobx-react';

import { ReactComponent as PlusIcon } from '../../../../../../../shared/static/plus.svg';
import { ReactComponent as MinusIcon } from '../../../../../../../shared/static/minus.svg';
import { useStore } from '../../../../../../utils/IoC';
import { MapCoreController } from '../../../../modules';

import Styled from './Zoom.styles';

const Zoom: FC = () => {
  const mapCoreController = useStore(MapCoreController);

  const handleZoomIn = () => mapCoreController.zoom('in');
  const handleZoomOut = () => mapCoreController.zoom('out');

  return (
    <Styled.Wrapper>
      <Styled.Button onClick={handleZoomIn} data-test-id="map-user-controls-zoom-in-action">
        <PlusIcon />
      </Styled.Button>
      <Styled.Button onClick={handleZoomOut} data-test-id="map-user-controls-zoom-out-action">
        <MinusIcon />
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default observer(Zoom);
