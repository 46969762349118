import { Navigate, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../shared/utils/IoC';
import {
  EStoAccessAction,
  STO_ACCESS_ACTION_LIST,
} from '../../../shared/constants/access-rules-action';
import { ApplicationRoutes } from '../../../routes';
import { OrganizationsStore } from '../../stores/organizations.store';
import { SpinnerLoader } from '../../../shared/components/loaders';

import EStoRoute from './routes/sto.routes';
import { STOListingContainer as StoListing } from './containers';
import { StoContainer } from './modules/Sto';
import { useStoParams } from './hooks';
import { StoBaseController } from './mobx';
import { StoOperation } from './modules/Sto/modules/StoOperations/modules';
import { StoChecklists } from './modules/Sto/modules/StoOperations/modules/StoOperation/modules';
import { StoChecklist } from './modules/Sto/modules/StoOperations/modules/StoOperation/modules/StoChecklists/modules';
import {
  StoPublishModalConfig,
  stoDeleteDraftModalConfig,
  stoDiscardDraftModalConfig,
} from './modules/Sto/modals';
import { StoAttribute } from './modules/Sto/modules/StoOperations/modules/StoOperation/modules/StoChecklists/modules/StoChecklist/modules';

const StoRouter = () => {
  const controller = useStore(StoBaseController);
  const { currentOrganization } = useStore(OrganizationsStore);
  const { orgId } = useStoParams();
  const { registerModalList } = useModal();

  const [hasAccess, setHasAccess] = useState(null);

  useEffect(() => {
    registerModalList(
      [StoPublishModalConfig, stoDiscardDraftModalConfig, stoDeleteDraftModalConfig],
      'sto-action-modals'
    );
  }, []);

  useEffect(() => {
    if (!currentOrganization) {
      setHasAccess(false);
      return;
    }

    controller.fetchStoActionList(orgId).then(data => {
      const stoOrgDisabled = !currentOrganization.isSTOEnabled;
      let noViewActions = true;
      let isViewOnly = true;

      data.forEach(item => {
        if (Boolean(item.available) && (STO_ACCESS_ACTION_LIST as string[]).includes(item.code))
          noViewActions = false;
        if (Boolean(item.available) && item.code === EStoAccessAction.Edit) isViewOnly = false;
      });

      controller.setIsViewOnly(isViewOnly);

      setHasAccess(!(stoOrgDisabled || noViewActions));
    });
  }, [currentOrganization]);

  if (hasAccess === null) {
    return <SpinnerLoader />;
  }

  if (hasAccess === false) {
    return <Navigate to={ApplicationRoutes.PageForbidden} />;
  } else {
    return (
      <Routes>
        <Route path={EStoRoute.Listing} element={<StoListing />} />

        <Route path={EStoRoute.StoItem} element={<StoContainer />} />
        <Route path={EStoRoute.StoItemSummary} element={<StoContainer />} />
        <Route path={EStoRoute.StoItemFieldList} element={<StoContainer />} />
        <Route path={EStoRoute.StoRegistryList} element={<StoContainer />} />
        <Route path={EStoRoute.StoItemOperationList} element={<StoContainer />} />

        <Route path={EStoRoute.StoItemOperation} element={<StoOperation />}>
          <Route path={EStoRoute.StoItemChecklists} element={<StoChecklists />}>
            <Route path={EStoRoute.StoItemChecklist} element={<StoChecklist />}>
              <Route path={EStoRoute.StoItemAttribute} element={<StoAttribute />} />
            </Route>
          </Route>
        </Route>

        <Route path={EStoRoute.StoItemHistory} element={<StoContainer />} />
      </Routes>
    );
  }
};

export default observer(StoRouter);
