import React, { FC, MouseEvent, useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { AutoTooltip, useShowTooltip, useSidebar } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';

import { ISto } from '../../../../../../../../api/models/as-fields/STO/STO.model';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { StoStatusChip as StatusChip } from '../../../../components';
import { useHandlerByScreenResize } from '../../../../../../../shared/hooks/useHanlerByScreenResize';
import { IRequestStatusActions } from '../../../../../../../../api/models/common/request';

import defaultCultureIcon from './assets/icons/defaultCulture.svg';
import Styled from './StoCard.styles';

interface IProps {
  sto: ISto;
  onClick: (id: string) => void;
  onDownloadClick: (
    id: string,
    stoName: string,
    handleLoading: IRequestStatusActions<any>['handleLoading']
  ) => Promise<Blob>;
}

const StoCard: FC<IProps> = ({ sto, onClick, onDownloadClick }) => {
  const { id, name, culture, calculatedStatus, cultureZones, totalCultureZones } = sto;

  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);

  /**
   * В данном состоянии хранится флаг, который убирает сильно длинное название на время,
   * пока не произведен точный расчет ширины карточки.
   */
  const [isContentLoading, setIsContentLoading] = useState(true);
  /**
   * Состояние, в котором хранится новое (рассчитанное) значение ширины карточки.
   */
  const [forcedCardWidth, setForcedCardWidth] = useState<string>('auto');
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { ref: nameRef, isShowTooltip: isShowNameTooltip } = useShowTooltip<HTMLDivElement>();

  const sidebar = useSidebar();

  /**
   * Функция ресайза. Основная ее задача — рассчитать и установить новое значение ширины карточки,
   * согласно измененным размерам родителя.
   */
  const handleResize = useCallback((isNeedDelay?: boolean): void => {
    /**
     * Проверка на то, нужно ли производить расчет ширины карточки.
     * Проще говоря: если символов у названия меньше 25 — расчет не проводим, если больше — проводим.
     * 25 — это значение выявленное "методом тыка".
     */
    if (name?.length < 25) {
      setIsContentLoading(false);
      return;
    }

    setIsContentLoading(true);
    setForcedCardWidth(`auto`);

    if (!isNeedDelay) {
      setForcedCardWidth(`${wrapperRef.current?.clientWidth}px`);
      setIsContentLoading(false);
      return;
    }

    /**
     * Отложенный расчет, так как присутствует анимация сайдбара.
     */
    setTimeout(() => {
      setForcedCardWidth(`${wrapperRef.current?.clientWidth}px`);
      setIsContentLoading(false);
    }, 300);
  }, []);

  /**
   * Обертка над функцией ресайза, нужна она для того, чтобы сократить количество перерасчетов размеров карточки.
   */
  const debounceResize = useRef(
    _.debounce((isNeedDelay?: boolean): void => {
      handleResize(isNeedDelay);
    }, 500)
  );

  /**
   * Вызывает перерасчет размеров карточки, когда изменяется размер экрана.
   */
  useHandlerByScreenResize(() => debounceResize.current(false));

  /**
   * Вызывает перерасчет размеров карточки, когда открывается/закрывается сайдбар.
   */
  useEffect(() => {
    handleResize(true);
  }, [sidebar.expanded]);

  const fieldsDescription = useMemo(() => {
    const cultureZonesNumber = cultureZones.length;

    return `Поля: ${cultureZonesNumber} из ${totalCultureZones}`;
  }, [cultureZones, totalCultureZones]);

  const handleDownloadClick = async (event: MouseEvent<HTMLDivElement>): Promise<void> => {
    event.stopPropagation();

    await onDownloadClick(id, name, setIsDownloadLoading);
  };

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      onClick(id);
    },
    [onClick]
  );

  const getDataTestId = useDataTestIdV2('sto__list__sto-card');

  return (
    <Styled.Wrapper
      onClick={handleClick}
      {...getDataTestId()}
      ref={wrapperRef}
      $width={forcedCardWidth}
    >
      <Styled.Header {...getDataTestId('header')}>
        <AutoTooltip content={name} disabled={!isShowNameTooltip}>
          <Styled.Name ref={nameRef} {...getDataTestId('name')}>
            {isContentLoading ? <Skeleton /> : name}
          </Styled.Name>
        </AutoTooltip>

        <Styled.CultureIcon
          src={culture?.picLink?.downloadUrl ?? defaultCultureIcon}
          alt={culture.name}
          {...getDataTestId('culture-icon')}
        />
      </Styled.Header>

      <Styled.Content {...getDataTestId('content')}>
        <Styled.CultureName {...getDataTestId('culture-name')}>{culture.name}</Styled.CultureName>

        <Styled.FieldsDescription {...getDataTestId('fields')}>
          {fieldsDescription}
        </Styled.FieldsDescription>
      </Styled.Content>

      <Styled.Footer {...getDataTestId('footer')}>
        <StatusChip calculatedStatus={calculatedStatus} />

        {isDownloadLoading ? (
          <Styled.LoadingIcon />
        ) : (
          <Styled.DownloadIcon onClick={handleDownloadClick} {...getDataTestId('download-icon')} />
        )}
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

StoCard.displayName = 'StoCard';

export default observer(StoCard);
