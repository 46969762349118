import { NewDropdown, INewDropdownSelectOption } from '@farmlink/farmik-ui';
import { FC, useMemo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { FieldsViewStore as Store } from '../../../../../../mobx';
import { EFieldInExp } from '../../../../../../../../utils/filters/FieldInExp.filter';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import FilterSectionHeader from '../../components/SectionHeader/SectionHeader';

import Styled from './FieldInExpFilter.styles';

const FieldInExpFilterSection: FC = () => {
  // Сторы
  const store = useStore(Store);

  const getDataTestId = useDataTestIdV2('field-in-exp-filter');

  const filter = store.filterManager.filters.getOne('inExp');

  const optionList: INewDropdownSelectOption[] = [
    {
      value: EFieldInExp.ALL,
      label: 'Показывать все',
    },
    {
      value: EFieldInExp.IN_EXP,
      label: 'Только в опыте',
    },
    {
      value: EFieldInExp.NOT_IN_EXP,
      label: 'Только не в опыте',
    },
  ];

  const defaultValue = useMemo(() => {
    return optionList.find(option => option.value === filter.value);
  }, [filter.value]);

  const handleChange = (value: EFieldInExp) => {
    filter.setValue(value);
  };

  return (
    <Styled.Wrapper>
      <FilterSectionHeader title={'Опыты'} showResetBtn={false} getDataTestId={getDataTestId} />

      <NewDropdown
        config={{
          field: {
            defaultValue,
            onChange: handleChange,
          },
          body: {
            optionList,
            isKeepSelectedOptions: false,
          },
        }}
      />
    </Styled.Wrapper>
  );
};

export default FieldInExpFilterSection;
