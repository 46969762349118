import { isNil } from 'lodash';

import {
  EStoAction,
  EStoStatus,
  ICreateSto,
  IStoContent,
  IUpdateSto,
} from '../../../../../../../../api/models/as-fields/STO/STO.model';
import { ERequestStatus } from '../../../../../../../shared/constants/requests';
import { StoService } from '../../../../../../../shared/mobx/services/as-fields';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { TypeApiRequest } from '../../../../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../../stores/seasons.store';
import { EStoTab, IInitiateStoOptions, IInitiateStoResult } from '../../models/StoCommon';
import { StoFieldsTabStore } from '../../modules/FieldsTab/mobx/stores';
import { StoActionService, StoOptionService, StoTabService } from '../services';
import { StoStore } from '../stores';
import StoErrorService from '../services/StoError.service';

@provide.transient()
class StoController {
  @lazyInject(StoStore)
  protected store: StoStore;

  @lazyInject(StoTabService)
  protected stoTabService: StoTabService;

  @lazyInject(StoService)
  protected stoService: StoService;

  @lazyInject(StoFieldsTabStore)
  protected stoFieldsTabStore: StoFieldsTabStore;

  @lazyInject(StoOptionService)
  protected optionService: StoOptionService;

  @lazyInject(StoActionService)
  protected actionService: StoActionService;

  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(StoErrorService)
  protected errorService: StoErrorService;

  initiateSto = async (options: IInitiateStoOptions): Promise<IInitiateStoResult> => {
    try {
      if (options.isCreation) {
        const tab = this.stoTabService.calculateStoItemTab(options.isCreation);

        this.store.setIsCreateMode(true);

        this.stoTabService.calculateStoItemTabConfigByParams(tab, options.isCreation);

        this.optionService.fetchCultureList();

        return Promise.resolve({ redirectTab: tab });
      }

      if (options.stoId) {
        const stoData = await this.fetchSto(options.stoId, true, null, options?.versionId);

        const isViewOnly =
          stoData.status === EStoStatus.Inactive ||
          !stoData.availableActions?.includes(EStoAction.StoEdit);

        const tab = this.stoTabService.calculateStoItemTab(false, options?.tabFromUrl);

        this.stoTabService.calculateStoItemTabConfigByParams(tab);

        this.checkEditStoUserInputValidity();

        return Promise.resolve({ redirectTab: tab, isViewOnly });
      }
    } catch (error) {
      throw this.errorService.parseStoFetchingErrorToCode(error);
    }
  };

  /**
   * Функция вычисления текущего активного таба
   * @returns ESTOItemTab
   */
  calculateCurrentTab = (): EStoTab => {
    return this.stoTabService.calculateStoItemTab();
  };

  updateStoCreationData = (data: Partial<ICreateSto>) => {
    this.store.updateCreateStoData(data);
    this.store.setHasChanges(true);

    this.checkCreationStoUserInputValidity();
  };

  updateStoEditData = (data: Partial<IUpdateSto>) => {
    this.store.setUpdatedSto(data);
    this.store.setHasChanges(true);

    this.checkEditStoUserInputValidity();
  };

  public fetchSto = (
    stoId: string,
    includeDrafts?: boolean,
    asOnDate?: string,
    versionId?: string
  ): Promise<IStoContent> => {
    this.store.setRequestStoStatus(ERequestStatus.Pending);

    const data = this.stoService
      .getSto({ id: stoId, includeDrafts, asOnDate, versionId })
      .then(sto => {
        this.store.setSelectedSto(sto);

        this.store.setRequestStoStatus(ERequestStatus.Fulfilled);

        return sto;
      })
      .catch(e => {
        this.store.setRequestStoStatus(ERequestStatus.Rejected);
        throw e;
      });

    return data;
  };

  changeStoTab = (tab: EStoTab) => {
    this.stoTabService.calculateStoItemTabConfigByParams(tab);

    this.store.setStoTab(tab);
  };

  checkCreationStoUserInputValidity = () => {
    const data = this.store.createStoData;

    const trimmedName = (data?.name ?? '').trim();
    const isNameValid = Boolean(trimmedName) && trimmedName.length <= 50;

    const isCultureValid = Boolean(data?.cultureId?.length);

    this.store.setIsCreateStoFormValid(isNameValid && isCultureValid);
  };

  checkEditStoUserInputValidity = () => {
    // Если изменения ещё не вносились - валидируем стандартную модель СТО
    const data = this.store.hasChanges ? this.store.updatedStoData : this.store.selectedSto;

    const trimmedName = (data?.name ?? '').trim();
    const isNameValid = Boolean(trimmedName) && trimmedName.length <= 50;

    this.store.setIsEditStoFormValid(isNameValid);
  };

  updateCreateStoName = (name: string) => {
    this.updateStoCreationData({ name });
  };

  updateCrateStoCulture = (cultureId: string) => {
    this.updateStoCreationData({ cultureId });
  };

  createSto = () => {
    return this.stoService
      .createSto({
        cultureId: this.store.createStoData.cultureId,
        name: this.store.createStoData.name.trim(),
        isDefault: false,
        organizationId: this.organizationsStore?.currentOrganization.organizationId,
        seasonYear: Number(this.seasonsStore?.selectedSeason),
      })
      .then(sto => {
        this.store.setHasChanges(false);
        this.store.clearCreationMode();

        this.store.setSelectedSto(sto);

        return sto;
      });
  };

  updateSto = async ({
    operationsSuccessHandler,
    registriesSuccessHandler,
  }: {
    operationsSuccessHandler?: () => void;
    registriesSuccessHandler?: () => Promise<void>;
  }) => {
    const payload: TypeApiRequest<'updateSto'> = {
      id: this.store.selectedSto.id,
      ...this.store.updatedStoData,
    };

    let isFieldsAdded = false;
    let isFieldsRemoved = false;

    if (this.store.StoTab === EStoTab.Fields) {
      payload.cultureZones = Array.from(this.stoFieldsTabStore.selectedFieldIdSet.values());

      if (this.stoFieldsTabStore.transferredFiledIdSet.size > 0) {
        payload.transferCultureZones = Array.from(
          this.stoFieldsTabStore.transferredFiledIdSet.values()
        );
      }

      isFieldsAdded =
        this.store.selectedSto?.cultureZones?.length === 0 &&
        (payload?.cultureZones?.length > 0 || payload?.transferCultureZones?.length > 0);

      isFieldsRemoved =
        this.store.selectedSto?.cultureZones?.length > 0 && !payload?.cultureZones?.length;
    }

    if (this.store.StoTab === EStoTab.Operations) {
      operationsSuccessHandler();
    }

    if (this.store.StoTab === EStoTab.Registries) {
      await registriesSuccessHandler();
    }

    return this.stoService
      .updateSto(payload)
      .then(sto => {
        const tabFrom = this.store.StoTab;

        this.store.setHasChanges(false);

        this.store.setSelectedSto(sto);
        this.store.clearUpdatedSto();

        let tab = this.store.StoTab;

        if (isFieldsAdded || isFieldsRemoved) {
          tab = this.stoTabService.calculateStoItemTab();

          this.stoTabService.calculateStoItemTabConfigByParams(tab);
        }

        this.store.setIsViewOnly(!sto.availableActions?.includes(EStoAction.StoEdit));

        return { sto, tab, tabFrom };
      })
      .catch(e => {
        throw e;
      });
  };

  updateStoName = (name: string) => {
    this.updateStoEditData({ name });
  };

  publishSto = (comment: string) => {
    return this.actionService.publishSto(comment);
  };

  activateSto = async () => {
    const response = await this.actionService.activateSto();

    return response;
  };

  deactivateSto = () => {
    return this.actionService.deactivateSto();
  };

  discardDraft = (stoId?: string) => {
    return this.actionService.discardDraft(stoId);
  };

  deleteChanges = (stoId?: string) => {
    return this.actionService.discardDraft(stoId);
  };

  clearEditOnLeaveSummaryTabTab = () => {
    this.store.clearUpdatedSto();
    this.store.clearIsEditStoFromValid();

    this.checkEditStoUserInputValidity();
  };
}

export default StoController;
