import styled from 'styled-components';
import { Button } from '@farmlink/farmik-ui';

const Wrapper = styled(Button)`
  width: 426px;
  height: 40px;

  border-radius: 12px;
  padding: 12px 16px;
`;

const StyledChecklistsButton = {
  Wrapper,
};

export default StyledChecklistsButton;
