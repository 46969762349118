import { cloneDeep } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { TFilterManagerEvents as TEvents } from '../interfaces/FilterManagerEvents.interface';
import TypedEventEmitter from '../../../utils/helpers/TypedEventEmitter/TypedEventEmitter';
import { TFilterListTriggers, TUpdateMeta } from '../interfaces/Filters.interface';

import Filter from './Filter';
import FiltersStorage from './FiltersStorage';
import TempEditor from './TempEditor';
import Sorter from './Sorter';

/**
 * Главный класс фильтрации и сортировки массива.
 * Содержит все фильтры, изначальный массив и отфильтрованный массив.
 */
class FiltersManager<FILTERS extends Filter[] = any, ELEM = unknown, SORT = unknown> {
  public readonly tempEditor = new TempEditor<FILTERS, ELEM, SORT>(this);

  public readonly emitter = new TypedEventEmitter<TEvents<ELEM>>();

  public readonly filters = new FiltersStorage<FILTERS>();

  public readonly sorter: Sorter<SORT> | null = null;

  private _sourceList: ReadonlyArray<ELEM> = [];
  private _filteredList: Array<ELEM> = [];

  constructor(sourceList: Array<ELEM>, filters: FILTERS, sorter?: Sorter<SORT>) {
    makeAutoObservable(this);

    this._sourceList = cloneDeep(sourceList ?? []);
    this.filters.setAll(filters, this);

    if (sorter) {
      this.sorter = sorter;
      this.sorter._setManager(this);
    }

    if (sourceList.length) {
      this.update(null);
    }
  }

  public get filteredList() {
    return this._filteredList;
  }

  public get sourceList() {
    return this._sourceList;
  }

  /**
   * Главная функция фильтрации. Вызывается автоматически после присвоения нового значения в любой из фильтров
   * Обновляет все фильтры и фильтрует sourceList
   */
  public update(meta: TUpdateMeta = { triggeredBy: 'manual' }) {
    this.filters.updateAll(meta);
    this.filterSourceList(meta.triggeredBy);
  }

  /**
   * Сбрасывает все значения фильтров до дефолтных, обновляет их и фильтрует sourceList
   */
  public reset() {
    this.filters.resetAll(true);
    this.sorter?.resetValue?.(true);
    this.update({ triggeredBy: 'resetFn' });
  }

  /**
   * Обновляет source массив и применяет для него фильтрацию.
   * Необходимо вызывать в случаях когда обновился sourceList
   */
  public updateSourceList(sourceList: Array<ELEM>) {
    this._sourceList = cloneDeep(sourceList ?? []);
    this.filters.getAllList().forEach(filter => filter._initState?.());
    this.update({ triggeredBy: 'updateSourceListFn' });
  }

  /**
   * Фильтрует sourceList при помощи зарегистрированного списка фильтров.
   * Если у фильтра пустое значение то он пропускается. Если фильтров нет то возвращается sourceList
   *
   * @param skipFilters - список id {@link Filter фильтров} которые необходимо пропустить в процессе фильтрации
   * @param sourceList - опциональный список который будет фильтроваться. По дефолту это sourceList из конструктора. Но можно передать свой
   * @return Возвращает отфильтрованный список. !!!Не устанавливает этот список в переменную
   * @see filterSourceList
   */
  public getFilteredSourceList(skipFilters: Array<string> = [], sourceList?: Array<ELEM>): ELEM[] {
    const activeFilters = this.filters.getActiveList(skipFilters);
    // Возможно стоит делать глубокую копию. При глубокой копии время фильтрации * ~х50
    const shallowSourceList = [...(sourceList ?? this.sourceList)];

    if (!activeFilters.length) {
      return shallowSourceList;
    }

    return shallowSourceList.filter(el => {
      return activeFilters.every(filter => filter._getFilterRule(el));
    });
  }

  /**
   * Обновляет filteredList переменную при помощи функции getFilteredSourceList. Тригерит событие фильтрации.
   * @see getFilteredSourceList
   */
  public filterSourceList(triggeredBy: TFilterListTriggers) {
    const newFilteredList = this.getFilteredSourceList().sort(this.sorter?._compareFn);

    // Обновляем список временного редактора если он включен
    if (this.tempEditor.enabled) {
      this.tempEditor.filteredList = newFilteredList;
      return;
    }

    this._filteredList = newFilteredList;

    this.emitter.emit('changeFilteredList', this._filteredList, triggeredBy);
  }
}

export default FiltersManager;
