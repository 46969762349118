import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Switcher } from '@farmlink/farmik-ui';

import { TChecklistsBooleanAttrToDraw as TBooleanAttrToDraw } from '../../../../models';
import { ChecklistsAttr as Attribute } from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsStore } from '../../../../mobx/stores';
import { EChecklistMode } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistsAttrErrorList as useAttrErrorList,
  useChecklistsAttrVisibility as useAttrVisibility,
} from '../../../../hooks';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './ChecklistsBooleanAttr.styles';

interface IProps {
  attrToDraw: TBooleanAttrToDraw;
}

const ChecklistsBooleanAttr: FC<IProps> = ({ attrToDraw }) => {
  const getDataTestId = useDataTestIdV2('checklists__boolean-attribute');

  const { groupId, initialModel, value, dependentFileAttrId } = attrToDraw;

  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const isShowAttribute = useAttrVisibility(attrToDraw);
  const errorList = useAttrErrorList(attrToDraw);

  const handleChange = useCallback((newValue: boolean): void => {
    checklistsController.changeAttrValue(EAttrType.Boolean, groupId, {
      ...value,
      booleanValue: newValue,
    });
  }, []);

  return (
    <>
      {isShowAttribute ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          dataTestId={getDataTestId()['data-test-id']}
        >
          <Styled.Wrapper {...getDataTestId('wrapper')} $isActive={value.booleanValue}>
            <Styled.Label {...getDataTestId('label')}>{initialModel.attribute?.name}</Styled.Label>

            <Switcher
              value={value.booleanValue}
              onChange={handleChange}
              isBlocked={checklistsStore.mode === EChecklistMode.View}
              dataTestId={getDataTestId('switcher')['data-test-id']}
            />
          </Styled.Wrapper>

          <Styled.Container {...getDataTestId('container')}>
            <FileAttrContainer groupId={groupId} attrId={dependentFileAttrId} />

            <InputFieldError error={{ errorList }} />
          </Styled.Container>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsBooleanAttr.displayName = 'ChecklistsBooleanAttr';

export default observer(ChecklistsBooleanAttr);
