import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';

import { TablePlugBuilder } from '../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { CREATE_SEASON_MODAL_ID } from '../../../../../seasons/modals/CreateSeasonModal/CreateSeasonModal.config';

import Styled from './StoListNoSeasonPlug.styled';

const StoListNoSeasonPlug: FC = () => {
  const { openModalByModalId } = useModal();

  const handleButtonClick = useCallback(() => {
    openModalByModalId(CREATE_SEASON_MODAL_ID);
  }, []);

  const getDataTestId = useDataTestIdV2('sto__list__no-season-plug');

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'calendar' },
        content: {
          title: 'Для работы с разделом добавьте сезон',
        },
        footer: {
          renderButton: () => (
            <Styled.Button
              color={'primary'}
              onClick={handleButtonClick}
              alignment={'center'}
              dataTestId={getDataTestId('add-season-btn')['data-test-id']}
            >
              Добавить сезон
            </Styled.Button>
          ),
        },
      }}
      dataTestId={getDataTestId()['data-test-id']}
    />
  );
};

StoListNoSeasonPlug.displayName = 'StoListNoSeasonPlug';

export default observer(StoListNoSeasonPlug);
