import { EFieldsUploadErrorType } from '../../../../modules/dashboard/modules/fields/modules/FieldsImport/utils/constants';
import { TypeApiRoute } from '../../../models/type.api.request';

type TImportFieldsFromShapeReq = {
  seasonYear: number;
  files: FormData;
  organizationId?: string;
};

type TImportFieldsFromShapeRes = {
  token: 'string';
  errorType?: EFieldsUploadErrorType;
  errorDescription?: 'string';
};

const importFieldsFromShape: TypeApiRoute & {
  request: TImportFieldsFromShapeReq;
  response: TImportFieldsFromShapeRes;
} = {
  url: () => '/api/as-fields/import/shp',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  request: {} as TImportFieldsFromShapeReq,
  response: {} as TImportFieldsFromShapeRes,
};

export type { TImportFieldsFromShapeReq, TImportFieldsFromShapeRes };

export { importFieldsFromShape };
