import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { FieldsStore } from '../../../../../shared/mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationField } from '../../../../../../api/models/operations/operation.field.model';
import { toFixedWithCeilBackEnd } from '../../../../../shared/utils/toFixedWithCeil';
import { TasksController } from '../../controllers/tasks.controller';
import { useOperationsParams } from '../../hooks';
import { useOperationsParamsActions } from '../../hooks/useOperationsParamsActions';

import { Field, FieldImg, FieldName, FieldTasks, FieldArea, FieldInfo } from './style';

type Props = {
  field: OperationField;
  isActive: boolean;
};

export const OperationFieldsListItem: FC<Props> = observer(({ field, isActive }) => {
  const fieldsStore = useStore(FieldsStore);
  const tasksController = useStore(TasksController);

  const params = useOperationsParams();
  const paramsActions = useOperationsParamsActions();

  const handleFieldClick = async (fieldId: string) => {
    if (params.fieldId === fieldId) paramsActions.selectField(null);
    else paramsActions.selectField(fieldId);

    tasksController.clear();
    tasksController.resetPageSettings();

    await tasksController.fetchTasks({
      organizationId: params.orgId,
      seassonCultureId: params.cultureId,
      operationId: params.operationId,
      fieldId: params.fieldId === fieldId ? undefined : fieldId,
      noCulture: params.cultureId === 'emptyCulture' ? true : undefined,
    });
  };

  const selectedField = useMemo(() => {
    return fieldsStore.getFieldById(field?.fieldId);
  }, [field?.fieldId]);

  return (
    <Field
      onClick={handleFieldClick.bind(this, field.fieldId)}
      data-test-id={'operation-field-item'}
      isActive={isActive}
    >
      <FieldImg src={selectedField?.icon?.downloadUrl} data-test-id={'operation-field-item-img'} />
      <FieldInfo>
        <FieldName data-test-id={'operation-field-item-name'}>{field.fieldName}</FieldName>
        <FieldTasks data-test-id={'operation-field-item-tasks'}>{field.tasks} задач</FieldTasks>
      </FieldInfo>
      <FieldArea data-test-id={'operation-field-item-area'}>
        {`${selectedField?.area ? toFixedWithCeilBackEnd(selectedField?.area) : '0'} га`}
      </FieldArea>
    </Field>
  );
});
