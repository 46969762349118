import L, { LatLng } from 'leaflet';

interface IMapOptions extends Omit<L.MapOptions, 'center'> {
  center: LatLng;
}

const defaultMapOptions: IMapOptions = {
  // Москва. Кремль
  center: new LatLng(55.751244, 37.618423),
  // Базовое значение зума
  zoom: 19,
  zoomControl: false,
  minZoom: 3,
  maxZoom: 20,
  zoomSnap: 0.1,
};

export default defaultMapOptions;
