import { FC, memo } from 'react';

import totalAreaSvg from '../../../assets/totalArea.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  area: string;
  dataTestId: string;
}
const TaskOperationCreateVerificationTotalArea: FC<IProps> = ({ area, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__total-area`);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label color={'generalDarkHover'} {...getDataTestId('label')}>
        Общая площадь
      </Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={totalAreaSvg} />

      <Styled.Info {...getDataTestId('info')} data-test-id={'task-assignee'}>
        {area} га
      </Styled.Info>
    </Styled.InfoBlock>
  );
};

TaskOperationCreateVerificationTotalArea.displayName = 'TaskOperationCreateVerificationTotalArea';

export default memo(TaskOperationCreateVerificationTotalArea);
