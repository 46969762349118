import React, { CSSProperties, FC, memo, ReactElement } from 'react';
import { CSSObject } from 'styled-components';

import { useDataTestIdV2 } from '../../../../../shared/features/utils/hooks/locators';

import ListCardTextWrapper from './components/TextWrapper/TextWrapper';
import Styled from './ListCard.styles';

interface IProps {
  title: string;
  subtitle: string;
  isSelected: boolean;

  key?: string;
  leftBlock?: () => ReactElement;
  rightBlock?: () => ReactElement;
  wrapperStyles?: CSSObject;
  // Нативные стили для virtualizedList
  style?: CSSProperties;
  onClick?: () => void;
  dataTestId?: string;
}

const ListCard: FC<IProps> = props => {
  const getDataTestId = useDataTestIdV2(props.dataTestId ?? 'field-list-card');

  return (
    <Styled.Wrapper
      key={props.key ?? 'list-card-wrapper'}
      active={props.isSelected}
      $styles={props.wrapperStyles}
      style={props.style}
      onClick={props.onClick}
    >
      {props.leftBlock?.()}

      <ListCardTextWrapper
        title={props.title}
        subtitle={props.subtitle}
        getDataTestId={getDataTestId}
      />

      {props.rightBlock?.()}
    </Styled.Wrapper>
  );
};

export default memo(ListCard);
