import { lazyInject, provide } from '../../../../utils/IoC';
import FilterManager from '../../core/FilterManager';
import Store from '../stores/Filter.store';
import Filter from '../../core/Filter';

@provide.transient()
class FilterController {
  @lazyInject(Store)
  private store: Store;

  /**
   * Создаёт фильтр-менеджера. Менеджеров может быть много, каждый из них представляет отдельный класс для управления фильтрами и переданным массивом
   *
   * @param id - уникальный id менеджера, испольуется чтобы достать фильтр из стора
   */
  public createManager<F extends Filter[], A, S>(
    id: string,
    ...args: ConstructorParameters<typeof FilterManager<F, A, S>>
  ) {
    const instance = new FilterManager<F, A, S>(...args);

    this.store.setManager(id, instance);
  }

  /**
   * Проверяет создан ли фильтр-менеджер
   */
  public isExist(id: string) {
    return Boolean(this.store.getManager(id));
  }
}

export default FilterController;
