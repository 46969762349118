import { FC } from 'react';

import Styled from './CreateZonePopup.style';

import './leaflet-popup.css';

interface IProps {
  onAccept: () => void;
  onDeny: () => void;
}

export const CreateZonePopup: FC<IProps> = ({ onAccept, onDeny }) => {
  return (
    <Styled.Wrapper>
      <Styled.Description>Добавить культуру на этот участок?</Styled.Description>

      <Styled.ActionsWrapper>
        <Styled.Action colorScheme={'deny'} onClick={onDeny}>
          <p>Нет</p>
        </Styled.Action>

        <Styled.Action colorScheme={'accent'} onClick={onAccept}>
          <p>Да</p>
        </Styled.Action>
      </Styled.ActionsWrapper>

      <Styled.Tail />
    </Styled.Wrapper>
  );
};
