import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  padding: '8px 12px',
  borderBottom: `1px solid ${Colors.technicalBorderGrey}`,
});

const Styles = {
  Wrapper,
};

export default Styles;
