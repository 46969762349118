import { PolylineOptions } from 'leaflet';

import { IDrawChecklistInstance } from '../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { CultureZone, Field } from '../../../../../../../../../api/models/field.model';
import {
  IMapMarkerElement as IMarkerElement,
  IMapPolygonElementConfig as TPolygonElementConfig,
} from '../../../../../../../../shared/features/map/models';

interface ICreateZoneConfigOptions {
  style?: PolylineOptions;
}

function createZoneConfig<E extends CultureZone | Field>(
  zone: E,
  options?: ICreateZoneConfigOptions
): TPolygonElementConfig<E> {
  return {
    element: zone,
    geometry: zone.geometry,
    options: {
      style: options?.style,
    },
  };
}

function getPointElementListToChangeStyle(
  pointElementList: IMarkerElement[],
  drawPointInstanceList: IDrawChecklistInstance[]
): IMarkerElement<IDrawChecklistInstance>[] {
  return pointElementList.reduce<IMarkerElement<IDrawChecklistInstance>[]>(
    (list, { element, marker }) => {
      const changedPoint = drawPointInstanceList.find(({ id }) => id === element.id);

      if (changedPoint) {
        const hasChangedNumber = element.positionNumber !== changedPoint.positionNumber;

        if (hasChangedNumber) {
          list.push({ marker, element: changedPoint });
        }
      }

      return list;
    },
    []
  );
}

const InspectionPointsElementHelpers = {
  createZoneConfig,
  getPointElementListToChangeStyle,
};

export default InspectionPointsElementHelpers;
