import styled, { css } from 'styled-components';
import { Typography } from '@farmlink/farmik-ui';

import { Colors } from '../../../../../../../../shared/constans/colors';
import { ETaskStatus } from '../../../../../../../../../api/models/as-fields/task/task.model';

const getWrapperColors = (taskStatus: ETaskStatus, daysLeft: number): Colors[] => {
  const pinkColors = [Colors.lightPink, Colors.pink];

  switch (taskStatus) {
    case ETaskStatus.New: {
      return daysLeft < 0 ? pinkColors : [Colors.lightBlue, Colors.sky];
    }
    case ETaskStatus.InWork: {
      return daysLeft < 0 ? pinkColors : [Colors.lightGreen, Colors.green];
    }
    case ETaskStatus.Completed:
      return [Colors.gray, Colors.black];
    case ETaskStatus.Canceled:
      return pinkColors;
    default:
      return pinkColors;
  }
};

interface IWrapperProps {
  $taskStatus: ETaskStatus;
  $daysLeft: number;
}

const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  min-height: 52px;
  display: flex;
  align-items: center;
  padding: 16px 5px 16px 24px;
  width: 100%;
  border-radius: 12px;
  ${({ $taskStatus, $daysLeft }) => {
    const [backgroundColor] = getWrapperColors($taskStatus, $daysLeft);

    return `
      background-color: ${backgroundColor};
    `;
  }}

  ${({ $taskStatus }) =>
    $taskStatus === ETaskStatus.Canceled &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}

  &:before {
    content: '';
    height: calc(100% - 10px);
    width: 4px;
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 10px 0px 0px 10px;
    ${({ $taskStatus, $daysLeft }) => {
      const [_, borderColor] = getWrapperColors($taskStatus, $daysLeft);

      return `
      background-color: ${borderColor};
    `;
    }}
  }
`;

const BoldText = styled(Typography)`
  margin-right: 4px;
`;

const TooltipWrapper = styled.div`
  position: absolute;
  right: 0;

  & .task-status-data-chip {
    background: transparent;
    svg {
      fill: ${Colors.generalBlack};
    }
  }
`;

const TooltipContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 224px;
`;

const StyledTasksDrawerStatusBlock = {
  Wrapper,
  BoldText,
  TooltipWrapper,
  TooltipContentRow,
  TooltipContent,
};

export default StyledTasksDrawerStatusBlock;
