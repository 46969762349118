import React, { FC, useState } from 'react';

import { ReactComponent as LegendIcon } from './assets/legend-icon.svg';
import Styled from './Legend.styles';

const StaticLegendColors = [
  '#C03333',
  '#FF3333',
  '#FE7C33',
  '#FFCC03',
  '#EDEA48',
  '#FFE41C',
  '#FEF913',
  '#E9F96A',
  '#C0F873',
  '#A8E74B',
  '#8FD93C',
  '#7FC23A',
  '#6AAE38',
  '#668E25',
  '#407520',
  '#2A5F17',
];

const WeatherColors = [
  { label: 'Снег', color: '#DEDEDE' },
  { label: 'Облака', color: '#B2BAB6' },
  { label: 'Вода', color: '#1E88E5' },
  { label: 'Земля', color: '#603D20' },
];

const IndicesLegend: FC = () => {
  const [open, setOpen] = useState(true);

  if (!open) {
    return (
      <Styled.IconButton onClick={() => setOpen(true)}>
        <LegendIcon />
      </Styled.IconButton>
    );
  }

  return (
    <Styled.LegendWrapper data-test-id="static-legend-wrapper" onClick={() => setOpen(false)}>
      <Styled.SetWrapper>
        <Styled.ColorBlocksWrapper>
          {StaticLegendColors.map(color => (
            <Styled.ColorBlock key={color} color={color} />
          ))}
        </Styled.ColorBlocksWrapper>

        <Styled.LabelsBlock>
          <Styled.Label>Низкая</Styled.Label>
          <Styled.Label>Средняя</Styled.Label>
          <Styled.Label>Высокая</Styled.Label>
        </Styled.LabelsBlock>
      </Styled.SetWrapper>

      <Styled.SetWrapper>
        <Styled.WeatherColorsWrapper>
          {WeatherColors.map(({ color }) => (
            <Styled.ColorBlock key={color} color={color} />
          ))}
        </Styled.WeatherColorsWrapper>

        <Styled.LabelsBlock>
          {WeatherColors.map(({ label }) => (
            <Styled.Label key={label}>{label}</Styled.Label>
          ))}
        </Styled.LabelsBlock>
      </Styled.SetWrapper>
    </Styled.LegendWrapper>
  );
};

export default IndicesLegend;
