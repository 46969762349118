import { FC, memo } from 'react';

import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { useDataTestId } from '../../../../../../../../shared/features/utils/hooks/locators';
import { OperationTaskTypeIcon } from '../../../../../../../../shared/components/OperationTaskTypeIcon';

import Styled from './TaskListOperationTaskTypeCell.styles';

interface IProps {
  row: ITask;
}

const TaskListOperationTaskTypeCell: FC<IProps> = ({ row }) => {
  const getDataTestId = useDataTestId('tasks-table-operation-task-type-cell');
  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <OperationTaskTypeIcon dataTestId={getDataTestId()['data-test-id']} taskType={row?.type} />
    </Styled.Wrapper>
  );
};

TaskListOperationTaskTypeCell.displayName = 'TaskListOperationTaskTypeCell';

export default memo(TaskListOperationTaskTypeCell);
