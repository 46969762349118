import { Button, ENotificationType, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';

import { useStore } from '../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoChecklistsCoreStore as StoChecklistsStore } from '../../../../mobx/stores';
import { StoChecklistsCoreController as StoChecklistsController } from '../../../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../hooks';
import getNotificationScheme, {
  ENotificationScheme,
} from '../../../../../../../../../../../../../../../shared/utils/helpers/getNotificationScheme/getNotificationScheme';
import { StoAttributeCoreController as AttributeController } from '../../modules/StoAttribute/mobx/controllers';
import { StoOperationCoreController as OperationController } from '../../../../../../mobx/controllers';

import Styled from './StoChecklistControlButtons.styles';

const StoChecklistControlButtons: FC = () => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const checklistsStore = useStore(StoChecklistsStore);

  const operationController = useStore(OperationController);
  const checklistsController = useStore(StoChecklistsController);
  const attributeController = useStore(AttributeController);

  const params = useStoParams();
  const notificator = useNotificator();

  const handleButtonClick = () => {
    const handleSuccess = (): void => {
      notificator.setNotification(
        getNotificationScheme(
          ENotificationScheme.Dark,
          'Настройки параметров сохранились. Не забудьте опубликовать изменения',
          { type: ENotificationType.Success }
        )
      );

      attributeController.clearStore();
      attributeController.clearAlreadyChangedAttrIds();
      operationController.fetchSto(params.stoId, params.versionId);
    };

    checklistsController.saveStoChecklistsAttrsTables({
      stoId: params.stoId,
      operationId: params.operationId,
      successHandler: handleSuccess,
      loadingHandler: setIsSaving,
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.ButtonWrapper $isHidden={Boolean(params.versionId)}>
        <Button
          color={'primary'}
          type={'button'}
          onClick={handleButtonClick}
          disabled={checklistsStore.isLoading || isSaving || !checklistsStore.hasChanges}
        >
          Сохранить
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

StoChecklistControlButtons.displayName = 'StoChecklistControlButtons';

export default observer(StoChecklistControlButtons);
