import styled from 'styled-components';

const TaskTypeIcon = styled.img<{ $iconSize?: string; $margin?: string }>`
  width: ${({ $iconSize }) => $iconSize || '20px'};
  height: ${({ $iconSize }) => $iconSize || '20px'};
  margin: ${({ $margin }) => $margin || '0 16px 0 0'};
`;

const StyledTaskTypeIcon = {
  TaskTypeIcon,
};

export default StyledTaskTypeIcon;
