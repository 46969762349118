import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';
import { OperationField } from '../../../../../../api/models/operations/operation.field.model';
import { TGetOperationListReq } from '../../../../../../api/endpoints/operations/operations.get';
import { Operation } from '../../../../../../api/models/operations/operation.model';
import { IRequestStatusActions } from '../../../../../../api/models/common/request';

@provide.singleton()
class OperationsService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  getOperationList = async (payload: TGetOperationListReq): Promise<Operation[]> => {
    const { getOperations } = this.axiosService.api;

    try {
      const { content } = await getOperations(payload);

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getFieldList = async (operationId: string): Promise<OperationField[]> => {
    const { getOperationFields } = this.axiosService.api;

    try {
      const fieldList = await getOperationFields({ operationId });

      return fieldList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getOperation = async (
    payload: TypeApiRequest<'getOperation'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getOperation'>>;
    }
  ): Promise<TypeApiResponse<'getOperation'>> => {
    const { getOperation } = this.axiosService.api;

    try {
      config?.actions?.handleLoading?.(true);

      const response = await getOperation(payload, { omit: ['id'] });

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };
}

export default OperationsService;
