import { useParams, useSearchParams } from 'react-router-dom';

import { IOperationsParams } from '../../interfaces';

const useOperationsParams = (): IOperationsParams => {
  const params = useParams<keyof IOperationsParams>();
  const [searchParams] = useSearchParams();

  const cultureId = searchParams.get('cultureId');
  const operationId = searchParams.get('operationId');
  const fieldId = searchParams.get('fieldId');

  return { ...params, cultureId, operationId, fieldId };
};

export default useOperationsParams;
