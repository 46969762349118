import { Combobox } from '@headlessui/react/dist';
import React, { FC } from 'react';

import { useDataTestIdV2 } from '../../../../features/utils/hooks/locators';
import Styled from '../../SearchInput.styles';

interface IProps {
  list: string[];
  textHighlighter: (option: string) => string;
  onOptionClick: (option: string) => void;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const OptionsList: FC<IProps> = props => {
  if (!props.list.length) {
    return (
      <Combobox.Options as={Styled.Body} static {...props.getDataTestId('options-list')}>
        <Combobox.Option as={Styled.Option} value={'К сожалению, мы ничего не смогли найти'}>
          К сожалению, мы ничего не смогли найти
        </Combobox.Option>
      </Combobox.Options>
    );
  }

  return (
    <Combobox.Options as={Styled.Body} static {...props.getDataTestId('options-list')}>
      {props.list.map((option, index) => (
        <Combobox.Option
          as={Styled.Option}
          key={`${option}-${index}`}
          value={option}
          dangerouslySetInnerHTML={{ __html: props.textHighlighter(option) }}
          onClick={() => props.onOptionClick(option)}
          {...props.getDataTestId(`option-${index}`)}
        />
      ))}
    </Combobox.Options>
  );
};

export default OptionsList;
