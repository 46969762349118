import turfUnion from '@turf/union';
import { truncate as turfTruncate } from '@turf/turf';
import { Feature, Polygon, MultiPolygon } from 'geojson';

import { BasePolygon } from '../MapElements';

type TPolygon = ReturnType<typeof turfUnion> | BasePolygon;

/**
 * Объединяет переданные полигоны в один
 */
function mergePolygons(polygons: TPolygon[]): Feature<Polygon | MultiPolygon> | undefined {
  const turfPolygonsList = extractTurfPolygonFromArg(polygons);

  const [firstPolygon, ...restPolygons] = turfPolygonsList;

  return restPolygons.reduce((acc, curr) => {
    try {
      return turfUnion(acc, curr);
    } catch {
      /**
       * В случае если у полигона есть координаты с большой точностью после запятой (>12), то возникает ошибка соединения.
       * Для фикса необходимо уменьшить кол-во точек после запятой
       * https://github.com/Turfjs/turf/issues/1983
       */
      return turfUnion(turfTruncate(acc), turfTruncate(curr));
    }
  }, firstPolygon);
}

function extractTurfPolygonFromArg(arg: TPolygon[]): ReturnType<typeof turfUnion>[] {
  return arg.map(polygon => {
    return polygon instanceof BasePolygon ? polygon.toTurf() : polygon;
  });
}

export default mergePolygons;
