import styled, { css } from 'styled-components';

const ErrorText = styled.div`
  color: #df2e22;
  font-feature-settings: 'clig' off, 'liga' off;
  /* body: reg14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
`;

const DependentAttribute = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Header = styled.div<{ $isEnabled: boolean }>`
  display: grid;
  grid-template-columns: 40px calc(100% - 24px - 40px - 170px) 170px;
  align-items: center;
  grid-gap: 12px;

  ${({ $isEnabled }) => {
    if ($isEnabled) {
      return css`
        border-bottom: 1px solid #e6e9ee;
        padding-bottom: 12px;
      `;
    }

    return css`
      padding-bottom: 8px;
    `;
  }}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledStoAttributeOdz = {
  ErrorText,
  DependentAttribute,
  Header,
  Body,
  Wrapper,
};

export default StyledStoAttributeOdz;
