import { useCallback } from 'react';

function useInputHint(inputValue: string, filteredList: Array<string>) {
  const regexp = new RegExp(`^${inputValue}`, 'i');

  return useCallback(() => {
    return filteredList.find(item => regexp.test(item))?.replace(regexp, inputValue);
  }, [inputValue, filteredList]);
}

export default useInputHint;
