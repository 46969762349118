import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizesObj } from '../../../../../../../../../../../shared/constans/sizes';

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: ESpaceSize.S,
});

const Title = styled('h4')({
  ...FontSizesObj.mediumBold,
  margin: 0,
});

const Styles = {
  Wrapper,
  Title,
};

export default Styles;
