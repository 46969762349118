import { lazyInject, provide } from '../../../../../../../../../../shared/utils/IoC';
import { StoRegistriesStore as Store } from '../../stores';
import {
  EStoDictionaryName as EDictionaryName,
  IStoDictionary as IDictionary,
} from '../../../../../../../../../../../api/models/as-fields/new/sto';

@provide.transient()
class StoRegistriesChangesService {
  @lazyInject(Store)
  protected store: Store;

  public selectWeedPlant = (id: string): void => {
    this.store.setSelectedWeedPlantId(id);
  };

  public selectVermin = (id: string): void => {
    this.store.setSelectedVerminId(id);
  };

  public selectDisease = (id: string): void => {
    this.store.setSelectedDiseaseId(id);
  };

  public deselectedWeedPlant = (id: string): void => {
    this.store.deleteSelectedWeedPlantId(id);
  };

  public deselectedVermin = (id: string): void => {
    this.store.deleteSelectedVerminId(id);
  };

  public deselectedDisease = (id: string): void => {
    this.store.deleteSelectedDiseaseId(id);
  };

  public toggleAllEntities = (name: EDictionaryName, state: boolean): void => {
    switch (name) {
      case EDictionaryName.WeedPlants:
        this.toggleAllWeedPlants(state);
        break;

      case EDictionaryName.Vermin:
        this.toggleAllVermin(state);
        break;

      case EDictionaryName.Diseases:
        this.toggleAllDiseases(state);
        break;

      default:
    }
  };

  public searchEntities = (name: EDictionaryName, searchValue: string): void => {
    if (searchValue === '' || !searchValue) {
      this.displayAllEntities(name);
      return;
    }

    const searchTermList = searchValue.toLowerCase().split(' ');

    switch (name) {
      case EDictionaryName.WeedPlants:
        this.searchWeedPlants(searchTermList);
        break;

      case EDictionaryName.Vermin:
        this.searchVermin(searchTermList);
        break;

      case EDictionaryName.Diseases:
        this.searchDiseases(searchTermList);
        break;

      default:
    }
  };

  public addEntityList = (name: EDictionaryName, entityList: IDictionary[]): void => {
    if (!entityList.length) {
      return;
    }

    switch (name) {
      case EDictionaryName.WeedPlants:
        this.addWeedPlants(entityList);
        break;

      case EDictionaryName.Vermin:
        this.addVermin(entityList);
        break;

      case EDictionaryName.Diseases:
        this.addDiseases(entityList);
        break;

      default:
    }
  };

  public removeHighlight = (name: EDictionaryName, id: string): void => {
    switch (name) {
      case EDictionaryName.WeedPlants:
        this.removeWeedPlantHighlight(id);
        break;

      case EDictionaryName.Vermin:
        this.removeVerminHighlight(id);
        break;

      case EDictionaryName.Diseases:
        this.removeDiseaseHighlight(id);
        break;

      default:
    }
  };

  protected toggleAllWeedPlants = (state: boolean): void => {
    this.store.weedPlantListToDisplay.forEach(entity => {
      if (state) this.store.setSelectedWeedPlantId(entity.id);
      else this.store.deleteSelectedWeedPlantId(entity.id);
    });
  };

  protected toggleAllVermin = (state: boolean): void => {
    this.store.verminListToDisplay.forEach(entity => {
      if (state) this.store.setSelectedVerminId(entity.id);
      else this.store.deleteSelectedVerminId(entity.id);
    });
  };

  protected toggleAllDiseases = (state: boolean): void => {
    this.store.diseaseListToDisplay.forEach(entity => {
      if (state) this.store.setSelectedDiseaseId(entity.id);
      else this.store.deleteSelectedDiseaseId(entity.id);
    });
  };

  protected displayAllEntities = (name: EDictionaryName): void => {
    switch (name) {
      case EDictionaryName.WeedPlants:
        this.store.setWeedPlantListToDisplay(this.store.weedPlantList);
        break;

      case EDictionaryName.Vermin:
        this.store.setVerminListToDisplay(this.store.verminList);
        break;

      case EDictionaryName.Diseases:
        this.store.setDiseaseListToDisplay(this.store.diseaseList);
        break;

      default:
    }
  };

  protected searchWeedPlants = (searchTermList: string[]): void => {
    const filteredList = this.store.weedPlantList.filter(entity => {
      if (!entity.name) return false;

      const lowerCaseName = entity.name.toLowerCase();

      return searchTermList.every(term => lowerCaseName.includes(term));
    });

    this.store.setWeedPlantListToDisplay(filteredList);
  };

  protected searchVermin = (searchTermList: string[]): void => {
    const filteredList = this.store.verminList.filter(entity => {
      if (!entity.name) return false;

      const lowerCaseName = entity.name.toLowerCase();

      return searchTermList.every(term => lowerCaseName.includes(term));
    });

    this.store.setVerminListToDisplay(filteredList);
  };

  protected searchDiseases = (searchTermList: string[]): void => {
    const filteredList = this.store.diseaseList.filter(entity => {
      if (!entity.name) return false;

      const lowerCaseName = entity.name.toLowerCase();

      return searchTermList.every(term => lowerCaseName.includes(term));
    });

    this.store.setDiseaseListToDisplay(filteredList);
  };

  protected addWeedPlants = (entityList: IDictionary[]): void => {
    this.store.setWeedPlantList(prev => {
      const formattedPrevList: IDictionary[] = prev.map(d => ({
        ...d,
        systemData: {
          isHighlighted: false,
        },
      }));

      return [...entityList, ...formattedPrevList];
    });

    this.store.setWeedPlantListToDisplay(prev => {
      const formattedPrevList: IDictionary[] = prev.map(d => ({
        ...d,
        systemData: {
          isHighlighted: false,
        },
      }));

      return [...entityList, ...formattedPrevList];
    });

    this.store.setSelectedWeedPlantIdList(prev => [...prev, ...entityList.map(({ id }) => id)]);
  };

  protected addVermin = (entityList: IDictionary[]): void => {
    this.store.setVerminList(prev => {
      const formattedPrevList: IDictionary[] = prev.map(d => ({
        ...d,
        systemData: {
          isHighlighted: false,
        },
      }));

      return [...entityList, ...formattedPrevList];
    });

    this.store.setVerminListToDisplay(prev => {
      const formattedPrevList: IDictionary[] = prev.map(d => ({
        ...d,
        systemData: {
          isHighlighted: false,
        },
      }));

      return [...entityList, ...formattedPrevList];
    });

    this.store.setSelectedVerminIdList(prev => [...prev, ...entityList.map(({ id }) => id)]);
  };

  protected addDiseases = (entityList: IDictionary[]): void => {
    this.store.setDiseaseList(prev => {
      const formattedPrevList: IDictionary[] = prev.map(d => ({
        ...d,
        systemData: {
          isHighlighted: false,
        },
      }));

      return [...entityList, ...formattedPrevList];
    });

    this.store.setDiseaseListToDisplay(prev => {
      const formattedPrevList: IDictionary[] = prev.map(d => ({
        ...d,
        systemData: {
          isHighlighted: false,
        },
      }));

      return [...entityList, ...formattedPrevList];
    });

    this.store.setSelectedDiseaseIdList(prev => [...prev, ...entityList.map(({ id }) => id)]);
  };

  protected removeWeedPlantHighlight = (id: string): void => {
    this.store.setWeedPlantList(prev => {
      return prev.map(d => {
        if (d.id !== id) return d;

        return {
          ...d,
          systemData: {
            isHighlighted: false,
          },
        };
      });
    });

    this.store.setWeedPlantListToDisplay(prev => {
      return prev.map(d => {
        if (d.id !== id) return d;

        return {
          ...d,
          systemData: {
            isHighlighted: false,
          },
        };
      });
    });
  };

  protected removeVerminHighlight = (id: string): void => {
    this.store.setVerminList(prev => {
      return prev.map(d => {
        if (d.id !== id) return d;

        return {
          ...d,
          systemData: {
            isHighlighted: false,
          },
        };
      });
    });

    this.store.setVerminListToDisplay(prev => {
      return prev.map(d => {
        if (d.id !== id) return d;

        return {
          ...d,
          systemData: {
            isHighlighted: false,
          },
        };
      });
    });
  };

  protected removeDiseaseHighlight = (id: string): void => {
    this.store.setDiseaseList(prev => {
      return prev.map(d => {
        if (d.id !== id) return d;

        return {
          ...d,
          systemData: {
            isHighlighted: false,
          },
        };
      });
    });

    this.store.setDiseaseListToDisplay(prev => {
      return prev.map(d => {
        if (d.id !== id) return d;

        return {
          ...d,
          systemData: {
            isHighlighted: false,
          },
        };
      });
    });
  };
}

export default StoRegistriesChangesService;
