import React, { FC } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './StoAttributeUserDictionaryRegistryItemRemoveIcon.styles';

interface IProps {
  dataTestId: string;
  onClick: () => void;
}

const StoAttributeUserDictionaryRegistryItemRemoveIcon: FC<IProps> = ({ dataTestId, onClick }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...getDataTestId()}
      onClick={onClick}
    >
      <g clip-path="url(#clip0_3557_15769)">
        <path d="M9.20905 10.3301H10.2557V18.2402H9.20905V10.3301Z" fill="#DF2E22" />
        <path d="M11.4769 10.3301H12.5235V18.2402H11.4769V10.3301Z" fill="#DF2E22" />
        <path d="M13.7445 10.3301H14.7911V18.2402H13.7445V10.3301Z" fill="#DF2E22" />
        <path
          d="M18.3429 10.3301H17.9903L18.1465 8.625H19.3265V6.95508C19.3265 6.0343 18.5831 5.28516 17.6693 5.28516H15.725L14.5912 3H9.40886L8.275 5.28516H6.33072C5.41697 5.28516 4.67355 6.0343 4.67355 6.95508V8.625H5.85349L6.00972 10.3301H5.65718C4.74343 10.3301 4 11.0792 4 12C4 12.9208 4.74343 13.6699 5.65718 13.6699H6.31572L6.98735 21H17.0127L17.6843 13.6699H18.3428C19.2566 13.6699 20 12.9208 20 12C20 11.0792 19.2566 10.3301 18.3429 10.3301ZM10.0557 4.05469H13.9443L14.5549 5.28516H9.44521L10.0557 4.05469ZM5.72019 6.95508C5.72019 6.61586 5.99409 6.33984 6.33072 6.33984H17.6693C18.0059 6.33984 18.2799 6.61586 18.2799 6.95508V7.57031H5.72019V6.95508ZM5.65718 12.6152C5.32054 12.6152 5.04664 12.3392 5.04664 12C5.04664 11.6608 5.32054 11.3848 5.65718 11.3848H6.10636L6.21912 12.6152H5.65718ZM16.0582 19.9453H7.94182L6.9046 8.625H17.0954L16.0582 19.9453ZM18.3429 12.6152H17.781L17.8937 11.3848H18.3429C18.6795 11.3848 18.9534 11.6608 18.9534 12C18.9534 12.3392 18.6795 12.6152 18.3429 12.6152Z"
          fill="#DF2E22"
        />
      </g>
      <defs>
        <clipPath id="clip0_3557_15769">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Styled.Wrapper>
  );
};

StoAttributeUserDictionaryRegistryItemRemoveIcon.displayName =
  'StoAttributeUserDictionaryRegistryItemRemoveIcon';

export default StoAttributeUserDictionaryRegistryItemRemoveIcon;
