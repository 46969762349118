import { FC } from 'react';

import Styled from './SummaryForm.styles';
import { StoCultureSelect, StoName } from './components';

const SummaryForm: FC = () => {
  return (
    <Styled.Form>
      <Styled.Col $variant="calc(50% - 8px) calc(50% - 8px)">
        <Styled.Row>
          <StoName />
        </Styled.Row>
        <Styled.Row>
          <StoCultureSelect />
        </Styled.Row>
      </Styled.Col>
    </Styled.Form>
  );
};

SummaryForm.displayName = 'SummaryForm';

export default SummaryForm;
