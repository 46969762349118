import {
  EIconButtonSize,
  ENotificationType,
  ENotificatorTheme,
  NewDropdown,
  useNotificator,
} from '@farmlink/farmik-ui';
import { ISelectOption } from '@farmlink/farmik-ui/dist/types';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as CheckIcon } from '../../../../../../../../../../../../../shared/assets/images/check-icon.svg';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { EFieldsErrors } from '../../../../../../../../../../utils';
import {
  FieldSeasonsViewController as Controller,
  FieldSeasonsViewStore as Store,
} from '../../../../../../../../mobx';

import Styled from './CopyFieldContent.styles';

const CopyFieldContent: FC = () => {
  // Навигация
  const { fieldId } = useParams();

  // Сторы
  const store = useStore(Store);

  // Контроллеры
  const controller = useStore(Controller);

  const notificator = useNotificator();
  const getDataTestId = useDataTestIdV2('field-season-card-copy-field');

  const [optionList, setOptionList] = useState<ISelectOption[]>([]);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);

  const handleDropdownChange = (value: string) => {
    setSelectedValue(Number(value));
  };

  const handleApplyClick = () => {
    if (!selectedValue) {
      return;
    }

    const fromSeason = store.getSeasonByYear(selectedValue);
    void controller.copyField(fieldId, fromSeason).catch(err => {
      if (err?.message === EFieldsErrors.GeometryIntersection) {
        notificator.setNotification({
          message: 'Измените границы полей, чтобы не было пересечений',
          style: {
            type: ENotificationType.Warning,
            theme: ENotificatorTheme.Light,
            placement: 'top-right',
          },
        });
      }
    });
  };

  useEffect(() => {
    controller.getSeasonsOptionList(fieldId).then(setOptionList);
  }, [fieldId]);

  return (
    <Styled.ControlsWrapper {...getDataTestId('wrapper')}>
      <NewDropdown
        config={{
          field: {
            onChange: handleDropdownChange,
            placeholder: 'Сезон',
          },
          body: { optionList },
          visual: {
            label: 'Скопировать контур из сезона',
          },
          styles: {
            fieldPadding: '5px 12px',
            borderRadius: '8px',
          },
          other: {
            dataTestId: getDataTestId('dropdown')['data-test-id'],
          },
        }}
      />

      <Styled.ApplyIcon
        customIcon={<CheckIcon />}
        size={EIconButtonSize.x32}
        colorScheme={Styled.applyIconColorScheme}
        dataTestId={getDataTestId('apply-icon')['data-test-id']}
        onClick={handleApplyClick}
      />
    </Styled.ControlsWrapper>
  );
};

export default observer(CopyFieldContent);
