import { makeAutoObservable } from 'mobx';
import { throttle } from 'lodash';
import { Portal } from 'react-portal-hook';

import { provide } from '../../shared/utils/IoC';

export enum ESidebarWidth {
  SHORT = '62px',
  LARGE = '196px',
}

@provide.singleton()
export class UiStore {
  currentPortal: Portal = null;

  pageWidth = 1920;
  pageHeight = 1080;

  isSidebarShort = true;

  isTipOpen = false;

  tipContainerRef = null;
  tipLocation = {
    x: 0,
    y: 0,
  };

  isEditSeasonModalOpen = false;

  constructor() {
    makeAutoObservable(this);

    setTimeout(() => {
      this.handleResize();
    }, 0);

    window.addEventListener('resize', throttle(this.handleResize, 300));
  }

  handleResize = () => {
    this.pageWidth = document.documentElement.clientWidth;
    this.pageHeight = document.documentElement.clientHeight;
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  get isAdaptive() {
    return this.pageWidth >= 1024 && this.pageWidth <= 1365;
  }

  get isAdaptiveSidebarVisible() {
    return this.pageWidth >= 1024 && this.pageWidth <= 1365 && !this.isSidebarShort;
  }

  setIsSidebarShort = (v: boolean) => {
    this.isSidebarShort = v;
    localStorage.setItem('sidebarShort', v ? 'true' : 'false');
  };

  sidebarShortState = () => localStorage.getItem('sidebarShort') === 'true';

  setContainerRef = (ref: any) => {
    this.tipContainerRef = ref;
  };

  contextMenuCloseFunc: () => void;

  setContextMenuCloseFunc = (func: () => void) => {
    this.contextMenuCloseFunc = func;
  };

  openTip = (x: number, y: number) => {
    this.isTipOpen = true;
    this.tipLocation = {
      x,
      y,
    };
  };

  closeTip = () => {
    this.isTipOpen = false;
  };

  setEditSeason = (v: boolean) => {
    this.isEditSeasonModalOpen = v;
  };

  closePortal = () => {
    this.currentPortal.close();
  };

  setPortal = (portal: Portal) => {
    this.currentPortal = portal;
  };

  cleanPortal = () => {
    this.currentPortal = null;
  };
}
