import { useModal } from '@farmlink/farmik-ui';

import Styled from './TransferFieldsModal.styles';

const TransferFieldsModal = () => {
  const { getModalPayload } = useModal();

  const payload = getModalPayload() as { fieldName?: string };

  return (
    <Styled.Wrapper>
      <Styled.Icon />
      <Styled.Text>
        На поле {payload?.fieldName} ранее был назначен другой СТО. Переназначить на поле текущий
        СТО?
      </Styled.Text>
    </Styled.Wrapper>
  );
};

export default TransferFieldsModal;
