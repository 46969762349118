import { Geometry } from '@turf/turf';
import turfDifference from '@turf/difference';

import { CultureZone } from '../../../../../../../../api/models/field.model';
import {
  calculateArea,
  toTurfPolygon,
} from '../../../../../../../shared/features/map/utils/helpers';

/**
 * Обрезает геометрию всех переданных культурных зон.
 * @param areaToCut - Геометрия для обрезки. Каждый полигон из массива будет обрезать разницу с ней
 * @param cultureZonesList - массив кульутрных зон для обрезки
 */
function cutCultureZones(areaToCut: Geometry, cultureZonesList: CultureZone[]) {
  const resultList: CultureZone[] = [];

  cultureZonesList.forEach(element => {
    const diff = turfDifference(toTurfPolygon(element.geometry), toTurfPolygon(areaToCut));

    const newGeometry = diff?.geometry ?? element.geometry;

    resultList.push({
      ...element,
      geometry: newGeometry,
      area: calculateArea(newGeometry),
    });
  });

  return resultList;
}

export default cutCultureZones;
