import { makeAutoObservable } from 'mobx';

import { MapEventBus } from '../../../../../modules';
import { BasePolygon } from '../../../index';

import Memento from './Memento';

class CareTaker {
  private _mementos: Memento[] = [];

  private polygon: BasePolygon;

  constructor(polygon: BasePolygon) {
    makeAutoObservable(this);

    this.polygon = polygon;
  }

  public get canUndo() {
    return this._mementos.length > 0;
  }
  public get mementos() {
    return this._mementos;
  }

  public backup() {
    const memento = this.polygon.saveState();
    console.log('create backup: ', memento.getState());

    this._mementos.push(memento);
  }

  public undo() {
    if (!this.canUndo) {
      return;
    }

    const memento = this._mementos.pop();

    if (memento) {
      this.polygon.restore(memento);

      MapEventBus.emit('polygon.undo', this.polygon);
    }
  }
}

export default CareTaker;
