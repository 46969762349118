import { FC, ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

import useDataTestIdV2 from '../../../../../shared/features/utils/hooks/locators/useDataTestIdV2/useDataTestIdV2';

import Styled from './ListSkeleton.styles';

interface IProps {
  length: number;
  isLoading: boolean;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
  children: ReactElement | ReactElement[];
}

const FieldsListSkeleton: FC<IProps> = props => {
  if (!props.isLoading) {
    if (Array.isArray(props.children)) {
      return <>{props.children}</>;
    }

    return props.children;
  }

  return (
    <Styled.Wrapper {...props.getDataTestId('skeleton-wrapper')}>
      {Array.from({ length: props.length }, (_, i) => (
        <Styled.Card key={`list-skeleton-${i}`} {...props.getDataTestId('skeleton-card')}>
          <Skeleton className={'left-item'} width={40} height={40} duration={0} />

          <Styled.TextWrapper>
            <Skeleton className={'text'} width={126} height={12} duration={0} />
            <Skeleton className={'text'} width={100} height={12} duration={0} />
          </Styled.TextWrapper>

          <Skeleton className={'text'} width={34} height={12} duration={0} />
        </Styled.Card>
      ))}
    </Styled.Wrapper>
  );
};

export default FieldsListSkeleton;
