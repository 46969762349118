import { TypeApiRoute } from '../../../models/type.api.request';
import { IChecklistsByTechOperationType } from '../../../models/as-fields/new/sto/ChecklistsByTechOperationType';

type TRequest = {
  id: string;
  withChecklistOnly?: boolean;
  asOnDate?: string;
  versionId?: string;
};

type TResponse = IChecklistsByTechOperationType[];

export const getChecklistsByTechOperationType: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/sto/${id}/checklistsByTechOperationType`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
