import L from 'leaflet';
import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/IoC';
import { IMapGlobalConfig } from '../../../models';

@provide.singleton()
class MapCoreStore {
  private _globalConfig: IMapGlobalConfig | null = null;

  private _instance: L.Map | null = null;

  private _deflateLayer: L.DeflateLayer | null = null;

  private _resizeObserver: ResizeObserver | null = null;

  // Необоходимо для предотвращения всплытия евента после нажатия на элемент карты
  private _preventMapClick = false;

  constructor() {
    makeAutoObservable(this);
  }

  get globalConfig() {
    return this._globalConfig;
  }

  set globalConfig(globalConfig: IMapGlobalConfig) {
    this._globalConfig = globalConfig;
  }

  get instance() {
    return this._instance;
  }

  set instance(instance: L.Map) {
    if (!instance) {
      return;
    }

    instance.boxZoom.disable();
    // @ts-ignore
    window.leafletMap = instance;
    this._instance = instance;
  }

  get resizeObserver() {
    return this._resizeObserver;
  }

  set resizeObserver(value: ResizeObserver) {
    this._resizeObserver = value;
  }

  /**
   * Возвращает слой кластеризации
   */
  get deflateLayer() {
    return this._deflateLayer;
  }

  set deflateLayer(layer) {
    this._deflateLayer = layer;
  }

  get preventMapClick() {
    return this._preventMapClick;
  }

  set preventMapClick(value) {
    this._preventMapClick = value;
  }

  updateGlobalConfig = (config: IMapGlobalConfig) => {
    this.globalConfig = { ...this._globalConfig, ...config };
  };

  clearGlobalConfig = (): void => {
    this._globalConfig = null;
  };

  clearInstance = (): void => {
    this._instance = null;
  };
}

export default MapCoreStore;
