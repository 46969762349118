import { Button, Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  padding: ESpaceSize.M,
  borderTop: `1px solid ${Colors.technicalBorderGrey}`,
});

// TODO: переделать через @farmik
const DropdownButtonWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',

  '& button > div:nth-child(1)': {
    height: '40px !important',
    borderTopLeftRadius: ESpaceSize.M,
    borderBottomLeftRadius: ESpaceSize.M,
  },
  '& button > div:nth-child(2)': {
    height: '40px !important',
    flexBasis: '40px',
    borderTopRightRadius: ESpaceSize.M,
    borderBottomRightRadius: ESpaceSize.M,
  },
});

const CustomButton = styled(Button)({
  height: '40px',
  borderRadius: ESpaceSize.M,
  whiteSpace: 'nowrap',
});

const Styles = {
  Wrapper,
  CustomButton,
  DropdownButtonWrapper,
};

export default Styles;
