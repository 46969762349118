import { useModal } from '@farmlink/farmik-ui';
import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { sortBy } from 'lodash';

import {
  EMapControlPlace,
  GoogleSearchMapControl,
  LayerFillMapControl,
  LayerTooltipMapControl,
  LocationMapControl,
  ZoomMapControl,
} from '../../../../../shared/features/map/components/Controls';
import { EFieldFill } from '../../../../constants/FieldFill.enum';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import FieldsLayout from '../../FieldsLayout';

import { FieldsImportController as Controller } from './mobx';
import FieldsImportList from './components/List/List';

const FieldsImportPage: FC = () => {
  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const fieldsStore = useStore(FieldsStore);

  const modal = useModal();

  const fieldsList = useMemo(() => {
    const filteredList = fieldsStore.fieldsList.filter(field => field?.isImported);

    return sortBy(filteredList, ['errorType', 'area']);
  }, [fieldsStore.fieldsList]);

  useEffect(() => {
    void controller.initialize();
    modal.closeModal();

    return () => {
      controller.destroy();
    };
  }, []);

  return (
    <FieldsLayout
      id={'fields-import'}
      leftPanel={<FieldsImportList list={fieldsList} />}
      map={{
        config: {
          controls: [
            { id: 'search', place: EMapControlPlace.TOP, component: <GoogleSearchMapControl /> },
            {
              id: 'tooltip',
              place: EMapControlPlace.TOP,
              component: <LayerTooltipMapControl allFields={fieldsStore.fieldsList} />,
            },
            {
              id: 'fill',
              place: EMapControlPlace.TOP,
              component: <LayerFillMapControl skipOptions={[EFieldFill.Ndvi, EFieldFill.Msavi]} />,
            },

            { id: 'zoom', place: EMapControlPlace.RIGHT, component: <ZoomMapControl /> },
            { id: 'location', place: EMapControlPlace.RIGHT, component: <LocationMapControl /> },
          ],
          pointCoordinatesControl: true,
        },
      }}
    />
  );
};

export default observer(FieldsImportPage);
