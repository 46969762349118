import { observer } from 'mobx-react';
import { FC, MouseEvent, useCallback, useEffect, useRef } from 'react';

import { useTableBuilderUIContext as useUIContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { TableBuilderGrid as Grid } from '../../shared';
import { useStore } from '../../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../../mobx/stores';
import { useSortBy } from '../../../../utils/hooks/sort';
import { ITableBuilderRow as IRow } from '../../../models/data';
import { TableBuilderCell as Cell } from '../TableBuilderCell';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { TableBuilderController as Controller } from '../../../mobx/controllers';

import Styled from './TableBuilderRow.styles';

interface IProps {
  row: IRow;
  index: number;
}

const TableBuilderRow: FC<IProps> = ({ row, index }) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const UIContext = useUIContext();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const cellList = useSortBy(store.getCellList(UIContext.builderId));
  const rowConfig = store.getRowConfig(UIContext.builderId);
  const preset = store.getTableStylePreset(UIContext.builderId);
  const isHighlighted = Boolean(rowConfig?.visual?.highlightedRowsIds?.[row.id]);

  useEffect(() => {
    if (!isHighlighted) return;

    setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }
    }, 300);
  }, [isHighlighted]);

  const handleMouseLeave = useCallback((): void => {
    if (!isHighlighted) return;

    controller.unHighlightRow('tasks', row.id);
  }, [isHighlighted]);

  const handleRowClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      rowConfig?.onRowClick?.(row.element, event, index);
    },
    [rowConfig?.onRowClick, row.element, index]
  );

  const getDataTestId = useDataTestId();

  return (
    <Styled.Wrapper
      ref={wrapperRef}
      onClick={handleRowClick}
      $isHoverable={rowConfig?.visual?.isHoverable}
      $hasClickEvent={Boolean(rowConfig?.onRowClick)}
      $isHighlighted={isHighlighted}
      onMouseLeave={handleMouseLeave}
      {...getDataTestId('row-wrapper')}
    >
      <Grid $gridStylesConfig={rowConfig?.visual?.presets?.[preset]?.grid}>
        {cellList.map(cell => (
          <Cell key={cell.id as string} cell={cell} row={row} />
        ))}
      </Grid>
    </Styled.Wrapper>
  );
};

TableBuilderRow.displayName = 'TableBuilderRow';

export default observer(TableBuilderRow);
