import { makeAutoObservable } from 'mobx';
import { isMap } from 'lodash';

import { provide } from '../../../../../../../../shared/utils/IoC';
import {
  IChecklistsAttrToDraw as IAttrToDraw,
  IChecklistsNestedInstanceToDraw as INestedInstanceToDraw,
} from '../../../models';
import { IGetChecklist as IChecklist } from '../../../../../../../../../api/models/checklist/checklist.model';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { EChecklistMode } from '../../../../../../operationsAndTasks/stores/checklist.instances.store';

@provide.singleton()
class ChecklistsStore {
  /**
   * Режим чек-листа.
   */
  private _mode: EChecklistMode | null = null;

  /**
   * Выбранный чек-лист.
   */
  private _selectedChecklist: IChecklist | null = null;

  /**
   * Коллекция атрибутов для отрисовки.
   */
  private _attrsToDrawByIdByGroupId: Map<string, Map<string, IAttrToDraw>> = new Map();

  /**
   * Плоская коллекция атрибутов для отрисовки.
   */
  private _attrIdToGroupId: Map<string, string> = new Map();

  /**
   * Плоская коллекция инстансов для групп.
   */
  private _instanceIdToGroupId: Map<string, string> = new Map();

  /**
   * Коллекции вложенных записей.
   */
  private _nestedInstancesToDrawByIdByAttrId: Map<
    string,
    Map<string, INestedInstanceToDraw>
  > = new Map();

  /**
   * Идентификатор атрибута с ошибкой, что блокирует сохранение чек-листа.
   */
  private _attrIdWithError: string | null = null;

  /**
   * Идентификатор атрибута, который мы редактировали последним.
   */
  private _lastEditedAttrId: string | null = null;

  /**
   * Системное свойство, что отвечает за скролл к атрибуту с ошибкой.
   */
  private _isAttrIdWithErrorTargeted = false;

  constructor() {
    makeAutoObservable(this);
  }

  get mode() {
    return this._mode;
  }

  get selectedChecklist() {
    return this._selectedChecklist;
  }

  get attrToDrawList(): IAttrToDraw[] {
    return [...this._attrsToDrawByIdByGroupId.values()].flatMap(col => [...col.values()]);
  }

  getAttrToDrawListByGroupId = (groupId: string): IAttrToDraw[] => {
    const collection = this._attrsToDrawByIdByGroupId.get(groupId);

    if (collection) {
      return [...collection.values()];
    } else {
      return [];
    }
  };

  getAttrToDraw = <T extends EAttrType>(groupId: string, attrId: string): IAttrToDraw<T> => {
    return this._attrsToDrawByIdByGroupId.get(groupId)?.get?.(attrId);
  };

  getNestedInstanceToDrawListByAttrId = (attrId: string): INestedInstanceToDraw[] => {
    const collection = this._nestedInstancesToDrawByIdByAttrId.get(attrId);

    if (collection) {
      return [...collection.values()];
    } else {
      return [];
    }
  };

  getNestedInstanceToDraw = (attrId: string, id: string): INestedInstanceToDraw => {
    return this._nestedInstancesToDrawByIdByAttrId.get(attrId)?.get?.(id);
  };

  get attrIdWithError() {
    return this._attrIdWithError;
  }

  get isAttrIdWithErrorTargeted() {
    return this._isAttrIdWithErrorTargeted;
  }

  public get lastEditedAttrId(): string | null {
    return this._lastEditedAttrId;
  }

  setMode = (mode: EChecklistMode): void => {
    this._mode = mode;
  };

  setSelectedChecklist = (checklist: IChecklist): void => {
    this._selectedChecklist = checklist;
  };

  setAttrToDrawList = (groupId: string, attrToDrawList: IAttrToDraw[]): void => {
    const previousEntryList = this._attrsToDrawByIdByGroupId.get(groupId)?.entries?.() || [];

    const newCollection = new Map<string, IAttrToDraw>(previousEntryList);

    attrToDrawList.forEach(attr => {
      newCollection.set(attr.id, attr);

      this._attrIdToGroupId.set(attr.id, groupId);
    });

    this._attrsToDrawByIdByGroupId.set(groupId, newCollection);
  };

  updateAttrToDraw = (groupId: string, id: string, newData: Partial<IAttrToDraw>): void => {
    const attrToDraw = this._attrsToDrawByIdByGroupId.get(groupId)?.get?.(id);

    if (!attrToDraw) return;

    this._attrsToDrawByIdByGroupId.get(groupId).set(id, { ...attrToDraw, ...newData });
  };

  updateAttrToDrawOptions = <T extends EAttrType>(
    groupId: string,
    id: string,
    payload: Partial<IAttrToDraw<T>['options']>
  ): void => {
    const attrToDraw = this._attrsToDrawByIdByGroupId.get(groupId)?.get?.(id);

    if (!attrToDraw) return;

    this._attrsToDrawByIdByGroupId
      .get(groupId)
      .set(id, { ...attrToDraw, options: { ...attrToDraw.options, ...payload } });
  };

  updateAttrToDrawValidationScheme = <T extends EAttrType>(
    groupId: string,
    id: string,
    payload: Partial<IAttrToDraw<T>['validationScheme']>
  ): void => {
    const attrToDraw = this._attrsToDrawByIdByGroupId.get(groupId)?.get?.(id);

    if (!attrToDraw) return;

    this._attrsToDrawByIdByGroupId
      .get(groupId)
      .set(id, { ...attrToDraw, validationScheme: { ...attrToDraw.validationScheme, ...payload } });
  };

  deleteAttrsToDrawByGroupId = (groupId: string): void => {
    this._attrsToDrawByIdByGroupId.delete(groupId);
  };

  setNestedAttrIdToGroupId = (attrId: string, instance: INestedInstanceToDraw) => {
    this._instanceIdToGroupId.set(instance.id, attrId);
  };

  setNestedInstanceToDrawList = (
    attrId: string,
    nestedInstanceToDrawList: INestedInstanceToDraw[]
  ): void => {
    const previousEntryList =
      this._nestedInstancesToDrawByIdByAttrId.get(attrId)?.entries?.() || [];

    const newCollection = new Map<string, INestedInstanceToDraw>(previousEntryList);

    nestedInstanceToDrawList.forEach(instance => {
      newCollection.set(instance.id, instance);

      this.setNestedAttrIdToGroupId(attrId, instance);
    });

    this._nestedInstancesToDrawByIdByAttrId.set(attrId, newCollection);
  };

  setNestedInstanceToDraw = (attrId: string, nestedInstanceToDraw: INestedInstanceToDraw): void => {
    const hasCollection = this._nestedInstancesToDrawByIdByAttrId.has(attrId);

    if (hasCollection) {
      this._nestedInstancesToDrawByIdByAttrId
        .get(attrId)
        .set(nestedInstanceToDraw.id, nestedInstanceToDraw);
    } else {
      const newCollection = new Map<string, INestedInstanceToDraw>();
      newCollection.set(nestedInstanceToDraw.id, nestedInstanceToDraw);

      this._nestedInstancesToDrawByIdByAttrId.set(attrId, newCollection);
    }

    this.setNestedAttrIdToGroupId(attrId, nestedInstanceToDraw);
  };

  updateNestedInstanceToDraw = (
    attrId: string,
    id: string,
    payload: Partial<INestedInstanceToDraw>
  ): void => {
    const instanceToDraw = this._nestedInstancesToDrawByIdByAttrId.get(attrId)?.get?.(id);

    if (!instanceToDraw) {
      return;
    }

    this._nestedInstancesToDrawByIdByAttrId.get(attrId).set(id, { ...instanceToDraw, ...payload });
  };

  getGroupIdByAttrId = (attrId: string, groupId?: string) => {
    const nestedAttrId = this._instanceIdToGroupId.get(groupId);

    if (nestedAttrId) {
      // Аттрибут находится в коллекции вложенных чек-листов

      return groupId;
    } else {
      // Атрибут находится в основном потоке чек-листа, группу нужно достать из плоского списка атрибутов

      return this._attrIdToGroupId.get(attrId);
    }
  };

  deleteNestedInstanceToDraw = (attrId: string, id: string): void => {
    this._nestedInstancesToDrawByIdByAttrId.get(attrId)?.delete?.(id);
  };

  setAttrIdWithError = (attrId: string): void => {
    this._attrIdWithError = attrId;
  };

  setLastEditedAttrId = (attrId: string): void => {
    this._lastEditedAttrId = attrId;
  };

  setIsAttrIdWithErrorTargeted = (value: boolean): void => {
    this._isAttrIdWithErrorTargeted = value;
  };

  clearMode = (): void => {
    this._mode = null;
  };

  clearSelectedChecklist = (): void => {
    this._selectedChecklist = null;
  };

  clearAttrsToDrawByIdByGroupId = (): void => {
    this._attrsToDrawByIdByGroupId.clear();
  };

  clearNestedInstancesToDrawByIdByAttrId = (): void => {
    this._nestedInstancesToDrawByIdByAttrId.clear();
  };

  clearAttrIdWithError = (): void => {
    this._attrIdWithError = null;
  };

  clearLastEditedAttrId = (): void => {
    this._lastEditedAttrId = null;
  };

  clearIsAttrIdWithErrorTargeted = (): void => {
    this._isAttrIdWithErrorTargeted = false;
  };

  clearAttrIdToGroupId = (): void => {
    this._attrIdToGroupId = new Map();
  };
}

export default ChecklistsStore;
