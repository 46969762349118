import { TypeApiRoute } from '../../../models/type.api.request';
import { EStoDictionaryName, IStoDictionaryPut } from '../../../models/as-fields/new/sto';

export interface IUpdateStoDictionaryRequest {
  stoId: string;
  dictionaryList: IStoDictionaryPut[];
}

type TResponse = { updatedStoDictionaries: { dictionaryName: EStoDictionaryName }[] };

export const updateStoDictionary: TypeApiRoute & {
  request: IUpdateStoDictionaryRequest | ((request: any) => IStoDictionaryPut[]);
  response: TResponse;
} = {
  url: ({ stoId }) => `/api/as-fields/sto/${stoId}/dictionary`,
  method: 'PUT',
  headers: {},
  request: req => req.dictionaryList,
  response: {} as TResponse,
};
