import { EMapControlPlace } from '../../../../../../interfaces/Controls.interface';
import { lazyInject, provide } from '../../../../../../../../../../utils/IoC';
import MapControlsStore from '../../../../../../mobx/stores/Controls.store';
import Legend from '../../components/Legend/Legend';

const LEGEND_ID = 'index-legend';

@provide.transient()
class IndicesMapControlsService {
  @lazyInject(MapControlsStore)
  private mapControlsStore: MapControlsStore;

  public addControls() {
    this.mapControlsStore.addControl({
      id: LEGEND_ID,
      place: EMapControlPlace.BOTTOM,
      order: 'first',
      component: <Legend />,
    });
  }

  public removeControls() {
    this.mapControlsStore.removeControl(LEGEND_ID);
  }
}

export default IndicesMapControlsService;
