import { Geometry } from '@turf/turf';
import { PolylineOptions } from 'leaflet';
import { v4 } from 'uuid';

interface IState {
  geometry: Geometry;
  options: PolylineOptions;
}

class Memento {
  private id: string;

  private state: IState;

  private date: string;

  constructor(state: IState) {
    this.id = v4();
    this.state = state;
    this.date = new Date().toISOString().slice(0, 19).replace('T', ' ');
  }

  public getState(): IState {
    return this.state;
  }

  public getName(): string {
    return `${this.date}`;
  }

  public getId() {
    return this.id;
  }
}

export default Memento;
