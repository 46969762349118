import { Colors } from '@farmlink/farmik-ui';
import type { Geometry } from '@turf/turf';
import type { PolylineOptions } from 'leaflet';

import { CultureZone } from '../../../../../../../api/models/field.model';

import { BasePolygon } from './index';

/**
 * @module BaseCulturePolygon
 * КЗ-полигон. Может быть как пустым (нет заливки), так и заполненным.
 * Хранит в себе информационную модель культурной зоны.
 * Автоматически извлекает цвет из инф. модели и устанавливает его как заливку
 */
class BaseCulturePolygon extends BasePolygon {
  private readonly _dataModel: CultureZone;

  constructor(geometry: Geometry, dataModel: CultureZone, options?: PolylineOptions) {
    const opts = options ?? getDefaultStyle(dataModel);

    super(geometry, opts);

    this._dataModel = dataModel;
  }

  /**
   * Информационная модель текущего КЗ-полигона
   */
  public get dataModel(): CultureZone {
    return this._dataModel;
  }

  public changeGeometry(geometry?: Geometry) {
    super.changeGeometry(geometry);

    this._dataModel.geometry = geometry;
  }
}

const getDefaultStyle = (dataModel: CultureZone): PolylineOptions => {
  const color = dataModel?.culture?.attrs?.assistanceColorLegend;

  if (!color) {
    return {
      fill: `url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg)` as any,
      fillOpacity: 0.5,
      color: Colors.generalWhite,
    };
  }

  return {
    fill: true,
    fillColor: color,
    fillOpacity: 1,
    color: Colors.generalWhite,
  };
};

export default BaseCulturePolygon;
