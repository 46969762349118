import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ButtonLink, Checkbox, Switcher, Typography, useModal } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { useStoParams } from '../../../../../../../../../../../../../../../../hooks';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../../../../../../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../../../mobx/controllers';
import { StoAttributeCoreStore as Store } from '../../../../mobx/stores';
import { StoStore } from '../../../../../../../../../../../../../../mobx';

import Styled from './StoAttributeOdz.styles';
import { StoAttributeOdzGroupContainer as Group } from './containers';
import {
  StoAttributeOdzDependency as Dependency,
  StoAttributeOdzDependencyGroup as DependencyGroup,
} from './components';
import { StoAttributeOdzModals } from './modals';

const StoAttributeOdz: FC = () => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);
  const checklistsStore = useStore(ChecklistsStore);

  const controller = useStore(Controller);

  const params = useStoParams();
  const modalActions = useModal();

  useEffect(() => {
    modalActions.registerModalList(Object.values(StoAttributeOdzModals), 'stoAttributeOdz');
  }, []);

  const { stoODZFormType } = checklistsStore.getAttrUpdate(
    params.checklistId,
    params.stageId,
    params.attributeId
  );

  /**
   * Данная логика требуется, чтобы правильно обрабатывать модальное окно, что предупреждает о потере данных.
   */
  const [_isComplexODZ, _setIsComplexOdz] = useState(stoODZFormType.isComplexODZ);
  const [_izODZEnabled, _setIzODZEnabled] = useState(stoODZFormType.izODZEnabled);

  useEffect(() => {
    _setIsComplexOdz(stoODZFormType.isComplexODZ);
  }, [stoODZFormType.isComplexODZ]);

  useEffect(() => {
    _setIzODZEnabled(stoODZFormType.izODZEnabled);
  }, [stoODZFormType.izODZEnabled]);

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const checkIfSelectorIsDisabled = (): boolean => {
    if (!stoODZFormType.izODZEnabled) return true;

    return false;
  };

  const checkIfNeedToShowButtonAddMore = (): boolean => {
    return (
      !checkIfIsBlockedByPermissions() &&
      stoODZFormType.isComplexODZ &&
      Boolean(stoODZFormType.odzGroups.length)
    );
  };

  const checkIfNeedToShowButtonRemove = (): boolean => {
    return (
      !checkIfIsBlockedByPermissions() &&
      stoODZFormType.isComplexODZ &&
      Boolean(stoODZFormType.odzGroups.length > 1)
    );
  };

  const handleIsEnabledChange = (value: boolean): void => {
    if (value) controller.enableOdz(params);
    else controller.disableOdz(params);
  };

  const handleIsComplexOdzDependencyChange = (value: boolean): void => {
    const success = (): void => {
      if (value) controller.enableOdzDependency(params);
      else controller.disableOdzDependency(params);
    };

    if (controller.checkIfOdzGroupsIsEmpty(params)) {
      success();
      return;
    }

    _setIsComplexOdz(value);

    modalActions.openModalByModalId(
      StoAttributeOdzModals.warningBeforeOdzTypeChange.id,
      null,
      () => {
        success();
        _setIsComplexOdz(!value);
      },
      () => _setIsComplexOdz(!value)
    );
  };

  const handleClickButtonAddMore = useCallback((): void => {
    controller.addOdzGroup(params);
  }, []);

  const getDataTestId = useDataTestIdV2('sto__attribute__odz');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Header $isEnabled={stoODZFormType.izODZEnabled}>
        <Switcher
          value={_izODZEnabled}
          onChange={handleIsEnabledChange}
          isBlocked={checkIfIsBlockedByPermissions()}
          dataTestId={'switcher'}
        />

        <Typography
          color={checkIfSelectorIsDisabled() ? 'secondaryDark' : 'generalDark'}
          variant={'title'}
          dataTestId={getDataTestId('title')['data-test-id']}
        >
          Установить область допустимых значений
        </Typography>

        {stoODZFormType.izODZEnabled ? (
          <Styled.DependentAttribute {...getDataTestId('dependent-attribute')}>
            <Typography dataTestId={getDataTestId('required-label')['data-test-id']}>
              Зависимый параметр
            </Typography>

            <Checkbox
              dataTestId={getDataTestId('dependent-attribute-checkbox')['data-test-id']}
              disable={checkIfIsBlockedByPermissions()}
              value={_isComplexODZ}
              onChange={handleIsComplexOdzDependencyChange}
            />
          </Styled.DependentAttribute>
        ) : null}
      </Styled.Header>

      {stoODZFormType.izODZEnabled ? (
        <Styled.Body>
          {stoODZFormType.isComplexODZ ? <Dependency /> : null}

          {stoODZFormType.isComplexODZ
            ? stoODZFormType.odzGroups.map(group => (
                <DependencyGroup
                  key={group.id}
                  group={group}
                  isShowButtonRemove={checkIfNeedToShowButtonRemove()}
                />
              ))
            : stoODZFormType.odzGroups.map(group => <Group key={group.id} group={group} />)}

          {checkIfNeedToShowButtonAddMore() ? (
            <ButtonLink
              dataTestId={getDataTestId('add-more')['data-test-id']}
              color={'accent'}
              onClick={handleClickButtonAddMore}
            >
              + Добавить ещё
            </ButtonLink>
          ) : null}

          {store.hasNoSelectedDependency ? (
            <Styled.ErrorText>
              Выберите значение или отключите пометку «зависимый параметр»
            </Styled.ErrorText>
          ) : null}
        </Styled.Body>
      ) : null}
    </Styled.Wrapper>
  );
};

StoAttributeOdz.displayName = 'StoAttributeOdz';

export default observer(StoAttributeOdz);
