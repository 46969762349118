import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  maxWidth: 240,
  minWidth: 240,

  backgroundColor: Colors.generalWhite,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const ListContainer = styled('div')({
  flex: 1,
});

const Styles = {
  Wrapper,
  ListContainer,
};

export default Styles;
