import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';
import { IRequestStatusActions } from '../../../../../../api/models/common/request';

@provide.singleton()
class TaskOperationConfigsService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  public getTaskOperationConfigList = async (
    payload: TypeApiRequest<'getTaskOperationConfigList'>,
    config?: {
      actions?: IRequestStatusActions<TypeApiResponse<'getTaskOperationConfigList'>>;
    }
  ): Promise<TypeApiResponse<'getTaskOperationConfigList'>> => {
    try {
      config?.actions?.handleLoading?.(true);

      const response = await this.axiosService.api.getTaskOperationConfigList(payload);

      config?.actions?.handleSuccess?.(response);

      return response;
    } catch (e) {
      config?.actions?.handleFailure?.(e);

      throw Promise.reject(e);
    } finally {
      config?.actions?.handleLoading?.(false);
    }
  };
}

export default TaskOperationConfigsService;
