import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

interface IProps {
  $backgroundColor?: string;
}

const Wrapper = styled('div')<IProps>(
  {
    maxWidth: 240,
    minWidth: 240,
  },
  ({ $backgroundColor }) => ({
    backgroundColor: $backgroundColor ?? Colors.secondaryPageBgGrey,
  })
);

const Body = styled('div')({
  height: 'calc(100% - 64px)',
  overflow: 'auto',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
});

export default {
  Wrapper,
  Body,
};
