import { FC } from 'react';
import { observer } from 'mobx-react';

import { IComparisonTableBuilderRow } from '../../../models/data';
import { ComparisonTableBuilderGrid as Grid } from '../../ComparisonTableBuilderGrid';
import { ComparisonTableBuilderCell as Cell } from '../../../components/cells';
import { ComparisonTableBuilderController as Controller } from '../../../mobx/controllers';
import { useComparisonTableBuilderContext } from '../../../context';
import { ComparisonTableBuilderCellContainer as CellContainer } from '../../cells';
import { createComparisonTableBuilderRowClassName as createRowClassName } from '../../../helpers';
import { useStore } from '../../../../../utils/IoC';

interface IProps {
  row: IComparisonTableBuilderRow;
}

const ComparisonTableBuilderRowAutoRenderContainer: FC<IProps> = ({ row }) => {
  const controller = useStore(Controller);

  const { builderId, tableName } = useComparisonTableBuilderContext();

  const cells = row?.isWithoutCells
    ? { fixedCellList: [], scrollableCellList: [] }
    : controller.getOrderedCellList(builderId, row.id);

  if (row?.isWithoutCells) {
    return (
      <div className={createRowClassName(tableName)}>
        <Cell
          preset={row.autoRenderConfig.preset}
          value={row.autoRenderConfig.name ?? ''}
          padding={row.autoRenderConfig.cellPadding}
          isMainRowCell
        />
      </div>
    );
  }

  return (
    <Grid
      fixedChildren={
        <>
          <Cell
            preset={row.autoRenderConfig.preset}
            value={row.autoRenderConfig.name ?? ''}
            padding={row.autoRenderConfig.cellPadding}
            isMainRowCell
          />

          {cells.fixedCellList.map(cell => (
            <CellContainer key={cell.columnId} cell={cell} />
          ))}
        </>
      }
      scrollableChildren={cells.scrollableCellList.map(cell => (
        <CellContainer key={cell.columnId} cell={cell} />
      ))}
    />
  );
};

ComparisonTableBuilderRowAutoRenderContainer.displayName =
  'ComparisonTableBuilderRowAutoRenderContainer';

export default observer(ComparisonTableBuilderRowAutoRenderContainer);
