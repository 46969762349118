import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskOperationCreateStore as Store } from '../../../../mobx/stores';
import { TaskOperationCreateController as Controller } from '../../../../mobx/controllers';
import { TASK_OPERATION_CREATE_FORM_REQUIRED_TEXT as FORM_REQUIRED_TEXT } from '../../constants';

interface IProps {
  dataTestId: string;
}

const TaskOperationCreateOperationSelect: FC<IProps> = ({ dataTestId }) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const hasError = store.checkIfTaskHasError('operationId');

  const errorList = useMemo<string[]>(() => {
    if (hasError) return [FORM_REQUIRED_TEXT];
    else return [];
  }, [hasError]);

  const handleChange = useCallback((operationId: string) => {
    controller.changeOperation(operationId ?? '');
  }, []);

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      isRequired: true,
      defaultValue: controller.operationDefaultValue,
      onChange: handleChange,
      placeholder: 'Выберите операцию',
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
          },
        },
      },
    },
    body: {
      optionList: store.operationSelectOptionList,
    },
    visual: {
      label: 'Операция',
      isBlocked: controller.isOperationSelectBlocked,
    },
    validation: {
      error: {
        errorList,
      },
    },
    other: {
      dataTestId,
    },
  };

  return <NewDropdown config={dropdownConfig} />;
};

TaskOperationCreateOperationSelect.displayName = 'TaskOperationCreateOperationSelect';

export default observer(TaskOperationCreateOperationSelect);
