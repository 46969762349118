import { FieldSeasonsModel } from '../../../../../../../../api/models/field.seasons.model';
import { Axios } from '../../../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import Store from '../stores/FieldSeasons.store';

@provide.transient()
class FieldSeasonsAPIService {
  @lazyInject(Axios)
  private axios: Axios;

  @lazyInject(Store)
  private store: Store;

  public fetchAllFieldSeasons(
    fieldId: string,
    isNeedToGetStoInfo?: boolean
  ): Promise<FieldSeasonsModel[] | null> {
    this.store.isLoading = true;

    const promise = this.axios.api.getFieldSeasonsById(
      {
        fieldId,
        includeAllSeasons: true,
        includeStoInfo: Boolean(isNeedToGetStoInfo),
      },
      { omit: ['fieldId'] }
    );

    return promise
      .then(({ content }) => {
        this.store.clearFieldSeasons();
        this.store.setFieldSeasons(content);

        return content;
      })
      .catch(() => null)
      .finally(() => {
        this.store.isLoading = false;
      });
  }

  public fetchNonEmptyFieldSeasons(fieldId: string): Promise<Map<number, FieldSeasonsModel>> {
    this.store.isLoading = true;

    const promise = this.axios.api.getFieldSeasonsById({
      fieldId,
      includeAllSeasons: false,
    });

    return promise
      .then(({ content }) => {
        const collection = new Map<number, FieldSeasonsModel>();

        content.forEach(obj => {
          collection.set(Number(obj.season.year), obj);
        });

        return collection;
      })
      .finally(() => {
        this.store.isLoading = false;
      });
  }

  public copyField(fieldId: string, fromSeason: FieldSeasonsModel, toSeason: FieldSeasonsModel) {
    const fromSeasonYear = fromSeason.season.year;
    const toSeasonYear = toSeason.season.year;

    const promise = this.axios.api.copyFieldById(
      { fieldId, fromSeasonYear, toSeasonYear },
      {
        omit: ['fieldId', 'fromSeasonYear', 'toSeasonYear'],
      }
    );

    return promise.catch(err => {
      throw new Error(err.response.data?.errors?.[0].title ?? 'Unknown error');
    });
  }

  public fetchCulturesForSelect(query: string) {
    return this.axios.api.findAllCultures(
      {
        nameFilter: query,
        fetchAttributes: true,
        attrs: {
          useInAssistance: true,
        },
        limit: 50,
        page: 0,
      },
      { omit: ['limit', 'page'] }
    );
  }
}

export default FieldSeasonsAPIService;
