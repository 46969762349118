import { useModal } from '@farmlink/farmik-ui';
import { useEffect } from 'react';

import { useStore } from '../../../../../../shared/utils/IoC';
import { FieldCulturesStore } from '../mobx';
import { EFieldSeasonsModalName } from '../modals';

/**
 * Хук для функционала перекрытия полигона другим полигоном.
 * {@link https://digitalagrodev.atlassian.net/browse/H15-5398}
 */
function useOverlapModal() {
  // Сторы
  const store = useStore(FieldCulturesStore);

  const modal = useModal();

  useEffect(() => {
    if (!store.overlappedCultureZonesList.length) {
      return;
    }

    modal.openModalByModalId(EFieldSeasonsModalName.ZoneOverlap);
  }, [store.overlappedCultureZonesList]);
}

export default useOverlapModal;
