import { Colors, ESpaceSize, IconButton, IIconButtonColorScheme } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import SharedStyled from '../../../../../../components/shared/styles/Shared.styles';

const Wrapper = styled('div')({
  position: 'relative',
  padding: '10px 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '63px',

  backgroundColor: Colors.generalWhite,
  borderBottom: `1px solid ${Colors.technicalBorderGrey}`,

  zIndex: 2,
});

const TextWrapper = styled(SharedStyled.TextWrapper)({
  marginLeft: 0,
  marginRight: ESpaceSize.S,
});

const IconsWrapper = styled('div')({
  display: 'flex',
  gap: ESpaceSize.S,
  alignItems: 'center',
});

const StrokeIconWrapper = styled(SharedStyled.IconWrapper)({
  '& :hover': {
    path: {
      stroke: Colors.accentGreen,
    },
  },
});

const BackIcon = styled(IconButton)({
  marginRight: ESpaceSize.S,
});

const IconsScheme: IIconButtonColorScheme = {
  default: {
    icon: Colors.generalBlack,
    background: 'transparent',
  },
  hover: {
    icon: Colors.accentGreen,
    background: 'transparent',
  },
};

const Styles = {
  Wrapper,
  TextWrapper,
  IconsWrapper,
  IconsScheme,
  StrokeIconWrapper,
  BackIcon,
};

export default Styles;
