import { cloneDeep } from 'lodash';
import { makeObservable, observable } from 'mobx';

import { MultiListFilter, TFilterMemento } from '../../../../../shared/features/FilterManager';
import { IMultiListFilterOption } from '../../interfaces/Filter.interface';
import { getUniqueCulturesNameList, sortOptionList } from '../index';
import { Field } from '../../../../../../api/models/field.model';

interface IMeta {
  area: number;
  cultures: string;
}

export type TFieldNameOption = IMultiListFilterOption<IMeta>;

class FieldNameFilter extends MultiListFilter<'name', string, Field> {
  // Список доступных для выбора опций. Является созависимым массивом
  public optionList: Array<TFieldNameOption> = [];

  constructor() {
    super('name');

    makeObservable(this, { optionList: observable });
  }

  public _getFilterRule(el): boolean {
    return this.value.includes(el.id);
  }

  public _updateState() {
    const filteredList = this._manager.getFilteredSourceList([this.id]);
    const fieldsIds = new Set(filteredList.map((field: Field) => field.id));

    this.optionList = this._manager.sourceList
      .map((field: Field) => ({
        value: field.id,
        label: field.name,
        meta: {
          area: field.area,
          cultures: getUniqueCulturesNameList(field.cultureZones).join(', ') || 'Нет культуры',
        },
        selected: this.value?.includes(field.id),
        disabled: !fieldsIds.has(field.id),
      }))
      .sort(sortOptionList);
  }

  public createMemento(): TFilterMemento {
    const value = [...this.value];
    const optionList = cloneDeep(this.optionList);

    return {
      restore: skipFiltering => {
        this.setValue(value, skipFiltering);
        this.optionList = optionList;
      },
    };
  }
}

export default FieldNameFilter;
