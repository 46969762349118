import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  gap: 8px;
  overflow: hidden;
`;

const Timeline = styled.div`
  background: ${Colors.generalWhite};
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 8px;
  overflow: hidden;
  width: 100%;

  & .react-loading-skeleton {
    height: 100%;
    transform: translateY(-1px);
    border-radius: 12px;
  }
`;

const Calendar = styled.div`
  width: 40px;
  height: 40px;
  background: ${Colors.generalWhite};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .react-loading-skeleton {
    height: 100%;
    border-radius: 4px;
    transform: translateX(-1px);
  }
`;

const Styled = {
  Wrapper,
  Timeline,
  Calendar,
};

export default Styled;
