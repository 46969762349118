import styled from 'styled-components';

const Svg = styled.svg`
  cursor: pointer;

  &:hover {
    & rect {
      transition: all 0.15s ease-in-out;

      fill: #e9ecf0;
    }
  }
`;

const StyledButtonUpload = {
  Svg,
};

export default StyledButtonUpload;
