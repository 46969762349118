import { FC } from 'react';
import { Link } from '@farmlink/farmik-ui';

import { ReactComponent as AlertIcon } from '../../../../../../../../../../../../assets/images/allert.svg';

import Styled from './Plug.styles';

interface IProps {
  onRefreshClick: () => void;
}

const TimelinePlug: FC<IProps> = props => (
  <Styled.Wrapper data-test-id="map-timeline-wrapper">
    <Styled.InfoMessage data-test-id="map-timeline-no-data">
      <AlertIcon />
      <span>Мы уже начали обрабатывать снимки, но нам нужно еще немного времени</span>
      <Link
        underlined
        color="default"
        label="Обновить"
        onClick={props.onRefreshClick}
        data-test-id="map-timeline-no-data-reload"
      />
    </Styled.InfoMessage>
  </Styled.Wrapper>
);

export default TimelinePlug;
