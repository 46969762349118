import styled from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
`;

export const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 188px;
  border-radius: 16px;
  margin-top: 24px;
`;

export const NoDataTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #151f32;
`;

export const NoDataDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #7e8b9d;
  margin-top: 4px;
`;

export const NoDataButton = styled.button.attrs({ type: 'button' })`
  box-sizing: border-box;
  width: 240px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid #3fb65f;
  border-radius: 12px;
  color: #3fb65f;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 24px;
  transition: 0.3s;

  &:hover {
    background: ${Colors.lightGreen};
  }
`;

export const StyledChecklistsPointsContainerTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  height: 100%;
`;

export const TestWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  margin: 24px 0px;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  z-index: 9999;
  margin-left: auto;
  top: 20px;
  right: 1%;
  width: 157px;
`;

export const StyledChecklistsPointsContainerTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  padding-bottom: 12px;
`;
