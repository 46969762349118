import { ModalFooter, TModalButton, useModal } from '@farmlink/farmik-ui';
import React, { FC, useState } from 'react';

import { CultureModel } from '../../../../../../../../../api/models/culture.model';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';
import { CultureSelect } from '../../../../../components';

import Styled from './AssignCultureModal.styles';

const AssignCultureModal: FC = () => {
  const [selectedCulture, setSelectedCulture] = useState<CultureModel>(null);

  const modal = useModal();
  const getDataTestId = useDataTestIdV2('fields-multiselect-assign-modal');

  const handlers = modal.getModalRuntimeHandlers();
  const payload = modal.getModalPayload();

  const handleSelect = (culture: CultureModel) => {
    setSelectedCulture(culture);
  };

  const handleSuccessClick = () => {
    handlers.onSuccess(selectedCulture);
    modal.closeModal();
  };

  const denyButton: TModalButton = {
    title: 'Отменить',
    dataTestId: getDataTestId('deny-button')['data-test-id'],
    color: 'default',
    handler: modal.closeModal,
  };

  const successButton: TModalButton = {
    title: 'Назначить',
    dataTestId: getDataTestId('success-button')['data-test-id'],
    color: 'primary',
    handler: handleSuccessClick,
  };

  return (
    <Styled.Wrapper>
      <Styled.Title>Назначить культуру</Styled.Title>
      <Styled.Description>
        <b>Выбраны поля: {payload.selectedFieldsCount}. </b>
        Если на поля ранее была назначена другая культура, она будет заменена
      </Styled.Description>

      <Styled.Divider />

      <CultureSelect onChange={handleSelect} dataTestId={'culture-select'} />

      <Styled.ModalFooterContainer>
        <ModalFooter
          dataTestId={getDataTestId('footer')['data-test-id']}
          denyButton={denyButton}
          successButton={successButton}
        />
      </Styled.ModalFooterContainer>
    </Styled.Wrapper>
  );
};

export default AssignCultureModal;
