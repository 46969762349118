import { Listbox } from '@headlessui/react';
import { Icon } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React from 'react';

import { EFieldFill } from '../../../../../../../../../dashboard/constants/FieldFill.enum';
import { ESelectType, SelectWithCustomButton } from '../../../../../../../../components';
import { useStore } from '../../../../../../../../utils/IoC';
import { IndicesStore } from '../../mobx';

import Styled from './FillSelector.styles';

const OPTION_LIST: { label: string; value: EFieldFill }[] = [
  { label: 'NDVI', value: EFieldFill.Ndvi },
  { label: 'Без заливки', value: EFieldFill.None },
];

const FillSelector = () => {
  const { setCurrentFillMode, currentFillMode } = useStore(IndicesStore);

  const getLabelByValue = (value: EFieldFill) => {
    return OPTION_LIST.find(item => item.value === value)?.label;
  };

  return (
    <SelectWithCustomButton
      type={ESelectType.SINGLE}
      label={'Заливка'}
      value={currentFillMode}
      optionList={OPTION_LIST}
      renderButton={value => (
        <Listbox.Button as={Styled.SmallButton}>
          <Icon icon={'fillLayer'} size={'medium'} />
          {getLabelByValue(value)}
        </Listbox.Button>
      )}
      onChange={setCurrentFillMode}
    />
  );
};

export default observer(FillSelector);
