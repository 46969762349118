import { FC, memo, useMemo } from 'react';

import operationValuesSvg from '../../../../../../../../operations/components/TaskView/assets/operationValuesSvg.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../../../shared/features/utils/hooks/locators';
import { ITaskCreateOperationValue } from '../../../../../../../../../../../api/models/as-fields/task/task.model';

interface IProps {
  taskCreateOperationValues: ITaskCreateOperationValue[];
  dataTestId: string;
}
const TaskOperationCreateVerificationSprayingType: FC<IProps> = ({
  taskCreateOperationValues,
  dataTestId,
}) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__spraying-type`);

  const taskOperationValuesInfo = useMemo(() => {
    if (!taskCreateOperationValues) return;

    return taskCreateOperationValues?.map(({ systemData }) => systemData.option.label).join(', ');
  }, [taskCreateOperationValues]);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label color={'generalDarkHover'} {...getDataTestId('label')}>
        Тип опрыскивания
      </Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-svg')} src={operationValuesSvg} />

      <Styled.Info {...getDataTestId('info')}>{taskOperationValuesInfo}</Styled.Info>
    </Styled.InfoBlock>
  );
};

TaskOperationCreateVerificationSprayingType.displayName =
  'TaskOperationCreateVerificationSprayingType';

export default memo(TaskOperationCreateVerificationSprayingType);
