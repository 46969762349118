import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { NewDropdown, TNewDropdownProps, useModal } from '@farmlink/farmik-ui';

import { ISelectOption } from '../../../../../../../../../../../../../../../../../../../../../../../../types/selectOption';
import { IStoAttribute as IAttribute } from '../../../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { StoAttributeOdzModals } from '../../../../modals';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreController as Controller } from '../../../../../../../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../../../../../../../../../hooks';
import { ButtonRemove } from '../../../../../../../../../../../../../../../../../../../../../../../shared/features/UI/new/ButtonRemove';

interface IProps {
  dataTestId: string;
  id: string;
  defaultValue: ISelectOption<IAttribute>;
  optionList: ISelectOption<IAttribute>[];
  isBlocked: boolean;
  isShowButtonRemove: boolean;
}

const StoAttributeOdzDepSelect: FC<IProps> = ({
  dataTestId,
  id,
  defaultValue,
  optionList,
  isBlocked,
  isShowButtonRemove,
}) => {
  const controller = useStore(Controller);

  const params = useStoParams();
  const modalActions = useModal();

  /**
   * Специальная логика, чтобы заставить работать правильно дропдаун с модальным окном при выборе значения.
   */
  const [_selectedValue, _setSelectedValue] = useState<ISelectOption | null>(null);

  useEffect(() => {
    _setSelectedValue(defaultValue);
  }, [defaultValue]);

  const checkIfNeedToShowButtonRemove = (): boolean => {
    if (isBlocked) return false;

    return isShowButtonRemove;
  };

  const handleChange: TNewDropdownProps['config']['field']['onChange'] = (_, otherData) => {
    if (controller.checkIfOdzGroupsIsEmpty(params)) {
      if (!otherData?.option) {
        controller.changeDependencySelect(params, { id, attr: null });
        return;
      }

      controller.changeDependencySelect(params, { id, attr: otherData.option.initialModel });
      return;
    }

    _setSelectedValue(otherData?.option);

    const handleSuccess = (): void => {
      if (!otherData?.option) {
        controller.changeDependencySelect(params, { id, attr: null });
        return;
      }

      controller.changeDependencySelect(params, { id, attr: otherData.option.initialModel });
      _setSelectedValue(otherData.option);
    };

    const handleDeny = (): void => {
      _setSelectedValue(defaultValue);
    };

    modalActions.openModalByModalId(
      StoAttributeOdzModals.warningBeforeDependencyChange.id,
      null,
      handleSuccess,
      handleDeny
    );
  };

  const handleButtonRemoveClick = useCallback((): void => {
    if (controller.checkIfOdzGroupsIsEmpty(params)) {
      controller.removeDependencySelect(id, params);
      return;
    }

    modalActions.openModalByModalId(
      StoAttributeOdzModals.warningBeforeDependencyChange.id,
      null,
      () => {
        controller.removeDependencySelect(id, params);
      }
    );
  }, []);

  return (
    <NewDropdown
      config={{
        field: {
          onChange: handleChange,
          defaultValue: _selectedValue,
          icons: {
            clear: {},
          },
          placeholder: 'Выберите значение',
        },
        body: {
          optionList,
        },
        visual: {
          label: 'Определяющий параметр',
          isBlocked,
          labelAdditionalContent: checkIfNeedToShowButtonRemove() ? (
            <ButtonRemove dataTestId={dataTestId} size={16} onClick={handleButtonRemoveClick} />
          ) : null,
        },
        other: {
          dataTestId,
        },
      }}
    />
  );
};

StoAttributeOdzDepSelect.displayName = 'StoAttributeOdzDepSelect';

export default memo(StoAttributeOdzDepSelect);
