import useDataTestIdV2 from '../../../../../utils/hooks/locators/useDataTestIdV2/useDataTestIdV2';
import { useContainerContext as useContext } from '../../context/ContainerContext/hooks';

const useContainerDataTestId = (initialDataTestId?: string): ReturnType<typeof useDataTestIdV2> => {
  const { dataTestId } = useContext();

  const getDataTestId = useDataTestIdV2(`${initialDataTestId ?? dataTestId}__container`);

  return getDataTestId;
};

export default useContainerDataTestId;
