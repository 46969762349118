import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const NewChip = styled('span')({
  display: 'block',
  padding: '0 2px',
  backgroundColor: Colors.lightSky,
  color: Colors.addSky,
  borderRadius: ESpaceSize.XS,
  marginTop: ESpaceSize.XS,

  fontSize: 10,
  fontWeight: 400,
  lineHeight: '16px',
});

export default {
  NewChip,
};
