import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { FieldCulturesController as Controller } from '../../../../../../mobx';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { IInputProps } from '../../types/CommonProps.types';
import { CultureSelect as Select } from '../../../../../../../../components';

const CultureSelect: FC<IInputProps> = props => {
  // Контроллеры
  const controller = useStore(Controller);

  return (
    <Select
      isRequired
      disabled={!props.cultureZone}
      defaultValue={props.cultureZone}
      dataTestId={props.dataTestId}
      onChange={culture => {
        controller.changeCultureOfZone(props.cultureZone, culture);
      }}
    />
  );
};

export default observer(CultureSelect);
