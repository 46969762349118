import styled from 'styled-components';

const ListingContent = styled.div`
  padding-right: 10px;
`;

const StyledFieldsTabListing = {
  ListingContent,
};

export default StyledFieldsTabListing;
