// @ts-nocheck
/* eslint-disable */

import L from 'leaflet';

const moveEndFn = L.Tooltip.prototype._updatePosition;

/**
 * Данный плагин позволяет избежать коллизий при рендере тултипов. Если 2 тултипа визуально пересекаются то мы скрываем один из тултипов.
 * В текущей реализации нет возможности контролировать какой из двух тултипов будет скрыт
 */
L.Tooltip.include({
  // Переопределяем лифлетовскую функцию которая вызывается каждый раз когда нужно перестроить положение тултипа. (Zoom, addToMap и ещё некоторые триггеры)
  _updatePosition() {
    moveEndFn.call(this);

    const tooltips = [];
    let intersects = false;
    // Получаем координаты DOM контейнера тултипа
    const rectSelection = this._container.getBoundingClientRect();

    /**
     * Извлекаем все слои которые являются тултипами. Будут извлечены только тултипы которые сейчас находятся во viewport.
     * Надо подумать над тем, чтобы вынести отсюда этот цикл. Возможно стоит заполнять отдельную коллекцию с тултипами при их добавлении на карту
     */
    this._map.eachLayer(layer => {
      if (layer instanceof L.Tooltip && layer._leaflet_id !== this._leaflet_id) {
        tooltips.push(layer);
      }
    });

    /**
     * Проверяем пересечения
     */
    tooltips.forEach(tooltip => {
      // Пропускаем тултипы которые уже имеют пересечения, т.к они не видны на карте и в проверке пересечения нет смысла
      if (tooltip._intersects) {
        return;
      }

      // Получаем координаты DOM контейнера тултипа
      const rect = tooltip._container.getBoundingClientRect();

      // Проверка
      if (this.isIntersect(rect, rectSelection)) {
        // Не удаляем тултип а меняем ему opacity. При удалении возникают странные side эффекты
        this.setOpacity(0);

        // Помечаем что тултип имеет пересечение
        this._intersects = true;
        intersects = true;
      }
    });

    // Если после проверок у тултипа нет пересечений, НО они были до этого. Показываем тултип
    if (!intersects && this._intersects) {
      this.setOpacity(1);
      this._intersects = false;
    }
  },

  isIntersect(rect1, rect2) {
    return (
      rect1.bottom > rect2.top &&
      rect1.right > rect2.left &&
      rect1.top < rect2.bottom &&
      rect1.left < rect2.right
    );
  },
});
