import styled from 'styled-components';

import closeIconDefault from './assets/icons/close-icon-default-12x12.svg';
import closeIconHover from './assets/icons/close-icon-hover-12x12.svg';

const CloseButton = styled.div`
  width: 12px;
  height: 12px;

  flex: 0 0 12px;

  cursor: pointer;

  background: url(${closeIconDefault});
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex: 1 1 50%;

  margin-right: 4px;

  color: #151f32;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  max-width: 200px;

  border-radius: 16px;

  padding: 4px 12px;

  background-color: #e7f1fd;

  :hover {
    background-color: #bedbff;
  }

  :hover ${CloseButton} {
    background: url(${closeIconHover});
  }
`;

const StyledTableFiltersBuilderTag = {
  CloseButton,
  Label,
  Wrapper,
};

export default StyledTableFiltersBuilderTag;
