import React, { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';

import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { useStoNavigation, useStoParams } from '../../../../../../../../hooks';
import { useWarningBeforeLeavingThePage } from '../../../../../../../../../../../shared/hooks';

import { StoChecklistsCoreController as Controller } from './mobx/controllers';
import { StoChecklistsCoreStore as Store } from './mobx/stores';
import { StoChecklistsCarousel as Carousel } from './containers';
import Styled from './StoChecklists.styles';
import { StoAttributeCoreController as AttributeController } from './modules/StoChecklist/modules/StoAttribute/mobx/controllers';

const StoChecklists: FC = () => {
  const store = useStore(Store);

  const controller = useStore(Controller);
  const attributeController = useStore(AttributeController);

  const params = useStoParams();
  const navigations = useStoNavigation();

  const pathListWithoutModalWarning = useMemo(() => {
    return controller.getPathListWithoutModalWarning(params);
  }, [store.checklistList]);

  useWarningBeforeLeavingThePage(store.hasChanges, pathListWithoutModalWarning);

  useEffect(() => {
    (async () => {
      const tableList = await controller.initiateTables({
        id: params.stoId,
        operationTypeId: params.operationId,
        ...(params.versionId && { ...(params.versionId && { versionId: params.versionId }) }),
      });

      if (!tableList.length) return;

      if (params.checklistId) {
        const alreadySelectedTable = tableList.find(table => table.id === params.checklistId);

        if (!alreadySelectedTable) return;

        if (params.stageId && params.attributeId) {
          navigations.navigateToAttribute(params, {
            query: { ...(params.versionId && { versionId: params.versionId }) },
          });

          return;
        }

        navigations.navigateToChecklist(params, {
          query: { ...(params.versionId && { versionId: params.versionId }) },
        });

        return;
      }

      const [firstTable] = tableList;

      navigations.navigateToChecklist(
        {
          ...params,
          checklistId: firstTable.id,
        },
        {
          query: { ...(params.versionId && { versionId: params.versionId }) },
        }
      );
    })();

    return () => {
      controller.clearStore();
      attributeController.clearAlreadyChangedAttrIds();
    };
  }, []);

  return (
    <Styled.Wrapper>
      {store.checklistList.length < 2 ? null : <Carousel />}

      {store.isLoading ? null : <Outlet />}
    </Styled.Wrapper>
  );
};

StoChecklists.displayName = 'StoChecklists';

export default observer(StoChecklists);
