import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { IGetChecklistStage as IStage } from '../../../../../../../../api/models/checklist/stage/checklist.stage.model';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../mobx/controllers';
import { ChecklistsStageContainer as StageContainer } from '../elements';
import { ChecklistsStore } from '../../mobx/stores';
import { useSortBy } from '../../../../../../../shared/features/utils/hooks/sort';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';

import Styled from './ChecklistsChecklistContainer.styles';

const ChecklistsChecklistContainer: FC = () => {
  const getDataTestId = useDataTestIdV2('checklists__checklist');

  const {
    selectedChecklist,
    attrIdWithError,
    isAttrIdWithErrorTargeted,
    clearIsAttrIdWithErrorTargeted,
  } = useStore(ChecklistsStore);

  const { fetchStageList, clearChecklistsStore } = useStore(ChecklistsController);

  const [stageList, setStageList] = useState<IStage[]>([]);

  useEffect(() => {
    (async () => {
      if (!selectedChecklist) return;

      const fetchedStageList = await fetchStageList();
      setStageList(fetchedStageList);
    })();
  }, [selectedChecklist]);

  useEffect(() => {
    return () => {
      clearChecklistsStore();
      setStageList([]);
    };
  }, []);

  useEffect(() => {
    if (!attrIdWithError) return;

    const attrWithErrorNode = document.querySelector(`[id='${attrIdWithError}']`);

    if (!attrWithErrorNode) return;

    attrWithErrorNode.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });

    clearIsAttrIdWithErrorTargeted();
  }, [isAttrIdWithErrorTargeted]);

  const orderedStageList = useSortBy(stageList);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      {orderedStageList.map(stage => (
        <StageContainer key={stage.id} stage={stage} />
      ))}
    </Styled.Wrapper>
  );
};

ChecklistsChecklistContainer.displayName = 'ChecklistsChecklistContainer';

export default observer(ChecklistsChecklistContainer);
