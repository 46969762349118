import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import AssignCultureModal from './components/AssignCultureModal/AssignCultureModal';
import DeleteFieldModal from './components/DeleteFieldModal/DeleteFieldModal';

const FIELDS_MULTISELECT_MODALS_UNIQUE_KEY = 'fieldsMultiselect';

enum EFieldsMultiselectModalName {
  assignCulture = 'ASSIGN_CULTURE',
  deleteField = 'DELETE_FIELD',
  backWithoutSave = 'BACK_WITHOUT_SAVE',
  resetChanges = 'RESET_CHANGES',
}

const assignCultureModalConfig: TModalConfig = {
  id: EFieldsMultiselectModalName.assignCulture,
  name: EFieldsMultiselectModalName.assignCulture,
  dataTestId: EFieldsMultiselectModalName.assignCulture,
  title: '',
  type: EModalType.Custom,
  children: AssignCultureModal,
  styledProps: {
    $isFullContentWidth: true,
    $modalPaddings: '0px',
    $size: '450px',
  },
};

const deleteFieldModalConfig: TModalConfig = {
  id: EFieldsMultiselectModalName.deleteField,
  name: EFieldsMultiselectModalName.deleteField,
  dataTestId: EFieldsMultiselectModalName.deleteField,
  title: '',
  type: EModalType.Custom,
  children: DeleteFieldModal,
  styledProps: {
    $isFullContentWidth: true,
    $modalPaddings: '40px',
    $size: '450px',
  },
};

const backWithoutSaveModalConfig: TModalConfig = {
  id: EFieldsMultiselectModalName.backWithoutSave,
  name: EFieldsMultiselectModalName.backWithoutSave,
  dataTestId: EFieldsMultiselectModalName.backWithoutSave,
  title: 'Вы уверены, что хотите выйти из режима редактирования, не сохранив изменения?',
  type: EModalType.Warning_Before_Living,
  successButton: {
    title: 'Выйти',
    color: 'secondary',
  },
  denyButton: {
    title: 'Остаться',
    color: 'default',
  },
  styledProps: {
    $isFullContentWidth: true,
    $size: '450px',
  },
};

const resetMultiselectModalConfig: TModalConfig = {
  id: EFieldsMultiselectModalName.resetChanges,
  name: EFieldsMultiselectModalName.resetChanges,
  dataTestId: EFieldsMultiselectModalName.resetChanges,
  title: 'Вы уверены, что не хотите сохранить внесенные изменения?',
  type: EModalType.Warning_Before_Living,
  successButton: {
    title: 'Не сохранять',
    color: 'secondary',
  },
  denyButton: {
    title: 'Вернуться',
    color: 'default',
  },
  styledProps: {
    $isFullContentWidth: true,
    $size: '450px',
  },
};

const fieldsMultiselectModalConfigList = [
  deleteFieldModalConfig,
  assignCultureModalConfig,
  backWithoutSaveModalConfig,
  resetMultiselectModalConfig,
];

export {
  fieldsMultiselectModalConfigList,
  FIELDS_MULTISELECT_MODALS_UNIQUE_KEY,
  EFieldsMultiselectModalName,
};
