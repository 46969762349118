import { TypeApiRoute } from '../../../models/type.api.request';
import { IStoChecklistsParamsTable } from '../../../models/as-fields/new/sto';
import { IStoChecklistsAttrsTableChecklistUpdate } from '../../../models/as-fields/new/sto/StoChecklistsParamsTable';

type TRequest = {
  id: string;
  operationTypeId: string;
  checklists: IStoChecklistsAttrsTableChecklistUpdate[];
};

type TResponse = IStoChecklistsParamsTable;

export const saveStoChecklistsAttrsTable: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id, operationTypeId }) =>
    `/api/as-fields/sto/${id}/techOperationType/${operationTypeId}/checklists`,
  method: 'PUT',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
