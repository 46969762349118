import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import conditionalObjectProp from '../../../../../../../../../../../../../shared/utils/conditionalObjectProp';

interface IListItemProps {
  disabled: boolean;
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: ESpaceSize.S,
});

const ListItem = styled('div')<IListItemProps>(
  {
    cursor: 'pointer',
  },
  ({ disabled }) => ({
    '& *': {
      ...conditionalObjectProp(disabled, { color: Colors.hoverBorder }),
    },
  })
);

const Styles = {
  Wrapper,
  ListItem,
};

export default Styles;
