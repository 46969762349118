import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../utils/IoC';
import { useDataTestId } from '../utils/hooks/locators';

import {
  TableFiltersBuilderFilters as Filters,
  TableFiltersBuilderPresets as Presets,
  TableFiltersBuilderTags as Tags,
} from './containers';
import { TableFiltersBuilderStore as BuilderStore } from './mobx/stores';
import { TableFiltersBuilderController as BuilderController } from './mobx/controllers';
import Styled from './TableFiltersBuilder.styles';
import { TableFiltersBuilderUIContext as UIContext } from './context/UI';
import { TableFiltersBuilderHeader as Header } from './containers/header/TableFiltersBuilderHeader';

interface IProps {
  builderId: string;
}

const TableFiltersBuilder: FC<IProps> = ({ builderId }) => {
  const builderStore = useStore(BuilderStore);
  const builderController = useStore(BuilderController);

  const isBlocked = builderStore.getIsBlocked(builderId);
  const isShowFilters = Boolean(builderStore.getIsShowFilters(builderId));
  const hasAppliedValues = builderController.hasAppliedValueList(builderId);
  const hasPresets = Boolean(builderStore.getPresetList(builderId).length);
  const hasFilters = Boolean(builderStore.getFilterList(builderId).length);

  useEffect(() => {
    return () => {
      builderController.clearStore(builderId);
    };
  }, []);

  const getDataTestId = useDataTestId(`${builderId}-table-filters`);

  return (
    <UIContext.Provider value={{ builderId }}>
      <Styled.Wrapper {...getDataTestId()}>
        <Styled.HeaderWrapper {...getDataTestId('header-wrapper')}>
          <Header />
        </Styled.HeaderWrapper>

        {!isBlocked ? (
          <>
            <Styled.Content {...getDataTestId('content-wrapper')}>
              {hasPresets ? (
                <Styled.ContentPresets {...getDataTestId('presets-wrapper')}>
                  <Presets builderId={builderId} />
                </Styled.ContentPresets>
              ) : null}

              {isShowFilters && hasFilters ? (
                <Styled.ContentFilters {...getDataTestId('filters-wrapper')}>
                  <Filters />
                </Styled.ContentFilters>
              ) : null}
            </Styled.Content>

            {hasAppliedValues && !isShowFilters ? (
              <Styled.FooterWrapper {...getDataTestId('footer-wrapper')}>
                <Tags />
              </Styled.FooterWrapper>
            ) : null}
          </>
        ) : null}
      </Styled.Wrapper>
    </UIContext.Provider>
  );
};

TableFiltersBuilder.displayName = 'TableFiltersBuilder';

export default observer(TableFiltersBuilder);
