import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { useStore } from '../../../../utils/IoC';
import { ContextualLinkCoreStore as Store } from '../../mobx/stores';
import { ContextualLinkCoreController as Controller } from '../../mobx/controllers';
import { IContextualLink as ILink, IContextualLinkConfig as ILinkConfig } from '../../models';

const useContextualLink = () => {
  const { getLink } = useStore(Store);
  const { addLink, addStoredLinks, createLink, removeLink, setStateFrom } = useStore(Controller);

  const navigate = useNavigate();

  const logDefaultLink = useCallback((defaultLink: ILink): void => {
    const prevLink = getLink(defaultLink.id);

    if (prevLink) return;

    addLink(defaultLink);
  }, []);

  const logLink = useCallback((config: ILinkConfig, navigateOptions?: any): void => {
    createLink(config);

    navigate(config.to.url, navigateOptions);
  }, []);

  const handleReturnClick = useCallback((id: string): void => {
    const link = getLink(id);

    if (!link.from?.url) return;

    navigate(link.from.url);
  }, []);

  return {
    getLink,
    removeLink,
    logDefaultLink,
    logLink,
    setStateFrom,
    onReturnClick: handleReturnClick,
    addStoredLinks,
  };
};

export default useContextualLink;
