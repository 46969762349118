import React, { FC } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import {
  CultureItemBody,
  CultureItemName,
  CultureItemField,
  CultureItemImg,
  CultureItemFieldCount,
  CultureItemFieldArea,
} from './style';

type CultureItemType = {
  id?: string;
  name: string;
  fieldCount: string;
  totalArea?: string;
  img: string;
  selected: boolean;
  onClick: () => void;
  isDisabled: boolean;
};

const MAX_CULTURE_LENGTH = 12;

export const CultureItem: FC<CultureItemType> = ({
  id,
  name,
  fieldCount,
  totalArea,
  img,
  selected,
  onClick,
  isDisabled = false,
}) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>([]);

  return (
    <CultureItemBody
      isDisabled={isDisabled && !selected}
      id={id}
      active={selected}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
      data-test-id={'operation-culture-item'}
    >
      <AutoTooltip
        disabled={!isShowTooltip || name.length < MAX_CULTURE_LENGTH}
        dataTestId={`current-item-tooltip-${id}`}
        content={name}
      >
        <CultureItemName ref={ref} data-test-id={'operation-culture-item-name'}>
          {name}
        </CultureItemName>
      </AutoTooltip>

      <CultureItemField data-test-id={'operation-culture-item-fieldCount'}>
        <CultureItemFieldCount data-test-id={'operation-culture-item-fieldCount-value'}>
          {fieldCount}
        </CultureItemFieldCount>
        <CultureItemFieldArea data-test-id={'operation-culture-item-fieldCount-area'}>
          {totalArea}
        </CultureItemFieldArea>
      </CultureItemField>

      <CultureItemImg src={img} data-test-id={'operation-culture-item-img'}></CultureItemImg>
    </CultureItemBody>
  );
};
