import { FC } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './ChecklistsFormulaIcon.styles';

const ChecklistsFormulaIcon: FC = () => {
  const getDataTestId = useDataTestIdV2('checklists__formula-icon');

  return <Styled.Wrapper {...getDataTestId()} />;
};

ChecklistsFormulaIcon.displayName = 'ChecklistsFormulaIcon';

export default ChecklistsFormulaIcon;
