import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OperationsStore } from '../stores/operations.store';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { Operation } from '../../../../../api/models/operations/operation.model';

type FormData = {
  operationTypeId: string;
  startDate: string;
  endDate: string;
  name: string;
};

@provide.singleton()
export class CreateOperationFormController {
  @lazyInject(OperationsStore)
  operationsStore: OperationsStore;

  @lazyInject(OrganizationsStore)
  organizationStore: OrganizationsStore;

  @lazyInject(SeasonsStore)
  seasonStore: SeasonsStore;

  handleCreateOperation = async (formData: FormData, selectedCultureId: string) => {
    const organizationId =
      this.organizationStore.selectedOrganizationId === 'my'
        ? ''
        : this.organizationStore.selectedOrganizationId;

    try {
      return await this.operationsStore.createOperation(
        {
          organizationId,
          operationTypeId: formData.operationTypeId,
          startDate: formData.startDate,
          endDate: formData.endDate,
          seasonYear: this.seasonStore.selectedSeassonData.year,
          name: formData.name,
          cultureId: selectedCultureId !== 'emptyCulture' ? selectedCultureId : undefined,
        },
        selectedCultureId
      );
    } catch (e) {
      return Promise.reject(e);
    }
  };

  handleEditOperation = async (
    formData: FormData,
    operationId: string,
    selectedCultureId: string
  ) => {
    try {
      return await this.operationsStore.editOperation({
        operationTypeId: formData.operationTypeId,
        startDate: formData.startDate,
        endDate: formData.endDate,
        seasonYear: this.seasonStore.selectedSeassonData.year,
        name: formData.name,
        cultureId: selectedCultureId !== 'emptyCulture' ? selectedCultureId : undefined,
        operationId,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getOperationById = (operationId: string): Operation => {
    return this.operationsStore.OperationsList.find(operation => operation.id === operationId);
  };
}
