import { NewScrollbar, useSidebar } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, memo, useEffect } from 'react';

import { useStore } from '../../utils/IoC';
import { UiStore } from '../../../dashboard/stores/ui.store';

import Styled, { IFullscreenFooterProps } from './styles';
import FullscreenBackButton, { IFullscreenBackButtonProps } from './FullscreenBackButton';

export type IFullscreenProps = React.PropsWithChildren<
  {
    contentStyle?: IFullscreenFooterProps;
    className?: string;
    actionButton?: {
      label?: string;
      onClick?: () => void;
    };
    onClose?: () => void;
  } & IFullscreenBackButtonProps
>;

const Fullscreen: FC<IFullscreenProps> = observer(props => {
  const {
    children,
    backButtonHandler,
    backButtonText,
    actionButton,
    rightSideContent,
    arrowText,
    titleAdditionalElement,
    contentStyle,
    underTitleContent,
  } = props;
  const uiStore = useStore(UiStore);

  const { setSidebarVisibility } = useSidebar();

  useEffect(() => {
    setSidebarVisibility(false);

    return () => {
      setSidebarVisibility(true);
    };
  }, []);

  return (
    <Styled.FullscreenWrapper
      $isShort={uiStore.isAdaptive || uiStore.isSidebarShort}
      className={`${props?.className || ''} fullscreen-header`}
    >
      <Styled.FullScreenHeader className={`fullscreen-header`}>
        <FullscreenBackButton
          backButtonText={backButtonText}
          backButtonHandler={backButtonHandler}
          rightSideContent={rightSideContent}
          arrowText={arrowText}
          titleAdditionalElement={titleAdditionalElement}
          underTitleContent={underTitleContent}
          actionButton={
            actionButton && (
              <Styled.ActionButton onClick={actionButton.onClick}>
                {actionButton.label}
              </Styled.ActionButton>
            )
          }
        />
      </Styled.FullScreenHeader>
      <NewScrollbar>
        <Styled.FullscreenFooter {...contentStyle}>
          <Styled.Fullscreen>{children}</Styled.Fullscreen>
        </Styled.FullscreenFooter>
      </NewScrollbar>
    </Styled.FullscreenWrapper>
  );
});

export default memo(Fullscreen);
