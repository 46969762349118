import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  position: relative;
`;

const Line = styled.div`
  width: 248px;
  height: 0.5px;
  background: ${Colors.borderGray};
  position: absolute;
  bottom: 0;
  left: 16px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
`;

const MapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: ${Colors.generalWhite};
  border: 1px solid ${Colors.borderGray};
  & svg {
    fill: ${Colors.darkGrey};
    width: 16px;
    height: 16px;
  }
`;

const RightColumn = styled.div`
  width: 192px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BottomSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;
const AreaLabel = styled.p<{ isError?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGrey};
  margin: 0;

  color: ${({ isError }) => isError && Colors.red};
`;

const Delete = styled.p`
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.red};
  margin: 0;
`;

export default {
  Wrapper,
  ContentWrapper,
  Line,
  MapIcon,
  RightColumn,
  BottomSectionWrapper,
  AreaLabel,
  Delete,
};
