import { Badge, EBadgeSize, EIconButtonSize, Icon, IconButton } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../../../../stores/seasons.store';
import SharedStyled from '../../../../../../components/shared/styles/Shared.styles';
import { useFieldsRoutes } from '../../../../../../hooks';
import { ELeftPanelType } from '../../../../interfaces/LeftPanelType.enum';
import { FieldsViewStore as Store } from '../../../../mobx';

import SearchInput from './components/SearchInput/SearchInput';
import Styled from './Header.styles';

interface IProps {
  search?: boolean;
  filter?: boolean;
  multiselect?: boolean;
  backButton?: boolean;
  onBackClick?: () => void;
}

const LeftPanelHeader: FC<IProps> = props => {
  const { multiselect, filter, search, backButton } = props;

  // Навигация
  const navigate = useNavigate();
  const fieldsRoutes = useFieldsRoutes();

  // Сторы
  const store = useStore(Store);
  const seasonsStore = useStore(SeasonsStore);
  const orgStore = useStore(OrganizationsStore);

  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  const filterManager = store.filterManager;
  const seasonLabel = seasonsStore.selectedSeassonData?.label ?? '';
  const orgName = orgStore.currentOrganization?.name ?? 'Организация';

  const getDataTestId = useDataTestIdV2('fields-view-leftpanel-header');

  const handleMultiselectClick = () => {
    navigate(fieldsRoutes.fieldsMultiselectRoute);
  };

  const handleFilterClick = () => {
    store.leftPanelType = ELeftPanelType.FILTER;
  };

  const handleBackClick = () => {
    if (typeof props.onBackClick === 'function') {
      props.onBackClick();

      return;
    }

    store.leftPanelType = ELeftPanelType.FIELDS_LIST;
  };

  const getIconDataTestId = (secondary: string) => {
    return getDataTestId(`${secondary}-icon`)['data-test-id'];
  };

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      {backButton && (
        <Styled.BackIcon
          icon={'arrow_left'}
          size={EIconButtonSize.x12}
          colorScheme={SharedStyled.hoverIconColorScheme}
          onClick={handleBackClick}
          dataTestId={getDataTestId('back-icon')['data-test-id']}
        />
      )}

      <Styled.TextWrapper>
        <SharedStyled.Title {...getDataTestId('title')} fontSize={16} fontWeight={600}>
          {orgName}
        </SharedStyled.Title>

        <SharedStyled.Subtitle {...getDataTestId('subtitle')}>{seasonLabel}</SharedStyled.Subtitle>
      </Styled.TextWrapper>

      <Styled.IconsWrapper {...getDataTestId('icons')}>
        {multiselect && (
          <Styled.StrokeIconWrapper onClick={handleMultiselectClick}>
            <Icon
              icon={'multiselect'}
              size={'medium'}
              dataTestId={getIconDataTestId('multiselect')}
            />
          </Styled.StrokeIconWrapper>
        )}

        {filter && (
          <Styled.StrokeIconWrapper onClick={handleFilterClick}>
            <Badge
              content={filterManager?.filters.getCounter()}
              size={EBadgeSize.x14}
              position={{ top: -2, right: 0 }}
              hideEmpty
            >
              <Icon icon={'filter'} size={'medium'} dataTestId={getIconDataTestId('filter')} />
            </Badge>
          </Styled.StrokeIconWrapper>
        )}

        {search && (
          <IconButton
            icon={'search'}
            size={EIconButtonSize.x16}
            onClick={() => setIsSearchOpen(true)}
            colorScheme={Styled.IconsScheme}
            dataTestId={getIconDataTestId('search')}
          />
        )}
      </Styled.IconsWrapper>

      {isSearchOpen && <SearchInput onBackClick={() => setIsSearchOpen(false)} />}
    </Styled.Wrapper>
  );
};

export default observer(LeftPanelHeader);
