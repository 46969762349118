import L from 'leaflet';
import './clusterIcon.style.css';

function ClusterIcon(elementsCount: number) {
  if (elementsCount > 0 && elementsCount < 15) {
    return L.divIcon({
      html: String(elementsCount),
      className: 'base-cluster-icon',
      iconSize: [32, 32],
    });
  }

  if (elementsCount >= 15 && elementsCount < 50) {
    return L.divIcon({
      html: String(elementsCount),
      className: 'base-cluster-icon',
      iconSize: [40, 40],
    });
  }

  if (elementsCount >= 50 && elementsCount < 999) {
    return L.divIcon({
      html: String(elementsCount),
      className: 'base-cluster-icon',
      iconSize: [64, 64],
    });
  }

  return L.divIcon({
    html: String(elementsCount),
    className: 'base-cluster-icon',
    iconSize: [80, 80],
  });
}

export default ClusterIcon;
