import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import useTasksParams from '../useTasksParams/useTasksParams';
import { TasksRoute } from '../../tasks.route';
import { useContextualLink } from '../../../../../shared/features/ContextualLink/hooks';
import { EContextualLinkId } from '../../../../constants/configs/ContextualLinkId';
import { TTasksContextualLinkConfig } from '../../types';

type TContextualReturn = TTasksContextualLinkConfig['from'];

const createContextualLinkConfig = (
  contextualReturn: TContextualReturn,
  path: string
): TTasksContextualLinkConfig => {
  return {
    id: EContextualLinkId.TasksTaskBackButton,
    from: contextualReturn,
    to: {
      url: path,
    },
  };
};

const useTasksTaskOperationRoutes = () => {
  const navigate = useNavigate();
  const params = useTasksParams();

  const contextualLink = useContextualLink();

  const getCommonInfoPath = useCallback((): string => {
    return generatePath(TasksRoute.TaskOperationCreateCommonInfo, {
      orgId: params.orgId,
    });
  }, [params.orgId]);

  const goToCommonInfo = useCallback(
    (contextualReturn?: TContextualReturn): void => {
      const path = getCommonInfoPath();

      if (!contextualReturn) {
        navigate(path);
        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      contextualLink.logLink(contextualLinkConfig);
    },
    [getCommonInfoPath, contextualLink.logLink]
  );

  const getFieldsPath = useCallback((): string => {
    return generatePath(TasksRoute.TaskOperationCreateFields, {
      orgId: params.orgId,
    });
  }, [params.orgId]);

  const getVerificationPath = useCallback((): string => {
    return generatePath(TasksRoute.TaskOperationCreateVerification, {
      orgId: params.orgId,
    });
  }, [params.orgId]);

  const goToFields = useCallback(
    (contextualReturn?: TContextualReturn): void => {
      const path = getFieldsPath();

      if (!contextualReturn) {
        navigate(path);
        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      contextualLink.logLink(contextualLinkConfig);
    },
    [getFieldsPath, contextualLink.logLink]
  );

  const goToVerification = useCallback(
    (contextualReturn?: TContextualReturn): void => {
      const path = getVerificationPath();

      if (!contextualReturn) {
        navigate(path);
        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      contextualLink.logLink(contextualLinkConfig);
    },
    [getFieldsPath, contextualLink.logLink]
  );

  return {
    goToCommonInfo,
    goToFields,
    goToVerification,
  };
};

export default useTasksTaskOperationRoutes;
