import styled from 'styled-components';

const Row = styled.div``;

const Col = styled.div<{ $variant: string }>`
  display: grid;
  grid-template-columns: ${({ $variant }) => $variant};
  grid-column-gap: 16px;
  grid-row-gap: 20px;
`;

const Form = styled.form`
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Styled = {
  Row,
  Col,
  Form,
};

export default Styled;
