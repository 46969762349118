import { useModal, useSidebar } from '@farmlink/farmik-ui';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import {
  CulturesLegendMapControl,
  GoogleSearchMapControl,
  LayerFillMapControl,
  LocationMapControl,
  EMapControlPlace,
  ZoomMapControl,
} from '../../../../../shared/features/map/components/Controls';
import { EFieldFill } from '../../../../constants/FieldFill.enum';
import { EMapModeId } from '../../../../constants/MapModeId.enum';
import { useStore } from '../../../../../shared/utils/IoC';
import useFieldRoutes from '../../hooks/useFieldsRoutes';
import { MapDrawControls } from '../../components';
import FieldsLayout from '../../FieldsLayout';

import { FIELD_SEASONS_MODALS_UNIQUE_KEY, fieldSeasonsModalConfigList } from './modals';
import useMapNotifications from './hooks/useMapNotifications';
import LeftPanel from './components/LeftPanel/LeftPanel';
import useOverlapModal from './hooks/useOverlapModal';
import {
  FieldCulturesController as CulturesController,
  FieldCulturesStore as CulturesStore,
  FieldSeasonsViewController as SeasonsController,
} from './mobx';

type TRouterParams = {
  orgId?: string;
  fieldId?: string;
};

const FieldSeasonsPage: FC = () => {
  // Навигация
  const navigate = useNavigate();
  const routes = useFieldRoutes();
  const { state: routeState } = useLocation();
  const { fieldId } = useParams<TRouterParams>();

  // Сторы
  const culturesStore = useStore(CulturesStore);

  // Контроллеры
  const seasonsController = useStore(SeasonsController);
  const culturesController = useStore(CulturesController);

  useOverlapModal();
  useMapNotifications();
  const modal = useModal();
  const sidebar = useSidebar();

  useEffect(() => {
    sidebar.setSidebarVisibility(false);

    seasonsController.initialize(fieldId).then(isInit => {
      if (!isInit) {
        navigate(routes.fieldsViewRoute);

        return;
      }

      // @ts-ignore
      if (routeState?.enableDraw) {
        const [initialCulture] = culturesStore.culturesZonesList;
        culturesController.enableEditCultureZone(initialCulture);
      }
    });

    return () => {
      seasonsController.destroy();
      sidebar.setSidebarVisibility(true);
    };
  }, [fieldId]);

  useEffect(() => {
    modal.registerModalList(fieldSeasonsModalConfigList, FIELD_SEASONS_MODALS_UNIQUE_KEY);
  }, []);

  return (
    <FieldsLayout
      id={EMapModeId.FieldSeasons}
      leftPanel={<LeftPanel />}
      map={{
        config: {
          controls: [
            { id: 'draw-panel', place: EMapControlPlace.TOP, component: <MapDrawControls /> },
            { id: 'search', place: EMapControlPlace.TOP, component: <GoogleSearchMapControl /> },
            {
              id: 'fill',
              place: EMapControlPlace.TOP,
              component: <LayerFillMapControl skipOptions={[EFieldFill.Ndvi, EFieldFill.Msavi]} />,
            },
            {
              id: 'culturesLegend',
              place: EMapControlPlace.TOP,
              component: <CulturesLegendMapControl />,
              hidden: true,
            },

            { id: 'zoom', place: EMapControlPlace.RIGHT, component: <ZoomMapControl /> },
            { id: 'location', place: EMapControlPlace.RIGHT, component: <LocationMapControl /> },
          ],
          pointCoordinatesControl: true,
        },
      }}
    />
  );
};

export default observer(FieldSeasonsPage);
