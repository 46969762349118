import { IGetChecklistAttributeUserDictionary } from '../../../../models/checklist/attribute/checklist.attribute.model';
import { TypeApiRoute } from '../../../../models/type.api.request';

type TypeRequest = {
  attributeId: string;
  organizationId: string;
  stageId: string;
  size?: number;
  taskId?: string;
};

type TypeResponse = { content: IGetChecklistAttributeUserDictionary[] };

export const getChecklistUserDictionaryListByAttributeId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: ({ attributeId }) => `/api/as-fields/checklist/attribute/${attributeId}/userDictionaryList`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
