import styled, { css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding-bottom: 12px;
  border-bottom: 1px solid ${Colors.technicalBorderGrey};
`;

const WrapperButtonAddMore = styled.div`
  margin-top: 12px;
`;

const Checkbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const CheckboxList = styled.div`
  display: flex;
  gap: 12px;
`;

const AdditionalData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const EmployeeList = styled.div<{ $isDisabledGap: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ $isDisabledGap }) => {
    if ($isDisabledGap) return;

    return css`
      gap: 16px;
    `;
  }};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-top: 12px;
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFieldsAddStoModal = {
  Header,
  WrapperButtonAddMore,
  Checkbox,
  CheckboxList,
  AdditionalData,
  EmployeeList,
  Body,
  Wrapper,
};

export default StyledFieldsAddStoModal;
