import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div``;

const UnderTitleTypography = styled.p`
  ${({ theme }) => theme.Typography.schemas.body};
  color: ${Colors.secondaryDarkGray};

  margin: 0;
`;

const TitleWrapper = styled.span`
  & > span {
    margin-right: 12px;
  }
  margin-right: 12px;
`;

const TitlePaddingWrapper = styled.div`
  padding-left: 22px;
`;

const ChipWrapper = styled.span`
  font-weight: 400;
  display: inline-block;
`;

const Styled = { Wrapper, UnderTitleTypography, TitleWrapper, TitlePaddingWrapper, ChipWrapper };

export default Styled;
