import { TypeApiRoute } from '../../../models/type.api.request';
import { IResponseList } from '../../../models/common/response';
import { IGetDictionary } from '../../../models/dictionary/dictionary.model';

interface IRequest {
  seasonYear: number;
  organizationId?: string;
  cultureId?: string;
  forDA?: boolean;
  forUH?: boolean;
  forOthers?: boolean;
  noCulture?: boolean;
}

type TResponse = IResponseList<IGetDictionary>;

export const getStoTechOperationList: TypeApiRoute & {
  request: IRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/sto/operation/findTechOperations`,
  method: 'GET',
  headers: {},
  request: {} as IRequest,
  response: {} as TResponse,
};
