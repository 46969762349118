import React, { FC, ReactElement } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import SharedStyled from '../../../../../../../../../../components/shared/styles/Shared.styles';
import { ListCardStyles } from '../../../../../../../../../../components/ListCard';

import Styled from './RightBlockWithNewChip.styles';

interface IProps {
  area: string;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
  dropdownMenu: ReactElement;
}

const RightBlockWithNewChip: FC<IProps> = props => {
  return (
    <ListCardStyles.RightBlock {...props.getDataTestId('right-block-new')}>
      <SharedStyled.Title {...props.getDataTestId('area')}>{props.area}</SharedStyled.Title>
      <ListCardStyles.MenuIconWrapper>{props.dropdownMenu}</ListCardStyles.MenuIconWrapper>

      <Styled.NewChip {...props.getDataTestId('new-chip')}>Новое</Styled.NewChip>
    </ListCardStyles.RightBlock>
  );
};

export default RightBlockWithNewChip;
