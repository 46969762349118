import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizesObj } from '../../../../../../../../../../../../../shared/constans/sizes';

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 12,
  padding: ESpaceSize.M,
  color: Colors.generalWhite,
  height: 44,
  boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.10)',
  borderBottom: `1px solid ${Colors.technicalBorderGrey}`,

  '& span': {
    color: Colors.darkGrey,
    ...FontSizesObj.medium,
  },
});

const Styles = {
  Wrapper,
};

export default Styles;
