import Skeleton from 'react-loading-skeleton';

import Styled from './TimeLineSkeleton.styles';

const TimeLineSkeleton = () => {
  return (
    <Styled.Wrapper>
      <Styled.Timeline>
        {Array.from({ length: 20 }, (_, i) => (
          <Skeleton key={`timeline-skeleton-item-${i}`} width={68} height={20} />
        ))}
      </Styled.Timeline>

      <Styled.Calendar>
        <Skeleton width={16} height={16} />
      </Styled.Calendar>
    </Styled.Wrapper>
  );
};
export default TimeLineSkeleton;
