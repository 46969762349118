import { IGetStoReportBySeasonPayloadEmployee as IEmployee } from '../../../../../../../../../../../api/endpoints/as-fields/sto/getStoReportBySeason';
import { IStoDownloadReportModalEmployee as ITechEmployee } from '../../../interfaces';

const changeEmployee = (
  list: ITechEmployee[],
  prop: keyof ITechEmployee,
  id: string,
  value: string
): ITechEmployee[] => {
  return list.map(employee => {
    if (employee.id !== id) return employee;

    return {
      ...employee,
      [prop]: value,
    };
  });
};

const removeEmployee = (list: ITechEmployee[], id: string): ITechEmployee[] => {
  return list.filter(employee => employee.id !== id);
};

const validateEmployeeList = (techList: ITechEmployee[]): IEmployee[] => {
  return techList.reduce<IEmployee[]>((list, e) => {
    if (e.name || e.title) {
      list.push({
        name: e.name,
        title: e.title,
      });
    }

    return list;
  }, []);
};

const checkIfAllowedAddingMoreAuthors = (techList: ITechEmployee[]): boolean => {
  if (!techList.length) return false;
  if (techList.length >= 2) return false;

  const [employee] = techList;

  return Boolean(employee.title) && Boolean(employee.name);
};

const StoDownloadReportHelpers = {
  changeEmployee,
  removeEmployee,
  validateEmployeeList,
  checkIfAllowedAddingMoreAuthors,
};

export default StoDownloadReportHelpers;
