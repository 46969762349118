import { FC, memo, ReactNode } from 'react';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';

import Styled from './ComparisonTableBuilderClearedHeader.styles';

interface IProps {
  columnsChildren: ReactNode;
}

const ComparisonTableBuilderClearedHeader: FC<IProps> = ({ columnsChildren }) => {
  const getDataTestId = useDataTestId({ componentName: 'cleared-header' });

  return <Styled.Wrapper {...getDataTestId()}>{columnsChildren}</Styled.Wrapper>;
};

ComparisonTableBuilderClearedHeader.displayName = 'ComparisonTableBuilderClearedHeader';

export default memo(ComparisonTableBuilderClearedHeader);
