import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

interface IWrapperProps {
  hiddenOverflow: boolean;
}

const Wrapper = styled('div')<IWrapperProps>(
  {
    pointerEvents: 'auto',

    display: 'flex',
    gap: ESpaceSize.M,
  },
  ({ hiddenOverflow }) => ({
    overflow: hiddenOverflow ? 'hidden' : 'visible',
  })
);

const SmallButton = styled('div')({
  height: '40px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  cursor: 'pointer',

  backgroundColor: Colors.generalWhite,
  borderRadius: ESpaceSize.M,

  gap: 6,
  paddingLeft: ESpaceSize.M,
  paddingRight: ESpaceSize.M,

  '&:hover': {
    color: Colors.accentGreen,

    '& path': {
      stroke: `${Colors.accentGreen} !important`,
    },
  },
});

const NotificationWrapper = styled('div')({
  display: 'flex',
  gap: ESpaceSize.M,

  '& > div': {
    pointerEvents: 'auto',
  },
});

const Styles = {
  Wrapper,
  SmallButton,
  NotificationWrapper,
};

export default Styles;
