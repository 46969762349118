import Skeleton from 'react-loading-skeleton';
import { FC, memo } from 'react';

import Styled from './SkeletonFieldList.styles';

const SkeletonFieldList: FC<{ listLength?: number }> = ({ listLength = 1 }) => {
  return (
    <>
      {Array(listLength)
        .fill(null)
        .map((_, i) => (
          <Styled.Item key={i} $isLast={i + 1 === listLength} data-test-id="sto-field-skeleton">
            <Skeleton height={36} />
          </Styled.Item>
        ))}
    </>
  );
};

SkeletonFieldList.displayName = 'SkeletonFieldList';

export default memo(SkeletonFieldList);
