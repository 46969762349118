import { useSearchParams } from 'react-router-dom';
import { isFunction } from 'lodash';

import { IOperationsParams } from '../../interfaces';

type TUseParamsSetter = (
  params:
    | Partial<IOperationsParams>
    | ((prevParams: Partial<IOperationsParams>) => Partial<IOperationsParams>),
  options?: Parameters<ReturnType<typeof useSearchParams>[1]>[1]
) => void;

const getOperationsParams = (searchParams: URLSearchParams): Partial<IOperationsParams> => {
  const prevParams: Partial<IOperationsParams> = {};

  const cultureId = searchParams.get('cultureId');
  const operationId = searchParams.get('operationId');
  const fieldId = searchParams.get('fieldId');

  if (cultureId) prevParams.cultureId = cultureId;
  if (operationId) prevParams.operationId = operationId;
  if (fieldId) prevParams.fieldId = fieldId;

  return prevParams;
};

const useParamsSetter = (): TUseParamsSetter => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParams: TUseParamsSetter = (params, options) => {
    if (isFunction(params)) {
      const prevParams = getOperationsParams(searchParams);

      setSearchParams(params(prevParams), options);
    } else {
      setSearchParams(params, options);
    }
  };

  return setParams;
};

const useOperationsParamsActions = () => {
  const setParams = useParamsSetter();

  const selectCulture = (cultureId: string): void => {
    setParams({ cultureId });
  };

  const selectOperation = (operationId: string): void => {
    setParams(prev => {
      const copiedPrevParams: Partial<IOperationsParams> = { cultureId: prev.cultureId };

      if (!operationId) {
        return copiedPrevParams;
      }

      return { ...copiedPrevParams, operationId };
    });
  };

  const selectField = (fieldId: string): void => {
    setParams(prev => {
      const copiedPrevParams: Partial<IOperationsParams> = { ...prev };

      if (!fieldId) {
        delete copiedPrevParams?.fieldId;
        return copiedPrevParams;
      }

      return { ...copiedPrevParams, fieldId };
    });
  };

  const clearParams = (): void => setParams({});

  return {
    selectCulture,
    selectOperation,
    selectField,
    clearParams,
  };
};

export default useOperationsParamsActions;
