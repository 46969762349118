import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { StoChecklistsCoreController } from '../../mobx/controllers';
import { useStoNavigation, useStoParams } from '../../../../../../../../../../hooks';
import { ConfigurableSlider } from '../../../../../../../../../../../operations/components/ConfigurableSlider';
import { StoChecklistsCoreStore } from '../../mobx/stores';

import { StoChecklistsCard } from './components';

const StoChecklistsCarousel: FC = () => {
  const { checklistList } = useStore(StoChecklistsCoreStore);
  const { checkIfChecklistIsSelected } = useStore(StoChecklistsCoreController);

  const params = useStoParams();
  const navigations = useStoNavigation();

  const handleCardClick = useCallback(
    (id: string): void => {
      navigations.navigateToChecklist(
        { ...params, checklistId: id },
        {
          query: { ...(params.versionId && { versionId: params.versionId }) },
        }
      );
    },
    [navigations.navigateToChecklist]
  );

  return (
    <ConfigurableSlider>
      {checklistList.map(checklist => (
        <StoChecklistsCard
          key={checklist.id}
          id={checklist.id}
          name={checklist.name}
          type={checklist.type}
          isActive={checklist.id === params.checklistId}
          isSelected={checkIfChecklistIsSelected(checklist.id)}
          onClick={handleCardClick}
        />
      ))}
    </ConfigurableSlider>
  );
};

StoChecklistsCarousel.displayName = 'StoChecklistsCarousel';

export default observer(StoChecklistsCarousel);
