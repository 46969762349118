import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/IoC';
import { IContextualLink as ILink } from '../../../models';

@provide.singleton()
class ContextualLinkCoreStore {
  /**
   * Коллекция моделей контекстных ссылок, что индексированы согласно их id.
   */
  private _linksById: Map<string, ILink> = new Map<string, ILink>();

  constructor() {
    makeAutoObservable(this);
  }

  public getLink = (id: string): ILink => {
    return this._linksById.get(id);
  };

  public setLink = (link: ILink): void => {
    this._linksById.set(link.id, link);
  };

  public setLinkList = (linkList: ILink[]): void => {
    (linkList ?? []).forEach(link => {
      this._linksById.set(link.id, link);
    });
  };

  public deleteLink = (id: string): void => {
    this._linksById.delete(id);
  };

  public clearLinksById = (): void => {
    this._linksById.clear();
  };
}

export default ContextualLinkCoreStore;
