import { CalendarComponent } from '@farmlink/farmik-ui';
import moment, { Moment } from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { FieldSeasonsViewStore as Store } from '../../../../../../mobx';
import { IInputProps } from '../../types/CommonProps.types';

import Styled from './SowingHarvestDatePickers.styles';

const DISPLAY_FORMAT = 'DD.MM.YYYY';
const BACKEND_FORMAT = 'YYYY-MM-DD';

const SowingHarvestDatePickers: FC<IInputProps> = props => {
  const { cultureZone, dataTestId } = props;

  // Сторы
  const store = useStore(Store);

  const selectedSeason = store.selectedSeason?.season;
  const [sowingDate, setSowingDate] = useState<Moment | null>(null);
  const [harvestDate, setHarvestDate] = useState<Moment | null>(null);

  const seasonBorder = {
    min: moment.utc(selectedSeason?.startDate ?? new Date(), BACKEND_FORMAT),
    max: moment.utc(selectedSeason?.endDate ?? new Date(), BACKEND_FORMAT),
  };

  const isDisabled = !cultureZone;

  const sowingMaxDate = useMemo(() => {
    if (!harvestDate || harvestDate?.isSame(seasonBorder.max)) {
      return moment(seasonBorder.max).subtract(1, 'd');
    }

    return harvestDate;
  }, [harvestDate, seasonBorder]);

  const harvestMinDate = useMemo(() => {
    if (!sowingDate || sowingDate?.isSame(seasonBorder.min)) {
      return moment(seasonBorder.min).add(1, 'd');
    }

    return sowingDate;
  }, [sowingDate, seasonBorder]);

  const handleChangeSowingDate = (value: Date) => {
    const formattedValue = moment(value);
    cultureZone.sowingDate = formattedValue.format(BACKEND_FORMAT) as any;

    setSowingDate(formattedValue);
  };

  const handleChangeHarvestDate = (value: Date) => {
    const formattedValue = moment(value);
    cultureZone.harvestDate = formattedValue.format(BACKEND_FORMAT) as any;

    setHarvestDate(formattedValue);
  };

  useEffect(() => {
    const sDate = cultureZone?.sowingDate;
    const hDate = cultureZone?.harvestDate;

    setSowingDate(!sDate ? null : moment(sDate, BACKEND_FORMAT));
    setHarvestDate(!hDate ? null : moment(hDate, BACKEND_FORMAT));
  }, [cultureZone?.sowingDate, cultureZone?.harvestDate]);

  return (
    <React.Fragment>
      <Styled.DatePickerWrapper data-test-id={dataTestId}>
        <CalendarComponent
          label={'Дата сева'}
          placeholder={'Введите дату'}
          value={sowingDate?.format(DISPLAY_FORMAT)}
          onChange={handleChangeSowingDate}
          datePickerOptions={{
            dateRange: {
              minDate: moment.utc(seasonBorder.min).startOf('d').toDate(),
              maxDate: moment.utc(sowingMaxDate).toDate(),
            },
          }}
          forceOpenToDate={seasonBorder.min.format('DD.MM.YYYY')}
          error={''}
          readOnly={false}
          isBlocked={false}
          isDisabled={isDisabled}
          isCloseOnChange={true}
          dataTestId="culture-sowing-date"
        />
      </Styled.DatePickerWrapper>

      <Styled.DatePickerWrapper data-test-id={dataTestId}>
        <CalendarComponent
          label={'Дата уборки'}
          placeholder={'Введите дату'}
          value={harvestDate?.format(DISPLAY_FORMAT)}
          onChange={handleChangeHarvestDate}
          datePickerOptions={{
            dateRange: {
              minDate: moment.utc(harvestMinDate).toDate(),
              maxDate: moment.utc(seasonBorder.max).toDate(),
            },
          }}
          forceOpenToDate={seasonBorder.max.format('DD.MM.YYYY')}
          error={''}
          readOnly={false}
          isBlocked={false}
          isDisabled={isDisabled}
          isCloseOnChange={true}
          dataTestId="culture-harvest-date"
        />
      </Styled.DatePickerWrapper>
    </React.Fragment>
  );
};

export default SowingHarvestDatePickers;
