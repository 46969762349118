import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TaskOperationCreateStore as Store } from '../../stores';
import {
  ETaskStatus,
  ETaskType,
  ITaskCreate,
} from '../../../../../../../../../api/models/as-fields/task/task.model';
import { TaskOperationCreateApiService as ApiService } from '../TaskOperationCreateApiService';
import { ProfileStore } from '../../../../../../profile/stores/ProfileStore';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { IDictionaryEntity } from '../../../../../../../../../api/models/da-dictionary/DictionaryEntity';
import { OperationField } from '../../../../../../../../../api/models/operations/operation.field.model';

@provide.transient()
class TaskOperationCreateCrudService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(ApiService)
  protected apiService: ApiService;

  public createInitialTaskData = (): void => {
    const initialTaskCreate: ITaskCreate = {
      planStartDate: '',
      planEndDate: '',
      comment: '',
      status: ETaskStatus.Completed,
      assigneeId: this.profileStore?.user?.id ?? '',
      operationId: '',
      type: ETaskType.Operation,
      taskItems: [],
    };

    this.store.setTaskCreate(initialTaskCreate);
  };

  public changeCulture = (cultureId: string): void => {
    this.store.deleteTaskPropWithError('cultureId');

    this.clearConfigData();
    this.store.clearSelectedFieldIdList();

    this.changeTaskCreate(prev => ({
      ...prev,
      cultureId,
      operationId: '',
      planStartDate: '',
      planEndDate: '',
      taskItems: [],
    }));

    void this.apiService.getOperationOptionList();
  };

  public changeOperation = (operationId: string): void => {
    this.store.deleteTaskPropWithError('operationId');

    this.clearConfigData();
    this.store.clearSelectedFieldIdList();

    this.changeTaskCreate(prev => ({
      ...prev,
      operationId,
      planStartDate: '',
      planEndDate: '',
      taskItems: [],
      operationValues: [],
    }));

    if (!operationId) return;

    void this.apiService.getConfig();
  };

  public changeDate = (date: string): void => {
    this.changeTaskCreate(prev => ({
      ...prev,
      planStartDate: date,
      planEndDate: date,
    }));
  };

  public changeConfig = (optionList: ISelectOption<IDictionaryEntity>[]): void => {
    this.store.deleteTaskPropWithError('operationValues');

    this.changeTaskCreate(prev => ({
      ...prev,
      operationValues: optionList.map(option => ({
        dictionaryLink: this.store.config.dictionaryLink,
        dictionaryValueId: option.value,
        systemData: {
          option,
        },
      })),
    }));
  };

  protected changeTaskCreate: Store['setTaskCreate'] = value => {
    this.store.setHasChanges(true);
    this.store.setTaskCreate(value);
  };

  protected clearConfigData = (): void => {
    this.store.clearConfig();
    this.store.clearConfigSelectOptionList();
  };

  public changeField = (option: ISelectOption<OperationField>): void => {
    this.store.setHasChanges(true);

    const isFieldSelected = this.store.hasSelectedFieldId(option.value);

    if (isFieldSelected) {
      this.store.deleteSelectedFieldId(option.value);

      this.deleteFieldFromTaskItems(option.value);
    } else {
      this.store.addSelectedFieldId(option.value);

      this.setFieldToTaskItems(option);
    }
  };

  protected setFieldToTaskItems = (option: ISelectOption<OperationField>): void => {
    if (!this.isThereFieldInTaskItem(option.value)) {
      this.store.setTaskCreate(prev => ({
        ...prev,
        taskItems: [
          ...prev.taskItems,
          { fieldId: option.initialModel.fieldId, cultureZoneId: option.value },
        ],
      }));
    }
  };

  protected deleteFieldFromTaskItems = (cultureZoneId: string): void => {
    if (this.isThereFieldInTaskItem(cultureZoneId)) {
      this.store.setTaskCreate(prev => ({
        ...prev,
        taskItems: prev.taskItems.filter(item => item.cultureZoneId !== cultureZoneId),
      }));
    }
  };

  protected isThereFieldInTaskItem = (cultureZoneId: string): boolean => {
    return this.store.taskCreate.taskItems.some(item => {
      return item.cultureZoneId === cultureZoneId;
    });
  };

  protected clearFieldsInTaskItems = (): void => {
    this.store.setTaskCreate(prev => ({
      ...prev,
      taskItems: [],
    }));
  };

  public changeAllFields = (value: boolean): void => {
    this.store.setHasChanges(true);

    if (value) {
      this.store.fieldOptionListToDisplay.forEach(option => {
        this.store.addSelectedFieldId(option.value);

        this.setFieldToTaskItems(option);
      });
    } else {
      this.store.clearSelectedFieldIdList();

      this.clearFieldsInTaskItems();
    }
  };

  public searchFields = (searchValue: string): void => {
    this.store.setHasChanges(true);

    if (!searchValue) this.store.setFieldOptionListToDisplay(this.store.fieldOptionList);

    const searchTermList = searchValue.toLowerCase().split(' ');

    const filteredList = this.store.fieldOptionList.filter(entity => {
      if (!entity.label) return false;

      const lowerCaseName = entity.label.toLowerCase();

      return searchTermList.every(term => lowerCaseName.includes(term));
    });

    this.store.setFieldOptionListToDisplay(filteredList);
  };
}

export default TaskOperationCreateCrudService;
