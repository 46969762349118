import React, { FC } from 'react';
import { observer } from 'mobx-react';

import Container from '../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/Container';
import { useStore } from '../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../mobx/controllers';
import { EContainerHeaderType } from '../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';

import {
  StoAttributePhotosSelector as PhotosSelector,
  StoAttributeRequiredSelector as RequiredSelector,
  StoAttributeToolTipSelector as ToolTipSelector,
} from './containers';
import { StoAttributeOdz as Odz } from './components';

const StoAttributeCommonData: FC = () => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  return (
    <Container
      dataTestId={'sto__attribute__common-data'}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: store.attribute.attribute.name,
        },
        additionalContent: <RequiredSelector />,
      }}
      bodyStyles={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        paddingTop: '28px',
      }}
    >
      <PhotosSelector />
      <ToolTipSelector />

      {controller.isNeedOdz ? <Odz /> : null}
    </Container>
  );
};

StoAttributeCommonData.displayName = 'StoAttributeCommonData';

export default observer(StoAttributeCommonData);
