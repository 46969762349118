import { FC, memo, PropsWithChildren } from 'react';

import { EChecklistAttributeWidth } from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsContent as Content } from '../ChecklistsContent';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './ChecklistsAttr.styles';

interface IProps {
  width: EChecklistAttributeWidth;
  isNewLine?: boolean;
  id?: string;
  dataTestId?: string;
}

const ChecklistsAttr: FC<PropsWithChildren<IProps>> = ({
  width,
  isNewLine,
  id,
  children,
  dataTestId,
}) => {
  const getDataTestId = useDataTestIdV2(dataTestId ?? 'checklists_attribute');

  if (isNewLine) {
    return (
      <Styled.Wrapper {...getDataTestId()} $width={EChecklistAttributeWidth.Full}>
        <Content>
          <Styled.Wrapper id={id} $width={width}>
            {children}
          </Styled.Wrapper>
        </Content>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper id={id} {...getDataTestId()} $width={width}>
      {children}
    </Styled.Wrapper>
  );
};

ChecklistsAttr.displayName = 'ChecklistsAttr';

export default memo(ChecklistsAttr);
