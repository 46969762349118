export enum TasksRoute {
  Listing = '/:orgId/tasks',
  /**
   * Общие данные. Таб "Постановка задачи"
   */
  Task = '/:orgId/tasks/:taskId',

  /**
   * Таб "На карте"
   */
  TaskMap = '/:orgId/tasks/:taskId/map',
  TaskMapCreatePoint = '/:orgId/tasks/:taskId/map/createPoint',
  TaskMapEditPoint = '/:orgId/tasks/:taskId/map/editPoint',

  /**
   * Таб "Чек-листы"
   */
  Checklist = '/:orgId/tasks/:taskId/checklists/:checklistInstanceId',
  ChecklistList = '/:orgId/tasks/:taskId/checklists',
  ChecklistEdit = '/:orgId/tasks/:taskId/checklists/:checklistInstanceId/edit',

  TaskReport = '/:orgId/tasks/:taskId/report',

  TaskHistory = '/:orgId/tasks/:taskId/history',

  TaskCreate = '/:orgId/tasks/create',
  TaskCreateOne = '/:orgId/tasks/create/one',
  TaskCreateGroup = '/:orgId/tasks/create/group',

  TaskEdit = '/:orgId/tasks/:taskId/edit',

  /**
   * Модули задачи с типом "регистрация".
   */
  TaskOperationCreate = '/:orgId/tasks/task-operation-create',
  /**
   * Поставнока.
   */
  TaskOperationCreateCommonInfo = '/:orgId/tasks/task-operation-create/common-info',
  /**
   * Поля.
   */
  TaskOperationCreateFields = '/:orgId/tasks/task-operation-create/fields',
  /**
   * Проверка.
   */
  TaskOperationCreateVerification = '/:orgId/tasks/task-operation-create/verification',
  /**
   * Просмотр.
   */
  TaskOperationView = '/:orgId/tasks/task-operation/:taskId',
}
