import { lazyInject, provide } from '../../../../../../../../../../../../../../shared/utils/IoC';
import { StoChecklistsCoreStore as CoreStore } from '../../stores';
import {
  IStoAttributeStoOdzFormTypePut as OdzFormTypePut,
  IStoChecklistsAttrsTableChecklistUpdate,
  IStoChecklistsAttrsTableStageUpdate,
} from '../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import {
  IStoChecklistsAttrsTableUserDictionaryValueUpdate as IUserDictionaryValueUpdate,
  IStoChecklistsAttrsTableAttrUpdate,
} from '../../../../../../../../../../../../../../../api/models/as-fields/new/sto/StoChecklistsParamsTable/StoTable.model';
import StoAttributeOdzService from '../../../modules/StoChecklist/modules/StoAttribute/mobx/services/StoAttributeOdzService/StoAttributeOdz.service';

interface IChangeHandlerOptions {
  isWithoutChangeLogging?: boolean;
}

@provide.transient()
class StoChecklistsTableChangesService {
  @lazyInject(CoreStore)
  protected coreStore: CoreStore;

  @lazyInject(StoAttributeOdzService)
  protected odzService: StoAttributeOdzService;

  public changeChecklist = (
    checklistId: string,
    data: Partial<IStoChecklistsAttrsTableChecklistUpdate>,
    options?: IChangeHandlerOptions
  ): void => {
    const { setChecklistUpdate, setHasChanges } = this.coreStore;

    setChecklistUpdate(
      {
        id: checklistId,
        ...data,
      },
      {
        isUpdate: true,
      }
    );

    if (!options?.isWithoutChangeLogging) {
      setHasChanges(true);
    }
  };

  public changeStage = (
    checklistId: string,
    data: Partial<IStoChecklistsAttrsTableStageUpdate>,
    options?: IChangeHandlerOptions
  ): void => {
    const { hasChecklistUpdate, setStageUpdate, setHasChanges } = this.coreStore;

    if (!hasChecklistUpdate(checklistId)) {
      this.changeChecklist(
        checklistId,
        { id: checklistId },
        { isWithoutChangeLogging: options?.isWithoutChangeLogging }
      );
    }

    setStageUpdate(
      checklistId,
      {
        ...data,
      },
      {
        isUpdate: true,
      }
    );

    if (!options?.isWithoutChangeLogging) {
      setHasChanges(true);
    }
  };

  public changeAttr = (
    checklistId: string,
    stageId: string,
    data: Partial<IStoChecklistsAttrsTableAttrUpdate>,
    options?: IChangeHandlerOptions
  ): void => {
    const { hasChecklistUpdate, hasStageUpdate, setAttrUpdate, setHasChanges } = this.coreStore;

    if (!hasChecklistUpdate(checklistId)) {
      this.changeChecklist(
        checklistId,
        { id: checklistId },
        { isWithoutChangeLogging: options?.isWithoutChangeLogging }
      );
    }

    if (!hasStageUpdate(checklistId, stageId)) {
      this.changeStage(
        checklistId,
        { id: stageId },
        { isWithoutChangeLogging: options?.isWithoutChangeLogging }
      );
    }

    setAttrUpdate(
      checklistId,
      stageId,
      {
        ...data,
      },
      { isUpdate: true }
    );

    if (!options?.isWithoutChangeLogging) {
      setHasChanges(true);
    }
  };

  public getFormattedChecklistList = (): IStoChecklistsAttrsTableChecklistUpdate[] => {
    const { checklistUpdateList, hasStageUpdateList } = this.coreStore;

    return checklistUpdateList.reduce<IStoChecklistsAttrsTableChecklistUpdate[]>(
      (formattedList, checklist) => {
        const formattedChecklist: IStoChecklistsAttrsTableChecklistUpdate = {
          ...checklist,
        };

        const hasStageList = hasStageUpdateList(checklist.id);

        if (hasStageList) {
          formattedChecklist.stages = this.getFormattedStageList(checklist.id);
        }

        formattedList.push(formattedChecklist);

        return formattedList;
      },
      []
    );
  };

  protected getFormattedStageList = (
    checklistId: string
  ): IStoChecklistsAttrsTableStageUpdate[] => {
    const { getStageUpdateList, hasAttrUpdateList } = this.coreStore;

    const stageForUpdateList = getStageUpdateList(checklistId);

    return stageForUpdateList.reduce<IStoChecklistsAttrsTableStageUpdate[]>(
      (formattedList, stage) => {
        const formattedStage: IStoChecklistsAttrsTableStageUpdate = {
          ...stage,
        };

        const hasItemList = hasAttrUpdateList(checklistId, stage.id);

        if (hasItemList) {
          formattedStage.attributes = this.getFormattedAttrList(checklistId, stage.id);
        }

        formattedList.push(formattedStage);

        return formattedList;
      },
      []
    );
  };

  protected getFormattedAttrList = (
    checklistId: string,
    stageId: string
  ): IStoChecklistsAttrsTableAttrUpdate[] => {
    const { getAttrUpdateList } = this.coreStore;

    const attrUpdateList = getAttrUpdateList(checklistId, stageId);

    return attrUpdateList.reduce<IStoChecklistsAttrsTableAttrUpdate[]>((formattedList, attr) => {
      const formattedAttr: IStoChecklistsAttrsTableAttrUpdate = {
        ...attr,
      };

      if (attr?.userDictionaryValues) {
        formattedAttr.userDictionaryValues = this.formatUserDictionaryValues(
          attr.userDictionaryValues
        );
      }

      if (attr?.stoODZFormType?.izODZEnabled) {
        formattedAttr.stoODZFormType = this.odzService.formatOdzFormTypePutForSave(
          attr.stoODZFormType
        );
      }

      formattedList.push(formattedAttr);

      return formattedList;
    }, []);
  };

  public formatChecklistsAfterSaving = (): void => {
    this.coreStore.checklistUpdateList.forEach(checklist => {
      const hasStageList = this.coreStore.hasStageUpdateList(checklist.id);

      if (!hasStageList) return;

      const stageList = this.coreStore.getStageUpdateList(checklist.id);

      stageList.forEach(stage => {
        const hasAttrList = this.coreStore.hasAttrUpdateList(checklist.id, stage.id);

        if (!hasAttrList) return;

        const attrUpdateList = this.coreStore.getAttrUpdateList(checklist.id, stage.id);

        attrUpdateList.forEach(({ id, isSelected, technicalProps }) => {
          if (isSelected && technicalProps.isUnsaved) {
            this.coreStore.setAttrUpdate(
              checklist.id,
              stage.id,
              { id, technicalProps: { ...technicalProps, isUnsaved: false } },
              { isUpdate: true }
            );
          }

          if (!isSelected && !technicalProps.isUnsaved) {
            this.coreStore.setAttrUpdate(
              checklist.id,
              stage.id,
              { id, technicalProps: { ...technicalProps, isUnsaved: true } },
              { isUpdate: true }
            );
          }
        });
      });
    });
  };

  protected formatUserDictionaryValues = (
    valueList: IUserDictionaryValueUpdate[]
  ): IUserDictionaryValueUpdate[] => {
    return valueList.reduce<IUserDictionaryValueUpdate[]>((list, value) => {
      const copiedValue: IUserDictionaryValueUpdate = { ...value };

      if (copiedValue?.clientId) {
        delete copiedValue.clientId;
      }

      list.push(copiedValue);

      return list;
    }, []);
  };
}

export default StoChecklistsTableChangesService;
