import styled, { css } from 'styled-components';

import { TTableBuilderStylePreset as TStylePreset } from '../../types/styles';
import { MoveTop } from '../../../../components/MoveTop';

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;

  background-color: white;

  // Для того, чтобы не перекрывался тултип.
  z-index: 2;
`;

const ContentWrapper = styled.div`
  height: calc(100% - 57px);
  background-color: white;
`;

const getStylesByPreset = (preset: TStylePreset) => {
  const primaryPreset = css`
    border-radius: 16px 16px 0 0;

    & ${HeaderWrapper} {
      padding: 20px 24px 0 24px;
      border-radius: 16px 16px 0 0;
    }

    & ${ContentWrapper} {
      padding: 0 16px 20px 16px;
      border-radius: 0 0 16px 16px;
    }
  `;

  const clearedPreset = css``;

  switch (preset) {
    case 'primary':
      return primaryPreset;
    case 'cleared':
      return clearedPreset;
    default:
      return primaryPreset;
  }
};

const Wrapper = styled.div<{ $preset?: TStylePreset }>`
  position: relative;
  height: 100%;

  ${({ $preset }) => getStylesByPreset($preset)}
`;

const StyledMoveTop = styled(MoveTop)`
  z-index: 5;
  position: absolute;
  bottom: 24px;
  right: 24px;
`;

const StyledTableBuilderTable = {
  HeaderWrapper,
  ContentWrapper,
  Wrapper,
  MoveTop: StyledMoveTop,
};

export default StyledTableBuilderTable;
