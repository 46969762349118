import { IChecklistsFormulaResult as IFormulaResult } from '../../models';

const NOT_EDITED_ARGUMENTS: IFormulaResult = {
  value: false,
  isNotEdited: true,
};

const HIDDEN_ARGUMENTS: IFormulaResult = {
  value: false,
};

const EMPTY_VALUES: IFormulaResult = {
  value: false,
};

const CHECKLISTS_FORMULAS_VISIBILITY_RESULTS = {
  HIDDEN_ARGUMENTS,
  NOT_EDITED_ARGUMENTS,
  EMPTY_VALUES,
};

export default CHECKLISTS_FORMULAS_VISIBILITY_RESULTS;
