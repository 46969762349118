import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';
import { Field } from '../../models/field.model';

type TypeRequest = {
  organizationId?: string;
  viewport?: any;
  withGeometry?: boolean;
  withCultureZones?: boolean;
  seasonYear: number;
  sort?: string;
};

type TypeResponse = { content: Array<Field> } & TypeResponsePageable;

export const getFields: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request =>
    `/api/as-fields/fields/list?${
      request.organizationId.length > 0 ? `organizationId=${request.organizationId}` : ''
    }`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
