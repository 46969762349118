import { TypeApiRoute } from '../../../models/type.api.request';
import { EStoDictionaryName, IStoDictionary } from '../../../models/as-fields/new/sto';

type TRequest = {
  stoId: string;
  dictionaryName: EStoDictionaryName;
  versionId?: string;
  asOnDate?: string;
  includeDrafts?: boolean;
  selectedOnly?: boolean;
  sort?: string;
};

type TResponse = IStoDictionary[];

export const getStoDictionaryList: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ stoId, dictionaryName }) => `/api/as-fields/sto/${stoId}/dictionary/${dictionaryName}`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
