import { IDictionaryEntity } from '../../../../da-dictionary/DictionaryEntity';

export enum EStoDictionaryName {
  WeedPlants = 'weedPlants',
  Vermin = 'vermin',
  Diseases = 'diseases',
}

export interface IStoDictionary extends IDictionaryEntity {
  isSelected: boolean;
  /**
   * Данные, что используются только на фронте.
   */
  systemData?: {
    /**
     * Показывает, имеется ли подсветка у элемента.
     */
    isHighlighted?: boolean;
  };
}

export interface IStoDictionaryPut {
  dictionaryName: EStoDictionaryName;
  selectedValues: { id: string }[];
}
