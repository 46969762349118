import React, { FC, memo } from 'react';
import { CSSProperties } from 'styled-components';

import { useDataTestIdV2 } from '../../../utils/hooks/locators';

import Styled from './SimpleLoader.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__simple-loader.
   */
  dataTestId: string;
  size?: 24 | 32;
  color?: CSSProperties['fill'];
}

const SimpleLoader: FC<IProps> = ({ dataTestId, size, color }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__simple-loader`);

  return (
    <Styled.Svg
      {...getDataTestId()}
      width={size ?? 32}
      height={size ?? 32}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4036 4.04686C15.6968 3.36184 15.3803 2.55918 14.6621 2.36082C12.7906 1.84395 10.7985 1.88062 8.93209 2.48223C6.63531 3.22256 4.6837 4.76847 3.43725 6.83479C2.19081 8.90111 1.73361 11.3485 2.14998 13.7254C2.56635 16.1024 3.82822 18.2486 5.70281 19.7682C7.57741 21.2878 9.93828 22.0783 12.35 21.9939C14.7616 21.9094 17.0614 20.9557 18.8251 19.3087C20.5888 17.6617 21.6975 15.4325 21.9466 13.0322C22.149 11.0817 21.7727 9.12517 20.8798 7.4011C20.5371 6.73945 19.6863 6.5959 19.0768 7.02446C18.4672 7.45302 18.3338 8.29145 18.643 8.96936C19.1797 10.1458 19.3978 11.4516 19.2627 12.7537C19.0808 14.5063 18.2713 16.134 16.9835 17.3366C15.6957 18.5392 14.0165 19.2355 12.2555 19.2972C10.4946 19.3589 8.77077 18.7817 7.402 17.6721C6.03323 16.5625 5.11186 14.9954 4.80784 13.2599C4.50381 11.5243 4.83765 9.7373 5.74776 8.22853C6.65788 6.71977 8.08288 5.591 9.75991 5.05044C11.0059 4.64881 12.3286 4.592 13.5906 4.87365C14.3178 5.03596 15.1105 4.73189 15.4036 4.04686Z"
        fill={color ?? '#151F32'}
      />
    </Styled.Svg>
  );
};

SimpleLoader.displayName = 'SimpleLoader';

export default memo(SimpleLoader);
