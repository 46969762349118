import React, { FC, useCallback, useMemo } from 'react';
import { Switcher, Typography } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import Container from '../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/Container';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStoParams } from '../../../../../../../../../../../../../../hooks';
import { useStore } from '../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoStore } from '../../../../../../../../../../../../mobx';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../../../../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../mobx/controllers';
import { EStoAttributeValuesPrintMode } from '../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { SimpleContextualHelp } from '../../../../../../../../../../../../../../../../../shared/features/UI/new/SimpleContextualHelp';
import { StoAttributeCoreStore as Store } from '../../mobx/stores';
import { EContainerHeaderType } from '../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';

import Styled from './StoAttributePrintSettings.styles';

const StoAttributePrintSettings: FC = () => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);
  const checklistsStore = useStore(ChecklistsStore);

  const controller = useStore(Controller);

  const params = useStoParams();

  const { isPrintable, valuesPrintMode, stoODZFormType } = checklistsStore.getAttrUpdate(
    params.checklistId,
    params.stageId,
    params.attributeId
  );

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const isModeActive = useMemo<boolean>(() => {
    return valuesPrintMode === EStoAttributeValuesPrintMode.Attachments;
  }, [valuesPrintMode]);

  const handleIsPrintableChange = useCallback((value: boolean): void => {
    controller.changeAttribute(params, { isPrintable: value });
  }, []);

  const handleValuesPrintModeChange = (value: boolean): void => {
    if (value) {
      return controller.changeAttribute(params, {
        valuesPrintMode: EStoAttributeValuesPrintMode.Attachments,
      });
    }

    if (stoODZFormType?.izODZEnabled) {
      controller.changeAttribute(params, {
        valuesPrintMode: EStoAttributeValuesPrintMode.NotPrinted,
      });
    } else {
      controller.changeAttribute(params, { valuesPrintMode: EStoAttributeValuesPrintMode.Body });
    }
  };

  const getDataTestId = useDataTestIdV2('sto__attribute__print-settings');

  return (
    <Container
      dataTestId={getDataTestId()['data-test-id']}
      containerStyles={{
        gap: isPrintable && controller.isPrintModeSelectorAvailable ? '12px' : '32px',
      }}
      bodyStyles={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: 'Настройки печати',
          contextualHelp: (
            <SimpleContextualHelp
              dataTestId={getDataTestId()['data-test-id']}
              content={
                <span>
                  Настройки атрибута будут напечатаны в основном документе. <br /> При необходимости
                  вы можете вынести их в «Приложение»
                </span>
              }
            />
          ),
        },
      }}
    >
      <Styled.Element
        $isDisableLine={!isPrintable || !controller.isPrintModeSelectorAvailable}
        {...getDataTestId('main-selector')}
      >
        <Switcher
          value={isPrintable}
          onChange={handleIsPrintableChange}
          dataTestId={getDataTestId('main-selector__switcher')['data-test-id']}
          isBlocked={checkIfIsBlockedByPermissions()}
        />

        <Typography
          color={isPrintable ? 'generalDark' : 'secondaryDark'}
          variant={'title'}
          dataTestId={getDataTestId('main-selector__title')['data-test-id']}
        >
          Печатать в документе СТО
        </Typography>
      </Styled.Element>

      {isPrintable && controller.isPrintModeSelectorAvailable ? (
        <Styled.Element $isDisableLine {...getDataTestId('mode')}>
          <Switcher
            value={isModeActive}
            onChange={handleValuesPrintModeChange}
            dataTestId={getDataTestId('mode-selector')['data-test-id']}
            isBlocked={checkIfIsBlockedByPermissions()}
          />

          <Typography
            color={isModeActive ? 'generalDark' : 'secondaryDark'}
            variant={'title'}
            dataTestId={getDataTestId('mode-title')['data-test-id']}
          >
            Печатать только в Приложении документа СТО
          </Typography>
        </Styled.Element>
      ) : null}
    </Container>
  );
};

StoAttributePrintSettings.displayName = 'StoAttributePrintSettings';

export default observer(StoAttributePrintSettings);
