import {
  Task,
  TaskStatuses,
  TaskPrioretees,
} from '../../../modules/dashboard/modules/operations/stores/tasks.store';
import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  seasonYear: string;
  organizationId?: string;
  assignee?: string;
  cultureId?: string;
  noCulture?: boolean;
  operationId?: string;
  operationTypeId?: string;
  fieldId?: string;
  assigneeId?: string;
  planDateFrom?: string;
  planDateTo?: string;
  statusIn?: Array<TaskStatuses>;
  priorityIn?: Array<TaskPrioretees>;
  page?: number;
  size?: number;
};

type TypeResponse = {
  content: Array<Task>;
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalElements: number;
  last: boolean;
  totalPages: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

export const getTasks: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/task/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
