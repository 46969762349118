import { findIndex, ListIterateeCustom } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface IUseVirtualizedListScroll<T> {
  activeIndex: number;
  to: (findFn: ListIterateeCustom<T, boolean>) => void;
}

const useVirtualizedListScroll = <T>(
  list: Array<T>,
  listenElement?: ListIterateeCustom<T, boolean>
): IUseVirtualizedListScroll<T> => {
  const [activeIndex, setActiveIndex] = useState(0);

  const scrollTo = useCallback(
    (findFn: ListIterateeCustom<T, boolean>) => {
      const index = findIndex(list, findFn);

      if (typeof index === 'number') {
        setActiveIndex(index);
      }
    },
    [list]
  );

  useEffect(() => {
    if (!listenElement) {
      return;
    }

    scrollTo(listenElement);
  }, [listenElement]);

  return {
    activeIndex,
    to: scrollTo,
  };
};

export default useVirtualizedListScroll;
