import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Scrollbar } from '@farmlink/farmik-ui';

import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../dashboard/components/AccessRulesWrapper/ControlAccessRulesWrapper';
import { SidebarController } from '../../../dashboard/components/Sidebar/sidebar.controller';
import { useStore } from '../../utils/IoC';
import { TableFiltersBuilderController } from '../../features/TableFiltersBuilder/mobx/controllers';

import {
  Header,
  Placeholder,
  Wrapper,
  Label,
  Arrow,
  Body,
  InnerBody,
  Line,
  ListItem,
  TipWrapperContainer,
  TipWrapper,
  TipTail,
  ContentColumn,
  AddSeason,
  PlusIcon,
  AddSeasonLabel,
  EditIcon,
} from './style';
import { ReactComponent as PlusSVG } from './plus.svg';
import { ReactComponent as EditSVG } from './edit.svg';
import ArrowSVG from './arrow.svg';
import { AccordeonProps, Option } from './types';

const LINE_SPLIT_LENGTH = 20;
const LINE_SPLIT_HEADER_LENGTH = 13;

const SeasonAccordeon: FC<AccordeonProps> = observer(
  ({
    value,
    onChange,
    options,
    placeholder,
    disabled,
    isOpen,
    dataTestId = 'no',
    onCreate,
    onEdit,
    onOpen,
  }) => {
    const tableFiltersBuilderController = useStore(TableFiltersBuilderController);
    const { activeDropdown, setActiveDropdown } = useStore(SidebarController);
    const _isOpen = activeDropdown === 'seasons';

    const [currentValue, setCurrentValue] = useState<Option>({
      value: '',
      label: '',
      description: '',
    });
    const [tip, setTip] = useState({
      visible: false,
      x: 0,
      y: 0,
      label: '',
    });

    const scrollToActive = () => {
      if (options.length) {
        const index = options.findIndex(item => item.value === currentValue.value);
        document.querySelector(`[data-test-id="${dataTestId}-body-inner"]`).scrollTop = index * 42;
      }
    };

    useEffect(() => {
      scrollToActive();
    }, [isOpen]);

    useEffect(() => {
      if (!value) {
        setCurrentValue({
          value: '',
          label: '',
          description: '',
        });
      }
      const option = options.filter(i => i.value === value)[0];

      if (!option) {
        return;
      }

      setCurrentValue(option);
    }, [value]);

    const renderString = (v: string, length: number) =>
      v.length > length ? `${v.substr(0, length)}...` : v;

    const handleChange = (item: Option) => {
      /**
       * При смене сезона очищаем сохраненные данные фильтров в разделе "задачи" [H15-3512].
       */
      tableFiltersBuilderController.clearAppliedValuesInSessionStorage('tasks');

      onChange(item.value);
      setCurrentValue(item);
    };

    const handleMouseOver = (event: any, _label: string) => {
      event.stopPropagation();
      if (_label.length <= LINE_SPLIT_LENGTH) {
        return;
      }

      if (event.target.tagName === 'P') {
        setTip({ ...tip, visible: true });
        return;
      }

      const element = event.target;
      const bound = element.getBoundingClientRect();
      setTip({
        x: bound.right + 10,
        y: bound.bottom - 35,
        visible: true,
        label: _label,
      });
    };

    const handleMouseOut = () => {
      setTip({
        ...tip,
        visible: false,
      });
    };

    return (
      <Wrapper
        isOpen={_isOpen}
        data-test-id={dataTestId}
        data-is-disabled={disabled}
        data-is-open={_isOpen}
        disabled={disabled}
      >
        <Header
          onClick={() => {
            setActiveDropdown(activeDropdown === 'seasons' ? null : 'seasons');
          }}
          data-test-id={`${dataTestId}-header`}
        >
          {Boolean(currentValue.value.length) ? (
            <Label data-test-id={`${dataTestId}-header-label`}>
              {renderString(currentValue.label, LINE_SPLIT_HEADER_LENGTH)}
            </Label>
          ) : (
            <Placeholder data-test-id={`${dataTestId}-header-label`}>{placeholder}</Placeholder>
          )}
          <Arrow src={ArrowSVG} isOpen={!_isOpen} data-test-id={`${dataTestId}-header-arrow`} />
        </Header>
        {_isOpen && <Line />}
        <Body isOpen={_isOpen} data-test-id={`${dataTestId}-body`}>
          <InnerBody data-test-id={`${dataTestId}-body-inner`}>
            <Scrollbar>
              {Boolean(options.length) &&
                options.map((item, index) => {
                  return (
                    <ListItem
                      key={item.value + index}
                      onClick={() => handleChange(item)}
                      isActive={item.value === currentValue.value}
                      onMouseOver={e => handleMouseOver(e, item.label)}
                      onMouseOut={handleMouseOut}
                      data-test-id={`${dataTestId}-option-${index}`}
                    >
                      <ContentColumn>
                        <p data-test-id={`${dataTestId}-option-label`}>{item.label}</p>
                        <span data-test-id={`${dataTestId}-option-description`}>
                          {item.description}
                        </span>
                      </ContentColumn>
                      <ControlAccessRulesWrapper
                        mode={AccessRuleVisibility.Hide}
                        actionName="field.editSeason"
                      >
                        <EditIcon
                          onClick={e => {
                            onEdit();
                          }}
                        >
                          <EditSVG data-test-id={`${dataTestId}-option-edit`} />
                        </EditIcon>
                      </ControlAccessRulesWrapper>
                    </ListItem>
                  );
                })}
            </Scrollbar>
          </InnerBody>
          <ControlAccessRulesWrapper
            mode={AccessRuleVisibility.Hide}
            actionName="field.createFieldOrSeason"
          >
            <AddSeason onClick={onCreate} data-test-id={`${dataTestId}-create-season`}>
              <PlusIcon>
                <PlusSVG />
              </PlusIcon>
              <AddSeasonLabel>Добавить сезон</AddSeasonLabel>
            </AddSeason>
          </ControlAccessRulesWrapper>
        </Body>
        {tip.visible && (
          <TipWrapperContainer
            style={{ left: tip.x, top: tip.y }}
            data-test-id={`${dataTestId}-option-tip-wrapper`}
          >
            <TipWrapper data-test-id={`${dataTestId}-option-tip`}>
              {tip.label}
              <TipTail />
            </TipWrapper>
          </TipWrapperContainer>
        )}
      </Wrapper>
    );
  }
);

export default SeasonAccordeon;
