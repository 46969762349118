import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

interface IControlProps {
  disabled?: boolean;
  $bgColor?: Colors | string;
  $bgHoverColor?: Colors | string;
}

const Wrapper = styled('div')({
  width: '40px',
  position: 'relative',
  padding: ESpaceSize.S,
  borderRadius: ESpaceSize.M,
  backgroundColor: Colors.generalWhite,
  pointerEvents: 'all',
});

const Control = styled('div')<IControlProps>(
  {
    width: 24,
    height: 24,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    padding: ESpaceSize.XS,
    borderRadius: ESpaceSize.S,
    marginBottom: ESpaceSize.S,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  ({ disabled, $bgColor, $bgHoverColor }) => {
    if (disabled) {
      return {
        backgroundColor: Colors.technicalBgGrey,

        '& path': {
          // Временное решение до переноса/оптимизации иконок в farmik
          fill: `${Colors.hoverBorder} !important`,
        },
      };
    }

    return {
      backgroundColor: $bgColor ?? Colors.technicalBgGrey,

      '&:hover': {
        backgroundColor: $bgHoverColor ?? '#E9ECF0',
      },
    };
  }
);

export default {
  Wrapper,
  Control,
};
