import { TypeApiRoute } from '../../../models/type.api.request';
import { CultureZone } from '../../../models/field.model';
import { IResponseList } from '../../../models/common/response';

interface IRequest {
  organizationId?: string;
  operationId?: string;
  seasonYear?: number;
  cultureId?: string;
  byTasks?: boolean;
  withGeometry?: boolean;
  noCulture?: boolean;
  sort?: string;
}

type TResponse = IResponseList<CultureZone>;

export const getCultureZoneList: TypeApiRoute & {
  request: IRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/fields/cultureZones/list`,
  method: 'GET',
  headers: {},
  request: {} as IRequest,
  response: {} as TResponse,
};
