import { AutoTooltip, Checkbox } from '@farmlink/farmik-ui';
import React, { CSSProperties, FC } from 'react';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { ListCard, RightBlockWithAreaAndMenu } from '../../../../../../components/ListCard';
import { Field } from '../../../../../../../../../../api/models/field.model';
import { MultiselectController, MultiselectStore } from '../../../../mobx';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { getUniqueCulturesNameList } from '../../../../../../utils';

import Styled from './FieldCard.styles';

interface IProps {
  key: string;
  field: Field;
  style: CSSProperties;
}

const MultiSelectFieldCard: FC<IProps> = props => {
  // Сторы
  const store = useStore(MultiselectStore);

  // Контроллеры
  const multiselectController = useStore(MultiselectController);

  const getDataTestId = useDataTestIdV2('field-multiselect-card');

  const isChecked = store.isSelected(props.field.id);
  const isDisabled = store.isDisabled(props.field.id);
  const culturesLabel = getUniqueCulturesNameList(props.field.cultureZones).join(', ');

  const getLeftBlock = () => {
    return (
      <AutoTooltip
        content={'Поле нельзя удалить или назначить на него культуру\n из-за ограниченных прав'}
        disabled={!isDisabled}
      >
        <div>
          <Checkbox value={isChecked} disable={isDisabled} />
        </div>
      </AutoTooltip>
    );
  };

  const getRightBlock = () => {
    const cultureArea = `${props.field.area.toFixed(1)} га`;
    return <RightBlockWithAreaAndMenu area={cultureArea} getDataTestId={getDataTestId} />;
  };

  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    const value = store.isSelected(props.field.id);
    multiselectController.toggleField(props.field, !value);
  };

  return (
    <ListCard
      key={props.key}
      title={props.field.name}
      subtitle={culturesLabel || 'Нет культуры'}
      leftBlock={getLeftBlock}
      rightBlock={getRightBlock}
      isSelected={false}
      style={props.style}
      wrapperStyles={isDisabled && Styled.DisabledWrapperStyles}
      onClick={handleClick}
      dataTestId={getDataTestId()['data-test-id']}
    />
  );
};

export default observer(MultiSelectFieldCard);
