import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const SmallButton = styled('div')({
  height: '40px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  cursor: 'pointer',

  backgroundColor: Colors.generalWhite,
  borderRadius: ESpaceSize.M,

  gap: 6,
  paddingLeft: ESpaceSize.M,
  paddingRight: ESpaceSize.M,

  '&:hover': {
    color: Colors.accentGreen,

    '& path': {
      stroke: `${Colors.accentGreen} !important`,
    },
  },
});

const Styled = { SmallButton };

export default Styled;
