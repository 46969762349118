import { ModalFooter, TModalButton, useModal } from '@farmlink/farmik-ui';
import React, { FC, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import {
  DialogModalDescription,
  IconScg,
} from '../../../../../../../../../components/DialogModal/style';
import InfoIcon from '../../../../../assets/info.svg';

import Styled from './DeleteFieldModal.styles';

const DeleteFieldModal: FC = () => {
  const [checked, setChecked] = useState<boolean>(false);

  const modal = useModal();
  const handlers = modal.getModalRuntimeHandlers();

  const denyButton = useMemo<TModalButton>(() => {
    return {
      title: 'Отменить',
      handler: () => modal.closeModal(),
    };
  }, []);

  const successButton = useMemo<TModalButton>(() => {
    return {
      title: 'Удалить',
      color: 'secondary',
      handler: () => {
        handlers.onSuccess(checked);
        modal.closeModal();
      },
    };
  }, [checked]);

  return (
    <>
      <IconScg src={InfoIcon} alt="" />

      <Styled.Title data-test-id="modal-dialog-title">
        Удалить поля: {modal.getModalPayload().selectedCount} из сезона 2024-2025
      </Styled.Title>

      <DialogModalDescription data-test-id="modal-dialog-description">
        Вы уверены, что хотите удалить поля из сезона? <br />
        Вместе с полями удалятся связанные с ними данные: <br />
        участки, задачи, чек-листы
      </DialogModalDescription>

      <Styled.FooterWrapper>
        <ModalFooter denyButton={denyButton} successButton={successButton} />
      </Styled.FooterWrapper>

      <Styled.Checkbox
        onChange={setChecked}
        value={checked}
        label={'Удалить поля из всех сезонов'}
        dataTestId="dialog-modal-checkbox"
      />
    </>
  );
};

export default observer(DeleteFieldModal);
