import _, { sortBy } from 'lodash';

import {
  lazyInject,
  provide,
} from '../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import {
  ChecklistsService,
  StoService,
} from '../../../../../../../../../../../../../../../../../../shared/mobx/services/as-fields';
import { IStoParams } from '../../../../../../../../../../../../../../../models';
import { StoAttributeCoreStore as Store } from '../../stores';
import { IGetChecklistAttributeUserDictionary as IUserDictionary } from '../../../../../../../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { TypeApiRequest } from '../../../../../../../../../../../../../../../../../../shared/utils/axios2';
import { TChecklistsDictionarySelectOption as TDictionarySelectOption } from '../../../../../../../../../../../../../../../../tasks/modules/Checklists/types';
import { DictionaryService } from '../../../../../../../../../../../../../../../../../../shared/mobx/services/da-dictionary';
import { IStoAttribute as IAttribute } from '../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { ISelectOption } from '../../../../../../../../../../../../../../../../../../../types/selectOption';

type TFetchAttributeHandler = (
  params: Pick<
    IStoParams,
    'stoId' | 'operationId' | 'checklistId' | 'stageId' | 'attributeId' | 'versionId'
  >
) => Promise<IAttribute | undefined>;

type TFetchDependencyAttributeListHandler = (
  params: Pick<
    IStoParams,
    'stoId' | 'operationId' | 'checklistId' | 'stageId' | 'attributeId' | 'versionId'
  >
) => Promise<void>;

@provide.transient()
class StoAttributeRequestsService {
  @lazyInject(StoService)
  protected stoService: StoService;

  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ChecklistsService)
  protected checklistsService: ChecklistsService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  public fetchAttribute: TFetchAttributeHandler = async ({
    stoId,
    operationId,
    checklistId,
    stageId,
    attributeId,
    versionId,
  }) => {
    const reponse = await this.stoService.getStoAttribute(
      {
        id: stoId,
        techOperationTypeId: operationId,
        checklistId,
        stageId,
        checklistAttributeId: attributeId,
        versionId,
      },
      {
        actions: {
          handleSuccess: response => this.store.setAttribute(response),
        },
      }
    );

    return reponse;
  };

  public fetchDependencyAttributeList: TFetchDependencyAttributeListHandler = async ({
    stoId,
    operationId,
    checklistId,
    stageId,
    attributeId,
    versionId,
  }) => {
    await this.stoService.getDependencyAttributeList(
      {
        id: stoId,
        techOperationTypeId: operationId,
        checklistId,
        stageId,
        checklistAttributeId: attributeId,
        versionId,
      },
      {
        actions: {
          handleSuccess: response => {
            const optionList = response.map<ISelectOption<IAttribute>>(attr => ({
              label: attr.attribute.name,
              value: attr.id,
              initialModel: attr,
            }));

            const orderedOptionList = sortBy(optionList, 'label');

            this.store.setDependencyOptionList(orderedOptionList);
          },
        },
      }
    );
  };

  public fetchUserDictionaryList = async (
    organizationId: string,
    attributeId: string,
    stageId: string
  ): Promise<IUserDictionary[]> => {
    const response = await this.checklistsService.getUserDictionaryList({
      attributeId,
      organizationId,
      stageId,
      size: 2000,
    });

    if (!response?.content) return [];

    return response.content;
  };

  public getDictionaryOptionList = async (
    payload: TypeApiRequest<'getDictionaryEntityList'>
  ): Promise<TDictionarySelectOption[]> => {
    const response = await this.dictionaryService.getDictionaryEntityList(payload);

    if (!response?.content) return [];

    const optionList = response.content.map<TDictionarySelectOption>(el => ({
      label: el.name,
      value: el.id,
      initialModel: el,
    }));

    const clearedList = optionList.filter(o => o.label);

    return _.sortBy(clearedList, 'label');
  };

  public getEnumOptionList = async (
    payload: TypeApiRequest<'getChecklistEnumListByAttributeId'>
  ): Promise<TDictionarySelectOption[]> => {
    const response = await this.checklistsService.getEnumList(payload);

    if (!response) return [];

    const optionList = response.map<TDictionarySelectOption>(el => ({
      label: el.value,
      value: el.id,
    }));

    const clearedList = optionList.filter(o => o.label);

    return _.sortBy(clearedList, 'label');
  };
}

export default StoAttributeRequestsService;
