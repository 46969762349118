import React, { FC } from 'react';

import Container from '../../../../../../../../../../../shared/features/UI/new/Container/Container';
import { ContentLoader } from '../../../../../../../../../../../shared/features/UI/loaders/ContentLoader';

interface IProps {
  dataTestId: string;
}

const StoRegistryLoader: FC<IProps> = ({ dataTestId }) => {
  return (
    <Container dataTestId={dataTestId}>
      <ContentLoader
        dataTestId={dataTestId}
        overlayStyles={{ height: '200px', width: '100%', position: 'relative' }}
      />
    </Container>
  );
};

StoRegistryLoader.displayName = 'StoRegistryLoader';

export default StoRegistryLoader;
