import { TypeApiRoute } from '../../../models/type.api.request';

type TRequest = {
  stoId: string;
  dictionaryName?: string[];
  versionId?: string;
  asOnDate?: string;
  includeDrafts?: boolean;
};

type TResponse = Blob;

export const downloadStoDictionary: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ stoId }) => `/api/as-fields/sto/${stoId}/dictionaryDownload`,
  method: 'GET',
  headers: {},
  responseType: 'blob',
  request: {} as TRequest,
  response: {} as TResponse,
};
