import { ApiNames } from '../../../../../../../../shared/utils/axios2';
import { EFieldsImportFileExtension } from '../../../modals/containers/FieldsImportModalContainer/types';

const getApiNameByFileExtension = (fileExtension: EFieldsImportFileExtension): ApiNames | '' => {
  switch (fileExtension) {
    case EFieldsImportFileExtension.Kml:
      return 'importFieldsFromKml';
    case EFieldsImportFileExtension.GeoJSON:
      return 'importFieldsFromGeojson';
    case EFieldsImportFileExtension.Zip:
      return 'importFieldsFromZipShape';
    default:
      return '';
  }
};

export default getApiNameByFileExtension;
