import { makeAutoObservable } from 'mobx';

import { extractCultureNames } from '../../../../../../../../utils/helpers/extractCultureNames';
import { CultureModel } from '../../../../../../../../../../api/models/culture.model';
import { mapToArray } from '../../../../../../../../utils/helpers/map/mapToArray';
import { Field } from '../../../../../../../../../../api/models/field.model';
import { provide } from '../../../../../../../../utils/IoC';

@provide.singleton()
class CulturesLegendStore {
  private _cultures = new Map<string, CultureModel>();

  constructor() {
    makeAutoObservable(this);
  }

  public get cultures() {
    return mapToArray(this._cultures).sort((a, b) => a.name.localeCompare(b.name));
  }

  public setCultures(fields: Field | Field[]) {
    this._cultures = extractCultureNames(fields, false);
  }

  public get isNoCultures() {
    const culturesArray = mapToArray(this._cultures);
    return !Boolean(this._cultures.size) || culturesArray.every(culture => !culture.id);
  }

  public clearCultures() {
    this._cultures.clear();
  }
}

export default CulturesLegendStore;
