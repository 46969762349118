import { observer } from 'mobx-react';
import { FC, useEffect, useMemo, useCallback, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';
import { ButtonLink, useModal } from '@farmlink/farmik-ui';

import { FieldsStore } from '../../../../../shared/mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import { TasksController } from '../../controllers/tasks.controller';
import { TasksListingController } from '../../controllers/tasks.listing.controller';
import { PreparedTask, Task, TasksStore } from '../../stores/tasks.store';
import { OperationsStore } from '../../stores/operations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { DashboardRoutes } from '../../../../dashboard.routes';
import { ProfileStore } from '../../../profile/stores/ProfileStore';
import {
  cancellationModalConfig,
  cancellationModalSuccessConfig,
} from '../../modals/CancellationModal';
import { CheckAccessStore } from '../../../../../authorization/stores/checkAccess.store';
import { SCOUTING_ACCESS_ACTIONS } from '../../../../../shared/constants/access-rules-action';
import { ITask } from '../../../../../../api/models/as-fields/task/task.model';
import { TasksDrawer } from '../../../tasks/containers/TasksDrawer';
import { useOperationsTaskActions } from '../../hooks/useOperationsTaskActions';
import { ContentLoader } from '../../../../../shared/features/UI/loaders/ContentLoader';
import { Container } from '../../../../../shared/features/UI/Container';
import { useOperationsParams } from '../../hooks';

import {
  CreateNewTask,
  Spinner,
  TaskDateHeader,
  TaskGroupDate,
  TaskListWrapper,
  TasksBlockList,
  TasksDateBlock,
} from './style';
import { TasksItem } from './TaskItem';
import { WarningBlock } from './WarningBlock';
import { NoOperationsPlaceholder } from './NoOperationsPlaceholder';
import NoTasksPlaceHolder from './NoTasksPlaceholder';
import BottomBlockOfTasksList from './BottomBlockOfTasksList';

type Props = {
  showCulture?: boolean;
  isHidden?: boolean;
};

export const TasksList: FC<Props> = observer(({ showCulture, isHidden }) => {
  const navigate = useNavigate();
  const fieldsStore = useStore(FieldsStore);
  const operationsStore = useStore(OperationsStore);
  const { registerModalList } = useModal();

  const tasks = useStore(TasksStore);
  const tasksController = useStore(TasksController);
  const tasksListingController = useStore(TasksListingController);
  const seasonStore = useStore(SeasonsStore);
  const organizationsStore = useStore(OrganizationsStore);
  const tasksStore = useStore(TasksStore);
  const profileStore = useStore(ProfileStore);

  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);

  const { getAccessRuleValue } = useStore(CheckAccessStore);
  const operationsTaskActions = useOperationsTaskActions();

  const params = useOperationsParams();

  let lastInterval;

  const fieldsListingRoute = useMemo(
    () =>
      generatePath(DashboardRoutes.Fields, {
        orgId: organizationsStore.selectedOrganizationId,
      }),
    []
  );

  useEffect(() => {
    registerModalList(
      [
        cancellationModalConfig,
        {
          ...cancellationModalSuccessConfig,
          successButton: {
            title: 'Продолжить',
          },
        },
      ],
      'cancellationModal'
    );

    return () => {
      tasks.tasksMap.clear();
      operationsStore.clearOperationsStore();
      tasksController.clear();
    };
  }, []);

  const dateToTasks = useMemo(tasksListingController.getDateToTasks, [
    operationsStore.OperationsType,
    operationsStore.OperationsList,
    tasks.tasksMap.size,
    tasks.tasksMap,
  ]);

  const handleDrawerClose = useCallback(() => {
    tasks.setHighlightedTaskId(selectedTask.id);
    setSelectedTask(null);
  }, [selectedTask]);

  const handleSelectTask = useCallback((task: ITask) => {
    setSelectedTask(task);
  }, []);

  const handleIsDisableStatusChange = useCallback(
    (task: PreparedTask) => {
      const { user } = profileStore;

      if (organizationsStore.selectedOrganizationId === 'my') {
        return false;
      }

      if (user.scout) {
        if (getAccessRuleValue(SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS)) {
          return false;
        }

        return !(task?.assignee?.id === user?.id);
      }

      return false;
    },
    [organizationsStore.selectedOrganizationId, profileStore?.user?.id]
  );

  const loadAdditionalTasks = async () => {
    await tasksController.fetchTasks(
      {
        organizationId: params.orgId,
        seassonCultureId: params.cultureId,
        operationId: params.operationId,
        fieldId: params.fieldId,
        noCulture: params.cultureId === 'emptyCulture' ? true : undefined,
      },
      true
    );
  };

  const updateScrollRef = ref => {
    if (lastInterval) {
      clearInterval(lastInterval);
    }
    lastInterval = setInterval(async () => {
      if (
        ref &&
        !tasksStore.loading &&
        tasks.pageNumber < tasks.totalPages &&
        window.innerHeight - ref.getBoundingClientRect().top > 50
      ) {
        await loadAdditionalTasks();
      }
    }, 500);
  };

  if (!seasonStore.loading && !seasonStore.selectedSeason) {
    return (
      <WarningBlock
        title1={'Сначала добавьте сезон и поля'}
        title2={'После этого вы сможете запланировать операции и задачи на каждое поле'}
        buttonAction={() => {
          navigate(fieldsListingRoute);
        }}
      />
    );
  }

  if (!fieldsStore.hasFields) {
    return (
      <WarningBlock
        title1={'Добавьте поля '}
        title2={'После этого вы сможете запланировать операции и задачи на каждое поле'}
        buttonAction={() => {
          navigate(fieldsListingRoute);
        }}
      />
    );
  }

  if (!operationsStore.OperationsList.length && tasks.tasksLoaded) {
    return <NoOperationsPlaceholder />;
  }

  if (!tasks.tasksMap.size && tasks.tasksLoaded) {
    return <NoTasksPlaceHolder />;
  }

  return tasksStore.loading && !tasksStore.tasksLoaded ? (
    <Container>
      <ContentLoader
        overlayStyles={{
          position: 'relative',
          height: '352px',
          width: '100%',
        }}
      />
    </Container>
  ) : (
    <TaskListWrapper className="tasks-list" $isHidden={isHidden}>
      {Object.keys(dateToTasks).map((planDateKey, index) => (
        <TasksDateBlock key={index} data-test-id={'tasks-date-block'}>
          <TaskDateHeader>
            <TaskGroupDate data-test-id={'task-group-date'}>
              {`${moment(planDateKey).format('DD MMMM YYYY')}${' г.'}`}
            </TaskGroupDate>

            <CreateNewTask>
              <ButtonLink
                color={'accent'}
                onClick={() => {
                  operationsTaskActions.presetTask(
                    moment((dateToTasks[planDateKey][0] as Task).bindingDate).format('YYYY-MM-DD')
                  );
                }}
                data-test-id={'create-new-task-by-date-button'}
              >
                Новая задача
              </ButtonLink>
            </CreateNewTask>
          </TaskDateHeader>

          <TasksBlockList data-test-id={'tasks-block-list'}>
            {dateToTasks[planDateKey].map((task: PreparedTask) => (
              <TasksItem
                task={task}
                onClick={() => handleSelectTask(task as any)}
                showCulture={showCulture}
                key={task.id}
                profileUserName={`${profileStore.user?.lastName} ${profileStore.user?.firstName} ${profileStore.user?.secondName}`}
                isDisableStatusChange={handleIsDisableStatusChange(task)}
                isHighlighted={tasks.highlightedTaskId === task.id}
                unHighlightRow={() => tasks.clearHighlightedTaskId()}
              />
            ))}
          </TasksBlockList>
        </TasksDateBlock>
      ))}

      {tasks.tasksMap.size && tasks.tasksLoaded && !tasks.tasksLoadedByFilter ? (
        <BottomBlockOfTasksList />
      ) : null}

      {tasks.pageNumber < tasks.totalPages ? (
        <Spinner ref={ref => updateScrollRef(ref)} data-test-id={'tasks-list-spinner'} />
      ) : null}

      {selectedTask ? (
        <TasksDrawer fromModule={'operations'} task={selectedTask} onClose={handleDrawerClose} />
      ) : null}
    </TaskListWrapper>
  );
});
