import { FC } from 'react';
import { Switcher, Typography } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { StoFieldsTabStore } from '../../../../mobx/stores';
import { StoFieldsTabController } from '../../../../mobx/controllers';

import Styled from './FieldsTabListingItem.styles';

const FieldsTabListingItem: FC<{ fieldId: string; isBlocked?: boolean; isLast?: boolean }> = ({
  fieldId,
  isBlocked,
  isLast,
}) => {
  const store = useStore(StoFieldsTabStore);
  const controller = useStore(StoFieldsTabController);

  const fieldData = store.getPotentialCultureZone(fieldId);

  const value = fieldData.isSelected;

  const isSwitchBlocked = isBlocked;

  return (
    <Styled.Item $isLast={isLast} data-test-id={`sto-field-${fieldId}`} data-field-selected={value}>
      <Switcher
        isBlocked={isSwitchBlocked}
        value={value}
        onChange={() => controller.toggleField(fieldId)}
      />

      <Styled.FieldNameWrapper>
        <Typography dataTestId="sto-field-name">{fieldData.clientFieldName}</Typography>
      </Styled.FieldNameWrapper>
    </Styled.Item>
  );
};

export default observer(FieldsTabListingItem);
