import { FC, memo } from 'react';
import { Typography } from '@farmlink/farmik-ui';

import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { PointOutOfFieldWarningIcon } from '../../../../../../../../shared/components';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './TasksListPointsCell.styles';

interface IProps {
  row: ITask;
}

const TasksListPointsCell: FC<IProps> = ({ row }) => {
  const { pointOutOfField, instances } = row;

  const getDataTestId = useDataTestIdV2('tasks-table-point-cell');

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      {pointOutOfField && (
        <Styled.IconWrapper>
          <PointOutOfFieldWarningIcon getDataTestId={getDataTestId} />
        </Styled.IconWrapper>
      )}

      <Typography {...getDataTestId('name')}>{instances}</Typography>
    </Styled.Wrapper>
  );
};

TasksListPointsCell.displayName = 'TasksListPointsCell';

export default memo(TasksListPointsCell);
