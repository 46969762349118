import { TypeApiRoute } from '../../../models/type.api.request';
import { ICreateSto, IStoContent } from '../../../models/as-fields/STO/STO.model';

type TRequest = ICreateSto;

type TResponse = IStoContent;

export const createSto: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/sto`,
  method: 'POST',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
