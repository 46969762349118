import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const AllFieldsSelectedPlugWrapper = styled.div`
  padding-top: 36px;
  padding-bottom: 6px;
  background-color: ${Colors.generalWhite};
  border-radius: 16px;
`;

export default AllFieldsSelectedPlugWrapper;
