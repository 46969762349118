import styled from 'styled-components';
import { Button as InitialButton } from '@farmlink/farmik-ui';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  display: none;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const Button = styled(InitialButton)`
  width: 200px;
  height: 40px;

  border-radius: 12px;
`;

const StyledStoListNoDataPlug = {
  LoaderWrapper,
  Input,
  Button,
  Footer,
};

export default StyledStoListNoDataPlug;
