import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import { StoStore } from '../../../../mobx';
import { StoFieldsTabService } from '../services';
import { StoFieldsTabStore } from '../stores';

@provide.transient()
class StoFieldsTabController {
  @lazyInject(StoStore)
  protected store: StoStore;

  @lazyInject(StoFieldsTabStore)
  protected tabStore: StoFieldsTabStore;

  @lazyInject(StoFieldsTabService)
  protected tabService: StoFieldsTabService;

  fetchFieldList = (options?: { versionId?: string }) => {
    this.tabService.fetchFieldList({
      id: this.store.selectedSto?.id,
      includeUnavailable: true,
      page: 0,
      size: 50,
      ...(options?.versionId && { versionId: options.versionId }),
    });
  };

  toggleSearch = (state: boolean) => {
    this.tabStore.setIsSearchEnabled(state);
  };

  searchFields = (searchValue: string) => {
    this.tabService.searchZonesByClientFieldName(searchValue);
  };

  toggleField = (fieldId: string) => {
    this.tabService.toggleField(fieldId);
  };

  toggleAllFields = (state: boolean) => {
    this.tabService.toggleAllFields(state);
  };

  hasFieldIdInTransfer = (fieldId: string) => {
    return this.tabStore.transferredFiledIdSet.has(fieldId);
  };

  transferField = (fieldId: string) => {
    this.tabService.transferField(fieldId);
  };

  cancelTransferField = (fieldId: string) => {
    this.tabService.cancelTransferField(fieldId);
  };
}

export default StoFieldsTabController;
