import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { NewDropdown } from '@farmlink/farmik-ui';

import { CultureModel } from '../../../../../../api/models/culture.model';
import { CultureZone } from '../../../../../../api/models/field.model';
import { Axios } from '../../../../../shared/utils/axios2';
import conditionalObjectProp from '../../../../../shared/utils/conditionalObjectProp';
import { useStore } from '../../../../../shared/utils/IoC';

interface IProps {
  defaultValue?: CultureZone;
  isRequired?: boolean;
  disabled?: boolean;
  dataTestId: string;
  onChange: (culture: CultureModel) => void;
}

const CultureSelect: FC<IProps> = props => {
  // Сторы
  const axiosStore = useStore(Axios);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<string>(props.defaultValue?.culture?.name);
  const [fetchedCultures, setFetchedCultures] = useState<CultureModel[]>([]);

  const isErrorState = props.isRequired && !props.disabled && !selectedId;

  const getOptions = () => {
    return (fetchedCultures ?? []).map(culture => ({
      label: culture.name,
      value: culture.id,
    }));
  };

  const handleSelect = (cultureId: string) => {
    const selectedCulture = fetchedCultures.find(item => item.id === cultureId);

    if (props.disabled || !selectedCulture) {
      return;
    }

    setSelectedId(cultureId);
    props.onChange(selectedCulture);
  };

  useEffect(() => {
    setIsLoading(true);

    axiosStore.api
      .findAllCultures(
        {
          nameFilter: '',
          fetchAttributes: true,
          attrs: { useInAssistance: true },
          limit: 50,
          page: 0,
        },
        { omit: ['limit', 'page'] }
      )
      .then(({ content }) => {
        setFetchedCultures(content);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <NewDropdown
      config={{
        field: {
          isRequired: props.isRequired,
          type: {
            search: {},
          },
          ...conditionalObjectProp(Boolean(props.defaultValue), {
            defaultValue: {
              label: props.defaultValue?.culture?.name,
              value: props.defaultValue?.culture?.name,
            },
          }),
          placeholder: 'Введите культуру',
          onChange: handleSelect,
        },
        body: {
          optionList: getOptions(),
          zIndex: '10002',
        },
        validation: {
          error: {
            errorList: isErrorState ? ['Обязательно для заполнения'] : [],
          },
        },
        visual: {
          label: 'Культура',
          isLoading,
          isBlocked: props.disabled,
        },
        other: {
          dataTestId: props.dataTestId,
        },
      }}
    />
  );
};

export default observer(CultureSelect);
