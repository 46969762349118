import { FC } from 'react';

import { Container } from '../../../../../../../../../shared/features/UI/new/Container';
import { ContentLoader } from '../../../../../../../../../shared/features/UI/loaders/ContentLoader';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

const StoHistoryLoader: FC = () => {
  const getDataTestId = useDataTestIdV2('sto-history-loader');
  const dataTestId = getDataTestId()['data-test-id'];

  return (
    <Container dataTestId={dataTestId}>
      <ContentLoader
        dataTestId={dataTestId}
        overlayStyles={{ height: '400px', width: '100%', position: 'relative' }}
      />
    </Container>
  );
};

StoHistoryLoader.displayName = 'StoHistoryLoader';

export default StoHistoryLoader;
