import { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../mobx/stores';
import { TaskController } from '../../../../mobx/controllers';
import { TableBuilder } from '../../../../../../../shared/features/TableBuilder';
import { ETasksTableBuilderId } from '../../../../utils/constants';
import { ITask } from '../../../../../../../../api/models/as-fields/task/task.model';
import { TasksDrawer } from '../../../../containers/TasksDrawer';
import { TableBuilderController } from '../../../../../../../shared/features/TableBuilder/mobx/controllers';
import { ITableBuilderRowConfig } from '../../../../../../../shared/features/TableBuilder/models/configs';

const CreatedTasksTable: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);
  const tableBuilderController = useStore(TableBuilderController);

  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);

  const handleDrawerClose = useCallback(() => {
    setSelectedTask(null);
  }, []);

  const handleRowClick = useCallback<ITableBuilderRowConfig<ITask>['onRowClick']>((row, event) => {
    event.stopPropagation();

    setSelectedTask(row);
  }, []);

  useEffect(() => {
    taskController.initiateCreatedTasksTable();
    tableBuilderController.addRowClickEvent(ETasksTableBuilderId.CreatedTasks, handleRowClick);

    return () => {
      taskStore.clearCreatedTasksById();
    };
  }, []);

  return (
    <>
      <TableBuilder builderId={ETasksTableBuilderId.CreatedTasks} />

      {selectedTask ? (
        <TasksDrawer
          fromModule={'tasks'}
          task={selectedTask}
          onClose={handleDrawerClose}
          isDoNotNeedToSaveTaskHighlightingAfterReturn
        />
      ) : null}
    </>
  );
};

CreatedTasksTable.displayName = 'CreatedTasksTable';

export default observer(CreatedTasksTable);
