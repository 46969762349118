import styled, { css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const Wrapper = styled.div<{ $isLast: boolean; $isHighlighted: boolean }>`
  transition: all 0.3s ease-in-out;

  display: grid;
  grid-template-columns: 40px 1fr 60px;
  grid-gap: 12px;
  align-items: center;

  padding: 12px 0;

  border-bottom: 1px solid ${Colors.technicalBorderGrey};

  ${({ $isLast }) =>
    $isLast &&
    css`
      padding-bottom: 8px;
      border-bottom: none;
    `};

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      background-color: ${Colors.lightSky};
      border-radius: 8px;
      border-bottom: none;
      padding: 12px 0;
      cursor: pointer;
    `};
`;

const StyledStoAttributeUserDictionaryRegistryItem = {
  Actions,
  Wrapper,
};

export default StyledStoAttributeUserDictionaryRegistryItem;
