import styled, { css } from 'styled-components';

const Element = styled.div<{ $isDisableLine?: boolean }>`
  display: grid;
  grid-template-columns: 40px 1fr 109px;
  align-items: center;
  grid-gap: 12px;

  padding-bottom: 12px;

  ${({ $isDisableLine }) => {
    if (!$isDisableLine) {
      return css`
        border-bottom: 1px solid #e6e9ee;
      `;
    }
  }}
`;

const StyledStoAttributePrintSettings = {
  Element,
};

export default StyledStoAttributePrintSettings;
