import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  height: '100%',
});

const FiltersContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: ESpaceSize.L,
  padding: `${ESpaceSize.L}px ${ESpaceSize.M}px`,
});

const Styles = {
  Wrapper,
  FiltersContainer,
};

export default Styles;
