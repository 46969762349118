import { useMemo } from 'react';

import { IGetChecklistAttribute } from '../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { getChecklistsAttrPlaceholder as getAttrPlaceholder } from '../../helpers';

const useChecklistAttrPlaceholder = ({
  type,
  placeholder,
}: IGetChecklistAttribute['attribute']): string => {
  return useMemo(() => placeholder || getAttrPlaceholder(type), [type, placeholder]);
};

export default useChecklistAttrPlaceholder;
