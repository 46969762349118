import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizesObj } from '../../../../../../../shared/constans/sizes';

const AreaLabel = styled('div')({
  margin: 0,
  color: Colors.generalBlack,
  whiteSpace: 'nowrap',

  ...FontSizesObj.medium,
});

const Styles = {
  AreaLabel,
};

export default Styles;
