import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { ScrollableContainer } from '../../../../../../../../../shared/containers';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoOperationsChangeService } from '../../mobx/services';
import { StoOperationsCoreStore } from '../../mobx/stores';
import { useStoNavigation, useStoParams } from '../../../../../../hooks';
import { StoStore } from '../../../../mobx';

import { StoOperationsListItem } from './components';

const StoOperationsList: FC = () => {
  const { itemList } = useStore(StoOperationsCoreStore);
  const { toggleOperationSelection } = useStore(StoOperationsChangeService);
  const { isViewOnly } = useStore(StoStore);

  const { stoId, versionId } = useStoParams();
  const { navigateToOperation } = useStoNavigation();

  const maxHeight = itemList.length >= 10 ? 610 : itemList.length * 61;

  const handleSettingsClick = useCallback((id: string): void => {
    navigateToOperation(stoId, id, false, { query: { ...(versionId && { versionId }) } });
  }, []);

  const handleSwitcherClick = useCallback((id: string, value: boolean): void => {
    toggleOperationSelection(id, value);
  }, []);

  return (
    <ScrollableContainer
      maxHeight={`${maxHeight}px`}
      isDisabled={itemList.length <= 10}
      dataTestId={'sto__operations__list'}
    >
      {itemList.map(item => (
        <StoOperationsListItem
          key={item.techOperationTypeId}
          onSettingsClick={handleSettingsClick}
          onSwitcherClick={handleSwitcherClick}
          isViewOnly={isViewOnly}
          {...item}
        />
      ))}
    </ScrollableContainer>
  );
};

StoOperationsList.displayName = 'StoOperationsList';

export default observer(StoOperationsList);
