const WeatherEngToRus = (key: string): string => {
  const list = {
    clear: 'Ясно',
    'partly-cloudy': 'Малооблачно',
    cloudy: 'Облачно',
    overcast: 'Пасмурно',
    drizzle: 'Морось',
    'light-rain': 'Небольшой дождь',
    rain: 'Дождь',
    'moderate-rain': 'Умеренно сильный дождь',
    'heavy-rain': 'Сильный дождь',
    'continuous-heavy-rain': 'Длительный сильный дождь',
    showers: 'Ливень',
    'wet-snow': 'Дождь со снегом',
    'light-snow': 'Небольшой снег',
    snow: 'Снег',
    'snow-showers': 'Снегопад',
    hail: 'Град',
    thunderstorm: 'Гроза',
    'thunderstorm-with-rain': 'Дождь с грозой',
    'thunderstorm-with-hail': 'Гроза с градом',
  };

  return list[key];
};

export default WeatherEngToRus;
