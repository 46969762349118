import { observer } from 'mobx-react';
import { FC } from 'react';

import { CulturesLegendMapControl } from '../../../../../../../shared/features/map/components/Controls';
import { CultureModel } from '../../../../../../../../api/models/culture.model';
import { ELeftPanelType } from '../../interfaces/LeftPanelType.enum';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../mobx';

/**
 * Интеракивный вариант легенды культур для режима просмотра полей (синхронизуется с фильтрами по культуре)
 */
const FieldViewCulturesLegend: FC = () => {
  // Сторы
  const store = useStore(Store);

  const cultureFilter = store.filterManager?.filters.getOne('culture');

  const getSelectedCultures = () => {
    if (store.leftPanelType !== ELeftPanelType.FILTER) {
      return [];
    }

    return cultureFilter?.value ?? [];
  };

  const handleItemClick = (culture: CultureModel) => {
    store.leftPanelType = ELeftPanelType.FILTER;

    store.filterManager?.tempEditor.enable();
    cultureFilter?.toggleValue(culture.name);
  };

  return (
    <CulturesLegendMapControl selectedCultures={getSelectedCultures()} onClick={handleItemClick} />
  );
};

export default observer(FieldViewCulturesLegend);
