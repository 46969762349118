import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { BorderRadius } from '../../../../../../../../../../constans/sizes';

const Wrapper = styled('div')({
  display: 'flex',
  gap: ESpaceSize.S,
  overflow: 'hidden',
});

const TimelineWrapper = styled('div')({
  backgroundColor: Colors.generalWhite,
  borderRadius: BorderRadius.default,
  width: '100%',
  height: 40,
  display: 'flex',
  overflow: 'hidden',
});

export default {
  Wrapper,
  TimelineWrapper,
};
