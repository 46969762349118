import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  100% {
    transform:rotate(360deg);
  }
`;

const Svg = styled.svg`
  animation: ${rotateAnimation} 1s linear infinite;
`;

const StyledSimpleLoader = {
  Svg,
};

export default StyledSimpleLoader;
