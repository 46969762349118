import styled, { css } from 'styled-components';

const Title = styled.div`
  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  /* body: reg14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
`;

const Background = styled.div<{ $value: boolean }>`
  width: 38px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  padding: 4px 8px;

  ${({ $value }) =>
    $value
      ? css`
          background-color: #e7f1fd;
        `
      : css`
          background-color: #fef5f5;
        `};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledStoChecklistsBooleanCell = {
  Title,
  Background,
  Wrapper,
};

export default StyledStoChecklistsBooleanCell;
