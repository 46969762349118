import styled from 'styled-components';

const Icon = styled.div<{ $url?: string }>`
  width: 16px;
  height: 16px;

  flex: 0 0 16px;
  cursor: pointer;

  background: url(${({ $url }) => $url});
`;

export default {
  Icon,
};
