import { Layer } from 'leaflet';

import { lazyInject } from '../../../../../../../../../../utils/IoC';
import MapControlsStore from '../../../../../../mobx/stores/Controls.store';
import { IFillStrategy } from '../../../../interfaces/FillStrategy.model';
import { MapCoreController } from '../../../../../../../../modules';
import { IFillCultureLayer } from '../../../../../../../../models';

class CulturesFillStrategy implements IFillStrategy {
  @lazyInject(MapCoreController)
  private mapCoreController: MapCoreController;

  @lazyInject(MapControlsStore)
  private mapControlsStore: MapControlsStore;

  constructor(private layer?: Layer) {}

  public initialize() {
    const layersList = this.getLayersListToFill();

    layersList.forEach(layer => {
      layer.showCulturesPolygons();
      layer.openTooltip();
    });

    // Показываем легенду по культурам (если она зарегистрирована)
    this.mapControlsStore.toggleVisibility('culturesLegend', true);

    return Promise.resolve();
  }

  public reset(): void {
    const layersList = this.getLayersListToFill();
    layersList.forEach(layerGroup => layerGroup.hideCulturesPolygons());

    // Скрываем легенду по культурам (если она зарегистрирована)
    this.mapControlsStore.toggleVisibility('culturesLegend', false);
  }

  private getLayersListToFill() {
    return isLayerValid(this.layer) ? [this.layer] : this.getAllLayerGroups();
  }

  private getAllLayerGroups(): (Layer & IFillCultureLayer)[] {
    return this.mapCoreController.getAllMapLayers().filter(isLayerValid) as any;
  }
}

const isLayerValid = (layer: any): layer is IFillCultureLayer => {
  const hasShowFn = typeof layer?.showCulturesPolygons === 'function';
  const hasHideFn = typeof layer?.hideCulturesPolygons === 'function';

  return hasShowFn && hasHideFn;
};

export default CulturesFillStrategy;
