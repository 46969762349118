import { makeAutoObservable } from 'mobx';

import FilterManager from '../../core/FilterManager';
import { provide } from '../../../../utils/IoC';
import Filter from '../../core/Filter';

@provide.singleton()
class FilterStore {
  // Коллекция хранит инстансы созданных фильтр-менеджеров
  private _filtersCollection = new Map<string, FilterManager>();

  constructor() {
    makeAutoObservable(this);
  }

  public getManager<F extends Filter[], A, S>(id: string): FilterManager<F, A, S> {
    return this._filtersCollection.get(id) as FilterManager<F, A, S>;
  }

  public setManager(id: string, instance: FilterManager) {
    this._filtersCollection.set(id, instance);
  }

  public removeManager(id: string) {
    this._filtersCollection.delete(id);
  }
}

export default FilterStore;
