export const ACCESS_ACTIONS_MODULES = ['field', 'scouting', 'experiment', 'sto'];

export enum EFieldAccessAction {
  EditCultureZone = 'field.editCultureZone',
  EditSeason = 'field.editSeason',
  DeleteSeason = 'field.deleteSeason',
  CreateFieldOrSeason = 'field.createFieldOrSeason',
  DeleteField = 'field.deleteField',
  EditField = 'field.editField',
}

export enum SCOUTING_ACCESS_ACTIONS {
  WORK_WITH_TASKS = 'scouting.workWithTasks',
  MANAGE_TASKS = 'scouting.manageTasks',
  EDIT_ACTUAL_POINT = 'scouting.editActualPoints',
}

export enum EExperimentsAccessActions {
  IncludeCultureZones = 'experiments.includeCultureZones',
}

export enum EStoAccessAction {
  View = 'sto.view',
  Edit = 'sto.edit',
  Publish = 'sto.publish',
}

export const STO_ACCESS_ACTION_LIST = [EStoAccessAction.View];
