import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizesObj } from '../../../../../../../../../../../../../shared/constans/sizes';

interface IListItemProps {
  $color: string;
}

const List = styled('div')({
  height: 'calc(100% - 40px)',
  marginTop: ESpaceSize.M,
});

const ListItem = styled('div')<IListItemProps>(
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,

    '& span': {
      ...FontSizesObj.medium,
    },

    '& span:nth-child(2)': {
      color: Colors.secondaryDarkGray,
      whiteSpace: 'nowrap',
    },
  },
  ({ $color }) => ({
    '::before': {
      content: "''",
      position: 'absolute',
      width: ESpaceSize.S,
      height: ESpaceSize.S,
      borderRadius: '50%',
      backgroundColor: $color,
    },
  })
);

const NameWrapper = styled('div')({
  overflow: 'hidden',
  marginLeft: ESpaceSize.L,
});

const Styles = {
  List,
  ListItem,
  NameWrapper,
};

export default Styles;
