import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

type ISliderProps = {
  visibility: boolean;
};

const Slider = styled.div<ISliderProps>`
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${Colors.secondaryBGGrey};
  position: absolute;
  z-index: 10001;
  width: ${({ visibility }) => (visibility ? '100%' : '0')};
  transition: width 0.3s ease;
  @media (max-width: 1365px) {
    padding: ${({ visibility }) => (visibility ? '16px' : '0')};
  }
  @media (max-width: 1024px) {
    padding: ${({ visibility }) => (visibility ? '12px' : '0')};
  }
  overflow-y: scroll;
  /* ie 10+ */
  & {
    -ms-overflow-style: none;
  }

  /* фф (свойство больше не работает, других способов тоже нет)*/
  & {
    overflow: -moz-scrollbars-none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Container = styled('div')({
  padding: '24px',
});

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
  flex-shrink: 0;
  @media (max-width: 1366px) {
    margin-bottom: 16px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 12px;
  }
`;

const Styles = {
  Slider,
  Content,
  Container,
};

export default Styles;
