import { Colors, ESpaceSize, Input as FarmikInput } from '@farmlink/farmik-ui';
import styled, { CSSObject } from 'styled-components';

interface IInputProps {
  isOpen: boolean;
}

interface IWrapperProps {
  $styles: CSSObject;
}

const Wrapper = styled('div')<IWrapperProps>(
  {
    position: 'relative',
    backgroundColor: Colors.generalWhite,
    borderRadius: ESpaceSize.M,
    width: '100%',
  },
  ({ $styles }) => ({
    ...$styles,
  })
);

const InnerContainer = styled('div')({
  '&:hover': {
    '& div:nth-child(1) input': {
      borderColor: Colors.hoverBorderGrey,
    },

    '& div:nth-child(3)': {
      borderColor: Colors.hoverBorderGrey,
    },
  },
});

const Input = styled(FarmikInput)<IInputProps>({}, ({ isOpen }) => ({
  borderBottomLeftRadius: !isOpen ? ESpaceSize.M : 0,
  borderBottomRightRadius: !isOpen ? ESpaceSize.M : 0,
  borderBottom: !isOpen ? `1px solid ${Colors.borderGray}` : 'none',
}));

const Body = styled('div')({
  position: 'absolute',

  display: 'flex',
  flexDirection: 'column',
  gap: 12,

  width: '100%',
  maxHeight: '200px',
  backgroundColor: Colors.secondaryGray,
  zIndex: 100,
  overflow: 'auto',
  padding: '10px 16px',
  border: `1px solid ${Colors.borderGray}`,
  borderTop: 0,
  borderBottomLeftRadius: ESpaceSize.M,
  borderBottomRightRadius: ESpaceSize.M,

  '&::-webkit-scrollbar': {
    width: '12px',
  },

  '&::-webkit-scrollbar-thumb': {
    height: '32px',
    border: `4px solid transparent`,
    backgroundClip: 'padding-box',
    borderRadius: ESpaceSize.XL,
    opacity: 0.8,
    backgroundColor: Colors.darkGrey,

    '&:hover': {
      border: `2px solid transparent`,
      cursor: 'pointer',
    },
  },
});

const Option = styled('span')({
  display: 'block',
  cursor: 'pointer',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  color: Colors.secondaryDarkGray,

  '& b': {
    color: Colors.generalBlack,
    fontWeight: 400,
  },
  '&:hover': {
    color: Colors.generalBlack,
  },
});

const Divider = styled('div')({
  width: '100%',
  position: 'relative',

  '&:after': {
    content: "''",
    position: 'absolute',
    left: ESpaceSize.L,
    right: ESpaceSize.L,
    zIndex: 101,
    height: '1px',
    backgroundColor: Colors.borderShadowGray,
  },
});

export default {
  Wrapper,
  InnerContainer,
  Body,
  Option,
  Divider,
  Input,
};
