import { Typography } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const TopBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  scrollbar-gutter: stable;
  padding-right: 8px;
`;

const TaskBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 24px;
`;

const Info = styled(Typography)`
  margin-left: 12px;
`;

const FieldsCountInfo = styled(Info)`
  cursor: pointer;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  margin-bottom: 12px;
`;

const Label = styled(Typography)`
  min-width: 162px;
`;

const InfoImg = styled.img`
  height: 32px;
  width: 32px;
  max-height: 32px;
  max-width: 32px;
`;

const CommentBlock = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const CommentLabel = styled(Typography)`
  margin-bottom: 4px;
`;

const StyledTasksDrawerTask = {
  TaskBody,
  TopBlock,
  Info,
  FieldsCountInfo,
  InfoBlock,
  Label,
  InfoImg,
  CommentBlock,
  CommentLabel,
};

export default StyledTasksDrawerTask;
