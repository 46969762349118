import { observer } from 'mobx-react';
import { FC, useEffect, useMemo } from 'react';
import { Typography } from '@farmlink/farmik-ui';

import { useTaskAccessRules, useTasksParams, useTasksRouteActions } from '../../hooks';
import { useStore } from '../../../../../shared/utils/IoC';
import TaskFullscreen from '../../components/TaskFullscreen/TaskFullscreen';
import { TaskStore } from '../../mobx/stores';
import { TaskHeaderSideContent } from '../../components/TaskHeaderSideContent';
import { TaskStatusPill } from '../../components/TaskStatusPill';
import { IFullscreenProps } from '../../containers/fullscreen';
import { useTasksBackButtonData } from '../../hooks/useTasksBackButtonData';
import { ChecklistInstancesStore } from '../../../operationsAndTasks/stores/checklist.instances.store';
import { ETaskTabs } from '../../models';

import Styled from './TaskViewPage.styles';

const TaskViewPage: FC = () => {
  const taskStore = useStore(TaskStore);
  const { drawInstanceList } = useStore(ChecklistInstancesStore);

  const tasksRouteActions = useTasksRouteActions();
  const taskAccessRules = useTaskAccessRules();
  const backButtonData = useTasksBackButtonData();

  const params = useTasksParams();

  const actionButton = useMemo(() => {
    if (taskAccessRules.isAllowTaskEditing) {
      return {
        label: 'Редактировать',
        onClick: () => tasksRouteActions.goToTaskEdit(),
      };
    }
  }, [taskAccessRules.isAllowTaskEditing, tasksRouteActions.goToTaskEdit]);

  useEffect(() => {
    const defaultTab = tasksRouteActions.getDefaultTaskTab();

    taskStore.setMode('view');

    if (defaultTab?.isRedirectFromIndexLink) {
      const isChecklistWithoutId =
        defaultTab.tab === ETaskTabs.Checklists && !Boolean(params.checklistInstanceId);

      if (isChecklistWithoutId) {
        if (drawInstanceList?.length) {
          defaultTab.navigateHandler(drawInstanceList[0].id);
          taskStore.setTaskTab(defaultTab.tab);
        } else {
          tasksRouteActions.goToTask();
          taskStore.setTaskTab(ETaskTabs.Summary);
        }
      } else {
        defaultTab.navigateHandler();
        taskStore.setTaskTab(defaultTab.tab);
      }
    }
  }, []);

  const taskFullScreenConfig = useMemo<IFullscreenProps>(
    () => ({
      actionButton,
      backButtonHandler: backButtonData.handler,
      backButtonText: (
        <Styled.TaskTitlePaddingWrapper>
          <Typography className="header-title" variant="h4">
            <Styled.TaskTitleWrapper>
              <span data-test-id="operation-type-name">
                <Styled.TaskTitleEllipsis
                  dataTestId={'tasks__task__header__title'}
                  tooltipDataTestId={'tasks__task__header__title__tooltip'}
                  postfix={
                    <Styled.TaskStatusPillContent>
                      <TaskStatusPill
                        status={taskStore.selectedTask?.status}
                        eventList={taskStore.taskEventList}
                      />
                    </Styled.TaskStatusPillContent>
                  }
                >
                  {taskStore.selectedTask?.operationInfo?.name}
                </Styled.TaskTitleEllipsis>
              </span>
            </Styled.TaskTitleWrapper>
          </Typography>
        </Styled.TaskTitlePaddingWrapper>
      ),
      mode: 'view',
      arrowText: backButtonData.arrowText,

      rightSideContent: (
        <TaskHeaderSideContent
          status={taskStore.selectedTask?.status}
          availableStatusList={taskStore.selectedTask?.availableStatuses}
          mode={'view'}
        />
      ),
    }),
    [
      taskStore.selectedTask?.operationInfo?.name,
      taskStore.selectedTask?.status,
      taskStore.taskEventList,
    ]
  );

  return <TaskFullscreen fullscreenProps={taskFullScreenConfig} mode={'view'} />;
};

TaskViewPage.displayName = 'TaskViewPage';

export default observer(TaskViewPage);
