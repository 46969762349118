import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { AutoSizer, ListRowProps } from 'react-virtualized';
import { VirtualizedList } from '@farmlink/farmik-ui';

import { EFieldAccessAction } from '../../../../../../../../../shared/constants/access-rules-action';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import FieldsListSkeleton from '../../../../../../components/ListSkeleton/ListSkeleton';
import { useVirtualizedListScroll } from '../../../../../../../../../shared/hooks';
import { SeasonsStore } from '../../../../../../../../stores/seasons.store';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { useFieldsPermissions } from '../../../../../../hooks';
import { FieldsMapCoreStore } from '../../../../../../mobx';
import { FieldsViewStore as Store } from '../../../../mobx';
import LeftPanelHeader from '../Header/Header';

import FieldsListPlaceholder from './components/Placeholder/Placeholder';
import CulturesBar from './components/CulturesBar/CulturesBar';
import FieldsViewListFooter from './components/Footer/Footer';
import FieldCard from './components/FieldCard/FieldCard';
import Styled from './FieldList.styles';

const FieldsList: FC = () => {
  // Сторы
  const store = useStore(Store);
  const seasonsStore = useStore(SeasonsStore);
  const fieldsMapCoreStore = useStore(FieldsMapCoreStore);

  const list = store.filterManager?.filteredList ?? [];

  const permissions = useFieldsPermissions();
  const scroll = useVirtualizedListScroll(list);
  const getDataTestId = useDataTestIdV2('fields-view-list');

  const hasSeasons = Boolean(seasonsStore.selectedSeason);
  const hasFields = store.filterManager?.sourceList.length > 0;
  const hasMultiSelectAccess =
    permissions.hasAccess(EFieldAccessAction.EditCultureZone) ||
    permissions.hasAccess(EFieldAccessAction.DeleteField);

  const rowRender = ({ index, key, style }: ListRowProps) => {
    return <FieldCard key={key} field={list[index]} style={style} />;
  };

  const handleScroll = () => {
    store.isOpenLegend = false;
  };

  useEffect(() => {
    scroll.to({ id: fieldsMapCoreStore.selectedField?.id });
  }, [fieldsMapCoreStore.selectedField?.id]);

  useEffect(() => {
    // Очищает коллекцию новых полей
    return () => fieldsMapCoreStore.clearNewFields();
  }, []);

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <LeftPanelHeader multiselect={hasMultiSelectAccess} filter search={list.length > 0} />
      <CulturesBar isLoading={store.isInitialize} />

      <FieldsListSkeleton length={20} isLoading={store.isInitialize} getDataTestId={getDataTestId}>
        {!hasSeasons && <FieldsListPlaceholder type={'NO_SEASONS'} />}
        {hasSeasons && !hasFields && <FieldsListPlaceholder type={'NO_ITEMS'} />}

        <Styled.ListContainer>
          <AutoSizer>
            {size => (
              <VirtualizedList
                {...size}
                rowCount={list.length}
                rowHeight={60}
                rowRenderer={rowRender}
                scrollToIndex={scroll.activeIndex}
                overscanRowCount={4}
                onScroll={handleScroll}
              />
            )}
          </AutoSizer>
        </Styled.ListContainer>
      </FieldsListSkeleton>

      <FieldsViewListFooter />
    </Styled.Wrapper>
  );
};

export default observer(FieldsList);
