import styled from 'styled-components';

const Wrapper = styled('div')({
  position: 'relative',
});

const Hint = styled('span')({
  position: 'absolute',
  top: '53%',
  left: 13,
  right: 30,
  transform: 'translateY(-50%)',
  fontSize: 14,
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  pointerEvents: 'none',
  opacity: 0.6,
});

export default {
  Wrapper,
  Hint,
};
