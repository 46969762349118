import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { OperationCulture } from '../../../../../../api/models/operations/operation.culture';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { ConfigurableSlider } from '../ConfigurableSlider';
import { CultureItem } from '../CultureItem';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationsStore } from '../../stores/operations.store';
import { TasksStore } from '../../stores/tasks.store';
import { TasksController } from '../../controllers/tasks.controller';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { useOperationsParams } from '../../hooks';
import { useOperationsParamsActions } from '../../hooks/useOperationsParamsActions';

import NoSeasonsSvg from './assets/noSeasonsSvg.svg';
import {
  NoSeasonsPlaceHolder,
  NoSeasonsPlaceHolderTextBlock,
  NoSeasonsText1,
  NoSeasonsText2,
} from './style';

type CultureListType = {
  cultures: OperationCulture[];
  fieldsWithoutCulturesCount: number;
};

export const CultureList: FC<CultureListType> = observer(
  ({ cultures, fieldsWithoutCulturesCount }) => {
    const seasonsStore = useStore(SeasonsStore);
    const store = useStore(OperationsStore);
    const tasksStore = useStore(TasksStore);
    const tasksController = useStore(TasksController);
    const fieldsStore = useStore(FieldsStore);

    const params = useOperationsParams();
    const paramsActions = useOperationsParamsActions();

    useEffect(() => {
      const cultureCard = document.querySelector(`[id='${params.cultureId}']`);

      if (!cultureCard) return;

      setTimeout(() => {
        cultureCard.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }, 300);
    }, [params.cultureId, cultures]);

    const createFormattedFieldName = (
      fieldCountNumber: number,
      fieldName = ['поле', 'поля', 'полей']
    ): string => {
      const fieldCountNumberFirst = fieldCountNumber % 100;
      const fieldCountNumberSecond = fieldCountNumber % 10;
      if (fieldCountNumberFirst > 10 && fieldCountNumberFirst < 20) {
        return fieldName[2];
      }
      if (fieldCountNumberSecond > 1 && fieldCountNumberSecond < 5) {
        return fieldName[1];
      }
      if (fieldCountNumberSecond === 1) {
        return fieldName[0];
      }
      return fieldName[2];
    };

    const handleSelectCulture = async cultureId => {
      if (params.cultureId === cultureId) return;

      paramsActions.selectCulture(cultureId);

      store.OperationsList = [];
      store.OperationsType = [];
      tasksController.clear();
      store.resetPageNumberSettings();

      await store.getOperations(cultureId);
      tasksController.resetPageSettings();

      await tasksController.fetchTasks({
        organizationId: params.orgId,
        seassonCultureId: cultureId,
        operationId: '',
        fieldId: '',
        noCulture: cultureId === 'emptyCulture' ? true : undefined,
      });
    };

    return seasonsStore.selectedSeason && fieldsStore.hasFields ? (
      <ConfigurableSlider dataTestId="operation-cultures">
        {fieldsWithoutCulturesCount > 0 && (
          <CultureItem
            id={'emptyCulture'}
            onClick={handleSelectCulture.bind(this, 'emptyCulture')}
            name={'Культура не указана'}
            fieldCount={`${fieldsWithoutCulturesCount} ${createFormattedFieldName(
              fieldsWithoutCulturesCount
            )}`}
            img={''}
            selected={params.cultureId === 'emptyCulture'}
            key={'emptyCulture'}
            data-test-id={'operation-emptyculture-item'}
            isDisabled={store.isLoading || tasksStore.loading}
          />
        )}

        {cultures.map(item => {
          return (
            <CultureItem
              onClick={handleSelectCulture.bind(this, item.culture.id)}
              name={item.culture.name}
              fieldCount={`${item.fieldCount} ${createFormattedFieldName(item.fieldCount)}`}
              totalArea={`${item.totalArea.toFixed(1)} га`}
              img={item.culture.attrs?.smallAssistancePic}
              selected={params.cultureId === item.culture.id}
              key={item.culture.id + item.variety}
              id={item.culture.id}
              isDisabled={store.isLoading || tasksStore.loading}
            />
          );
        })}
      </ConfigurableSlider>
    ) : store.isLoading ? null : (
      <NoSeasonsPlaceHolder data-test-id={'no-seasons-or-fields-placeholder'}>
        <img src={NoSeasonsSvg} />
        <NoSeasonsPlaceHolderTextBlock>
          <NoSeasonsText1>Здесь будут отображаться все культуры выбранного сезона</NoSeasonsText1>
          <NoSeasonsText2>Вы сможете создать перечень операций для каждой культуры</NoSeasonsText2>
        </NoSeasonsPlaceHolderTextBlock>
      </NoSeasonsPlaceHolder>
    );
  }
);
