import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Input } from '@farmlink/farmik-ui';

import { IStoAttributeOdzGroupPut as IOdzGroupPut } from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreController as Controller } from '../../../../../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../../../../../../../hooks';
import { StoAttributeCoreStore as Store } from '../../../../../../mobx/stores';
import { StoStore } from '../../../../../../../../../../../../../../../../mobx';
import { EChecklistAttributeType as EAttributeType } from '../../../../../../../../../../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

import Styled from './StoAttributeNumericOdz.styles';
import { StoAttributeOdzInput as OdzInput } from './components';

const checkIfValueIsEmpty = (value: string | number): boolean => {
  return value === '' || value === undefined || value === null;
};

const getTarget = (precision: number): number => {
  const targetList: string[] = [];

  for (let i = 1; i < precision; i++) {
    targetList.push('0');
  }

  return Number(`0.${targetList.join('')}1`);
};

const formatDoubleNumber = (value: number, precision: number): string => {
  return value.toFixed(precision);
};

const formatOutputFrom = (value: string | number): string => {
  return `от ${value}`;
};

const formatOutputTo = (value: string | number): string => {
  return `до ${value}`;
};

const StoAttributeNumericOdz: FC<IOdzGroupPut> = ({ id, odzCriteria: { numericValues } }) => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);

  const controller = useStore(Controller);

  const params = useStoParams();

  const errorList = store.getOdzErrorList(id);

  const { precision, type } = store.attribute.attribute;

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const calculateIntegerFrom = (): string | number => {
    if (!checkIfValueIsEmpty(numericValues.yellowLow)) {
      return formatOutputFrom(Number(numericValues.yellowLow) + 1);
    }

    if (!checkIfValueIsEmpty(numericValues.redLow)) {
      return formatOutputFrom(Number(numericValues.redLow) + 1);
    }

    return '';
  };

  const calculateDoubleFrom = (): string | number => {
    const sum = !precision || precision === 0 ? 1 : getTarget(precision);

    if (!checkIfValueIsEmpty(numericValues.yellowLow)) {
      const result = Number(numericValues.yellowLow) + sum;

      return formatOutputFrom(formatDoubleNumber(result, precision ?? 0));
    }

    if (!checkIfValueIsEmpty(numericValues.redLow)) {
      const result = Number(numericValues.redLow) + sum;

      return formatOutputFrom(formatDoubleNumber(result, precision ?? 0));
    }

    return '';
  };

  const calculateFrom = (): string | number => {
    if (errorList.length) return '';

    switch (type) {
      case EAttributeType.Double:
        return calculateDoubleFrom();

      case EAttributeType.Int:
        return calculateIntegerFrom();

      default:
        return '';
    }
  };

  const calculateIntegerTo = (): string | number => {
    if (!checkIfValueIsEmpty(numericValues.yellowHigh)) {
      return formatOutputTo(Number(numericValues.yellowHigh) - 1);
    }

    if (!checkIfValueIsEmpty(numericValues.redHigh)) {
      return formatOutputTo(Number(numericValues.redHigh) - 1);
    }

    return '';
  };

  const calculateDoubleTo = (): string | number => {
    const subtract = !precision || precision === 0 ? 1 : getTarget(precision);

    if (!checkIfValueIsEmpty(numericValues.yellowHigh)) {
      const result = Number(numericValues.yellowHigh) - subtract;

      return formatOutputTo(formatDoubleNumber(result, precision ?? 0));
    }

    if (!checkIfValueIsEmpty(numericValues.redHigh)) {
      const result = Number(numericValues.redHigh) - subtract;

      return formatOutputTo(formatDoubleNumber(result, precision ?? 0));
    }

    return '';
  };

  const calculateTo = (): string | number => {
    if (errorList.length) return '';

    switch (type) {
      case EAttributeType.Double:
        return calculateDoubleTo();

      case EAttributeType.Int:
        return calculateIntegerTo();

      default:
        return '';
    }
  };

  const handleChangeRedLow = useCallback((enteredValue: string): void => {
    controller.changeOdzNumericValue(params, id, {
      redLow: enteredValue,
    });
  }, []);

  const handleChangeYellowLow = useCallback((enteredValue: string): void => {
    controller.changeOdzNumericValue(params, id, {
      yellowLow: enteredValue,
    });
  }, []);

  const handleChangeYellowHigh = useCallback((enteredValue: string): void => {
    controller.changeOdzNumericValue(params, id, {
      yellowHigh: enteredValue,
    });
  }, []);

  const handleChangeRedHigh = useCallback((enteredValue: string): void => {
    controller.changeOdzNumericValue(params, id, {
      redHigh: enteredValue,
    });
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Body>
        <Styled.Container>
          <OdzInput
            isBlocked={checkIfIsBlockedByPermissions()}
            numericType={type}
            precision={precision}
            label={'Критическое значение'}
            placeholder={'менее'}
            value={numericValues.redLow}
            onChange={handleChangeRedLow}
          />

          <Styled.LineWrapper>
            <Styled.Line $color={'red'} />
          </Styled.LineWrapper>
        </Styled.Container>

        <Styled.Container>
          <OdzInput
            isBlocked={checkIfIsBlockedByPermissions()}
            numericType={type}
            precision={precision}
            label={'Допустимое значение'}
            placeholder={'менее'}
            value={numericValues.yellowLow}
            onChange={handleChangeYellowLow}
          />

          <Styled.LineWrapper>
            <Styled.Line $color={'yellow'} />
          </Styled.LineWrapper>
        </Styled.Container>

        <Styled.Container $isCentral>
          <Styled.CentralWrapper>
            <Input
              blocked
              label={'Норма'}
              tooltip={'Норма рассчитывается автоматически'}
              placeholder={'от'}
              value={calculateFrom()}
            />

            <Input
              blocked
              label={'Норма'}
              tooltip={'Норма рассчитывается автоматически'}
              placeholder={'до'}
              value={calculateTo()}
            />
          </Styled.CentralWrapper>

          <Styled.LineWrapper>
            <Styled.Line $color={'green'} />
          </Styled.LineWrapper>
        </Styled.Container>

        <Styled.Container>
          <OdzInput
            isBlocked={checkIfIsBlockedByPermissions()}
            numericType={type}
            precision={precision}
            label={'Допустимое значение'}
            placeholder={'более'}
            value={numericValues.yellowHigh}
            onChange={handleChangeYellowHigh}
          />

          <Styled.LineWrapper>
            <Styled.Line $color={'yellow'} />
          </Styled.LineWrapper>
        </Styled.Container>

        <Styled.Container>
          <OdzInput
            isBlocked={checkIfIsBlockedByPermissions()}
            numericType={type}
            precision={precision}
            label={'Критическое значение'}
            placeholder={'более'}
            value={numericValues.redHigh}
            onChange={handleChangeRedHigh}
          />

          <Styled.LineWrapper>
            <Styled.Line $color={'red'} />
          </Styled.LineWrapper>
        </Styled.Container>
      </Styled.Body>

      {errorList.length ? (
        <Styled.Errors>
          {errorList.map(error => (
            <Styled.Error key={error}>{error}</Styled.Error>
          ))}
        </Styled.Errors>
      ) : null}
    </Styled.Wrapper>
  );
};

StoAttributeNumericOdz.displayName = 'StoAttributeNumericOdz';

export default observer(StoAttributeNumericOdz);
