import React, { FC } from 'react';

import { TContainerHeaderDefault as THeaderDefault } from '../../types';
import { useDataTestIdV2 } from '../../../../../../../utils/hooks/locators';
import { ContainerTitle as Title } from '../../../ContainerTitle';

import Styled from './ContainerHeaderDefault.styles';

interface IProps {
  dataTestId: string;
  header: THeaderDefault;
}

const ContainerHeaderDefault: FC<IProps> = ({ dataTestId, header }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
        <Title title={header.title} />

        {header?.additionalContent}
      </Styled.TitleWrapper>

      {header?.additionalBottomContent}
    </Styled.Wrapper>
  );
};

ContainerHeaderDefault.displayName = 'ContainerHeaderDefault';

export default ContainerHeaderDefault;
