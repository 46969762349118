import {
  FieldInExpFilter,
  FieldAreaFilter,
  FieldNameFilter,
  FieldCultureFilter,
  SearchByNameFilter,
} from '../utils';

export type TFiltersTuple = [
  FieldNameFilter,
  FieldAreaFilter,
  FieldInExpFilter,
  SearchByNameFilter,
  FieldCultureFilter
];

export enum EFieldsListSort {
  NAME_ASC,
  NAME_DESC,
  AREA_ASC,
  AREA_DESC,
  NEW,
}

export interface IMultiListFilterOption<M = unknown> {
  value: string;
  label: string;
  selected: boolean;
  disabled: boolean;
  meta?: M;
}
