import { IChecklistsFormulaResult as IFormulaResult } from '../../models';

const NOT_EDITED_ARGUMENTS: IFormulaResult = {
  value: null,
  isNotEdited: true,
};

const CALC_ERROR: IFormulaResult = {
  value: null,
  hasCalculationError: true,
};

const EMPTY_VALUES: IFormulaResult = {
  value: null,
};

const CHECKLISTS_FORMULAS_CALCULATION_RESULTS = {
  NOT_EDITED_ARGUMENTS,
  CALC_ERROR,
  EMPTY_VALUES,
};

export default CHECKLISTS_FORMULAS_CALCULATION_RESULTS;
