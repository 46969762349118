import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizesObj } from '../../../../../../../../shared/constans/sizes';

const Wrapper = styled('div')({
  padding: ESpaceSize['4XL'],
});

const Title = styled('h3')({
  margin: 0,
  marginBottom: ESpaceSize.M,
  ...FontSizesObj.xlargeBold,
});

const Description = styled('p')({
  ...FontSizesObj.medium,
});

const Divider = styled('div')({
  width: '100%',
  height: '1px',
  backgroundColor: Colors.technicalBorderGrey,

  marginTop: ESpaceSize.M,
  marginBottom: ESpaceSize.M,
});

const ModalFooterContainer = styled('div')({
  marginTop: ESpaceSize['2XL'],
});

const Styles = {
  Wrapper,
  Title,
  Description,
  Divider,
  ModalFooterContainer,
};

export default Styles;
