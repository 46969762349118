import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Input } from '@farmlink/farmik-ui';

import {
  formatEnteredDoubleValue,
  formatEnteredIntValue,
} from '../../../../../../../../../../../../../../../../../../../../../../../shared/utils/helpers/formatEnteredValue';
import { EChecklistAttributeType as EAttributeType } from '../../../../../../../../../../../../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

const checkIfValueIsEmpty = (value: string | number): boolean => {
  return value === '' || value === undefined || value === null;
};

const formatInputLess = (value: string): string => {
  const [, target] = value.split('менее ');

  return target;
};

const formatInputMore = (value: string): string => {
  const [, target] = value.split('более ');

  return target;
};

const formatInputFrom = (value: string): string => {
  const [, target] = value.split('от ');

  return target;
};

const formatInputTo = (value: string): string => {
  const [, target] = value.split('до ');

  return target;
};

const formatOutputLess = (value: string | number): string => {
  if (checkIfValueIsEmpty(value)) return '';

  return `менее ${value}`;
};

const formatOutputMore = (value: string | number): string => {
  if (checkIfValueIsEmpty(value)) return '';

  return `более ${value}`;
};

const formatOutputFrom = (value: string | number): string => {
  if (checkIfValueIsEmpty(value)) return '';

  return `от ${value}`;
};

const formatOutputTo = (value: string | number): string => {
  if (checkIfValueIsEmpty(value)) return '';

  return `до ${value}`;
};

interface IProps {
  numericType: EAttributeType;
  placeholder: string;
  value: string | number;
  onChange: (value: string | number) => void;
  label?: string;
  isBlocked?: boolean;
  precision?: number;
}

const StoAttributeOdzInput: FC<IProps> = ({
  numericType,
  label,
  placeholder,
  value,
  onChange,
  isBlocked,
  precision,
}) => {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [enteredValue, setEnteredValue] = useState<string | number>(value);

  useEffect(() => {
    if (isInputFocused) {
      setEnteredValue(value);
      return;
    }

    switch (placeholder) {
      case 'менее':
        setEnteredValue(formatOutputLess(value));
        break;

      case 'более':
        setEnteredValue(formatOutputMore(value));
        break;

      case 'от':
        setEnteredValue(formatOutputFrom(value));
        break;

      case 'до':
        setEnteredValue(formatOutputTo(value));
        break;

      default:
    }
  }, [value]);

  const debounceOnChange = useRef(
    _.debounce((newValue: string | number): void => {
      onChange(newValue);
    }, 500)
  );

  const handleInputChange = useCallback(
    (newValue: string): void => {
      switch (numericType) {
        case EAttributeType.Int: {
          const formattedValue = formatEnteredIntValue(newValue, { allowNegativeNumber: true });

          if (!_.isUndefined(formattedValue)) {
            setEnteredValue(formattedValue);
            debounceOnChange.current(formattedValue);
          }

          break;
        }

        case EAttributeType.Double: {
          const validatedValue = formatEnteredDoubleValue(newValue, {
            allowNegativeNumber: true,
          });

          if (!_.isUndefined(validatedValue)) {
            const isFractional = newValue.includes('.');

            if (isFractional) {
              const [intPart, fractPart] = newValue.split('.');

              if (fractPart.length > precision) {
                const formattedNumber = `${intPart}.${fractPart.slice(0, precision)}`;

                setEnteredValue(formattedNumber);
                debounceOnChange.current(formattedNumber);
              } else {
                setEnteredValue(newValue);
                debounceOnChange.current(newValue);
              }
            } else {
              setEnteredValue(newValue);
              debounceOnChange.current(newValue);
            }
          }

          break;
        }

        default:
      }
    },
    [numericType]
  );

  const handleFocus = (): void => {
    setIsInputFocused(true);

    switch (placeholder) {
      case 'менее': {
        const output = formatOutputLess(value);
        const input = formatInputLess(output);

        setEnteredValue(input);
        break;
      }

      case 'более': {
        const output = formatOutputMore(value);
        const input = formatInputMore(output);

        setEnteredValue(input);
        break;
      }

      case 'от': {
        const output = formatOutputFrom(value);
        const input = formatInputFrom(output);

        setEnteredValue(input);
        break;
      }

      case 'до': {
        const output = formatOutputTo(value);
        const input = formatInputTo(output);

        setEnteredValue(input);
        break;
      }

      default:
    }
  };

  const handleBlur = (): void => {
    setIsInputFocused(false);

    switch (placeholder) {
      case 'менее':
        setEnteredValue(formatOutputLess(value));
        break;

      case 'более':
        setEnteredValue(formatOutputMore(value));
        break;

      case 'от':
        setEnteredValue(formatOutputFrom(value));
        break;

      case 'до':
        setEnteredValue(formatOutputTo(value));
        break;

      default:
    }
  };

  return (
    <Input
      blocked={isBlocked}
      label={label}
      placeholder={placeholder}
      value={enteredValue}
      onChange={handleInputChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

StoAttributeOdzInput.displayName = 'StoAttributeOdzInput';

export default memo(StoAttributeOdzInput);
