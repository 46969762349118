import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Input, Typography } from '@farmlink/farmik-ui';
import _ from 'lodash';

import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { ButtonRemove } from '../../../../../../../../../shared/features/UI/new/ButtonRemove';
import { TStoDownloadReportModalAuthorType as TAuthorType } from '../../types';

import Styled from './StoDownloadReportEmployee.styles';

interface IProps {
  dataTestId: string;
  id: string;
  type: TAuthorType;
  name: string;
  position: string;
  isShowRemoveButton: boolean;
  onNameChange: (type: TAuthorType, id: string, v: string) => void;
  onPositionChange: (type: TAuthorType, id: string, v: string) => void;
  onRemoveClick: (type: TAuthorType, id: string) => void;
}

const StoDownloadReportEmployee: FC<IProps> = ({
  dataTestId,
  id,
  type,
  name,
  position,
  isShowRemoveButton,
  onNameChange,
  onPositionChange,
  onRemoveClick,
}) => {
  const [enteredName, setEnteredName] = useState<string>('');
  const [enteredPosition, setEnteredPosition] = useState<string>('');

  useEffect(() => {
    setEnteredName(name);
  }, [name]);

  useEffect(() => {
    setEnteredPosition(position);
  }, [position]);

  const debounceNameChange = useRef(
    _.debounce((v: string): void => {
      onNameChange(type, id, v);
    }, 500)
  );

  const debouncePositionChange = useRef(
    _.debounce((v: string): void => {
      onPositionChange(type, id, v);
    }, 500)
  );

  const handleNameChange = useCallback((v: string) => {
    setEnteredName(v);
    debounceNameChange.current(v);
  }, []);

  const handlePositionChange = useCallback((v: string) => {
    setEnteredPosition(v);
    debouncePositionChange.current(v);
  }, []);

  const getLocator = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getLocator()}>
      <Styled.Header {...getLocator('header')}>
        <Typography dataTestId={getLocator('title')['data-test-id']} variant={'bodySubAccent'}>
          {type === 'developer' ? 'Разработал' : 'Согласовал'}
        </Typography>

        {isShowRemoveButton ? (
          <ButtonRemove
            dataTestId={getLocator()['data-test-id']}
            onClick={() => onRemoveClick(type, id)}
          />
        ) : null}
      </Styled.Header>

      <Styled.Body {...getLocator('body')}>
        <Input
          dataTestId={getLocator('name')['data-test-id']}
          label={'Фамилия, имя, отчество'}
          maxLength={256}
          value={enteredName}
          onChange={handleNameChange}
        />

        <Input
          dataTestId={getLocator('position')['data-test-id']}
          label={'Должность'}
          maxLength={256}
          value={enteredPosition}
          onChange={handlePositionChange}
        />
      </Styled.Body>
    </Styled.Wrapper>
  );
};

StoDownloadReportEmployee.displayName = 'StoDownloadReportEmployee';

export default memo(StoDownloadReportEmployee);
