import { FC, memo, useMemo } from 'react';


import { OperationTaskTypeIcon } from '../../../../../../../../shared/components/OperationTaskTypeIcon';
import { ETaskType } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  taskType: ETaskType;
  dataTestId: string;
}
const TasksDrawerTaskType: FC<IProps> = ({ taskType , dataTestId}) => {

  const getDataTestId = useDataTestIdV2(`${dataTestId}__task-type`);

  const taskTypeInfo = useMemo(() => {
    return taskType ? 'Регистрация' : 'Plug';
  }, [taskType]);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label {...getDataTestId('label')}>Тип задачи</Styled.Label>

      <OperationTaskTypeIcon
        taskType={taskType}
        dataTestId={getDataTestId()['data-test-id']}
        withTooltip={false}
        $iconSize={'32px'}
        $margin={'0'}
      />

      <Styled.Info {...getDataTestId('info')}>
        {taskTypeInfo}
      </Styled.Info>
    </Styled.InfoBlock>
  );
};

TasksDrawerTaskType.displayName = 'TasksDrawerTaskType';

export default memo(TasksDrawerTaskType);
