import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('section')({
  display: 'flex',
  flexDirection: 'column',
});

const InputContainer = styled('div')({
  marginBottom: ESpaceSize.M,
});

const Styles = {
  Wrapper,
  InputContainer,
};

export default Styles;
