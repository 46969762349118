import { v4 } from 'uuid';

import {
  lazyInject,
  provide,
} from '../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../stores';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../../../../../../mobx/stores';
import {
  IStoAttribute as IAttribute,
  IStoAttributeOdzCriteria as IOdzCriteria,
  IStoAttributeOdzCriteriaPut as IOdzCriteriaPut,
  IStoAttributeOdzDependency as IOdzDependency,
  IStoAttributeOdzDependencyPut as IDependencyPut,
  IStoAttributeOdzDictionaryValue,
  IStoAttributeOdzGroup as IOdzGroup,
  IStoAttributeOdzGroupPut as IOdzGroupPut,
  IStoAttributeStoOdzFormType as OdzFormType,
  IStoAttributeStoOdzFormTypePut,
  IStoAttributeStoOdzFormTypePut as OdzFormTypePut,
} from '../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { EChecklistAttributeType as EAttributeType } from '../../../../../../../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { IStoParams } from '../../../../../../../../../../../../../../../models';
import { EStoOdzCommonError, EStoOdzNumericError } from '../../../constants';
import { IStoAttributeOdzDependencySelect as IDependencySelect } from '../../../interfaces';

const getTarget = (precision: number): number => {
  const targetList: string[] = [];

  for (let i = 1; i < precision; i++) {
    targetList.push('0');
  }

  return Number(`0.${targetList.join('')}1`);
};

@provide.transient()
class StoAttributeOdzService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  public get isNumericOdz(): boolean {
    return this.checkIfIsNumericOdz(this.store.attribute.attribute.type);
  }

  public get isDictionaryOdz(): boolean {
    return this.checkIfIsDictionaryOdz(this.store.attribute.attribute.type);
  }

  public get isNeedOdz(): boolean {
    return this.isNumericOdz || this.isDictionaryOdz;
  }

  public initiateOdz = (): OdzFormTypePut => {
    return this.formatOdzFormTypePut(this.store.attribute.stoODZFormType);
  };

  public initiateDependencySelects = (odzFormTypePut: OdzFormTypePut): void => {
    if (!odzFormTypePut?.izODZEnabled || !odzFormTypePut?.isComplexODZ) return;

    this.addDependencySelects(odzFormTypePut.odzGroups);
  };

  public enableOdz = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): OdzFormTypePut => {
    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    if (this.isNumericOdz) {
      return {
        ...stoODZFormType,
        isComplexODZ: false,
        izODZEnabled: true,
        odzGroups: [this.createInitialNumericGroup()],
      };
    }

    if (this.isDictionaryOdz) {
      return {
        ...stoODZFormType,
        isComplexODZ: false,
        izODZEnabled: true,
        odzGroups: [this.createInitialDictionaryGroup()],
      };
    }
  };

  public disableOdz = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): OdzFormTypePut => {
    this.clearAllGroupsErrors();

    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    if (this.isNumericOdz) {
      return {
        ...stoODZFormType,
        izODZEnabled: false,
        odzGroups: [],
      };
    }

    if (this.isDictionaryOdz) {
      return {
        ...stoODZFormType,
        izODZEnabled: false,
        odzGroups: [],
      };
    }
  };

  public enableOdzDependency = (): OdzFormTypePut => {
    this.clearAllGroupsErrors();

    this.addDependencySelect();

    return {
      izODZEnabled: true,
      isComplexODZ: true,
      odzGroups: [],
    };
  };

  public addDependencySelect = (): void => {
    this.store.setDependencySelectList(prev => {
      return [...prev, { id: v4(), attr: null }];
    });
  };

  public removeDependencySelect = (id: string): void => {
    this.store.setDependencySelectList(prev => {
      return prev.filter(select => select.id !== id);
    });
  };

  public disableOdzDependency = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): OdzFormTypePut => {
    this.clearAllGroupsErrors();
    this.store.clearDependencySelectList();

    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    if (this.isNumericOdz) {
      return {
        ...stoODZFormType,
        isComplexODZ: false,
        odzGroups: [this.createInitialNumericGroup()],
      };
    }

    if (this.isDictionaryOdz) {
      return {
        ...stoODZFormType,
        isComplexODZ: false,
        odzGroups: [this.createInitialDictionaryGroup()],
      };
    }
  };

  public initiateGroups = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): OdzFormTypePut => {
    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    this.store.setHasNoSelectedDependency(false);
    this.clearAllGroupsErrors();

    const hasNotSelectedDependency = this.store.dependencySelectList.some(({ attr }) => !attr);

    if (hasNotSelectedDependency) {
      return {
        ...stoODZFormType,
        odzGroups: [],
      };
    }

    if (this.isNumericOdz) {
      return {
        ...stoODZFormType,
        odzGroups: [this.createInitialNumericGroup(true)],
      };
    }

    if (this.isDictionaryOdz) {
      return {
        ...stoODZFormType,
        odzGroups: [this.createInitialDictionaryGroup(true)],
      };
    }
  };

  public changeDependencySelect = (data: Partial<IDependencySelect>): void => {
    this.store.setDependencySelectList(prev => {
      return prev.map(select => {
        if (select.id !== data.id) return select;

        return {
          ...select,
          ...data,
        };
      });
    });
  };

  public addGroup = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): OdzFormTypePut => {
    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    if (this.isNumericOdz) {
      return {
        ...stoODZFormType,
        odzGroups: [...stoODZFormType.odzGroups, this.createInitialNumericGroup(true)],
      };
    }

    if (this.isDictionaryOdz) {
      return {
        ...stoODZFormType,
        odzGroups: [...stoODZFormType.odzGroups, this.createInitialDictionaryGroup(true)],
      };
    }
  };

  public removeGroup = (
    groupId: string,
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): OdzFormTypePut => {
    this.store.deleteOdzErrorByGroupId(groupId);

    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    const changedData: IStoAttributeStoOdzFormTypePut = {
      ...stoODZFormType,
      odzGroups: stoODZFormType.odzGroups.filter(({ id }) => id !== groupId),
    };

    /**
     * Данная проверка нужна для обновления ошибки пустых значений.
     */
    if (changedData.odzGroups.length !== 1) return changedData;

    changedData.odzGroups.forEach(group => {
      if (!this.store.hasOdzErrorsByGroup(group.id)) return;

      const errorList = this.store.getOdzErrorList(group.id);

      if (!errorList.includes(EStoOdzCommonError.GroupEmpty)) return;

      this.store.deleteOdzError(group.id, EStoOdzCommonError.GroupEmpty);
      this.store.setOdzError(group.id, EStoOdzCommonError.Empty);
    });

    return changedData;
  };

  public changeNumericValue = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>,
    groupId: string,
    value: Partial<IOdzCriteriaPut['numericValues']>
  ): OdzFormTypePut => {
    this.store.deleteOdzError(groupId, EStoOdzCommonError.Empty);
    this.store.deleteOdzError(groupId, EStoOdzCommonError.GroupEmpty);

    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    return {
      ...stoODZFormType,
      odzGroups: stoODZFormType.odzGroups.map<IOdzGroupPut>(group => {
        if (group.id !== groupId) return group;

        const changedGroup: IOdzGroupPut = {
          ...group,
          odzCriteria: {
            ...group.odzCriteria,
            numericValues: {
              ...group.odzCriteria.numericValues,
              ...value,
            },
          },
        };

        this.validateNumericValueRedLow(groupId, changedGroup.odzCriteria.numericValues);
        this.validateNumericValueYellowLow(groupId, changedGroup.odzCriteria.numericValues);
        this.validateNumericValueYellowHigh(groupId, changedGroup.odzCriteria.numericValues);
        this.validateNumericValueRedHigh(groupId, changedGroup.odzCriteria.numericValues);

        return changedGroup;
      }),
    };
  };

  public changeDictionaryValue = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>,
    groupId: string,
    value: Partial<IOdzCriteriaPut['dictionaryValues']>
  ): OdzFormTypePut => {
    this.store.deleteOdzError(groupId, EStoOdzCommonError.Empty);
    this.store.deleteOdzError(groupId, EStoOdzCommonError.GroupEmpty);

    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    return {
      ...stoODZFormType,
      odzGroups: stoODZFormType.odzGroups.map<IOdzGroupPut>(group => {
        if (group.id !== groupId) return group;

        return {
          ...group,
          odzCriteria: {
            ...group.odzCriteria,
            dictionaryValues: {
              ...group.odzCriteria.dictionaryValues,
              ...value,
            },
          },
        };
      }),
    };
  };

  public changeDependencyNumericValue = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>,
    groupId: string,
    dependencyId: string,
    value: Partial<IDependencyPut['numericValues']>
  ): OdzFormTypePut => {
    this.store.clearOdzDepErrorEmptyInGroup(groupId);

    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    return {
      ...stoODZFormType,
      odzGroups: stoODZFormType.odzGroups.map<IOdzGroupPut>(group => {
        if (group.id !== groupId) return group;

        const changedGroup: IOdzGroupPut = {
          ...group,
          dependencyAttributes: group.dependencyAttributes.map<IDependencyPut>(dependency => {
            if (dependency.id !== dependencyId) return dependency;

            return {
              ...dependency,
              numericValues: {
                ...dependency.numericValues,
                ...value,
              },
            };
          }),
        };

        return changedGroup;
      }),
    };
  };

  public changeDependencyDictionaryValue = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>,
    groupId: string,
    dependencyId: string,
    value: IStoAttributeOdzDictionaryValue[]
  ): OdzFormTypePut => {
    this.store.clearOdzDepErrorEmptyInGroup(groupId);

    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    return {
      ...stoODZFormType,
      odzGroups: stoODZFormType.odzGroups.map<IOdzGroupPut>(group => {
        if (group.id !== groupId) return group;

        const changedGroup: IOdzGroupPut = {
          ...group,
          dependencyAttributes: group.dependencyAttributes.map<IDependencyPut>(dependency => {
            if (dependency.id !== dependencyId) return dependency;

            return {
              ...dependency,
              dictionaryValues: value,
            };
          }),
        };

        return changedGroup;
      }),
    };
  };

  protected validateNumericValueRedLow = (
    groupId: string,
    { redLow, yellowLow, yellowHigh, redHigh }: IOdzCriteriaPut['numericValues']
  ): void => {
    if (this.checkIfValueIsEmpty(redLow)) {
      return this.store.deleteOdzError(groupId, EStoOdzNumericError.RedLow);
    }

    const valueList: number[] = [];

    const precision = this.store.attribute.attribute?.precision;

    const sum = !precision || precision === 0 ? 1 : getTarget(precision);
    const numberRedLow = Number(redLow) + sum;

    const isNotEmptyYellowLow = !this.checkIfValueIsEmpty(yellowLow);
    const isNotEmptyYellowHigh = !this.checkIfValueIsEmpty(yellowHigh);
    const isNotEmptyRedHigh = !this.checkIfValueIsEmpty(redHigh);

    if (isNotEmptyYellowLow) valueList.push(Number(yellowLow));
    if (isNotEmptyYellowHigh) valueList.push(Number(yellowHigh));
    if (isNotEmptyRedHigh) valueList.push(Number(redHigh));

    const isTooBigValue = valueList.some(v => numberRedLow >= v);
    const isAlreadyAddedThisError = this.store
      .getOdzErrorList(groupId)
      .find(e => e === EStoOdzNumericError.RedLow);

    if (isTooBigValue) {
      if (!isAlreadyAddedThisError) this.store.setOdzError(groupId, EStoOdzNumericError.RedLow);
    } else {
      this.store.deleteOdzError(groupId, EStoOdzNumericError.RedLow);
    }
  };

  protected validateNumericValueYellowLow = (
    groupId: string,
    { yellowLow, yellowHigh }: IOdzCriteriaPut['numericValues']
  ): void => {
    if (this.checkIfValueIsEmpty(yellowLow)) {
      return this.store.deleteOdzError(groupId, EStoOdzNumericError.YellowBoth);
    }

    const valueList: number[] = [];

    const precision = this.store.attribute.attribute?.precision;

    const sum = !precision || precision === 0 ? 1 : getTarget(precision);
    const numberYellowLow = Number(yellowLow) + sum;

    const isNotEmptyYellowHigh = !this.checkIfValueIsEmpty(yellowHigh);

    if (isNotEmptyYellowHigh) valueList.push(Number(yellowHigh));

    const isTooBigValue = valueList.some(v => numberYellowLow >= v);
    const isAlreadyAddedThisError = this.store
      .getOdzErrorList(groupId)
      .find(e => e === EStoOdzNumericError.YellowBoth);

    if (isTooBigValue) {
      if (!isAlreadyAddedThisError) this.store.setOdzError(groupId, EStoOdzNumericError.YellowBoth);
    } else {
      this.store.deleteOdzError(groupId, EStoOdzNumericError.YellowBoth);
    }
  };

  protected validateNumericValueYellowHigh = (
    groupId: string,
    { yellowLow, yellowHigh }: IOdzCriteriaPut['numericValues']
  ): void => {
    if (this.checkIfValueIsEmpty(yellowHigh)) {
      return this.store.deleteOdzError(groupId, EStoOdzNumericError.YellowBoth);
    }

    const valueList: number[] = [];

    const precision = this.store.attribute.attribute?.precision;

    const sum = !precision || precision === 0 ? 1 : getTarget(precision);
    const numberYellowHigh = Number(yellowHigh) - sum;

    const isNotEmptyYellowLow = !this.checkIfValueIsEmpty(yellowLow);

    if (isNotEmptyYellowLow) valueList.push(Number(yellowLow));

    const isTooSmallValue = valueList.some(v => numberYellowHigh <= v);
    const isAlreadyAddedThisError = this.store
      .getOdzErrorList(groupId)
      .find(e => e === EStoOdzNumericError.YellowBoth);

    if (isTooSmallValue) {
      if (!isAlreadyAddedThisError) this.store.setOdzError(groupId, EStoOdzNumericError.YellowBoth);
    } else {
      this.store.deleteOdzError(groupId, EStoOdzNumericError.YellowBoth);
    }
  };

  protected validateNumericValueRedHigh = (
    groupId: string,
    { redLow, yellowLow, yellowHigh, redHigh }: IOdzCriteriaPut['numericValues']
  ): void => {
    if (this.checkIfValueIsEmpty(redHigh)) {
      return this.store.deleteOdzError(groupId, EStoOdzNumericError.RedHigh);
    }

    const valueList: number[] = [];

    const precision = this.store.attribute.attribute?.precision;

    const sum = !precision || precision === 0 ? 1 : getTarget(precision);
    const numberRedHigh = Number(redHigh) - sum;

    const isNotEmptyRedLow = !this.checkIfValueIsEmpty(redLow);
    const isNotEmptyYellowLow = !this.checkIfValueIsEmpty(yellowLow);
    const isNotEmptyYellowHigh = !this.checkIfValueIsEmpty(yellowHigh);

    if (isNotEmptyRedLow) valueList.push(Number(redLow));
    if (isNotEmptyYellowLow) valueList.push(Number(yellowLow));
    if (isNotEmptyYellowHigh) valueList.push(Number(yellowHigh));

    const isTooSmallValue = valueList.some(v => numberRedHigh <= v);
    const isAlreadyAddedThisError = this.store
      .getOdzErrorList(groupId)
      .find(e => e === EStoOdzNumericError.RedHigh);

    if (isTooSmallValue) {
      if (!isAlreadyAddedThisError) this.store.setOdzError(groupId, EStoOdzNumericError.RedHigh);
    } else {
      this.store.deleteOdzError(groupId, EStoOdzNumericError.RedHigh);
    }
  };

  protected createInitialNumericGroup = (hasDependency?: boolean): IOdzGroupPut => {
    if (hasDependency) {
      return {
        id: v4(),
        dependencyAttributes: this.createInitialDepAttrPutList(),
        odzCriteria: {
          numericValues: { yellowHigh: '', yellowLow: '', redHigh: '', redLow: '' },
        },
      };
    }

    return {
      id: v4(),
      odzCriteria: {
        numericValues: { yellowHigh: '', yellowLow: '', redHigh: '', redLow: '' },
      },
    };
  };

  protected createInitialDictionaryGroup = (hasDependency?: boolean): IOdzGroupPut => {
    if (hasDependency) {
      return {
        id: v4(),
        dependencyAttributes: this.createInitialDepAttrPutList(),
        odzCriteria: {
          dictionaryValues: {
            green: [],
            yellow: [],
            red: [],
          },
        },
      };
    }

    return {
      id: v4(),
      odzCriteria: {
        dictionaryValues: {
          green: [],
          yellow: [],
          red: [],
        },
      },
    };
  };

  protected createInitialDepAttrPutList = (): IDependencyPut[] => {
    const depList = this.store.dependencySelectList.map(({ attr }) => attr);

    return depList.map(this.createInitialDepAttributePut);
  };

  protected createInitialDepAttributePut = (depAttribute: IAttribute): IDependencyPut => {
    if (this.checkIfIsNumericOdz(depAttribute.attribute.type)) {
      return {
        id: depAttribute.id,
        type: depAttribute.attribute.type,
        numericValues: {
          from: null,
          to: null,
        },
        systemData: {
          attribute: depAttribute,
        },
      };
    }

    if (this.checkIfIsDictionaryOdz(depAttribute.attribute.type)) {
      return {
        id: depAttribute.id,
        type: depAttribute.attribute.type,
        dictionaryValues: [],
        systemData: {
          attribute: depAttribute,
          optionList: [],
        },
      };
    }
  };

  protected formatOdzFormTypePut = (odzFormType: OdzFormType): OdzFormTypePut => {
    return {
      isComplexODZ: Boolean(odzFormType?.isComplexODZ),
      izODZEnabled: Boolean(odzFormType?.izODZEnabled),
      odzGroups: this.formatOdzGroupList(odzFormType?.odzGroups ?? []),
    };
  };

  protected addDependencySelects = (groupPutList: IOdzGroupPut[]): void => {
    if (!groupPutList.length) return;

    const [firstGroup] = groupPutList;

    if (!firstGroup?.dependencyAttributes?.length) return;

    const dependencySelectList = firstGroup.dependencyAttributes.map<IDependencySelect>(d => ({
      id: v4(),
      attr: d.systemData.attribute,
    }));

    this.store.setDependencySelectList(dependencySelectList);
  };

  protected formatOdzGroupList = (groupList: IOdzGroup[]): IOdzGroupPut[] => {
    const formattedList = groupList.map(this.formatOdzGroup);

    this.addDependencySelects(formattedList);

    return formattedList;
  };

  protected formatOdzGroup = (group: IOdzGroup): IOdzGroupPut => {
    const id = v4();

    if (this.isNumericOdz) {
      return {
        id,
        dependencyAttributes: this.formatDependencyList(group.dependencyAttributes ?? []),
        odzCriteria: {
          numericValues: this.formatOdzNumericValues(group?.odzCriteria?.numericValues),
        },
      };
    }

    return {
      id,
      dependencyAttributes: this.formatDependencyList(group.dependencyAttributes ?? []),
      odzCriteria: {
        dictionaryValues: this.formatOdzDictionaryValues(group?.odzCriteria?.dictionaryValues),
      },
    };
  };

  protected formatDependencyList = (dependencyList: IOdzDependency[]): IDependencyPut[] => {
    return dependencyList.map(this.formatDependency);
  };

  protected formatDependency = (dependency: IOdzDependency): IDependencyPut => {
    const attribute = this.store.dependencyOptionList.find(o => o.initialModel.id === dependency.id)
      .initialModel;

    if (this.checkIfIsNumericOdz(dependency.type)) {
      return {
        id: dependency.id,
        type: dependency.type,
        numericValues: {
          from: dependency.numericValues?.from ?? null,
          to: dependency.numericValues?.to ?? null,
        },
        systemData: {
          attribute,
        },
      };
    }

    if (this.checkIfIsDictionaryOdz(dependency.type)) {
      return {
        id: dependency.id,
        type: dependency.type,
        dictionaryValues: dependency.dictionaryValues ?? [],
        systemData: {
          attribute,
        },
      };
    }
  };

  protected formatOdzNumericValues = (
    numericValues: IOdzCriteria['numericValues']
  ): IOdzCriteriaPut['numericValues'] => {
    return {
      redLow: numericValues?.redLow ?? null,
      redHigh: numericValues?.redHigh ?? null,
      yellowLow: numericValues?.yellowLow ?? null,
      yellowHigh: numericValues?.yellowHigh ?? null,
    };
  };

  protected formatOdzDictionaryValues = (
    dictionaryValues: IOdzCriteria['dictionaryValues']
  ): IOdzCriteriaPut['dictionaryValues'] => {
    return {
      red: dictionaryValues?.red ?? [],
      yellow: dictionaryValues?.yellow ?? [],
      green: dictionaryValues?.green ?? [],
    };
  };

  public formatOdzFormTypePutForSave = (odzFormTypePut: OdzFormTypePut): OdzFormTypePut => {
    return {
      isComplexODZ: Boolean(odzFormTypePut?.isComplexODZ),
      izODZEnabled: Boolean(odzFormTypePut?.izODZEnabled),
      odzGroups: this.formatOdzGroupListForSave(odzFormTypePut?.odzGroups ?? []),
    };
  };

  protected formatOdzGroupListForSave = (groupPutList: IOdzGroupPut[]): IOdzGroupPut[] => {
    return groupPutList.map(this.formatOdzGroupForSave);
  };

  protected formatOdzGroupForSave = (groupPut: IOdzGroupPut): IOdzGroupPut => {
    return {
      dependencyAttributes: this.formatDependencyListForSave(groupPut?.dependencyAttributes ?? []),
      odzCriteria: {
        numericValues: this.formatOdzNumericValuesForSave(groupPut?.odzCriteria?.numericValues),
        dictionaryValues: this.formatOdzDictionaryValuesForSave(
          groupPut?.odzCriteria?.dictionaryValues
        ),
      },
    };
  };

  protected formatDependencyListForSave = (dependencyList: IDependencyPut[]): IDependencyPut[] => {
    return dependencyList.map(dependency => {
      return {
        id: dependency.id,
        type: dependency.type,
        numericValues: {
          from: !this.checkIfValueIsEmpty(dependency?.numericValues?.from)
            ? Number(dependency?.numericValues?.from)
            : null,
          to: !this.checkIfValueIsEmpty(dependency?.numericValues?.to)
            ? Number(dependency?.numericValues?.to)
            : null,
        },
        dictionaryValues: dependency.dictionaryValues ?? [],
      };
    });
  };

  protected formatOdzNumericValuesForSave = (
    numericValues: IOdzCriteriaPut['numericValues']
  ): IOdzCriteriaPut['numericValues'] => {
    return {
      redLow: !this.checkIfValueIsEmpty(numericValues?.redLow)
        ? Number(numericValues?.redLow)
        : null,
      redHigh: !this.checkIfValueIsEmpty(numericValues?.redHigh)
        ? Number(numericValues?.redHigh)
        : null,
      yellowLow: !this.checkIfValueIsEmpty(numericValues?.yellowLow)
        ? Number(numericValues?.yellowLow)
        : null,
      yellowHigh: !this.checkIfValueIsEmpty(numericValues?.yellowHigh)
        ? Number(numericValues?.yellowHigh)
        : null,
    };
  };

  protected formatOdzDictionaryValuesForSave = (
    dictionaryValues: IOdzCriteriaPut['dictionaryValues']
  ): IOdzCriteriaPut['dictionaryValues'] => {
    return {
      red: dictionaryValues?.red ?? [],
      yellow: dictionaryValues?.yellow ?? [],
      green: dictionaryValues?.green ?? [],
    };
  };

  public checkIfValueIsEmpty = (value: string | number): boolean => {
    return value === '' || value === undefined || value === null;
  };

  protected checkIfIsNumericOdz = (type: EAttributeType): boolean => {
    return type === EAttributeType.Int || type === EAttributeType.Double;
  };

  protected checkIfIsDictionaryOdz = (type: EAttributeType): boolean => {
    return type === EAttributeType.DictionaryLink || type === EAttributeType.Enum;
  };

  protected clearAllGroupsErrors = (): void => {
    this.store.clearOdzErrorListByGroupId();
    this.store.clearOdzDepErrorListByAttrIdByGroupId();
  };
}

export default StoAttributeOdzService;
