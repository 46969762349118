import React, { FC, memo, MouseEvent } from 'react';

import { useDataTestIdV2 } from '../../../utils/hooks/locators';

import Styled from './ButtonAdd.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__button-add.
   */
  dataTestId: string;
  onClick?: () => void;
}

const ButtonAdd: FC<IProps> = ({ dataTestId, onClick }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__button-add`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    event.stopPropagation();

    onClick?.();
  };

  return (
    <Styled.Svg
      onClick={handleClick}
      {...getDataTestId()}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="12" fill="#F3F4F8" />
      <path
        d="M8 16C8 16.8837 8.61632 17 9.5 17H15V22.4C15 23.2837 15.1163 24 16 24C16.8837 24 17 23.2837 17 22.4V17H22.4C23.2837 17 24 16.8837 24 16C24 15.1163 23.2837 15 22.4 15H17V9.6C17 8.71632 16.8837 8 16 8C15.1163 8 15 8.71632 15 9.6V15H9.5C8.5 15 8 15.304 8 16Z"
        fill="#151F32"
      />
    </Styled.Svg>
  );
};

ButtonAdd.displayName = 'ButtonDownload';

export default memo(ButtonAdd);
