import { Checkbox, ENotificationType, Icon, useNotificator } from '@farmlink/farmik-ui';
import { Listbox } from '@headlessui/react/dist';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { FieldsMapCoreStore } from '../../../../../../../dashboard/modules/fields/mobx';
import { EFieldFill } from '../../../../../../../dashboard/constants/FieldFill.enum';
import { ESelectType, SelectWithCustomButton } from '../../../../../../components';
import { useStore } from '../../../../../../utils/IoC';

import IndicesTimeline from './modules/IndicesFillStrategy/components/Timeline/Timeline';
import { LayerFillController as Controller, LayerFillStore as Store } from './mobx';
import { IndicesFillStrategy } from './modules/IndicesFillStrategy/mobx';
import Styled from './LayerFill.styles';

const INDICES_VALUES = [EFieldFill.Ndvi, EFieldFill.Msavi];
const OPTIONS = [
  { label: 'Культура', value: EFieldFill.Cultures },
  { label: 'NDVI', value: EFieldFill.Ndvi },
  { label: 'MSAVI', value: EFieldFill.Msavi },
  { label: 'Без заливки', value: EFieldFill.None },
];

interface IProps {
  skipOptions?: EFieldFill[];
}

const LayerFillControl: FC<IProps> = props => {
  // Сторы
  const store = useStore(Store);

  // !Стор с полями протекает в контрол карты. Надо подумать над тем как убрать его отсюда
  const fieldsMapCoreStore = useStore(FieldsMapCoreStore);

  // Контроллеры
  const controller = useStore(Controller);

  const notificator = useNotificator();

  const showTimeline = store.strategy instanceof IndicesFillStrategy && !store.strategy.isInvalid;

  const getLabelByValue = (value: EFieldFill) => {
    return OPTIONS.find(item => item.value === value)?.label;
  };

  const getOptions = () => {
    return OPTIONS.filter(option => !(props.skipOptions ?? []).includes(option.value));
  };

  const handleNotificationCheckbox = () => {
    store.showNotification = false;
  };

  const setFillStrategy = (value: EFieldFill) => {
    const { selectedField } = fieldsMapCoreStore;

    if (!selectedField && INDICES_VALUES.includes(value) && store.showNotification) {
      const label = getLabelByValue(value);

      notificator.setNotification({
        message: (
          <Styled.NotificationWrapper>
            Выберите поле, чтобы посмотреть {label}
            <div>
              <Checkbox label={'Не показывать'} onChange={handleNotificationCheckbox} />
            </div>
          </Styled.NotificationWrapper>
        ),
        style: {
          type: ENotificationType.Info,
          placement: 'top-right',
        },
      });
    }

    controller.setStrategy(value, selectedField);
  };

  return (
    <Styled.Wrapper hiddenOverflow={showTimeline}>
      <SelectWithCustomButton
        type={ESelectType.SINGLE}
        label={'Заливка'}
        value={store.fillType}
        optionList={getOptions()}
        renderButton={value => (
          <Listbox.Button as={Styled.SmallButton}>
            <Icon icon={'fillLayer'} size={'medium'} />
            {getLabelByValue(value)}
          </Listbox.Button>
        )}
        onChange={setFillStrategy}
      />

      {showTimeline && <IndicesTimeline />}
    </Styled.Wrapper>
  );
};

export default observer(LayerFillControl);
