enum EFieldsMode {
  DISPLAY = 'display',
  CREATE = 'create',
  EDIT = 'edit',
  IMPORT = 'import',
  MULTISELECT = 'MULTISELECT',

  FIELD_SEASONS = 'field_seasons',
}

export default EFieldsMode;
