import IndicesFillStrategy from '../../modules/IndicesFillStrategy/mobx/strategy/IndicesFill.strategy';
import EmptyFillStrategy from '../../modules/EmptyFillStrategy/mobx/strategy/EmptyFillStrategy';
import { EFieldFill } from '../../../../../../../../../dashboard/constants/FieldFill.enum';
import { CulturesFillStrategy } from '../../modules/CulturesFillStrategy/mobx';
import { Field } from '../../../../../../../../../../api/models/field.model';
import { lazyInject, provide } from '../../../../../../../../utils/IoC';
import { MapCoreController } from '../../../../../../modules';
import Store from '../stores/LayerFillStore';

/**
 * Сомнительная реализация стратегий. Возможно есть более красивое решение.
 * Основная проблема в аргументах метода setStrategy().
 * Если нужно будет добавлять новые типы заливки может появиться проблема с пробросом нужных аргументов,
 * т.к для каждой стратегии потенциально свой набор аргументов
 *
 * Можно передавать инстанс стратегии напрямую вместо value и агрументов, но тогда будут проблемы в onChange у селекта, т.к он не знает какие аргументы передавать
 */
@provide.transient()
class LayerFillController {
  @lazyInject(Store)
  private store: Store;

  @lazyInject(MapCoreController)
  private mapCoreController: MapCoreController;

  /**
   * Устанавливает стратегию заливки поля.
   * Перед установкой выполняет сброс предыдущей стратегии.
   *
   * @param value - стратегия заливки. Если передать 'last' то возьмет последнее значение из стора
   * @param field - опционально, поле для заливки. (для Ndvi и Msavi обязательно)
   */
  public async setStrategy(value: EFieldFill | 'last', field?: Field) {
    const fillType = value === 'last' ? this.store.fillType : value;
    const fieldLayer = this.mapCoreController.getMapLayerById(field?.polyId);

    this.store.fillType = fillType;

    if (this.store.strategy) {
      this.store.strategy.reset();
    }

    if (this.isIndexValue(fillType)) {
      this.store.strategy = new IndicesFillStrategy(fillType, field, fieldLayer);
      await this.store.strategy.initialize();

      return;
    }

    if (fillType === EFieldFill.Cultures) {
      this.store.strategy = new CulturesFillStrategy(fieldLayer);
      await this.store.strategy.initialize();

      return;
    }

    if (fillType === EFieldFill.None) {
      this.store.strategy = new EmptyFillStrategy(fieldLayer);
      await this.store.strategy.initialize();
    }
  }

  private isIndexValue(value: EFieldFill): value is EFieldFill.Ndvi | EFieldFill.Msavi {
    return [EFieldFill.Ndvi, EFieldFill.Msavi].includes(value);
  }
}

export default LayerFillController;
