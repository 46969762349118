import React, { FC, memo, MouseEvent } from 'react';

import { useDataTestIdV2 } from '../../../utils/hooks/locators';

import Styled from './ButtonDownload.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__button-download.
   */
  dataTestId: string;
  onClick?: () => void;
}

const ButtonDownload: FC<IProps> = ({ dataTestId, onClick }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__button-download`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    event.stopPropagation();

    onClick?.();
  };

  return (
    <Styled.Svg
      onClick={handleClick}
      {...getDataTestId()}
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="8" fill="#F3F4F8" />
      <path
        d="M12.0037 13.8243C12.1501 13.8243 12.2746 13.7816 12.4211 13.6395L14.8966 11.3158C15.0065 11.2093 15.0724 11.0956 15.0724 10.9463C15.0724 10.655 14.838 10.4489 14.5377 10.4489C14.3986 10.4489 14.2448 10.5058 14.1423 10.6195L13.029 11.7635L12.5383 12.2681L12.5823 11.2022V5.05427C12.5823 4.75582 12.3186 4.5 12.0037 4.5C11.6887 4.5 11.4177 4.75582 11.4177 5.05427V11.2022L11.4617 12.2681L10.971 11.7635L9.85775 10.6195C9.75521 10.5058 9.59408 10.4489 9.45493 10.4489C9.14732 10.4489 8.92761 10.655 8.92761 10.9463C8.92761 11.0956 8.99352 11.2093 9.10338 11.3158L11.5789 13.6395C11.7254 13.7816 11.8499 13.8243 12.0037 13.8243ZM7.79972 18.5H16.2003C17.731 18.5 18.5 17.761 18.5 16.2971V9.20537C18.5 7.74154 17.731 7.00252 16.2003 7.00252H14.1569V8.14658H16.1783C16.9034 8.14658 17.3208 8.5303 17.3208 9.26933V16.2332C17.3208 16.9722 16.9034 17.3559 16.1783 17.3559H7.81437C7.08197 17.3559 6.67915 16.9722 6.67915 16.2332V9.26933C6.67915 8.5303 7.08197 8.14658 7.81437 8.14658H9.8431V7.00252H7.79972C6.26901 7.00252 5.5 7.74154 5.5 9.20537V16.2971C5.5 17.761 6.26901 18.5 7.79972 18.5Z"
        fill="#151F32"
      />
    </Styled.Svg>
  );
};

ButtonDownload.displayName = 'ButtonDownload';

export default memo(ButtonDownload);
