import { useEffect } from 'react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../mobx/controllers';
import { EChecklistAttributeType as EAttributeType } from '../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { IChecklistsAttrToDraw } from '../../models';

const useChecklistsAttrVisibility = <AT extends EAttributeType>({
  id,
  groupId,
  initialModel,
  dependentFileAttrId,
}: IChecklistsAttrToDraw<AT>): boolean => {
  const { calculateAttrVisibility, toggleAttrVisibility } = useStore(ChecklistsController);

  const visibilityResult = initialModel.visibility
    ? calculateAttrVisibility(groupId, id)
    : { value: true };

  useEffect(() => {
    toggleAttrVisibility(groupId, id, visibilityResult.value as boolean);

    if (!dependentFileAttrId) return;

    toggleAttrVisibility(groupId, dependentFileAttrId, visibilityResult.value as boolean);
  }, [JSON.stringify(visibilityResult)]);

  return visibilityResult.value;
};

export default useChecklistsAttrVisibility;
