import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { TablePlugBuilder } from '../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import ESTORoute from '../../../../routes/sto.routes';

import Styled from './StoListNoDataPlug.styled';

const StoListNoDataPlug: FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = useCallback(() => {
    navigate(`..${ESTORoute.StoItemCreate}`);
  }, []);

  const getDataTestId = useDataTestIdV2('sto__list__no-data-plug');

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'field' },
        content: {
          title: 'Создайте новый технологический стандарт ',
          description: 'Отслеживайте качество выполнения сельскохозяйственных работ',
        },
        footer: {
          renderButton: () => (
            <Styled.Button
              color={'primary'}
              onClick={handleButtonClick}
              alignment={'center'}
              dataTestId={getDataTestId('create-sto-btn')['data-test-id']}
            >
              Создать СТО
            </Styled.Button>
          ),
        },
      }}
      dataTestId={getDataTestId()['data-test-id']}
    />
  );
};

StoListNoDataPlug.displayName = 'StoListNoDataPlug';

export default observer(StoListNoDataPlug);
