import { useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import {
  EMapControlPlace,
  GoogleSearchMapControl,
  LayerFillMapControl,
  LayerTooltipMapControl,
  LocationMapControl,
  ZoomMapControl,
} from '../../../../../shared/features/map/components/Controls';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import FieldsLayout from '../../FieldsLayout';
import {
  FIELDS_IMPORT_MODALS_UNIQUE_KEY,
  fieldsImportModalConfigList,
} from '../FieldsImport/modals';

import CulturesLegend from './components/CulturesLegend/CulturesLegend';
import LeftPanel from './components/LeftPanel/LeftPanel';
import WeatherSlider from './components/WeatherSlider/WeatherSlider';

import { FieldsViewController as Controller } from './index';

const FieldsViewPage: FC = () => {
  // Сторы
  const fieldsStore = useStore(FieldsStore);
  const seasonsStore = useStore(SeasonsStore);
  const organizationsStore = useStore(OrganizationsStore);

  // Контроллеры
  const controller = useStore(Controller);

  const season = seasonsStore.selectedSeason;
  const orgId = organizationsStore.selectedOrganizationId;

  const modal = useModal();

  useEffect(() => {
    if (!season || !orgId) {
      return;
    }

    void controller.initialize();

    return () => {
      controller.destroy();
    };
  }, [season, orgId]);

  useEffect(() => {
    modal.registerModalList(fieldsImportModalConfigList, FIELDS_IMPORT_MODALS_UNIQUE_KEY);
  }, []);

  return (
    <FieldsLayout
      id={'fields-view-map'}
      leftPanel={<LeftPanel />}
      map={{
        config: {
          controls: [
            { id: 'search', place: EMapControlPlace.TOP, component: <GoogleSearchMapControl /> },
            {
              id: 'tooltip',
              place: EMapControlPlace.TOP,
              component: <LayerTooltipMapControl allFields={fieldsStore.fieldsList} />,
            },
            {
              id: 'fill',
              place: EMapControlPlace.TOP,
              component: <LayerFillMapControl />,
            },

            // Переопределяем стандартную легенду культур
            {
              id: 'culturesLegend',
              place: EMapControlPlace.TOP,
              component: <CulturesLegend />,
              hidden: true,
            },

            { id: 'zoom', place: EMapControlPlace.RIGHT, component: <ZoomMapControl /> },
            { id: 'location', place: EMapControlPlace.RIGHT, component: <LocationMapControl /> },
          ],
        },
      }}
      mapWidgets={[<WeatherSlider />]}
    />
  );
};

export default observer(FieldsViewPage);
