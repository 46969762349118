import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import { BorderRadius, FontSizesObj } from '../../../../../../../../../../../../constans/sizes';

const Wrapper = styled('div')({
  backgroundColor: Colors.generalWhite,
  borderRadius: BorderRadius.default,
  height: 40,
  overflow: 'hidden',
  display: 'flex',
});

const InfoMessage = styled('div')({
  ...FontSizesObj.medium,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  padding: '0 15px',
  color: Colors.darkGrey,

  '& span': {
    margin: '0 8px',
  },
});

const Styles = {
  Wrapper,
  InfoMessage,
};

export default Styles;
