import { TypeApiRoute } from '../../../models/type.api.request';
import { IStoContent } from '../../../models/as-fields/STO/STO.model';

type TRequest = { id: string };

type TResponse = IStoContent;

export const deactivateSto: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/sto/${id}/deactivate`,
  method: 'PUT',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
