import { ModalFooter, TModalButton, useModal } from '@farmlink/farmik-ui';
import React, { FC, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { DialogModalTitle, IconScg } from '../../../../../../../../components/DialogModal/style';
import InfoIcon from '../../../../assets/info.svg';

import Styled from './DeleteFieldModal.styles';

const DeleteFieldModal: FC = observer(() => {
  const [checked, setChecked] = useState<boolean>(false);

  const { closeModal, getModalRuntimeHandlers } = useModal();
  const { onSuccess } = getModalRuntimeHandlers();

  const denyButton = useMemo<TModalButton>(() => {
    return {
      title: 'Нет, отменить',
      handler: () => closeModal(),
    };
  }, []);

  const successButton = useMemo<TModalButton>(() => {
    return {
      title: 'Да, удалить',
      color: 'secondary',
      handler: () => onSuccess(checked),
    };
  }, [checked]);

  return (
    <>
      <IconScg src={InfoIcon} alt="" />

      <DialogModalTitle data-test-id="modal-dialog-title">
        Вы уверены, что хотите удалить поле? Поле будет удалено из текущего сезона
      </DialogModalTitle>

      <Styled.FooterWrapper>
        <ModalFooter denyButton={denyButton} successButton={successButton} />
      </Styled.FooterWrapper>

      <Styled.Checkbox
        onChange={setChecked}
        value={checked}
        label={'Удалить это поле из всех сезонов'}
        dataTestId="dialog-modal-checkbox"
      />
    </>
  );
});

export default DeleteFieldModal;
