import { AxiosError } from 'axios';

import { TChangeImportedFieldReq } from '../../../../../../../../api/endpoints/fields/import/changeImportedField';
import {
  TGetImportedFieldListReq,
  TGetImportedFieldListRes,
} from '../../../../../../../../api/endpoints/fields/import/getImportedFieldList';
import {
  TImportFieldsFromGeojsonReq,
  TImportFieldsFromGeojsonRes,
} from '../../../../../../../../api/endpoints/fields/import/importFieldsFromGeojson';
import {
  TImportFieldsFromShapeReq,
  TImportFieldsFromShapeRes,
} from '../../../../../../../../api/endpoints/fields/import/importFieldsFromShape';
import { Axios } from '../../../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EFieldsImportModalName, EFieldsUploadErrorType } from '../../utils/constants';
import { getImportedFieldsSaveError } from '../../utils/helpers';

@provide.transient()
class ImportFieldsService {
  @lazyInject(Axios)
  private axios: Axios;

  importFields = (
    apiName: string,
    payload: { seasonYear: number; file: FormData; organizationId?: string }
  ): Promise<{
    token: 'string';
    errorType?: EFieldsUploadErrorType;
    errorDescription?: 'string';
  }> => {
    const { seasonYear, file, organizationId } = payload;

    return this.axios.api?.[apiName]?.(payload, {
      omit: ['organizationId', 'seasonYear'],
      query: {
        organizationId,
        seasonYear,
      },
      formData: file,
    });
  };

  importFieldsFromGeojson = async (
    payload: TImportFieldsFromGeojsonReq
  ): Promise<TImportFieldsFromGeojsonRes> => {
    const { seasonYear, file, organizationId } = payload;

    try {
      const response = await this.axios.api.importFieldsFromGeojson(payload, {
        omit: ['organizationId', 'seasonYear'],
        query: {
          organizationId,
          seasonYear,
        },
        formData: file,
      });

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  importFieldsFromShape = async (
    payload: TImportFieldsFromShapeReq
  ): Promise<TImportFieldsFromShapeRes> => {
    const { seasonYear, files, organizationId } = payload;

    try {
      const response = await this.axios.api.importFieldsFromShape(payload, {
        omit: ['organizationId', 'seasonYear'],
        query: {
          organizationId,
          seasonYear,
        },
        formData: files,
      });

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  getImportedFieldList = async (
    payload: TGetImportedFieldListReq
  ): Promise<TGetImportedFieldListRes> => {
    try {
      const response = await this.axios.api.getImportedFieldList(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  deleteImportedFieldById = async (id: string): Promise<EFieldsImportModalName> => {
    try {
      await this.axios.api.deleteImportedFieldById({ id }, { omit: ['id'] });

      return EFieldsImportModalName._Success;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      return getImportedFieldsSaveError(e as AxiosError);
    }
  };

  saveImportedFieldList = (token: string): Promise<EFieldsImportModalName | Array<string>> => {
    return this.axios.api
      .saveImportedFieldList({ token })
      .then(res => res)
      .catch(getImportedFieldsSaveError);
  };

  changeImportedField = async (
    id: string,
    props: TChangeImportedFieldReq
  ): Promise<EFieldsImportModalName> => {
    if (!id) {
      return;
    }

    try {
      await this.axios.api.changeImportedField(props, { omit: ['id'] });

      return EFieldsImportModalName._Success;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      return getImportedFieldsSaveError(e as AxiosError);
    }
  };
}

export default ImportFieldsService;
