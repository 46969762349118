import { ISelectOption } from '../../../../../../types/selectOption';
import { IGetChecklist } from '../../../../../../api/models/checklist/checklist.model';

const createChecklistSelectOption = (checklist: IGetChecklist): ISelectOption => {
  return { label: checklist.name, value: checklist.id, initialModel: checklist };
};

const createChecklistSelectOptionList = (
  checklistList: IGetChecklist[]
): ISelectOption<IGetChecklist>[] => {
  return checklistList.map(createChecklistSelectOption);
};

export { createChecklistSelectOption, createChecklistSelectOptionList };
