import { useModal } from '@farmlink/farmik-ui';
import { useCallback } from 'react';

import { createWarningBeforeLeavingThePage } from '../../configs/modals/global';
import { useBlocker } from '../useBlocker';

type ShowWarningBeforeLeavingThePage = (successHandler: () => void) => void;

interface Actions {
  showWarningBeforeLeavingThePage: ShowWarningBeforeLeavingThePage;
}

const useWarningBeforeLeavingThePage = (
  isBlockActivated?: boolean,
  whiteList?: string[],
  denyHandler?: () => void,
  successHandler?: () => void
): Actions => {
  const { registerModalList, openModalByModalId } = useModal();

  const blocker = useCallback(
    (tx: any) => {
      const handleSuccess = (): void => {
        successHandler?.();
        tx.retry();
      };

      const modalConfig = createWarningBeforeLeavingThePage(handleSuccess, denyHandler);

      registerModalList(modalConfig.configList, modalConfig.key);

      openModalByModalId(modalConfig.name);
    },
    [denyHandler, successHandler]
  );

  useBlocker(blocker, isBlockActivated, whiteList ?? []);

  const showWarningBeforeLeavingThePage = useCallback<ShowWarningBeforeLeavingThePage>(
    _successHandler => {
      const modalConfig = createWarningBeforeLeavingThePage(_successHandler);

      registerModalList(modalConfig.configList, modalConfig.key);

      openModalByModalId(modalConfig.name);
    },
    []
  );

  return {
    showWarningBeforeLeavingThePage,
  };
};

export default useWarningBeforeLeavingThePage;
