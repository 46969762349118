import { intersect } from '@turf/turf';

import { BasePolygon } from '../MapElements';

/**
 * Возврщает список полигонов которые пересекаются с comparableLayer
 */
const getIntersectingPolygons = (
  comparablePolygon: BasePolygon,
  layerList: BasePolygon[]
): BasePolygon[] => {
  const intersectedPolygonsCollection: Map<number, BasePolygon> = new Map<number, BasePolygon>();

  const comparableLayerId = comparablePolygon.id;
  const comparableLayerGeoJSON = comparablePolygon.toTurf();

  layerList.forEach(layer => {
    const layerGeoJSON = layer.toTurf();

    const isExactlyThisLayer = comparableLayerId === layer.id;
    if (isExactlyThisLayer) {
      return;
    }

    const isIntersects = intersect(comparableLayerGeoJSON, layerGeoJSON);
    if (isIntersects) {
      intersectedPolygonsCollection.set(comparableLayerId, comparablePolygon);
      intersectedPolygonsCollection.set(layer.id, layer);
    }
  });

  return Array.from(intersectedPolygonsCollection.values());
};

export default getIntersectingPolygons;
