import { FC, PropsWithChildren } from 'react';
import { CSSProperties } from 'styled-components';

import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './ChecklistsCSSContainer.styles';

type TProps = CSSProperties & { dataTestId?: string };

const ChecklistsCSSContainer: FC<PropsWithChildren<TProps>> = ({
  children,
  dataTestId,
  ...styles
}) => {
  const getDataTestId = useDataTestIdV2(dataTestId ?? 'css-container');

  return (
    <Styled.Wrapper {...getDataTestId()} $styles={styles}>
      {children}
    </Styled.Wrapper>
  );
};

ChecklistsCSSContainer.displayName = 'ChecklistsCSSContainer';

export default ChecklistsCSSContainer;
