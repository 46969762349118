const UseTaskAssigneeName = (
  taskAssigneeFullname: string,
  profileFullname: string,
  organiztionId: string
) => {
  const isMyOrganization = organiztionId === 'my';

  if (isMyOrganization) {
    return profileFullname;
  }

  if (taskAssigneeFullname) {
    return taskAssigneeFullname;
  }

  return 'Не назначено';
};

export default UseTaskAssigneeName;
