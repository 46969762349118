import { Button, Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin: 12px;
  margin-bottom: 24px;
`;

const Title = styled.h4`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
  margin: 0;
  margin-bottom: 12px;
`;

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${Colors.secondaryDarkGray};
  margin: 0;
  margin-bottom: 40px;
  text-align: center;
  max-width: 724px;
`;

const StyledButton = styled(Button)`
  max-width: 240px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  max-height: 40px;
  justify-content: center;
  border-radius: 12px;
`;

const Styled = {
  Wrapper,
  Icon,
  Title,
  Subtitle,
  StyledButton,
};

export default Styled;
