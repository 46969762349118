import { makeObservable, observable } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';

import { Filter, TFilterMemento } from '../../../../../shared/features/FilterManager';
import { Field } from '../../../../../../api/models/field.model';

export interface IMinMaxRange<T = number> {
  min: T;
  max: T;
}

class FieldAreaFilter extends Filter<'area', IMinMaxRange, Field> {
  public minMaxRange: IMinMaxRange;

  constructor() {
    super('area', { min: 0, max: 0 });

    makeObservable(this, { minMaxRange: observable });

    this.minMaxRange = this._defaultValue;
  }

  public get isEmptyValue(): boolean {
    return !this.value.min && !this.value.max;
  }

  public resetValue(skipFiltering?) {
    this.setValue(this._defaultValue, skipFiltering);
  }

  public createMemento(): TFilterMemento {
    const value = cloneDeep(this.value);
    const minMaxRange = cloneDeep(this.minMaxRange);

    return {
      restore: skipFiltering => {
        this.setValue(value, skipFiltering);
        this.minMaxRange = minMaxRange;
      },
    };
  }

  public _getFilterRule(el: Field): boolean {
    const { min, max } = this.value;
    const fieldArea = Number(el.area.toFixed(1));

    return fieldArea >= min && fieldArea <= max;
  }

  public _updateState() {
    const filteredList = this._manager.getFilteredSourceList([this.id]);
    const allAreas = filteredList.map((el: Field) => el.area);

    this.minMaxRange = {
      min: Number(Math.min(...allAreas).toFixed(1)),
      max: Number(Math.max(...allAreas).toFixed(1)),
    };

    return this.minMaxRange;
  }
}

export default FieldAreaFilter;
