import { Layer } from 'leaflet';

import { IFillStrategy } from '../../../../interfaces/FillStrategy.model';

class EmptyFillStrategy implements IFillStrategy {
  constructor(private layer: Layer) {}

  public initialize() {
    this.layer?.openTooltip();

    return Promise.resolve();
  }

  public reset(): void {
    // console.log('reset');
  }
}

export default EmptyFillStrategy;
