import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Item = styled.div<{ $isLast?: boolean }>`
  width: 100%;
  height: 36px;
  margin-top: ${ESpaceSize.M}px;
  padding-right: 4px;
  border-bottom: 1px solid ${({ $isLast }) => ($isLast ? 'transparent' : Colors.borderGray)};
`;

const FieldRowWrapper = styled.div<{ isTransferred?: boolean }>`
  transform: translateY(2px);
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;

  color: ${({ isTransferred }) => (isTransferred ? Colors.generalBlack : Colors.secondaryDarkGray)};
`;

const FieldNameWrapper = styled.div`
  max-width: 720px;
`;

const Styled = {
  Item,
  FieldRowWrapper,
  FieldNameWrapper,
};

export default Styled;
