import React, { FC, memo, MouseEvent } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ButtonUploadS40.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__button-upload.
   */
  dataTestId: string;
  onClick: (event: MouseEvent<HTMLOrSVGElement>) => void;
}

const ButtonUploadS40: FC<IProps> = ({ dataTestId, onClick }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Svg
      {...getDataTestId()}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect width="40" height="40" rx="12" fill="#FAF9FD" />
      <path
        d="M20.0045 9.99995C20.1848 9.99995 20.338 10.0487 20.5183 10.2111L23.5651 12.8667C23.7003 12.9885 23.7814 13.1185 23.7814 13.289C23.7814 13.622 23.493 13.8575 23.1234 13.8575C22.9521 13.8575 22.7628 13.7925 22.6366 13.6626L21.2665 12.3551L20.6625 11.7785L20.7166 12.9966V20.0228C20.7166 20.3639 20.3921 20.6562 20.0045 20.6562C19.6169 20.6562 19.2834 20.3639 19.2834 20.0228V12.9966L19.3375 11.7785L18.7335 12.3551L17.3634 13.6626C17.2372 13.7925 17.0389 13.8575 16.8676 13.8575C16.489 13.8575 16.2186 13.622 16.2186 13.289C16.2186 13.1185 16.2997 12.9885 16.4349 12.8667L19.4817 10.2111C19.662 10.0487 19.8152 9.99995 20.0045 9.99995Z"
        fill="#151F32"
      />
      <path
        d="M14.8304 28.0001H25.1696C27.0535 28.0001 28 27.1555 28 25.4825V17.3777C28 15.7047 27.0535 14.8601 25.1696 14.8601H22.6546V16.1676H25.1425C26.0349 16.1676 26.5487 16.6061 26.5487 17.4507V25.4094C26.5487 26.254 26.0349 26.6926 25.1425 26.6926H14.8485C13.947 26.6926 13.4513 26.254 13.4513 25.4094V17.4507C13.4513 16.6061 13.947 16.1676 14.8485 16.1676H17.3454V14.8601H14.8304C12.9465 14.8601 12 15.7047 12 17.3777V25.4825C12 27.1555 12.9465 28.0001 14.8304 28.0001Z"
        fill="#151F32"
      />
    </Styled.Svg>
  );
};

ButtonUploadS40.displayName = 'ButtonUpload';

export default memo(ButtonUploadS40);
