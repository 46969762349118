import { AutoTooltip, Colors, Icon } from '@farmlink/farmik-ui';
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import MapDrawerStore from '../../../../../shared/features/map/modules/MapDrawer/stores/MapDrawer.store';
import { useDataTestIdV2 } from '../../../../../shared/features/utils/hooks/locators';
import { MapDrawerController } from '../../../../../shared/features/map/modules';
import { ECurrDrawPolygonStatus } from '../../modules/FieldsCreate/types';
import { useMapEvents } from '../../../../../shared/features/map/hooks';
import { useStore } from '../../../../../shared/utils/IoC';
import { FieldsCreateStore } from '../../modules';

import Styled from './DrawControls.styles';

/**
 * Временный контрол для управления рисуемым контуром.
 * В будущих версиях будет частью DrawControlPanel
 */
const MapDrawControls: FC = () => {
  // Сторы
  const mapDrawerStore = useStore(MapDrawerStore);
  const fieldsCreateStore = useStore(FieldsCreateStore);

  // Контроллеры
  const mapDrawerController = useStore(MapDrawerController);

  const [vertexCount, setVertexCount] = useState<number>(0);

  const mapEvents = useMapEvents();
  const getDataTestId = useDataTestIdV2('map-controls');

  const handleRemoveLastCordClick = () => {
    /**
     * geoman вызывает функцию disableDraw() если был удален последний вертекс. Исправляем ситуацию
     * TODO: вынести реализацию в @farmlink/gefim
     */
    if (vertexCount <= 1) {
      mapDrawerController.removeLastVertex();
      mapDrawerController.enableDrawPolygon();

      setVertexCount(0);
    } else {
      mapDrawerController.removeLastVertex();
    }
  };

  const handleRemoveContour = () => {
    mapDrawerController.resetDrawPolygon(false);

    setVertexCount(0);
  };

  useEffect(() => {
    mapEvents.on('draw.polygon.vertex.add', marker => {
      if (!marker.options.draggable) {
        setVertexCount(prev => prev + 1);
      }
    });

    mapEvents.on('draw.polygon.vertex.remove', () => {
      setVertexCount(prev => prev - 1);
    });

    mapEvents.on('draw.polygon.create', () => {
      setVertexCount(0);
    });
  }, []);

  useEffect(() => {
    if (!vertexCount) {
      fieldsCreateStore.drawStatus = ECurrDrawPolygonStatus.FINISHED;
    }
  }, [vertexCount]);

  if (!mapDrawerStore.isDrawEnabled) {
    return null;
  }

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <AutoTooltip content={'Удалить точку'} position={'right'}>
        <Styled.Control
          disabled={!vertexCount}
          onClick={handleRemoveLastCordClick}
          {...getDataTestId('remove-last-point-control')}
        >
          <Icon icon={'removeCoord'} size={'small'} />
        </Styled.Control>
      </AutoTooltip>

      <AutoTooltip content={'Удалить контур'} position={'right'}>
        <Styled.Control
          disabled={!vertexCount}
          $bgColor={Colors.lightPink}
          $bgHoverColor={Colors.lightPinkHover}
          style={{ paddingRight: 2 }}
          onClick={handleRemoveContour}
          {...getDataTestId('remove-polygon-control')}
        >
          <Icon icon={'delete'} size={'13.5'} fill={Colors.accentPink} />
        </Styled.Control>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

export default observer(MapDrawControls);
