import React, { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../utils/hooks/locators';
import { TContainerTitleDefault as TTitleDefault } from '../../types';

import Styled from './ContainerTitleDefault.styles';

interface IProps {
  dataTestId: string;
  title: TTitleDefault;
}

const ContainerTitleDefault: FC<IProps> = ({ dataTestId, title }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <Styled.Title {...getDataTestId('title')}>{title.text}</Styled.Title>

      {title.contextualHelp}
    </Styled.Wrapper>
  );
};

ContainerTitleDefault.displayName = 'ContainerTitleDefault';

export default memo(ContainerTitleDefault);
