import { Colors, Icon, IconButton, Switcher } from '@farmlink/farmik-ui';
import { FC, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoFieldsTabController } from '../../mobx/controllers';
import { StoFieldsTabStore } from '../../mobx/stores';

import Styled from './FieldsTabSearch.styles';

const FieldsTabSearch: FC<{ isBlocked?: boolean }> = ({ isBlocked }) => {
  const { selectedFieldIdSet, czIdToPotentialCultureZones, isFieldsFetchingCompleted } = useStore(
    StoFieldsTabStore
  );
  const controller = useStore(StoFieldsTabController);

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedText] = useDebounce(searchValue, 200);

  const ref = useRef<HTMLInputElement>();

  const selectedFieldNumber = selectedFieldIdSet.size;
  const maxFieldNumber = czIdToPotentialCultureZones.size;
  const isDisplaySearchIconInInput = isSearchActive && searchValue?.length === 0;
  const isDisplaySearchClear = isSearchActive && searchValue?.length > 0;

  useEffect(() => {
    controller.searchFields(debouncedText);
  }, [debouncedText]);

  useEffect(() => {
    if (maxFieldNumber === 0) {
      setIsSelectAll(false);
      return;
    }

    setIsSelectAll(selectedFieldNumber === maxFieldNumber);
  }, [selectedFieldNumber, maxFieldNumber]);

  const isSelectAllBlocked = isBlocked || !isFieldsFetchingCompleted;

  const isSearchBlocked = isBlocked || !isFieldsFetchingCompleted;

  const handleToggleActivitySwitch = () => {
    if (isSelectAllBlocked) return;

    controller.toggleAllFields(!isSelectAll);
    setIsSelectAll(!isSelectAll);
  };

  const handleToggleSearch = () => {
    const state = !isSearchActive;
    setIsSearchActive(state);
    setSearchValue('');

    if (state) {
      setTimeout(() => {
        ref.current.focus();
      }, 100);
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  const dataTestIdBase = 'sto-field-search';

  return (
    <Styled.SearchWrapper>
      <Styled.SwitcherGroup>
        <Switcher
          value={isSelectAll}
          onChange={handleToggleActivitySwitch}
          isBlocked={isSelectAllBlocked}
          dataTestId={`${dataTestIdBase}-selectAll-switch`}
        />
        <Styled.SwitcherLabel
          onClick={handleToggleActivitySwitch}
          data-test-id={`${dataTestIdBase}-selectAll-label`}
        >
          Выбрать все
        </Styled.SwitcherLabel>
        <Styled.Delimiter>|</Styled.Delimiter>
        <Styled.FieldCounter data-test-id={`${dataTestIdBase}-counter`}>
          Выбрано: {selectedFieldNumber} из {maxFieldNumber}
        </Styled.FieldCounter>
      </Styled.SwitcherGroup>

      {!isSearchBlocked && (
        <Styled.SearchIcon
          isSearchActive={isSearchActive}
          onClick={handleToggleSearch}
          isDisabled={isSearchBlocked}
          data-test-id={`${dataTestIdBase}-search`}
        />
      )}

      <Styled.SearchInputIcon $isDisplay={isDisplaySearchIconInInput}>
        <Icon icon="search" size={16} fill={Colors.secondaryDarkGray} />
      </Styled.SearchInputIcon>
      <Styled.SearchField
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        disabled={isSearchBlocked}
        ref={ref}
        $isDisplay={isSearchActive}
        $isDisplaySearchIconInInput={isDisplaySearchIconInInput}
        placeholder="Введите название поля"
        data-test-id={`${dataTestIdBase}-search-input`}
        data-is-displayed={isSearchActive}
      />
      <Styled.ClearInputIcon $isDisplay={isDisplaySearchClear}>
        <IconButton
          icon="close"
          size={16}
          onClick={handleClearSearch}
          colorScheme={{
            default: {
              background: 'transparent',
              icon: Colors.secondaryDarkGray,
            },
            hover: {
              background: 'transparent',
              icon: Colors.black,
            },
          }}
          data-test-id={`${dataTestIdBase}-search-input-clear`}
        />
      </Styled.ClearInputIcon>
    </Styled.SearchWrapper>
  );
};

export default observer(FieldsTabSearch);
