import { useEffect } from 'react';

import { Widget } from '../../../../../../../shared/components';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { SummaryForm } from '../../common';
import { StoController } from '../../mobx';

const SummaryTab = () => {
  const { clearEditOnLeaveSummaryTabTab } = useStore(StoController);

  useEffect(() => {
    return () => {
      clearEditOnLeaveSummaryTabTab();
    };
  }, []);

  return (
    <Widget displayTitle title="Общие данные" dataTestId="sto-summary-tab">
      <SummaryForm />
    </Widget>
  );
};

SummaryTab.displayName = 'SummaryTab';

export default SummaryTab;
