import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Container } from '../../../../../../../shared/features/UI/new/Container';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { useStoParams } from '../../../../hooks';
import { EContainerHeaderType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';

import { StoOperationsCoreController } from './mobx/controllers';
import { StoOperationsCoreStore } from './mobx/stores';
import {
  StoOperationsList as List,
  StoOperationsListLoader as Loader,
  StoOperationsListNoDataPlug as NoDataPlug,
} from './containers';

const StoOperations: FC = () => {
  const { isLoading, itemList } = useStore(StoOperationsCoreStore);
  const { title, fetchItems, clearStoOperationsStore } = useStore(StoOperationsCoreController);

  const params = useStoParams();

  useEffect(() => {
    fetchItems(params.versionId);

    return () => clearStoOperationsStore();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!itemList.length) {
    return <NoDataPlug />;
  }

  return (
    <Container
      dataTestId={'sto__operations'}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: title,
        },
      }}
    >
      <List />
    </Container>
  );
};

StoOperations.displayName = 'StoOperations';

export default observer(StoOperations);
