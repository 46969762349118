import React, { FC, memo, MouseEvent } from 'react';

import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';

import Styled from './ButtonUploadS32.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__button-upload.
   */
  dataTestId: string;
  onClick: (event: MouseEvent<HTMLOrSVGElement>) => void;
}

const ButtonUploadS32: FC<IProps> = ({ dataTestId, onClick }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Svg
      {...getDataTestId()}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect width="32" height="32" rx="9.6" fill="#F3F4F8" />
      <path
        d="M16.0045 6.00007C16.1848 6.00007 16.338 6.0488 16.5183 6.21122L19.5651 8.86683C19.7003 8.98865 19.7814 9.11858 19.7814 9.28913C19.7814 9.62209 19.493 9.85761 19.1234 9.85761C18.9521 9.85761 18.7628 9.79264 18.6366 9.6627L17.2665 8.3552L16.6625 7.7786L16.7166 8.99677V16.0229C16.7166 16.364 16.3921 16.6564 16.0045 16.6564C15.6169 16.6564 15.2834 16.364 15.2834 16.0229V8.99677L15.3375 7.7786L14.7335 8.3552L13.3634 9.6627C13.2372 9.79264 13.0389 9.85761 12.8676 9.85761C12.489 9.85761 12.2186 9.62209 12.2186 9.28913C12.2186 9.11858 12.2997 8.98865 12.4349 8.86683L15.4817 6.21122C15.662 6.0488 15.8152 6.00007 16.0045 6.00007Z"
        fill="#151F32"
      />
      <path
        d="M10.8304 24.0001H21.1696C23.0535 24.0001 24 23.1555 24 21.4825V13.3777C24 11.7047 23.0535 10.8601 21.1696 10.8601H18.6546V12.1676H21.1425C22.0349 12.1676 22.5487 12.6061 22.5487 13.4507V21.4094C22.5487 22.254 22.0349 22.6926 21.1425 22.6926H10.8485C9.94704 22.6926 9.45127 22.254 9.45127 21.4094V13.4507C9.45127 12.6061 9.94704 12.1676 10.8485 12.1676H13.3454V10.8601H10.8304C8.94648 10.8601 8 11.7047 8 13.3777V21.4825C8 23.1555 8.94648 24.0001 10.8304 24.0001Z"
        fill="#151F32"
      />
    </Styled.Svg>
  );
};

ButtonUploadS32.displayName = 'ButtonUpload';

export default memo(ButtonUploadS32);
