import { PolylineOptions } from 'leaflet';

import { Field } from '../../../../../../../api/models/field.model';

import BasePolygon from './BasePolygon/BasePolygon';

class FieldPolygon extends BasePolygon {
  public fieldId: string = null;

  constructor(field: Field, options: PolylineOptions) {
    super(field.geometry, options);

    this.fieldId = field.id;
  }
}

export default FieldPolygon;
