import { TypeApiRoute } from '../../../models/type.api.request';
import { EStoDictionaryName } from '../../../models/as-fields/new/sto';

type TRequest = {
  stoId: string;
  source: FormData;
};

type TResponse = { updatedStoDictionaries: { dictionaryName: EStoDictionaryName }[] };

export const uploadStoDictionary: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ stoId }) => `/api/as-fields/sto/${stoId}/dictionaryUpload`,
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
  request: {} as TRequest,
  response: {} as TResponse,
};
