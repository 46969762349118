import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ButtonsWrapper = styled('div')({
  display: 'flex',
  gap: ESpaceSize.S,
  padding: ESpaceSize.M,
  backgroundColor: Colors.generalWhite,

  '& button': {
    height: 40,
    borderRadius: ESpaceSize.M,
  },
});

const Styles = {
  Wrapper,
  ButtonsWrapper,
};

export default Styles;
