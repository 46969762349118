import moment from 'moment';

import { ETaskStatus, ITask } from '../../../../../../../api/models/as-fields/task/task.model';

interface ITaskStatusData {
  boldText: string;
  normalText: string;
}

const formatDate = (date: string | moment.Moment) => moment(date).format('DD.MM.YYYY');

export const getTaskStatusData = (task: ITask): ITaskStatusData => {
  const { status, daysLeft, planEndDate, cancellationReason } = task;

  switch (status) {
    case ETaskStatus.New: {
      if (daysLeft > 0) {
        return {
          boldText: `${daysLeft} д.`,
          normalText: `до планового завершения ${formatDate(planEndDate)}`,
        };
      }
      if (daysLeft === 0) {
        return {
          boldText: 'Сегодня',
          normalText: `плановое завершение ${formatDate(planEndDate)}`,
        };
      }
      if (daysLeft < 0) {
        return {
          boldText: `${Math.abs(daysLeft)} д. просрочена`,
          normalText: `со дня планового завершения ${formatDate(planEndDate)}`,
        };
      }
      break;
    }
    case ETaskStatus.InWork: {
      if (daysLeft > 0) {
        return {
          boldText: `${daysLeft} д.`,
          normalText: `до планового завершения ${formatDate(planEndDate)}`,
        };
      }
      if (daysLeft === 0) {
        return {
          boldText: 'Сегодня',
          normalText: `плановое завершение ${formatDate(planEndDate)}`,
        };
      }
      if (daysLeft < 0) {
        return {
          boldText: `${Math.abs(daysLeft)} д. просрочена`,
          normalText: `со дня планового завершения ${formatDate(planEndDate)}`,
        };
      }
      break;
    }
    case ETaskStatus.Completed: {
      if (daysLeft < 0) {
        return {
          boldText: `${Math.abs(daysLeft)} д. просрочена`,
          normalText: `со дня планового завершения ${formatDate(planEndDate)}`,
        };
      }
      break;
    }
    case ETaskStatus.Canceled: {
      const [title, body] = splitFistNewLine(cancellationReason);

      return {
        boldText: `Причина отмены: ${title[0].charAt(0).toUpperCase() + title.slice(1)}`,
        normalText: body,
      };
    }

    default:
      return {
        boldText: '',
        normalText: '',
      };
  }
};

function splitFistNewLine(text: string): string[] {
  const index = text.indexOf('\n');
  if (index === -1) {
    return [text];
  } else {
    return [text.slice(0, index), text.slice(index + 1)];
  }
}
