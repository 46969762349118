import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import { useStore } from '../../../../utils/IoC';

import { EMapControlPlace, TMapControl } from './interfaces/Controls.interface';
import Store from './mobx/stores/Controls.store';
import Styled from './Controls.styles';

interface IProps {
  controls: TMapControl[];
}

const MapControlsOverlay: FC<IProps> = props => {
  // Сторы
  const store = useStore(Store);

  const getControls = (place: EMapControlPlace) => {
    return store.getControlsBy(place).map(control => control.component);
  };

  useEffect(() => {
    store.registerControls(props.controls);

    return () => store.clear();
  }, [props.controls]);

  return (
    <Styled.Overlay>
      <Styled.TopArea>{getControls(EMapControlPlace.TOP)}</Styled.TopArea>
      <Styled.LeftArea>{getControls(EMapControlPlace.LEFT)}</Styled.LeftArea>
      <Styled.RightArea>{getControls(EMapControlPlace.RIGHT)}</Styled.RightArea>
      <Styled.BottomArea>{getControls(EMapControlPlace.BOTTOM)}</Styled.BottomArea>
    </Styled.Overlay>
  );
};

export default observer(MapControlsOverlay);
