import L from 'leaflet';
import * as turf from '@turf/turf';

import { lazyInject, provide } from '../../../../../utils/IoC';
import isPointInPolygon from '../../../utils/helpers/isPointInPolygon';
import { MapEventBus } from '../../MapCore';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapMarkerStore from '../stores/MapMarker.store';

import MapMarkerService from './MapMarker.service';

@provide.transient()
class MapMarkerEventsService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapMarkerStore)
  private markerStore: MapMarkerStore;

  @lazyInject(MapMarkerService)
  private service: MapMarkerService;

  public register() {
    if (!this.coreStore.instance) {
      return;
    }

    this.coreStore.instance.on('click', this.handleCreateMarker);
  }

  public unregister() {
    if (!this.coreStore.instance) {
      return;
    }

    this.coreStore.instance.off('click', this.handleCreateMarker);
  }

  /**
   *  Устанавливает маркер на карту.
   *  Если в конфиге маркера есть полигон, то проверяет что маркер внутри полигона
   */
  private handleCreateMarker = (event: L.LeafletMouseEvent) => {
    if (!this.markerStore.isDrawEnabled) {
      return;
    }

    const polygonToDrawInside = this.markerStore.markersConfig.bounds;
    const pointFromMouseCoords = turf.point([event.latlng.lng, event.latlng.lat]);

    if (!polygonToDrawInside) {
      const newMarker = this.service.createMarkerOnClick(event);
      if (newMarker) {
        MapEventBus.emit('map.marker.createOnClick', newMarker);
      }
      return;
    }

    const isInsidePolygon = isPointInPolygon(
      pointFromMouseCoords,
      polygonToDrawInside.getInfo().geometry
    );

    if (isInsidePolygon) {
      const newMarker = this.service.createMarkerOnClick(event);
      if (newMarker) {
        MapEventBus.emit('map.marker.createOnClick', newMarker);
      }
    }
  };
}

export default MapMarkerEventsService;
