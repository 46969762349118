import styled from 'styled-components';

import { Colors } from '../../../../../../../../../../../shared/constans/colors';

export const DescriptionLine = styled.div`
  margin-bottom: 12px;

  p {
    width: 100%;
    color: ${Colors.darkGray};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    line-height: 20px;
    margin: 0;
  }
  span {
    font-size: 14px;
    line-height: 20px;
    margin-left: auto;
    color: ${Colors.black};
  }
`;

export const Wrapper = styled.div`
  width: 208px;
  /* height: 360px; */
  margin-right: 52px;
  display: flex;
  flex-direction: column;
  ${DescriptionLine}:last-child {
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 32px;
  border-bottom: 1px solid ${Colors.grayBorder};
  margin-bottom: 16px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${Colors.black};
  margin: 0;
`;

export const Time = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const TemperatureAndConditionWrapper = styled.div`
  height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 16px;
`;

export const ConditionIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 8px;
`;

export const Temperature = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.green};
  margin: 0;
`;

export const Condition = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 0 auto;
  color: ${Colors.black};
`;
