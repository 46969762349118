import { observer } from 'mobx-react';
import { FC } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { MultiselectStore } from '../../../../mobx';
import { useStore } from '../../../../../../../../../shared/utils/IoC';

import ApplyResetButtons from './component/ApplyResetButtons/ApplyResetButtons';
import ActionButtons from './component/ActionButtons/ActionButtons';
import Styled from './Footer.styles';

const MultiSelectFooter: FC = () => {
  // Сторы
  const store = useStore(MultiselectStore);

  const getDataTestId = useDataTestIdV2('fields-multiselect-footer');

  const isReadyToSave = store.deletedFields || store.changedFields;

  if (!isReadyToSave && !store.selectedFieldsCount) {
    return null;
  }

  return (
    <Styled.Wrapper>
      {isReadyToSave ? (
        <ApplyResetButtons getDataTestId={getDataTestId} />
      ) : (
        <ActionButtons getDataTestId={getDataTestId} />
      )}
    </Styled.Wrapper>
  );
};

export default observer(MultiSelectFooter);
