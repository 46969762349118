import React, { FC, memo } from 'react';

import Styled from './StoChecklistsColumn.styled';

interface IProps {
  name: string;
  isWithoutTextAlign?: boolean;
}

const StoChecklistsColumn: FC<IProps> = ({ name, isWithoutTextAlign }) => {
  return <Styled.Wrapper $isWithoutTextAlign={isWithoutTextAlign}>{name}</Styled.Wrapper>;
};

StoChecklistsColumn.displayName = 'StoChecklistsColumn';

export default memo(StoChecklistsColumn);
