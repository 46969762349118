import { FC, memo } from 'react';
import moment from 'moment/moment';

import calendarSvg from '../../../../../../operations/components/TaskView/assets/calendarSvg.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  taskExecutedDate: string;
  dataTestId: string;
}
const TasksDrawerTaskExecutedDate: FC<IProps> = ({ taskExecutedDate, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__task-executed-date`);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label {...getDataTestId('label')}>Дата</Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={calendarSvg} />

      <Styled.Info {...getDataTestId('info')}>
        {moment(taskExecutedDate).format('DD.MM.YYYY')}
      </Styled.Info>
    </Styled.InfoBlock>
  );
};

TasksDrawerTaskExecutedDate.displayName = 'TasksDrawerTaskExecutedDate';

export default memo(TasksDrawerTaskExecutedDate);
