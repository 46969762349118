import * as L from 'leaflet';
import * as turf from '@turf/turf';

import { ProfileStore } from '../../../../../../../../../dashboard/modules/profile/stores/ProfileStore';
import { OrganizationsStore } from '../../../../../../../../../dashboard/stores/organizations.store';
import { SeasonsStore } from '../../../../../../../../../dashboard/stores/seasons.store';
import { lazyInject, provide } from '../../../../../../../../utils/IoC';
import { Axios, TypeApiRequest } from '../../../../../../../../utils/axios2';
import { MapCoreStore, MapPolygonStore } from '../../../../../../modules';

@provide.transient()
class IndicesService {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  private seasonsStore: SeasonsStore;

  @lazyInject(ProfileStore)
  private profileStore: ProfileStore;

  @lazyInject(OrganizationsStore)
  private organizationsStore: OrganizationsStore;

  @lazyInject(MapCoreStore)
  private mapCoreStore: MapCoreStore;

  @lazyInject(MapPolygonStore)
  protected mapPolygonStore: MapPolygonStore;

  getIndices = (payload: TypeApiRequest<'getIndicesList'>) => {
    return this.axios.api.getIndicesList(payload);
  };

  getIndicesYears = (payload: TypeApiRequest<'getIndicesYearList'>) => {
    return this.axios.api.getIndicesYearList(payload);
  };

  createImageOverlay = (
    polyLeafletId: number,
    imageUrl: string,
    fieldId: string,
    coordinates?: any
  ): L.ImageOverlay => {
    try {
      let polygon;
      let imageOverlay;
      let turfPolygonCords;

      if (coordinates) {
        const normalizedCoordinates = L.GeoJSON.coordsToLatLngs(coordinates, 1);

        polygon = L.polygon(normalizedCoordinates);

        if (!polygon || !this.mapCoreStore.instance) {
          return;
        }

        // Создаем Leaflet-оверлей изображения с оригинальным URL
        imageOverlay = L.imageOverlay(
          this._addTrackingImageData(imageUrl, fieldId),
          polygon.getBounds()
        );

        // Создаем полигон Turf.js из координат полигона Leaflet
        // @ts-ignore
        turfPolygonCords = polygon._latlngs.map(posArr =>
          posArr.map(item => {
            return [item.lng, item.lat];
          })
        );
      } else {
        polygon = this.mapPolygonStore.getPolygon(polyLeafletId);

        if (!polygon || !this.mapCoreStore.instance) {
          return;
        }

        // Создаем Leaflet-оверлей изображения с оригинальным URL
        imageOverlay = L.imageOverlay(
          this._addTrackingImageData(imageUrl, fieldId),
          polygon.getBounds()
        );

        // Создаем полигон Turf.js из координат полигона Leaflet
        // @ts-ignore
        turfPolygonCords = polygon.getLatLngs().map(posArr =>
          posArr.map(item => {
            return [item.lng, item.lat];
          })
        );
      }

      let turfPolygon;

      // Проверка на мультиполигон
      if (turfPolygonCords.length > 1) {
        turfPolygon = turf.multiPolygon(turfPolygonCords);
      } else {
        // Замыкаем поле путём копирования последней первой точки
        turfPolygonCords[0].push(turfPolygonCords[0][0]);
        turfPolygon = turf.polygon(turfPolygonCords);
      }

      // Вычисляем пересечение границ изображения и полигона
      const imageBounds = imageOverlay.getBounds().toBBoxString().split(',').map(Number);
      const bbox: turf.BBox = [...imageBounds, 0, 0, 0, 0] as any;
      const intersection = turf.intersect(turf.bboxPolygon(bbox), turfPolygon);

      if (!intersection) {
        return imageOverlay;
      }

      const bounds = L.latLngBounds(
        intersection.geometry.coordinates[0].map(coord => L.latLng([coord[1], coord[0]]))
      );

      const croppedOverlay = L.imageOverlay(this._addTrackingImageData(imageUrl, fieldId), bounds);

      croppedOverlay.addTo(this.mapCoreStore.instance);

      /** 
      Ниже кейс с попыткой отвязаться от карты и сделать на базе кропа svg.
      Проблематика: Любое действие пользователя требует перерасчёта позиции элемента и избегание переписывания transform

      if (croppedOverlay._image) {
      const imageElement = croppedOverlay._image as HTMLImageElement;

      imageElement.classList.add('clipping-indices');

      const svgClipPath = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const clipPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
      clipPathElement.id = 'indices-clipping-path';
      // clipPathElement.clipPathUnits = 'objectBoundingBox';

      clipPathElement.appendChild((polygon.leafletPolygon._path as SVGPathElement).cloneNode(true));
      svgClipPath.appendChild(clipPathElement);

      // (polygon.leafletPolygon._path as SVGPathElement).id =
      //   'clipping-path';

      const style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = `.clipping-indices { clip-path: url(#indices-clipping-path) }`;
      document.getElementsByTagName('head')[0].appendChild(style);
      document.body.appendChild(svgClipPath);

      imageElement.style.clipPath = `url(${polygon.leafletPolygon._path})`;

      }
         */

      return croppedOverlay;
    } catch (error) {
      console.error('Ошибка отображения индекса', error);
    }
  };

  _addTrackingImageData = (url: string, fieldId: string) => {
    const season = this.seasonsStore.selectedSeason;
    const userId = this.profileStore.user?.id;
    const orgId = this.organizationsStore.selectedOrganizationId;

    const orgIdQueryArg = orgId === 'my' || !orgId ? '' : `&organizationId=${orgId}`;

    return `${url}?fieldId=${fieldId}&seasonYear=${season}&userId=${userId}${orgIdQueryArg}`;
  };
}

export default IndicesService;
