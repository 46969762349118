import { Checkbox } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../../../../../mobx';
import { TCultureOption } from '../../../../../../../../utils';
import MultiListSection from '../../components/MultiListSection/MultiListSection';

import Styled from './FieldCultureFilter.styles';

const FieldCultureFilterSection: FC = () => {
  // Сторы
  const store = useStore(Store);

  const filter = store.filterManager.filters.getOne('culture');

  return (
    <MultiListSection
      title={'Культура'}
      filter={filter}
      createSearchFilter={createSearchFilter}
      listItem={{
        height: 32,
        render: (item: TCultureOption) => (
          <Styled.ListItem key={`filter-key-${item.label}`}>
            <Checkbox value={item.selected} disable={item.disabled} />
            <span>{item.label}</span>
          </Styled.ListItem>
        ),
      }}
    />
  );
};

const createSearchFilter = (value: string) => (option: TCultureOption) => {
  return option.label.toLowerCase().includes(value);
};

export default observer(FieldCultureFilterSection);
