import { BasePolygon } from '../MapElements';

/**
 * Проверяет что площадь полигона не превышает maxArea
 * @param polygon - Полигон для проверки
 * @param maxArea - Максимальная площать полигона в гектарах
 */
const isBigAreaInHectares = (polygon: BasePolygon, maxArea = 3000): boolean => {
  if (!polygon || typeof polygon?.getInfo !== 'function') {
    return false;
  }

  const areaInHectares = polygon.getInfo().area;

  return areaInHectares > maxArea;
};

export default isBigAreaInHectares;
