import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';

import {
  TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw,
  TChecklistsDoubleAttrToDraw as TDoubleAttrToDraw,
} from '../../../models';
import {
  useChecklistsAttrErrorList as useAttrErrorList,
  useChecklistsAttrVisibility as useAttrVisibility,
} from '../../../hooks';
import { InputFieldError } from '../../../../../../../../shared/components/InputFieldError';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { Input } from '../../../../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';
import { ChecklistsAttr as Attribute } from '../../../components/elements';
import { createChecklistsAttributeId as createAttrId } from '../../../helpers';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import {
  AverageAttributeCulledItemsBody,
  AverageAttributeItemsBody as ItemsBody,
} from './components';
import Styled from './ChecklistsAverageAttribute.styles';
import cullNumbersModalConfig from './modals/ChecklistsAverageAttriCullNumbersModal/ChecklistsAverageAttrCullNumbersModal';
import AverageAttributeHelpers from './helpers/AverageAttribute.helpers';

interface IProps {
  attrToDraw: TIntegerAttrToDraw | TDoubleAttrToDraw;
}

const { getValueListToCull } = AverageAttributeHelpers;

const ChecklistsAverageAttribute: FC<IProps> = ({ attrToDraw }) => {
  const { id, groupId, initialModel, options, isBlocked, dependentFileAttrId } = attrToDraw;

  const getDataTestId = useDataTestIdV2('checklists__attribute_average');

  const { openModalByModalId, registerModalList } = useModal();

  const isShowAttribute = useAttrVisibility(attrToDraw);
  const errorList = useAttrErrorList(attrToDraw);

  useEffect(() => {
    registerModalList([cullNumbersModalConfig], cullNumbersModalConfig.id);
  }, []);

  const handleCullNumbersClick = () => {
    openModalByModalId(cullNumbersModalConfig.id, {
      attrToDraw,
    });
  };

  const valueListToCull = useMemo(() => {
    return getValueListToCull(options?.averageData?.rowList);
  }, [options?.averageData?.rowList]);

  if (!isShowAttribute) {
    return <></>;
  }

  return (
    <Attribute
      width={initialModel.position.width}
      isNewLine={initialModel.position.newLine}
      id={createAttrId(groupId, id)}
      dataTestId={getDataTestId()['data-test-id']}
    >
      <Styled.Wrapper {...getDataTestId('wrapper')} $isError={Boolean(errorList.length)}>
        <Styled.Header {...getDataTestId('header')}>
          <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
            <Styled.TitleAndErrorContainer {...getDataTestId('title-and-error-container')}>
              {initialModel.attribute?.name ? (
                <Styled.Title {...getDataTestId('title')} $isRequired={initialModel.isRequired}>
                  {initialModel.attribute?.name}
                </Styled.Title>
              ) : null}

              <InputFieldError error={{ errorList }} />
            </Styled.TitleAndErrorContainer>

            {!isBlocked ? (
              <Styled.ActionWrapper {...getDataTestId('action-wrapper')}>
                <Styled.Action
                  type={'button'}
                  onClick={handleCullNumbersClick}
                  {...getDataTestId('action-button')}
                  $isDisabled={!valueListToCull.length}
                >
                  Выбраковать значения
                </Styled.Action>
              </Styled.ActionWrapper>
            ) : null}
          </Styled.TitleWrapper>

          {initialModel.attribute.placeholder ? (
            <Styled.Description {...getDataTestId('description')}>
              {initialModel.attribute.placeholder}
            </Styled.Description>
          ) : null}
        </Styled.Header>

        <Styled.ItemsBodyWrapper {...getDataTestId('items-body-wrapper')}>
          <ItemsBody attrToDraw={attrToDraw} />

          <FileAttrContainer groupId={groupId} attrId={dependentFileAttrId} />
        </Styled.ItemsBodyWrapper>

        <Styled.CalculatedDataWrapper {...getDataTestId('calculated-data-wrapper')}>
          <Input label={'Среднее'} isBlocked value={attrToDraw.options.averageData.average} />
          <Input label={'Минимум'} isBlocked value={attrToDraw.options.averageData.min} />
          <Input label={'Максимум'} isBlocked value={attrToDraw.options.averageData.max} />
        </Styled.CalculatedDataWrapper>

        {attrToDraw.options.averageData.culledValueList.length ? (
          <AverageAttributeCulledItemsBody
            attrToDraw={attrToDraw}
            culledValueList={attrToDraw.options.averageData.culledValueList}
            isBlocked={isBlocked}
          />
        ) : null}
      </Styled.Wrapper>
    </Attribute>
  );
};

ChecklistsAverageAttribute.displayName = 'ChecklistsAverageAttribute';

export default observer(ChecklistsAverageAttribute);
