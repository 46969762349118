import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { ChecklistsStore } from '../../stores';
import {
  EChecklistAttrDependencyDependencyType as EDependencyType,
  EChecklistAttributeType as EAttrType,
  IChecklistAttrDependency as IAttrDependency,
} from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { TaskStore } from '../../../../../mobx/stores';
import { ChecklistInstancesStore } from '../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { IChecklistsFormulaResult as IFormulaResult } from '../../../models';
import { IDependencyRecordForRequest } from '../../../../../../../../../api/endpoints/da-dictionary/dictionary/getDictionaryEntityList';

interface IDictionaryDepResult {
  isNotEdited: boolean;
  data: IDependencyRecordForRequest;
  isTheAttributeDoesntExist?: boolean;
}

@provide.transient()
class ChecklistsAttrsDependencyService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistInstancesStore)
  protected instancesStore: ChecklistInstancesStore;

  @lazyInject(TaskStore)
  protected taskStore: TaskStore;

  getEnumDependency = (groupId: string, id: string): IFormulaResult => {
    const attrToDraw = this.checklistsStore.getAttrToDraw<EAttrType.Enum>(groupId, id);

    if (!attrToDraw) return;

    const hasDependency = Boolean(attrToDraw.initialModel?.dependency?.dependencies?.length);

    if (!hasDependency) return;

    const [firstDep] = attrToDraw.initialModel?.dependency?.dependencies;

    if (firstDep.dependencyType === EDependencyType.Attribute) {
      const depAttrToDraw = this.checklistsStore.getAttrToDraw<EAttrType.Enum>(
        groupId,
        firstDep.attrId
      );

      return {
        value: depAttrToDraw.value.enumValues?.[0],
        isNotEdited: !depAttrToDraw.isEdited,
      };
    }
  };

  getDictionaryDependency = (
    groupId: string,
    id: string
  ): IFormulaResult<IDependencyRecordForRequest[]> => {
    const attrToDraw = this.checklistsStore.getAttrToDraw<EAttrType.DictionaryLink>(groupId, id);

    if (!attrToDraw) return;

    const hasDependency = Boolean(attrToDraw.initialModel?.dependency?.dependencies?.length);

    if (!hasDependency) return;

    const dependencyList = attrToDraw.initialModel?.dependency?.dependencies;

    const depValueList = dependencyList.map(dep => this.getDictionaryDepValue(groupId, dep));

    /**
     * Костыль для регресса. (Логика рабочая, но случай странный).
     * Есть кейс, когда нет родительского атрибута, что расположен, согласно ордеру, перед зависимым.
     * Требуется найти причину данной проблемы.
     */
    if (depValueList.some(value => value?.isTheAttributeDoesntExist)) {
      return;
    }

    if (depValueList.every(value => value?.data)) {
      const isNotEdited = depValueList.every(el => el.isNotEdited);

      return {
        value: depValueList.map(el => el.data),
        isNotEdited,
      };
    }

    return {
      value: [],
    };
  };

  protected getDictionaryDepValue = (
    groupId: string,
    dep: IAttrDependency
  ): IDictionaryDepResult => {
    switch (dep.dependencyType) {
      case EDependencyType.Attribute:
        return this.getDictionaryAttrDepValue(groupId, dep);

      case EDependencyType.CultureTask:
        return this.getDictionaryCulture();

      case EDependencyType.OperationTypeTask:
        return this.getDictionaryOperationType();

      case EDependencyType.PhenophaseTask:
        return this.getDictionaryPhenophase();

      default:
    }
  };

  protected getDictionaryAttrDepValue = (
    groupId: string,
    dep: IAttrDependency
  ): IDictionaryDepResult => {
    const attributeGroupId = this.checklistsStore.getGroupIdByAttrId(dep.attrId, groupId);

    const depAttrToDraw = this.checklistsStore.getAttrToDraw<EAttrType.DictionaryLink>(
      attributeGroupId,
      dep.attrId
    );

    if (!depAttrToDraw) {
      return {
        isTheAttributeDoesntExist: true,
        isNotEdited: true,
        data: null,
      };
    }

    const value = depAttrToDraw.value.dictionaryValueList?.[0];

    if (!value) return;

    return {
      isNotEdited: !depAttrToDraw.isEdited,
      data: {
        dependencyName: depAttrToDraw.initialModel.attribute?.dictionaryLink,
        dependencyRecordId: value,
        directDependencies: false,
      },
    };
  };

  protected getDictionaryCulture = (): IDictionaryDepResult => {
    const isInstanceComplete = this.instancesStore.selectedInstance?.isComplete;
    const cultureId = this.taskStore.selectedTask?.culture?.id;

    if (!cultureId) return;

    return {
      isNotEdited: Boolean(isInstanceComplete),
      data: {
        dependencyName: 'culture',
        dependencyRecordId: cultureId,
        directDependencies: false,
      },
    };
  };

  protected getDictionaryOperationType = (): IDictionaryDepResult => {
    const isInstanceComplete = this.instancesStore.selectedInstance?.isComplete;
    const operationTypeId = this.taskStore.selectedTask?.operationInfo?.operationTypeInfo?.id;

    if (!operationTypeId) return;

    return {
      isNotEdited: Boolean(isInstanceComplete),
      data: {
        dependencyName: 'techOperationType',
        dependencyRecordId: operationTypeId,
        directDependencies: false,
      },
    };
  };

  protected getDictionaryPhenophase = (): IDictionaryDepResult => {
    const isInstanceComplete = this.instancesStore.selectedInstance?.isComplete;
    const phenophaseId = this.instancesStore?.selectedIntensity?.id;

    if (!phenophaseId) return;

    return {
      isNotEdited: Boolean(isInstanceComplete),
      data: {
        dependencyName: 'phenophase',
        dependencyRecordId: phenophaseId,
        directDependencies: false,
      },
    };
  };
}

export default ChecklistsAttrsDependencyService;
