import { ISelectOption } from '../../../../../../types/selectOption';
import { OperationCulture } from '../../../../../../api/models/operations/operation.culture';

const createOperationCultureSelectOption = (
  operationCulture: OperationCulture
): ISelectOption<OperationCulture> => {
  return {
    label: operationCulture.culture.name,
    value: operationCulture.culture.id,
    initialModel: operationCulture,
  };
};

const createOperationCultureSelectOptionList = (
  operationCultureList: OperationCulture[]
): ISelectOption[] => {
  return operationCultureList.map(createOperationCultureSelectOption);
};

export { createOperationCultureSelectOption, createOperationCultureSelectOptionList };
