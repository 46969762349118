import { Typography } from '@farmlink/farmik-ui';
import { FC, useEffect, useRef } from 'react';

import { formatDateToNameString } from '../../../../../../../../../../../../utils/formatDateToNameString';
import conditionalObjectProp from '../../../../../../../../../../../../utils/conditionalObjectProp';
import { IndexModel } from '../../../../../../../../../../../../../../api/models/indices.model';
import { useStore } from '../../../../../../../../../../../../utils/IoC';
import { IndicesStore } from '../../../../mobx';

import Styled from './List.styles';

interface IProps {
  onItemClick: (item: IndexModel) => void;
}

const TimelineList: FC<IProps> = props => {
  // Сторы
  const store = useStore(IndicesStore);

  const ref = useRef<HTMLLIElement>(null);

  const isSelectedItem = (item: IndexModel) => {
    return store.selectedIndex?.publicId === item.publicId;
  };

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [store.selectedIndexPosition]);

  return (
    <Styled.List>
      {store.indices?.map((item, i) => {
        return (
          <Styled.Item
            key={item.publicId}
            onClick={() => props.onItemClick(item)}
            data-test-id={`map-timeline-item-${i}`}
            data-test-date={item.fromDate}
            $isSelected={isSelectedItem(item)}
            $width={108}
            {...conditionalObjectProp(isSelectedItem(item), { ref })}
          >
            <Typography align="center" variant="body">
              {formatDateToNameString(item.fromDate, true)}
            </Typography>
          </Styled.Item>
        );
      })}
    </Styled.List>
  );
};

export default TimelineList;
