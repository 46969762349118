import { EStoAction } from '../../../../../../../../api/models/as-fields/STO/STO.model';
import { StoService } from '../../../../../../../shared/mobx/services/as-fields';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EStoTab } from '../../models/StoCommon';
import { StoFieldsTabService } from '../../modules/FieldsTab/mobx/services';
import { StoHistoryService } from '../../modules/HistoryTab/mobx';
import { StoOperationsItemsService } from '../../modules/StoOperations/mobx/services';
import { StoStore } from '../stores';
import { StoRegistriesController } from '../../modules/StoRegistries/mobx/controllers';
import { IStoParams } from '../../../../models';

import StoTabService from './StoTab.service';

@provide.transient()
class StoActionService {
  @lazyInject(StoStore)
  protected store: StoStore;

  @lazyInject(StoService)
  protected service: StoService;

  @lazyInject(StoTabService)
  protected tabService: StoTabService;

  @lazyInject(StoHistoryService)
  protected historyService: StoHistoryService;

  @lazyInject(StoFieldsTabService)
  protected fieldsService: StoFieldsTabService;

  @lazyInject(StoOperationsItemsService)
  protected operationsService: StoOperationsItemsService;

  @lazyInject(StoRegistriesController)
  protected stoRegistriesController: StoRegistriesController;

  publishSto = (comment: string) => {
    const versionComment = comment?.length > 0 ? comment.trim() : null;
    const isFirstPublishOnOperationsTab =
      this.store.StoTab === EStoTab.Operations && this.store?.selectedSto.publishDate === null;

    return this.service
      .publishSto({
        id: this.store?.selectedSto?.id,
        versionComment,
      })
      .then(data => {
        this.store.setHasChanges(false);

        this.store.setSelectedSto(data);
        this.store.clearUpdatedSto();

        if (!isFirstPublishOnOperationsTab) {
          this.refreshStoDataByActiveTab();
        }

        /**
         * Требуется, чтобы после публикации СТО не менялся TAB, когда мы находимся в "Реестрах".
         */
        const isFromRegistriesTab = this.store.StoTab === EStoTab.Registries;

        const tab = this.tabService.calculateStoItemTab(
          false,
          isFromRegistriesTab ? EStoTab.Registries : null
        );
        this.tabService.calculateStoItemTabConfigByParams(tab);

        this.store.setIsViewOnly(!data.availableActions?.includes(EStoAction.StoEdit));

        return data;
      })
      .catch(e => {
        throw e;
      });
  };

  activateSto = () => {
    return this.service
      .activateSto({ id: this.store?.selectedSto?.id })
      .then(data => {
        if (data) {
          this.store.setHasChanges(false);

          this.store.setSelectedSto(data);

          this.refreshStoDataByActiveTab();

          this.store.setIsViewOnly(!data.availableActions?.includes(EStoAction.StoEdit));
        }

        return data;
      })
      .catch(e => {
        throw e;
      });
  };

  deactivateSto = () => {
    return this.service
      .deactivateSto({ id: this.store?.selectedSto?.id })
      .then(data => {
        if (data) {
          this.store.setHasChanges(false);

          this.store.setSelectedSto(data);

          this.refreshStoDataByActiveTab();

          this.store.setIsViewOnly(!data.availableActions?.includes(EStoAction.StoEdit));
        }

        return data;
      })
      .catch(e => {
        throw e;
      });
  };

  discardDraft = (stoId?: string) => {
    return this.service
      .discardDraft({ id: stoId ?? this.store?.selectedSto?.id })
      .then(data => {
        this.store.setHasChanges(false);

        if (data) {
          this.store.setSelectedSto(data);

          this.refreshStoDataByActiveTab();

          this.store.setIsViewOnly(!data.availableActions?.includes(EStoAction.StoEdit));
        }

        return data;
      })
      .catch(e => {
        throw e;
      });
  };

  refreshStoDataByActiveTab = () => {
    switch (this.store.StoTab) {
      case EStoTab.Summary:
        break;

      case EStoTab.Fields:
        this.fieldsService.fetchFieldList({
          id: this.store.selectedSto?.id,
          includeUnavailable: true,
          page: 0,
          size: 50,
        });
        break;

      case EStoTab.Operations:
        this.operationsService.addItems();
        break;

      case EStoTab.Registries:
        this.stoRegistriesController.initiatePage({ stoId: this.store.selectedSto.id });
        break;

      case EStoTab.History:
        this.historyService.fetchStoHistory();
        break;

      default:
        break;
    }
  };
}

export default StoActionService;
