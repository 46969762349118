import { useEffect } from 'react';

import { TableFiltersBuilderController } from '../../mobx/controllers';
import { useStore } from '../../../../utils/IoC';

/**
 * @param builderId — уникальный идентификатор фильтров [билдера].
 * @param applyHandler — функция, которая вызывается, когда применены какие-то фильтры.
 */
const useTableFilters = (builderId: string, applyHandler: () => void): void => {
  const controller = useStore(TableFiltersBuilderController);

  const appliedFilters = controller.getAppliedFilters(builderId);

  useEffect(() => {
    if (!appliedFilters) {
      return;
    }

    applyHandler();
  }, [JSON.stringify(appliedFilters)]);
};

export default useTableFilters;
