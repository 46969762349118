import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { ButtonLoader } from '../../../../../../../../../shared/components/ButtonLoader/ButtonLoader';
import { useWarningBeforeLeavingThePage } from '../../../../../../../../../shared/hooks';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import useFieldRoutes from '../../../../../../hooks/useFieldsRoutes';
import { EFieldsCommonModalName } from '../../../../../../modals';
import { EFieldsErrors } from '../../../../../../utils';
import { EFieldsCreateModalName } from '../../../../modals';
import { FieldsCreateController as Controller, FieldsCreateStore as Store } from '../../../../mobx';

import Styled from './Footer.styles';

const FieldsCreateListFooter: FC = () => {
  // Навигация
  const navigate = useNavigate();
  const { fieldsViewRoute } = useFieldRoutes();

  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const store = useStore(Store);

  const modal = useModal();
  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();

  const handleClose = () => {
    if (store.hasCreatedField) {
      showWarningBeforeLeavingThePage(() => {
        navigate(fieldsViewRoute);
      });

      return;
    }

    navigate(fieldsViewRoute);
  };

  const handleSave = () => {
    if (store.isAreaTooBig) {
      modal.openModalByModalId(EFieldsCommonModalName.OversizeAreaWarning);
      return;
    }

    if (!store.isFinishedPolygon) {
      modal.openModalByModalId(EFieldsCreateModalName.UnfinishedContourWarning);
      return;
    }

    controller
      .submitFields()
      .then(() => navigate(fieldsViewRoute))
      .catch(error => {
        if (error?.message === EFieldsErrors.GeometryIntersection) {
          modal.openModalByModalId(EFieldsCommonModalName.IntersectionWarning);
        }
      });
  };

  return (
    <>
      <Styled.ButtonWrapper>
        <Button
          type={'button'}
          color={'default'}
          onClick={handleClose}
          dataTestId={'fields-add-cancel'}
        >
          Отменить
        </Button>
      </Styled.ButtonWrapper>

      <Styled.ButtonWrapper>
        <ButtonLoader
          isLoading={store.isLoading}
          onClick={handleSave}
          disabled={!store.hasCreatedField || store.hasErrors}
          data-test-id={'fields-add-save'}
        >
          Сохранить
        </ButtonLoader>
      </Styled.ButtonWrapper>
    </>
  );
};

export default observer(FieldsCreateListFooter);
