import { FC, memo } from 'react';

import cultureSvg from '../../../../../../../../operations/components/TaskView/assets/cultureSvg.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  taskCultureName: string;
  dataTestId: string;
}
const TaskOperationCreateVerificationCultureName: FC<IProps> = ({
  taskCultureName,
  dataTestId,
}) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__culture-name`);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label color={'generalDarkHover'} {...getDataTestId('label')}>
        Культура
      </Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={cultureSvg} />

      <Styled.Info {...getDataTestId('info')}>
        {taskCultureName || 'Культура не указана'}
      </Styled.Info>
    </Styled.InfoBlock>
  );
};

TaskOperationCreateVerificationCultureName.displayName =
  'TaskOperationCreateVerificationCultureName';

export default memo(TaskOperationCreateVerificationCultureName);
