import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { ReactComponent as InExpSvgIcon } from '../../../../../../../../../../../../../../../../static/experiment-status-icons/in-exp.svg';
import { ReactComponent as NotInExpSvgIcon } from '../../../../../../../../../../../../../../../../static/experiment-status-icons/not-in-exp.svg';
import { ReactComponent as InExpRedSvgIcon } from '../../../../../../../../../../../../../../../../static/experiment-status-icons/in-exp-red.svg';
import { ReactComponent as InExpBlueSvgIcon } from '../../../../../../../../../../../../../../../../static/experiment-status-icons/in-exp-blue.svg';

const InExpIcon = styled(InExpSvgIcon)({
  '&:hover': {
    path: {
      fill: Colors.hoverDarkGrey,
    },
    circle: {
      fill: '#E9ECF0',
    },
  },
});

const NotInExpIcon = styled(NotInExpSvgIcon)({
  '&:hover': {
    path: {
      fill: Colors.hoverDarkGrey,
    },
    circle: {
      fill: '#E9ECF0',
    },
  },
});

const InExpRedIcon = styled(InExpRedSvgIcon)({
  '&:hover': {
    path: {
      fill: Colors.red,
    },
    circle: {
      fill: Colors.lightPinkHover,
    },
  },
});

const InExpBlueIcon = styled(InExpBlueSvgIcon)({
  '&:hover': {
    path: {
      fill: Colors.hoverBlue,
    },
    circle: {
      fill: Colors.hoverLightSky,
    },
  },
});

export default {
  InExpIcon,
  NotInExpIcon,
  InExpRedIcon,
  InExpBlueIcon,
};
