import { memo, FC } from 'react';

import icon from './assets/icons/coloredField.svg';
import Styled from './NoPointsPlaceholder.styled';

const NO_POINT = 'Вы можете запланировать точки осмотра заранее, для этого отметьте их на карте';
const NO_POINTS_CHECKLISTS =
  'Вы не можете запланировать точки осмотра, так как по ним не найден чек-лист, который можно применить к этому типу операции и культуре. Обратитесь к администратору, чтобы его настроить';

const NoPointsPlaceholder: FC<{
  onButtonClick: () => void;
  isNoChecklists?: boolean;
  isDisableAddPoints?: boolean;
}> = ({ onButtonClick, isNoChecklists, isDisableAddPoints }) => {
  return (
    <Styled.Wrapper>
      <Styled.Icon src={icon} />
      <Styled.Title>Нет точек осмотра</Styled.Title>

      <Styled.Subtitle data-test-id="no-points-subtitle">
        {isNoChecklists ? NO_POINTS_CHECKLISTS : NO_POINT}
      </Styled.Subtitle>

      {isNoChecklists || isDisableAddPoints ? null : (
        <Styled.StyledButton 
          onClick={onButtonClick}
          alignment ="center"
          styleType="outlined"
          color="primary"
          type="button"
          data-test-id="no-points-add-point-button"
        >
          Добавить точки
        </Styled.StyledButton>
      )}
    </Styled.Wrapper>
  );
};

export default memo(NoPointsPlaceholder);
