import L, { LatLngExpression } from 'leaflet';

import {
  ECheckListInstanceType,
  IDrawChecklistInstance,
  IGetChecklistInstanceByTaskId,
  IPutChecklistInstance,
} from '../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { IMapMarkerElementConfig } from '../../../../../../../../../shared/features/map/models';

import BaseInspectionPointMarker from './BaseInspectionPointMarker';
import PlanInspectionPointMarker from './PlanInspectionPointMarker';
import ActualInspectionPointMarker from './ActualInspectionPointMarker';

type TInstance = IGetChecklistInstanceByTaskId | IPutChecklistInstance | undefined;
type TCoordsKey = 'actualCoords' | 'planCoords';

function extractCoords(
  instance: TInstance,
  key: TCoordsKey,
  isNullable?: boolean
): LatLngExpression {
  const coordsFromGeometry = instance?.[key]?.geometry?.coordinates;
  const coords = instance?.[key]?.coordinates ?? coordsFromGeometry;

  if (isNullable && !coords) {
    return null;
  }

  if (!coords) {
    return [0, 0];
  }

  return L.GeoJSON.coordsToLatLng(coords as any);
}

function getInspectionMarker(point: IDrawChecklistInstance): BaseInspectionPointMarker {
  if (!point || !point.instance) {
    return new BaseInspectionPointMarker([0, 0]);
  }

  const { instance, positionNumber, isDisabled } = point;

  const actualCoords = extractCoords(instance, 'actualCoords', true);
  const planCoords = extractCoords(instance, 'planCoords', true);
  const isCompleted = (instance as IGetChecklistInstanceByTaskId)?.isComplete;

  switch (instance.type) {
    case ECheckListInstanceType.ActualPoint:
      return new ActualInspectionPointMarker(actualCoords, positionNumber, isCompleted, isDisabled);

    case ECheckListInstanceType.Field:
      return new PlanInspectionPointMarker(
        actualCoords || planCoords,
        positionNumber,
        isCompleted,
        isDisabled
      );

    case ECheckListInstanceType.Machinery:
      return new PlanInspectionPointMarker(
        actualCoords || planCoords,
        positionNumber,
        isCompleted,
        isDisabled
      );

    case ECheckListInstanceType.PlannedPoint:
      return new PlanInspectionPointMarker(planCoords, positionNumber, isCompleted, isDisabled);
    default:
      return new BaseInspectionPointMarker([0, 0]);
  }
}

function getInspectionMarkerConfig(point: IDrawChecklistInstance): IMapMarkerElementConfig {
  return {
    element: point,
    marker: getInspectionMarker(point),
  };
}

export default getInspectionMarkerConfig;
