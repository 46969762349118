import React, { FC, memo, MouseEvent } from 'react';

import { useDataTestIdV2 } from '../../../utils/hooks/locators';

import { ButtonUploadS40 as S40, ButtonUploadS32 as S32 } from './components';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__button-upload.
   */
  dataTestId: string;
  size: 's32' | 's40';
  onClick?: () => void;
}

const ButtonUpload: FC<IProps> = ({ dataTestId, onClick, size }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__button-upload`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    event.stopPropagation();

    onClick?.();
  };

  switch (size) {
    case 's32':
      return <S32 dataTestId={getDataTestId()['data-test-id']} onClick={handleClick} />;

    case 's40':
      return <S40 dataTestId={getDataTestId()['data-test-id']} onClick={handleClick} />;

    default:
      return <></>;
  }
};

ButtonUpload.displayName = 'ButtonUpload';

export default memo(ButtonUpload);
