import { List, ListRowProps } from 'react-virtualized';
import { observer } from 'mobx-react';
import { FC } from 'react';

import EditableListItem from '../../../../components/ListItems/EditableListItem/EditableListItem';
import useMapNotifications from '../../hooks/useMapNotifications';
import { FieldsCreateController as Controller, FieldsCreateStore as Store } from '../../mobx';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ListWrapper } from '../../../../components';

import DrawInfoPlaceholder from './components/DrawInfoPlaceholder/DrawInfoPlaceholder';
import FieldsCreateListFooter from './components/Footer/Footer';

const ROW_HEIGHT = 92;

const FieldsCreateList: FC = () => {
  // Сторы
  const store = useStore(Store);

  // Контроллеры
  const controller = useStore(Controller);

  useMapNotifications(store);

  const list = store.createdFieldsList;

  const handleInputChange = (fieldId: number, value: string) => {
    controller.changeFieldName(fieldId, value);
  };

  const handleDelete = (fieldId: number) => {
    controller.deleteCreatedField(fieldId);
  };

  const rowRender = (row: ListRowProps) => {
    const field = list[row.index];

    return (
      <div style={row.style} key={row.key}>
        <EditableListItem
          field={field}
          onInputChange={value => handleInputChange(field.id, value)}
          onDeleteClick={() => handleDelete(field.id)}
        />
      </div>
    );
  };

  const listHeight = list.length < 3 ? list.length * ROW_HEIGHT : window?.innerHeight - 167;

  return (
    <ListWrapper
      title="Добавление полей"
      footer={<FieldsCreateListFooter />}
      dataTestId="fields-add-section"
    >
      {Boolean(list.length) && (
        <List
          width={284}
          height={listHeight}
          row
          rowCount={list.length}
          rowHeight={ROW_HEIGHT}
          rowRenderer={rowRender}
        />
      )}

      <DrawInfoPlaceholder fieldsCount={list.length} />
    </ListWrapper>
  );
};

export default observer(FieldsCreateList);
