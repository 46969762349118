import { useMemo } from 'react';

import { Field } from '../../../../../api/models/field.model';
import { MapCoreController } from '../../../../shared/features/map/modules';
import { BaseLayerGroup, BasePolygon } from '../../../../shared/features/map/utils/MapElements';
import { useStore } from '../../../../shared/utils/IoC';

function useFieldMapPolygon(fieldOrPolygon: Field | BasePolygon) {
  const mapCoreController = useStore(MapCoreController);

  return useMemo(() => {
    if (fieldOrPolygon instanceof BasePolygon) {
      return fieldOrPolygon;
    }

    const mapElement = mapCoreController.getMapLayerById(fieldOrPolygon?.polyId);

    if (mapElement instanceof BaseLayerGroup) {
      return mapElement.getMainPolygon();
    }

    if (mapElement instanceof BasePolygon) {
      return mapElement;
    }

    return null;
  }, [fieldOrPolygon]);
}

export default useFieldMapPolygon;
