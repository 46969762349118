import React, { MouseEvent, memo, useCallback, forwardRef } from 'react';
import { AutoTooltip, Icon } from '@farmlink/farmik-ui';

import { useDataTestId } from '../../../../utils/hooks/locators';

import Styled from './TableFiltersBuilderTag.styles';

interface IProps {
  id: string;
  label: string;
  isDisableDeleting?: boolean;
  onDeleteClick?: (id: string) => void;
  dataTestId?: string;
  noteText?: string;
  withNote?: boolean;
}

const TableFiltersBuilderTag = forwardRef<HTMLDivElement, IProps>(
  ({ id, label, isDisableDeleting, onDeleteClick, dataTestId, withNote, noteText }, ref) => {
    const handleDeleteClick = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();

        onDeleteClick?.(id);
      },
      [onDeleteClick]
    );

    const getDataTestId = useDataTestId(dataTestId || 'tag');

    return (
      <AutoTooltip
        dataTestId={`tasks-table-days-left-cell-${id}`}
        content={noteText}
        disabled={!withNote}
        isKeepOpenOnMouseOverBody
      >
        <Styled.Wrapper ref={ref} {...getDataTestId()}>
          {withNote && <Icon size={'medium'} icon={'info'} />}
          <Styled.Label {...getDataTestId('label')}>{label}</Styled.Label>

          {!isDisableDeleting ? (
            <Styled.CloseButton onClick={handleDeleteClick} {...getDataTestId('close-button')} />
          ) : null}
        </Styled.Wrapper>
      </AutoTooltip>
    );
  }
);

TableFiltersBuilderTag.displayName = 'TableFiltersBuilderTag';

export default memo(TableFiltersBuilderTag);
