import { observer } from 'mobx-react';
import { ButtonLink, NewScrollbar, Typography } from '@farmlink/farmik-ui';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistInstancesStore as store } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './History.styles';

const SOURCE_MAP: Record<string, { text: string; icon: React.ReactNode }> = {
  WEB: { text: 'Через web-браузер', icon: <Styled.DesktopIcon /> },
  MOBILE: { text: 'C мобильного устройства', icon: <Styled.MobileIcon /> },
};

const LENGTH_TO_EXPAND = 3;

const History: React.FC = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getDataTestId = useDataTestIdV2('checklists__point-history');

  const { selectedInstanceEventList, selectedInstance } = useStore(store);

  const isExpandable = selectedInstanceEventList?.length > LENGTH_TO_EXPAND;
  const itemsLeft = selectedInstanceEventList?.length - 3;

  useEffect(() => {
    setIsExpanded(false);
  }, [selectedInstance]);

  const eventList = useMemo(() => {
    if (!isExpandable) {
      return selectedInstanceEventList;
    } else {
      return isExpanded
        ? selectedInstanceEventList
        : selectedInstanceEventList.slice(0, LENGTH_TO_EXPAND);
    }
  }, [selectedInstanceEventList, isExpanded, isExpandable]);

  const historyHeight = useMemo(() => (eventList.length < 10 ? 'auto' : '308px'), [eventList]);

  const onExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Styled.ScrollWrapper historyHeight={historyHeight}>
        <NewScrollbar
          isDisabled={historyHeight === 'auto' || !isExpandable || (isExpandable && !isExpanded)}
          scrollbarPaddingOffset={8}
          scrollbarRightPosition={0}
          isAutoResize
        >
          <Styled.List {...getDataTestId()}>
            {eventList?.map((event, i) => {
              const data = SOURCE_MAP[event.source];
              const isFirst = i === 0;

              return (
                <Styled.Item key={`${event.creationDate}_${i}`} {...getDataTestId('record')}>
                  <Styled.Icon {...getDataTestId('record__icon')}>{data.icon}</Styled.Icon>

                  <Typography
                    variant="body"
                    dataTestId={getDataTestId('record__text')['data-test-id']}
                  >
                    {data.text ?? 'С неопознанного устройства'}
                  </Typography>

                  <Styled.Date {...getDataTestId('record__date-wrapper')}>
                    <Typography
                      variant="body"
                      color="secondaryDark"
                      dataTestId={getDataTestId('record__date')['data-test-id']}
                    >
                      {moment(event.creationDate).format('DD MMMM YYYY в HH:mm')}
                    </Typography>
                  </Styled.Date>

                  {isFirst && (
                    <Styled.LastElementWrapper {...getDataTestId('btn-wrapper')}>
                      {children}
                    </Styled.LastElementWrapper>
                  )}
                </Styled.Item>
              );
            })}
          </Styled.List>
        </NewScrollbar>
      </Styled.ScrollWrapper>
      <Styled.ExpandButton>
        {isExpandable && (
          <ButtonLink color="secondary" onClick={onExpand}>
            {isExpanded ? 'Свернуть' : `Показать ещё: ${itemsLeft}`}
          </ButtonLink>
        )}
      </Styled.ExpandButton>
    </>
  );
};

export default observer(History);
