import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import {
  CulturesLegendMapControl,
  GoogleSearchMapControl,
  LayerFillMapControl,
  LocationMapControl,
  ZoomMapControl,
} from '../../../../../shared/features/map/components';
import { EMapControlPlace } from '../../../../../shared/features/map/components/Controls';
import { useStore } from '../../../../../shared/utils/IoC';
import FieldsLayout from '../../FieldsLayout';

import { FieldEditController as Controller, FieldEditStore as Store } from './mobx';
import useNotifications from './hooks/useNotifications';
import FieldEditList from './components/List/List';

const FieldEditPage: FC = () => {
  // Навигация
  const { fieldId } = useParams<{ fieldId: string }>();

  // Сторы
  const store = useStore(Store);

  // Контроллеры
  const controller = useStore(Controller);

  useNotifications();

  useEffect(() => {
    if (!fieldId) {
      return;
    }

    void controller.initialize(fieldId);

    return () => {
      controller.destroy();
    };
  }, []);

  return (
    <FieldsLayout
      id={'fields-view-map'}
      leftPanel={<FieldEditList field={store.editableField} />}
      map={{
        config: {
          controls: [
            { id: 'search', place: EMapControlPlace.TOP, component: <GoogleSearchMapControl /> },
            {
              id: 'fill',
              place: EMapControlPlace.TOP,
              component: <LayerFillMapControl />,
            },
            {
              id: 'culturesLegend',
              place: EMapControlPlace.TOP,
              component: <CulturesLegendMapControl />,
              hidden: true,
            },
            { id: 'zoom', place: EMapControlPlace.RIGHT, component: <ZoomMapControl /> },
            { id: 'location', place: EMapControlPlace.RIGHT, component: <LocationMapControl /> },
          ],
          pointCoordinatesControl: true,
        },
      }}
    />
  );
};

export default observer(FieldEditPage);
