import { ESpaceSize } from '@farmlink/farmik-ui';
import styled, { CSSObject } from 'styled-components';

import { FontSizesObj } from '../../../../../../../../../../../shared/constans/sizes';

const ListItem = styled('div')({
  display: 'flex',
  gap: ESpaceSize.S,
});

const AreaLabel = styled('span')({
  ...FontSizesObj.medium,
  whiteSpace: 'nowrap',
});

const ListCardStyle: CSSObject = {
  padding: 0,
  height: 40,
  borderBottom: 'none',

  '&:hover': {
    backgroundColor: 'none',
  },
};

const Styles = {
  ListItem,
  ListCardStyle,
  AreaLabel,
};

export default Styles;
