import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TaskOperationCreateStore as Store } from '../../stores';

@provide.transient()
class TaskOperationCreateValidationsService {
  @lazyInject(Store)
  protected store: Store;

  public validateCommonInfoForm = (): boolean => {
    const hasEmptyCulture = !this.store.taskCreate?.cultureId;
    const hasEmptyOperation = !this.store.taskCreate?.operationId;
    const hasEmptyConfig: boolean = this.store.config
      ? !this.store.taskCreate?.operationValues?.length
      : false;

    if (hasEmptyCulture) this.store.setTaskPropWithError('cultureId');
    if (hasEmptyOperation) this.store.setTaskPropWithError('operationId');
    if (hasEmptyConfig) this.store.setTaskPropWithError('operationValues');

    return hasEmptyCulture || hasEmptyOperation || hasEmptyConfig;
  };

  public validateFieldsForm = (): boolean => {
    const isFieldsTaskItemsEmpty = !this.store.taskCreate.taskItems.length;

    if (isFieldsTaskItemsEmpty) {
      this.store.setTaskPropWithError('taskItems');
    } else {
      this.store.deleteTaskPropWithError('taskItems');
    }

    return isFieldsTaskItemsEmpty;
  };
}

export default TaskOperationCreateValidationsService;
