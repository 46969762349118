import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,

  display: 'flex',
  alignItems: 'center',
  padding: ESpaceSize.M,
  gap: 8,

  backgroundColor: Colors.generalWhite,
});

const Styles = {
  Wrapper,
};

export default Styles;
