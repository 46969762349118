import styled, { CSSProperties, css } from 'styled-components';

import { ITableBuilderColumnsWidth as IColumnsWidth } from '../../../models/data';

const getGridTemplateColumnsByScreenSize = (columnsWidth: IColumnsWidth) => {
  return css`
    @media only screen and (min-width: 1920px) {
      grid-template-columns: ${columnsWidth.s1920};
    }

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      grid-template-columns: ${columnsWidth.s1366};
    }

    @media only screen and (max-width: 1365px) {
      grid-template-columns: ${columnsWidth.s1024};
    }
  `;
};

const Wrapper = styled.div<{
  $columnsWidth: IColumnsWidth;
  $customGridStyles: CSSProperties;
}>`
  display: grid;
  align-items: center;

  ${({ $columnsWidth }) => getGridTemplateColumnsByScreenSize($columnsWidth)};

  ${({ $customGridStyles }) => $customGridStyles as {}};

  & > :not(:last-child) {
    padding-right: 16px;
  }
`;

const StyledTableBuilderGrid = {
  Wrapper,
};

export default StyledTableBuilderGrid;
