import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { sortBy } from 'lodash';

import { useStore } from '../../../../../../../../shared/utils/IoC';
import { ChecklistsStore } from '../../../mobx/stores';
import { ChecklistsController } from '../../../mobx/controllers';
import { TChecklistsChecklistAttrToDraw as TChecklistAttrToDraw } from '../../../models';
import {
  ChecklistsAttr as Attribute,
  ChecklistsButton as Button,
  ChecklistsContent as Content,
} from '../../../components/elements';
import {
  EChecklistAttributeWidth,
  IGetChecklistAttribute as IAttribute,
} from '../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { useTasksParams } from '../../../../../hooks';
import { useSortBy } from '../../../../../../../../shared/features/utils/hooks/sort';
import { EChecklistMode } from '../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './ChecklistsChecklistAttr.styled';
import { ChecklistsChecklistAttrInstance as Instance } from './components';

interface IProps {
  attrToDraw: TChecklistAttrToDraw;
}

const ChecklistsChecklistAttr: FC<IProps> = ({
  attrToDraw: { id, groupId, stageId, initialModel },
}) => {
  const getDataTestId = useDataTestIdV2('checklists__checklist-link-attribute');

  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const params = useTasksParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [attrList, setAttrList] = useState<IAttribute[]>([]);

  const instanceToDrawList = useSortBy(checklistsStore.getNestedInstanceToDrawListByAttrId(id));

  useEffect(() => {
    (async () => {
      const fetchedAttrList = await checklistsController.fetchAttrListByPublicId({
        publicId: initialModel.attribute.checklistLinkPublicId,
        size: 2000,
        taskId: params.taskId,
        stageId,
        activeOnly: true,
      });

      const orderedAttrList = sortBy(fetchedAttrList, 'order');

      setAttrList(orderedAttrList);
      checklistsController.addInitialNestedInstanceList(stageId, id, orderedAttrList);

      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (instanceToDrawList.length) {
      return;
    }

    checklistsController.addTemplateNestedInstance(groupId, id, attrList);
  }, [instanceToDrawList.length, isLoading]);

  const visibilityResult = initialModel.visibility
    ? checklistsController.calculateAttrVisibility(groupId, id)
    : { value: true };

  useEffect(() => {
    checklistsController.toggleAttrVisibility(groupId, id, visibilityResult.value as boolean);
  }, [JSON.stringify(visibilityResult)]);

  const isShowAddInstanceButton = useMemo<boolean>(() => {
    const isEditMode = checklistsStore.mode === EChecklistMode.Edit;

    if (!isEditMode) {
      return false;
    }

    if (!instanceToDrawList.length) {
      return false;
    }

    const hasEditInstance = instanceToDrawList.some(
      instance => instance.isEditing || instance.isTemplate
    );

    if (hasEditInstance) {
      return false;
    }

    return Boolean(initialModel.attribute.isMultiselect);
  }, [checklistsStore.mode, instanceToDrawList]);

  const handleAddInstanceButtonClick = useCallback(() => {
    checklistsController.addTemplateNestedInstance(groupId, id, attrList);
  }, [attrList]);

  const totalAddedInstanceNumber = useMemo(() => {
    return instanceToDrawList.filter(inst => !inst.isTemplate).length;
  }, [instanceToDrawList]);

  if (!visibilityResult.value) {
    return <></>;
  }

  return (
    <Attribute width={EChecklistAttributeWidth.Full} dataTestId={getDataTestId()['data-test-id']}>
      {!isLoading ? (
        <Styled.Wrapper {...getDataTestId('nested-records')}>
          {instanceToDrawList.map((instance, index) => (
            <Instance
              key={instance.id}
              instanceToDraw={instance}
              totalInstancesNumber={totalAddedInstanceNumber}
              recordNumber={index + 1}
            />
          ))}

          {isShowAddInstanceButton ? (
            <Content dataTestId={getDataTestId('button-wrapper')['data-test-id']}>
              <Attribute width={EChecklistAttributeWidth.Half}>
                <Button
                  onClick={handleAddInstanceButtonClick}
                  dataTestId={getDataTestId('button')['data-test-id']}
                >
                  Добавить ещё
                </Button>
              </Attribute>
            </Content>
          ) : null}
        </Styled.Wrapper>
      ) : (
        <div>LOADING...</div>
      )}
    </Attribute>
  );
};

ChecklistsChecklistAttr.displayName = 'ChecklistsChecklistAttr';

export default observer(ChecklistsChecklistAttr);
