import { Button, Colors } from '@farmlink/farmik-ui';
import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { MapDrawerController } from '../../../../../../../../../shared/features/map/modules';
import { useWarningBeforeLeavingThePage } from '../../../../../../../../../shared/hooks';
import CulturesAPIService from '../../../../mobx/services/FieldCulturesAPI.service';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import Styled from '../SeasonsList/SeasonsList.styles';
import { useStoInfoModal } from '../../../../hooks';
import Header from '../Header/Header';
import {
  FieldSeasonsViewStore as Store,
  FieldCulturesStore as CulturesStore,
  FieldCulturesController as CulturesController,
  FieldSeasonsViewController as SeasonsViewController,
} from '../../../../mobx';

import SowingHarvestDatePickers from './components/SowingHarvestDatePickers/SowingHarvestDatePickers';
import ExperimentsSwitch from './components/ExperimentSwitch/ExperimentSwitch';
import CultureSelect from './components/CultureSelect/CultureSelect';
import NameInput from './components/NameInput/NameInput';
import SortInput from './components/SortInput/SortInput';
import AreaInput from './components/AreaInput/AreaInput';
import Style from './EditCultureZone.styles';

const FieldSeasonsEditCultureZone: FC = () => {
  // Сторы
  const store = useStore(Store);
  const culturesStore = useStore(CulturesStore);

  // Контроллеры
  const culturesController = useStore(CulturesController);
  const mapDrawerController = useStore(MapDrawerController);
  const seasonsViewController = useStore(SeasonsViewController);

  // Сервисы
  const culturesAPIService = useStore(CulturesAPIService);

  const warningConfirm = useWarningBeforeLeavingThePage();
  const stoInfoModal = useStoInfoModal(store.selectedSeason);
  const getDataTestId = useDataTestIdV2('field-season-edit-zone');

  const zone = culturesStore.editableCultureZone;
  const isCultureSelected = zone && zone?.culture?.id !== '';
  const isSaveDisabled = !isCultureSelected;

  const handleBackClick = () => {
    warningConfirm.showWarningBeforeLeavingThePage(() => {
      const polygon = culturesController.getEditablePolygon();

      mapDrawerController.disableDrawPolygon();
      mapDrawerController.disableEditPolygon(polygon);

      culturesController.restoreSnapshot();

      culturesStore.setEditableCultureZone(null);
    });
  };

  const handleSaveClick = () => {
    const season = store.selectedSeason;

    if (!zone.culture?.name) {
      return;
    }

    culturesAPIService.saveCultureZones(season).then(() => {
      const polygon = culturesController.getEditablePolygon();

      mapDrawerController.disableDrawPolygon();
      mapDrawerController.disableEditPolygon(polygon);

      culturesStore.setEditableCultureZone(null);

      stoInfoModal.open();

      void seasonsViewController.refetchSeasons();
    });
  };

  const getControlDataTestId = (type: string) => {
    return getDataTestId(`${type}-control`)['data-test-id'];
  };

  return (
    <Styled.Wrapper $backgroundColor={Colors.generalWhite} {...getDataTestId('wrapper')}>
      <Header
        title={store.field?.name}
        subtitle={store.selectedSeason?.season.label}
        onBackClick={handleBackClick}
        mode="edit"
      />

      <Style.Body>
        <NameInput cultureZone={zone} dataTestId={getControlDataTestId('name')} />
        <AreaInput cultureZone={zone} dataTestId={getControlDataTestId('area')} />
        <CultureSelect cultureZone={zone} dataTestId={getControlDataTestId('cultures')} />
        <SortInput cultureZone={zone} dataTestId={getControlDataTestId('sort')} />
        <SowingHarvestDatePickers cultureZone={zone} dataTestId={getControlDataTestId('picker')} />
        <ExperimentsSwitch cultureZone={zone} dataTestId={getControlDataTestId('experiment')} />
      </Style.Body>

      <Style.Footer {...getDataTestId('footer')}>
        <Button
          isLoading={culturesStore.isLoading}
          disabled={isSaveDisabled}
          color={'primary'}
          onClick={handleSaveClick}
          dataTestId={getDataTestId('save-btn')['data-test-id']}
        >
          Сохранить
        </Button>
      </Style.Footer>
    </Styled.Wrapper>
  );
};

export default observer(FieldSeasonsEditCultureZone);
