import React, { FC } from 'react';
import { ButtonLink } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { StoChecklistsCoreController as Controller } from '../../mobx/controllers';
import { useStoNavigation, useStoParams } from '../../../../../../../../../../hooks';
import { StoStore } from '../../../../../../../../mobx';

interface IProps {
  stageId: string;
  attributeId: string;
}

const StoChecklistsSettingsCell: FC<IProps> = ({ stageId, attributeId }) => {
  const stoStore = useStore(StoStore);
  const controller = useStore(Controller);

  const params = useStoParams();
  const navigations = useStoNavigation();

  const isChecklistSelected = controller.checkIfChecklistIsSelected(params.checklistId);
  const isAttrSelected = controller.checkIfAttrIsSelected(params.checklistId, stageId, attributeId);
  const isUnsaved = controller.checkIfAttrIsUnsaved(params.checklistId, stageId, attributeId);

  const goToAttribute = (): void => {
    navigations.navigateToAttribute(
      { ...params, stageId, attributeId },
      {
        query: { ...(params.versionId && { versionId: params.versionId }) },
      }
    );
  };

  const label = params.versionId || stoStore.isViewOnly ? 'Посмотреть' : 'Настроить';

  return (
    <ButtonLink
      color={!isChecklistSelected || !isAttrSelected ? 'secondary' : 'accent'}
      icon={{
        type: 'arrow-right',
        placement: 'right',
      }}
      onClick={goToAttribute}
      isDisabled={isUnsaved}
    >
      {label}
    </ButtonLink>
  );
};

StoChecklistsSettingsCell.displayName = 'StoChecklistsSettingsCell';

export default observer(StoChecklistsSettingsCell);
