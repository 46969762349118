import { CSSObject } from 'styled-components';

const DisabledWrapperStyles: CSSObject = {
  '& *': {
    opacity: 0.8,
  },
};

const Styles = {
  DisabledWrapperStyles,
};

export default Styles;
