import React, { FC, memo } from 'react';
import { isFunction } from 'lodash';

import { useContainerDataTestId as useDataTestId } from '../../hooks';

import { TContainerHeader as THeader } from './types';
import {
  ContainerHeaderCleared as HeaderCleared,
  ContainerHeaderDefault as HeaderDefault,
} from './components';
import { EContainerHeaderType as EHeaderType } from './constants';

interface IHeaderProps {
  header: THeader;
}

const ContainerHeader: FC<IHeaderProps> = ({ header }) => {
  const getDataTestId = useDataTestId();

  const formattedDataTestId = getDataTestId('header')['data-test-id'];

  if (isFunction(header)) {
    return (
      <>
        {header({
          dataTestId: formattedDataTestId,
        })}
      </>
    );
  }

  if (header.type === EHeaderType.Default) {
    return <HeaderDefault dataTestId={formattedDataTestId} header={header} />;
  }

  if (header.type === EHeaderType.Cleared) {
    return <HeaderCleared dataTestId={formattedDataTestId} header={header} />;
  }

  return <></>;
};

ContainerHeader.displayName = 'ContainerHeader';

export default memo(ContainerHeader);
