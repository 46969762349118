import React, { FC, memo, PropsWithChildren } from 'react';
import { CSSProperties } from 'styled-components';

import { TContainerHeader as THeader } from './containers/ContainerHeader/types';
import Styled from './Container.styles';
import { ContainerHeader as Header } from './containers';
import { useContainerDataTestId as useDataTestId } from './hooks';
import { ContainerContext as Context } from './context';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__container.
   */
  dataTestId: string;
  header?: THeader;
  containerStyles?: CSSProperties;
  bodyStyles?: CSSProperties;
}

const Container: FC<PropsWithChildren<IProps>> = ({
  dataTestId,
  header,
  containerStyles,
  bodyStyles,
  children,
}) => {
  const getDataTestId = useDataTestId(dataTestId);

  return (
    <Context.Provider value={{ dataTestId }}>
      <Styled.Wrapper {...getDataTestId()} $containerStyles={containerStyles}>
        <Styled.HeaderWrapper {...getDataTestId('header-wrapper')}>
          {header ? <Header header={header} /> : null}
        </Styled.HeaderWrapper>

        <Styled.Body {...getDataTestId('body')} $bodyStyles={bodyStyles}>
          {children}
        </Styled.Body>
      </Styled.Wrapper>
    </Context.Provider>
  );
};

Container.displayName = 'Container';

export default memo(Container);
