import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../shared/utils/IoC';

@provide.singleton()
class WeatherStore {
  private _isOpen = false;

  private _isLoading = false;

  private _fieldWeather: any = null;

  private _fieldWeatherForecasts: any = null;

  // Указывает текущее положение скролла. Используется в BackButton комоненте для определения fixed позиции
  private _sliderScrollTop = 0;

  // Используется для запуска анимации полявления слайдера
  private _isFullWidth = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isOpen() {
    return this._isOpen;
  }

  set isOpen(value) {
    this._isOpen = value;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  get fieldWeather() {
    return this._fieldWeather;
  }

  set fieldWeather(value) {
    this._fieldWeather = value;
  }

  get fieldWeatherForecasts() {
    return this._fieldWeatherForecasts;
  }

  set fieldWeatherForecasts(value) {
    this._fieldWeatherForecasts = value;
  }

  get sliderScrollTop() {
    return this._sliderScrollTop;
  }

  set sliderScrollTop(value) {
    this._sliderScrollTop = value;
  }

  get isFullWidth() {
    return this._isFullWidth;
  }

  set isFullWidth(value) {
    this._isFullWidth = value;
  }
}

export default WeatherStore;
