import styled from 'styled-components';

const ListWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export default {
  ListWrapper,
};
