import { ERequestStatus } from '../../../../../../../../../shared/constants/requests';
import { StoService } from '../../../../../../../../../shared/mobx/services/as-fields';
import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import { StoStore } from '../../../../mobx';
import { StoHistoryStore } from '../stores';

@provide.transient()
class StoHistoryService {
  @lazyInject(StoStore)
  protected store: StoStore;

  @lazyInject(StoHistoryStore)
  protected historyStore: StoHistoryStore;

  @lazyInject(StoService)
  protected service: StoService;

  fetchStoHistory = (stoId?: string) => {
    if (!Boolean(stoId || this.store.selectedStoId)) return Promise.reject(new Error('NO_STO_ID'));

    this.historyStore.setHistoryVersionFetchStatus(ERequestStatus.Pending);

    return this.service
      .getStoVersionHistory({ id: stoId || this.store.selectedStoId })
      .then(data => {
        this.historyStore.setStoHistoryVersion(data);

        this.historyStore.setHistoryVersionFetchStatus(ERequestStatus.Fulfilled);

        return data;
      })
      .catch(() => {
        this.historyStore.setHistoryVersionFetchStatus(ERequestStatus.Rejected);
      });
  };
}

export default StoHistoryService;
