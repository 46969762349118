import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';
import { Breakpoints, FontSizes } from '../../../../../../../../../shared/constans/sizes';

export const PlantProtectionSection = styled.div`
  background: ${Colors.white};
  /* box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1); */
  border-radius: 16px;
  /* min-height: 224px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 32px; */
  margin-bottom: 24px;

  @media (max-width: ${Breakpoints.small}) {
    padding: 32px 16px;
  }
`;

export const PlantProtectionHeader = styled.div`
  height: 64px;
  width: 100%;
  padding: 32px 32px 12px 32px;
  border-bottom: 0.5px solid ${Colors.grayBorder};
  display: flex;

  p {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    ${FontSizes.largeBold}
    color: ${Colors.black};
    margin-right: 9px;
  }

  span {
    @media (max-width: ${Breakpoints.large}) {
      display: inline-block;
    }
    display: none;
  }
`;

export const PlantProtectionContent = styled.div`
  display: flex;
  margin: 20px 0 32px;
  padding: 0 32px;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

export const PlantProtectionContentTimeLineWrapper = styled.div`
  max-width: calc(100vw - 289px);
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  @media (max-width: ${Breakpoints.small}) {
    max-width: 100%;
  }
`;

export const PlantProtectionContentTimeLine = styled.div`
  @media (max-width: ${Breakpoints.large}) {
    margin-bottom: 0;
  }
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-gap: 2px;
  margin-bottom: 32px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  @media (max-width: ${Breakpoints.large}) {
    grid-template-columns: repeat(24, minmax(38px, 1fr));
  }
`;

type PlantProtectionTimePartType = {
  type: 'common' | 'green' | 'yellow' | 'red';
};

const renderColor = (color: string) => {
  const variants = {
    common: Colors.lightnessGray,
    green: Colors.green,
    yellow: Colors.yellow,
    red: Colors.red,
  };

  return variants[color as PlantProtectionTimePartType['type']];
};

export const PlantProtectionTimePart = styled.div<PlantProtectionTimePartType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${Colors.darkGray};
    margin: 0 0 8px;
  }
  div {
    /* width: 38px; */
    height: 8px;
    background: ${({ type }) => renderColor(type)};
  }

  @media (max-width: ${Breakpoints.large}) {
    p {
      color: ${({ type }) => (type === 'green' ? Colors.white : Colors.black)};
    }
  }
`;

export type PlantProtectionStyledProps = {
  disabled?: boolean;
};

const PlantProtectionControl = styled.div<PlantProtectionStyledProps>`
  height: 32px;
  width: 32px;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: ${Colors.white};
  top: -8px;
  /* filter: ${({ disabled }) =>
    disabled ? 'none' : 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.08))'}; */
  svg * {
    fill: ${({ disabled }) => (disabled ? Colors.darkGray : Colors.black)} !important;
  }
  @media (max-width: ${Breakpoints.large}) {
    display: flex;
  }
`;

export const PlantProtectionLeftControl = styled(PlantProtectionControl)`
  left: 0;
  z-index: 10;
  cursor: pointer;
`;

export const PlantProtectionRightControl = styled(PlantProtectionControl)`
  right: 0;
  z-index: 10;
  cursor: pointer;
`;

export const PlantProtectionContentDescription = styled.div`
  @media (max-width: ${Breakpoints.large}) {
    display: none;
  }
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.darkGray};
  margin: 0;
`;
