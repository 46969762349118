import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { DeleteFieldModal } from '../components';

const FIELDS_COMMON_MODALS_UNIQUE_KEY = 'fieldsCommonModals';

enum EFieldsCommonModalName {
  DeleteFieldWarning = 'DELETE_FIELD_WARNING',
  IntersectionWarning = 'INTERSECTION_WARNING',
  OversizeAreaWarning = 'OVERSIZE_AREA_WARNING',
}

const deleteFieldModalConfig: TModalConfig = {
  name: EFieldsCommonModalName.DeleteFieldWarning,
  title: '',
  type: EModalType.Custom,
  children: DeleteFieldModal,
  id: EFieldsCommonModalName.DeleteFieldWarning,
  styledProps: {
    $modalPaddings: '50px',
    $isFullContentWidth: true,
  },
  dataTestId: EFieldsCommonModalName.DeleteFieldWarning,
};

const intersectionWarningConfig: TModalConfig = {
  id: EFieldsCommonModalName.IntersectionWarning,
  name: EFieldsCommonModalName.IntersectionWarning,
  dataTestId: EFieldsCommonModalName.IntersectionWarning,
  title: 'Границы полей не могут пересекаться. Измените границы для сохранения изменений!',
  type: EModalType.Warning,
  successButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};

const oversizedAreaWarningConfig: TModalConfig = {
  id: EFieldsCommonModalName.OversizeAreaWarning,
  name: EFieldsCommonModalName.OversizeAreaWarning,
  dataTestId: EFieldsCommonModalName.OversizeAreaWarning,
  title:
    'Площадь поля не может быть больше 3000 га. Уменьшите площадь поля для сохранения изменений!',
  type: EModalType.Warning,
  denyButton: {
    title: 'Продолжить',
    color: 'primary',
  },
};

const fieldsCommonModalConfigList = [
  deleteFieldModalConfig,
  intersectionWarningConfig,
  oversizedAreaWarningConfig,
];

export { fieldsCommonModalConfigList, FIELDS_COMMON_MODALS_UNIQUE_KEY, EFieldsCommonModalName };
