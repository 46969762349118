import { Axios } from '../../../../../../../shared/utils/axios2';
import conditionalObjectProp from '../../../../../../../shared/utils/conditionalObjectProp';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../../stores/seasons.store';
import { IChangedFields, IDeletedFields } from '../../interfaces/Multiselect.interface';

@provide.transient()
class MultiselectAPIService {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(OrganizationsStore)
  protected organizationStore: OrganizationsStore;

  public updateCultures(fields: IChangedFields) {
    const organizationId = this.organizationStore.getOrganizationId({ skipDefault: true });

    const fieldsDto = fields.fieldsIds.map(id => ({
      fieldId: id,
      cultureId: fields.newCultureId,
    }));

    return this.axios.api
      .updateCultures({
        fields: fieldsDto,
        seasonYear: Number(this.seasonsStore.selectedSeason),
        ...conditionalObjectProp(Boolean(organizationId), { organizationId }),
      })
      .catch(() => null);
  }

  public deleteCultures(fields: IDeletedFields) {
    const organizationId = this.organizationStore.getOrganizationId({ skipDefault: true });

    return this.axios.api
      .deleteFieldsById({
        fields: fields.fieldsIds,
        allSeasons: fields.fromAllSeasons,
        seasonYear: Number(this.seasonsStore.selectedSeason),
        ...conditionalObjectProp(Boolean(organizationId), { organizationId }),
      })
      .catch(() => null);
  }
}

export default MultiselectAPIService;
