import { Colors, ESpaceSize, IconButton } from '@farmlink/farmik-ui';
import { IColorScheme } from '@farmlink/farmik-ui/dist/IconButton/IconButton.styles';
import styled from 'styled-components';

const ControlsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'end',
  padding: '10px 12px',
  marginTop: ESpaceSize.S,
});

const ApplyIcon = styled(IconButton)({
  marginLeft: ESpaceSize.S,
  border: `1px solid #3FB65F26`,
});

const applyIconColorScheme: IColorScheme = {
  default: {
    icon: Colors.accentGreen,
    background: Colors.lightGreen,
  },
  hover: {
    icon: Colors.accentGreenHover,
    background: '#E2F4E7',
  },
  disabled: {
    icon: Colors.accentGreen,
    background: Colors.lightGreen,
  },
};

export default {
  ControlsWrapper,
  ApplyIcon,
  applyIconColorScheme,
};
