import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import DisableFieldModal from '../components/DisableFieldModal/DisableFieldModal';
import OverlapZoneModal from '../components/OverlapZoneModal/OverlapZoneModal';
import FieldsAddStoModal from '../components/AddStoModal/FieldsAddStoModal';

const FIELD_SEASONS_MODALS_UNIQUE_KEY = 'fieldsCreate';

enum EFieldSeasonsModalName {
  disableField = 'DISABLE_FIELD_MODAL',
  deleteField = 'DELETE_FIELD',
  resetField = 'RESET_FIELD',
  fieldAddSto = 'FIELD_ADD_STO',
  ZoneOverlap = 'ZONE_OVERLAP',
}

const disableFieldModalConfig: TModalConfig = {
  id: EFieldSeasonsModalName.disableField,
  name: EFieldSeasonsModalName.disableField,
  dataTestId: EFieldSeasonsModalName.disableField,
  title: '',
  type: EModalType.Custom,
  children: DisableFieldModal,
  styledProps: {
    $isFullContentWidth: true,
    $size: '700px',
  },
};

const deleteFieldModalConfig: TModalConfig = {
  id: EFieldSeasonsModalName.deleteField,
  name: EFieldSeasonsModalName.deleteField,
  dataTestId: EFieldSeasonsModalName.deleteField,
  title: 'Вы уверены, что хотите удалить участок?',
  type: EModalType.Warning_Before_Living,
  successButton: {
    title: 'Удалить',
    color: 'secondary',
  },
  denyButton: {
    title: 'Отменить',
    color: 'default',
  },
  styledProps: {
    $isFullContentWidth: true,
    $size: '700px',
  },
};

const resetFieldModalConfig: TModalConfig = {
  id: EFieldSeasonsModalName.resetField,
  name: EFieldSeasonsModalName.resetField,
  dataTestId: EFieldSeasonsModalName.resetField,
  title: 'Вы уверены, что хотите сбросить участок?',
  type: EModalType.Warning_Before_Living,
  successButton: {
    title: 'Сбросить',
    color: 'secondary',
  },
  denyButton: {
    title: 'Отменить',
    color: 'default',
  },
  styledProps: {
    $isFullContentWidth: true,
    $size: '700px',
  },
};

const fieldsAddStoModalModalConfig: TModalConfig = {
  id: EFieldSeasonsModalName.fieldAddSto,
  name: EFieldSeasonsModalName.fieldAddSto,
  dataTestId: 'fields__add-sto-modal',
  title: '',
  type: EModalType.Custom,
  children: FieldsAddStoModal,
  styledProps: {
    $modalPaddings: '40px 60px',
    $size: '440px',
  },
};

const zoneOverlapModalConfig: TModalConfig = {
  id: EFieldSeasonsModalName.ZoneOverlap,
  name: EFieldSeasonsModalName.ZoneOverlap,
  dataTestId: EFieldSeasonsModalName.ZoneOverlap,
  title: '',
  type: EModalType.Custom,
  children: OverlapZoneModal,
  styledProps: {
    $modalPaddings: '40px',
    $size: '440px',
  },
};

const fieldSeasonsModalConfigList = [
  disableFieldModalConfig,
  deleteFieldModalConfig,
  resetFieldModalConfig,
  fieldsAddStoModalModalConfig,
  zoneOverlapModalConfig,
];

export { fieldSeasonsModalConfigList, FIELD_SEASONS_MODALS_UNIQUE_KEY, EFieldSeasonsModalName };
