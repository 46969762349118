import { makeAutoObservable } from 'mobx';
import { isFunction } from 'lodash';

import { provide } from '../../../../../../../../shared/utils/IoC';
import { TStoreSetter } from '../../../../../../../../shared/types/mobx';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { ITaskEvent } from '../../../../../../../../../api/models/task/events/task.event.mode';

@provide.singleton()
class TaskOperationViewStore {
  private _selectedTask: ITask | null = null;

  private _statusPillEventList: ITaskEvent[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get selectedTask(): ITask {
    return this._selectedTask;
  }

  public get statusPillEventList(): ITaskEvent[] {
    return this._statusPillEventList;
  }

  public setSelectedTask: TStoreSetter<ITask> = value => {
    if (isFunction(value)) {
      this._selectedTask = value(this._selectedTask);
    } else {
      this._selectedTask = value;
    }
  };

  public setStatusPillEventList: TStoreSetter<ITaskEvent[]> = value => {
    if (isFunction(value)) {
      this._statusPillEventList = value(this._statusPillEventList);
    } else {
      this._statusPillEventList = value;
    }
  };

  public clearSelectedTask = (): void => {
    this._selectedTask = null;
  };

  public clearStatusPillEventList = (): void => {
    this._statusPillEventList = null;
  };
}

export default TaskOperationViewStore;
