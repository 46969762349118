import { Colors, ESpaceSize, IIconButtonColorScheme } from '@farmlink/farmik-ui';
import styled from 'styled-components';

interface IWrapperProps {
  isOpen: boolean;
}

const Wrapper = styled('div')({
  zIndex: 1,
  position: 'relative',
});

const BackBlock = styled('div')({
  height: '52px',
});

const LegendWrapper = styled('div')<IWrapperProps>(
  {
    position: 'absolute',
    top: 0,
    width: '100%',
    padding: ESpaceSize.M,

    backgroundColor: Colors.generalWhite,
    boxShadow: '0px 1px 4px 0px rgba(177, 173, 173, 0.25)',
    transition: 'height .4s ease',
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.10)',
    },
  },
  ({ isOpen }) => ({
    height: !isOpen ? '52px' : '224px',
  })
);

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const HeaderCaption = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Title = styled('span')({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
});

const ArrowIconWrapper = styled('div')<IWrapperProps>({}, ({ isOpen }) => ({
  '& > button': {
    transition: 'transform .4s ease',
    transform: `rotateZ(${isOpen ? '180deg' : '0deg'})`,
  },
}));

const ArrowBtnColorScheme: IIconButtonColorScheme = {
  default: {
    background: 'transparent',
    icon: Colors.generalBlack,
  },
  hover: {
    background: 'transparent',
    icon: Colors.accentGreen,
  },
};

const Styles = {
  Wrapper,
  BackBlock,
  LegendWrapper,
  Header,
  Title,
  HeaderCaption,
  ArrowIconWrapper,
  ArrowBtnColorScheme,
};

export default Styles;
