import { FC, FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { TChecklistsLongStingAttrToDraw as TLongStringAttrToDraw } from '../../../../models';
import { Input } from '../../../../../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList as useAttrErrorList,
  useChecklistsAttrVisibility as useAttrVisibility,
} from '../../../../hooks';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

const MAX_LONG_STRING_LENGTH = 4000;

interface IProps {
  attrToDraw: TLongStringAttrToDraw;
}

const ChecklistsLongStringAttr: FC<IProps> = ({ attrToDraw }) => {
  const { id, groupId, initialModel, value, isBlocked, dependentFileAttrId } = attrToDraw;

  const getDataTestId = useDataTestIdV2('checklists__long-string-attribute');

  const checklistsController = useStore(ChecklistsController);

  const [enteredValue, setEnteredValue] = useState(value.longStringValue);

  const isShowAttribute = useAttrVisibility(attrToDraw);
  const errorList = useAttrErrorList(attrToDraw);
  const placeholder = useAttrPlaceholder(initialModel.attribute);

  const errorString = useMemo(() => errorList.join('\n'), [errorList]);

  useEffect(() => {
    setEnteredValue(value.longStringValue);
  }, [value.longStringValue]);

  const debounceOnChange = useRef(
    _.debounce((newValue: string): void => {
      checklistsController.changeAttrValue(EAttrType.LongString, groupId, {
        ...value,
        longStringValue: newValue,
      });
    }, 500)
  );

  const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.target.scrollTo({ top: 0 });
  }, []);

  const handleChange = useCallback((newValue: string): void => {
    setEnteredValue(newValue);
    debounceOnChange.current(newValue);
  }, []);

  return (
    <>
      {isShowAttribute ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
          dataTestId={getDataTestId()['data-test-id']}
        >
          <Input
            value={enteredValue}
            onChange={handleChange}
            onBlur={handleBlur}
            label={initialModel.attribute?.name}
            placeholder={placeholder}
            tooltip={initialModel.toolTip}
            maxLength={MAX_LONG_STRING_LENGTH}
            as={'textarea'}
            height="100px"
            isRequired={initialModel.isRequired}
            isBlocked={isBlocked}
            isWithoutErrorText={true}
            hideScrollbar={true}
            isResizable={false}
            error={errorString}
            dataTestId={getDataTestId('input')['data-test-id']}
          />

          <CSSContainer
            display={'flex'}
            justifyContent={'space-between'}
            dataTestId={getDataTestId('additional-info')['data-test-id']}
          >
            <CSSContainer dataTestId={getDataTestId('error-wrapper')['data-test-id']}>
              <InputFieldError error={{ errorList }} />
            </CSSContainer>

            <FileAttrContainer
              groupId={groupId}
              attrId={dependentFileAttrId}
              isNeedAdjustableContainer
            />
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsLongStringAttr.displayName = 'ChecklistsLongStringAttr';

export default observer(ChecklistsLongStringAttr);
