import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';

import { Widget } from '../../../../../../../shared/components';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { StoStore } from '../../mobx';
import { TablePlugBuilder } from '../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { FieldSvg } from '../../../../../../../shared/assets/images/asComponent';
import { useStoParams } from '../../../../hooks';

import {
  AllFieldsSelectedPlugWrapper,
  FieldsTabListing,
  FieldsTabListingUsed,
  FieldsTabSearch,
} from './components';
import { StoFieldsTabController } from './mobx/controllers';
import { StoFieldsTabStore } from './mobx/stores';
import { transferFieldModalConfig } from './modals';

const StoFieldsTab: FC = () => {
  const { selectedSto, isViewOnly } = useStore(StoStore);
  const {
    clearStoFieldsTabStore,
    hasFieldsInAnotherSto,
    isFieldsFetchingCompleted,
    noFieldsToSelect,
  } = useStore(StoFieldsTabStore);
  const controller = useStore(StoFieldsTabController);

  const { registerModalList } = useModal();
  const { versionId } = useStoParams();

  const isBlocked = isViewOnly;

  const isAllFieldsInAnotherSto = isFieldsFetchingCompleted && noFieldsToSelect;

  useEffect(() => {
    registerModalList([transferFieldModalConfig], 'sto-tab-fields');

    return () => {
      clearStoFieldsTabStore();
    };
  }, []);

  useEffect(() => {
    if (!selectedSto?.id) {
      return;
    }

    controller.fetchFieldList({ versionId });
  }, [selectedSto?.id]);

  return (
    <>
      {isAllFieldsInAnotherSto ? (
        <AllFieldsSelectedPlugWrapper>
          <TablePlugBuilder
            autoRenderConfig={{
              header: {
                icon: <FieldSvg />,
              },
              content: {
                title: 'На все поля с данной культурой уже назначен другой СТО',
                description:
                  'Вы можете выбрать поля из списка ниже и переназначить на них текущий стандарт',
              },
            }}
          />
        </AllFieldsSelectedPlugWrapper>
      ) : (
        <Widget
          displayTitle
          title={`Поля: ${selectedSto ? selectedSto?.culture?.name?.toLowerCase?.() : 'культура'}`}
          dataTestId="sto-fields-tab"
        >
          <FieldsTabSearch isBlocked={isBlocked} />
          <FieldsTabListing isBlocked={isBlocked} />
        </Widget>
      )}

      {hasFieldsInAnotherSto && (
        <Widget
          displayTitle
          title={`Поля, выбранные в другом СТО: ${
            selectedSto ? selectedSto?.culture?.name?.toLowerCase?.() : 'культура'
          }`}
          dataTestId="sto-fields-tab-used"
        >
          <FieldsTabListingUsed isBlocked={isBlocked} />
        </Widget>
      )}
    </>
  );
};

export default observer(StoFieldsTab);
