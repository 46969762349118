import { computed, makeObservable, observable } from 'mobx';

import FiltersManager from './FilterManager';

/**
 * Абстрактный класс сортировки для FilterManager. В FilterManager может быть только одна сортировка.
 *
 * При обновлении переменной value автоматически сортирует manager.filteredList
 * Функция _setManager() является внутренней, не стоит использовать её извне
 */
abstract class Sorter<V = unknown> {
  private _value: V | null = null;
  protected _defaultValue: V | null = null;

  protected manager: FiltersManager;

  protected constructor(defaultValue: V | null = null) {
    makeObservable<Sorter, '_value' | '_defaultValue'>(this, {
      _value: observable,
      _defaultValue: observable,
      value: computed,
      isDefault: computed,
    });

    this._defaultValue = defaultValue;
    this._value = defaultValue;
  }

  public abstract _compareFn(a: any, b: any): number;
  public abstract resetValue(skipSorting?: boolean): void;

  public _setManager(manager: FiltersManager) {
    this.manager = manager;
  }

  public get isDefault() {
    return this._value === this._defaultValue;
  }

  public get value() {
    return this._value;
  }

  public setValue(value, skipSorting = false) {
    this._value = value;

    if (!skipSorting) {
      this.manager.filterSourceList('sorter');
    }
  }
}

export default Sorter;
