import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskOperationCreateController as Controller } from '../../../../mobx/controllers';

interface IProps {
  dataTestId: string;
}

const TaskOperationCreateAssigneeSelect: FC<IProps> = ({ dataTestId }) => {
  const controller = useStore(Controller);

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      onChange: null,
      defaultValue: controller.assigneeDefaultValue,
    },
    body: {
      optionList: [],
    },
    visual: {
      label: 'Исполнитель',
      isBlocked: true,
    },
    other: {
      dataTestId,
    },
  };

  return <NewDropdown config={dropdownConfig} />;
};

TaskOperationCreateAssigneeSelect.displayName = 'TaskOperationCreateAssigneeSelect';

export default observer(TaskOperationCreateAssigneeSelect);
