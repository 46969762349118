import { FC, memo, PropsWithChildren, useContext } from 'react';

import { ComparisonTableBuilderContext as Context } from '../../../context';

import Styled from './ComparisonTableBuilderSliderControls.styles';

const ComparisonTableBuilderSliderControls: FC<PropsWithChildren<{}>> = ({ children }) => {
  const context = useContext(Context);

  const handleArrowClick = (position: 'left' | 'right') => {
    if (position === 'right') {
      context.sliderControl.onArrowClick(position);
    } else {
      context.sliderControl.onArrowClick(position);
    }
  };

  const isShowControls =
    context.sliderControl.isShowControls && !context.sliderControl.isHideSliderControls;

  return (
    <Styled.Wrapper>
      {children}

      {isShowControls ? (
        <Styled.ArrowsWrapper>
          <Styled.ArrowWrapper
            onClick={() => handleArrowClick('left')}
            $isDisabled={context.sliderControl.isLeftArrowDisabled}
          >
            <Styled.ArrowLeft $isLeftArrowDisabled={context.sliderControl.isLeftArrowDisabled} />
          </Styled.ArrowWrapper>

          <Styled.ArrowWrapper
            onClick={() => handleArrowClick('right')}
            $isDisabled={context.sliderControl.isRightArrowDisabled}
          >
            <Styled.ArrowRight $isRightArrowDisabled={context.sliderControl.isRightArrowDisabled} />
          </Styled.ArrowWrapper>
        </Styled.ArrowsWrapper>
      ) : null}
    </Styled.Wrapper>
  );
};

ComparisonTableBuilderSliderControls.displayName = 'ComparisonTableBuilderSliderControls';

export default memo(ComparisonTableBuilderSliderControls);
