import { TypeApiRoute } from '../../../models/type.api.request';
import { IStoContent } from '../../../models/as-fields/STO/STO.model';

interface IRequest {
  stoId: string;
  cultureZoneId: string;
}

type TResponse = IStoContent;

export const addCultureZoneToSto: TypeApiRoute & {
  request: IRequest;
  response: TResponse;
} = {
  url: ({ stoId, cultureZoneId }) => `/api/as-fields/sto/${stoId}/addCultureZone/${cultureZoneId}`,
  method: 'PUT',
  headers: {},
  request: {} as IRequest,
  response: {} as TResponse,
};
