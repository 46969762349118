import { ISelectOption } from '../../../../../../types/selectOption';
import { CultureZone } from '../../../../../../api/models/field.model';
import { toDouble } from '../../../../../dashboard/modules/tasks/mobx/stores/TaskStore/Task.store';

const createZoneName = (zone: CultureZone): string => {
  if (zone.fieldName) {
    return `${zone.fieldName}. ${zone.culture.name}. ${toDouble(zone.area)} га`;
  }

  return `${zone.culture.name}. ${toDouble(zone.area)} га`;
};

const createCultureZoneSelectOption = (zone: CultureZone): ISelectOption<CultureZone> => {
  return { label: createZoneName(zone), value: zone.id };
};

const createCultureZoneSelectOptionList = (
  zoneList: CultureZone[]
): ISelectOption<CultureZone>[] => {
  return zoneList.map(createCultureZoneSelectOption);
};

export { createCultureZoneSelectOption, createCultureZoneSelectOptionList };
