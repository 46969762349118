import * as turf from '@turf/turf';

import { IDrawChecklistInstance } from '../../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { useStore } from '../../../../../../../../../../shared/utils/IoC';
import { InspectionPointsStore } from '../../../../../../../../tasks/components/InspectionPoints/mobx/stores';
import { ChecklistInstancesStore } from '../../../../../../../stores/checklist.instances.store';
import { TInstanceExtendedByClientId } from '../../ChecklistInstance';
import isPointInPolygon from '../../../../../../../../../../shared/features/map/utils/helpers/isPointInPolygon';

const DELETABLE_CLASSNAME = 'inspection-point_deletable';

const useChecklistInstanceActions = () => {
  const { getPointElement, setIsInspectionPointsChanged, zoneElement } = useStore(
    InspectionPointsStore
  );
  const { setDrawInst } = useStore(ChecklistInstancesStore);

  const markAsDeletable = (id: string, state: boolean) => {
    const point = getPointElement(id);

    if (point?.marker) {
      const { className } = point.marker.options.icon.options;

      if (state) {
        point.marker.setStyle({
          className: `${className} ${DELETABLE_CLASSNAME}`,
        });
      } else {
        point.marker.setStyle({
          className: className.replace(` ${DELETABLE_CLASSNAME}`, ''),
        });
      }
    }
  };

  const getUpdatedDrawInst = (
    instance: IDrawChecklistInstance,
    updatedInstance: TInstanceExtendedByClientId
  ): IDrawChecklistInstance => {
    const turfPoint = turf.point(updatedInstance.coordinates);
    return {
      ...instance,
      isMachinery: updatedInstance.type === 'MACHINERY',
      isField: updatedInstance.type === 'FIELD',
      instance: {
        ...instance.instance,
        pointOutOfField: zoneElement
          ? !isPointInPolygon(turfPoint, zoneElement.element.geometry)
          : false,
        ...(updatedInstance.type ? { type: updatedInstance.type } : {}),
        ...(updatedInstance?.coordinates
          ? {
              ...(instance.instance.planCoords
                ? {
                    planCoords: {
                      ...instance.instance.planCoords,
                      coordinates: updatedInstance.coordinates,
                      geometry: {
                        ...instance.instance.planCoords.geometry,
                        coordinates: updatedInstance.coordinates,
                      },
                    },
                  }
                : {
                    actualCoords: {
                      ...instance.instance.actualCoords,
                      coordinates: updatedInstance.coordinates,
                      geometry: {
                        ...instance.instance.actualCoords.geometry,
                        coordinates: updatedInstance.coordinates,
                      },
                    },
                  }),
            }
          : {}),
      },
    };
  };

  const updateDrawInst = (
    instance: IDrawChecklistInstance,
    updatedInstance: TInstanceExtendedByClientId
  ) => {
    const updatedDrawInst = getUpdatedDrawInst(instance, updatedInstance);

    setIsInspectionPointsChanged(true);

    setDrawInst(updatedDrawInst);
  };

  return { markAsDeletable, getUpdatedDrawInst, updateDrawInst };
};

export default useChecklistInstanceActions;
