import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const FIELDS_CREATE_MODALS_UNIQUE_KEY = 'fieldsCreate';

enum EFieldsCreateModalName {
  UnfinishedContourWarning = 'UNFINISHED_CONTOUR_WARNING',
}

const unfinishedContourWarningConfig: TModalConfig = {
  id: EFieldsCreateModalName.UnfinishedContourWarning,
  name: EFieldsCreateModalName.UnfinishedContourWarning,
  dataTestId: EFieldsCreateModalName.UnfinishedContourWarning,
  title: `Закончите контур, чтобы сохранить поле`,
  type: EModalType.Warning,
  denyButton: {
    title: 'Вернуться и закончить контур',
    color: 'primary',
  },
};

const fieldsCreateModalConfigList = [unfinishedContourWarningConfig];

export { fieldsCreateModalConfigList, FIELDS_CREATE_MODALS_UNIQUE_KEY, EFieldsCreateModalName };
