import { lazyInject, provide } from '../../../../../../../../../../../../shared/utils/IoC';
import {
  OperationsService,
  StoService,
} from '../../../../../../../../../../../../shared/mobx/services/as-fields';
import { StoOperationCoreStore as CoreStore } from '../../stores';
import { StoOperationHeaderService as HeaderService } from '../../services/StoOperationHeaderService';
import {
  EStoAction,
  EStoStatus,
  IStoContent,
} from '../../../../../../../../../../../../../api/models/as-fields/STO/STO.model';
import { StoErrorService, StoStore } from '../../../../../../../mobx';
import { StoChecklistsCoreStore } from '../../../modules/StoChecklists/mobx/stores';

@provide.transient()
class StoOperationCoreController {
  @lazyInject(StoStore)
  protected stoStore: StoStore;

  @lazyInject(CoreStore)
  protected coreStore: CoreStore;

  @lazyInject(StoChecklistsCoreStore)
  protected checklistCoreStore: StoChecklistsCoreStore;

  @lazyInject(StoService)
  protected stoService: StoService;

  @lazyInject(OperationsService)
  protected operationsService: OperationsService;

  @lazyInject(HeaderService)
  protected headerService: HeaderService;

  @lazyInject(StoErrorService)
  protected errorService: StoErrorService;

  public getHeaderTitle: HeaderService['createTitle'] = selectedOperationId => {
    return this.headerService.createTitle(selectedOperationId);
  };

  public getHeaderSubtitle: HeaderService['createSubtitle'] = () => {
    return this.headerService.createSubtitle();
  };

  public initiatePage = async ({
    selectedStoId,
    versionId,
  }: {
    selectedStoId: string;
    versionId?: string;
  }): Promise<IStoContent> => {
    try {
      const sto = await this.fetchSto(selectedStoId, versionId);

      const isViewOnly =
        sto.status === EStoStatus.Inactive || !sto.availableActions?.includes(EStoAction.StoEdit);

      this.stoStore.setIsViewOnly(isViewOnly);

      return sto;
    } catch (error) {
      throw this.errorService.parseStoFetchingErrorToCode(error);
    } finally {
      this.coreStore.setIsLoading(false);
    }
  };

  public clearStore = (): void => {
    this.coreStore.clearIsLoading();
    this.coreStore.clearSelectedSto();
  };

  public fetchSto = async (id: string, versionId?: string): Promise<IStoContent> => {
    const { setSelectedSto } = this.coreStore;
    const { getSto } = this.stoService;

    const sto = await getSto(
      { id, includeDrafts: true, ...(versionId && { versionId }) },
      {
        actions: {
          handleSuccess: response => {
            setSelectedSto(response);
            this.stoStore.setSelectedSto(response);
          },
        },
      }
    );

    return sto;
  };
}

export default StoOperationCoreController;
