import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

interface IWrapperProps {
  active?: boolean;
}

const Wrapper = styled('div')<IWrapperProps>(
  {
    display: 'flex',
    cursor: 'pointer',
    padding: '10px 12px',

    '&:hover': {
      backgroundColor: Colors.technicalBgGrey,
    },
  },
  ({ active }) => ({
    borderBottom: active ? `1px solid ${Colors.borderGray}` : 'none',
  })
);

export default {
  Wrapper,
};
