import moment, { Moment } from 'moment';

import { IndexModel } from '../../../../../../../../../../../../api/models/indices.model';

/**
 * Ищет ближайшую дату по месяцу. Если дат в нужном месяце несколько, то выбирает самую большую.
 * Если не найдено ни одной даты, то берет ближайшую к сегодняшнему дню дату из всего списка
 *
 * @example
 *  targetDate = 2022-03-12
 *  indicesList = [2022-01-01, 2022-02-12, 2022-03-01, 2022-03-30, 2023-04-01] -> в примере массив индексов упрощен до массива дат, по факту у индексов есть даты
 *  result = 2022-03-30 - по факту вернется индекс с такой датой
 */
function findNearestIndexByMonth(indicesList: IndexModel[], targetDate: Moment): IndexModel {
  const targetDateMonth = targetDate.get('month');

  let maxIndexByMonth: IndexModel | null = null;
  let maxIndex: IndexModel = indicesList[0];

  for (let i = 0; i < indicesList.length; i++) {
    const currIndexDate = moment(indicesList[i].toDate);

    // Высчитываем макс дату. Нужно для случая когда не найдено ни одной даты по месяцу
    maxIndex = currIndexDate.isAfter(moment(maxIndex.toDate)) ? indicesList[i] : maxIndex;

    if (currIndexDate.get('month') !== targetDateMonth) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!maxIndexByMonth) {
      maxIndexByMonth = indicesList[i];
      // eslint-disable-next-line no-continue
      continue;
    }

    if (currIndexDate.isAfter(moment(maxIndexByMonth.toDate))) {
      maxIndexByMonth = indicesList[i];
    }
  }

  return maxIndexByMonth ?? maxIndex;
}

export default findNearestIndexByMonth;
