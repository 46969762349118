import { FC, memo } from 'react';

import { TasksListDaysLeftValue as Value } from '../../values';

interface IProps {
  id: string;
  daysLeft: number;
  dataTestId: string;
}

const TasksListDaysLeftWithStatusNew: FC<IProps> = ({ id, daysLeft, dataTestId }) => {
  if (daysLeft > 0) {
    return (
      <Value
        id={id}
        icon={'finish'}
        title={`${Math.abs(daysLeft)} д.`}
        tooltipTitle={'Дни до завершения'}
        dataTestId={dataTestId}
      />
    );
  }

  if (daysLeft === 0) {
    return (
      <Value
        id={id}
        icon={'check'}
        title={'Сегодня'}
        tooltipTitle={'Завершение по плану сегодня'}
        dataTestId={dataTestId}
      />
    );
  }

  if (daysLeft < 0) {
    return (
      <Value
        id={id}
        icon={'clock'}
        title={`${Math.abs(daysLeft)} д.`}
        tooltipTitle={'Просроченные дни'}
        dataTestId={dataTestId}
      />
    );
  }

  return <></>;
};

TasksListDaysLeftWithStatusNew.displayName = 'TasksListDaysLeftWithStatusNew';

export default memo(TasksListDaysLeftWithStatusNew);
