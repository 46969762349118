enum EFieldsImportModalName {
  FileImport = 'fileImport',
  Loader = 'loader',
  NotEnoughRights = 'notEnoughRights',
  SomethingWentWrongWithoutUpload = 'somethingWentWrongWithoutUpload',
  UnexpectedError = 'UnexpectedError',
  NotAuthorized = 'NotAuthorized',
  // system
  _Success = '_success',
}

export default EFieldsImportModalName;
