import styled, { css } from 'styled-components';

const SwitcherWrapper = styled.div<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          pointer-events: none;
        `
      : css`
          pointer-events: auto;
        `}
`;

const RequiredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 109px;
  align-items: center;
  grid-gap: 12px;

  padding-bottom: 12px;
  border-bottom: 1px solid #e6e9ee;
`;

const StyledStoAttributePhotosSelector = {
  SwitcherWrapper,
  RequiredWrapper,
  Wrapper,
};

export default StyledStoAttributePhotosSelector;
