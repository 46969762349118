import { Colors, Icon, IconButton, Switcher } from '@farmlink/farmik-ui';
import { FC, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { EStoDictionaryName as EDictionaryName } from '../../../../../../../../../../../../api/models/as-fields/new/sto';
import { StoRegistriesController as Controller } from '../../../../mobx/controllers';
import { StoStore } from '../../../../../../mobx';

import Styled from './StoRegistrySearch.styles';

interface IProps {
  dataTestId: string;
  dictionaryName: EDictionaryName;
}

const StoRegistrySearch: FC<IProps> = ({ dataTestId, dictionaryName }) => {
  const stoStore = useStore(StoStore);

  const controller = useStore(Controller);

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedText] = useDebounce(searchValue, 200);

  const ref = useRef<HTMLInputElement>();

  const list = controller.getEntityList(dictionaryName);
  const selectedIdList = controller.getSelectedEntityIdList(dictionaryName);

  const selectedNumber: number = selectedIdList.length;
  const maxNumber: number = list.length;
  const isDisplaySearchIconInInput = isSearchActive && searchValue?.length === 0;
  const isDisplaySearchClear = isSearchActive && searchValue?.length > 0;

  useEffect(() => {
    controller.searchEntities(dictionaryName, debouncedText);
  }, [debouncedText]);

  useEffect(() => {
    if (maxNumber === 0) {
      setIsSelectAll(false);
      return;
    }

    setIsSelectAll(selectedNumber === maxNumber);
  }, [selectedNumber, maxNumber]);

  const isSelectAllBlocked = stoStore.isViewOnly;
  const isSearchBlocked = list.length < 12;

  const handleToggleActivitySwitch = () => {
    if (isSelectAllBlocked) return;

    controller.toggleAllEntities(dictionaryName, !isSelectAll);
    setIsSelectAll(!isSelectAll);
  };

  const handleToggleSearch = () => {
    const state = !isSearchActive;

    setIsSearchActive(state);
    setSearchValue('');

    if (state) {
      setTimeout(() => {
        ref.current.focus();
      }, 100);
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.SearchWrapper {...getDataTestId()}>
      <Styled.SwitcherGroup {...getDataTestId('switcher-group')}>
        {!isSelectAllBlocked ? (
          <>
            <Switcher
              value={isSelectAll}
              onChange={handleToggleActivitySwitch}
              dataTestId={getDataTestId('selectAll-switch')['data-test-id']}
            />

            <Styled.SwitcherLabel
              onClick={handleToggleActivitySwitch}
              {...getDataTestId('selectAll-label')}
            >
              Выбрать все
            </Styled.SwitcherLabel>

            <Styled.Delimiter>|</Styled.Delimiter>
          </>
        ) : null}

        <Styled.FieldCounter {...getDataTestId('counter')}>
          Выбрано: {selectedNumber} из {maxNumber}
        </Styled.FieldCounter>
      </Styled.SwitcherGroup>

      {!isSearchBlocked && (
        <Styled.SearchIcon
          isSearchActive={isSearchActive}
          onClick={handleToggleSearch}
          isDisabled={isSearchBlocked}
          {...getDataTestId('search')}
        />
      )}

      <Styled.SearchInputIcon $isDisplay={isDisplaySearchIconInInput}>
        <Icon icon="search" size={16} fill={Colors.secondaryDarkGray} />
      </Styled.SearchInputIcon>

      <Styled.SearchField
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        disabled={isSearchBlocked}
        ref={ref}
        $isDisplay={isSearchActive}
        $isDisplaySearchIconInInput={isDisplaySearchIconInInput}
        placeholder={'Введите название'}
        data-is-displayed={isSearchActive}
        {...getDataTestId('search-input')}
      />

      <Styled.ClearInputIcon $isDisplay={isDisplaySearchClear}>
        <IconButton
          icon="close"
          size={16}
          onClick={handleClearSearch}
          colorScheme={{
            default: {
              background: 'transparent',
              icon: Colors.secondaryDarkGray,
            },
            hover: {
              background: 'transparent',
              icon: Colors.black,
            },
          }}
          data-test-id={getDataTestId('search-input-clear')['data-test-id']}
        />
      </Styled.ClearInputIcon>
    </Styled.SearchWrapper>
  );
};

export default observer(StoRegistrySearch);
