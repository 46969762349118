import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/IoC';
import { IMapMarkersConfig as IMarkersConfig } from '../../../models';
import { BaseMarker } from '../../../utils/MapElements';

@provide.singleton()
class MapMarkerStore {
  private _markersById: Map<number, BaseMarker> = new Map<number, BaseMarker>();

  private _isDrawEnabled = false;

  private _markersConfig: IMarkersConfig;

  constructor() {
    makeAutoObservable(this);
  }

  get markersList() {
    return [...this._markersById.values()];
  }

  get isDrawEnabled() {
    return this._isDrawEnabled;
  }

  set isDrawEnabled(value) {
    this._isDrawEnabled = value;
  }

  get markersConfig() {
    return this._markersConfig;
  }

  set markersConfig(value) {
    this._markersConfig = value;
  }

  getMarker = (id: number): BaseMarker | undefined => {
    return this._markersById.get(id);
  };

  setMarker = (marker: BaseMarker): void => {
    this._markersById.set(marker.id, marker);
  };

  deleteMarker = (id: number): void => {
    this._markersById.delete(id);
  };
}

export default MapMarkerStore;
