import { useLocation, useParams } from 'react-router-dom';

import { IStoParams } from '../../models';

const useStoParams = (): IStoParams => {
  const params = useParams<keyof IStoParams>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const versionId = queryParams.get('versionId');

  return { ...params, versionId };
};

export default useStoParams;
