import React, { FC } from 'react';
import { observer } from 'mobx-react';

import Container from '../../../../../../../shared/features/UI/new/Container/Container';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { EContainerHeaderType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskOperationCreateStore as Store } from '../../mobx/stores';

import Styled from './TaskOperationCreateCommonInfo.styles';
import {
  TaskOperationCreateAssigneeSelect as AssigneeSelect,
  TaskOperationCreateCultureSelect as CultureSelect,
  TaskOperationCreateDateSelect as DateSelect,
  TaskOperationCreateOperationSelect as OperationSelect,
  TaskOperationCreateConfigSelect as ConfigSelect,
} from './components';

const TaskOperationCreateCommonInfo: FC = () => {
  const store = useStore(Store);

  const getDataTestId = useDataTestIdV2('tasks__task-operation-create__common-info');

  return (
    <Container
      dataTestId={getDataTestId()['data-test-id']}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: 'Общие данные',
        },
      }}
      containerStyles={{
        gap: '16px',
      }}
    >
      <Styled.Form {...getDataTestId('')}>
        <CultureSelect dataTestId={getDataTestId('culture-select')['data-test-id']} />

        <OperationSelect dataTestId={getDataTestId('operation-select')['data-test-id']} />

        <DateSelect dataTestId={getDataTestId('date-select')['data-test-id']} />

        <AssigneeSelect dataTestId={getDataTestId('assignee-select')['data-test-id']} />

        {store.config ? (
          <ConfigSelect dataTestId={getDataTestId('config-select')['data-test-id']} />
        ) : null}
      </Styled.Form>
    </Container>
  );
};

TaskOperationCreateCommonInfo.displayName = 'TaskOperationCreateCommonInfo';

export default observer(TaskOperationCreateCommonInfo);
