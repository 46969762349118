import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useModal } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { EStoDictionaryName as EDictionaryName } from '../../../../../../../../api/models/as-fields/new/sto';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { useStoParams } from '../../../../hooks';

import Styled from './StoRegistries.styles';
import { StoRegistry } from './components';
import { StoRegistriesController as Controller } from './mobx/controllers';
import { StoRegistriesAddEntityFromDictionaryModal as AddEntityFromDictionaryModal } from './modals';

const StoRegistries: FC = () => {
  const controller = useStore(Controller);

  const params = useStoParams();
  const modal = useModal();

  useEffect(() => {
    controller.initiatePage(params);

    modal.registerModalList([AddEntityFromDictionaryModal], 'sto__registries');

    return () => {
      controller.clearStore();
    };
  }, []);

  const getDataTestId = useDataTestIdV2('sto__registries');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <StoRegistry title={'Сорняки'} dictionaryName={EDictionaryName.WeedPlants} />

      <StoRegistry title={'Вредители'} dictionaryName={EDictionaryName.Vermin} />

      <StoRegistry title={'Болезни'} dictionaryName={EDictionaryName.Diseases} />
    </Styled.Wrapper>
  );
};

StoRegistries.displayName = 'StoRegistries';

export default observer(StoRegistries);
