import React, { FC, memo, ReactElement } from 'react';

import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import ListCardStyled from '../../ListCard.styles';

import Styled from './RightBlockWithAreaAndMenu.styles';

interface IProps {
  area: string;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
  dropdownMenu?: ReactElement;
}

const RightBlockWithAreaAndMenu: FC<IProps> = props => {
  return (
    <ListCardStyled.RightBlock {...props.getDataTestId('right-block')}>
      <Styled.AreaLabel {...props.getDataTestId('area')}>{props.area}</Styled.AreaLabel>

      {props.dropdownMenu && (
        <ListCardStyled.MenuIconWrapper>{props.dropdownMenu}</ListCardStyled.MenuIconWrapper>
      )}
    </ListCardStyled.RightBlock>
  );
};

export default memo(RightBlockWithAreaAndMenu);
