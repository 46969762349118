import { makeAutoObservable, runInAction } from 'mobx';

import { mapToArray } from '../../../utils/helpers/map/mapToArray';
import {
  TFilterById,
  TFilterMemento,
  TFiltersIds,
  TUpdateMeta,
} from '../interfaces/Filters.interface';

import FilterManager from './FilterManager';
import Filter from './Filter';

class FiltersStorage<F extends Filter[]> {
  private _filters = new Map<string, F[number]>();
  private _mementosList: TFilterMemento[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public getOne<ID extends TFiltersIds<F>>(id: ID): TFilterById<F, ID> {
    return (this._filters.get(id as string) as unknown) as TFilterById<F, ID>;
  }

  public getAllList() {
    return mapToArray(this._filters);
  }

  /**
   * Возвращает список фильтров у которых заполнено значение и которые не присутствуют в skipFilters массиве
   */
  public getActiveList(skipFilters: Array<string> = []) {
    return this.getAllList().filter(f => {
      return !skipFilters.includes(f.id) && !f.isEmptyValue;
    });
  }

  public getCounter() {
    return this.getAllList().reduce((acc: number, curr: Filter) => {
      return acc + curr.selectedCount;
    }, 0);
  }

  public setAll(filters: F, manager: FilterManager) {
    runInAction(() => {
      filters.forEach(filter => {
        filter._setManager(manager);
        this._filters.set(filter.id, filter);
      });
    });
  }

  public updateAll(meta: TUpdateMeta = { triggeredBy: 'manual' }) {
    this.getAllList().forEach(filter => filter._updateState?.(meta));
  }

  public resetAll(skipFiltering?: boolean) {
    this.getAllList().forEach(filter => filter.resetValue(skipFiltering));
  }

  public createSnapshot() {
    this._mementosList = this.getAllList().map(filter => filter.createMemento());
  }

  public restoreSnapshot(skipFiltering = false) {
    this._mementosList.forEach(memento => memento.restore(skipFiltering));
    this._mementosList.length = 0;
  }
}

export default FiltersStorage;
