import { Checkbox } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { DialogModalTitle } from '../../../../../../../../../components/DialogModal/style';

const Title = styled(DialogModalTitle)({
  marginBottom: 0,
});

const StyledCheckbox = styled(Checkbox)`
  margin-top: 24px;
  justify-content: center;
`;

const FooterWrapper = styled('div')`
  margin-top: 24px;
`;

export default {
  Title,
  FooterWrapper,
  Checkbox: StyledCheckbox,
};
