import { makeAutoObservable, runInAction } from 'mobx';
import { cloneDeep } from 'lodash';

import { EFieldsListSort, TFiltersTuple } from '../../../../interfaces/Filter.interface';
import { IChangedFields, IDeletedFields } from '../../interfaces/Multiselect.interface';
import { FIELDS_FILTER_MANAGER_ID, getEmptyCultureZoneModel } from '../../../../utils';
import { mapToArray } from '../../../../../../../shared/utils/helpers/map/mapToArray';
import { CultureZone, Field } from '../../../../../../../../api/models/field.model';
import { FilterStore } from '../../../../../../../shared/features/FilterManager';
import { CultureModel } from '../../../../../../../../api/models/culture.model';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';

@provide.singleton()
class MultiselectStore {
  @lazyInject(FilterStore)
  private filterStore: FilterStore;

  /**
   * Коллекция хранит глубокую копию списка полей из {@link FieldsStore}
   * Данная коллекция модифицируется в процессе работы мультиселекта (удаляются и изменяются элементы).
   * После каждого изменения коллекции она обновляет собой sourceList в {@link FilterManager}
   */
  private _fieldsList = new Map<string, Field>();

  /**
   * Коллекция хранит глубокую копию списка полей из {@link FieldsStore}
   * Используется для восстановления списка полей в случае отмены изменений мультиселекта
   */
  private _fieldsListSnapshot = new Map<string, Field>();

  // Коллекция хранит id выбранных полей
  private _selectedFieldsIds = new Set<string>();

  /**
   * Объект хранит коллекцию id полей для удаления и доп. флаг fromAllSeasons
   * Эти данные будут отправлены на бэк
   */
  private _deletedFields: IDeletedFields | null = null;

  /**
   * Объект хранит коллекцию id полей у которых изменена/добавлена кз а также id новой культуры
   * Эти данные будут отправлены на бэк
   */
  private _changedFields: IChangedFields | null = null;

  private _isInitialize = false;

  constructor() {
    makeAutoObservable(this);
  }

  // Весь список полей. В том числе и заблокированные для выбора
  public get fieldsList() {
    return mapToArray(this._fieldsList);
  }

  public get fieldsListSnapshot() {
    return mapToArray(this._fieldsListSnapshot);
  }

  // Активный список полей. (Отфильтрован, исключены заблокированные поля)
  public get activeFieldsList() {
    const filteredList = this.filterManager?.filteredList ?? [];
    return filteredList.filter(field => !this.isDisabled(field.id));
  }

  // Коллекция id выбранных полей
  public get selectedFieldsCollection() {
    return this._selectedFieldsIds;
  }

  public get selectedFieldsCount() {
    return this._selectedFieldsIds.size;
  }

  /**
   * Возвращает фильтр-менеджер
   */
  public get filterManager() {
    const id = FIELDS_FILTER_MANAGER_ID;
    return this.filterStore.getManager<TFiltersTuple, Field, EFieldsListSort>(id);
  }

  public get deletedFields() {
    return this._deletedFields;
  }

  public get changedFields() {
    return this._changedFields;
  }

  public get isInitialize() {
    return this._isInitialize;
  }

  public set isInitialize(value) {
    this._isInitialize = value;
  }

  // Проверяет является ли поле выбранным
  public isSelected(fieldId: string) {
    return this._selectedFieldsIds.has(fieldId);
  }

  // Возвращает true если поле недоступно для мультиселекта
  public isDisabled(fieldId: string) {
    const field = this._fieldsList.get(fieldId);
    return field?.cultureZones.some(zone => Boolean(zone.experiment));
  }

  public setFieldsList(list: Field[]) {
    this.setCollection(list, '_fieldsList');
    this.setCollection(list, '_fieldsListSnapshot');
  }

  public deleteSelectedFields(fromAllSeasons: boolean) {
    this._selectedFieldsIds.forEach(id => this._fieldsList.delete(id));

    this._deletedFields = {
      fromAllSeasons,
      fieldsIds: mapToArray(this._selectedFieldsIds),
    };

    this._selectedFieldsIds.clear();
  }

  public changeSelectedFieldsCultures(culture: CultureModel) {
    this._selectedFieldsIds.forEach(id => {
      const field = this._fieldsList.get(id);

      // Создаем новую временную культуру в случае если у поля их нет
      if (!field.cultureZones.length) {
        const zone = getEmptyCultureZoneModel(field.geometry, null) as CultureZone;
        field.cultureZones = [zone];
      }

      field.cultureZones = field.cultureZones.map(zone => ({ ...zone, culture }));
    });

    this._changedFields = {
      newCultureId: culture.id,
      fieldsIds: mapToArray(this._selectedFieldsIds),
    };

    this._selectedFieldsIds.clear();
  }

  /**
   * Очищает стор. Используется при выходе из режима
   */
  public clear() {
    this._fieldsList.clear();
    this._fieldsListSnapshot.clear();
    this.reset(null);
  }

  /**
   * Сбрасывает значения до дефолтных. Используется при сбросе всех изменений или при выходе из режима
   */
  public reset(snapshot?: Field[]) {
    this._selectedFieldsIds.clear();
    this._deletedFields = null;
    this._changedFields = null;

    if (snapshot) {
      this.setFieldsList(snapshot);
    }
  }

  private setCollection(list: Field[], collectionKey: '_fieldsList' | '_fieldsListSnapshot') {
    const clonedList = cloneDeep(list) as Field[];

    runInAction(() => {
      this[collectionKey].clear();

      clonedList.forEach(field => {
        this[collectionKey].set(field.id, field);
      });
    });
  }
}

export default MultiselectStore;
