import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useMemo, useState } from 'react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { StoController, StoStore } from '../../mobx';
import { StoOperationsCoreController } from '../../modules/StoOperations/mobx/controllers';
import { EStoTab } from '../../models/StoCommon';
import { EStoAction, EStoStatus } from '../../../../../../../../api/models/as-fields/STO/STO.model';
import { useStoParams } from '../../../../hooks';
import { StoRegistriesController } from '../../modules/StoRegistries/mobx/controllers';
import { StoRegistriesStore } from '../../modules/StoRegistries/mobx/stores';
import { SimpleLoader } from '../../../../../../../shared/features/UI/new/SimpleLoader';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';

import Styled from './StoControlButtons.styles';
import { useStoControls } from './hooks';

const StoControlButtons: FC = () => {
  const store = useStore(StoStore);
  const stoRegistriesStore = useStore(StoRegistriesStore);

  const controller = useStore(StoController);
  const operationsCoreController = useStore(StoOperationsCoreController);
  const registriesController = useStore(StoRegistriesController);

  const [isFetching, setIsFetching] = useState(false);

  const controls = useStoControls();
  const { stoId } = useStoParams();

  const handleButtonClick = () => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    if (store.isCreateMode) {
      controller
        .createSto()
        .then(STO => controls.createStoSuccessHandler(STO.id))
        .catch(() => controls.createStoErrorHandler())
        .finally(() => {
          setIsFetching(false);
        });
    }

    if (store.selectedSto?.id) {
      controller
        .updateSto({
          operationsSuccessHandler: operationsCoreController.allowAvailAbleSettingsManagement,
          registriesSuccessHandler: () => registriesController.saveDictionaries(stoId),
        })
        .then(response => controls.updateStoSuccessHandler(response))
        .catch(e => controls.updateStoErrorHandler(e))
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  const primaryButtonText = useMemo(() => {
    if (store.isCreateMode) {
      return 'Создать СТО';
    }

    return 'Сохранить';
  }, [store.isCreateMode]);

  const checkIfIsBlocked = (): boolean => {
    if (store.isCreateMode) {
      return !store.isCreateStoFormValid;
    }

    if (store?.hasChanges) {
      return !store.isEditStoFormValid;
    }

    if (store.StoTab === EStoTab.Registries) {
      return !store.hasChanges;
    }

    return false;
  };

  const checkIfButtonIsHidden = (): boolean => {
    if (store?.isViewOnly) return true;

    if (store?.StoTab === EStoTab.History) return true;
    if (store?.selectedSto?.calculatedStatus?.status === EStoStatus.Inactive) return true;
    if (!store?.isCreateMode && !store?.selectedSto?.availableActions?.includes(EStoAction.StoEdit))
      return true;

    if (store.StoTab === EStoTab.Registries) {
      if (stoRegistriesStore.isInitialLoading) return true;

      const hasData: boolean =
        Boolean(stoRegistriesStore.weedPlantList.length) ||
        Boolean(stoRegistriesStore.verminList.length) ||
        Boolean(stoRegistriesStore.diseaseList.length);

      return !hasData;
    }

    return false;
  };

  const getDataTestId = useDataTestIdV2('sto__control-buttons');

  const Loader = useMemo(() => {
    return (
      <Button
        color={'default'}
        type={'button'}
        disabled
        dataTestId={getDataTestId('button-loader')['data-test-id']}
      >
        <Styled.LoaderWrapper {...getDataTestId('button-loader__loader-wrapper')}>
          <SimpleLoader dataTestId={getDataTestId()['data-test-id']} size={24} color={'#FFFFFF'} />
        </Styled.LoaderWrapper>
      </Button>
    );
  }, []);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.ButtonWrapper
        $isHidden={checkIfButtonIsHidden()}
        {...getDataTestId('buttons-wrapper')}
      >
        {isFetching ? (
          Loader
        ) : (
          <Button
            color={'primary'}
            type={'button'}
            onClick={handleButtonClick}
            disabled={checkIfIsBlocked()}
            dataTestId={
              store.isCreateMode ? 'fullscreen-create-sto-button' : 'fullscreen-save-sto-button'
            }
          >
            {primaryButtonText}
          </Button>
        )}
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

StoControlButtons.displayName = 'StoControlButtons';

export default observer(StoControlButtons);
