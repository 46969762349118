import { lazyInject, provide } from '../../../../../utils/IoC';
import { BaseImageOverlay } from '../../../utils/MapElements';
import MapImageOverlayService from '../services/MapImageOverlay.service';

type TImageOverlayServiceArgs<K extends keyof MapImageOverlayService> = Parameters<
  MapImageOverlayService[K]
>;

@provide.transient()
class MapImageOverlayController {
  @lazyInject(MapImageOverlayService)
  private service: MapImageOverlayService;

  public display(...args: TImageOverlayServiceArgs<'create'>): BaseImageOverlay {
    return this.service.create(...args);
  }

  public remove(id: number): void {
    this.service.removeById(id);
  }
}

export default MapImageOverlayController;
