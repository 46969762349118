import { FC, useCallback, useMemo } from 'react';
import { Colors, DropdownButton, TButtonMultiAction, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useTaskActionControl, useTasksParams } from '../../hooks';
import { ETaskAction, ETaskStatus } from '../../../../../../api/models/as-fields/task/task.model';
import { getAvailableStatusChange } from '../InspectionPoints/utils/helpers';
import { TaskController } from '../../mobx/controllers';
import { useStore } from '../../../../../shared/utils/IoC';

import useTaskHeaderSideCancel from './hooks/useTaskHeaderSideCancel/useTaskHeaderSideCancel';

interface ITaskHeaderSideContentProps {
  status: ETaskStatus;
  availableStatusList: ETaskStatus[];
  mode?: 'view' | 'edit' | 'create';
}

const TaskHeaderSideContent: FC<ITaskHeaderSideContentProps> = ({
  status,
  mode,
  availableStatusList,
}) => {
  const { taskId } = useTasksParams();

  const { sendTaskStatus } = useStore(TaskController);

  const controls = useTaskActionControl();
  const isAllowedToChangeStatus = controls.hasPermission(ETaskAction.TaskSetStatus);
  const isAllowedToDelete = controls.hasPermission(ETaskAction.TaskDelete);

  const { handleCancelClick } = useTaskHeaderSideCancel(status, taskId);
  const { setNotification } = useNotificator();

  const isViewMode = mode === 'view';

  const isAllowToCancel = useMemo(
    () =>
      status === ETaskStatus.New ||
      status === ETaskStatus.InWork ||
      status === ETaskStatus.Impossible,
    [status]
  );

  const availableStatusChange = useMemo(() => {
    if (!isAllowedToChangeStatus) {
      return null;
    }

    const statusButtonConfig = getAvailableStatusChange(status);

    return availableStatusList?.includes(statusButtonConfig.status) ? statusButtonConfig : null;
  }, [status, availableStatusList, isAllowedToChangeStatus]);

  const handleChangeTaskStatus = useCallback(() => {
    if (availableStatusChange) {
      sendTaskStatus({
        status: availableStatusChange.status,
        taskId,
        cancellationReason: null,
      }).then(() => {
        if (availableStatusChange?.notification) {
          setNotification(availableStatusChange.notification);
        }

        availableStatusChange?.onChangeHandler?.(availableStatusChange.status);
      });
    }
  }, [taskId, availableStatusChange]);

  const btnActionForCreateOneTask = useMemo<TButtonMultiAction>(() => {
    if (!availableStatusChange) {
      return {
        title: null,
        onClick: null,
        isHidden: true,
      };
    }

    return {
      title: availableStatusChange.buttonText,
      onClick: handleChangeTaskStatus,
    };
  }, [status]);

  const btnActionForCreateMultipleTask = useMemo<TButtonMultiAction>(() => {
    return {
      title: status === ETaskStatus.New ? 'Удалить' : 'Отменить',
      onClick: handleCancelClick,
      textColor: Colors.red,
      isHidden: !isAllowToCancel || (status === ETaskStatus.New && !isAllowedToDelete),
    };
  }, [status]);

  return (
    <>
      {isViewMode && (
        <DropdownButton
          dataTestId={'task-header-side-content'}
          title={'Действия'}
          multiActionList={[btnActionForCreateOneTask, btnActionForCreateMultipleTask]}
          style={{
            color: 'primary',
            size: 'sm',
            placement: 'bottom',
            width: '170px',
          }}
        />
      )}
    </>
  );
};

export default observer(TaskHeaderSideContent);
