import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { BorderRadius } from '../../../../../../../../../../constans/sizes';

const IconButton = styled('div')({
  backgroundColor: Colors.generalWhite,
  borderRadius: ESpaceSize.M,
  pointerEvents: 'auto',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: 40,
  height: 40,
});

const LegendWrapper = styled.div`
  height: 40px;
  padding: 10px 8px 8px 8px;
  border-radius: ${BorderRadius.default};
  background: ${Colors.generalWhite};
  position: absolute;
  bottom: ${ESpaceSize.M};
  left: 16px;
  z-index: 1001;
  display: flex;
  flex-direction: row;
  pointer-events: auto;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${Colors.darkGrey};
`;

const SetWrapper = styled.div`
  margin-right: 12px;

  &:last-child {
    margin-right: 0;

    ${Label} {
      text-align: center;
      flex-grow: 1;
    }
  }
`;

const WeatherColorsWrapper = styled.div`
  display: flex;
  border-radius: ${BorderRadius.default};
  overflow: hidden;
  margin-bottom: 4px;
  width: 10rem;
`;

const ColorBlocksWrapper = styled.div`
  display: flex;
  border-radius: ${BorderRadius.default};
  overflow: hidden;
  margin-bottom: 4px;
`;

const ColorBlock = styled.div`
  width: 16px;
  height: 8px;
  background: ${props => props.color};
  flex-grow: 1;
`;

const LabelsBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default {
  IconButton,
  LegendWrapper,
  SetWrapper,
  Label,
  WeatherColorsWrapper,
  ColorBlocksWrapper,
  ColorBlock,
  LabelsBlock,
};
