import React, { FC } from 'react';

import { ContentLoader } from '../../../../../../../../../shared/features/UI/loaders/ContentLoader';
import { Container } from '../../../../../../../../../shared/features/UI/Container';

interface IProps {
  dataTestId: string;
}

const TaskOperationCreateFieldsLoader: FC<IProps> = ({ dataTestId }) => {
  return (
    <Container dataTestId={dataTestId}>
      <ContentLoader
        dataTestId={dataTestId}
        overlayStyles={{ height: '200px', width: '100%', position: 'relative' }}
      />
    </Container>
  );
};

TaskOperationCreateFieldsLoader.displayName = 'StoRegistryLoader';

export default TaskOperationCreateFieldsLoader;
