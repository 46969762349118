import {
  ENotificationType,
  ModalFooter,
  TModalButton,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';

import { FieldSeasonsModel } from '../../../../../../../../../api/models/field.seasons.model';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { ReactComponent as InfoIcon } from '../../../../../../../static/info.svg';
import { FieldSeasonsViewController as SeasonsController } from '../../../mobx';

import Styled from './DisableFieldModal.styles';

const DisableFieldModal: FC = () => {
  // Контроллер
  const seasonsController = useStore(SeasonsController);

  const notificator = useNotificator();
  const modal = useModal();

  const handlers = modal.getModalRuntimeHandlers();
  const payload = modal.getModalPayload() as FieldSeasonsModel;

  const disableField = () => {
    if (payload) {
      void seasonsController
        .deleteFieldFromSeason(payload)
        .then(() => {
          modal.closeModal();
        })
        .catch(() => {
          notificator.setNotification({
            message: 'Вы не можете удалить участок, т.к. он находится в опыте',
            style: {
              placement: 'top-right',
              type: ENotificationType.Warning,
            },
          });
        });
    }
  };

  const successButton = useMemo<TModalButton>(() => {
    return {
      title: 'Выключить',
      color: 'secondary',
      handler: disableField,
    };
  }, []);

  const denyButton = useMemo<TModalButton>(() => {
    return {
      title: 'Отменить',
      handler: () => {
        modal.closeModal();
        handlers.onCancel();
      },
    };
  }, []);

  return (
    <Styled.Wrapper>
      <InfoIcon />
      <Styled.Title data-test-id="seasons-modal-title">Выключить поле из сезона</Styled.Title>

      <Styled.Description data-test-id="seasons-modal-body">
        Вы уверены, что хотите выключить поле из сезона? <br />
        Вместе с полем отключатся связанные с ним данные: <br />
        участки, задачи, чек-листы и опыты
      </Styled.Description>

      <ModalFooter successButton={successButton} denyButton={denyButton} />
    </Styled.Wrapper>
  );
};

export default observer(DisableFieldModal);
