import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/IoC';
import { BaseLayerGroup, BasePolygon } from '../../../utils/MapElements';

@provide.singleton()
class MapLayerGroupStore<T extends BaseLayerGroup = BaseLayerGroup> {
  private _layerGroupsById: Map<number, T> = new Map<number, T>();

  private _selectedLayerGroup: T | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get layerGroupsList() {
    return [...this._layerGroupsById.values()];
  }

  get layerGroupsMainPolygons(): BasePolygon[] {
    return this.layerGroupsList.map(layerGroup => layerGroup.getMainPolygon());
  }

  get selectedLayerGroup() {
    return this._selectedLayerGroup;
  }

  set selectedLayerGroup(value) {
    this._selectedLayerGroup = value;
  }

  get hasLayers() {
    return Boolean(this._layerGroupsById.size);
  }

  getLayerGroup = (id: number): T | undefined => {
    return this._layerGroupsById.get(id);
  };

  setLayerGroup = (layerGroup: T): void => {
    this._layerGroupsById.set(layerGroup.id, layerGroup);
  };

  deleteLayerGroup = (id: number): void => {
    this._layerGroupsById.delete(id);
  };
}

export default MapLayerGroupStore;
