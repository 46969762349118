import { EPolygonErrorType } from '../../../../../../../shared/features/map/models/PolygonErrors/PolygonErrors.model';
import { MapDrawerController, MapEventBus } from '../../../../../../../shared/features/map/modules';
import { PolygonValidator } from '../../../../../../../shared/features/map/utils/validators';
import { IMapPolygonError } from '../../../../../../../shared/features/map/models';
import { EFieldTooltip } from '../../../../../../constants/FieldTooltip.enum';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../../constants/FieldFill.enum';
import { Field } from '../../../../../../../../api/models/field.model';
import { FIELD_POLYGON_OPTIONS, EFieldsMode } from '../../../../utils';
import { FieldsMapCoreController } from '../../../../mobx';
import Service from '../services/EditFieldsApi.service';
import Store from '../stores/EditFields.store';
import {
  BasePolygon,
  FieldLayerGroup,
} from '../../../../../../../shared/features/map/utils/MapElements';

@provide.transient()
class EditFieldController extends FieldsMapCoreController {
  @lazyInject(Store)
  private store: Store;

  @lazyInject(Service)
  private service: Service;

  @lazyInject(MapDrawerController)
  private mapDrawerController: MapDrawerController;

  public async initialize(editableFieldId: string) {
    this.coreStore.fieldsMode = EFieldsMode.EDIT;

    // Получаем список полей с бэка. Сетаем их в FieldsStore
    const fieldsList = await this.service.fetchFieldsList();
    if (!this.fieldsStore.hasField(editableFieldId)) {
      return;
    }

    // Подготовка карты. Центрируем
    this.centerMapToFieldBounds(this.fieldsStore.getFieldById(editableFieldId));

    // Инициализируем карту. Делаем связь слой - поле
    await this.buildMap(fieldsList, this.getLayerGroupConfig);
    this.registerMapEvents();

    // Получаем редактируемый полигон и включаем ему режим редактирования
    const editableField = this.fieldsStore.getFieldById(editableFieldId);
    const fieldPolygon = this.getLayerGroupByField(editableField)?.getMainPolygon();

    this.mapDrawerController.enableEditPolygon(fieldPolygon);

    // Выбираем поле
    this.selectField(editableField, { skipCenter: true });

    // Заливаем редактируемый слой культурными зонами и устанавливаем ему тултип
    this.culturesLegendStore.setCultures(fieldsList);
    this.layerFillController.setStrategy(EFieldFill.Cultures, editableField);
    this.layerTooltipController.setContent([EFieldTooltip.Name], editableField);

    // Сетаем редактируемое поле в стор, для отображения в левом меню
    this.store.setEditableField(editableField, fieldPolygon.getInfo());
  }

  public destroy() {
    super.destroy();

    if (this.layerGroup) {
      const fieldPolygon = this.layerGroup?.getMainPolygon();
      this.mapDrawerController.disableEditPolygon(fieldPolygon);
    }

    this.store.clear();
  }

  public submitField() {
    return this.service.submitField(this.store.editableField);
  }

  public changeFieldName(value: string) {
    this.store.changeEditableFieldName(value);
    this.layerGroup.setTooltipContent(value);
  }

  // Расширяет базовый метод
  protected getLayerGroupConfig(field: Field) {
    const config = super.getLayerGroupConfig(field);

    config.layerGroup = new FieldLayerGroup(field, {
      optimization: false,
      fieldOptions: FIELD_POLYGON_OPTIONS.display,
      selectedStyle: FIELD_POLYGON_OPTIONS.edit,
    });

    return config;
  }

  protected registerMapEvents() {
    const listener = MapEventBus.on('draw.polygon.edit', this.handleEditPolygon);
    const listener2 = MapEventBus.on('polygon.undo', this.handleEditPolygon);
    const listener1 = MapEventBus.on('draw.polygon.vertex.click', () => {
      this.layerGroup.getMainPolygon().careTaker.backup();
    });

    this.coreStore.setMapEventListeners([listener, listener1, listener2]);
  }

  public handleEditPolygon = (polygon: BasePolygon): IMapPolygonError[] => {
    const updatedZones = this.layerGroup.cutCulturePolygonsAlongField();
    const successCut = Array.isArray(updatedZones);

    this.store.updateEditableField(polygon.getInfo(), updatedZones);

    if (this.store.isCultureZoneOutOfField && successCut) {
      this.store.isCultureZoneOutOfField = false;
    }

    if (!this.store.isCultureZoneOutOfField && !successCut) {
      this.store.isCultureZoneOutOfField = true;
    }

    this.layerGroup.redrawTooltip();

    return this.validatePolygon(polygon);
  };

  private validatePolygon(polygon: BasePolygon): IMapPolygonError[] {
    const validator = new PolygonValidator(polygon)
      .checkIntersections(this.fieldsPolygonsList)
      .checkIsAreaTooBig();

    const largeAreaError = validator.errors.get(EPolygonErrorType.LargeArea);
    this.store.setErrorToField(largeAreaError);

    return validator.errors.list;
  }

  private get layerGroup(): FieldLayerGroup {
    return this.getLayerGroupByField(this.store.editableField) as FieldLayerGroup;
  }
}

export default EditFieldController;
