export const toFixedWithCeil = (num: number): string => {
  const n = Number(num);

  if (!Number.isFinite(n)) {
    return '';
  }

  const preNumber = n * 1000;

  if (preNumber > 0 && preNumber < 100) {
    return '0.1';
  }
  if (preNumber > 1000) {
    return Math.trunc(n).toString();
  }

  return n?.toFixed(1);
};

export const toFixedWithCeilBackEnd = (num: number): string => {
  const n = Number(num);

  if (!Number.isFinite(n)) {
    return '';
  }

  const preNumber = n * 1000;

  if (preNumber > 0 && preNumber < 100) {
    return '0.1';
  }

  return n?.toFixed(1);
};
