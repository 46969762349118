import { Geometry } from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';

import { calculateArea } from '../../../../../shared/features/map/utils/helpers';

/**
 * Возвращает дата-модель пустой культурной зоны
 */
function getEmptyCultureZoneModel(geometry: Geometry, area?: number) {
  return {
    id: `empty-${uuidv4()}`,
    name: '',
    culture: {
      attrs: {
        assistanceColorLegend: '',
      },
      picLink: { downloadUrl: '' },
      id: '',
      name: '',
    },
    variety: '',
    area: area ?? calculateArea(geometry),
    geometry,
    experimentReady: false,
  };
}

export default getEmptyCultureZoneModel;
