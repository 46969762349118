import { action, computed, makeAutoObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { lazyInject, provide } from '../shared/utils/IoC';
import { SessionStore } from '../authorization/stores/session.store';
import { ApplicationRoutes } from '../routes';
import { logoutEvent } from '../authorization/events/logout';

@provide.singleton()
export class AdminViewStore {
  @lazyInject(SessionStore)
  sessionStore: SessionStore;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  authAsUser = (token: string, navigate: NavigateFunction, timestamp: string) => {
    if (!token) return;
    const tokenTemp = token.replaceAll('==398==', '.');
    localStorage.setItem('loginAs', tokenTemp);
    localStorage.setItem('loginAsExpireIn', timestamp);
    this.sessionStore.checkIsLoggedIn();
    setTimeout(() => navigate(ApplicationRoutes.Dashboard), 500);
    if (Number(timestamp) > new Date().getTime()) {
      setTimeout(() => {
        localStorage.removeItem('loginAs');
        localStorage.removeItem('loginAsExpireIn');
        document.dispatchEvent(logoutEvent);
      }, Number(timestamp) - new Date().getTime());
    }
  };

  @computed
  get isAdminPanelActive() {
    return Boolean(localStorage.getItem('loginAs')) && this.sessionStore.isLoggedIn;
  }
}
