import { FC, memo } from 'react';
import { Moment } from 'moment';

import { TaskStatuses } from '../../../dashboard/modules/tasks/models';
import { useDataTestIdV2 } from '../../features/utils/hooks/locators';

import {
  TasksListDaysLeftWithStatusNew as New,
  TasksListDaysLeftWithStatusInWork as InWork,
  TasksListDaysLeftWithStatusCompleted as Completed,
  TasksListDaysLeftWithStatusCanceled as Canceled,
} from './components/statuses';
import Styled from './TaskDaysLeft.styles'



interface IProps {
  id: string;
  status: string;
  daysLeft: number;
  executedDate: string | Moment;
  canceledDate: string | Moment;
  dataTestId: string;
}

const TaskDaysLeft: FC<IProps> = ({
  id,
  status,
  daysLeft,
  executedDate,
  canceledDate,
  dataTestId,
}) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__task-days-left`);

  switch (status) {
    case TaskStatuses.New:
      return (
        <New
          id={id}
          daysLeft={daysLeft}
          dataTestId={{ ...getDataTestId('status-new') }['data-test-id']}
        />
      );

    case TaskStatuses.InWork:
      return (
        <InWork
          id={id}
          daysLeft={daysLeft}
          dataTestId={{ ...getDataTestId('status-inwork') }['data-test-id']}
        />
      );

    case TaskStatuses.Completed:
      return (
        <Completed
          id={id}
          daysLeft={daysLeft}
          executedDate={executedDate as string}
          dataTestId={{ ...getDataTestId('status-complete') }['data-test-id']}
        />
      );

    case TaskStatuses.Canceled:
      return (
        <Canceled
          id={id}
          canceledDate={canceledDate as string}
          dataTestId={{ ...getDataTestId('status-canceled') }['data-test-id']}
        />
      );

    default:
      return <Styled.Plug/>;
  }
};

TaskDaysLeft.displayName = 'TaskDaysLeft';

export default memo(TaskDaysLeft);
