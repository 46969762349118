import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  NewDropdown as Dropdown,
  EModalType,
  ModalFooter,
  TNewDropdownProps,
  TModalButton,
  TModalConfig,
  Typography,
  useModal,
  useNotificator,
  ENotificationType,
} from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { IStoRegistriesAddEntityFromDictionaryModalPayload as IModalPayload } from '../../interfaces';
import { TStoRegistriesEntitySelectOption as TEntitySelectOption } from '../../types';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoRegistriesController as Controller } from '../../mobx/controllers';
import {
  EStoDictionaryName as EDictionaryName,
  IStoDictionary,
} from '../../../../../../../../../../api/models/as-fields/new/sto';
import getNotificationScheme, {
  ENotificationScheme,
} from '../../../../../../../../../shared/utils/helpers/getNotificationScheme/getNotificationScheme';

import Styled from './StoRegistriesAddEntityFromDictionaryModal.styles';

const getFormattedDictionaryName = (dictionaryName: EDictionaryName): string => {
  switch (dictionaryName) {
    case EDictionaryName.WeedPlants:
      return 'сорняки';

    case EDictionaryName.Vermin:
      return 'вредителей';

    case EDictionaryName.Diseases:
      return 'болезни';

    default:
      return '';
  }
};

const getNotificatorText = (
  dictionaryName: EDictionaryName,
  addedEntitiesNumber: number
): string => {
  return (
    `Добавлены ${getFormattedDictionaryName(dictionaryName)}: ${addedEntitiesNumber}. Сохраните
` + 'и опубликуйте изменения'
  );
};

const StoRegistriesAddEntityFromDictionaryModal: FC = () => {
  const controller = useStore(Controller);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [optionList, setOptionList] = useState<TEntitySelectOption[]>([]);
  const [selectedOptionList, setSelectedOptionList] = useState<TEntitySelectOption[]>([]);

  const modal = useModal();
  const notificator = useNotificator();

  const modalPayload =
    (modal.getModalPayload() as IModalPayload) ??
    ({
      dictionaryName: null,
    } as IModalPayload);

  useEffect(() => {
    (async () => {
      if (!modalPayload.dictionaryName) return;

      const createdOptionList = await controller.createEntityOptionList(
        modalPayload.dictionaryName
      );

      setOptionList(createdOptionList);
      setIsLoading(false);
    })();
  }, [modalPayload.dictionaryName]);

  const handleChange = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (_, otherData) => {
      if (!otherData?.selectedValueList) {
        setSelectedOptionList([]);
        return;
      }

      setSelectedOptionList(otherData.selectedValueList);
    },
    []
  );

  const handleSuccessClick = (): void => {
    const formattedEntityList: IStoDictionary[] = selectedOptionList.map<IStoDictionary>(
      ({ initialModel }) => ({
        ...initialModel,
        isSelected: true,
        systemData: {
          isHighlighted: true,
        },
      })
    );

    controller.addEntityList(modalPayload.dictionaryName, formattedEntityList);

    const text = getNotificatorText(modalPayload.dictionaryName, formattedEntityList.length);

    notificator.setNotification(
      getNotificationScheme(ENotificationScheme.Dark, text, {
        type: ENotificationType.Success,
      })
    );

    modal.closeModal();
  };

  const getDataTestId = useDataTestIdV2('sto__registries__add-entity-from-dictionary__modal');

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      onChange: handleChange,
      placeholder: 'Выберите из списка',
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
          },
        },
        multiselect: {
          options: {
            itemStyle: 'checkbox',
          },
          counter: {
            label: 'Выбрано:',
          },
          isSelectedFirst: true,
          isPreventSortingInDropped: true,
        },
      },
    },
    body: {
      optionList,
      zIndex: '10002',
      isKeepSelectedOptions: true,
    },
    visual: {
      label: 'Название',
      isLoading,
      isPreventCloseAfterSelect: true,
      isRounded: true,
    },
    other: {
      dataTestId: getDataTestId('dropdown')['data-test-id'],
    },
  };

  const denyButton: TModalButton = {
    title: 'Отменить',
    dataTestId: getDataTestId('button-deny')['data-test-id'],
    color: 'default',
    handler: modal.closeModal,
  };

  const successButton: TModalButton = {
    title: 'Добавить',
    dataTestId: getDataTestId('button-success')['data-test-id'],
    color: 'primary',
    disabled: !selectedOptionList.length,
    handler: handleSuccessClick,
  };

  return (
    <Styled.Wrapper {...getDataTestId('')}>
      <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
        <Typography dataTestId={getDataTestId('title')['data-test-id']} variant={'h5'}>
          Добавить {getFormattedDictionaryName(modalPayload.dictionaryName)}
        </Typography>
      </Styled.TitleWrapper>

      <Styled.Header {...getDataTestId('header')}>
        <Typography dataTestId={getDataTestId('subtitle')['data-test-id']}>
          Выберите из справочника
        </Typography>
      </Styled.Header>

      <Styled.Body {...getDataTestId('body')}>
        <Dropdown config={dropdownConfig} />
      </Styled.Body>

      <ModalFooter
        dataTestId={getDataTestId('footer')['data-test-id']}
        denyButton={denyButton}
        successButton={successButton}
      />
    </Styled.Wrapper>
  );
};

StoRegistriesAddEntityFromDictionaryModal.displayName = 'StoRegistriesAddEntityFromDictionaryModal';

const StoRegistriesAddEntityFromDictionaryModalConfig: TModalConfig = {
  name: 'StoRegistriesAddEntityFromDictionary',
  title: '',
  type: EModalType.Custom,
  id: 'StoRegistriesAddEntityFromDictionary',
  children: StoRegistriesAddEntityFromDictionaryModal,
  dataTestId: 'sto__registries__add-entity-from-dictionary__modal-container',
  styledProps: {
    $modalPaddings: '40px',
    $size: '440px',
  },
};

export default StoRegistriesAddEntityFromDictionaryModalConfig;
