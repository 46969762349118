import { FC, useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { ButtonLink } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../../mobx/controllers';
import { ChecklistsStore } from '../../../../../mobx/stores';
import { useSortBy } from '../../../../../../../../../../shared/features/utils/hooks/sort';
import { ChecklistsAttrByTypeContainer as AttrByTypeContainer } from '../../../ChecklistsAttrByTypeContainer';
import {
  IChecklistsExtendedFileValue,
  IChecklistsNestedInstanceToDraw as INestedInstanceToDraw,
  TChecklistsFileAttrToDraw as TFileAttrToDraw,
} from '../../../../../models';
import {
  ChecklistsButton as Button,
  ChecklistsContent as Content,
} from '../../../../../components/elements';
import { EChecklistMode } from '../../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import subtitleIncludeSvg from '../../../../../../../../../static/include.svg';
import { useDataTestIdV2 } from '../../../../../../../../../../shared/features/utils/hooks/locators';
import {
  EChecklistAttributeType,
  EChecklistAttributeWidth,
} from '../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsFileAttr } from '../../../ChecklistsFileAttr/ChecklistsFileAttr';

import editHoverSvg from './assets/icons/edit-hover-17x16.svg';
import deleteDefaultSvg from './assets/icons/delete-default-24x24.svg';
import deleteHoverSvg from './assets/icons/delete-hover-24x24.svg';
import arrowDownDefaultSvg from './assets/icons/arrow-down-default-24x24.svg';
import arrowDownHoverSvg from './assets/icons/arrow-down-hover-24x24.svg';
import arrowTopDefaultSvg from './assets/icons/arrow-top-default-24x24.svg';
import arrowTopHoverSvg from './assets/icons/arrow-top-hover-24x24.svg';
import editDefaultSvg from './assets/icons/edit-default-17x16.svg';
import Styled from './ChecklistsChecklistAttrInstance.styles';

interface IProps {
  instanceToDraw: INestedInstanceToDraw;
  totalInstancesNumber: number;
  recordNumber: number;
}

const ChecklistsChecklistAttrInstance: FC<IProps> = ({
  instanceToDraw,
  totalInstancesNumber,
  recordNumber,
}) => {
  const getDataTestId = useDataTestIdV2('checklists__nested-record');

  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const attrToDrawList = useSortBy(checklistsStore.getAttrToDrawListByGroupId(instanceToDraw.id));

  const wrapperRef = useRef(null);

  // Отображает название уже добавленной записи.
  const instanceTitles = useMemo(() => {
    const defaultTitles = {
      title: `Запись №${recordNumber}`,
      subTitle: '',
    };

    const titles = checklistsController.getInstanceTitles(instanceToDraw.id);

    if (titles.title) defaultTitles.title = titles.title;
    if (titles.subtitle) defaultTitles.subTitle = titles.subtitle;

    return defaultTitles;
  }, [recordNumber, attrToDrawList]);

  // Отображает название для новой записи.
  const templateTitle = useMemo<string>(() => {
    if (totalInstancesNumber) {
      return `Запись №${totalInstancesNumber + 1}`;
    } else {
      return 'Запись №1';
    }
  }, [instanceToDraw.isTemplate, totalInstancesNumber]);

  const handleEditInstanceClick = useCallback(() => {
    checklistsController.editNestedInstance(instanceToDraw.attrId, instanceToDraw.id);
  }, []);

  const handleRemoveInstanceClick = useCallback(() => {
    checklistsController.removeNestedInstance(instanceToDraw.attrId, instanceToDraw.id);
  }, []);

  const handleOpenInstanceClick = useCallback(() => {
    checklistsController.openNestedInstance(instanceToDraw.attrId, instanceToDraw.id);
  }, []);

  const handleCloseInstanceClick = useCallback(() => {
    checklistsController.closeNestedInstance(instanceToDraw.attrId, instanceToDraw.id);
  }, []);

  const handleSaveInstanceClick = useCallback(() => {
    checklistsController.saveNestedInstance(instanceToDraw.attrId, instanceToDraw.id);
  }, []);

  const ActionButtons = (
    <Styled.ButtonsWrapper {...getDataTestId('icons')}>
      {checklistsStore.mode === EChecklistMode.Edit ? (
        <>
          <Styled.EditButton
            onClick={handleEditInstanceClick}
            {...getDataTestId('edit-icon')}
            $editDefaultSvg={editDefaultSvg}
            $editHoverSvg={editHoverSvg}
          />

          <Styled.DeleteButton
            onClick={handleRemoveInstanceClick}
            {...getDataTestId('delete-icon')}
            $deleteDefaultSvg={deleteDefaultSvg}
            $deleteHoverSvg={deleteHoverSvg}
          />
        </>
      ) : null}

      <Styled.CollapseButton
        onClick={instanceToDraw.isOpen ? handleCloseInstanceClick : handleOpenInstanceClick}
        {...getDataTestId('collapse-icon')}
        $isCollapsed={instanceToDraw.isOpen}
        $arrowDownDefaultSvg={arrowDownDefaultSvg}
        $arrowDownHoverSvg={arrowDownHoverSvg}
        $arrowTopDefaultSvg={arrowTopDefaultSvg}
        $arrowTopHoverSvg={arrowTopHoverSvg}
      />
    </Styled.ButtonsWrapper>
  );

  const InlineDeleteButton = useMemo(() => {
    if (!totalInstancesNumber) return;

    return (
      <ButtonLink
        color={'critical'}
        dataTestId={getDataTestId('delete-button')['data-test-id']}
        onClick={handleRemoveInstanceClick}
      >
        Удалить
      </ButtonLink>
    );
  }, [totalInstancesNumber]);

  const SaveButton = useMemo(() => {
    const isEditMode = checklistsStore.mode === EChecklistMode.Edit;

    if (!isEditMode) return;

    if (instanceToDraw.isTemplate || instanceToDraw.isEditing) {
      return (
        <Styled.SaveButtonWrapper {...getDataTestId('save-button-wrapper')}>
          <Button
            onClick={handleSaveInstanceClick}
            dataTestId={getDataTestId('save-button')['data-test-id']}
          >
            {instanceToDraw.isTemplate ? 'Добавить' : 'Сохранить'}
          </Button>
        </Styled.SaveButtonWrapper>
      );
    }
  }, [instanceToDraw.isTemplate, instanceToDraw.isEditing]);

  const AvailableFileList = useMemo(() => {
    if (instanceToDraw.isEditing) return;
    if (instanceToDraw.isTemplate) return;

    let plugAttrToDraw: TFileAttrToDraw | null = null;

    const filesToDraw: IChecklistsExtendedFileValue[] = attrToDrawList.reduce<
      IChecklistsExtendedFileValue[]
    >((fileList, attr) => {
      const isFileLink = attr.initialModel.attribute.type === EChecklistAttributeType.FileLink;
      const hasValues = isFileLink ? (attr as TFileAttrToDraw).value.fileValue.length > 0 : false;

      if (hasValues) {
        if (!plugAttrToDraw) plugAttrToDraw = attr;
        fileList.push(...(attr as TFileAttrToDraw).options.extendedFileList);
      }

      return fileList;
    }, []);

    if (!plugAttrToDraw) {
      return
    }

    return (
      <ChecklistsFileAttr
        key={plugAttrToDraw.id}
        attrToDraw={plugAttrToDraw}
        maxInRow={18}
        filesToDrawList={filesToDraw}
        isForceVisible={!instanceToDraw.isOpen}
      />
    );
  }, [attrToDrawList, instanceToDraw.isEditing, instanceToDraw.isTemplate]);

  return (
    <Styled.Wrapper {...getDataTestId()} ref={wrapperRef}>
      <Styled.Header {...getDataTestId('header')}>
        <Styled.Title {...getDataTestId('title')}>
          {instanceToDraw.isTemplate ? templateTitle : instanceTitles.title}
        </Styled.Title>

        {instanceToDraw.isTemplate || instanceToDraw.isEditing ? InlineDeleteButton : ActionButtons}

        {instanceTitles.subTitle && !instanceToDraw.isTemplate ? (
          <Styled.Subtitle {...getDataTestId('subtitle')} $subtitleIncludeSvg={subtitleIncludeSvg}>
            {instanceTitles.subTitle}
          </Styled.Subtitle>
        ) : null}
      </Styled.Header>

      <Content
        isOpen={instanceToDraw.isOpen}
        dataTestId={getDataTestId('attributes')['data-test-id']}
      >
        {attrToDrawList.map(attrToDraw => (
          <AttrByTypeContainer key={attrToDraw.id} attrToDraw={attrToDraw} />
        ))}
        {SaveButton}
      </Content>

      {AvailableFileList}
    </Styled.Wrapper>
  );
};

ChecklistsChecklistAttrInstance.displayName = 'ChecklistsChecklistAttrInstance';

export default observer(ChecklistsChecklistAttrInstance);
