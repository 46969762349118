import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  width: '100%',
  height: '52px',
  padding: ESpaceSize.M,
  borderBottom: `1px solid ${Colors.technicalBgGrey}`,

  '& .react-loading-skeleton': {
    backgroundColor: Colors.technicalBgGrey,
    backgroundImage: 'none',
    borderRadius: ESpaceSize.M,
  },
});

const Header = styled('div')({
  display: 'flex',
  gap: 8,
  justifyContent: 'space-between',
  marginBottom: ESpaceSize.XS,

  '& .icon': {
    borderRadius: 6,
  },
});

const Line = styled('div')({
  width: '100%',
  height: 4,
  borderRadius: ESpaceSize.M,
});

export default {
  Wrapper,
  Header,
  Line,
};
