import React, { FC, useCallback } from 'react';
import { AutoTooltip, Switcher, useShowTooltip } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { StoChecklistsCoreController as Controller } from '../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../hooks';
import { StoStore } from '../../../../../../../../mobx';

import Styled from './StoChecklistsAttrCell.styled';

interface IProps {
  attrId: string;
  stageId: string;
  name: string;
}

const StoChecklistsAttrCell: FC<IProps> = ({ attrId, stageId, name }) => {
  const stoStore = useStore(StoStore);
  const controller = useStore(Controller);

  const params = useStoParams();

  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>();

  const isChecklistSelected = controller.checkIfChecklistIsSelected(params.checklistId);
  const isAttrSelected = controller.checkIfAttrIsSelected(params.checklistId, stageId, attrId);

  const checkIfSwitcherIsDisabled = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;

    return false;
  };

  const handleChange = useCallback((value: boolean): void => {
    controller.changeAttr(params.checklistId, stageId, { id: attrId, isSelected: value });
  }, []);

  return (
    <Styled.Wrapper $isDisabled={!isChecklistSelected}>
      <Switcher
        isBlocked={checkIfSwitcherIsDisabled()}
        value={isAttrSelected}
        onChange={handleChange}
      />

      <AutoTooltip content={name} disabled={!isShowTooltip} position="bottom">
        <Styled.Title ref={ref}>{name}</Styled.Title>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

StoChecklistsAttrCell.displayName = 'StoChecklistsAttrCell';

export default observer(StoChecklistsAttrCell);
