import styled, { css } from 'styled-components';

const Content = styled.div`
  display: flex;
  align-items: center;

  overflow: hidden;

  height: 28px;
`;

const Wrapper = styled.div<{
  $isDisabled: boolean;
  $isWithoutBottomBorder: boolean;
  $isWithoutAlign: boolean;
}>`
  display: flex;
  align-items: center;

  padding: 16px 0 12px 0;

  ${({ $isWithoutBottomBorder }) =>
    !$isWithoutBottomBorder
      ? css`
          border-bottom: 1px solid #e9e6f0;
        `
      : css`
          padding-bottom: 16px;
        `};

  ${({ $isWithoutAlign }) =>
    !$isWithoutAlign &&
    css`
      justify-content: center;
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

const StyledStoChecklistsCellContainer = {
  Content,
  Wrapper,
};

export default StyledStoChecklistsCellContainer;
