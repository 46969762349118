import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  padding: '12px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: ESpaceSize.XS,
  backgroundColor: Colors.generalWhite,
  borderBottom: `1px solid ${Colors.hoverGray}`,
});

export default {
  Wrapper,
};
