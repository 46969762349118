import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  flex: 0 0 300px;

  & > button {
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
`;

const StyledTaskOperationCreateControlButtons = {
  LoaderWrapper,
  ButtonWrapper,
  Wrapper,
};

export default StyledTaskOperationCreateControlButtons;
