import React, { FC, useMemo, useState } from 'react';
import { Button, ENotificationType, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskOperationCreateStore as Store } from '../../mobx/stores';
import { TaskOperationCreateController as Controller } from '../../mobx/controllers';
import { useTasksTaskOperationRoutes } from '../../../../hooks';
import { useTasksBackButtonData } from '../../../../hooks/useTasksBackButtonData';
import getNotificationScheme, {
  ENotificationScheme,
} from '../../../../../../../shared/utils/helpers/getNotificationScheme/getNotificationScheme';
import { SimpleLoader } from '../../../../../../../shared/features/UI/new/SimpleLoader';

import Styled from './TaskOperationCreateControlButtons.styles';

interface IProps {
  dataTestId: string;
}

const TaskOperationCreateControlButtons: FC<IProps> = ({ dataTestId }) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const location = useLocation();
  const routes = useTasksTaskOperationRoutes();
  const backButtonData = useTasksBackButtonData();
  const notificator = useNotificator();

  const [isSuccessButtonLoading, setIsSuccessButtonLoading] = useState<boolean>(false);

  const isCommonInfoTab = store.tabList?.[0]?.id === location.pathname;
  const isFieldsTab = store.tabList?.[1]?.id === location.pathname;
  const isVerificationTab = store.tabList?.[2]?.id === location.pathname;

  const handleButtonSuccessClickFromCommonInfo = (): void => {
    const hasErrors = controller.validateCommonInfoForm();

    if (hasErrors) return;

    controller.storeDefaultDate();
    routes.goToFields();
  };

  const handleButtonSuccessClickFromFields = (): void => {
    const hasErrors = controller.validateFieldsForm();

    if (hasErrors) return;

    routes.goToVerification();
  };

  const handleButtonSuccessClickFromVerification = (): void => {
    controller.createTaskOperation({
      handleSuccess: response => {
        const notification = getNotificationScheme(
          ENotificationScheme.Dark,
          `Вы зарегистрировали работы: ${response.length}`,
          {
            type: ENotificationType.Success,
          }
        );

        notificator.setNotification(notification);
        backButtonData.handler();
      },
      handleLoading: setIsSuccessButtonLoading,
      handleFailure: () => {
        const notification = getNotificationScheme(
          ENotificationScheme.Dark,
          `Ошибка создания регистраций`,
          {
            type: ENotificationType.Warning,
          }
        );

        notificator.setNotification(notification);
      },
    });
  };

  const handleButtonBackClickFromVerification = (): void => {
    routes.goToCommonInfo();
  };

  const handleButtonSuccessClick = (): void => {
    if (isCommonInfoTab) return handleButtonSuccessClickFromCommonInfo();
    if (isFieldsTab) return handleButtonSuccessClickFromFields();
    if (isVerificationTab) return handleButtonSuccessClickFromVerification();
  };

  const successButtonTitle = useMemo(() => {
    if (isVerificationTab) {
      return 'Все верно, зарегистрировать';
    } else {
      return 'Продолжить';
    }
  }, [isVerificationTab]);

  const getDataTestId = useDataTestIdV2(dataTestId);

  const Loader = useMemo(() => {
    return (
      <Button
        color={'default'}
        type={'button'}
        disabled
        dataTestId={getDataTestId('button-loader')['data-test-id']}
      >
        <Styled.LoaderWrapper {...getDataTestId('button-loader__loader-wrapper')}>
          <SimpleLoader dataTestId={getDataTestId()['data-test-id']} size={24} color={'#FFFFFF'} />
        </Styled.LoaderWrapper>
      </Button>
    );
  }, []);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      {isVerificationTab ? (
        <Styled.ButtonWrapper {...getDataTestId('button-wrapper')}>
          <Button
            dataTestId={getDataTestId('button-back')['data-test-id']}
            color={'default'}
            type={'button'}
            onClick={handleButtonBackClickFromVerification}
          >
            Вернуться к постановке
          </Button>
        </Styled.ButtonWrapper>
      ) : null}

      <Styled.ButtonWrapper {...getDataTestId('button-wrapper')}>
        {isSuccessButtonLoading ? (
          Loader
        ) : (
          <Button
            dataTestId={getDataTestId('button-success')['data-test-id']}
            color={'primary'}
            type={'button'}
            onClick={handleButtonSuccessClick}
          >
            {successButtonTitle}
          </Button>
        )}
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

TaskOperationCreateControlButtons.displayName = 'TaskOperationCreateControlButtons';

export default observer(TaskOperationCreateControlButtons);
