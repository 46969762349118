import { runInAction } from 'mobx';
import { groupBy } from 'lodash';

import {
  IPotentialCultureZone,
  IPotentialCultureZoneClientExtended,
} from '../../../../../../../../../../api/models/as-fields/STO/potentialCultureZones.model';
import { ERequestStatus } from '../../../../../../../../../shared/constants/requests';
import { StoService } from '../../../../../../../../../shared/mobx/services/as-fields';
import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import { TypeApiRequest, TypeApiResponse } from '../../../../../../../../../shared/utils/axios2';
import { StoFieldsTabStore } from '../stores';
import { StoStore } from '../../../../mobx';

@provide.transient()
class StoFieldsTabService {
  @lazyInject(StoService)
  protected stoService: StoService;

  @lazyInject(StoFieldsTabStore)
  protected tabStore: StoFieldsTabStore;

  @lazyInject(StoStore)
  protected store: StoStore;

  fetchFieldList = async (payload: TypeApiRequest<'getPotentialCZList'>) => {
    try {
      this.tabStore.setFieldFetchingStatus(ERequestStatus.Pending);

      const firstLoadFieldList = await this.stoService.fetchPotentialCultureZoneList(payload);

      const isMultiplePage = firstLoadFieldList.totalPages > 1;

      this.addFieldListToPotentialZoneList(firstLoadFieldList.content);

      this.tabStore.setIsFirstLoadReady(true);

      if (isMultiplePage) {
        const lastPage = firstLoadFieldList.totalPages;
        let currentPage = 0;

        const promiseCollection: Promise<TypeApiResponse<'getPotentialCZList'>>[] = [];

        while (currentPage < lastPage) {
          currentPage++;

          promiseCollection.push(
            this.stoService.fetchPotentialCultureZoneList({ ...payload, page: currentPage })
          );
        }

        const pageableFieldList = await Promise.all(promiseCollection);

        this.addFieldListToPotentialZoneList([
          ...firstLoadFieldList.content,
          ...pageableFieldList?.flatMap(page => page.content),
        ]);
      }

      this.tabStore.setFieldFetchingStatus(ERequestStatus.Fulfilled);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);

      this.tabStore.setFieldFetchingStatus(ERequestStatus.Rejected);
    }
  };

  addFieldListToPotentialZoneList = (list: IPotentialCultureZone[]) => {
    const setList = this.tabStore.selectedFieldIdSet;

    const parsedList: IPotentialCultureZoneClientExtended[] = list.map(item => {
      if (item.isSelected && !item.isSelectedInAnotherSTO) {
        runInAction(() => {
          setList.add(item.id);
        });
      }

      return {
        ...item,
        clientFieldName: `${item.fieldName?.trim?.() ?? ''}${
          item.name ? ` ${item.name}` : ''
        } ${parseFloat(item.area.toFixed(2))} Га`,
      };
    });

    const groupedList = groupBy(parsedList, 'isSelectedInAnotherSTO');
    const notUsedInAnotherSto = groupedList?.false ?? [];
    const usedInAnotherSto = groupedList?.true ?? [];

    this.tabStore.updateCZIdToPotentialCultureZones(notUsedInAnotherSto);
    this.tabStore.setPotentialCultureZoneListToDisplay(notUsedInAnotherSto);

    const notUsedFieldMap = new Map();
    const usedFieldMap = new Map();

    notUsedInAnotherSto.forEach(field => notUsedFieldMap.set(field.id, field));
    usedInAnotherSto.forEach(field => usedFieldMap.set(field.id, field));

    this.tabStore.setCZIdToPotentialCultureZones(notUsedFieldMap);
    this.tabStore.setCZIdToPotentialCultureZonesUsedInAnotherSto(usedFieldMap);
  };

  displayAllZones = () => {
    this.tabStore.setPotentialCultureZoneListToDisplay(this.tabStore.potentialCultureZoneList);
  };

  searchZonesByClientFieldName = (searchValue: string) => {
    if (this.tabStore.lastSearchFieldValue === searchValue) {
      return;
    }

    this.tabStore.setLastSearchFieldValue(searchValue);

    if (searchValue === '' || !searchValue) {
      this.displayAllZones();
    }

    const searchTermList = searchValue.toLowerCase().split(' ');

    const filteredList = this.tabStore.potentialCultureZoneList.filter(zone => {
      if (!zone.clientFieldName) return false;

      const lowerCaseClientFieldName = zone.clientFieldName.toLowerCase();

      return searchTermList.every(term => lowerCaseClientFieldName.includes(term));
    });

    this.tabStore.setPotentialCultureZoneListToDisplay(filteredList);
  };

  toggleField = (fieldId: string) => {
    runInAction(() => {
      const field = this.tabStore.czIdToPotentialCultureZones.get(fieldId);
      const isSelected = !field.isSelected;

      field.isSelected = !field.isSelected;

      this.store.setHasChanges(true);

      if (isSelected) {
        this.tabStore.selectedFieldIdSet.add(fieldId);
      } else {
        this.tabStore.selectedFieldIdSet.delete(fieldId);
      }
    });
  };

  toggleAllFields = (state: boolean) => {
    runInAction(() => {
      const setList = this.tabStore.selectedFieldIdSet;

      this.store.setHasChanges(true);

      this.tabStore.potentialCultureZoneListToDisplay.forEach(item => {
        this.tabStore.partiallyUpdateCZInPotentialCultureZonesMap(item.id, {
          isSelected: state,
          isClientSelected: state,
        });

        if (state) {
          setList.add(item.id);
        } else {
          setList.delete(item.id);
        }
      });
    });
  };

  transferField = (fieldId: string) => {
    this.store.setHasChanges(true);

    this.tabStore.transferredFiledIdSet.add(fieldId);
  };

  cancelTransferField = (fieldId: string) => {
    this.tabStore.transferredFiledIdSet.delete(fieldId);
  };
}

export default StoFieldsTabService;
