import { Input } from '@farmlink/farmik-ui';
import { FC, useState } from 'react';

import { IInputProps } from '../../types/CommonProps.types';

const NameInput: FC<IInputProps> = ({ cultureZone, dataTestId }) => {
  const [inputValue, setInputValue] = useState<string>(cultureZone?.name);

  const handleChange = (value: string) => {
    setInputValue(String(value));

    if (cultureZone) {
      cultureZone.name = String(value);
    }
  };

  return (
    <Input
      type={'text'}
      label={'Название участка'}
      onChange={handleChange}
      value={inputValue}
      dataTestId={dataTestId}
    />
  );
};

export default NameInput;
