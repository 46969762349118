import { useMemo } from 'react';

import { IChecklistsAttrToDraw } from '../../models';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ChecklistsStore } from '../../mobx/stores';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

const useChecklistsAttrErrorList = ({
  groupId,
  dependentFileAttrId,
  validationScheme,
}: IChecklistsAttrToDraw): string[] => {
  const { getAttrToDraw } = useStore(ChecklistsStore);

  const dependentFileAttrScheme = getAttrToDraw<EAttrType.FileLink>(groupId, dependentFileAttrId)
    ?.validationScheme;

  return useMemo<string[]>(() => {
    const isShownErrorInParentAttr: boolean = validationScheme.isShowError;
    const isShownErrorInFileAttr: boolean = dependentFileAttrScheme?.isShowError;
    const isShownErrorInBothAttrs = Boolean(isShownErrorInParentAttr && isShownErrorInFileAttr);

    if (isShownErrorInBothAttrs) {
      return [dependentFileAttrScheme.errorTitle, validationScheme.errorTitle];
    }

    if (isShownErrorInParentAttr) {
      return [validationScheme.errorTitle];
    }

    if (isShownErrorInFileAttr) {
      return [dependentFileAttrScheme.errorTitle];
    }

    return [];
  }, [
    validationScheme.isShowError,
    validationScheme.errorTitle,
    dependentFileAttrScheme?.isShowError,
    dependentFileAttrScheme?.errorTitle,
  ]);
};

export default useChecklistsAttrErrorList;
