import { makeAutoObservable } from 'mobx';

import { EFieldFill } from '../../../../../../../../../dashboard/constants/FieldFill.enum';
import { IFillStrategy } from '../../interfaces/FillStrategy.model';
import { provide } from '../../../../../../../../utils/IoC';

@provide.singleton()
class LayerFillStore {
  private _fillType: EFieldFill = EFieldFill.None;

  private _strategy: IFillStrategy | null = null;

  private _showNotification = true;

  constructor() {
    makeAutoObservable(this);
  }

  public get fillType() {
    return this._fillType;
  }

  public set fillType(value) {
    this._fillType = value;
  }

  public get strategy() {
    return this._strategy;
  }

  public set strategy(value) {
    this._strategy = value;
  }

  public get showNotification() {
    return this._showNotification;
  }

  public set showNotification(value) {
    this._showNotification = value;
  }

  // Выбранное value NDVI или MSAVI
  public isIndexValue() {
    return [EFieldFill.Ndvi, EFieldFill.Msavi].includes(this._fillType);
  }
}

export default LayerFillStore;
