import { makeAutoObservable } from 'mobx';
import { ReactNode } from 'react';

import { provide } from '../../../../../utils/IoC';
import {
  ITableBuilderCell as ICell,
  ITableBuilderColumn as IColumn,
  ITableBuilderRow as IRow,
} from '../../../models/data';
import {
  ITableBuilderPaginationConfig as IPaginationConfig,
  ITableBuilderRowConfig as IRowConfig,
} from '../../../models/configs';
import { TTableBuilderStylePreset } from '../../../types/styles';

interface ISetElementListOptions {
  isClearPreviousList?: boolean;
}

@provide.singleton()
class TableBuilderStore<E = any, B = string> {
  /**
   * Коллекция колонок таблиц.
   */
  private _columnsByBuilderId: Map<B, Map<keyof E, IColumn<E, B>>> = new Map();

  /**
   * Коллекция значений рядов, т.е. тех объектов, которые используются в качестве ряда [E].
   */
  private _rowsByBuilderId: Map<B, Map<string, IRow<E, B>>> = new Map();

  /**
   * Коллекция ячеек.
   */
  private _cellsByBuilderId: Map<B, Map<keyof E, ICell<E, B>>> = new Map();

  /**
   * Коллекция настроек [конфигов] для ряда.
   */
  private _rowConfigsByBuilderId: Map<B, IRowConfig<E>> = new Map();

  /**
   * Коллекция конфигов динамической пагинации.
   */
  private _paginationConfigsByBuilderId: Map<B, IPaginationConfig> = new Map();

  /**
   * Коллекция текущих страниц.
   */
  private _currentPagesByBuilderId: Map<B, number> = new Map();

  /**
   * Коллекция всего страниц.
   */
  private _totalPagesByBuilderId: Map<B, number> = new Map();

  /**
   * Коллекция состояний загрузки элементов.
   */
  private _isFetchingElementsByBuilderId: Map<B, boolean> = new Map();

  /**
   * Флаг для отображения заглушки, когда таблица была только создана
   * и не было выполнено еще ни одного запроса за данными.
   */
  private _isShowDefaultPlug: Map<B, boolean> = new Map();

  /**
   * Коллекция заглушек для для таблиц.
   */
  private _tablePlugCollectionByBuilderIdByPlugId: Map<B, Map<string, ReactNode>> = new Map();

  /**
   * Коллекция активных заглушек.
   */
  private _tableActivePlugByBuilderId: Map<B, string | false> = new Map();

  private _tableStylePresetByBuilderId: Map<B, TTableBuilderStylePreset> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  getColumnList = (builderId: B): IColumn<E, B>[] => {
    const columns = this._columnsByBuilderId.get(builderId);

    if (columns) {
      return [...columns.values()];
    }

    return [];
  };

  getRowList = (builderId: B): IRow<E, B>[] => {
    const rows = this._rowsByBuilderId.get(builderId);

    if (rows) {
      return [...rows.values()];
    }

    return [];
  };

  getCellList = (builderId: B): ICell<E, B>[] => {
    const cells = this._cellsByBuilderId.get(builderId);

    if (cells) {
      return [...cells.values()];
    }

    return [];
  };

  getRowConfig = (builderId: B): IRowConfig<E> => {
    const rowConfig = this._rowConfigsByBuilderId.get(builderId);

    return rowConfig ?? {};
  };

  getPaginationConfig = (builderId: B): IPaginationConfig => {
    const paginationConfig = this._paginationConfigsByBuilderId.get(builderId);

    return paginationConfig ?? {};
  };

  getCurrentPage = (builderId: B): number => {
    const currentPage = this._currentPagesByBuilderId.get(builderId);

    return currentPage ?? 0;
  };

  getTotalPages = (builderId: B): number => {
    const totalPages = this._totalPagesByBuilderId.get(builderId);

    return totalPages ?? 0;
  };

  getIsFetchingElements = (builderId: B): boolean => {
    const isFetchingElements = this._isFetchingElementsByBuilderId.get(builderId);

    return isFetchingElements;
  };

  getIsShowDefaultPlug = (builderId: B): boolean => {
    const isShowDefaultPlug = this._isShowDefaultPlug.get(builderId);

    return isShowDefaultPlug;
  };

  getTablePlugNode = (builderId: B, plugId: string) => {
    const plugConfig = this._tablePlugCollectionByBuilderIdByPlugId?.get(builderId)?.get(plugId);

    return plugConfig;
  };

  getTableActivePlugId = (builderId: B) => {
    const activeId = this._tableActivePlugByBuilderId.get(builderId);

    return activeId;
  };

  getActivePlugNode = (builderId: B) => {
    const activePlugId = this.getTableActivePlugId(builderId);

    if (activePlugId) {
      return this.getTablePlugNode(builderId, activePlugId);
    }
  };

  getTableStylePreset = (builderId: B) => {
    const stylePreset = this._tableStylePresetByBuilderId.get(builderId);

    return stylePreset;
  };

  setColumnList = (
    builderId: B,
    columnList: IColumn<E>[],
    options?: ISetElementListOptions
  ): void => {
    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(this._columnsByBuilderId.get(builderId)?.entries?.() || [])];

    const newCollection = new Map<keyof E, IColumn<E>>(previousEntryList);

    columnList.forEach(column => {
      newCollection.set(column.id, column);
    });

    this._columnsByBuilderId.set(builderId, newCollection);
  };

  setRowList = (builderId: B, rowList: IRow<E, B>[], options?: ISetElementListOptions): void => {
    const collection = this._rowsByBuilderId.get(builderId);

    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(collection?.entries?.() || [])];

    const newCollection = new Map<string, IRow<E, B>>(previousEntryList);

    rowList.forEach(row => {
      newCollection.set(row.id, row);
    });

    this._rowsByBuilderId.set(builderId, newCollection);
  };

  setCellList = (builderId: B, cellList: ICell<E, B>[], options?: ISetElementListOptions): void => {
    const collection = this._cellsByBuilderId.get(builderId);

    const previousEntryList = options?.isClearPreviousList
      ? []
      : [...(collection?.entries?.() || [])];

    const newCollection = new Map<keyof E, ICell<E, B>>(previousEntryList);

    cellList.forEach(cell => {
      newCollection.set(cell.id, cell);
    });

    this._cellsByBuilderId.set(builderId, newCollection);
  };

  setPartialRowConfig = (builderId: B, partialConfig: Partial<IRowConfig<E>>): void => {
    const rowConfig = this._rowConfigsByBuilderId.get(builderId) ?? {};

    this._rowConfigsByBuilderId.set(builderId, { ...rowConfig, ...partialConfig });
  };

  setPartialPaginationConfig = (builderId: B, partialConfig: Partial<IPaginationConfig>): void => {
    const paginationConfig = this._paginationConfigsByBuilderId.get(builderId) ?? {};

    this._paginationConfigsByBuilderId.set(builderId, { ...paginationConfig, ...partialConfig });
  };

  setCurrentPage = (builderId: B, currentPage: number): void => {
    this._currentPagesByBuilderId.set(builderId, currentPage);
  };

  setTotalPages = (builderId: B, totalPages: number): void => {
    this._totalPagesByBuilderId.set(builderId, totalPages);
  };

  setIsFetchingElements = (builderId: B, value: boolean): void => {
    this._isFetchingElementsByBuilderId.set(builderId, value);
  };

  setIsShowDefaultPlug = (builderId: B, value: boolean): void => {
    this._isShowDefaultPlug.set(builderId, value);
  };

  setTablePlugByPlugId = (builderId: B, plugId: string, autoBuildedPlugNode: ReactNode) => {
    const plugConfigCollection = this._tablePlugCollectionByBuilderIdByPlugId.get(builderId);

    if (plugConfigCollection) {
      plugConfigCollection.set(plugId, autoBuildedPlugNode);

      return;
    }

    const newPlugCollection: Map<string, ReactNode> = new Map();

    newPlugCollection.set(plugId, autoBuildedPlugNode);

    this._tablePlugCollectionByBuilderIdByPlugId.set(builderId, newPlugCollection);
  };

  setActivePlugId = (builderId: B, activePlugId: string) => {
    this._tableActivePlugByBuilderId.set(builderId, activePlugId);
  };

  setStylePreset = (builderId: B, stylePreset: TTableBuilderStylePreset) => {
    this._tableStylePresetByBuilderId.set(builderId, stylePreset);
  };

  deleteColumnList = (builderId: B): void => {
    this._columnsByBuilderId.delete(builderId);
  };

  deleteRowList = (builderId: B): void => {
    this._rowsByBuilderId.delete(builderId);
  };

  deleteCellList = (builderId: B): void => {
    this._cellsByBuilderId.delete(builderId);
  };

  deleteRowConfig = (builderId: B): void => {
    this._rowConfigsByBuilderId.delete(builderId);
  };

  deletePaginationConfig = (builderId: B): void => {
    this._paginationConfigsByBuilderId.delete(builderId);
  };

  deleteCurrentPage = (builderId: B): void => {
    this._currentPagesByBuilderId.delete(builderId);
  };

  deleteTotalPages = (builderId: B): void => {
    this._totalPagesByBuilderId.delete(builderId);
  };

  deleteIsFetchingElements = (builderId: B): void => {
    this._isFetchingElementsByBuilderId.delete(builderId);
  };

  deleteIsShowDefaultPlug = (builderId: B): void => {
    this._isShowDefaultPlug.delete(builderId);
  };

  deleteTablePlugConfig = (builderId: B) => {
    this._tablePlugCollectionByBuilderIdByPlugId.delete(builderId);
  };

  deleteTableActivePlug = (builderId: B) => {
    this._tableActivePlugByBuilderId.delete(builderId);
  };

  deleteTableStylePreset = (builderId: B) => {
    this._tableStylePresetByBuilderId.delete(builderId);
  };
}

export default TableBuilderStore;
