import { FC, memo } from 'react';
import { Chip, Typography } from '@farmlink/farmik-ui';
import moment from 'moment/moment';

import { ITask } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import { default as Styled } from './TasksDrawerStatusBlock.styled';

interface IProps {
  task: ITask;
  boldText: string;
  normalText: string;
  dataTestId: string;
}
const TasksDrawerStatusBlock: FC<IProps> = ({ task, boldText, normalText, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__status-block`);

  return (
    <Styled.Wrapper $daysLeft={task?.daysLeft} $taskStatus={task?.status}>
      <Styled.BoldText {...getDataTestId('bold-text')} variant={'bodyAccent'}>
        {boldText}
      </Styled.BoldText>

      <Typography dataTestId={getDataTestId('description')['data-test-id']}>
        {normalText}
      </Typography>

      <Styled.TooltipWrapper {...getDataTestId('tooltip-wrapper')}>
        <Chip
          className="task-status-data-chip"
          color="default"
          size="medium"
          icon="info"
          dataTestId={getDataTestId('task-status')['data-test-id']}
          iconTooltip={{
            content: (
              <Styled.TooltipContent {...getDataTestId('tooltip-content')}>
                <Styled.TooltipContentRow {...getDataTestId('tooltip-content-row')}>
                  <Typography
                    dataTestId={getDataTestId('date-start-label')['data-test-id']}
                    color={'secondaryLight'}
                  >
                    Плановое начало
                  </Typography>
                  <Typography
                    dataTestId={getDataTestId('date-start')['data-test-id']}
                    color={'secondaryLight'}
                  >
                    {moment(task.planStartDate).format('DD.MM.YYYY')}
                  </Typography>
                </Styled.TooltipContentRow>

                <Styled.TooltipContentRow>
                  <Typography
                    dataTestId={getDataTestId('date-end-label')['data-test-id']}
                    color={'secondaryLight'}
                  >
                    Плановое завершение
                  </Typography>
                  <Typography
                    dataTestId={getDataTestId('date-end')['data-test-id']}
                    color={'secondaryLight'}
                  >
                    {moment(task.planEndDate).format('DD.MM.YYYY')}
                  </Typography>
                </Styled.TooltipContentRow>
              </Styled.TooltipContent>
            ),
            position: 'bottom-left',
            $translate: {
              x: '20px',
            },
          }}
        />
      </Styled.TooltipWrapper>
    </Styled.Wrapper>
  );
};

TasksDrawerStatusBlock.displayName = 'TasksDrawerStatusBlock';

export default memo(TasksDrawerStatusBlock);
