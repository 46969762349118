import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledStoChecklists = {
  Wrapper,
};

export default StyledStoChecklists;
