import React, { FC, memo, useCallback, useMemo } from 'react';
import { ButtonLink, Switcher } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { IStoOperationsListItem } from '../../../../models';
import { useStoParams } from '../../../../../../../../hooks';

import Styled from './StoOperationsListItem.styles';

type TProps = {
  onSettingsClick: (id: string) => void;
  onSwitcherClick: (id: string, value: boolean) => void;
} & IStoOperationsListItem;

const StoOperationsListItem: FC<TProps> = ({
  onSettingsClick,
  onSwitcherClick,
  techOperationTypeId,
  techOperationTypeName,
  checklistCountLabel,
  isSelected,
  isSettingsManagementAllowed,
  isLast,
  isViewOnly,
}) => {
  const { versionId } = useStoParams();

  const handleSwitcherClick = useCallback(
    (value: boolean): void => {
      onSwitcherClick(techOperationTypeId, value);
    },
    [onSwitcherClick]
  );

  const handleSettingsClick = useCallback((): void => {
    onSettingsClick(techOperationTypeId);
  }, [onSettingsClick]);

  const isDisabledSettingsButton = useMemo(() => {
    if (!isSettingsManagementAllowed) return true;
    if (!isSelected) return true;
  }, [isSettingsManagementAllowed, isSelected, isViewOnly, versionId]);

  const isDisabledSwitcher = useMemo(() => {
    if (isViewOnly) return true;
  }, [isSettingsManagementAllowed, isSelected, isViewOnly]);

  const editButtonText = versionId || isViewOnly ? 'Посмотреть настройки' : 'Настроить';

  const getDataTestId = useDataTestIdV2('sto__operations__list__item');

  return (
    <Styled.Wrapper {...getDataTestId()} $isViewOnly={isViewOnly} $isLast={isLast}>
      <Switcher
        onChange={handleSwitcherClick}
        value={isSelected}
        isBlocked={isDisabledSwitcher}
        dataTestId={getDataTestId('switcher')['data-test-id']}
      />

      <Styled.Body {...getDataTestId('body')} $isSelected={isSelected}>
        <Styled.Operation {...getDataTestId('operation')}>{techOperationTypeName}</Styled.Operation>

        <Styled.Checklists {...getDataTestId('checklists')}>
          {checklistCountLabel}
        </Styled.Checklists>
      </Styled.Body>

      <ButtonLink
        onClick={handleSettingsClick}
        color={isSelected && !isDisabledSettingsButton ? 'accent' : 'secondary'}
        icon={{
          type: 'arrow-right',
          placement: 'right',
        }}
        isDisabled={isDisabledSettingsButton}
        dataTestId={getDataTestId('settings-button')['data-test-id']}
      >
        {editButtonText}
      </ButtonLink>
    </Styled.Wrapper>
  );
};

StoOperationsListItem.displayName = 'StoOperationsListItem';

export default memo(StoOperationsListItem);
