import L, { LatLngBounds, PolylineOptions } from 'leaflet';
import { Geometry } from '@turf/turf';
import { cloneDeep } from 'lodash';

import { getLatLngsFromGeometry } from '../../helpers';

/**
 * Абстрактный полигон. Инициализирует геометрию полигона.
 * Создаёт уникальный id
 */
abstract class AbstractPolygon extends L.Polygon {
  public id: number;
  public initialBounds: LatLngBounds | null = null;

  protected _prevOptions: PolylineOptions;

  protected _initialGeometry: Geometry;

  constructor(geometry: Geometry, options?: PolylineOptions) {
    const latLngs = getLatLngsFromGeometry(geometry);

    super(latLngs, { ...options });

    this.id = L.Util.stamp(this);
    this._prevOptions = cloneDeep(options);
    this._initialGeometry = geometry;
    this.initialBounds = this.getBounds();
  }

  public get initialGeometry() {
    return this._initialGeometry;
  }

  public set initialGeometry(value) {
    this._initialGeometry = value;
  }
}

export default AbstractPolygon;
