import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  gap: 24px;
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0;
  border: none;
  background-color: ${Colors.borderGray};
`;

const ButtonLinkWrapper = styled.div`
  align-self: center;
`;

const Styled = {
  Wrapper,
  Hr,
  ButtonLinkWrapper,
};

export default Styled;
