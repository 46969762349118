import { TaskStatuses } from '../../../modules/dashboard/modules/operations/stores/tasks.store';
import { TypeApiRoute } from '../../models/type.api.request';
import { ETaskType, ITask } from '../../models/as-fields/task/task.model';

type TypeRequest = {
  seasonYear: number;
  organizationId?: string;
  cultureId?: string[];
  operationId?: string[];
  operationTypeId?: string[];
  fieldId?: string[];
  assigneeId?: string[];
  planDateFrom?: string;
  planDateTo?: string;
  technologyId?: string;
  experimentId?: string;
  experimentStepId?: string;
  status?: TaskStatuses[];
  expired?: boolean;
  page?: number;
  size?: number;
  typeList?: ETaskType[];
};

type TypeResponse = {
  content: ITask[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalElements: number;
  last: boolean;
  totalPages: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

export const getTaskList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/task/search`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
