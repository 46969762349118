import L from 'leaflet';

const polygonOptions: L.PM.DrawModeOptions = {
  allowSelfIntersection: false,
  continueDrawing: true,
  preventMarkerRemoval: false,
  removeLayerBelowMinVertexCount: false,
  hintlineStyle: {
    fillColor: '#fff9e8',
    color: '#FFD765',
    dashArray: [5, 5],
  },
  snappable: true,
  snapDistance: 5,
  pathOptions: {
    fillColor: '#fff9e8',
    color: '#FFD765',
  },
  markerStyle: {
    // @ts-ignore
    color: '#FFD765',
  },
  templineStyle: {
    fillColor: '#fff9e8',
    color: '#FFD765',
  },
};

export default polygonOptions;
