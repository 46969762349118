import styled, { css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import { EStoStatus } from '../../../../../../api/models/as-fields/STO/STO.model';

const getStatusColors = (status: EStoStatus) => {
  switch (status) {
    case EStoStatus.Draft:
      return css`
        color: #7e8b9d;
        background-color: #f6f7f8;
      `;

    case EStoStatus.Active:
      return css`
        color: ${Colors.accentGreen};
        background-color: ${Colors.lightGreen};
      `;

    case EStoStatus.Modified:
      return css`
        color: #ef6517;
        background-color: #fff5e4;
      `;

    case EStoStatus.Inactive:
      return css`
        color: ${Colors.accentPink};
        background-color: ${Colors.lightPink};
      `;

    default:
  }
};

const Wrapper = styled.div<{ $status: EStoStatus }>`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;

  border-radius: 8px;
  padding: 2px 8px;

  ${({ $status }) => getStatusColors($status)};
`;

const StyledStoChipStatus = {
  Wrapper,
};

export default StyledStoChipStatus;
