import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { ELeftPanelType } from '../../interfaces/LeftPanelType.enum';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../mobx';

import FieldsList from './components/FieldsList/FieldsList';
import Filters from './components/Filters/Filters';
import Styled from './LeftPanel.styles';

const LeftPanel: FC = () => {
  // Сторы
  const store = useStore(Store);

  const variants = useMemo(
    () => ({
      [ELeftPanelType.FIELDS_LIST]: <FieldsList />,
      [ELeftPanelType.FILTER]: <Filters />,
    }),
    []
  );

  return <Styled.Wrapper>{variants[store.leftPanelType]}</Styled.Wrapper>;
};

export default observer(LeftPanel);
