import { FC, ReactNode, memo } from 'react';
import { Header, DefaultTheme } from '@farmlink/farmik-ui';
import { ThemeProvider } from 'styled-components';

import Styled from './styles';

export type IFullscreenBackButtonProps = {
  backButtonHandler: () => void;
  backButtonText: ReactNode;

  arrowText?: string;
  actionButton?: ReactNode;
  rightSideContent?: ReactNode;
  titleAdditionalElement?: ReactNode;
  underTitleContent?: ReactNode;
};

const FullscreenBackButton: FC<IFullscreenBackButtonProps> = props => {
  const {
    backButtonHandler,
    backButtonText,
    rightSideContent,
    titleAdditionalElement,
    arrowText,
    underTitleContent,
  } = props;

  return (
    <Styled.BackButton>
      <ThemeProvider theme={DefaultTheme}>
        <Header
          onArrowClick={backButtonHandler}
          title={backButtonText}
          goBackText={arrowText}
          dataTestId={'header-back-button'}
          fullWidth
          rightSideContent={rightSideContent}
          titleAdditionalElement={titleAdditionalElement}
          dataTestIdConfig={{
            headerTitle: 'fullscreen-header-title',
          }}
        />
        {underTitleContent && (
          <Styled.FullscreenHeaderUnderTitleWrapper>
            {underTitleContent}
          </Styled.FullscreenHeaderUnderTitleWrapper>
        )}
      </ThemeProvider>
    </Styled.BackButton>
  );
};

export default memo(FullscreenBackButton);
