import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskOperationCreateStore as Store } from '../../../../mobx/stores';
import { TaskOperationCreateController as Controller } from '../../../../mobx/controllers';
import { TASK_OPERATION_CREATE_FORM_REQUIRED_TEXT as FORM_REQUIRED_TEXT } from '../../constants';

interface IProps {
  dataTestId: string;
}

const TaskOperationCreateConfigSelect: FC<IProps> = ({ dataTestId }) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const hasError = store.checkIfTaskHasError('operationValues');

  const errorList = useMemo<string[]>(() => {
    if (hasError) return [FORM_REQUIRED_TEXT];
    else return [];
  }, [hasError]);

  const handleChange = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (_, otherData) => {
      if (store.config.isMultiselect) {
        controller.changeConfig(!otherData?.selectedValueList ? [] : otherData.selectedValueList);
      } else {
        controller.changeConfig(!otherData.option ? [] : [otherData.option]);
      }
    },
    []
  );

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      isRequired: true,
      defaultValueList: controller.configDefaultValueList,
      onChange: handleChange,
      placeholder: store.config.placeholderUi,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
          },
        },
        multiselect: store.config.isMultiselect
          ? {
              options: {
                itemStyle: 'checkbox',
              },
              isSelectedFirst: true,
            }
          : null,
      },
    },
    body: {
      optionList: store.configSelectOptionList,
      isKeepSelectedOptions: true,
    },
    visual: {
      label: store.config.descriptionUi,
      isBlocked: controller.isOperationSelectBlocked,
      isPreventCloseAfterSelect: true,
      isRounded: true,
    },
    validation: {
      error: {
        errorList,
      },
    },
    other: {
      dataTestId,
    },
  };

  return <NewDropdown config={dropdownConfig} />;
};

TaskOperationCreateConfigSelect.displayName = 'TaskOperationCreateConfigSelect';

export default observer(TaskOperationCreateConfigSelect);
