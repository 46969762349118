import { useEffect, useMemo, useState } from 'react';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TableBuilderController } from '../../../../../../../../../shared/features/TableBuilder/mobx/controllers';
import { StoHistoryController, StoHistoryStore } from '../../mobx';
import { useStoNavigation, useStoParams } from '../../../../../../hooks';
import { StoController } from '../../../../mobx';
import { EStoTab } from '../../../../models/StoCommon';

import { getTableConfig } from './getHistoryTableConfig';

const useHistoryTab = () => {
  const tableController = useStore(TableBuilderController);
  const { fetchStoHistory } = useStore(StoHistoryController);
  const store = useStore(StoHistoryStore);
  const controller = useStore(StoController);

  const { navigateToSto } = useStoNavigation();
  const { stoId } = useStoParams();

  const [isLoading, setIsLoading] = useState(false);

  const config = useMemo(
    () =>
      getTableConfig((id: string | 'current') => {
        if (id === 'current') {
          navigateToSto(stoId);
        } else {
          navigateToSto(stoId, false, { query: { versionId: id }, saveQuery: true });
        }
        controller.changeStoTab(EStoTab.Summary);
      }),
    []
  );

  const initHistoryTable = () => {
    fetchStoHistory(stoId);
  };

  useEffect(() => {
    if (!stoId) return;

    initHistoryTable();

    setIsLoading(true);

    return () => {
      store.clearStoHistoryStore();
    };
  }, [stoId]);

  useEffect(() => {
    if (store.isHistoryFetched) {
      tableController.initiateTable(config);
      tableController.addElementList('sto-history-tab', store.stoHistoryVersionList, 'id', {
        isClearPreviousList: true,
      });

      tableController.hideLoader('sto-history-tab');

      setIsLoading(false);
    }
  }, [store.stoHistoryVersionList]);

  return { isLoading };
};

export default useHistoryTab;
