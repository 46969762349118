import { FC, memo } from 'react';

import cultureSvg from '../../../../../../operations/components/TaskView/assets/cultureSvg.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  taskCultureName: string;
  dataTestId: string;
}
const TasksDrawerCultureName: FC<IProps> = ({ taskCultureName, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__culture-name`);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label {...getDataTestId('label')}>Культура</Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={cultureSvg} />

      <Styled.Info {...getDataTestId('info')}>
        {taskCultureName || 'Культура не указана'}
      </Styled.Info>
    </Styled.InfoBlock>
  );
};

TasksDrawerCultureName.displayName = 'TasksDrawerCultureName';

export default memo(TasksDrawerCultureName);
