import { checklistModalsConfig } from '../../dashboard/modules/operationsAndTasks/modules/fullscreen/checklist/modals/modalsConfig';
import { taskModalsConfig } from '../../dashboard/modules/operations/modals/modalsConfig';
import { IModal } from '../components/Modal/Modal';

export interface IModalConfig {
  [K: string]: IModal[];
}

const modalConfigList: IModalConfig = {
  ...taskModalsConfig,
  ...checklistModalsConfig,
};

export const displayModalWindow = (
  uniqueKey: string,
  name: string,
  successHandler?: IModal['successButton']['handler'],
  denyHandler?: IModal['denyButton']['handler']
): IModal => {
  const modal = modalConfigList?.[uniqueKey]?.find(configModal => configModal.name === name);

  if (modal) {
    return {
      ...modal,
      successButton: modal?.successButton && {
        ...modal.successButton,
        handler: successHandler || modal?.successButton?.handler,
      },
      denyButton: modal?.denyButton && {
        ...modal.denyButton,
        handler: denyHandler || modal?.denyButton?.handler,
      },
    };
  }
};
