import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useStore } from '../../../../../../../../shared/utils/IoC';
import { ReactComponent as InfoIcon } from '../../../../../../../static/info.svg';
import { FieldCulturesController, FieldCulturesStore } from '../../../mobx';

import Styled from './OverlapZoneModal.styles';

const OverlapZoneModal: FC = () => {
  // Сторы
  const store = useStore(FieldCulturesStore);

  // Контроллеры
  const controller = useStore(FieldCulturesController);

  const modal = useModal();

  const multipleOverlaps = store.overlappedCultureZonesList.length > 1;
  const atLeastOneHasExp = store.overlappedCultureZonesList.some(zone => Boolean(zone.experiment));

  const removeOverlappedZones = () => {
    const { overlappedCultureZonesList: list, editableCultureZone } = store;

    list.forEach(zone => controller.deleteCultureZone(zone));
    controller.enableEditCultureZone(editableCultureZone, false);

    store.clearOverlappedCultureZonesCollection();

    modal.closeModal();
  };

  const drawAgain = () => {
    store.clearOverlappedCultureZonesCollection();
    controller.restoreSnapshot();
    controller.enableDrawPolygon();

    modal.closeModal();
  };

  const getTitle = () => {
    return !multipleOverlaps
      ? 'Контур перекрывает существующий участок'
      : 'Контур перекрывает существующие участки';
  };

  const getDescription = () => {
    if (atLeastOneHasExp) {
      return multipleOverlaps
        ? `Измените контур нового участка:\n он полностью перекрывает несколько существующих участков, часть из которых включена в опыт`
        : `Измените контур нового участка:\n он полностью перекрывает существующий участок, включенный в опыт`;
    }

    return multipleOverlaps
      ? `Вы можете изменить контур нового участка или удалить старые участки. Вместе с участками удалятся связанные с ними данные: задачи и чек-листы`
      : `Вы можете изменить контур нового участка или удалить старый участок. Вместе с участком удалятся связанные с ним данные: задачи и чек-листы`;
  };

  const getActions = () => {
    if (atLeastOneHasExp) {
      return (
        <ModalFooter
          successButton={{
            title: 'Изменить контур нового участка',
            handler: drawAgain,
          }}
        />
      );
    }

    return (
      <ModalFooter
        successButton={{
          title: multipleOverlaps ? 'Удалить старые участки' : 'Удалить старый участок',
          color: 'secondary',
          handler: removeOverlappedZones,
        }}
        denyButton={{
          title: 'Изменить контур',
          handler: drawAgain,
        }}
      />
    );
  };

  return (
    <Styled.Wrapper>
      <InfoIcon />
      <Styled.Title data-test-id="seasons-modal-title">{getTitle()}</Styled.Title>

      <Styled.Description data-test-id="seasons-modal-body">{getDescription()}</Styled.Description>

      {getActions()}
    </Styled.Wrapper>
  );
};

export default observer(OverlapZoneModal);
