import { memo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { useDataTestId } from '../../features/utils/hooks/locators';

import monitoringTaskTypeSvg from './assets/monitoringTaskType.svg';
import operationTaskTypeSvg from './assets/operationTaskType.svg';
import Styled from './OperationTaskTypeIcon.styles';

interface IProps {
  taskType: string;
  dataTestId: string;
  $iconSize?: string;
  $margin?: string;
  withTooltip?: boolean;
}

const OperationTaskTypeIcon = ({
  taskType,
  dataTestId,
  withTooltip = true,
  $iconSize,
  $margin,
}: IProps) => {
  const getDataTestId = useDataTestId(`${dataTestId}__operation-task-type-icon`);

  switch (taskType) {
    case 'MONITORING':
      return (
        <AutoTooltip
          position={'bottom-right'}
          content={'Осмотр'}
          dataTestId={getDataTestId('tooltip')['data-test-id']}
          disabled={!withTooltip}
        >
          <Styled.TaskTypeIcon
            {...getDataTestId('monitoring')}
            $iconSize={$iconSize}
            $margin={$margin}
            src={monitoringTaskTypeSvg}
          />
        </AutoTooltip>
      );

    case 'OPERATION':
      return (
        <AutoTooltip
          position={'bottom-right'}
          content={'Регистрация'}
          dataTestId={getDataTestId('tooltip')['data-test-id']}
          disabled={!withTooltip}
        >
          <Styled.TaskTypeIcon
            {...getDataTestId('operation')}
            $iconSize={$iconSize}
            $margin={$margin}
            src={operationTaskTypeSvg}
          />
        </AutoTooltip>
      );

    default:
      return (
        <AutoTooltip
          position={'bottom-right'}
          content={'Plug'}
          dataTestId={getDataTestId('tooltip')['data-test-id']}
          disabled={!withTooltip}
        >
          <Styled.TaskTypeIcon
            {...getDataTestId('monitoring')}
            $iconSize={$iconSize}
            $margin={$margin}
            src={monitoringTaskTypeSvg}
          />
        </AutoTooltip>
      );
  }
};

export default memo(OperationTaskTypeIcon);
