import { Checkbox } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { TFieldNameOption } from '../../../../../../../../utils/filters/FieldName.filter';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { ListCard } from '../../../../../../../../components/ListCard';
import MultiListSection from '../../components/MultiListSection/MultiListSection';
import { FieldsViewStore as Store } from '../../../../../../mobx';

import Styled from './FieldNameFilter.styles';

const FieldNameFilterSection: FC = () => {
  // Сторы
  const store = useStore(Store);

  const nameFilter = store.filterManager.filters.getOne('name');

  const getArea = (item: TFieldNameOption) => {
    return `${item.meta.area.toFixed(1)} га`;
  };

  return (
    <MultiListSection
      title={'Поля'}
      filter={nameFilter}
      createSearchFilter={createSearchFilter}
      listItem={{
        height: 52,
        render: (item: TFieldNameOption) => (
          <ListCard
            key={`filter-key-${item.label}`}
            title={item.label}
            subtitle={item.meta.cultures}
            isSelected={false}
            leftBlock={() => <Checkbox value={item.selected} disable={item.disabled} />}
            rightBlock={() => <Styled.AreaLabel>{getArea(item)}</Styled.AreaLabel>}
            wrapperStyles={Styled.ListCardStyle}
          />
        ),
      }}
    />
  );
};

const createSearchFilter = (value: string) => (option: TFieldNameOption) => {
  return option.label.toLowerCase().includes(value);
};

export default observer(FieldNameFilterSection);
