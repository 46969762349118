import React, { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../shared/utils/IoC';
import { StoListPlugContainer } from '../StoListPlugContainer';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { TableFiltersBuilderController as BuilderController } from '../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { ETableFiltersBuilderId } from '../../../../constants/configs/TableFiltersBuilderId';

import {
  StoList,
  StoListNoDataPlug as NoDataPlug,
  StoListNoFilteredDataPlug as NoFilteredDataPlug,
  StoListNoSeasonPlug as NoSeasonPlug,
  StoListNoFieldsPlug as NoFieldsPlug,
} from './components';
import { StoListStore } from './mobx/stores';
import { StoListController } from './mobx/controllers';

const StoListContainer: FC = () => {
  const { selectedSeason } = useStore(SeasonsStore);
  const { stoList } = useStore(StoListStore);
  const { hasFields, clearStoListStore } = useStore(StoListController);
  const builderController = useStore(BuilderController);

  const hasAppliedValues = builderController.hasAppliedValueList(ETableFiltersBuilderId.Sto);

  useEffect(() => {
    return () => clearStoListStore();
  }, []);

  const AvailableNoSeasonPlug = useMemo(() => {
    if (selectedSeason) return;

    return <NoSeasonPlug />;
  }, [selectedSeason]);

  const AvailableNoFieldsPlug = useMemo(() => {
    if (hasFields) return;

    return <NoFieldsPlug />;
  }, [hasFields]);

  const AvailableNoDataPlug = useMemo(() => {
    if (stoList.length) return;

    if (hasAppliedValues) {
      return <NoFilteredDataPlug />;
    }

    return <NoDataPlug />;
  }, [stoList.length, hasAppliedValues]);

  const AvailablePlug = AvailableNoSeasonPlug || AvailableNoFieldsPlug || AvailableNoDataPlug;

  return AvailablePlug ? <StoListPlugContainer>{AvailablePlug}</StoListPlugContainer> : <StoList />;
};

StoListContainer.displayName = 'StoListContainer';

export default observer(StoListContainer);
