import moment from 'moment/moment';
import { saveAs } from 'file-saver';
import { sortBy } from 'lodash';

import { lazyInject, provide } from '../../../../../../../../../../shared/utils/IoC';
import { StoRegistriesStore as Store } from '../../stores';
import { StoService } from '../../../../../../../../../../shared/mobx/services/as-fields';
import {
  EStoDictionaryName,
  EStoDictionaryName as EDictionaryName,
  IStoDictionary as IDictionary,
  IStoDictionaryPut as IDictionaryPut,
} from '../../../../../../../../../../../api/models/as-fields/new/sto';
import { IStoParams } from '../../../../../../../models';
import { IUpdateStoDictionaryRequest } from '../../../../../../../../../../../api/endpoints/as-fields/sto/updateStoDictionary';
import { StoStore } from '../../../../../mobx';
import { TypeApiRequest, TypeApiResponse } from '../../../../../../../../../../shared/utils/axios2';
import { DictionaryService } from '../../../../../../../../../../shared/mobx/services/da-dictionary';
import { IDictionaryEntity } from '../../../../../../../../../../../api/models/da-dictionary/DictionaryEntity';

@provide.transient()
class StoRegistriesApiService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(StoStore)
  protected stoStore: StoStore;

  @lazyInject(StoService)
  protected stoService: StoService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  public fetchDictionaryList = async (
    params: Pick<IStoParams, 'stoId' | 'versionId'>
  ): Promise<void> => {
    await Promise.all([
      this.fetchWeedPlantsList(params),
      this.fetchVerminList(params),
      this.fetchDiseasesList(params),
    ]);
  };

  public downloadDictionary = (
    params: IStoParams,
    name: EDictionaryName,
    actions: Parameters<StoService['getStoReport']>[1]['actions']
  ): Promise<TypeApiResponse<'getStoReport'>> => {
    return this.stoService.downloadStoDictionary(
      {
        stoId: params.stoId,
        dictionaryName: [name],
        includeDrafts: !params.versionId,
        ...(params.versionId && { versionId: params.versionId }),
      },
      {
        actions: {
          ...actions,
          handleSuccess: response => {
            const blob = new Blob([response], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
            });

            const formattedDictionaryName = this.createDictionaryNameByTechnicalKey(name);
            const formattedStoName = this.stoStore.selectedSto.name.replaceAll(
              /[/|:*?"<>.]/gi,
              ' '
            );

            const formattedDate = moment().format('DD-MM-YYYY');

            saveAs(blob, `реестр ${formattedDictionaryName} ${formattedStoName} ${formattedDate}`);
          },
        },
      }
    );
  };

  public downloadDictionaryList = async (params: IStoParams): Promise<void> => {
    await this.stoService.downloadStoDictionary(
      {
        stoId: params.stoId,
        dictionaryName: [
          EDictionaryName.WeedPlants,
          EDictionaryName.Vermin,
          EDictionaryName.Diseases,
        ],
        includeDrafts: !params.versionId,
        ...(params.versionId && { versionId: params.versionId }),
      },
      {
        actions: {
          handleSuccess: response => {
            const blob = new Blob([response], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
            });

            const formattedName = this.stoStore.selectedSto.name.replaceAll(/[/|:*?"<>.]/gi, ' ');
            const formattedDate = moment().format('DD-MM-YYYY');

            saveAs(blob, `реестры ${formattedName} ${formattedDate}`);
          },
        },
      }
    );
  };

  public updateDictionaries = async (stoId: string): Promise<void> => {
    const payload: IUpdateStoDictionaryRequest = {
      stoId,
      dictionaryList: this.createDictionaryPutList(),
    };

    await this.stoService.updateStoDictionary(payload, {
      actions: {
        handleSuccess: async (response): Promise<void> => {
          const nameList = response.updatedStoDictionaries.map(
            ({ dictionaryName }) => dictionaryName
          );

          await this.fetchDictionaryListByNameList(payload.stoId, nameList);
        },
      },
    });
  };

  public fetchDictionaryEntityList = async (
    name: EDictionaryName
  ): Promise<IDictionaryEntity[]> => {
    const payload: TypeApiRequest<'getDictionaryEntityList'> = {
      latestVersion: true,
      remoteName: name,
      fetchAttributes: true,
      originalOnly: true,
      hasChild: false,
    };

    if (name === EDictionaryName.Diseases) {
      payload.dependencyRecords = [
        {
          dependencyName: 'culture',
          dependencyRecordId: this.stoStore.selectedSto.culture.id,
          directDependencies: false,
        },
      ];
    }

    const response = await this.dictionaryService.getDictionaryEntityList(payload);

    if (response?.content) {
      return response.content;
    }

    return [];
  };

  public uploadDictionary = async (
    payload: TypeApiRequest<'uploadStoDictionary'>,
    actions: Parameters<StoService['uploadDictionary']>[1]['actions']
  ): Promise<void> => {
    await this.stoService.uploadDictionary(payload, {
      actions: {
        handleSuccess: async (response): Promise<void> => {
          if (!response.updatedStoDictionaries.length) {
            actions.handleLoading(false);
            return;
          }

          const nameList = response.updatedStoDictionaries.map(
            ({ dictionaryName }) => dictionaryName
          );

          await this.fetchDictionaryListByNameList(payload.stoId, nameList);

          this.stoStore.setHasChanges(true);

          actions.handleSuccess(response);
          actions.handleLoading(false);
        },
        handleFailure: e => {
          actions.handleFailure(e);
          actions.handleLoading(false);
        },
      },
    });
  };

  protected createDictionaryPutList = (): IDictionaryPut[] => {
    const dictionaryPutList: IDictionaryPut[] = [];

    dictionaryPutList.push({
      dictionaryName: EDictionaryName.WeedPlants,
      selectedValues: this.store.selectedWeedPlantIdList.map(id => ({ id })),
    });

    dictionaryPutList.push({
      dictionaryName: EDictionaryName.Vermin,
      selectedValues: this.store.selectedVerminIdList.map(id => ({ id })),
    });

    dictionaryPutList.push({
      dictionaryName: EDictionaryName.Diseases,
      selectedValues: this.store.selectedDiseaseIdList.map(id => ({ id })),
    });

    return dictionaryPutList;
  };

  protected fetchWeedPlantsList = async ({
    stoId,
    versionId,
  }: Pick<IStoParams, 'stoId' | 'versionId'>): Promise<void> => {
    await this.stoService.getStoDictionaryList(
      {
        stoId,
        dictionaryName: EDictionaryName.WeedPlants,
        includeDrafts: !versionId,
        selectedOnly: false,
        sort: 'ASC',
        ...(versionId && { versionId }),
      },
      {
        actions: {
          handleSuccess: this.storeWeedPlantsList,
          handleLoading: this.handleWeedPlantsLoading,
        },
      }
    );
  };

  protected fetchVerminList = async ({
    stoId,
    versionId,
  }: Pick<IStoParams, 'stoId' | 'versionId'>): Promise<void> => {
    await this.stoService.getStoDictionaryList(
      {
        stoId,
        dictionaryName: EDictionaryName.Vermin,
        includeDrafts: !versionId,
        selectedOnly: false,
        sort: 'ASC',
        ...(versionId && { versionId }),
      },
      {
        actions: {
          handleSuccess: this.storeVerminList,
          handleLoading: this.handleVerminLoading,
        },
      }
    );
  };

  protected fetchDiseasesList = async ({
    stoId,
    versionId,
  }: Pick<IStoParams, 'stoId' | 'versionId'>): Promise<void> => {
    await this.stoService.getStoDictionaryList(
      {
        stoId,
        dictionaryName: EDictionaryName.Diseases,
        includeDrafts: !versionId,
        selectedOnly: false,
        sort: 'ASC',
        ...(versionId && { versionId }),
      },
      {
        actions: {
          handleSuccess: this.storeDiseasesList,
          handleLoading: this.handleDiseasesLoading,
        },
      }
    );
  };

  protected createDictionaryNameByTechnicalKey = (name: EDictionaryName): string => {
    switch (name) {
      case EDictionaryName.WeedPlants:
        return 'Сорняки';

      case EDictionaryName.Vermin:
        return 'Вредители';

      case EDictionaryName.Diseases:
        return 'Болезни';

      default:
        return '';
    }
  };

  protected getSelectedDictionaryIdList = (dictionaryList: IDictionary[]): string[] => {
    return dictionaryList.reduce<string[]>((list, dictionary) => {
      if (dictionary.isSelected) {
        list.push(dictionary.id);
      }

      return list;
    }, []);
  };

  protected getFormattedDictionaryListByIsSelectedState = (
    dictionaryList: IDictionary[]
  ): IDictionary[] => {
    const selectedList: IDictionary[] = [];
    const notSelectedList: IDictionary[] = [];

    dictionaryList.forEach(dictionary => {
      if (dictionary.isSelected) selectedList.push(dictionary);
      else notSelectedList.push(dictionary);
    });

    const sortedSelectedList = sortBy(selectedList, 'name');
    const sortedNotSelectedList = sortBy(notSelectedList, 'name');

    return [...sortedSelectedList, ...sortedNotSelectedList];
  };

  protected storeWeedPlantsList = (dictionaryList: IDictionary[]): void => {
    const formattedList = this.getFormattedDictionaryListByIsSelectedState(dictionaryList);
    this.store.setWeedPlantList(formattedList);

    const selectedIdList = this.getSelectedDictionaryIdList(formattedList);
    this.store.setSelectedWeedPlantIdList(selectedIdList);
  };

  protected storeVerminList = (dictionaryList: IDictionary[]): void => {
    const formattedList = this.getFormattedDictionaryListByIsSelectedState(dictionaryList);
    this.store.setVerminList(formattedList);

    const selectedIdList = this.getSelectedDictionaryIdList(formattedList);
    this.store.setSelectedVerminIdList(selectedIdList);
  };

  protected storeDiseasesList = (dictionaryList: IDictionary[]): void => {
    const formattedList = this.getFormattedDictionaryListByIsSelectedState(dictionaryList);
    this.store.setDiseaseList(formattedList);

    const selectedIdList = this.getSelectedDictionaryIdList(formattedList);
    this.store.setSelectedDiseaseIdList(selectedIdList);
  };

  protected fetchDictionaryListByNameList = async (
    stoId: string,
    nameList: EDictionaryName[]
  ): Promise<void> => {
    await Promise.all(
      nameList.map(name => {
        switch (name) {
          case EDictionaryName.WeedPlants:
            return this.fetchWeedPlantsList({ stoId });

          case EDictionaryName.Vermin:
            return this.fetchVerminList({ stoId });

          case EDictionaryName.Diseases:
            return this.fetchDiseasesList({ stoId });

          default:
            return null;
        }
      })
    );
  };

  protected handleWeedPlantsLoading = (isLoading: boolean): void => {
    if (isLoading) this.store.setLoadingDictionary(EStoDictionaryName.WeedPlants);
    else this.store.deleteLoadingDictionary(EStoDictionaryName.WeedPlants);
  };

  protected handleVerminLoading = (isLoading: boolean): void => {
    if (isLoading) this.store.setLoadingDictionary(EStoDictionaryName.Vermin);
    else this.store.deleteLoadingDictionary(EStoDictionaryName.Vermin);
  };

  protected handleDiseasesLoading = (isLoading: boolean): void => {
    if (isLoading) this.store.setLoadingDictionary(EStoDictionaryName.Diseases);
    else this.store.deleteLoadingDictionary(EStoDictionaryName.Diseases);
  };
}

export default StoRegistriesApiService;
