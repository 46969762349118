import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import { ELeftPanelType } from '../../../../../../interfaces/LeftPanelType.enum';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../../../../../mobx';

import ResultCounter from './component/ResultCounter/ResultCounter';
import Styled from './Footer.styles';

const FiltersFooter: FC = () => {
  // Сторы
  const store = useStore(Store);

  const { filterManager } = store;
  const tempFilteredList = filterManager.tempEditor.filteredList ?? [];

  const hasSelectedFilters = useMemo(() => {
    const selectedFilters = Boolean(filterManager.filters.getCounter());

    return selectedFilters || !filterManager.sorter.isDefault;
  }, [tempFilteredList]);

  const resetFilters = () => {
    filterManager.reset();
  };

  const goBack = () => {
    store.leftPanelType = ELeftPanelType.FIELDS_LIST;
    filterManager.tempEditor.discardChanges();
  };

  const appendFilters = () => {
    filterManager.tempEditor.applyChanges();
    goBack();
  };

  return (
    <Styled.Wrapper>
      <ResultCounter filteredList={tempFilteredList} />

      <Styled.ButtonsWrapper>
        <Button color={'default'} onClick={hasSelectedFilters ? resetFilters : goBack}>
          {hasSelectedFilters ? 'Сбросить' : 'Назад'}
        </Button>
        <Button color={'primary'} onClick={appendFilters}>
          Применить
        </Button>
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  );
};

export default observer(FiltersFooter);
