import moment from 'moment/moment';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TaskOperationViewStore as Store } from '../../stores';
import { TaskOperationViewApiService as ApiService } from '../TaskOperationViewApiService';
import { ProfileStore } from '../../../../../../profile/stores/ProfileStore';
import { TaskViewStore } from '../../../../../../operations/stores/task.view.store';
import { ISelectOption } from '../../../../../../../../../types/selectOption';

interface IDateRangeOptions {
  minDate?: Date;
  maxDate?: Date;
}

@provide.transient()
class TaskOperationViewDefaultValuesService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(ApiService)
  protected apiService: ApiService;

  public get cultureName() {
    return this.store.selectedTask.culture?.name;
  }

  public get fieldName() {
    return this.store.selectedTask.field?.name;
  }

  public get operationName() {
    return this.store.selectedTask.operationInfo?.name;
  }

  public get spayingType() {
    const operationValuesLength = this.store.selectedTask.operationValues.length;

    if (operationValuesLength) {
      if (operationValuesLength === 1) {
        return this.store.selectedTask.operationValues[0].dictionaryValueName;
      } else {
        return `Выбрано ${operationValuesLength}`;
      }
    }
  }

  public get assigneeName() {
    return this.store.selectedTask.assignee?.fullName;
  }

  public get date() {
    const { executedDate } = this.store.selectedTask;

    if (executedDate) {
      return moment(executedDate).format('DD.MM.YYYY');
    }
  }
}

export default TaskOperationViewDefaultValuesService;
