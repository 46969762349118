import styled from 'styled-components';

import { Colors } from '../../../../../../../../../../../shared/constans/colors';

import InfoImg from './assets/info.svg';

export const InfoBlockWrapper = styled.div`
  position: relative;
`;

export const ImgInfo = styled.img.attrs(props => ({
  src: props.src || InfoImg,
}))`
  cursor: pointer;
`;

export const TextBlock = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'inline-block' : 'none')};
  position: absolute;
  background: #fff;
  width: 385px;
  padding: 12px;
  box-sizing: border-box;
  bottom: calc(100% + 10px);
  left: -187px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
`;

export const TipTail = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${Colors.white};
  position: absolute;
  bottom: -10px;
  left: 190px;
  border-bottom: 5px solid transparent;
`;
