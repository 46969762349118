import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './Skeleton.styles';

interface IProps {
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const FieldsListLegendSkeleton: FC<IProps> = props => {
  return (
    <Styled.Wrapper {...props.getDataTestId('skeleton-card')}>
      <Styled.Header>
        <Skeleton width={150} height={16} duration={0} />

        <Skeleton className={'icon'} width={16} height={16} duration={0} />
      </Styled.Header>

      <Skeleton className={'icon'} width={'100%'} height={4} duration={0} />
    </Styled.Wrapper>
  );
};

export default FieldsListLegendSkeleton;
