import { Geometry } from '@turf/turf';
import L, { LatLngBounds } from 'leaflet';

import { getLatLngsFromGeometry } from './index';

/**
 * Вычилсяет границы на основе геометрии.
 * Поддерживает мультипольигоны
 */
function getBoundsByGeometry(geometry: Geometry): LatLngBounds | null {
  if (!geometry?.type || !geometry?.coordinates) {
    return null;
  }

  const latLngs = getLatLngsFromGeometry(geometry);

  return L.polygon(latLngs)?.getBounds() ?? null;
}

export default getBoundsByGeometry;
