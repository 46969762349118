import { ENotificationType, useNotificator } from '@farmlink/farmik-ui';
import { AxiosError } from 'axios';

import { useStoNavigation } from '../../../../../../hooks';
import {
  ENotificationScheme,
  getNotificationScheme,
} from '../../../../../../../../../shared/utils/helpers/getNotificationScheme';
import { STO_NOTIFICATION_MESSAGES } from '../../../../../../constants';
import { IStoContent } from '../../../../../../../../../../api/models/as-fields/STO/STO.model';
import { EStoTab } from '../../../../models/StoCommon';

const useStoControls = () => {
  const { setNotification } = useNotificator();
  const navigation = useStoNavigation();
  const { navigateToURLByTab } = useStoNavigation();

  const createStoSuccessHandler = (stoId: string) => {
    setNotification(
      getNotificationScheme(ENotificationScheme.Dark, STO_NOTIFICATION_MESSAGES.createStoSuccess, {
        type: ENotificationType.Success,
      })
    );

    navigation.navigateToSto(stoId, true);
  };

  const createStoErrorHandler = () => {
    setNotification(
      getNotificationScheme(ENotificationScheme.Dark, STO_NOTIFICATION_MESSAGES.createStoError, {
        type: ENotificationType.Warning,
      })
    );
  };

  const updateStoSuccessHandler = (e: { sto: IStoContent; tab: EStoTab; tabFrom: EStoTab }) => {
    switch (e.tabFrom) {
      case EStoTab.Operations:
        setNotification(
          getNotificationScheme(
            ENotificationScheme.Dark,
            STO_NOTIFICATION_MESSAGES.saveOperationsSuccess,
            {
              type: ENotificationType.Success,
            }
          )
        );
        break;

      case EStoTab.Registries:
        setNotification(
          getNotificationScheme(
            ENotificationScheme.Dark,
            STO_NOTIFICATION_MESSAGES.saveRegistriesSuccess,
            {
              type: ENotificationType.Success,
            }
          )
        );
        break;

      default:
        setNotification(
          getNotificationScheme(
            ENotificationScheme.Dark,
            STO_NOTIFICATION_MESSAGES.updateStoSuccess,
            {
              type: ENotificationType.Success,
            }
          )
        );
    }

    if (e.tab) navigateToURLByTab(e.sto.id, e.tab);
  };

  const updateStoErrorHandler = (error: AxiosError) => {
    let notificationMessage = STO_NOTIFICATION_MESSAGES.updateStoError;

    if (error.response.data.message === 'Current sto is draft') {
      notificationMessage = STO_NOTIFICATION_MESSAGES.transferErrorStoIsDraft;
    }

    if (error.response.data.message === 'Current sto contains only one culture zone') {
      notificationMessage = STO_NOTIFICATION_MESSAGES.transferErrorIsLastField;
    }

    setNotification(
      getNotificationScheme(ENotificationScheme.Dark, notificationMessage, {
        type: ENotificationType.Warning,
      })
    );
  };

  return {
    createStoSuccessHandler,
    createStoErrorHandler,
    updateStoSuccessHandler,
    updateStoErrorHandler,
  };
};

export default useStoControls;
