import { makeAutoObservable } from 'mobx';
import { isFunction } from 'lodash';

import { provide } from '../../../../../../../../../../shared/utils/IoC';
import { IStoOperationsListItem } from '../../../models';
import { IUpdateStoOperation } from '../../../../../../../../../../../api/models/as-fields/STO/STO.model';

type TStoreSetter<V = undefined> = (value: V | ((prevValue: V) => V)) => void;

@provide.singleton()
class StoOperationsCoreStore {
  private _isLoading = true;

  private _itemList: IStoOperationsListItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get itemList(): IStoOperationsListItem[] {
    return this._itemList;
  }

  public setIsLoading = (value: boolean): void => {
    this._isLoading = value;
  };

  public setItemList: TStoreSetter<IStoOperationsListItem[]> = value => {
    if (isFunction(value)) {
      this._itemList = value(this._itemList);
    } else {
      this._itemList = value;
    }
  };

  public clearIsLoading = (): void => {
    this._isLoading = true;
  };

  public clearItemList = (): void => {
    this._itemList = [];
  };
}

export default StoOperationsCoreStore;
