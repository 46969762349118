import { makeAutoObservable } from 'mobx';
import { isFunction } from 'lodash';

import { provide } from '../../../../../../../../shared/utils/IoC';
import { ISto } from '../../../../../../../../../api/models/as-fields/STO/STO.model';

@provide.singleton()
class StoListStore {
  private _isFetchingStoList = false;

  private _currentPage = 0;

  private _totalPages = 0;

  private _stoList: ISto[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isFetchingStoList() {
    return this._isFetchingStoList;
  }

  public get currentPage() {
    return this._currentPage;
  }
  public get totalPages() {
    return this._totalPages;
  }

  public get stoList() {
    return this._stoList;
  }
  public setIsFetchingStoList = (value: boolean): void => {
    this._isFetchingStoList = value;
  };

  public setCurrentPage = (value: number | ((prevValue: number) => number)): void => {
    if (isFunction(value)) {
      this._currentPage = value(this._currentPage);
    } else {
      this._currentPage = value as number;
    }
  };

  public setTotalPages = (totalPages: number): void => {
    this._totalPages = totalPages;
  };

  public setStoList = (value: ISto[] | ((prevStoList: ISto[]) => ISto[])): void => {
    if (isFunction(value)) {
      this._stoList = value(this.stoList);
    } else {
      this._stoList = value as ISto[];
    }
  };

  public clearIsFetchingStoList = (): void => {
    this._isFetchingStoList = false;
  };

  public clearCurrentPage = (): void => {
    this._currentPage = 0;
  };

  public clearTotalPages = (): void => {
    this._totalPages = 0;
  };

  public clearStoList = (): void => {
    this._stoList = [];
  };
}

export default StoListStore;
