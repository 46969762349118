import { TypeApiRoute } from '../../models/type.api.request';
import { CultureZone } from '../../models/update.culture.zone';

type TypeRequest = Array<Partial<CultureZone>>;

type InputData = {
  fields: {
    fieldId: string;
    cultureId: string;
  }[];
  seasonYear: number;
  organizationId?: string;
};

type TypeResponse = any;

export const updateCultures: TypeApiRoute & {
  request: InputData | ((request: any) => TypeRequest);
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/fields/updateCultures`,
  method: 'PUT',
  headers: {},
  request: req => req,
  response: {} as TypeResponse,
};
