import React, { FC, memo } from 'react';

import Styled from './StoChecklistsBooleanCell.styled';

interface IProps {
  value: boolean;
}

const StoChecklistsBooleanCell: FC<IProps> = ({ value }) => {
  return (
    <Styled.Wrapper>
      <Styled.Background $value={value}>
        <Styled.Title>{value ? 'да' : 'нет'}</Styled.Title>
      </Styled.Background>
    </Styled.Wrapper>
  );
};

StoChecklistsBooleanCell.displayName = 'StoChecklistsBooleanCell';

export default memo(StoChecklistsBooleanCell);
