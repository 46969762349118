import { FC } from 'react';

import { ReactComponent as NoSeasonsIconSvg } from '../../../../../../../../assets/map-add-entity-mock.svg';
import CultureSvg from '../../../../../../../../assets/cultures.svg';

import Styled from './Placeholder.styles';

interface IProps {
  type: 'NO_ITEMS' | 'NO_SEASONS';
}

const FieldsListPlaceholder: FC<IProps> = props => {
  if (props.type === 'NO_ITEMS') {
    return (
      <Styled.Wrapper data-test-id={'fields-list-no-fields'}>
        <Styled.Image src={CultureSvg} />
        <Styled.Title>У вас ещё нет полей</Styled.Title>

        <Styled.Description>
          Добавьте поля для доступа к&nbsp;снимкам вегетации и&nbsp;данным&nbsp;по погоде
        </Styled.Description>
      </Styled.Wrapper>
    );
  }

  if (props.type === 'NO_SEASONS') {
    return (
      <Styled.Wrapper $height={'calc(100% - 248px)'} data-test-id={'fields-list-no-seasons'}>
        <NoSeasonsIconSvg />
        <Styled.Title>У вас ещё нет сезонов</Styled.Title>

        <Styled.Description>
          Добавьте сезон, чтобы получить возможность добавлять поля
        </Styled.Description>
      </Styled.Wrapper>
    );
  }

  return null;
};

export default FieldsListPlaceholder;
