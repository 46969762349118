import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../shared/utils/axios2';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { OperationCulture } from '../../../../../api/models/operations/operation.culture';
import { Operation } from '../../../../../api/models/operations/operation.model';
import { SeasonsStore } from '../../../stores/seasons.store';
import { OperationField } from '../../../../../api/models/operations/operation.field.model';
import { OperationType } from '../../../../../api/models/operations/operation.type.model';
import { IGetDictionary } from '../../../../../api/models/dictionary/dictionary.model';

export const OperationsPageSizeDefault = 20;

@provide.singleton()
export class OperationsStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;

  constructor() {
    makeAutoObservable(this);
  }

  private _isLoading = false;
  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }
  get isLoading(): boolean {
    return this._isLoading;
  }

  OperationCulturesInFields: OperationCulture[] = [];
  culturesLoaded = false;

  private _operationsList: Array<Operation> = [];
  set OperationsList(list: Array<Operation>) {
    this._operationsList = list;
  }
  get OperationsList(): Array<Operation> {
    return this._operationsList;
  }

  OperationFieldsList: Array<OperationField> = [];
  OperationsType: Array<OperationType> = [];
  OperationsTypeForFilter: Array<OperationType> = [];
  fieldsWithoutCulturesCount = 0;

  operationPageNumber = 0;
  operationTotalPages = 0;
  totalOperationsNumber = 0;

  public getOperation = (id: string): Operation => {
    return this.OperationsList.find(operation => id === operation.id);
  };

  fetchOperationsFieldsList = async (selectedOperationId: string) => {
    if (!selectedOperationId) return;

    const organizationId =
      this.organizations.selectedOrganizationId === 'my'
        ? ''
        : this.organizations.selectedOrganizationId;

    let response: TypeApiResponse<'getOperationFields'> | null = null;

    try {
      this.isLoading = true;
      response = await this.axios.api.getOperationFields({
        organizationId,
        operationId: selectedOperationId,
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      return;
    }

    this.OperationFieldsList = response;
  };

  resetOperationsFieldsList = () => {
    this.OperationFieldsList = [];
  };

  fetchCulturesList = async () => {
    const organizationId =
      this.organizations.selectedOrganizationId === 'my'
        ? undefined
        : this.organizations.selectedOrganizationId;

    let response: TypeApiResponse<'getSeasonCulture'> | null = null;
    try {
      this.isLoading = true;
      response = await this.axios.api.getSeasonCulture(
        {
          organizationId,
          year: Number(this.seasons.selectedSeason),
        },
        { omit: ['year'] }
      );
    } catch (e) {
      this.isLoading = false;
      this.culturesLoaded = true;
      return;
    }

    this.isLoading = false;
    this.OperationCulturesInFields = response.cultureAndVarietyList;
    this.fieldsWithoutCulturesCount = response.fieldsWithoutCulturesCount;
    this.culturesLoaded = true;
  };

  public setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  public setFieldsWithoutCulturesCount = (value: number): void => {
    this.fieldsWithoutCulturesCount = value;
  };

  public setOperationsCulturesInFields = (operationCultureList: OperationCulture[]): void => {
    this.OperationCulturesInFields = operationCultureList;
  };

  getCulturesList = async () => {
    await this.fetchCulturesList();
    return this.OperationCulturesInFields;
  };

  createOperation = async (data: TypeApiRequest<'createOperation'>, selectedCultureId: string) => {
    try {
      this.isLoading = true;
      await this.axios.api.createOperation(data);
    } catch (e) {
      this.isLoading = false;
      return Promise.reject(e);
    }
    this.resetPageNumberSettings();
    await this.getOperations(selectedCultureId);
    this.isLoading = false;
  };

  editOperation = async (data: TypeApiRequest<'editOperation'>) => {
    try {
      this.isLoading = true;
      const result = await this.axios.api.editOperation(data);
      this.OperationsList = this.OperationsList.map(operation =>
        operation.id === result.id ? result : operation
      );
    } finally {
      this.isLoading = false;
    }
  };

  deleteOperation = async (data: TypeApiRequest<'deleteOperation'>, selectedCultureId: string) => {
    try {
      this.isLoading = true;
      await this.axios.api.deleteOperation(data);
      this.resetPageNumberSettings();
      this.getOperations(selectedCultureId);
    } catch (e) {
      this.isLoading = false;
      return Promise.reject(e);
    }
    this.isLoading = false;
  };

  resetPageNumberSettings = () => {
    this.operationPageNumber = 0;
    this.operationTotalPages = 0;
    this.totalOperationsNumber = 0;
  };

  getOperations = async (selectedCultureId: string, concat?: boolean) => {
    const organizationId =
      this.organizations.selectedOrganizationId === 'my'
        ? ''
        : this.organizations.selectedOrganizationId;

    let response: TypeApiResponse<'getOperations'> | null = null;

    try {
      this.isLoading = true;
      response = await this.axios.api.getOperations({
        organizationId,
        seasonYear: Number(this.seasons.selectedSeason),
        cultureId: selectedCultureId === 'emptyCulture' ? undefined : selectedCultureId,
        noCulture: selectedCultureId === 'emptyCulture',
        page: this.operationPageNumber,
        size: OperationsPageSizeDefault,
      });
    } catch (e) {
      this.isLoading = false;
      return;
    }
    if (concat) {
      this.OperationsList = this.OperationsList.concat(response.content);
      this.operationPageNumber++;
    } else {
      this.OperationsList = response.content;
      this.operationPageNumber = 1;
    }

    this.operationTotalPages = response.totalPages;
    this.totalOperationsNumber = response.totalElements;
    this.isLoading = false;
  };

  get getOperationsTypeSelectAdapter() {
    return this.OperationsType.map(type => ({
      label: type.name,
      value: type.id,
    }));
  }

  fetchOperationTypeDictionaryByCultureId = async (
    cultureId: string,
    isFilter?: boolean
  ): Promise<IGetDictionary[]> => {
    try {
      const payload: TypeApiRequest<'getStoTechOperationList'> = {
        seasonYear: Number(this.seasons.selectedSeason),
        organizationId:
          this.organizations.selectedOrganizationId === 'my'
            ? ''
            : this.organizations.selectedOrganizationId,
      };

      if (cultureId) {
        if (cultureId === 'emptyCulture') payload.noCulture = true;
        else payload.cultureId = cultureId;
      }

      if (!isFilter) {
        if (!payload.organizationId) {
          payload.forOthers = true;
        } else {
          payload.forDA = true;
          payload.forOthers = true;
        }
      }

      const { content } = await this.axios.api.getStoTechOperationList(payload);

      const normalizedOperationType = content.map(({ name, id }) => ({ name, id }));
      this.OperationsType = normalizedOperationType;

      if (isFilter) {
        this.OperationsTypeForFilter = normalizedOperationType;
      }

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  clearOperationsStore = () => {
    this.isLoading = false;
    this.OperationCulturesInFields = [];
    this.OperationsList = [];
    this.OperationFieldsList = [];
    this.OperationsType = [];
    this.fieldsWithoutCulturesCount = 0;
  };
}
