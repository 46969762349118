import { useCallback } from 'react';

import { useStore } from '../../../../../../../../../../shared/utils/IoC';
import { FieldsImportController as Controller } from '../../../../../mobx';
import { EFieldsUploadErrorType } from '../../../../../utils/constants';
import { getApiNameByFileExtension } from '../../../../../utils/helpers';
import { EFieldsImportContainerRenderModalName } from '../../types';

import { TUseImportFields, TUseImportFieldsReturn } from './useImportFields.types';

const useImportFields: TUseImportFields = setRenderModalName => {
  const controller = useStore(Controller);

  const handleImportFields = useCallback<TUseImportFieldsReturn['importFields']>(
    async (fileExtension, fileList) => {
      const formData = new FormData();
      formData.append('file', fileList[0]);

      const apiName = getApiNameByFileExtension(fileExtension);

      if (!apiName) {
        return;
      }

      setRenderModalName(EFieldsImportContainerRenderModalName.Loader);

      const importFieldsRes = await controller.importFields(apiName, formData);

      if (!importFieldsRes) {
        setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);

        return;
      }

      const { token, errorType } = importFieldsRes;

      if (errorType) {
        if (errorType === EFieldsUploadErrorType.FieldsCount) {
          setRenderModalName(EFieldsImportContainerRenderModalName.TooManyFields);

          return;
        }

        setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);

        return;
      }

      const isSuccess = await controller.fetchImportedList(token);

      if (isSuccess) {
        setRenderModalName(EFieldsImportContainerRenderModalName.Success);
      } else {
        setRenderModalName(EFieldsImportContainerRenderModalName.SomethingWentWrong);
      }
    },
    []
  );

  return {
    importFields: handleImportFields,
  };
};

export default useImportFields;
