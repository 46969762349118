import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { CalendarComponent } from '@farmlink/farmik-ui';
import moment from 'moment/moment';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskOperationCreateController as Controller } from '../../../../mobx/controllers';

interface IProps {
  dataTestId: string;
}

const TaskOperationCreateDateSelect: FC<IProps> = ({ dataTestId }) => {
  const controller = useStore(Controller);

  const handleChange = useCallback((value: Date): void => {
    controller.changeDate(moment(value).format('YYYY-MM-DD'));
  }, []);

  return (
    <CalendarComponent
      dataTestId={dataTestId}
      isRequired
      label={'Дата'}
      defaultValue={controller.dateDefaultValue}
      datePickerOptions={{
        dateRange: controller.dateRangeOptions,
      }}
      onChange={handleChange}
      isCloseOnChange
      isBlocked={controller.isDateSelectBlocked}
    />
  );
};

TaskOperationCreateDateSelect.displayName = 'TaskOperationCreateDateSelect';

export default observer(TaskOperationCreateDateSelect);
