import { LatLng, Layer, LocateOptions } from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { IMapGlobalConfig as IGlobalConfig } from '../../../models';
import { DefaultMapOptions } from '../../../utils/constants';
import { MapDrawerEventsService } from '../../MapDrawer';
import MapDrawerService from '../../MapDrawer/services/MapDrawer.service';
import MapImageOverlayService from '../../MapImageOverlay/services/MapImageOverlay.service';
import MapImageOverlayStore from '../../MapImageOverlay/stores/MapImageOverlay.store';
import MapLayerGroupService from '../../MapLayerGroup/services/MapLayerGroup.service';
import MapLayerGroupStore from '../../MapLayerGroup/stores/MapLayerGroup.store';
import MapMarkerService from '../../MapMarker/services/MapMarker.service';
import MapMarkerStore from '../../MapMarker/stores/MapMarker.store';
import MapPolygonService from '../../MapPolygon/services/MapPolygon.service';
import MapPolygonStore from '../../MapPolygon/stores/MapPolygon.store';
import MapCoreService from '../services/MapCore.service';
import MapCoreEventsService from '../services/MapCoreEvents.service';
import MapCoreStore from '../stores/MapCore.store';

type TCoreServiceArgs<K extends keyof MapCoreService> = Parameters<MapCoreService[K]>;

@provide.transient()
class MapCoreController {
  @lazyInject(MapCoreStore)
  private store: MapCoreStore;

  @lazyInject(MapPolygonStore)
  private polygonStore: MapPolygonStore;

  @lazyInject(MapMarkerStore)
  private markerStore: MapMarkerStore;

  @lazyInject(MapLayerGroupStore)
  private layerGroupStore: MapLayerGroupStore;

  @lazyInject(MapImageOverlayStore)
  private imageOverlayStore: MapImageOverlayStore;

  @lazyInject(MapCoreService)
  private coreService: MapCoreService;

  @lazyInject(MapPolygonService)
  private polygonService: MapPolygonService;

  @lazyInject(MapMarkerService)
  private markerService: MapMarkerService;

  @lazyInject(MapDrawerService)
  private drawerService: MapDrawerService;

  @lazyInject(MapLayerGroupService)
  private layerGroupService: MapLayerGroupService;

  @lazyInject(MapCoreEventsService)
  private coreEventsService: MapCoreEventsService;

  @lazyInject(MapDrawerEventsService)
  private drawerEventsService: MapDrawerEventsService;

  @lazyInject(MapImageOverlayService)
  private mapImageOverlayService: MapImageOverlayService;

  public initialize(globalConfig: IGlobalConfig): void {
    this.coreService.initMap(globalConfig);

    this.coreEventsService.registerGlobalEvents();
    this.drawerEventsService.registerGlobalEvents();
  }

  public zoom(...args: TCoreServiceArgs<'zoom'>): void {
    this.coreService.zoom(...args);
  }

  public locate(options?: LocateOptions): void {
    this.coreService.locate(options);
  }

  public getCenterOfMap(): { latLng: LatLng; zoom: number } {
    return {
      latLng: this.store.instance?.getCenter() ?? DefaultMapOptions.center,
      zoom: this.store.instance?.getZoom() ?? DefaultMapOptions.zoom,
    };
  }

  public centerOnBounds(...args: TCoreServiceArgs<'centerMapOnBounds'>): void {
    this.coreService.centerMapOnBounds(...args);
  }

  public centerOnLatLng(...args: TCoreServiceArgs<'centerMapOnLatLng'>): void {
    this.coreService.centerMapOnLatLng(...args);
  }

  public getAllMapLayers(): (Layer & { id: number })[] {
    const allPolygons = this.polygonStore.polygonsList;
    const allMarkers = this.markerStore.markersList;
    const allLayerGroup = this.layerGroupStore.layerGroupsList;
    const allImageOverlay = this.imageOverlayStore.imageOverlaysList;

    return [...allPolygons, ...allMarkers, ...allLayerGroup, ...allImageOverlay];
  }

  public getMapLayerById(elementId: number | string): Layer & { id: number } {
    const id = Number(elementId);

    if (!Number.isFinite(id)) {
      return null;
    }

    const allElements = this.getAllMapLayers();

    return allElements.find(element => element.id === id);
  }

  /**
   * Закрывает режим рисования
   * Удаляет все объекты с карты и сторов
   */
  public clear(): void {
    this.drawerService.disableDrawPolygon();
    this.drawerService.disableDrawMarker();

    this.polygonService.removeManyByIds([], { isRemoveAll: true });
    this.markerService.removeManyByIds([], { isRemoveAll: true });
    this.layerGroupService.removeManyByIds([], { isRemoveAll: true });
    this.mapImageOverlayService.removeManyByIds([], { isRemoveAll: true });
  }

  /**
   * Закрывает режим рисования
   * Удаляет все объекты с карты и сторов.
   * Отписывается от событий
   * Удаляет инстанс leaflet
   */
  public destroy(): void {
    this.clear();

    this.coreEventsService.unregisterGlobalEvents();
    this.coreService.removeInstance();
  }
}

export default MapCoreController;
