import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import SharedStyles from '../shared/styles/Shared.styles';

const Wrapper = styled('div')({});

const Card = styled('div')({
  display: 'flex',
  gap: 8,
  padding: '10px 12px',

  borderBottom: `1px solid ${Colors.technicalBgGrey}`,

  '& .react-loading-skeleton': {
    backgroundColor: Colors.technicalBgGrey,
    backgroundImage: 'none',
    borderRadius: 12,
  },
});

const TextWrapper = styled(SharedStyles.TextWrapper)({
  margin: 0,
});

export default {
  Wrapper,
  TextWrapper,
  Card,
};
