import { FC, memo } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { TComparisonTableBuilderCellPreset as TCellPreset } from '../../../models/types/cells';
import { IComparisonTableBuilderCellTooltipConfig as ICellTooltipConfig } from '../../../models/configs/ComparisonTableBuilderCellTooltipConfig';

import Styled from './ComparisonTableBuilderCell.styles';

interface IProps {
  preset: TCellPreset;
  value: string | number;
  isMainRowCell?: boolean;
  padding?: string;
  tooltipConfig?: ICellTooltipConfig;
}

const ComparisonTableBuilderCell: FC<IProps> = ({
  preset,
  value,
  isMainRowCell,
  padding,
  tooltipConfig,
}) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>(value);

  const getDataTestId = useDataTestId({ componentName: isMainRowCell ? 'row' : 'cell' });

  const isForceShowDisabledForValue =
    typeof tooltipConfig?.isForceShowTooltip === 'undefined'
      ? !isShowTooltip
      : !tooltipConfig?.isForceShowTooltip;

  return (
    <AutoTooltip
      content={tooltipConfig?.tooltipValue ?? value}
      disabled={isForceShowDisabledForValue}
    >
      <Styled.Wrapper
        ref={ref}
        $preset={preset}
        $padding={padding}
        $isMainRowCell={isMainRowCell}
        {...getDataTestId()}
      >
        {value}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

ComparisonTableBuilderCell.displayName = 'ComparisonTableBuilderCell';

export default memo(ComparisonTableBuilderCell);
