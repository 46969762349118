import { useParams, useNavigate, useLocation, matchPath, generatePath } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import { lsAppSettingsKey, EAppSettingsType } from '../../..';
import { ApplicationRoutes, EExternalRedirectEntryPoints } from '../../routes';
import { useStore } from '../../shared/utils/IoC';
import { OrganizationsController } from '../controllers/organizations/organizations.controller';
import { EFieldsRoutes } from '../modules/fields/utils';
import { ProfileController } from '../modules/profile/controllers/profile.controller';
import { OrganizationsStore } from '../stores/organizations.store';
import { ProfileStore } from '../modules/profile/stores/ProfileStore';
import { TypeOrganization } from '../../../api/models/organization.model';

const SAVE_QUERY_PARAMS = ['versionId', 'fieldId', 'cultureId', 'operationId'];

const useOrganizationMatcher = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const organizationsController = useStore(OrganizationsController);
  const organizationsStore = useStore(OrganizationsStore);
  const profileController = useStore(ProfileController);
  const { isLoadingBySeasonFieldUrl } = useStore(ProfileStore);
  const location = useLocation();

  const savedQueryParams = useMemo<URLSearchParams>(() => {
    const query = new URLSearchParams(location.search);
    const savedQuery = {};

    query.forEach((value, key) => {
      if (SAVE_QUERY_PARAMS.includes(key)) {
        savedQuery[key] = value;
      }
    });

    return new URLSearchParams(savedQuery);
  }, [location, SAVE_QUERY_PARAMS]);

  const isShouldRedirectToFields = Boolean(
    matchPath(ApplicationRoutes.Dashboard, location.pathname) ||
      matchPath(ApplicationRoutes.DashboardWithOrgId, location.pathname)
  );

  const externalEntryPoint =
    matchPath(EExternalRedirectEntryPoints.Fields, location.pathname) ||
    matchPath(EExternalRedirectEntryPoints.Operations, location.pathname) ||
    matchPath(EExternalRedirectEntryPoints.Tasks, location.pathname) ||
    matchPath(EExternalRedirectEntryPoints.Sto, location.pathname);

  const localStorageAsObject = useMemo<{
    organization: string;
    season: string;
  }>(() => JSON.parse(localStorage.getItem(lsAppSettingsKey)), [localStorage]);

  const changeLocalStorageData = useCallback<(_organization?: string, season?: string) => void>(
    (_organization, season) => {
      const updateLocalStore = { ...localStorageAsObject };

      if (_organization) {
        updateLocalStore.organization = _organization;
        profileController.setAppSettings(EAppSettingsType.Organization)(_organization);
      }

      if (season) {
        updateLocalStore.season = season;
        profileController.setAppSettings(EAppSettingsType.Season)(season);
      }

      localStorage.setItem(lsAppSettingsKey, JSON.stringify(updateLocalStore));
    },
    [localStorageAsObject]
  );

  /**
   * Организация из localStorage
   */
  const storageOrgId = useMemo(() => localStorageAsObject?.organization, [localStorageAsObject]);

  const matchOrganization = useCallback(
    (orgList: TypeOrganization[], _orgId: string) => {
      const isOrgInList = (_organizationId: string) =>
        Boolean(orgList?.find(_org => _org?.organizationId === _organizationId));

      const matchOrgId = Boolean(externalEntryPoint) ? undefined : _orgId;

      let currentOrgId;

      switch (matchOrgId) {
        // перешли по URL с точным указанием организации my
        case 'my':
          if (storageOrgId === 'my') {
            currentOrgId = 'my';
          } else if (isOrgInList(orgId)) {
            currentOrgId = orgId;
          } else {
            currentOrgId = 'my';
          }

          break;

        // перешли по URL без организации
        case undefined:
          if (isOrgInList(storageOrgId)) {
            currentOrgId = storageOrgId;
          } else {
            currentOrgId = 'my';
          }

          break;

        // перешли по URL c указанием ID организации
        default:
          if (isOrgInList(matchOrgId)) {
            currentOrgId = matchOrgId;
          } else {
            currentOrgId = 'my';
          }

          break;
      }

      if (isShouldRedirectToFields && !externalEntryPoint) {
        navigate({
          pathname: generatePath(EFieldsRoutes.View, { orgId: currentOrgId }),
          search: savedQueryParams.toString(),
        });
      } else {
        const urlArr = location.pathname.split('/');
        urlArr[1] = currentOrgId;

        if (externalEntryPoint) {
          urlArr.push(externalEntryPoint.pattern.path);
        }

        navigate({ pathname: generatePath(urlArr.join('/')), search: savedQueryParams.toString() });
      }

      return organizationsController
        .selectOrganization(currentOrgId, isLoadingBySeasonFieldUrl)
        .finally(() => {
          organizationsStore.setIsFirstLoadOrganizationSelected(true);
          changeLocalStorageData(currentOrgId);

          return Promise.resolve(currentOrgId);
        });
    },
    [storageOrgId, externalEntryPoint, savedQueryParams]
  );

  return { matchOrganization };
};

export default useOrganizationMatcher;
