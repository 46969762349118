import React, { FC, PropsWithChildren, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { StoChecklistsCoreController } from '../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../hooks';

import Styled from './StoChecklistsCellContainer.styled';

interface IProps {
  stageId: string;
  attrId: string;
  isWithoutBottomBorder?: boolean;
  isWithoutAlign?: boolean;
}

const StoChecklistsCellContainer: FC<PropsWithChildren<IProps>> = ({
  stageId,
  attrId,
  isWithoutBottomBorder,
  isWithoutAlign,
  children,
}) => {
  const { checkIfChecklistIsSelected, checkIfAttrIsSelected } = useStore(
    StoChecklistsCoreController
  );

  const { checklistId } = useStoParams();

  const isChecklistSelected = checkIfChecklistIsSelected(checklistId);
  const isAttrSelected = checkIfAttrIsSelected(checklistId, stageId, attrId);

  const isDisabled = useMemo(() => {
    if (!isChecklistSelected) return false;

    return !isAttrSelected;
  }, [isChecklistSelected, isAttrSelected]);

  return (
    <Styled.Wrapper
      $isDisabled={isDisabled}
      $isWithoutBottomBorder={isWithoutBottomBorder}
      $isWithoutAlign={isWithoutAlign}
    >
      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

StoChecklistsCellContainer.displayName = 'StoChecklistsCellContainer';

export default observer(StoChecklistsCellContainer);
