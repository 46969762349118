import { makeAutoObservable, runInAction } from 'mobx';

import { EFieldTooltip } from '../../../../../../../../../dashboard/constants/FieldTooltip.enum';
import { mapToArray } from '../../../../../../../../utils/helpers/map/mapToArray';
import { Field } from '../../../../../../../../../../api/models/field.model';
import { provide } from '../../../../../../../../utils/IoC';

@provide.singleton()
class LayerTooltipStore {
  private _values = new Set([...EFieldTooltip.Name]);

  // Список всех полей к которым будут применены тултипы в случаях когда не передано конкретное поле через метод controller.setContent()
  private _allFields: Field[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get allFields() {
    return this._allFields;
  }

  public setAllFields(fields: Field[]) {
    this._allFields = [...fields];
  }

  public get values() {
    return mapToArray(this._values) as EFieldTooltip[];
  }

  public addValue(value: EFieldTooltip) {
    this._values.add(value);
  }

  public setValues(values: EFieldTooltip[]) {
    runInAction(() => {
      this._values.clear();
      values.forEach(v => this._values.add(v));
    });
  }

  public removeValue(value: EFieldTooltip) {
    this._values.delete(value);
  }

  public reset() {
    this._values.clear();
    this._values.add(EFieldTooltip.Name);
  }
}

export default LayerTooltipStore;
