import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';
import { FC } from 'react';
import moment from 'moment';

import {
  ECheckListInstanceType,
  IGetChecklistInstanceByTaskId,
} from '../../../../../../../../../../../../../api/models/checklist/instance/checklist.instance.model';
import { useDataTestIdV2 } from '../../../../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './ViewingPoints.styles';

const getPointName = (point: IGetChecklistInstanceByTaskId, positionNumber: number) => {
  switch (point.type) {
    case ECheckListInstanceType.PlannedPoint:
      return `Точка ${positionNumber}`;
    case ECheckListInstanceType.ActualPoint:
      return `Точка ${positionNumber}`;
    case ECheckListInstanceType.Machinery:
      return `Техника ${positionNumber}`;
    case ECheckListInstanceType.Field:
      return `Поле ${positionNumber}`;
    default:
      return '';
  }
};

const getPointType = (point: IGetChecklistInstanceByTaskId) => {
  switch (point.type) {
    case ECheckListInstanceType.PlannedPoint:
      return 'План';
    case ECheckListInstanceType.ActualPoint:
      return 'Факт';
    case ECheckListInstanceType.Machinery:
      return point.machineryName || '';
    default:
      return '';
  }
};

const ViewingPoint: FC<{
  point: IGetChecklistInstanceByTaskId;
  index: number;
  dataTestId: string;
}> = ({ point, index, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  const { isShowTooltip, ref } = useShowTooltip<HTMLSpanElement>();
  const subtitle = getPointType(point);

  return (
    <Styled.ViewingPoint {...getDataTestId()} key={point.id}>
      <Styled.ViewingPointDescription
        {...getDataTestId(`instance-point-icon${point?.isComplete ? '-complete' : ''}`)}
      >
        {point.isComplete ? <Styled.GreenDot /> : <Styled.InfoDot />}
        <Styled.ViewingPointName key={point.name} {...getDataTestId('instance-point-name')}>
          {getPointName(point, index + 1)}
        </Styled.ViewingPointName>

        <AutoTooltip content={subtitle} disabled={!isShowTooltip} position="top">
          <Styled.ViewingPointType {...getDataTestId('instance-point-type')} ref={ref}>
            {subtitle}
          </Styled.ViewingPointType>
        </AutoTooltip>
      </Styled.ViewingPointDescription>

      <Styled.ViewingPointDate {...getDataTestId('instance-point-date')}>
        {point.isComplete ? moment(point.createOrUpdateDate).format('DD.MM.YY HH:mm') : ''}
      </Styled.ViewingPointDate>
    </Styled.ViewingPoint>
  );
};

export default ViewingPoint;
