import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

interface IProps {
  active?: boolean;
}

const Wrapper = styled('div')<IProps>({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: ESpaceSize.XS,
  backgroundColor: Colors.generalWhite,
});

const ContentWrapper = styled('div')({});

export default {
  Wrapper,
  ContentWrapper,
};
