import styled, { css } from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding-bottom: 12px;
  border-bottom: 1px solid #e7f1fd;
`;

const Dependencies = styled.div<{ $widthIfAlone: string; $quantity: number }>`
  display: grid;
  grid-gap: 12px;

  ${({ $widthIfAlone, $quantity }) => {
    switch ($quantity) {
      case 2:
        return css`
          grid-template-columns: repeat(2, 1fr);
        `;

      default:
        return css`
          grid-template-columns: ${$widthIfAlone};
        `;
    }
  }};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 20px 16px;

  border-radius: 16px;
  border: 1px solid #e7f1fd;
`;

const StyledStoAttributeOdzDependency = {
  TitleWrapper,
  Header,
  Dependencies,
  Container,
  Wrapper,
};

export default StyledStoAttributeOdzDependency;
