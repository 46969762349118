import styled from 'styled-components';

const Wrapper = styled('div')({
  height: '100%',
  overflow: 'hidden',
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',
});

const ListContainer = styled('div')({
  flex: 1,
});

const Styles = {
  Wrapper,
  ListContainer,
};

export default Styles;
