import styled from 'styled-components';

import warningSvg from '../../../../../../../../../../dashboard/static/info.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  margin: 0 0 36px;
  ${({ theme }) => theme.Typography.schemas.title}
  text-align: center;
`;

const Icon = styled.div`
  height: 48px;
  width: 48px;

  margin: 16px 16px 36px;

  background: url(${warningSvg}) no-repeat center;
`;

const Styled = { Wrapper, Text, Icon };

export default Styled;
