import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PortalProvider } from 'react-portal-hook';
import styled, { ThemeProvider } from 'styled-components';
import { observer } from 'mobx-react';
import { DefaultTheme, ModalContainer, Notificator } from '@farmlink/farmik-ui';

// eslint-disable-next-line import/order
import { EAppSettingsType, lsAppSettingsKey } from '..';

import { ApplicationRoutes } from './routes';
import { useStore } from './shared/utils/IoC';
import { Axios } from './shared/utils/axios2';
import AppRouter from './AppRouter';
import { useContextualLink } from './shared/features/ContextualLink/hooks';

export const App: FC = observer(() => {
  const axios = useStore(Axios);
  const location = useLocation();

  const contextualLinkActions = useContextualLink();

  useEffect(() => {
    contextualLinkActions.addStoredLinks();
  }, []);

  const localData = localStorage.getItem(lsAppSettingsKey);
  const orgId = JSON.parse(localData)?.[EAppSettingsType.Organization] || 'my';

  const redirectPath =
    // ApplicationRoutes.Dashboard +
    (location.pathname === '/' ? orgId : location.pathname) + window.location.search;

  let registrationStep2Url =
    process.env?.NODE_ENV === 'development'
      ? ApplicationRoutes.ExternalRegistrationStep2Dev
      : `${window._env_.AUTH_URL}${ApplicationRoutes.ExternalRegistrationStep2}`;
  const systemId = 'assistant';
  registrationStep2Url = `${registrationStep2Url}&systemId=${systemId}`;

  return (
    <>
      <ThemeProvider theme={DefaultTheme}>
        <Wrapper>
          <Notificator />
          <PortalProvider>
            <AppRouter
              needAdditionalInfo={axios?.needAdditionalInfo}
              redirectPath={redirectPath}
              registrationStep2Url={registrationStep2Url}
            />
            <ModalContainer />
          </PortalProvider>
        </Wrapper>
      </ThemeProvider>
    </>
  );
});

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  & .Toastify__toast-container.Toastify__toast-container--top-right {
    top: 1em;
    right: 75px;
  }

  & .cancellationModal-body {
    overflow-y: unset;

    & .cancellationModal-content-wrapper {
      overflow-y: unset;
    }
  }
`;
