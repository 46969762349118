import * as turf from '@turf/turf';

import { lazyInject, provide } from '../../../../../utils/IoC';
import isPointInPolygon from '../../../utils/helpers/isPointInPolygon';
import { MapEventBus } from '../../MapCore';
import { BasePolygon } from '../../../utils/MapElements';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapPolygonStore from '../stores/MapPolygon.store';

@provide.transient()
class MapPolygonEventsService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapPolygonStore)
  private polygonStore: MapPolygonStore;

  public registerClickEvent(polygon: BasePolygon) {
    if (!this.coreStore.instance) {
      return;
    }

    polygon.on('click', this.handleClickPolygon);
  }

  public handleClickPolygon = (event: L.LeafletMouseEvent) => {
    const polygon = event.target as BasePolygon;

    const { selectedPolygon } = this.polygonStore;

    if (polygon && polygon.id !== selectedPolygon?.id) {
      MapEventBus.emit('polygon.click', polygon);
    }
  };

  public handleDeselectPolygon = (event: L.LeafletMouseEvent) => {
    if (!this.polygonStore.selectedPolygon) {
      return;
    }

    const pointFromMouseCoords = turf.point([event.latlng.lng, event.latlng.lat]);

    const isInsidePolygon = this.polygonStore.polygonsList.some(polygon => {
      return isPointInPolygon(pointFromMouseCoords, polygon.getInfo().geometry);
    });

    if (!isInsidePolygon) {
      MapEventBus.emit('polygon.cancelSelected');
    }
  };
}

export default MapPolygonEventsService;
