import React, { FC, useMemo } from 'react';
import { Typography } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { TablePlugBuilder } from '../../../../../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { Container } from '../../../../../../../../../../../shared/features/UI/new/Container';
import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { StoStore } from '../../../../../../mobx';
import { SimpleLoader } from '../../../../../../../../../../../shared/features/UI/new/SimpleLoader';
import { EContainerHeaderType } from '../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';
import { useStoRegistryFromExcelUploader as useFromExcelUploader } from '../../utils/hooks';

import Styled from './StoListNoDataPlug.styled';

interface IProps {
  dataTestId: string;
  title: string;
  onAddFromDictionaryClick: () => void;
}

const StoRegistryNoDataPlug: FC<IProps> = ({ dataTestId, title, onAddFromDictionaryClick }) => {
  const stoStore = useStore(StoStore);

  const fromExcelUploader = useFromExcelUploader();

  const getDataTestId = useDataTestIdV2(dataTestId);

  const Loader = useMemo(() => {
    return (
      <Styled.Button
        color={'default'}
        type={'button'}
        disabled
        dataTestId={getDataTestId('button-loader')['data-test-id']}
      >
        <Styled.LoaderWrapper {...getDataTestId('button-loader__loader-wrapper')}>
          <SimpleLoader dataTestId={getDataTestId()['data-test-id']} size={24} color={'#FFFFFF'} />
        </Styled.LoaderWrapper>
      </Styled.Button>
    );
  }, []);

  return (
    <Container
      dataTestId={getDataTestId()['data-test-id']}
      containerStyles={{ gap: '28px' }}
      bodyStyles={{ paddingBottom: '32px' }}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: title,
        },
      }}
    >
      <TablePlugBuilder
        autoRenderConfig={{
          header: { icon: 'app' },
          content: {
            title: (
              <>
                {!stoStore.isViewOnly ? (
                  <Typography
                    variant={'bodySubAccent'}
                    align={'center'}
                    dataTestId={getDataTestId('title')['data-test-id']}
                  >
                    В реестре пока нет записей. <br /> Вы можете добавить новую запись или загрузить
                    EXL файл
                  </Typography>
                ) : (
                  <Typography
                    variant={'bodySubAccent'}
                    align={'center'}
                    dataTestId={getDataTestId('title')['data-test-id']}
                  >
                    В реестре пока нет записей.
                  </Typography>
                )}
              </>
            ),
          },
          footer: {
            renderButton: () => (
              <>
                {!stoStore.isViewOnly ? (
                  <Styled.Footer {...getDataTestId('footer')}>
                    <Styled.Input
                      {...getDataTestId('upload-dictionary-from-excel__input')}
                      type="file"
                      name="file"
                      ref={fromExcelUploader.fileUploaderRef}
                      onChange={fromExcelUploader.onChange}
                      accept=".xlsx"
                    />

                    <Styled.Button
                      dataTestId={getDataTestId('button-add-from-dictionary')['data-test-id']}
                      color={'default'}
                      styleType={'outlined'}
                      onClick={onAddFromDictionaryClick}
                    >
                      Добавить из справочника
                    </Styled.Button>

                    {fromExcelUploader.isUploadPending ? (
                      Loader
                    ) : (
                      <Styled.Button
                        dataTestId={
                          getDataTestId('button-upload-dictionary-from-excel')['data-test-id']
                        }
                        color={'primary'}
                        styleType={'outlined'}
                        onClick={fromExcelUploader.onUploaderClick}
                      >
                        Загрузить EXL
                      </Styled.Button>
                    )}
                  </Styled.Footer>
                ) : null}
              </>
            ),
          },
        }}
        dataTestId={getDataTestId()['data-test-id']}
      />
    </Container>
  );
};

StoRegistryNoDataPlug.displayName = 'StoRegistryNoDataPlug';

export default observer(StoRegistryNoDataPlug);
