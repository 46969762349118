import { lazyInject, provide } from '../../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../stores';
import { ITableBuilderRow as IRow } from '../../../models/data';
import { ITableBuilderRowConfig as IRowConfig } from '../../../models/configs';

@provide.transient()
class TableBuilderRowsService<E = any, B = string> {
  @lazyInject(Store)
  protected store: Store<E, B>;
  addElementList = (
    builderId: B,
    elementList: E[],
    elementIdKey: keyof E,
    options?: { isClearPreviousList?: boolean }
  ): void => {
    const rowList = this.createRowList(builderId, elementList, elementIdKey);

    this.store.setRowList(builderId, rowList, options);
  };

  addPartialRowConfig = (builderId: B, partialConfig: Partial<IRowConfig<E>>): void => {
    this.store.setPartialRowConfig(builderId, partialConfig);
  };

  createRowList = (builderId: B, elementList: E[], elementIdKey: keyof E): IRow<E, B>[] => {
    const rowList = elementList.map(element => this.createRow(builderId, element, elementIdKey));

    return rowList;
  };

  createRow = (builderId: B, element: E, elementIdKey: keyof E): IRow<E, B> => {
    const row: IRow<E, B> = {
      builderId,
      id: element[elementIdKey as string],
      element,
    };

    return row;
  };

  public highlightRow = (builderId: B, rowId: string): void => {
    const config = this.store.getRowConfig(builderId);

    if (!config) {
      this.store.setPartialRowConfig(builderId, {
        visual: {
          highlightedRowsIds: {
            [rowId]: rowId,
          },
        },
      });

      return;
    }

    this.store.setPartialRowConfig(builderId, {
      ...config,
      visual: {
        ...(config?.visual ?? {}),
        highlightedRowsIds: {
          ...(config?.visual?.highlightedRowsIds ?? {}),
          [rowId]: rowId,
        },
      },
    });
  };

  public removeRowsHighlight = (builderId: B): void => {
    const config = this.store.getRowConfig(builderId);

    if (!config) return;

    this.store.setPartialRowConfig(builderId, {
      ...config,
      visual: {
        ...(config?.visual ?? {}),
        highlightedRowsIds: {},
      },
    });
  };

  public unHighlightRow = (builderId: B, rowId: string): void => {
    const config = this.store.getRowConfig(builderId);

    if (!config) return;

    delete config?.visual?.highlightedRowsIds?.[rowId];

    this.store.setPartialRowConfig(builderId, config);
  };
}

export default TableBuilderRowsService;
