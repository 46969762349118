import React, { FC, memo, MouseEvent } from 'react';

import { useDataTestIdV2 } from '../../../utils/hooks/locators';

import Styled from './ButtonRemove.styles';

interface IProps {
  /**
   * Ключ для автоматизации.
   * Финальное название будет следующего типа: <ПЕРЕДАННАЯ_ВАМИ_СТРОКА>__button-remove.
   */
  dataTestId: string;
  onClick?: () => void;
  size?: 16 | 24;
}

const ButtonRemove: FC<IProps> = ({ dataTestId, onClick, size }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__button-remove`);

  const handleClick = (event: MouseEvent<HTMLOrSVGElement>): void => {
    event.stopPropagation();

    onClick?.();
  };

  return (
    <Styled.Wrapper
      onClick={handleClick}
      {...getDataTestId()}
      width={size ?? 24}
      height={size ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="8" fill="#FEF5F5" />
      <path d="M9.78564 11.4711H10.6161V17.9164H9.78564V11.4711Z" fill="#DF2E22" />
      <path d="M11.5848 11.4711H12.4153V17.9164H11.5848V11.4711Z" fill="#DF2E22" />
      <path d="M13.384 11.4711H14.2145V17.9164H13.384V11.4711Z" fill="#DF2E22" />
      <path
        d="M17.2844 11.139H16.9906L17.1208 9.74935H18.1038V8.38837C18.1038 7.63793 17.4845 7.02738 16.7232 7.02738H15.1034L14.1587 5.16498H9.84121L8.89657 7.02738H7.27675C6.51548 7.02738 5.89611 7.63793 5.89611 8.38837V9.74935H6.87915L7.00931 11.139H6.7156C5.95433 11.139 5.33496 11.7495 5.33496 12.5C5.33496 13.2504 5.95433 13.861 6.7156 13.861H7.26425L7.8238 19.835H16.1761L16.7357 13.861H17.2843C18.0456 13.861 18.665 13.2504 18.665 12.5C18.665 11.7495 18.0456 11.139 17.2844 11.139ZM10.3802 6.02455H13.6198L14.1285 7.02738H9.8715L10.3802 6.02455ZM6.76809 8.38837C6.76809 8.1119 6.99629 7.88695 7.27675 7.88695H16.7232C17.0037 7.88695 17.2319 8.1119 17.2319 8.38837V8.88978H6.76809V8.38837ZM6.7156 13.0014C6.43514 13.0014 6.20694 12.7764 6.20694 12.5C6.20694 12.2235 6.43514 11.9986 6.7156 11.9986H7.08982L7.18376 13.0014H6.7156ZM15.381 18.9754H8.61899L7.75485 9.74935H16.2451L15.381 18.9754ZM17.2844 13.0014H16.8162L16.9102 11.9986H17.2844C17.5648 11.9986 17.793 12.2235 17.793 12.5C17.793 12.7764 17.5648 13.0014 17.2844 13.0014Z"
        fill="#DF2E22"
      />
    </Styled.Wrapper>
  );
};

ButtonRemove.displayName = 'ButtonRemove';

export default memo(ButtonRemove);
