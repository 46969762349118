import { provide, lazyInject } from '../../../../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../../../../stores/seasons.store';
import APIService from '../services/WeatherAPI.service';
import Store from '../stores/Weather.store';

@provide.transient()
class WeatherController {
  @lazyInject(Store)
  private store: Store;

  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  @lazyInject(APIService)
  private apiService: APIService;

  public open(fieldId: string) {
    void this.loadWeather(fieldId);

    this.store.isOpen = true;
    setTimeout(() => {
      this.store.isFullWidth = true;
    });
  }

  public close() {
    this.store.isFullWidth = false;
    setTimeout(() => {
      this.store.isOpen = false;
      this.store.sliderScrollTop = 0;
    }, 300);
  }

  /**
   * Загружает данные погоды для поля
   * @param fieldId - id поля для фетчинга
   * @param skipIfClosed - пропускает загрузку если виджет погоды закрыт
   */
  public async loadWeather(fieldId: string, skipIfClosed = false) {
    const { selectedSeason } = this.seasons;
    const isClosed = !this.store.isOpen;

    if (!selectedSeason || (skipIfClosed && isClosed)) {
      return;
    }

    this.store.isLoading = true;

    const weatherPromise = this.apiService.fetchWeather(fieldId, selectedSeason);
    const weatherForecastPromise = this.apiService.fetchWeatherForecasts(fieldId, selectedSeason);

    await Promise.allSettled([weatherPromise, weatherForecastPromise]).then(
      (res: Array<PromiseSettledResult<any>>) => {
        if (res[0].status === 'fulfilled') {
          this.store.fieldWeather = res[0].value;
        }
        if (res[1].status === 'fulfilled') {
          this.store.fieldWeatherForecasts = res[1].value;
        }

        this.store.isLoading = false;
      }
    );
  }
}

export default WeatherController;
