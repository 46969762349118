import { ReactNode } from 'react';

export enum EStoTab {
  Summary = 'Summary',
  Fields = 'Fields',
  Operations = 'Operations',
  Registries = 'Registries',
  History = 'History',
}

export interface IStoFullscreenConfig {
  goBackText: string;
  titleText: string;
  dataTestIdPrefix: string;
  fullscreenContent: ReactNode;
  titleAdditionalElement?: ReactNode;
}

export interface IInitiateStoOptions {
  isCreation?: boolean;
  stoId?: string;
  tabFromUrl?: EStoTab;
  versionId?: string;
}

export interface IInitiateStoResult {
  redirectTab?: EStoTab;
  isReplace?: boolean;
  isViewOnly?: boolean;
}
