import { isObject } from 'lodash';

import { TGeoObject } from '../../interfaces/WeatherSlider.interface';

const getCurrentGeoPoint = (geoPoint: TGeoObject): string => {
  if (!isObject(geoPoint) || !Object.keys(geoPoint).length) {
    return 'Не определено';
  }

  let result = 'Не определено';

  const geoQueue = ['locality', 'district', 'province', 'country'];

  for (const key of geoQueue) {
    if (key in geoPoint && geoPoint[key] && geoPoint[key].name) {
      result = geoPoint[key].name;
      return result;
    }
  }

  return result;
};

export default getCurrentGeoPoint;
