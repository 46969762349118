import { makeAutoObservable } from 'mobx';
// eslint-disable-next-line
import { isFunction } from 'lodash';
import { ITab } from '@farmlink/farmik-ui/dist/Tabs/types';
import { ISelectOption } from '@farmlink/farmik-ui/dist/types';

import { provide } from '../../../../../../../shared/utils/IoC';
import { EStoTab, IStoFullscreenConfig } from '../../models/StoCommon';
import {
  ICreateSto,
  IStoContent,
  IUpdateSto,
} from '../../../../../../../../api/models/as-fields/STO/STO.model';
import { ERequestStatus } from '../../../../../../../shared/constants/requests';

type TStoreSetter<V = undefined> = (value: V | ((prevValue: V) => V)) => void;

@provide.singleton()
class StoStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _StoTab: EStoTab = null;
  private _StoFullscreenConfig: IStoFullscreenConfig = null;

  private _isCreateMode = false;
  private _hasChanges = false;
  private _isCreateStoFormValid = false;
  private _isEditStoFormValid = false;
  private _isViewOnly = true;

  private _createStoData: Partial<ICreateSto> = null;
  private _selectedSto: IStoContent = null;
  private _requestStoStatus: ERequestStatus | null = null;
  private _updatedSto: IUpdateSto = null;

  private _availableTabList: ITab[] = [];

  // Options
  private _cultureOptionList: ISelectOption[] = [];
  private _fieldOptionList: ISelectOption[] = [];

  get StoTab() {
    return this._StoTab;
  }

  get createStoData() {
    return this._createStoData;
  }

  get isCreateMode() {
    return this._isCreateMode;
  }

  get isViewOnly() {
    return this._isViewOnly;
  }

  get hasChanges() {
    return this._hasChanges;
  }

  get isCreateStoFormValid() {
    return this._isCreateStoFormValid;
  }

  get isEditStoFormValid() {
    return this._isEditStoFormValid;
  }

  get selectedSto() {
    return this._selectedSto;
  }

  get selectedStoId() {
    return this._selectedSto?.id;
  }

  get updatedStoData() {
    return this._updatedSto;
  }

  get availableTabList() {
    return this._availableTabList;
  }

  get requestStoStatus() {
    return this._requestStoStatus;
  }

  get StoFullscreenConfig() {
    return this._StoFullscreenConfig;
  }

  get cultureOptionList() {
    return this._cultureOptionList;
  }

  setStoTab = (tab: EStoTab) => {
    this._StoTab = tab;
  };

  setCreateStoData = (createSTO: Partial<ICreateSto>) => {
    this._createStoData = createSTO;
  };

  setIsCreateMode = (state: boolean) => {
    this._isCreateMode = state;
  };

  setHasChanges = (state: boolean) => {
    this._hasChanges = state;
  };

  setIsCreateStoFormValid = (state: boolean) => {
    this._isCreateStoFormValid = state;
  };

  setIsEditStoFormValid = (state: boolean) => {
    this._isEditStoFormValid = state;
  };

  setIsViewOnly = (state: boolean) => {
    this._isViewOnly = state;
  };

  updateCreateStoData = (updateData: Partial<ICreateSto>) => {
    this._createStoData = { ...this._createStoData, ...updateData };
  };

  setSelectedSto = (STO: IStoContent) => {
    this._selectedSto = STO;
  };

  setUpdatedSto: TStoreSetter<Partial<IUpdateSto>> = value => {
    if (isFunction(value)) {
      this._updatedSto = value(this._updatedSto ?? {}) as IUpdateSto;
    } else {
      this._updatedSto = { ...this._updatedSto, ...value };
    }
  };

  setAvailableTabList = (tabList: ITab[]) => {
    this._availableTabList = tabList;
  };

  setRequestStoStatus = (status: ERequestStatus) => {
    this._requestStoStatus = status;
  };

  setStoFullscreenConfig = (config: IStoFullscreenConfig) => {
    this._StoFullscreenConfig = config;
  };

  setCultureOptionList = (cultureList: typeof this._cultureOptionList) => {
    this._cultureOptionList = cultureList;
  };

  clearStoTab = () => {
    this._StoTab = null;
  };

  clearStoCreate = () => {
    this._createStoData = null;
  };

  clearCreationMode = () => {
    this._isCreateMode = false;
  };

  clearHasChanges = () => {
    this._hasChanges = false;
  };

  clearIsCreateStoFormValid = () => {
    this._isCreateStoFormValid = false;
  };

  clearIsEditStoFromValid = () => {
    this._isEditStoFormValid = false;
  };

  clearIsViewOnly = () => {
    this._isViewOnly = true;
  };

  clearSelectedSto = () => {
    this._selectedSto = null;
  };

  clearUpdatedSto = () => {
    this._updatedSto = null;
  };

  clearAvailableTabList = () => {
    this._availableTabList = [];
  };

  clearRequestStoStatus = () => {
    this._requestStoStatus = null;
  };

  clearStoFullscreenConfig = () => {
    this._StoFullscreenConfig = null;
  };

  clearCultureOptionList = () => {
    this._cultureOptionList = [];
  };

  clearStoStore = () => {
    this.clearStoTab();
    this.clearStoCreate();
    this.clearCreationMode();
    this.clearSelectedSto();
    this.clearUpdatedSto();
    this.clearAvailableTabList();
    this.clearRequestStoStatus();
    this.clearStoFullscreenConfig();
    this.clearCultureOptionList();
    this.clearIsCreateStoFormValid();
    this.clearIsEditStoFromValid();
    this.clearHasChanges();
    this.clearIsViewOnly();
  };
}

export default StoStore;
