import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Wrapper = styled.svg`
  cursor: pointer;

  &:hover {
    & path {
      fill: ${Colors.accentGreen};
      transition: all 0.3s ease-in-out;
    }
  }
`;

const StyledStoAttributeUserDictionaryRegistryItemEditIcon = {
  Wrapper,
};

export default StyledStoAttributeUserDictionaryRegistryItemEditIcon;
