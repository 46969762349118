import { FC, memo } from 'react';
import moment from 'moment/moment';

import calendarSvg from '../../../../../../../../operations/components/TaskView/assets/calendarSvg.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  date: string;
  dataTestId: string;
}
const TaskOperationCreateVerificationDate: FC<IProps> = ({ date, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__date`);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label color={'generalDarkHover'} {...getDataTestId('label')}>
        Дата
      </Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={calendarSvg} />

      <Styled.Info {...getDataTestId('info')}>{moment(date).format('DD.MM.YYYY')}</Styled.Info>
    </Styled.InfoBlock>
  );
};

TaskOperationCreateVerificationDate.displayName = 'TaskOperationCreateVerificationDate';

export default memo(TaskOperationCreateVerificationDate);
