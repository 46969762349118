import Sorter from '../../../../../shared/features/FilterManager/core/Sorter';
import { EFieldsListSort } from '../../interfaces/Filter.interface';
import { Field } from '../../../../../../api/models/field.model';
import { lazyInject } from '../../../../../shared/utils/IoC';
import FieldsMapCoreStore from '../../mobx/stores/FieldsMapCoreStore';

/**
 * Сортировщик для массива полей. Применяется в {@link FilterManager}
 * Соритрует данные при присвоении нового значения в filterManager.sorter.value и при фильтрации
 *
 * Поля, которые были только что добавлены выводятся в начале списка
 */
class FieldsSorter extends Sorter<EFieldsListSort> {
  @lazyInject(FieldsMapCoreStore)
  private fieldsMapCoreStore: FieldsMapCoreStore;

  constructor() {
    super(EFieldsListSort.NAME_ASC);
  }

  public _compareFn = (a: Field, b: Field) => {
    const aIsNewField = this.fieldsMapCoreStore.newFieldsList.indexOf(a.id) > -1;
    const bIsNewField = this.fieldsMapCoreStore.newFieldsList.indexOf(b.id) > -1;

    if (aIsNewField && !bIsNewField) {
      return -1;
    }

    if (!aIsNewField && bIsNewField) {
      return 1;
    }

    return this.compareByValue(a, b);
  };

  public resetValue(skipSorting?) {
    this.setValue(EFieldsListSort.NAME_ASC, skipSorting);
  }

  private compareByValue(a: Field, b: Field): number {
    switch (this.value) {
      case EFieldsListSort.NAME_ASC:
      default:
        return a.name.localeCompare(b.name);

      case EFieldsListSort.NAME_DESC:
        return b.name.localeCompare(a.name);

      case EFieldsListSort.AREA_ASC:
        return b.area - a.area;

      case EFieldsListSort.AREA_DESC:
        return a.area - b.area;

      case EFieldsListSort.NEW:
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    }
  }
}

export default FieldsSorter;
