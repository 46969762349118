import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

interface IPartProps {
  $color: string;
  $width: string;
}

const Wrapper = styled('div')({
  display: 'flex',
  width: '100%',
  height: '4px',
  borderRadius: ESpaceSize.XS,
  marginTop: ESpaceSize.XS,
});

const Part = styled('div')<IPartProps>(
  {
    height: '100%',
  },
  ({ $color, $width }) => ({
    backgroundColor: $color,
    width: $width,
  })
);

export default {
  Wrapper,
  Part,
};
