import { TypeApiRoute } from '../../../models/type.api.request';

type TRequest = { id: string; includeDrafts?: boolean; asOnDate?: string; versionId?: string };

type TResponse = Blob;

export const getStoReport: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/sto/${id}/report`,
  method: 'GET',
  headers: {},
  responseType: 'blob',
  request: {} as TRequest,
  response: {} as TResponse,
};
