import React, { FC, FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Switcher, Typography } from '@farmlink/farmik-ui';
import _ from 'lodash';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { useStoParams } from '../../../../../../../../../../../../../../../../hooks';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../../../../../../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../../../mobx/controllers';
import { Input } from '../../../../../../../../../../../../../../../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';
import { StoAttributeCoreStore as Store } from '../../../../mobx/stores';
import { StoStore } from '../../../../../../../../../../../../../../mobx';

import Styled from './StoAttributeToolTipSelector.styles';

const StoAttributeToolTipSelector: FC = () => {
  const stoStore = useStore(StoStore);
  const store = useStore(Store);
  const checklistsStore = useStore(ChecklistsStore);

  const controller = useStore(Controller);

  const params = useStoParams();

  const { isToolTipEnabled, toolTip } = checklistsStore.getAttrUpdate(
    params.checklistId,
    params.stageId,
    params.attributeId
  );

  const [enteredValue, setEnteredValue] = useState<string>(toolTip ?? '');

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const checkIfSelectorIsDisabled = (): boolean => {
    if (!isToolTipEnabled) return true;

    return false;
  };

  const checkIfNeedToShowInput = (): boolean => {
    if (isToolTipEnabled) return true;

    if (!isToolTipEnabled && toolTip) return true;

    return false;
  };

  useEffect(() => {
    setEnteredValue(toolTip);
  }, [toolTip]);

  const debounceOnChange = useRef(
    _.debounce((newValue: string): void => {
      controller.changeAttribute(params, {
        toolTip: newValue,
      });
    }, 500)
  );

  const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>): void => {
    event.target.scrollTo({ top: 0 });
  }, []);

  const handleInputChange = useCallback((newValue: string): void => {
    setEnteredValue(newValue);
    debounceOnChange.current(newValue);
  }, []);

  const handleIsEnabledChange = useCallback((value: boolean): void => {
    controller.changeAttribute(params, { isToolTipEnabled: value });
  }, []);

  const getDataTestId = useDataTestIdV2('sto__attribute__tool-tip-selector');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Header>
        <Switcher
          value={isToolTipEnabled}
          onChange={handleIsEnabledChange}
          dataTestId={'switcher'}
          isBlocked={checkIfIsBlockedByPermissions()}
        />

        <Typography
          color={checkIfSelectorIsDisabled() ? 'secondaryDark' : 'generalDark'}
          variant={'title'}
          dataTestId={getDataTestId('title')['data-test-id']}
        >
          Показывать подсказку
        </Typography>
      </Styled.Header>

      {checkIfNeedToShowInput() ? (
        <Input
          value={enteredValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          label={'Текст подсказки'}
          maxLength={4000}
          as={'textarea'}
          height={'80px'}
          isBlocked={checkIfIsBlockedByPermissions() || checkIfSelectorIsDisabled()}
          hideScrollbar={true}
          isResizable={false}
          dataTestId={getDataTestId('input')['data-test-id']}
          error={store.hasErrors ? 'Это поле не может быть пустым' : ''}
        />
      ) : null}
    </Styled.Wrapper>
  );
};

StoAttributeToolTipSelector.displayName = 'StoAttributeToolTipSelector';

export default observer(StoAttributeToolTipSelector);
