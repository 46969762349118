import { lazyInject, provide } from '../../../../../../../../../../../../shared/utils/IoC';
import { StoOperationCoreStore as CoreStore } from '../../stores';

@provide.transient()
class StoOperationHeaderService {
  @lazyInject(CoreStore)
  protected coreStore: CoreStore;

  public createTitle = (selectedOperationId: string): string => {
    if (!this.coreStore.selectedSto) return 'Настройка чек-листа';

    const { selectedSto } = this.coreStore;

    const selectedOperation = selectedSto.stoOperations.find(
      o => o?.techOperationType?.id === selectedOperationId
    );

    if (!selectedOperation) return 'Настройка чек-листа';

    return `${selectedOperation.techOperationType.name}: настройка чек-листа`;
  };

  public createSubtitle = (): string => {
    if (!this.coreStore.selectedSto) return null;

    const { selectedSto } = this.coreStore;

    const fieldsCount = selectedSto.cultureZones.length;

    return `${selectedSto.name} • ${selectedSto.culture.name} • Поля: ${fieldsCount}`;
  };
}

export default StoOperationHeaderService;
