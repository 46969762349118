import { ProfileStore } from '../../../../../../../../../../../dashboard/modules/profile/stores/ProfileStore';
import { OrganizationsStore } from '../../../../../../../../../../../dashboard/stores/organizations.store';
import { lazyInject, provide } from '../../../../../../../../../../utils/IoC';
import {
  EIndexType,
  IndexModel,
} from '../../../../../../../../../../../../api/models/indices.model';
import { Axios } from '../../../../../../../../../../utils/axios2';
import { TFetchArgs } from '../../interfaces/IndicesFill.interface';
import Store from '../stores/Indices.store';

@provide.transient()
class IndicesAPIService {
  @lazyInject(Axios)
  private axios: Axios;

  @lazyInject(Store)
  private store: Store;

  @lazyInject(ProfileStore)
  private profileStore: ProfileStore;

  @lazyInject(OrganizationsStore)
  private organizationStore: OrganizationsStore;

  public fetchIndices(partialArgs: TFetchArgs): Promise<IndexModel[]> {
    const response = this.axios.api.getIndicesList({
      ...partialArgs,
      status: [EIndexType.Completed],
    });

    return response.then(({ content }) => content).catch(() => []);
  }

  public fetchIndicesYears(partialArgs: TFetchArgs): Promise<number[]> {
    const response = this.axios.api.getIndicesYearList({
      fieldId: partialArgs.fieldId,
      indexName: partialArgs.indexName,
      seasonYear: partialArgs.year,
      status: [EIndexType.Completed],
    });

    return response.then(years => years).catch(() => []);
  }

  public buildVisImageUrl(url: string): string {
    const { fieldId, year } = this.store.fetchArgs;
    const orgId = this.organizationStore.selectedOrganizationId;
    const userId = this.profileStore.user?.id;

    const orgIdQueryArg = orgId === 'my' || !orgId ? '' : `&organizationId=${orgId}`;

    return `${url}?fieldId=${fieldId}&seasonYear=${year}&userId=${userId}${orgIdQueryArg}`;
  }
}

export default IndicesAPIService;
