import EventEmitter2, { Listener, OnOptions } from 'eventemitter2';

interface IExtendedOnOptions extends OnOptions {
  // Перед подпиской удаляет все листенеры с таким eventName
  unsubscribeBefore?: boolean;
}

/**
 * При надобности можно расширять данный emitter
 */
class TypedEventEmitter<TEvents extends Record<string, any>> {
  private emitter = new EventEmitter2();

  public emit<N extends keyof TEvents & string>(eventName: N, ...args: Parameters<TEvents[N]>) {
    this.emitter.emit(eventName, ...args);
  }

  public on<N extends keyof TEvents & string>(
    eventName: N,
    handler: TEvents[N],
    options: IExtendedOnOptions = { objectify: true }
  ): Listener {
    const count = this.emitter.listenerCount(eventName);

    if (count > 5) {
      console.warn(
        `Количество листенеров для (${eventName}) первысило [${count}]. Возможна утечка памяти`
      );
    }

    if (options.unsubscribeBefore) {
      this.removeAllListeners(eventName);
    }

    return this.emitter.on(eventName as string, handler, options) as Listener;
  }

  public off<N extends keyof TEvents & string>(eventName: N, handler: TEvents[N]) {
    this.emitter.off(eventName, handler as any);
  }

  public removeAllListeners<N extends keyof TEvents & string>(eventName?: N) {
    this.emitter.removeAllListeners(eventName);
  }
}

export default TypedEventEmitter;
