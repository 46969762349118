import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { TChecklistsDoubleAttrToDraw as TDoubleAttrToDraw } from '../../../../models';
import { Input } from '../../../../../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { formatEnteredDoubleValue } from '../../../../../../../../../shared/utils/helpers/formatEnteredValue';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList as useAttrErrorList,
  useChecklistsAttrVisibility as useAttrVisibility,
} from '../../../../hooks';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  attrToDraw: TDoubleAttrToDraw;
}

const ChecklistsDoubleAttr: FC<IProps> = ({ attrToDraw }) => {
  const { id, groupId, initialModel, value, isBlocked, dependentFileAttrId } = attrToDraw;

  const getDataTestId = useDataTestIdV2('checklists__double-attribute');

  const { changeAttrValue } = useStore(ChecklistsController);

  const [enteredValue, setEnteredValue] = useState(value.doubleValue);

  const isShowAttribute = useAttrVisibility(attrToDraw);
  const errorList = useAttrErrorList(attrToDraw);
  const placeholder = useAttrPlaceholder(initialModel.attribute);

  useEffect(() => {
    setEnteredValue(value.doubleValue);
  }, [value.doubleValue]);

  const debounceOnChange = useRef(
    _.debounce((newValue: string): void => {
      changeAttrValue(EAttrType.Double, groupId, {
        ...value,
        doubleValue: newValue,
      });
    }, 500)
  );

  const handleInputChange = useCallback((newValue: string): void => {
    const validatedValue = formatEnteredDoubleValue(newValue, {
      allowNegativeNumber: true,
    });

    if (!_.isUndefined(validatedValue)) {
      const isFractional = newValue.includes('.');

      if (isFractional) {
        const [intPart, fractPart] = newValue.split('.');

        if (fractPart.length > initialModel.attribute?.precision) {
          const formattedNumber = `${intPart}.${fractPart.slice(
            0,
            initialModel.attribute.precision
          )}`;

          setEnteredValue(formattedNumber);
          debounceOnChange.current(formattedNumber);
        } else {
          setEnteredValue(newValue);
          debounceOnChange.current(newValue);
        }
      } else {
        setEnteredValue(newValue);
        debounceOnChange.current(newValue);
      }
    }
  }, []);

  return (
    <>
      {isShowAttribute ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
          dataTestId={getDataTestId()['data-test-id']}
        >
          <Input
            isBlocked={isBlocked}
            value={enteredValue}
            onChange={handleInputChange}
            isRequired={initialModel.isRequired}
            label={initialModel.attribute?.name}
            placeholder={placeholder}
            maxLength={16}
            tooltip={initialModel.toolTip}
            isWithoutErrorText
            dataTestId={getDataTestId('input')['data-test-id']}
          />

          <CSSContainer
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={'column'}
            dataTestId={getDataTestId('additional-info')['data-test-id']}
          >
            <CSSContainer dataTestId={getDataTestId('error-wrapper')['data-test-id']}>
              <InputFieldError error={{ errorList }} />
            </CSSContainer>

            <FileAttrContainer
              groupId={groupId}
              attrId={dependentFileAttrId}
              isNeedAdjustableContainer
            />
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsDoubleAttr.displayName = 'ChecklistsDoubleAttr';

export default observer(ChecklistsDoubleAttr);
