import { area, Geometry } from '@turf/turf';

import toTurfPolygon from './toTurfPolygon';

/**
 * Вычисляет площать полигона/геометрии в гектарах
 */
function calculateArea(polygonOrGeometry: L.Polygon | Geometry) {
  const polygon = toTurfPolygon(polygonOrGeometry);

  return area(polygon) / 10000;
}

export default calculateArea;
