import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { TableBuilder } from '../../../../../shared/features/TableBuilder';
import { FieldsApiService } from '../../../../../shared/mobx/services/as-fields';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import { ITask } from '../../../../../../api/models/as-fields/task/task.model';
import { TasksDrawer } from '../TasksDrawer';
import { TableBuilderController } from '../../../../../shared/features/TableBuilder/mobx/controllers';
import { ITableBuilderRowConfig } from '../../../../../shared/features/TableBuilder/models/configs';
import { ETasksTableBuilderId } from '../../utils/constants';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { useTaskDataConsistency } from '../../hooks';
import { TableFiltersBuilderController as FiltersBuilderController } from '../../../../../shared/features/TableFiltersBuilder/mobx/controllers';

import { TasksListController } from './mobx/controllers';
import { TasksListNoDataPlug as NoDataPlug, TaskListNoSeasonPlug } from './components/plugs';

const TasksList: FC = () => {
  const tasksListController = useStore(TasksListController);
  const fieldsStore = useStore(FieldsStore);

  const tableBuilderController = useStore(TableBuilderController);
  const tableFiltersBuilderController = useStore(FiltersBuilderController);

  const seasonsStore = useStore(SeasonsStore);
  const fieldsService = useStore(FieldsApiService);
  const { isFieldsLoaded, isOrganizationLoaded, isSeasonLoaded } = useTaskDataConsistency({
    consistencyKey: 'tasks',
  });

  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);

  const isNoSeason = !seasonsStore.selectedSeason || seasonsStore.selectedSeason === '';
  const isNoFields = !fieldsStore.hasFields;
  const isDataLoading = seasonsStore.loading || fieldsStore.isLoading;
  const isDataLoaded = !isDataLoading && isFieldsLoaded && isOrganizationLoaded && isSeasonLoaded;

  const handleDrawerClose = useCallback(() => {
    tableBuilderController.highlightRow('tasks', selectedTask.id);
    setSelectedTask(null);
  }, [selectedTask]);

  const handleRowClick = useCallback<ITableBuilderRowConfig<ITask>['onRowClick']>((row, event) => {
    event.stopPropagation();

    setSelectedTask(row);
  }, []);

  useEffect(() => {
    tasksListController.initiateTable();

    tableBuilderController.addRowClickEvent(ETasksTableBuilderId.Tasks, handleRowClick);
    tableBuilderController.showDefaultPlug(ETasksTableBuilderId.Tasks);

    tableBuilderController.addPlugConfig(
      ETasksTableBuilderId.Tasks,
      'noSeason',
      <TaskListNoSeasonPlug />
    );

    tableBuilderController.showLoader(ETasksTableBuilderId.Tasks);
  }, []);

  useEffect(() => {
    if (!seasonsStore.selectedSeason) {
      return;
    }

    // Так как фетчинг полей теперь отвязан от сезонов, необходимо фетчить их вручную
    void fieldsService.fetchFieldsList();
  }, [seasonsStore.selectedSeason]);

  /**
   * Быстрофиксом накидал лишних рендеров
   * Проблема: редкий кейс при обновлении страницы таблица не получает поля
   * TODO оптимизировать кол-во рендеров путём анализа и степпинга порядка загрузки необходимых данных
   * можно использовать хук `useTaskDataConsistency` для описания этой логики там
   */
  useEffect(() => {
    if (isDataLoading) {
      tableBuilderController.showLoader(ETasksTableBuilderId.Tasks);
      return;
    }

    if (isDataLoaded || isNoSeason || isNoFields) {
      if (isNoSeason || isNoFields) {
        tableBuilderController.displayPlug(ETasksTableBuilderId.Tasks, 'noSeason');
        tableBuilderController.hideLoader(ETasksTableBuilderId.Tasks);

        tableFiltersBuilderController.blockFilters('tasks');
        return;
      }

      tableBuilderController.displayPlug(ETasksTableBuilderId.Tasks, null);
      tableBuilderController.hideDefaultPlug(ETasksTableBuilderId.Tasks);
      tableBuilderController.hideLoader(ETasksTableBuilderId.Tasks);

      tableFiltersBuilderController.unBlockFilters('tasks');
    }
  }, [seasonsStore.selectedSeason, isDataLoaded, isDataLoading, isNoSeason, isNoFields]);

  return (
    <>
      <TableBuilder
        builderId={ETasksTableBuilderId.Tasks}
        renderNoDataPlug={() => <NoDataPlug />}
        showMoveTop
      />

      {selectedTask ? (
        <TasksDrawer fromModule={'tasks'} task={selectedTask} onClose={handleDrawerClose} />
      ) : null}

      <Outlet />
    </>
  );
};

TasksList.displayName = 'TasksList';

export default observer(TasksList);
