import { FieldSeasonsModel } from '../../../../../../../../api/models/field.seasons.model';
import { Axios } from '../../../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import Store from '../stores/FieldCultures.store';

@provide.transient()
class FieldCulturesAPIService {
  @lazyInject(Axios)
  private axios: Axios;

  @lazyInject(Store)
  private store: Store;

  public fetchCultures(query: string) {
    return this.axios.api.findAllCultures(
      {
        nameFilter: query,
        fetchAttributes: true,
        attrs: {
          useInAssistance: true,
        },
        limit: 50,
        page: 0,
      },
      { omit: ['limit', 'page'] }
    );
  }

  public saveCultureZones(season: FieldSeasonsModel) {
    // Сохраняем только КЗ у которых есть культура
    const filledCultureZones = this.store.culturesZonesList.filter(zone => zone.culture.id);

    this.store.isLoading = true;

    const seasonYear = Number(season.season.year);

    const promise = this.axios.api.updateCultureZones({
      fieldId: this.store.activeField.id,
      seasonYear,
      zones: filledCultureZones.map(zone => {
        const zoneId = zone.id.includes('empty-') ? '' : zone.id;

        return {
          id: zoneId,
          name: zone.name,
          cultureZoneId: zoneId,
          variety: zone.variety,
          seasonYear,
          cultureId: zone.culture.id,
          geoJson: {
            type: 'Feature',
            geometry: zone.geometry,
          },
          experimentReady: zone.experimentReady,
          ...(zone.sowingDate ? { sowingDate: String(zone.sowingDate) } : {}),
          ...(zone.harvestDate ? { harvestDate: String(zone.harvestDate) } : {}),
        };
      }),
    });

    return promise.finally(() => {
      this.store.isLoading = false;
    });
  }
}

export default FieldCulturesAPIService;
