export enum EFieldsImportFileExtension {
  Kml = 'kml',
  GeoJSON = 'geojson',
  Zip = 'zip',
  Shape = 'shp',
  Cpg = 'cpg',
  Prj = 'prj',
  Qmd = 'qmd',
  Shx = 'shx',
  Dbf = 'dbf',
}
