import L from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import MapEventBus from '../../MapCore/services/MapEventBus.service';
import { BasePolygon } from '../../../utils/MapElements';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapPolygonEventsService from '../../MapPolygon/services/MapPolygonEvents.service';
import MapPolygonStore from '../../MapPolygon/stores/MapPolygon.store';
import MapDrawerStore from '../stores/MapDrawer.store';

/**
 * Данный сервис регистрирует функции которые отвечают за рисование и редактирование на карте.
 */
@provide.transient()
class MapDrawerEventsService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapDrawerStore)
  private store: MapDrawerStore;

  @lazyInject(MapPolygonStore)
  private polygonStore: MapPolygonStore;

  @lazyInject(MapPolygonEventsService)
  private polygonEventsService: MapPolygonEventsService;

  // Глобальные события рисования
  registerGlobalEvents = () => {
    if (!this.coreStore.instance) {
      return;
    }

    this.coreStore.instance.on('pm:create', this.handleDrawCreate);
    this.coreStore.instance.on('pm:drawstart', this.handleDrawStart);
    this.coreStore.instance.on('pm:drawend', this.handleDrawEnd);
  };

  public registerEditPolygonEvents(polygon: BasePolygon) {
    polygon.on('pm:edit', this.handleEditPolygon);

    polygon.on('pm:vertexclick', this.handleClickVertex);
    polygon.on('pm:vertexadded', this.handleAddedVertex);
    polygon.on('pm:vertexremoved', this.handleRemoveVertex);

    polygon.on('pm:markerdragend', this.handleMarkerDragEnd);
  }

  public unregisterEditPolygonEvents(polygon: BasePolygon) {
    polygon.off('pm:edit', this.handleEditPolygon);

    polygon.off('pm:vertexclick', this.handleClickVertex);
    polygon.off('pm:vertexadded', this.handleAddedVertex);
    polygon.off('pm:vertexremoved', this.handleRemoveVertex);

    polygon.off('pm:markerdragend', this.handleMarkerDragEnd);
  }

  public registerCutEvents() {
    this.coreStore.instance.on('pm:cut', this.handleCut);
  }

  public unregisterCutEvents() {
    this.coreStore.instance.off('pm:cut', this.handleCut);
  }

  /**
   * Вызывается после вызова функции instance.pm.enableDraw()
   */
  private handleDrawStart: L.PM.DrawStartEventHandler = ({ workingLayer }) => {
    MapEventBus.emit('draw.start');

    workingLayer.on('pm:vertexadded', this.handleAddedVertex);
    workingLayer.on('pm:vertexremoved', this.handleRemoveVertex);

    this.store.isDrawEnabled = true;
  };

  /**
   * Вызывается после вызова функции instance.pm.disableDraw().
   * Также вызывается после завершения создания объекта, когда был передан параметр { continueDrawing: false }
   */
  private handleDrawEnd: L.PM.DrawStartEventHandler = () => {
    MapEventBus.emit('draw.end');

    this.store.isDrawEnabled = false;
  };

  /**
   * Вызывается после редактирования конкретного полигона
   */
  private handleEditPolygon: L.PM.EditEventHandler = event => {
    // @ts-ignore
    const polygonFromEvent = event.layer as BasePolygon;

    if (polygonFromEvent) {
      MapEventBus.emit('draw.polygon.edit', polygonFromEvent);
    }
  };

  /**
   * Вызывается после завершения рисования фигуры
   */
  private handleDrawCreate: L.PM.CreateEventHandler = event => {
    if (!event?.layer) {
      return;
    }

    /**
     * На данный момент рисование реализовано только для фигуры "Polygon".
     * В будущем хотелось бы добавить поддержку рисования маркеров. На данный момент маркеры рисуются с использованием instance.on('click')
     * Основная проблема с поддержкой маркеров это валидация границ, внутри которых должен быть нарисован маркер. В geoman нет такой проверки
     */
    if (event.shape === 'Polygon') {
      const drawnLayer = event.layer as L.Polygon;
      const { geometry } = drawnLayer.toGeoJSON(false);

      const polygon = new BasePolygon(geometry, {
        pmIgnore: false,
        ...event.layer.options,
      });

      this.coreStore.instance.addLayer(polygon);
      this.coreStore.instance.removeLayer(event.layer);

      this.polygonStore.setPolygon(polygon);

      polygon?.bringToFront();

      // установка режима редактирования для нового полигона. Стоит ли делать это тут?
      this.registerEditPolygonEvents(polygon);
      polygon.pm.enable({
        allowSelfIntersection: false,
        preventMarkerRemoval: false,
        removeLayerBelowMinVertexCount: false,
      });

      MapEventBus.emit('draw.polygon.create', polygon);
    }
  };

  /**
   * Вызывается после добавления Vertex у полигона
   */
  private handleAddedVertex: L.PM.VertexAddedEventHandler = event => {
    if (event.shape === 'Polygon') {
      MapEventBus.emit('draw.polygon.vertex.add', event.marker);
    }
  };

  /**
   * Вызывается после удаления Vertex у полигона
   */
  private handleRemoveVertex: L.PM.VertexRemovedEventHandler = event => {
    if (event.shape === 'Polygon') {
      MapEventBus.emit('draw.polygon.vertex.remove', event.marker);
    }
  };

  /**
   * Вызывается после клика на Vertex
   */
  private handleClickVertex: L.PM.VertexClickEventHandler = event => {
    if (event.shape === 'Polygon') {
      MapEventBus.emit('draw.polygon.vertex.click', {
        // @ts-ignore
        marker: event?.markerEvent?.target || event?.marker,
        polygon: event.layer as BasePolygon,
        index: (event.indexPath as unknown) as [number, number],
      });
    }
  };

  /**
   * Вызывается после завершения движения маркера
   */
  private handleMarkerDragEnd: L.PM.MarkerDragEndEventHandler = event => {
    if (event.shape === 'Polygon') {
      MapEventBus.emit('draw.polygon.marker.drag', {
        // @ts-ignore
        marker: event?.markerEvent?.target || event?.marker,
        polygon: event.layer as BasePolygon,
        index: (event.indexPath as unknown) as [number, number],
      });
    }
  };

  private handleCut: L.PM.CutEventHandler = event => {
    console.log(event);
  };
}

export default MapDrawerEventsService;
