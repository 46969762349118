import { Colors, Ellipsis } from '@farmlink/farmik-ui';
import { IColorScheme } from '@farmlink/farmik-ui/dist/IconButton/IconButton.styles';
import styled from 'styled-components';

import { FontSizesObj } from '../../../../../../shared/constans/sizes';

interface ITitleProps {
  fontSize?: number;
  fontWeight?: number;
  active?: boolean;
}

const TextWrapper = styled('div')({
  width: '100%',
  margin: '0 8px',
  overflow: 'hidden',
});

const Title = styled(Ellipsis)<ITitleProps>(
  {
    margin: 0,
    lineHeight: '20px',
  },
  ({ active, fontSize, fontWeight }) => ({
    color: active ? Colors.accentGreen : Colors.generalBlack,

    fontSize: fontSize ?? 14,
    fontWeight: active ? 600 : fontWeight ?? 400,
  })
);

const Subtitle = styled(Ellipsis)({
  margin: 0,
  ...FontSizesObj.medium,
  color: Colors.secondaryDarkGray,
});

const IconWrapper = styled('div')({
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
});

const hoverIconColorScheme: IColorScheme = {
  default: {
    icon: Colors.generalBlack,
    background: 'transparent',
  },
  hover: {
    icon: Colors.accentGreen,
    background: 'transparent',
  },
};

const Styles = {
  TextWrapper,
  Title,
  Subtitle,
  IconWrapper,
  hoverIconColorScheme,
};

export default Styles;
