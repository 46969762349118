import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import { StoHistoryService } from '../services';

@provide.transient()
class StoHistoryController {
  @lazyInject(StoHistoryService)
  protected service: StoHistoryService;

  fetchStoHistory = (stoId?: string) => {
    return this.service.fetchStoHistory(stoId);
  };
}

export default StoHistoryController;
