import {
  lazyInject,
  provide,
} from '../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreService as Service } from '../../services';
import { IStoParams } from '../../../../../../../../../../../../../../../models';
import { StoAttributeCoreStore as Store } from '../../stores';
import { IStoAttributeOdzDependencySelect as IDependencySelect } from '../../../interfaces';

@provide.transient()
class StoAttributeCoreController {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(Service)
  protected service: Service;

  public get isPrintModeSelectorAvailable() {
    return this.service.isPrintModeSelectorAvailable;
  }

  public get isNumericOdz() {
    return this.service.isNumericOdz;
  }
  public get isDictionaryOdz() {
    return this.service.isDictionaryOdz;
  }

  public get isNeedOdz() {
    return this.service.isNeedOdz;
  }

  public initiatePage = async (params: IStoParams): Promise<void> => {
    await this.service.initiatePage(params);
  };

  public addDepEnumOptionList: Service['addDepEnumOptionList'] = async depAttrId => {
    await this.service.addDepEnumOptionList(depAttrId);
  };

  public addDepDictionaryOptionList: Service['addDepDictionaryOptionList'] = async (
    depAttrId,
    dictionaryLink
  ) => {
    await this.service.addDepDictionaryOptionList(depAttrId, dictionaryLink);
  };

  public checkIfOdzGroupsIsEmpty: Service['checkIfOdzGroupsIsEmpty'] = params => {
    return this.service.checkIfOdzGroupsIsEmpty(params);
  };

  public enableOdz: Service['enableOdz'] = params => {
    this.service.enableOdz(params);
  };

  public disableOdz: Service['disableOdz'] = params => {
    this.service.disableOdz(params);
  };

  public enableOdzDependency: Service['enableOdzDependency'] = params => {
    return this.service.enableOdzDependency(params);
  };

  public disableOdzDependency: Service['disableOdzDependency'] = params => {
    return this.service.disableOdzDependency(params);
  };

  public addOdzGroup: Service['addOdzGroup'] = params => {
    return this.service.addOdzGroup(params);
  };

  public removeOdzGroup: Service['removeOdzGroup'] = (groupId, params) => {
    return this.service.removeOdzGroup(groupId, params);
  };

  public changeOdzNumericValue: Service['changeOdzNumericValue'] = (params, groupId, value) => {
    this.service.changeOdzNumericValue(params, groupId, value);
  };

  public changeOdzDictionaryValue: Service['changeOdzDictionaryValue'] = (
    params,
    groupId,
    value
  ) => {
    this.service.changeOdzDictionaryValue(params, groupId, value);
  };

  public changeOdzDependencyNumericValue: Service['changeOdzDependencyNumericValue'] = (
    params,
    groupId,
    dependencyId,
    value
  ) => {
    this.service.changeOdzDependencyNumericValue(params, groupId, dependencyId, value);
  };

  public changeOdzDependencyDictionaryValue: Service['changeOdzDependencyDictionaryValue'] = (
    params,
    groupId,
    dependencyId,
    value
  ) => {
    this.service.changeOdzDependencyDictionaryValue(params, groupId, dependencyId, value);
  };

  public changeAttribute: Service['changeAttribute'] = (params, data) => {
    this.service.changeAttribute(params, data);
  };

  public addNewUserDictionaryValue: Service['addNewUserDictionaryValue'] = (params, value) => {
    this.service.addNewUserDictionaryValue(params, value);
  };

  public toggleSelectionUserDictionaryValue: Service['toggleSelectionUserDictionaryValue'] = (
    params,
    value,
    isSelected
  ) => {
    this.service.toggleSelectionUserDictionaryValue(params, value, isSelected);
  };

  public removeUserDictionaryValue: Service['removeUserDictionaryValue'] = (params, value) => {
    this.service.removeUserDictionaryValue(params, value);
  };

  public editUserDictionaryValue: Service['editUserDictionaryValue'] = (params, value) => {
    this.service.editUserDictionaryValue(params, value);
  };

  public removeUserDictionaryHighlight: Service['removeItemHighlight'] = itemId => {
    this.service.removeItemHighlight(itemId);
  };

  public saveChanges: Service['saveChanges'] = props => {
    this.service.saveChanges(props);
  };

  public undoTheChanges: Service['undoTheChanges'] = params => {
    this.service.undoTheChanges(params);
  };

  public changeDependencySelect: Service['changeDependencySelect'] = (params, data) => {
    this.service.changeDependencySelect(params, data);
  };

  public addDependencySelect: Service['addDependencySelect'] = params => {
    this.service.addDependencySelect(params);
  };

  public removeDependencySelect: Service['removeDependencySelect'] = (id, params) => {
    this.service.removeDependencySelect(id, params);
  };

  public clearStore = (): void => {
    this.service.clearStore();
  };

  public clearAlreadyChangedAttrIds = (): void => {
    this.service.clearAlreadyChangedAttrIds();
  };
}

export default StoAttributeCoreController;
