import { FC } from 'react';
import { observer } from 'mobx-react';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { IComparisonTableBuilderRow } from '../../../models/data';
import { ComparisonTableBuilderGrid as Grid } from '../../ComparisonTableBuilderGrid';
import { ComparisonTableBuilderController as Controller } from '../../../mobx/controllers';
import { useComparisonTableBuilderContext } from '../../../context';
import { ComparisonTableBuilderCellContainer as CellContainer } from '../../cells';
import { useStore } from '../../../../../utils/IoC';

interface IProps {
  row: IComparisonTableBuilderRow;
}

const ComparisonTableBuilderRowCustomRenderContainer: FC<IProps> = ({ row }) => {
  const controller = useStore(Controller);
  const context = useComparisonTableBuilderContext();

  const cells = row?.isWithoutCells
    ? { fixedCellList: [], scrollableCellList: [] }
    : controller.getOrderedCellList(context.builderId, row.id);

  const getDataTestId = useDataTestId({ componentName: 'custom-row' });
  const dataTestId = getDataTestId()['data-test-id'];

  if (row?.isWithoutCells) {
    return <>{row.customRenderConfig.render(row, dataTestId)}</>;
  }

  return (
    <Grid
      fixedChildren={
        <>
          {row.customRenderConfig.render(row, dataTestId)}

          {cells.fixedCellList.map(cell => (
            <CellContainer key={cell.columnId} cell={cell} />
          ))}
        </>
      }
      scrollableChildren={cells.scrollableCellList.map(cell => (
        <CellContainer key={cell.columnId} cell={cell} />
      ))}
    />
  );
};

ComparisonTableBuilderRowCustomRenderContainer.displayName =
  'ComparisonTableBuilderRowCustomRenderContainer';

export default observer(ComparisonTableBuilderRowCustomRenderContainer);
