import { Button } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const SingleButton = styled(Button)`
  width: 164px;
  height: 40px;
  border-radius: 12px;
`;

const Styled = { SingleButton };

export default Styled;
