import { Layer } from 'leaflet';

import { EFieldTooltip } from '../../../../../../../../../dashboard/constants/FieldTooltip.enum';
import { Field } from '../../../../../../../../../../api/models/field.model';
import { lazyInject, provide } from '../../../../../../../../utils/IoC';
import makeTooltip from '../../utils/helpers/makeTooltip.helper';
import { MapCoreController } from '../../../../../../modules';
import Store from '../store/LayerTooltip.store';

@provide.transient()
class LayerTooltipController {
  @lazyInject(Store)
  private store: Store;

  @lazyInject(MapCoreController)
  private mapCoreController: MapCoreController;

  /**
   * Устанавливает тултип на слой карты.
   * Удаляет тултип если values пустой
   *
   * @param values - сборные части тултипа
   * @param field - поле для которого нужно установить тултип. Если поле не передано то устанавливает тултипы на все слои карты
   */
  public setContent(values: EFieldTooltip[], field?: Field) {
    this.store.setValues(values);

    if (field) {
      const content = makeTooltip(field, values);
      const layer = this.mapCoreController.getMapLayerById(field?.polyId);

      this.toggleTooltip(layer, content);

      return;
    }

    this.store.allFields.forEach(fieldItem => {
      const content = makeTooltip(fieldItem, values);
      const layer = this.mapCoreController.getMapLayerById(fieldItem?.polyId);

      this.toggleTooltip(layer, content);
    });
  }

  private toggleTooltip(layer: Layer | null, content: string | null) {
    if (content) {
      layer?.setTooltipContent(content);

      return;
    }

    layer?.unbindTooltip();
  }
}

export default LayerTooltipController;
