import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Item = styled.div<{ $isLast?: boolean }>`
  width: 100%;
  height: 36px;
  margin-top: ${ESpaceSize.M}px;
`;

const Styled = {
  Item,
};

export default Styled;
