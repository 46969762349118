import styled from 'styled-components';

const TableWrapper = styled.div`
  & .sto-history-tab-table__header-wrapper {
    padding: 24px 24px 0;
    border-radius: 16px;
  }
`;

const Styled = { TableWrapper };

export default Styled;
