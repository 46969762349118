import L, { LeafletMouseEvent } from 'leaflet';

import { TypedEventEmitter } from '../../../../../utils/helpers/TypedEventEmitter';
import { IMapLayerGroupClickPayload, IMapVertexInfo as IVertexInfoModel } from '../../../models';
import { BasePolygon, BaseMarker } from '../../../utils/MapElements';

export type TMapEvents = {
  // Вызывается при клике на область карты без полигонов/слоев
  'map.click': (event: LeafletMouseEvent) => void;

  'layerGroup.click': (payload: IMapLayerGroupClickPayload) => void;

  // Вызывается при клике на область карты без слоев
  'layerGroup.cancelSelected': () => void;

  // Вызывается при клике на полигон
  'polygon.click': (polygon: BasePolygon) => void;

  // Вызывается при клике на область карты без слоев
  'polygon.cancelSelected': () => void;

  'polygon.undo': (polygon: BasePolygon) => void;

  // Нужно для обратной совместимости с useInspectionActionsHook
  'map.marker.createOnClick': (marker: BaseMarker) => void;

  // Вызывается после вызова функции instance.pm.enableDraw()
  'draw.start': () => void;

  // Вызывается после вызова функции instance.pm.disableDraw()
  'draw.end': () => void;

  // Вызывается после вызова функции pm.enable()
  'draw.enableEdit': () => void;

  // Вызывается после вызова функции pm.disable()
  'draw.disableEdit': () => void;

  // Вызывается после завершения рисования фигуры
  'draw.polygon.create': (polygon: BasePolygon) => void;

  // Вызывается после редактирования конкретного полигона
  'draw.polygon.edit': (polygon: BasePolygon) => void;

  // Вызывается после добавления нового Vertex для полигона
  'draw.polygon.vertex.add': (newVertex: L.Marker) => void;

  // Вызывается после удаления вертекса полигона
  'draw.polygon.vertex.remove': (vertex: L.Marker) => void;

  // Вызывается после клика на Vertex
  'draw.polygon.vertex.click': (vertexInfo: IVertexInfoModel) => void;

  // Вызывается на сдвиг маркера
  'draw.polygon.marker.drag': (vertexInfo: IVertexInfoModel) => void;

  'popup.click': (parent: BasePolygon) => void;
};

const mapEventBus = new TypedEventEmitter<TMapEvents>();

export default mapEventBus;
