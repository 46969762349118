import { provide } from '../../../../../utils/IoC';
import {
  IContextualLink as ILink,
  IContextualLinkStorageData as IStorageData,
} from '../../../models';
import { CONTEXTUAL_LINK_STORAGE_KEY as STORAGE_KEY } from '../../../constants';

@provide.transient()
class ContextualLinkStorageService {
  public setLink = (link: ILink): void => {
    const storedData = this.getStoredData();

    const updatedData: IStorageData = {
      ...storedData,
      contextualLinks: {
        ...storedData.contextualLinks,
        [link.id]: link,
      },
    };

    localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedData));
  };

  public getLinkList = (): ILink[] => {
    const data = this.getStoredData();

    const linkList = Object.values(data.contextualLinks);

    return linkList;
  };

  public removeLink = (id: string): void => {
    const storedData = this.getStoredData();

    delete storedData.contextualLinks[id];

    localStorage.setItem(STORAGE_KEY, JSON.stringify(storedData));
  };

  protected getStoredData = (): IStorageData => {
    const storedData = localStorage.getItem(STORAGE_KEY);

    if (!storedData) {
      return {
        contextualLinks: {},
      };
    }

    const formattedData = JSON.parse(storedData) as any;

    /**
     * В дальнейшем будет удалено.
     * Это обратная совместимость, так как изменились свойства, что хранятся в localStorage.
     */
    if (formattedData?.contextualLinkList) {
      localStorage.removeItem(STORAGE_KEY);

      return {
        contextualLinks: {},
      };
    }

    return formattedData as IStorageData;
  };
}

export default ContextualLinkStorageService;
