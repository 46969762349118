import { FC, ReactElement, useEffect } from 'react';
import { useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { v1 as uuid } from 'uuid';

import { useDataTestIdV2 } from '../../../shared/features/utils/hooks/locators';
import { FilterController } from '../../../shared/features/FilterManager';
import { MapCoreController } from '../../../shared/features/map/modules';
import { IMapGlobalConfig } from '../../../shared/features/map/models';
import { Field } from '../../../../api/models/field.model';
import { useStore } from '../../../shared/utils/IoC';
import { Map } from '../../../shared/features/map';

import { FIELDS_COMMON_MODALS_UNIQUE_KEY, fieldsCommonModalConfigList } from './modals';
import { EFieldsListSort, TFiltersTuple } from './interfaces/Filter.interface';
import Styled from './FieldsLayout.styles';
import {
  FieldAreaFilter,
  FieldCultureFilter,
  FieldInExpFilter,
  FieldNameFilter,
  FIELDS_FILTER_MANAGER_ID,
  FieldsSorter,
  SearchByNameFilter,
} from './utils';

interface IProps {
  id: string;
  leftPanel: ReactElement;
  map: {
    config: Omit<IMapGlobalConfig, 'mapKey'>;
  };
  mapWidgets?: ReactElement[];
}

const FieldsLayout: FC<IProps> = props => {
  // Контроллеры
  const mapCoreController = useStore(MapCoreController);
  const filterController = useStore(FilterController);

  const modal = useModal();
  const getDataTestId = useDataTestIdV2(props.id);

  const centerOfMap = mapCoreController.getCenterOfMap();

  /**
   * Инициализирует фильтр менеджер. На данный момент фильтр менеджер используется в режимах View и Multiselect.
   * Фильтр менеджер находится в shared store для более гибкой его настройки между режимами
   */
  const createFilterManager = () => {
    // Пропускаем инициализацию если фильтр-менеджер существует
    if (filterController.isExist(FIELDS_FILTER_MANAGER_ID)) {
      return;
    }

    filterController.createManager<TFiltersTuple, Field, EFieldsListSort>(
      FIELDS_FILTER_MANAGER_ID,
      [],
      [
        new FieldNameFilter(),
        new FieldAreaFilter(),
        new FieldInExpFilter(),
        new SearchByNameFilter(),
        new FieldCultureFilter(),
      ],
      new FieldsSorter()
    );
  };

  useEffect(() => {
    createFilterManager();
    modal.registerModalList(fieldsCommonModalConfigList, FIELDS_COMMON_MODALS_UNIQUE_KEY);
  }, []);

  return (
    <Styled.Wrapper {...getDataTestId('layout-wrapper')}>
      {props.leftPanel}

      <Styled.MapsControlsWrapper data-test-id={'fields-map'}>
        {props.mapWidgets?.map(element => (
          <div key={uuid()}>{element}</div>
        ))}

        <Styled.MapWrapper {...getDataTestId('map-wrapper')}>
          <Map
            globalConfig={{
              ...props.map.config,
              leafletOptions: {
                center: centerOfMap.latLng,
                zoom: centerOfMap.zoom,
                ...props.map.config.leafletOptions,
              },
              mapKey: `${props.id}-map`,
            }}
          />
        </Styled.MapWrapper>
      </Styled.MapsControlsWrapper>
    </Styled.Wrapper>
  );
};

export default observer(FieldsLayout);
