import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Wrapper = styled.div`
  width: 335px;
  height: 100%;
  background: ${Colors.generalWhite};
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Slider = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  flex: 0 0 auto;
`;

const Footer = styled.div`
  height: 104px;
  width: 100%;
  border-top: 0.5px solid ${Colors.borderGrey};
  margin-top: auto;
  position: absolute;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  bottom: 0;
  display: flex;
  flex-direction: row;
  background: ${Colors.generalWhite};
  z-index: 100;
`;

const Header = styled.div`
  height: 63px;
  width: 100%;
  display: flex;
  padding-left: 18px;
  padding-right: 18px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid ${Colors.borderGrey};
  position: absolute;
  top: 0;
  background: ${Colors.generalWhite};
  z-index: 100;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 68px;
  margin-bottom: 106px;
  /* padding-bottom: 106px; */
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;
  & .ReactVirtualized__Grid {
    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const LabelWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Label = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  color: ${Colors.generalDark};
  margin: 0;
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  margin-top: 120px;
`;

const PlaceholderDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
`;

const PlaceholderImage = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 24px;
`;

const PlaceholderTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 12px;
`;

export default {
  Wrapper,
  Slider,
  ListWrapper,
  LabelWrapper,
  Header,
  Footer,
  Label,

  PlaceholderWrapper,
  PlaceholderDescription,
  PlaceholderImage,
  PlaceholderTitle,
};
