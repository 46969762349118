import { makeAutoObservable } from 'mobx';

import { IGetChecklist } from '../../../../../api/models/checklist/checklist.model';
import {
  IDrawChecklistInstance,
  IGetChecklistInstance,
} from '../../../../../api/models/checklist/instance/checklist.instance.model';
import { IGetIntensity } from '../../../../../api/models/intensity/intensity.model';
import { provide } from '../../../../shared/utils/IoC';
import { sortBy } from '../../../../shared/features/utils/helpers/sort';
import { IChecklistInstanceEvent } from '../../../../../api/models/checklist/instance/events/checklist.instance.event.model';
import { ISelectOption } from '../../../../../types/selectOption';

export enum EChecklistMode {
  View = 'VIEW',
  Edit = 'EDIT',
}

@provide.singleton()
export class ChecklistInstancesStore {
  /** TODO: описать комментариями принадлежность ниже значений
   * (или вынести в контроллер подключив соответствующий стор) */
  hasPositionToInstanceChanged = false;
  taskId: string;

  private _isFormChanged = false;

  // Ниже располагаются выбранные значения чек-листа

  private _selectedInstance: IGetChecklistInstance | null = null;

  private _selectedIntensity: IGetIntensity | null = null;

  private _selectedInstanceEventList: IChecklistInstanceEvent[] = [];

  // Ниже располагаются коллекции данных

  private _checklistOptionsByValue = new Map<string, ISelectOption<IGetChecklist>>();

  private _idToExtendedInstance = new Map<string, IGetChecklistInstance>();

  private _idToDrawPointInst: Map<string, IDrawChecklistInstance> = new Map<
    string,
    IDrawChecklistInstance
  >();

  private _idToDrawMachineryInst: Map<string, IDrawChecklistInstance> = new Map<
    string,
    IDrawChecklistInstance
  >();

  private _idToIntensity = new Map<string, IGetIntensity>();

  // Специальное свойство, чтобы можно было предзаполнять дропдаун чек-листа пока не исправим компонент дропдауна.
  private _defaultChecklist: IGetChecklist | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Геттеры

  get isFormChanged() {
    return this._isFormChanged;
  }

  get selectedInstance() {
    return this._selectedInstance;
  }

  get selectedIntensity() {
    return this._selectedIntensity;
  }

  getChecklistOption = (id: string): ISelectOption<IGetChecklist> => {
    return this._checklistOptionsByValue.get(id);
  };

  get checklistOptionList(): ISelectOption<IGetChecklist>[] {
    return [...this._checklistOptionsByValue.values()];
  }

  get intensityList(): IGetIntensity[] {
    return Array.from(this._idToIntensity.values());
  }

  get drawInstanceList(): IDrawChecklistInstance[] {
    return [...this.listOfDrawPointInst, ...this.listOfDrawMachineryInst];
  }

  get listOfDrawPointInst(): IDrawChecklistInstance[] {
    return Array.from(this._idToDrawPointInst.values());
  }

  get listOfDrawMachineryInst(): IDrawChecklistInstance[] {
    return Array.from(this._idToDrawMachineryInst.values());
  }
  getTheBiggestDrawInstPosNum = (): number => {
    const drawInstList = this.listOfDrawPointInst;

    const theBiggestPositionNumber = drawInstList.map<number>(
      ({ positionNumber }) => positionNumber
    );

    if (theBiggestPositionNumber.length) {
      return Math.max(...theBiggestPositionNumber);
    }

    return 0;
  };

  get drawInstWithTheLowestPosNum(): IDrawChecklistInstance {
    const drawInstList = [...this.listOfDrawPointInst, ...this.listOfDrawMachineryInst];

    const [firstInstance] = sortBy(drawInstList, 'positionNumber');

    return firstInstance;
  }

  get selectedInstanceEventList() {
    return this._selectedInstanceEventList;
  }

  get defaultChecklist() {
    return this._defaultChecklist;
  }

  // Сеттеры
  setSelectedIntensity = (intensity: IGetIntensity): void => {
    this._selectedIntensity = intensity;
  };

  setSelectedInstance = (instance: IGetChecklistInstance): void => {
    this._selectedInstance = instance;
  };

  setChecklistOptionList = (optionList: ISelectOption<IGetChecklist>[]): void => {
    const col: Map<string, ISelectOption<IGetChecklist>> = new Map();

    optionList.forEach(o => col.set(o.initialModel.id, o));

    this._checklistOptionsByValue = col;
  };

  setExtendedInstance = (instance: IGetChecklistInstance): void => {
    this._idToExtendedInstance.set(instance.id, instance);
  };

  setIdToIntensity = (id: string, intensity: IGetIntensity): void => {
    this._idToIntensity.set(id, intensity);
  };
  setHasPositionToInstanceChanged = (value: boolean) => {
    this.hasPositionToInstanceChanged = value;
  };
  setIdToDrawInst = (listOfDrawPointInst: IDrawChecklistInstance[] = []): void => {
    const newCol: Map<string, IDrawChecklistInstance> = new Map<string, IDrawChecklistInstance>();

    listOfDrawPointInst.forEach(drawInst => newCol.set(drawInst.id, drawInst));

    this._idToDrawPointInst = newCol;
  };

  setDrawInst = (drawInst: IDrawChecklistInstance): void => {
    this._idToDrawPointInst.set(drawInst.id, drawInst);
  };

  setSelectedInstanceEventList = (eventList: IChecklistInstanceEvent[]) => {
    this._selectedInstanceEventList = eventList;
  };

  delDrawInst = (id: string): void => {
    this._idToDrawPointInst.delete(id);
  };

  setDefaultChecklist = (checklist: IGetChecklist): void => {
    this._defaultChecklist = checklist;
  };

  // Ниже методы для приведения значений в сторе к дефолтному состоянию

  clearIsFormChanged = (): void => {
    this._isFormChanged = false;
  };

  clearSelectedInstance = (): void => {
    this._selectedInstance = null;
  };

  clearSelectedIntensity = (): void => {
    this._selectedIntensity = null;
  };

  clearChecklistOptionsByValue = (): void => {
    this._checklistOptionsByValue.clear();
  };

  clearIdToExtendedInstance = (): void => {
    this._idToExtendedInstance.clear();
  };

  clearIdToIntensity = (): void => {
    this._idToIntensity.clear();
  };

  clearIdToDrawPointInst = (): void => {
    this._idToDrawPointInst.clear();
  };

  clearIdToDrawMachineryInst = (): void => {
    this._idToDrawMachineryInst.clear();
  };

  clearSelectedInstanceEventList = () => {
    this._selectedInstanceEventList = [];
  };

  clearDefaultChecklist = (): void => {
    this._defaultChecklist = null;
  };

  clearChecklistInstancesStore = (): void => {
    this.clearIsFormChanged();

    this.clearSelectedInstance();
    this.clearSelectedIntensity();

    this.clearIdToExtendedInstance();
    this.clearIdToIntensity();
    this.clearIdToDrawPointInst();
    this.clearIdToDrawMachineryInst();

    this.clearSelectedInstanceEventList();
  };
}
