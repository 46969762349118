import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  padding-left: 22px;
`;

const StyledTaskOperationCreate = {
  TitleWrapper,
};

export default StyledTaskOperationCreate;
