import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useCallback } from 'react';

import { useOperationsTaskActions } from '../../hooks/useOperationsTaskActions';

import createTasksSvg from './assets/svgCreateTasks.svg';
import {
  BottomBlockWrapperOfTasksList,
  CreateNewTaskButtonWrapper,
  CreateTasksSVG,
  CreateTasksText,
  CreateTasksTitle,
} from './style';

const BottomBlockOfTasksList = () => {
  const operationsTaskActions = useOperationsTaskActions();

  const handlePresetTaskClick = useCallback(() => {
    operationsTaskActions.presetTask();
  }, [operationsTaskActions.presetTask]);

  return (
    <BottomBlockWrapperOfTasksList>
      <CreateTasksSVG src={createTasksSvg} />
      <CreateTasksTitle>Создайте новую задачу</CreateTasksTitle>
      <CreateTasksText>Планируйте работы и следите за ходом их выполнения</CreateTasksText>
      <CreateNewTaskButtonWrapper>
        <Button color={'primary'} type={'button'} onClick={handlePresetTaskClick}>
          Добавить задачу
        </Button>
      </CreateNewTaskButtonWrapper>
    </BottomBlockWrapperOfTasksList>
  );
};

export default observer(BottomBlockOfTasksList);
