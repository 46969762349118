import { Input } from '@farmlink/farmik-ui';
import { FC } from 'react';

import { IInputProps } from '../../types/CommonProps.types';

const SortInput: FC<IInputProps> = ({ cultureZone }) => {
  const handleChange = (value: string) => {
    const regexp = /^[а-яёА-ЯЁa-zA-Z0-9 ]+$/iu;
    if (!regexp.test(value) && value !== '') {
      return;
    }

    if (cultureZone.variety) {
      cultureZone.variety = value;
    }
  };

  // TODO: (скрывать до реализации справочника сортов!)
  return null;

  return (
    <Input
      type={'text'}
      label={'Сорт'}
      onChange={handleChange}
      value={cultureZone?.variety}
      placeholder={'Выберите сорт'}
      blocked
      disabled={true}
      dataTestId="select-variety-culture-zone"
    />
  );
};

export default SortInput;
