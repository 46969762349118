import { Geometry } from '@turf/turf';
import L, { Content, Tooltip } from 'leaflet';
import { cloneDeep } from 'lodash';
import { Feature, Polygon, MultiPolygon } from 'geojson';

import { IMapPolygonInfo } from '../../../../models';
import { BaseTooltipOptions } from '../../../constants';
import { getLatLngsFromGeometry, toTurfPolygon } from '../../../helpers';
import calculateArea from '../../../helpers/calculateArea';
import AbstractPolygon from '../AbstractPolygon';

import PolygonErrors from './errors/PolygonErrors';
import CareTaker from './undoRedo/CareTaker';
import Memento from './undoRedo/Memento';

/**
 * @module BasePolygon
 * Базовый полигон для работы с картами. Наследуется от стандартного L.Polygon.
 * Содержит вспомогательные функции для работы с полигоном.
 * При инициализации создаёт уникальный leaflet-id
 */
class BasePolygon extends AbstractPolygon {
  // Вспомогательный класс для обработки ошибок полигона
  public errors = new PolygonErrors(this);

  public careTaker = new CareTaker(this);

  /**
   * Возвращает вспомогательную информацию о полигоне
   */
  public getInfo(): IMapPolygonInfo {
    const geoJSON = this.toGeoJSON(false);

    return {
      area: calculateArea(this),
      coordinates: geoJSON.geometry.coordinates,
      geometry: geoJSON.geometry,
    };
  }

  /**
   * Возвращает turf версию полигона
   */
  public toTurf(): Feature<Polygon | MultiPolygon> {
    return toTurfPolygon(this);
  }

  /**
   * Включает/Выключает геоман для полигона. По дефлоту у всех полигонов геоман выключен
   */
  public setGeomanModule(value: boolean) {
    this.options.pmIgnore = !value;
    L.PM.reInitLayer(this);
  }

  /**
   * Перерисовывает тултип
   */
  public rerenderTooltip() {
    const tooltip = cloneDeep(this.getTooltip());
    if (!tooltip) {
      return;
    }

    this.unbindTooltip();
    this.bindTooltip(tooltip.getContent(), tooltip.options);
  }

  /**
   * Устанавливает контент для тултипа. В случае если тултип не был создан,
   * то создает его с {@link BaseTooltipOptions базовыми опциями}
   */
  public setTooltipContent(content: Content | Tooltip): this {
    if (!this.getTooltip()) {
      this.bindTooltip(content, BaseTooltipOptions);
      return;
    }

    return super.setTooltipContent(content);
  }

  /**
   * Устанавливает предыдущий сохраненный стиль
   */
  public setPrevStyle(): void {
    this.setStyle(this._prevOptions);
    this.updateGeomanCachedColor(this._prevOptions?.color);
  }

  public saveState() {
    return new Memento({
      geometry: this.toTurf().geometry,
      options: this.options,
    });
  }

  public restore(memento: Memento) {
    const state = memento.getState();

    this.changeGeometry(state.geometry);
    this.setStyle(state.options);
    this.redraw();
  }

  /**
   * Изменяет геометрию полигона. Обновляет geoman markers (если включено)
   * @param geometry - геометрия в {@link Geometry geojson формате}.
   * Если не передать геометрию, то применит this.initialGeometry
   */
  public changeGeometry(geometry?: Geometry) {
    const latLngs = getLatLngsFromGeometry(geometry ?? this.initialGeometry);

    this.setLatLngs(latLngs);

    if (this.pm?.enabled()) {
      this.reInitGeomanMarkers();
    }
  }

  /**
   * Геоман может перезаписывать стиль полигона закешированным стилем. Пример:
   * 1. {@link https://github.com/geoman-io/leaflet-geoman/blob/develop/src/js/Edit/L.PM.Edit.Line.js#L401}
   *
   * Чтобы избежать этой проблемы необходимо вручную обновлять переменную закшированного стиля.
   * Side эффекты этой функции до конца не выявлены
   */
  public updateGeomanCachedColor(color: string) {
    if (!this.pm) {
      return;
    }

    // @ts-ignore
    this.pm.cachedColor = color;
  }

  /**
   * В случае если геометрия полигона меняется вручную (через метод this.setLatLngs()),
   * то необходимо вручную переинициализировать некоторые функции Geoman (если он включен). <br/>
   * {@link https://github.com/geoman-io/leaflet-geoman/blob/develop/src/js/Edit/L.PM.Edit.Line.js#L796}
   * Вызов данной функции полностью пересоздаёт маркеры. Соответственно любое состояние текущих маркеров будет сброшено
   */
  private reInitGeomanMarkers() {
    // @ts-ignore
    this.pm._initMarkers();

    if (this.pm.getOptions().snappable) {
      // @ts-ignore
      this.pm._initSnappableMarkers();
    }
  }
}

export default BasePolygon;
