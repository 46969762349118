import styled, { css } from 'styled-components';

const Operation = styled.div`
  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  /* h3: semi16 */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.15px;
`;

const Checklists = styled.div`
  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  /* note: reg12 */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

const Body = styled.div<{ $isSelected: boolean }>`
  ${({ $isSelected }) =>
    $isSelected
      ? css`
          & ${Operation}, ${Checklists} {
            color: #151f32;
          }
        `
      : css`
          & ${Operation}, ${Checklists} {
            color: #7e8b9d;
          }
        `};
`;

const Wrapper = styled.div<{ $isLast: boolean; $isViewOnly?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $isViewOnly }) =>
    $isViewOnly
      ? '40px calc(100% - 40px - 166px - 24px) 166px'
      : '40px calc(100% - 40px - 84px - 24px) 84px'};
  align-items: center;
  grid-gap: 12px;

  padding: 12px 0;

  border-bottom: 1px solid #e9e6f0;

  ${({ $isLast }) =>
    $isLast &&
    css`
      padding-bottom: 8px;
      border-bottom: none;
    `};
`;

const StyledStoOperationsListItem = {
  Operation,
  Checklists,
  Body,
  Wrapper,
};

export default StyledStoOperationsListItem;
