import styled from 'styled-components';

const Header = styled.div``;

const Description = styled.span`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;

  color: #7e8b9d;
`;

const Title = styled.span`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.15px;

  color: #151f32;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 12px;

  margin-top: 16px;
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTablePlugBuilderAutoRender = {
  Header,
  Description,
  Title,
  Content,
  Wrapper,
};

export default StyledTablePlugBuilderAutoRender;
