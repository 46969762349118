import { InputProps } from '@farmlink/farmik-ui';
import { FC } from 'react';

import SearchStyled from '../../SearchInput.styles';

import Styled from './InputWithHint.styles';

interface IProps extends InputProps {
  isOpen: boolean;
  hint: string;
}

const InputWithHint: FC<IProps> = props => {
  return (
    <Styled.Wrapper>
      {/* @ts-ignore */}
      <SearchStyled.Input {...props} />
      {props.isOpen && props.hint && <Styled.Hint>{props.hint}</Styled.Hint>}
    </Styled.Wrapper>
  );
};

export default InputWithHint;
