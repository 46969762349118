import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TableFiltersBuilderController } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import {
  STOFiltersConfigsService as ConfigsService,
  STOFiltersOptionsService as OptionsService,
} from '../../services';
import { ETableFiltersBuilderId } from '../../../../../../../constants/configs/TableFiltersBuilderId';
import { ISTOFilters as IFilters } from '../../../models';
import { StoListStore } from '../../../../StoListContainer/mobx/stores';
import { StoService } from '../../../../../../../../shared/mobx/services/as-fields';

type TConfigOfCreateConfig = Parameters<ConfigsService['createConfig']>[0];

@provide.transient()
class STOFiltersController {
  @lazyInject(StoListStore)
  protected STOListStore: StoListStore;

  @lazyInject(StoService)
  protected STOService: StoService;

  @lazyInject(ConfigsService)
  protected configsService: ConfigsService;

  @lazyInject(OptionsService)
  protected optionsService: OptionsService;

  @lazyInject(TableFiltersBuilderController)
  protected tableFiltersBuilderController: TableFiltersBuilderController<IFilters>;

  public initiateFilters = (serviceConfig: TConfigOfCreateConfig): void => {
    this.tableFiltersBuilderController.blockFilters(ETableFiltersBuilderId.Sto);

    const config = this.configsService.createConfig(serviceConfig);

    this.tableFiltersBuilderController.initiateConfig(config);
  };

  public addOptions = async (): Promise<void> => {
    const [
      cultureOptionList,
      cultureZoneOptionList,
    ] = await this.optionsService.createOptionsList();

    this.optionsService.addCultureOptionList(cultureOptionList);
    this.optionsService.addCultureZoneOptionList(cultureZoneOptionList);
  };
}

export default STOFiltersController;
