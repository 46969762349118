import _ from 'lodash';
import { runInAction } from 'mobx';

import {
  TChangeImportedFieldReq,
  TGetImportedFieldListReq,
  TImportFieldsFromKmlRes,
  TImportFieldsFromShapeReq,
  TImportFieldsFromShapeRes,
} from '../../../../../../../../api/api';
import { Field } from '../../../../../../../../api/models/field.model';
import { EImportedFieldErrorType } from '../../../../../../../../api/models/fields/getImportedField.model';
import {
  IMapLayerGroupClickPayload,
  IMapLayerGroupConfig,
} from '../../../../../../../shared/features/map/models';
import { EPolygonErrorType } from '../../../../../../../shared/features/map/models/PolygonErrors/PolygonErrors.model';
import { MapDrawerController } from '../../../../../../../shared/features/map/modules';
import { MapEventBus } from '../../../../../../../shared/features/map/modules/MapCore';
import {
  BasePolygon,
  FieldLayerGroup,
  FieldPolygon,
} from '../../../../../../../shared/features/map/utils/MapElements';
import { PolygonValidator } from '../../../../../../../shared/features/map/utils/validators';
import { mapToArray } from '../../../../../../../shared/utils/helpers/map/mapToArray';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EFieldTooltip } from '../../../../../../constants/FieldTooltip.enum';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../../stores/seasons.store';
import { ProfileStore } from '../../../../../profile/stores/ProfileStore';
import { FIELD_POLYGON_OPTIONS, EFieldsMode } from '../../../../utils';
import { EFieldsImportModalName } from '../../utils/constants';
import { importedFieldsSessionStorageHelpers } from '../../utils/helpers';
import FieldsMapCoreController from '../../../../mobx/controllers/FieldsMapCoreController';
import Store from '../stores/FieldsImport.store';
import Services from '../services/FieldsImport.service';

/**
 * TODO: Класс раздут, разбить на сервисы
 */
@provide.transient()
class FieldsImportController extends FieldsMapCoreController {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(OrganizationsStore)
  private organizationStore: OrganizationsStore;

  @lazyInject(ProfileStore)
  private profileStore: ProfileStore;

  @lazyInject(SeasonsStore)
  private seasonsStore: SeasonsStore;

  @lazyInject(Services)
  private service: Services;

  @lazyInject(MapDrawerController)
  private mapDrawerController: MapDrawerController;

  /**
   * Загружает и добавляет полигоны на карту
   */
  public async initialize(): Promise<void> {
    this.coreStore.fieldsMode = EFieldsMode.IMPORT;

    const { getToken } = importedFieldsSessionStorageHelpers;

    await this.fieldsApiService.fetchFieldsList();

    if (this.store.isEmptyImportedFieldsList) {
      await this.seasonsStore.waitSeasons();
      await this.profileStore.waitUser();

      const token = getToken(
        this.organizationStore.selectedOrganizationId,
        this.seasonsStore.selectedSeason
      );

      if (!token) {
        return;
      }

      await this.fetchImportedList(token).then(() => {
        this.addFieldsToMap();
      });

      return;
    }

    await this.addFieldsToMap();
  }

  public destroy() {
    super.destroy();

    this.store.clearStore();
  }

  /**
   * Переопределяет метод абстрактного класса.
   * Выбирает полигон на карте и устанавливает ему режим редактирования
   */
  public selectField(field: Field) {
    const { editableField } = this.store;

    if (editableField?.id === field.id) {
      return;
    }

    const selectedLayerGroup = this.getLayerGroupByField(field);
    if (!selectedLayerGroup) {
      return;
    }

    this.mapCoreController.centerOnBounds(selectedLayerGroup, { animate: false });

    if (editableField) {
      const editableLayerGroup = this.getLayerGroupByField(editableField);
      const editablePolygon = editableLayerGroup.getMainPolygon();
      this.mapDrawerController.disableEditPolygon(editablePolygon);
    }

    const fieldPolygon = selectedLayerGroup.getMainPolygon();
    this.mapDrawerController.enableEditPolygon(fieldPolygon, { allowSelfIntersection: true });

    runInAction(() => {
      this.store.setEditableField({
        ...field,
        areaInHectare: fieldPolygon.getInfo().area,
      });
    });
  }

  // Переопределяет метод абстрактного класса
  protected getLayerGroupConfig(field: Field): IMapLayerGroupConfig {
    const fieldPolygonStyle = field?.isImported
      ? FIELD_POLYGON_OPTIONS.edit
      : FIELD_POLYGON_OPTIONS.display;

    const baseConfig = super.getLayerGroupConfig(field);

    baseConfig.options.isAllowToClick = field?.isImported;

    baseConfig.layerGroup = new FieldLayerGroup(field, {
      optimization: { isClusterable: false, isRenderViewport: true },
      fieldOptions: fieldPolygonStyle,
    });

    return baseConfig;
  }

  protected registerMapEvents() {
    const listener1 = MapEventBus.on('layerGroup.click', this.handleLayerGroupClick);
    const listener2 = MapEventBus.on('draw.polygon.edit', this.handlePolygonEdit);

    this.coreStore.setMapEventListeners([listener1, listener2]);
  }

  protected handleLayerGroupClick = (payload: IMapLayerGroupClickPayload) => {
    const fieldOfPolygon = this.getFieldByLayerGroup(payload.layerGroup);

    this.selectField(fieldOfPolygon);
  };

  public handlePolygonEdit = (polygon: BasePolygon) => {
    const editableField = this.store.updateEditableField(polygon.getInfo());

    const validator = this.validatePolygon(polygon);
    this.checkErrors(validator.getTouchedList());

    void this.changeImportedField({
      id: editableField.id,
      name: editableField.name,
      geoJson: {
        type: 'Feature',
        geometry: editableField.geometry,
      },
    });

    return validator.errors.list;
  };

  importFields = async (apiName: string, formData: FormData): Promise<TImportFieldsFromKmlRes> => {
    const { selectedOrganizationId } = this.organizationStore;
    const { selectedSeason } = this.seasonsStore;
    const { setToken } = importedFieldsSessionStorageHelpers;

    this.store.setIsDataBeingProcessed(true);

    const res = await this.service
      .importFields(apiName, {
        organizationId: selectedOrganizationId === 'my' ? '' : selectedOrganizationId,
        seasonYear: Number(selectedSeason),
        file: formData,
      })
      .catch(() => null);

    if (!res) {
      this.store.setIsDataBeingProcessed(false);

      return res;
    }

    if (!res?.errorType) {
      setToken(selectedOrganizationId, selectedSeason, res?.token);
    }

    return res;
  };

  importFieldsFromShape = async (formData: FormData): Promise<TImportFieldsFromShapeRes> => {
    const { selectedOrganizationId } = this.organizationStore;
    const { selectedSeason } = this.seasonsStore;
    const { setToken } = importedFieldsSessionStorageHelpers;

    this.store.setIsDataBeingProcessed(true);

    const payload: TImportFieldsFromShapeReq = {
      organizationId: selectedOrganizationId === 'my' ? '' : selectedOrganizationId,
      seasonYear: Number(selectedSeason),
      files: formData,
    };

    const importShapeRes = await this.service.importFieldsFromShape(payload);

    if (!importShapeRes) {
      this.store.setIsDataBeingProcessed(false);

      return importShapeRes;
    }

    if (!importShapeRes?.errorType) {
      setToken(selectedOrganizationId, selectedSeason, importShapeRes?.token);
    }

    setToken(selectedOrganizationId, selectedSeason, importShapeRes?.token);

    return importShapeRes;
  };

  fetchImportedList = async (token: string): Promise<boolean> => {
    const importListPayload: TGetImportedFieldListReq = {
      token,
      size: 500,
    };

    const importListRes = await this.service.getImportedFieldList(importListPayload);

    if (!importListRes) {
      this.store.setIsDataBeingProcessed(false);

      return false;
    }

    const { content } = importListRes;

    this.store.setIdToImportedField(content);
    this.store.setIsDataBeingProcessed(false);

    return true;
  };

  deleteImportedField = async (field: Field): Promise<void> => {
    // Пытаемся обновить уже удаленное поле
    if (this.store.deletableFieldIdList.has(field?.id)) {
      this.store.deletableFieldIdList.delete(field?.id);

      return Promise.resolve();
    }

    this.store.setIsWaitingForEditRes(true);

    this.store.addDeletableFieldId(field.id);

    const res = await this.service.deleteImportedFieldById(field.id);

    this.store.setIsWaitingForEditRes(false);

    if (res !== EFieldsImportModalName._Success) {
      this.store.setSaveErrorModal(res);

      this.store.removeDeletableFieldId(field.id);

      return;
    }

    /**
     * Если удаляется поле с битой геометрией - оно не существует в контексте карты и
     * присутствует только в коллекциях. Поиск полигона не имеет смысла.
     */
    if (field.errorType !== EImportedFieldErrorType.InvalidGeometry) {
      const layerGroup = this.getLayerGroupByField(field);
      const mainPolygon = layerGroup?.getMainPolygon();

      if (!mainPolygon) {
        return;
      }

      const intersectedPolygons = mainPolygon.errors.intersections;

      if (layerGroup) {
        this.mapLayerGroupController.remove(layerGroup.id);
      }

      this.fieldsStore.deleteFieldById(field.id);

      if (field.id === this.store.editableField?.id) {
        this.store.clearEditableField();
      }

      this.checkErrors([mainPolygon, ...mapToArray(intersectedPolygons)]);
    } else {
      this.fieldsStore.deleteFieldById(field.id);

      if (field.id === this.store.editableField?.id) {
        this.store.clearEditableField();
      }

      this.checkErrors([]);
    }
  };

  saveImportedFieldList = async (): Promise<EFieldsImportModalName | string[]> => {
    const { selectedOrganizationId } = this.organizationStore;
    const { selectedSeason } = this.seasonsStore;
    const { getToken, deleteToken } = importedFieldsSessionStorageHelpers;

    const token = getToken(selectedOrganizationId, selectedSeason);
    if (!token) {
      return;
    }

    // Показываем лоадер
    this.store.setSaveErrorModal(EFieldsImportModalName.Loader);

    const res = await this.service.saveImportedFieldList(token);

    if (!Array.isArray(res)) {
      this.store.setSaveErrorModal(res);
      return res;
    }

    deleteToken();
    return res;
  };

  changeFieldName = (field: Field, value: string): Field => {
    const foundField = this.fieldsStore.getFieldById(field.id);
    const changedField: Field = { ...foundField, name: value };
    const changedEditableField = { ...this.store.editableField, name: value };

    this.fieldsStore.setField(changedField);
    this.store.setEditableField(changedEditableField);

    const layerGroup = this.getLayerGroupByField(field);
    layerGroup.setTooltipContent(value);

    return changedEditableField;
  };

  changeImportedField = async (fieldProps: TChangeImportedFieldReq): Promise<boolean> => {
    // Пытаемся обновить уже удаленное поле
    if (this.store.deletableFieldIdList.has(fieldProps?.id)) {
      this.store.deletableFieldIdList.delete(fieldProps?.id);

      return Promise.resolve(true);
    }

    this.store.setIsWaitingForEditRes(true);

    const res = await this.service.changeImportedField(fieldProps?.id, fieldProps);

    this.store.setIsWaitingForEditRes(false);

    if (res === EFieldsImportModalName._Success) {
      return true;
    }

    this.store.setSaveErrorModal(res);
  };

  private addFieldsToMap = () => {
    const { listOfImportedField } = this.store;
    const { fieldsList } = this.fieldsStore;

    this.mapCoreController.clear();

    const allFieldsList = [
      ...fieldsList,
      ...listOfImportedField?.map<any>(field => ({ ...field, isImported: true })),
    ];

    const polygonsConfig = allFieldsList.map(field => {
      if (field?.errorType === EImportedFieldErrorType.InvalidGeometry) {
        try {
          return this.getLayerGroupConfig(field);
        } catch (e) {
          return { element: field, layerGroup: null };
        }
      }

      return this.getLayerGroupConfig(field);
    });

    const mapElementsList = this.mapLayerGroupController.displayMany(polygonsConfig);
    this.registerMapEvents();

    this.fieldsStore.attachMapElements(mapElementsList);

    this.layerTooltipController.setContent([EFieldTooltip.Name]);

    this.validateImportedPolygons();

    const importedFieldsList = this.fieldsStore.fieldsList.filter(field => field?.isImported);
    const sortedFieldsList = _.sortBy(importedFieldsList, ['errorType', 'area']);

    const layerGroup = this.getLayerGroupByField(sortedFieldsList[0]);
    this.mapCoreController.centerOnBounds(layerGroup);
  };

  private validatePolygon(polygon: BasePolygon, skipIntersectionIds?: Set<number>) {
    const polygonValidator = new PolygonValidator(polygon);

    return polygonValidator
      .checkIntersections(this.fieldsPolygonsList, skipIntersectionIds)
      .checkSelfIntersection()
      .checkIsAreaTooBig();
  }

  // Сложность O(n2). Надо подумать над тем, как упросить валидацию при инициализации
  private validateImportedPolygons(): void {
    const skipIdsCollection = new Set<number>();
    const touchedList = new Map<number, BasePolygon>();

    this.fieldsStore.fieldsList
      .filter(field => field.isImported)
      .forEach(field => {
        const layerGroup = this.getLayerGroupByField(field);
        const polygon = layerGroup?.getMainPolygon();

        if (!polygon) {
          return;
        }

        const validator = this.validatePolygon(polygon, skipIdsCollection);
        validator.getTouchedList().forEach(p => {
          touchedList.set(p.id, p);
        });

        const hasIntersectionError = validator.errors.has(EPolygonErrorType.Intersection);

        if (!hasIntersectionError) {
          skipIdsCollection.add(polygon.id);
        }
      });

    this.checkErrors(mapToArray(touchedList));
  }

  private checkErrors = touchedList => {
    runInAction(() => {
      touchedList.forEach((polygon: FieldPolygon) => {
        if (polygon.errors.has()) {
          polygon.errors.list.forEach(error => {
            this.fieldsStore.setErrorToFieldById(polygon.fieldId, {
              error: true,
              errorType: error.type as any,
            });
          });
        } else {
          this.fieldsStore.setErrorToFieldById(polygon.fieldId, {
            error: false,
            errorType: null,
          });
        }
      });
    });

    const errors = this.fieldsStore.fieldsList.filter(field => field.isImported && field.error);
    this.store.hasErrors = Boolean(errors.length);
  };
}

export default FieldsImportController;
