import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const IconWrapper = styled('div')({
  minWidth: 20,
  height: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  border: `2px solid ${Colors.generalWhite}`,
});

export default {
  IconWrapper,
};
