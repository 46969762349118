import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 40px;
  border-radius: 12px;
  background-color: ${Colors.generalWhite};
  padding: 8px;
  pointer-events: all;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  max-height: 24px;
  font-size: 18px;
  justify-content: center;
  cursor: pointer;

  width: 24px;
  height: 24px;

  background: none;
  border: none;
  padding: 0;

  & svg > path {
    fill: ${Colors.black};
    transition: 0.3s ease-in-out;
  }

  :hover {
    & svg > path {
      fill: ${Colors.green};
    }
  }
`;

const Styled = {
  Wrapper,
  Button,
};

export default Styled;
