import styled from 'styled-components';

const Overlay = styled('div')({
  display: 'grid',
  gridTemplateRows: '40px auto 40px',
  gridTemplateColumns: '40px auto 40px',
  gap: 8,

  gridTemplateAreas: `
      "TOP      TOP       TOP"
      "LEFT     .         RIGHT"
      "BOTTOM   BOTTOM    BOTTOM"
    `,

  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  padding: '16px',
  zIndex: 10000,
  pointerEvents: 'none',
});

const TopArea = styled('div')({
  gridArea: 'TOP',

  position: 'absolute',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: 8,
});

const LeftArea = styled('div')({
  gridArea: 'LEFT',

  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const RightArea = styled('div')({
  gridArea: 'RIGHT',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 8,
});

const BottomArea = styled('div')({
  gridArea: 'BOTTOM',
});

const Styled = {
  Overlay,
  TopArea,
  LeftArea,
  RightArea,
  BottomArea,
};

export default Styled;
