import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useStore } from '../../../../../shared/utils/IoC';
import { TaskDefaultValuesService } from '../../../tasks/mobx/services';
import { TaskStore } from '../../../tasks/mobx/stores';
import { OperationsStore } from '../../stores/operations.store';
import { useTasksRouteActions } from '../../../tasks/hooks';
import { NO_CULTURE_SELECT_OPTION } from '../../../../../shared/utils/constants/selectOptions';
import { ITaskCreate } from '../../../../../../api/models/as-fields/task/task.model';
import { checkIfDateIsInTheRange } from '../../../../../shared/utils/helpers/checkIfDateIsInTheRange';
import { ETasksContextualReturnBackButtonText } from '../../../tasks/constants';
import { useOperationsParams } from '../useOperationsParams';

const useOperationsTaskActions = () => {
  const taskDefaultValuesService = useStore(TaskDefaultValuesService);

  const taskStore = useStore(TaskStore);
  const operationsStore = useStore(OperationsStore);

  const { goToTaskCreateOne } = useTasksRouteActions();
  const location = useLocation();

  const params = useOperationsParams();

  const handlePresetTask = useCallback(
    (bindingDate?: string) => {
      const cultureId =
        params.cultureId === 'emptyCulture' ? NO_CULTURE_SELECT_OPTION.value : params.cultureId;

      const selectedOperation = operationsStore.getOperation(params.operationId);

      const planStartDate =
        bindingDate ?? taskDefaultValuesService.getPresetPlanStartDate(selectedOperation);

      const data: Partial<ITaskCreate> = {
        cultureId,
        planStartDate,
        planEndDate: null,
      };

      /**
       * Странный кейс. У нас может быть операция, диапазон дат которой не пересекается
       * с предустановленной датой в списке задач.
       */
      if (selectedOperation) {
        if (bindingDate) {
          const { startDate, endDate } = selectedOperation;

          const isInTheRange = checkIfDateIsInTheRange(bindingDate, startDate, endDate);

          if (isInTheRange) {
            data.operationId = selectedOperation.id;
          }
        } else {
          data.operationId = selectedOperation.id;
        }
      }

      if (bindingDate) {
        data.bindingDate = bindingDate;
      }

      taskStore.setTaskCreateData(data);

      goToTaskCreateOne({
        backButtonLabel: ETasksContextualReturnBackButtonText.OperationsListing,
        url: `${location.pathname}${location.search}`,
      });
    },
    [goToTaskCreateOne, params.operationId, params.cultureId, location]
  );

  return {
    presetTask: handlePresetTask,
  };
};

export default useOperationsTaskActions;
