import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { LatLng } from 'leaflet';
import { cloneDeep } from 'lodash';
import { distance } from '@turf/turf';
import { ENotificationType, useNotificator } from '@farmlink/farmik-ui';

import { IMapPolygonError } from '../../../../models';
import { BasePolygon } from '../../../../utils/MapElements';
import { validateCoordinates } from '../../../../../../utils/helpers/validateCoordinates';
import useSelectedVertex from '../useSelectedMarker';

interface IProps {
  handleEditPolygon: (polygon: BasePolygon) => IMapPolygonError[];
}

/**
 * Перенести в geoman
 */
const usePointCoordinatesUpdate = ({ handleEditPolygon }: IProps) => {
  const vertex = useSelectedVertex();
  const notificator = useNotificator();

  const currentPointPosition = vertex?.marker.getLatLng();
  const selectedPolygon = vertex?.polygon ?? null;

  const [isExpanded, setIsExpanded] = useState(false);
  const [newCoords, setNewCoords] = useState('');
  const [error, setError] = useState('');
  const [referenceCords, setReferenceCords] = useState(null);

  const isExpandDisabled = !currentPointPosition;

  const coordinates = useMemo(
    () =>
      currentPointPosition &&
      `${Number(currentPointPosition.lat).toFixed(7)}, ${Number(currentPointPosition.lng).toFixed(
        7
      )}`,
    [currentPointPosition]
  );

  const onChangeCoordinates = (value: string) => {
    setError(null);
    setNewCoords(value);
  };

  const expandChangeCord = useCallback(() => {
    setNewCoords(coordinates);
    setIsExpanded(true);

    if (!referenceCords) {
      setReferenceCords(coordinates);
    }
  }, [coordinates, referenceCords]);

  const shrinkChangeCord = useCallback(() => {
    setIsExpanded(false);

    setNewCoords('');
    setError(null);
    setReferenceCords(null);
  }, [referenceCords]);

  const sendNotification = (message: string) => {
    notificator.setNotification({
      message,
      style: { placement: 'top-right', type: ENotificationType.Warning },
    });
  };

  const updateCord = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!selectedPolygon) {
        return;
      }

      const parsedNewCords = newCoords.split(', ').map(Number);
      const startCords = coordinates.split(', ').map(Number);

      /**
       *  Все валидации нужно убрать из этой части. Валидация должна выполняться внутри контроллеров режимов карты.
       *  При изменении координаты нужно отправлять эвент об изменении геометрии и каждый из режимов выполнит ту валидацию которая в нем прописана
       */
      try {
        validateCoordinates(newCoords);

        const layerCords = selectedPolygon.getLatLngs();

        const distanceBetweenCords = distance(parsedNewCords, startCords, { units: 'kilometers' });

        // Проверка валидности отдалённости
        if (distanceBetweenCords >= 1) {
          throw new Error('Перенос координаты более чем на 1 км');
        }

        const oldLatLng = new LatLng(startCords[0], startCords[1]);
        const newLatLng = new LatLng(parsedNewCords[0], parsedNewCords[1]);

        const previousCords = cloneDeep(layerCords);

        vertex.marker.setLatLng(newLatLng); // подтягивание маркера редактирования к вертексу

        // @ts-ignore
        selectedPolygon.pm.updatePolygonCoordsFromMarkerDrag(vertex.marker);

        // Проверка самопересечения
        if (selectedPolygon.pm.hasSelfIntersection()) {
          selectedPolygon.setLatLngs(previousCords);
          vertex.marker.setLatLng(oldLatLng);

          throw new Error('Пересечение собственного контура');
        }

        // Выполняем обрезку по родителю (актуально для режима редактирования КЗ)
        selectedPolygon.pm.handleKeepInside();

        const errors = handleEditPolygon(selectedPolygon);

        if (errors.length > 0) {
          const firstError = errors[0]?.message;

          throw new Error(firstError);
        }

        setIsExpanded(false);
      } catch (err) {
        sendNotification(err?.message || 'Непредвиденная ошибка валидации');
        setError(err?.message || 'Непредвиденная ошибка валидации');
      }
    },
    [selectedPolygon, coordinates, newCoords]
  );

  useEffect(() => {
    setNewCoords(coordinates);
  }, [coordinates, selectedPolygon]);

  useEffect(() => {
    setError('');
  }, [currentPointPosition]);

  useEffect(() => {
    setIsExpanded(false);
    setNewCoords('');
  }, []);

  return {
    isExpanded,
    newCoords,
    onChangeCoordinates,
    coordinates,
    expandChangeCord,
    shrinkChangeCord,
    updateCord,
    error,
    isExpandDisabled,
  };
};

export default usePointCoordinatesUpdate;
