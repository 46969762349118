import { FC, useMemo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { TCulturesList } from '../../interfaces/CulturesBar.interfaces';

import Styled from './CulturesLine.styles';

interface IProps {
  culturesList: TCulturesList;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const CulturesLine: FC<IProps> = props => {
  const allCount = useMemo(() => {
    return props.culturesList.reduce((acc, curr) => acc + curr.count, 0);
  }, [props.culturesList]);

  const getWidth = (cultureCount: number) => {
    return `${(cultureCount / allCount) * 100}%`;
  };

  return (
    <Styled.Wrapper>
      {props.culturesList.map(culture => (
        <Styled.Part
          key={`cultures-line-part-${culture.name}`}
          $color={culture.color}
          $width={getWidth(culture.count)}
          {...props.getDataTestId(`line-part-${culture.name}-${culture.count}`)}
        />
      ))}
    </Styled.Wrapper>
  );
};

export default CulturesLine;
