import { AxiosError } from 'axios';

import { provide } from '../../../../../../../shared/utils/IoC';

@provide.transient()
class StoErrorService {
  parseStoFetchingErrorToCode = (error: AxiosError) => {
    if (error?.isAxiosError) {
      if (
        error.response.status === 400 &&
        error.response.data.errors[0].title === 'REF_NOT_FOUND'
      ) {
        throw new Error('STO_NOT_FOUND');
      }
    }

    return error;
  };
}

export default StoErrorService;
