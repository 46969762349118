import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TasksParams } from '../../../../../models';
import {
  TaskOperationCreateApiService as ApiService,
  TaskOperationCreateTabsService as TabsService,
  TaskOperationCreateCrudService as CrudService,
  TaskOperationCreateDefaultValuesService as DefaultValuesService,
  TaskOperationCreateValidationsService as ValidationsService,
} from '../../services';
import { TaskOperationCreateStore as Store } from '../../stores';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { OperationField } from '../../../../../../../../../api/models/operations/operation.field.model';

@provide.transient()
class TaskOperationCreateController {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(TabsService)
  protected tabsService: TabsService;

  @lazyInject(ApiService)
  protected apiService: ApiService;

  @lazyInject(CrudService)
  protected crudService: CrudService;

  @lazyInject(DefaultValuesService)
  protected defaultValuesService: DefaultValuesService;

  @lazyInject(ValidationsService)
  protected validationsService: ValidationsService;

  public get pathListWithoutModalWarning() {
    return this.tabsService.pathListWithoutModalWarning;
  }

  public get isOperationSelectBlocked(): boolean {
    return !this.store.taskCreate?.cultureId;
  }

  public get isDateSelectBlocked(): boolean {
    return !this.store.taskCreate?.operationId;
  }

  public get cultureDefaultValue() {
    return this.defaultValuesService.cultureDefaultValue;
  }

  public get operationDefaultValue() {
    return this.defaultValuesService.operationDefaultValue;
  }

  public get dateDefaultValue() {
    return this.defaultValuesService.dateDefaultValue;
  }

  public get assigneeDefaultValue() {
    return this.defaultValuesService.assigneeDefaultValue;
  }

  public get configDefaultValueList() {
    return this.defaultValuesService.configDefaultValueList;
  }

  public get dateRangeOptions() {
    return this.defaultValuesService.dateRangeOptions;
  }

  public get activeCultureName() {
    return this.defaultValuesService.activeCultureName;
  }

  public get activeOperationName() {
    return this.defaultValuesService.activeOperationName;
  }

  public get totalAreaValue() {
    return this.defaultValuesService.totalAreaValue;
  }

  public initiatePage = async (params: TasksParams): Promise<void> => {
    this.tabsService.initiateTabs(params);
    this.crudService.createInitialTaskData();

    await this.apiService.getCultureOptionList();

    this.store.setIsPageLoading(false);
  };

  public getFieldOptionList = async (): Promise<void> => {
    await this.apiService.getFieldOptionList();

    this.store.setFieldOptionListToDisplay(this.store.fieldOptionList);
    this.store.setIsFieldOptionsLoading(false);
  };

  public toggleTabsIsDisableState = (): void => {
    this.tabsService.toggleTabsIsDisableState();
  };

  public changeCulture: CrudService['changeCulture'] = cultureId => {
    this.crudService.changeCulture(cultureId);
  };

  public changeOperation: CrudService['changeOperation'] = operationId => {
    this.crudService.changeOperation(operationId);
  };

  public changeDate: CrudService['changeDate'] = date => {
    this.crudService.changeDate(date);
  };

  public changeField: CrudService['changeField'] = (option: ISelectOption<OperationField>) => {
    this.crudService.changeField(option);
  };

  public changeAllFields: CrudService['changeAllFields'] = (value: boolean) => {
    this.crudService.changeAllFields(value);
  };

  public searchFields: CrudService['searchFields'] = (value: string) => {
    this.crudService.searchFields(value);
  };

  public changeConfig: CrudService['changeConfig'] = optionList => {
    this.crudService.changeConfig(optionList);
  };

  public storeDefaultDate: DefaultValuesService['storeDefaultDate'] = () => {
    this.defaultValuesService.storeDefaultDate();
  };

  public validateCommonInfoForm: ValidationsService['validateCommonInfoForm'] = () => {
    return this.validationsService.validateCommonInfoForm();
  };

  public validateFieldsForm: ValidationsService['validateFieldsForm'] = () => {
    return this.validationsService.validateFieldsForm();
  };

  public createTaskOperation: ApiService['createTaskOperation'] = async actions => {
    await this.apiService.createTaskOperation(actions);
  };

  public clearStore = (): void => {
    this.store.clearIsPageLoading();

    this.store.clearHasChanges();

    this.store.clearTabList();
    this.store.clearTaskCreate();

    this.store.clearSelectedFieldIdList();
    this.store.clearFieldOptionList();
    this.store.clearFieldOptionListToDisplay();

    this.store.clearCultureSelectOptionList();
    this.store.clearOperationSelectOptionList();
    this.store.clearConfigSelectOptionList();

    this.store.clearConfig();
    this.store.clearTaskPropsWithErrors();
  };
}

export default TaskOperationCreateController;
