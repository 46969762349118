import { lazyInject, provide } from '../../../../../utils/IoC';
import { ContextualLinkCoreService as Service } from '../../services';
import { IContextualLink as ILink, IContextualLinkConfig as ILinkConfig } from '../../../models';

@provide.transient()
class ContextualLinkCoreController {
  @lazyInject(Service)
  protected service: Service;

  public addLink = (link: ILink): void => {
    this.service.addLink(link);
  };

  public addStoredLinks = (): void => {
    this.service.addStoredLinks();
  };

  public createLink = (config: ILinkConfig): void => {
    this.service.createLink(config);
  };

  public removeLink = (id: string): void => {
    this.service.removeLink(id);
  };

  public setStateFrom = <S = any>(id: string, state: S | ((prevState: S) => S)): void => {
    this.service.setStateFrom<S>(id, state);
  };
}

export default ContextualLinkCoreController;
