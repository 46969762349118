import { Colors, IconButton } from '@farmlink/farmik-ui';
import { FC, useMemo } from 'react';

import { IndexModel } from '../../../../../../../../../../../../../../api/models/indices.model';

import Styled from './ArrowControls.styles';

const arrowsScheme = {
  default: { background: 'transparent', icon: Colors.generalDark },
  hover: { background: 'transparent', icon: Colors.green },
  disabled: { background: 'transparent', icon: Colors.darkGrey },
};

interface IProps {
  indicesList: IndexModel[];
  selectedIndex: IndexModel;
  onLeftClick: () => void;
  onRightClick: () => void;
}

const ArrowControls: FC<IProps> = props => {
  const extremesPublicId = useMemo<{ min: string; max: string }>(() => {
    const { indicesList } = props;

    if (!indicesList) {
      return { max: null, min: null };
    }

    return {
      min: indicesList[0]?.publicId,
      max: indicesList[indicesList.length - 1]?.publicId,
    };
  }, [props.indicesList]);

  return (
    <Styled.Wrapper>
      <IconButton
        icon="arrow_left"
        colorScheme={arrowsScheme}
        size={20}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          props.onLeftClick();
        }}
        $isDisabled={props.selectedIndex?.publicId === extremesPublicId.max}
      />

      <IconButton
        icon="arrow_right"
        colorScheme={arrowsScheme}
        size={20}
        onClick={() => props.onRightClick()}
        $isDisabled={props.selectedIndex?.publicId === extremesPublicId.min}
      />
    </Styled.Wrapper>
  );
};

export default ArrowControls;
