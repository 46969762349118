import { ReactNode } from 'react';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { TableBuilderStore as Store } from '../../stores';
import {
  ITableBuilderConfig as IBuilderConfig,
  ITableBuilderPaginationConfig as IPaginationConfig,
  ITableBuilderRowConfig as IRowConfig,
} from '../../../models/configs';
import {
  TableBuilderCellsService as CellsService,
  TableBuilderColumnsService as ColumnsService,
  TableBuilderRowsService as RowsService,
} from '../../services';

@provide.transient()
class TableBuilderController<E = any, B = string> {
  @lazyInject(Store)
  protected store: Store<E, B>;

  @lazyInject(ColumnsService)
  protected columnsService: ColumnsService<E, B>;

  @lazyInject(RowsService)
  protected rowsService: RowsService<E, B>;

  @lazyInject(CellsService)
  protected cellsService: CellsService<E, B>;

  initiateTable = (config: IBuilderConfig<E, B>): void => {
    this.columnsService.addColumnList(config.id, config.columnConfigList);
    this.cellsService.addCellList(config.id, config.cellConfigList);

    if (config?.rowConfig) {
      this.rowsService.addPartialRowConfig(config.id, config.rowConfig);
    }

    if (config?.paginationConfig) {
      this.store.setPartialPaginationConfig(config.id, config.paginationConfig);
    }
  };

  getColumnsWidth: ColumnsService<E, B>['getColumnsWidth'] = builderId => {
    const columnWidthList = this.columnsService.getColumnsWidth(builderId);

    return columnWidthList;
  };

  addElementList: RowsService<E, B>['addElementList'] = (
    builderId,
    elementList,
    elementIdKey,
    options
  ) => {
    this.rowsService.addElementList(builderId, elementList, elementIdKey, options);
  };

  addRowClickEvent = (builderId: B, event: IRowConfig<E>['onRowClick']): void => {
    this.rowsService.addPartialRowConfig(builderId, { onRowClick: event });
  };

  addPaginationScrollEvent = (builderId: B, event: IPaginationConfig['onScroll']): void => {
    this.store.setPartialPaginationConfig(builderId, { onScroll: event });
  };

  addCurrentPage = (builderId: B, currentPage: number): void => {
    this.store.setCurrentPage(builderId, currentPage);
  };

  addTotalPages = (builderId: B, totalPages: number): void => {
    this.store.setTotalPages(builderId, totalPages);
  };

  increaseCurrentPage = (builderId: B): void => {
    const currentPage = this.store.getCurrentPage(builderId);

    this.store.setCurrentPage(builderId, currentPage + 1);
  };

  showLoader = (builderId: B): void => {
    this.store.setIsFetchingElements(builderId, true);
  };

  hideLoader = (builderId: B): void => {
    this.store.setIsFetchingElements(builderId, false);
  };

  showDefaultPlug = (builderId: B): void => {
    this.store.setIsShowDefaultPlug(builderId, true);
  };

  hideDefaultPlug = (builderId: B): void => {
    this.store.setIsShowDefaultPlug(builderId, false);
  };

  addPlugConfig = (builderId: B, plugId: string, autoBuildedPlugNode: ReactNode) => {
    this.store.setTablePlugByPlugId(builderId, plugId, autoBuildedPlugNode);
  };

  public highlightRow = (builderId: B, rowId: string): void => {
    this.rowsService.highlightRow(builderId, rowId);
  };

  public removeRowsHighlight = (builderId: B): void => {
    this.rowsService.removeRowsHighlight(builderId);
  };

  public unHighlightRow = (builderId: B, rowId: string): void => {
    this.rowsService.unHighlightRow(builderId, rowId);
  };

  displayPlug = (builderId: B, plugId: string) => {
    this.store.setActivePlugId(builderId, plugId);
  };

  clearStore = (builderId: B): void => {
    this.store.deleteColumnList(builderId);
    this.store.deleteRowList(builderId);
    this.store.deleteCellList(builderId);
    this.store.deleteRowConfig(builderId);
    this.store.deletePaginationConfig(builderId);
    this.store.deleteCurrentPage(builderId);
    this.store.deleteTotalPages(builderId);
    this.store.deleteIsFetchingElements(builderId);
    this.store.deleteIsShowDefaultPlug(builderId);
    this.store.deleteTablePlugConfig(builderId);
    this.store.deleteTableActivePlug(builderId);
  };
}

export default TableBuilderController;
