import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled, { CSSObject } from 'styled-components';

import conditionalObjectProp from '../../../../../../../../../../../shared/utils/conditionalObjectProp';
import { ListCardStyles } from '../../../../../../../../components/ListCard';

import RightBlockStyled from './components/RightBlockWithNewChip/RightBlockWithNewChip.styles';

interface IImageProps {
  imgUrl?: any;
}

const NewListCardStyles: CSSObject = {
  backgroundColor: '#F9FCFF',

  [ListCardStyles.MenuIconWrapper]: {
    display: 'none',
  },

  ':hover': {
    [RightBlockStyled.NewChip]: {
      display: 'none',
    },
    [ListCardStyles.MenuIconWrapper]: {
      display: 'flex',
    },
  },
};

const MapImage = styled('div')<IImageProps>(
  {
    width: 40,
    height: 40,
    borderRadius: ESpaceSize.M,
    flexShrink: 0,
    backgroundColor: Colors.technicalBgGrey,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ({ imgUrl }) => ({
    ...conditionalObjectProp(imgUrl, {
      background: `url(${imgUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: Colors.technicalBgGrey,
      backgroundSize: `${imgUrl?.length ? 'cover' : '40% 40%'}`,
    }),
  })
);

export default {
  MapImage,
  NewListCardStyles,
};
