import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ControlWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Title = styled('h6')({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '20px',
  color: Colors.generalBlack,
  marginBottom: ESpaceSize.S,
});

const Label = styled('span')({
  fontSize: 14,
  fontWeight: 400,
});

export default {
  Wrapper,
  ControlWrapper,
  Title,
  Label,
};
