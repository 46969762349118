import L from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { IRemoveManyOptions } from '../../../models';
import { BaseMarker } from '../../../utils/MapElements';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapMarkerStore from '../stores/MapMarker.store';

@provide.transient()
class MapMarkerService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapMarkerStore)
  private markerStore: MapMarkerStore;

  public create(marker: BaseMarker): BaseMarker | null {
    if (!marker || !this.coreStore.instance) {
      return null;
    }

    marker.addTo(this.coreStore.instance);
    this.markerStore.setMarker(marker);

    return marker;
  }

  /**
   * Вызывается при клике на карту в режиме создания маркеров
   * Добавляет на карту маркер из опций. Если опиций нет, добавляет базовый маркер
   */
  public createMarkerOnClick(event: L.LeafletMouseEvent): BaseMarker | null {
    const markersConfig = this.coreStore.globalConfig?.markersConfig || {};
    const markerFromOptions = markersConfig.getNewMarkerOnClick?.(event.latlng);

    const newMarker = this.create(markerFromOptions ?? new BaseMarker(event.latlng));

    if (newMarker) {
      markersConfig.createMarker?.(newMarker);
    }

    return newMarker;
  }

  public removeById(markerId: number): void {
    const markerToDelete = this.markerStore.getMarker(markerId);
    if (!markerToDelete) {
      return;
    }

    markerToDelete.unbindTooltip();
    markerToDelete.remove();

    this.markerStore.deleteMarker(markerId);
  }

  public removeManyByIds(idList: number[], options?: IRemoveManyOptions): void {
    if (options?.isRemoveAll) {
      this.markerStore.markersList.forEach(({ id }) => this.removeById(id));
      return;
    }

    idList.forEach(id => this.removeById(id));
  }
}

export default MapMarkerService;
