import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';
import { CultureModel } from '../../models/culture.model';

type TypeRequest = {
  attrs: any;
  nameFilter: string;
  limit?: number;
  page?: number;
  fetchAttributes: boolean;
};

type TypeResponse = { content: Array<CultureModel> } & TypeResponsePageable;

export const findAllCultures: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req =>
    `/api/da-dictionary/dictionary/culture/findAll${
      req.page !== null && req.page !== undefined && req.limit !== null && req.limit !== undefined
        ? `?page=${req.page}&limit=${req.limit}`
        : ``
    }`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
