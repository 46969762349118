import { FC, memo } from 'react';

import operationValuesSvg from '../../../../../../../../operations/components/TaskView/assets/operationValuesSvg.svg';
import { StyledTasksDrawerTask as Styled } from '../../styles';
import { useDataTestIdV2 } from '../../../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  taskOperationName: string;
  dataTestId: string;
}
const TaskOperationCreateVerificationOperationName: FC<IProps> = ({
  taskOperationName,
  dataTestId,
}) => {
  const getDataTestId = useDataTestIdV2(`${dataTestId}__operation-name`);

  return (
    <Styled.InfoBlock {...getDataTestId('info-block')}>
      <Styled.Label color={'generalDarkHover'} {...getDataTestId('label')}>
        Операция
      </Styled.Label>

      <Styled.InfoImg {...getDataTestId('info-img')} src={operationValuesSvg} />

      <Styled.Info {...getDataTestId('info')}>{taskOperationName}</Styled.Info>
    </Styled.InfoBlock>
  );
};

TaskOperationCreateVerificationOperationName.displayName =
  'TaskOperationCreateVerificationOperationName';

export default memo(TaskOperationCreateVerificationOperationName);
