import { IGetDictionaryEntityListPayload } from '../../da-dictionary/dictionary/getDictionaryEntityList';
import { IResponseList } from '../../../models/common/response';
import { IDictionaryEntity } from '../../../models/da-dictionary/DictionaryEntity';
import { TypeApiRoute } from '../../../models/type.api.request';

interface IRequest extends IGetDictionaryEntityListPayload {
  taskId: string;
}

type TResponse = IResponseList<IDictionaryEntity>;

export const getStoDictionaryEntityList: TypeApiRoute & {
  request: IRequest;
  response: TResponse;
} = {
  url: ({ remoteName }) => `/api/as-fields/sto/dictionary/${remoteName}/findAllByTask`,
  method: 'POST',
  request: {} as IRequest,
  response: {} as TResponse,
  headers: {},
};
