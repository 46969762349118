import React, { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Typography, useSidebar } from '@farmlink/farmik-ui';
import { Outlet } from 'react-router-dom';

import { useStoNavigation, useStoParams } from '../../../../../../hooks';
import Styled from '../../../../containers/StoContainer/StoContainer.styles';
import { Fullscreen } from '../../../../../../../../../shared/containers';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoActionButton } from '../../../../common';

import { StoOperationCoreController as Controller } from './mobx/controllers';
import { StoOperationCoreStore as Store } from './mobx/stores';

const StoOperation: FC = () => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const params = useStoParams();
  const navigations = useStoNavigation();

  const sidebarActions = useSidebar();

  /**
   * Данный эффект перенаправляет в чек-листы, так как роут выбранной операции пока не используется
   * и лишь выполняет роль контейнера.
   */
  useEffect(() => {
    if (params.stageId && params.attributeId) {
      navigations.navigateToAttribute(params, {
        query: { ...(params.versionId && { versionId: params.versionId }) },
      });

      return;
    }

    navigations.navigateToChecklists(params, {
      query: { ...(params.versionId && { versionId: params.versionId }) },
    });
  }, []);

  useEffect(() => {
    sidebarActions.setInBackground(true);

    controller
      .initiatePage({ selectedStoId: params.stoId, versionId: params.versionId })
      .catch(error => {
        if (error.message === 'STO_NOT_FOUND') navigations.navigateToNotFound();
      });

    return () => {
      controller.clearStore();
    };
  }, []);

  const handleBackButtonClick = useCallback((): void => {
    navigations.navigateToSto(params.stoId, false, {
      query: { ...(params.versionId && { versionId: params.versionId }) },
    });
  }, []);

  return (
    <Fullscreen
      backButtonText={
        <Styled.TitlePaddingWrapper>
          {store.isLoading ? (
            ''
          ) : (
            <Typography className="header-title" variant="h4">
              {controller.getHeaderTitle(params.operationId)}
            </Typography>
          )}
        </Styled.TitlePaddingWrapper>
      }
      arrowText={'Вернуться'}
      underTitleContent={
        store.isLoading ? (
          ''
        ) : (
          <Styled.UnderTitleTypography data-test-id="sto-title-subtitle">
            {controller.getHeaderSubtitle()}
          </Styled.UnderTitleTypography>
        )
      }
      backButtonHandler={handleBackButtonClick}
      contentStyle={{
        $paddings: '4px 0 32px',
      }}
      rightSideContent={<StoActionButton />}
    >
      {store.isLoading ? null : <Outlet />}
    </Fullscreen>
  );
};

StoOperation.displayName = 'StoOperation';

export default observer(StoOperation);
