import styled, { css } from 'styled-components';

import { BorderRadius } from '../../constans/sizes';
import { Colors } from '../../constans/colors';

export const Scroll = styled.div<{ isRight?: boolean; $hasScroll: boolean }>`
  display: flex;
  overflow-x: hidden;
  scroll-width: none;
  scroll-behavior: smooth;
  direction: ${({ isRight }) => (isRight ? 'rtl' : 'ltr')};
  padding: ${({ $hasScroll, isRight }) => ($hasScroll && !isRight ? '0 12px' : '0')};

  & div {
    direction: ltr;
  }

  &::-webkit-scrollbar {
    height: 0;
    display: none;
  }

  &::-moz-scrollbar {
    display: none;
  }

  scrollbar-color: transparent transparent;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
`;

const leftPreset = css`
  margin-left: -32px;
  border-radius: ${BorderRadius.default} 0 0 ${BorderRadius.default};
`;

const rightPreset = css`
  margin-right: -32px;
  border-radius: 0 ${BorderRadius.default} ${BorderRadius.default} 0;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
`;

const displayNone = css`
  display: none;
`;

interface ISwipeBtn {
  $hasScroll: boolean;
  $position: 'left' | 'right';
}

export const StyledSwipeBtn = styled.div<ISwipeBtn>`
  width: 32px;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 2px 0px 4px rgb(0 0 0 / 5%);
  cursor: pointer;
  ${({ $position }) => ($position === 'left' ? leftPreset : rightPreset)}
  ${({ $hasScroll }) => !$hasScroll && displayNone}

  * {
    fill: ${Colors.darkGray};
  }

  &:hover {
    * {
      fill: ${Colors.green};
    }
  }
`;
