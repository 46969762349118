import { TypeApiRoute } from '../../../models/type.api.request';
import { ISto } from '../../../models/as-fields/STO/STO.model';
import { IResponseList } from '../../../models/common/response';

interface IQueryPayload {
  size?: number;
  page?: number;
  sort?: string;
}

interface IBodyPayload {
  organizationIds?: string[];
  cultureIds?: string[];
  status?: string;
  name?: string;
  isDefault?: boolean;
  includeDrafts?: boolean;
  asOnDate?: string;
  seasonYear?: number;
  cultureZones?: string[];
  activeOnly?: boolean;
}

type TRequest = IQueryPayload & IBodyPayload;

type TResponse = IResponseList<ISto>;

export const getStoList: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/sto/list`,
  method: 'POST',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
