import { Layer, LayerOptions } from 'leaflet';

import { IOptimizationOptions, TOptimizationOptions } from '../../../models';

import BaseLayerGroup from './BaseLayerGroup';

interface IOptions extends LayerOptions {
  optimization?: TOptimizationOptions;
}

abstract class OptimizedLayerGroup extends BaseLayerGroup {
  public isClusterable = true;
  /**
   * Пропускает кластеризацию (работает только в случае если isClusterable = true)
   */
  public skipClustering = false;
  public isRenderViewport = true;

  protected constructor(layers: Layer[], options?: IOptions) {
    super(layers, options);

    /**
     * Данная группа и все её наследники могут добавляться только в слой оптимизации
     */
    this.parentLayerKey = 'deflateLayer';

    const { isClusterable, isRenderViewport } = getOptimizationOptions(options?.optimization);
    this.isClusterable = isClusterable;
    this.isRenderViewport = isRenderViewport;
  }
}

export default OptimizedLayerGroup;

function getOptimizationOptions(options: TOptimizationOptions): IOptimizationOptions {
  if (options === undefined) {
    return {
      isClusterable: true,
      isRenderViewport: true,
    };
  }

  if (options === false) {
    return {
      isClusterable: false,
      isRenderViewport: false,
    };
  }

  return {
    isClusterable: options.isClusterable,
    isRenderViewport: options.isRenderViewport,
  };
}
