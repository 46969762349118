import { useCallback } from 'react';
import { generatePath, useLocation, useNavigate, matchPath } from 'react-router-dom';

import useTasksParams from '../useTasksParams/useTasksParams';
import { TasksRoute } from '../../tasks.route';
import { useStore } from '../../../../../shared/utils/IoC';
import { TaskStore } from '../../mobx/stores';
import { ETaskTabs } from '../../models';
import { ETaskStatus } from '../../../../../../api/models/as-fields/task/task.model';
import { useContextualLink } from '../../../../../shared/features/ContextualLink/hooks';
import { EContextualLinkId } from '../../../../constants/configs/ContextualLinkId';
import { TTasksContextualLinkConfig } from '../../types';

interface IDefaultTaskTab {
  tab: ETaskTabs;
  /**
   * Перешёл ли пользователь по чистой ссылке (/tasks/{id})
   *
   * Если переход был осуществлён по ней - должны отрабатывать методы определения нужного таба
   */
  isRedirectFromIndexLink: boolean;
  navigateHandler: (checklistId?: string) => void;
}

type TContextualReturn = TTasksContextualLinkConfig['from'];

const createContextualLinkConfig = (
  contextualReturn: TContextualReturn,
  path: string
): TTasksContextualLinkConfig => {
  return {
    id: EContextualLinkId.TasksTaskBackButton,
    from: contextualReturn,
    to: {
      url: path,
    },
  };
};

const useTasksRouteActions = () => {
  const taskStore = useStore(TaskStore);

  const navigate = useNavigate();
  const params = useTasksParams();
  const location = useLocation();

  const { logLink } = useContextualLink();

  const getTasksPath = useCallback((): string => {
    return generatePath(TasksRoute.Listing, {
      orgId: params.orgId,
    });
  }, [params]);

  const goToTasks = useCallback((): void => {
    navigate(getTasksPath());
  }, [getTasksPath]);

  const getTaskPath = useCallback(
    (taskId?: string): string => {
      return generatePath(TasksRoute.Task, {
        orgId: params.orgId,
        taskId: taskId ?? params?.taskId,
      });
    },
    [params]
  );

  const goToTask = useCallback(
    (taskId?: string, goFrom?: string, contextualReturn?: TContextualReturn): void => {
      const path = getTaskPath(taskId);

      if (!contextualReturn) {
        navigate(path, { state: { from: goFrom } });
        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      logLink(contextualLinkConfig, { state: { from: goFrom } });
    },
    [getTaskPath, logLink]
  );

  const getTaskEditPath = useCallback(
    (taskId?: string): string => {
      return `/${params.orgId}/tasks/${taskId}/edit`;
    },
    [params]
  );

  const goToTaskEdit = useCallback(
    (selectedTaskId?: string, contextualReturn?: TContextualReturn): void => {
      const taskId = selectedTaskId ?? params?.taskId;

      if (!taskId) {
        return;
      }

      const path = getTaskEditPath(taskId);

      if (!contextualReturn) {
        navigate(path);
        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      logLink(contextualLinkConfig);
    },
    [getTaskEditPath, logLink]
  );

  const getTaskCreateOnePath = useCallback((): string => {
    return generatePath(TasksRoute.TaskCreateOne, {
      orgId: params.orgId,
    });
  }, [params]);

  const goToTaskCreateOne = useCallback(
    (contextualReturn?: TContextualReturn): void => {
      const path = getTaskCreateOnePath();

      if (!contextualReturn) {
        navigate(path);
        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      logLink(contextualLinkConfig);
    },
    [getTaskCreateOnePath, logLink]
  );

  const getTaskCreateGroupPath = useCallback((): string => {
    return generatePath(TasksRoute.TaskCreateGroup, {
      orgId: params.orgId,
    });
  }, [params]);

  const goToTaskCreateGroup = useCallback(
    (contextualReturn?: TContextualReturn) => {
      const path = getTaskCreateGroupPath();

      if (!contextualReturn) {
        navigate(path);
        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      logLink(contextualLinkConfig);
    },
    [getTaskCreateGroupPath, logLink]
  );

  const getChecklistPath = useCallback(
    (instanceId: string, taskId?: string): string => {
      return generatePath(TasksRoute.Checklist, {
        orgId: params.orgId,
        taskId: taskId ?? params?.taskId,
        checklistInstanceId: instanceId ?? '',
      });
    },
    [params]
  );

  const goToChecklist = useCallback(
    (instanceId: string, taskId?: string, contextualReturn?: TContextualReturn): void => {
      const path = getChecklistPath(instanceId, taskId);

      if (!contextualReturn) {
        navigate(path);
        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      logLink(contextualLinkConfig);
    },
    [getChecklistPath]
  );

  const getChecklistEditPath = useCallback(
    (instanceId: string, taskId?: string): string => {
      return generatePath(TasksRoute.ChecklistEdit, {
        orgId: params.orgId,
        taskId: taskId ?? params?.taskId,
        checklistInstanceId: instanceId ?? params?.checklistInstanceId,
      });
    },
    [params]
  );

  const goToChecklistEdit = useCallback(
    (instanceId?: string, taskId?: string): void => {
      const path = getChecklistEditPath(instanceId, taskId);

      navigate(path);
    },
    [getChecklistEditPath]
  );

  const getMapPath = useCallback(
    (taskId?: string): string => {
      return generatePath(TasksRoute.TaskMap, {
        orgId: params.orgId,
        taskId: taskId ?? params?.taskId,
      });
    },
    [params]
  );

  const goToMap = useCallback(
    (taskId?: string, contextualReturn?: TContextualReturn): void => {
      const path = getMapPath(taskId);

      if (!contextualReturn) {
        navigate(path);
        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      logLink(contextualLinkConfig);
    },
    [getMapPath]
  );

  const getMapCreatePointPath = useCallback(
    (taskId?: string): string => {
      return generatePath(TasksRoute.TaskMapCreatePoint, {
        orgId: params.orgId,
        taskId: taskId ?? params?.taskId,
      });
    },
    [params]
  );

  /**
   * skipDisplayPointsFromBack используется для перехода между режимом изменения точек на карте и режимом отображения на карте.
   * В будущем стоит провести рефакторинг страницы с табами и избавиться от данной переменной
   */
  const goToMapCreatePoint = useCallback(
    (
      taskId?: string,
      skipDisplayPointsFromBack?: boolean,
      contextualReturn?: TContextualReturn
    ): void => {
      const path = getMapCreatePointPath(taskId);

      if (!contextualReturn) {
        navigate(path, {
          state: { skipDisplayPointsFromBack },
        });

        return;
      }

      const contextualLinkConfig = createContextualLinkConfig(contextualReturn, path);
      logLink(contextualLinkConfig, { state: { skipDisplayPointsFromBack } });
    },
    [getMapCreatePointPath, logLink]
  );

  const goToMapEditPoint = useCallback(
    ({
      orgId,
      taskId,
      skipDisplayPointsFromBack,
    }: {
      orgId: string;
      taskId: string;
      skipDisplayPointsFromBack?: boolean;
    }) => {
      navigate(generatePath(TasksRoute.TaskMapEditPoint, { orgId, taskId }), {
        state: { skipDisplayPointsFromBack },
      });
    },
    [params]
  );

  const goToTaskOperationView = useCallback(
    ({ orgId, taskId }: { orgId: string; taskId: string }) => {
      navigate(generatePath(TasksRoute.TaskOperationView, { orgId, taskId }));
    },
    [params]
  );

  const getDefaultTaskTab = useCallback<() => IDefaultTaskTab>(() => {
    // @ts-ignore
    const isRedirectFromEdit = location.state?.from === 'edit';

    const isRedirectFromIndexLink =
      Boolean(
        matchPath(
          generatePath(TasksRoute.Task, {
            orgId: params.orgId,
            taskId: taskStore?.selectedTask?.id ?? params?.taskId,
          }),
          location.pathname
        )
      ) && !isRedirectFromEdit;

    /**
     * Задача в работе
     */
    if (taskStore?.selectedTask?.status === ETaskStatus.InWork) {
      return { tab: ETaskTabs.Map, navigateHandler: goToMap, isRedirectFromIndexLink };
    }

    /**
     * Задача завершена
     */
    if (
      taskStore?.selectedTask?.status === ETaskStatus.Completed &&
      (taskStore?.selectedTask?.checklistsAvailable ||
        taskStore?.selectedTask?.checklistsMachineryAvailable ||
        taskStore?.selectedTask?.checklistsFieldAvailable)
    ) {
      return {
        tab: ETaskTabs.Checklists,
        navigateHandler: checklistId =>
          goToChecklist(checklistId || params?.checklistInstanceId, params?.taskId),
        isRedirectFromIndexLink,
      };
    }

    return { tab: ETaskTabs.Summary, navigateHandler: goToTask, isRedirectFromIndexLink, params };
  }, [params, taskStore?.selectedTask, location.pathname]);

  return {
    getTasksPath,
    getTaskPath,
    getTaskEditPath,
    getTaskCreateOnePath,
    getTaskCreateGroupPath,
    getChecklistPath,
    getChecklistEditPath,
    getMapPath,
    getMapCreatePointPath,
    goToTasks,
    goToTask,
    goToTaskEdit,
    goToTaskCreateOne,
    goToTaskCreateGroup,
    goToChecklist,
    goToChecklistEdit,
    goToMap,
    goToMapCreatePoint,
    goToMapEditPoint,
    getDefaultTaskTab,
    goToTaskOperationView
  };
};

export default useTasksRouteActions;
