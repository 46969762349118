import { FC } from 'react';

import { ForecastType } from '../../index';
import WeatherEngToRus from '../../../../utils/helpers/WeatherEngToRus.helper';
import { WindDirectionEngToRus } from '../../../../utils/helpers/WindDirectionEngToRus.helper';
import { WeatherConditionToIcon } from '../../../../utils/helpers/WeatherConditionToIcon.helper';

import {
  Wrapper,
  Header,
  Condition,
  ConditionIcon,
  DescriptionLine,
  Temperature,
  TemperatureAndConditionWrapper,
  Time,
} from './style';

export const ForecastDayPart: FC<ForecastType> = ({
  windGust,
  windDirection,
  pressureMm,
  humidity,
  temperature,
  condition,
  hours,
  windSpeed,
  precType,
}) => {
  const precTypeMap = (id: number) => {
    return ['без осадков', 'дождь', 'дождь со снегом', 'снег', 'град'][Number(id)];
  };

  return (
    <Wrapper data-test-id="forecast-day-component">
      <Header data-test-id="forecast-day-component-header">
        <Time data-test-id="forecast-day-component-header-time">{hours}</Time>
      </Header>
      <TemperatureAndConditionWrapper data-test-id="forecast-day-component-temp-cond-wrapper">
        <ConditionIcon
          src={WeatherConditionToIcon(condition, parseInt(hours, 10))}
          alt=""
          data-test-id="forecast-day-component-cond-icon"
        />
        {/* <img src={WeatherConditionToIcon(condition)} alt="" /> */}
        <Temperature data-test-id="forecast-day-component-temp-icon">{temperature}°</Temperature>
        <Condition data-test-id="forecast-day-component-cond">
          {WeatherEngToRus(condition)}
        </Condition>
      </TemperatureAndConditionWrapper>
      <DescriptionLine data-test-id="forecast-day-component-prop-container">
        <p data-test-id="forecast-day-component-prop-humidity">
          Влажность <span>{humidity} %</span>
        </p>
      </DescriptionLine>
      <DescriptionLine data-test-id="forecast-day-component-prop-container">
        <p data-test-id="forecast-day-component-prop-precType">
          Осадки <span>{precTypeMap(Number(precType))}</span>
        </p>
      </DescriptionLine>
      <DescriptionLine data-test-id="forecast-day-component-prop-container">
        <p data-test-id="forecast-day-component-prop-pressureMm">
          Давление <span>{pressureMm} мм рт.ст.</span>
        </p>
      </DescriptionLine>
      <DescriptionLine data-test-id="forecast-day-component-prop-container">
        <p data-test-id="forecast-day-component-prop-windSpeed">
          Скорость ветра <span>{windSpeed} м/с</span>
        </p>
      </DescriptionLine>
      <DescriptionLine data-test-id="forecast-day-component-prop-container">
        <p data-test-id="forecast-day-component-prop-windDirection">
          Направление ветра <span>{WindDirectionEngToRus(windDirection)}</span>
        </p>
      </DescriptionLine>
      <DescriptionLine data-test-id="forecast-day-component-prop-container">
        <p data-test-id="forecast-day-component-prop-windGust">
          Порывы ветра <span>{windGust} м/с</span>
        </p>
      </DescriptionLine>
    </Wrapper>
  );
};
