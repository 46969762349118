import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { ButtonLink, Typography, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../../../../../mobx/stores';
import { useStoParams } from '../../../../../../../../../../../../../../../../../../hooks';
import { StoStore } from '../../../../../../../../../../../../../../../../mobx';
import { IStoAttribute as IAttribute } from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { ISelectOption } from '../../../../../../../../../../../../../../../../../../../../../../types/selectOption';
import { StoAttributeCoreController as Controller } from '../../../../../../mobx/controllers';
import { StoAttributeOdzModals } from '../../modals';

import Styled from './StoAttributeOdzDependency.styles';
import { StoAttributeOdzDepSelect } from './components';

const StoAttributeOdzDependency: FC = () => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);

  const controller = useStore(Controller);

  const params = useStoParams();
  const modalActions = useModal();

  useEffect(() => {
    return () => {
      store.clearHasNoSelectedDependency();
      store.clearDependencySelectList();
    };
  }, []);

  const availableOptionList = useMemo<ISelectOption<IAttribute>[]>(() => {
    return store.dependencyOptionList.filter(
      o => !store.dependencySelectList.some(s => o.value === s.attr?.id)
    );
  }, [store.dependencySelectList, store.dependencyOptionList]);

  const title =
    store.dependencySelectList.length > 1
      ? 'Выберите определяющие параметры, от показателей которых будет зависеть область допустимых значений:'
      : 'Выберите определяющий параметр, от показателей которого будет зависеть область допустимых значений:';

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const handleButtonAddMoreClick = useCallback((): void => {
    if (controller.checkIfOdzGroupsIsEmpty(params)) {
      controller.addDependencySelect(params);
      return;
    }

    modalActions.openModalByModalId(
      StoAttributeOdzModals.warningBeforeDependencyChange.id,
      null,
      () => {
        controller.addDependencySelect(params);
      }
    );
  }, []);

  const getDataTestId = useDataTestIdV2('sto__attribute__odz__dependency');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Header {...getDataTestId('header')}>
        <Typography dataTestId={getDataTestId('title')['data-test-id']} variant={'bodyAccent'}>
          {title}
        </Typography>
      </Styled.Header>

      <Styled.Body {...getDataTestId('body')}>
        <Styled.Dependencies
          {...getDataTestId('dependencies')}
          $quantity={store.dependencySelectList.length}
        >
          {store.dependencySelectList.map(select => (
            <StoAttributeOdzDepSelect
              key={select.id}
              id={select.id}
              dataTestId={getDataTestId('select')['data-test-id']}
              defaultValue={store.dependencyOptionList.find(o => o.value === select.attr?.id)}
              optionList={availableOptionList}
              isBlocked={checkIfIsBlockedByPermissions()}
              isShowButtonRemove={store.dependencySelectList.length > 1}
            />
          ))}
        </Styled.Dependencies>

        {store.dependencySelectList.length > 1 ? null : (
          <ButtonLink
            dataTestId={getDataTestId('button-add-more')['data-test-id']}
            color={'accent'}
            onClick={handleButtonAddMoreClick}
          >
            + Добавить ещё
          </ButtonLink>
        )}
      </Styled.Body>
    </Styled.Wrapper>
  );
};

StoAttributeOdzDependency.displayName = 'StoAttributeOdzDependency';

export default observer(StoAttributeOdzDependency);
