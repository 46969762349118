import L, { gridLayer, LatLngBounds, LatLngLiteral, LocateOptions } from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { IMapGlobalConfig as IGlobalConfig } from '../../../models';
import { DefaultMapOptions } from '../../../utils/constants';
import { clusterIcon } from '../../../utils/MapElements';
import MapCoreStore from '../stores/MapCore.store';

// Подключение плагинов
import '@farmlink/gefim';
import '@farmlink/gefim/dist/leaflet-geoman.css';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import '../../../utils/plugins/PolygonFillPattern.plugin';
import '../../../utils/plugins/Deflate.plugin';
import '../../../utils/plugins/TooltipCollision.plugin';

/**
 *  Данный клас содержит методы по работе с инстансом карты
 */
@provide.transient()
class MapCoreService {
  @lazyInject(MapCoreStore)
  private store: MapCoreStore;

  public initMap(globalConfig: IGlobalConfig): L.Map | null {
    const { mapKey, leafletOptions } = globalConfig;

    const instance = L.map(mapKey, {
      ...DefaultMapOptions,
      ...leafletOptions,
    });

    if (!instance) {
      return null;
    }

    // Слой отвечающий за оптимизацию/кластеризацию полигонов
    const deflateLayer = globalConfig?.deflateLayer ?? this.getBaseDeflateLayer();

    this.store.globalConfig = globalConfig;
    this.store.deflateLayer = deflateLayer;

    this.store.instance = instance;

    // @ts-ignore
    gridLayer.googleMutant({ type: 'hybrid' }).addTo(instance);
    deflateLayer.addTo(instance);

    instance.attributionControl.setPrefix('');

    return instance;
  }

  public removeInstance() {
    this.store.instance?.off();
    this.store.instance?.remove();
    this.store.clearInstance();
    this.store.clearGlobalConfig();
  }

  public centerMapOnBounds(
    layer: { getBounds: () => LatLngBounds },
    options?: L.FitBoundsOptions
  ): void {
    if (typeof layer?.getBounds !== 'function') {
      return;
    }

    const bounds = layer.getBounds();
    const padBounds = bounds?.pad(0.1);

    if (padBounds) {
      this.store.instance?.fitBounds(padBounds, { animate: true, ...options });
    }
  }

  public centerMapOnLatLng({ lat, lng }: LatLngLiteral, zoom?: number) {
    const coords = new L.LatLng(lat, lng);
    this.store.instance.flyTo(coords, zoom, { animate: false });
  }

  public zoom(type: 'in' | 'out'): void {
    if (type === 'in') {
      this.store.instance?.zoomIn();
      return;
    }

    this.store.instance?.zoomOut();
  }

  public locate(options?: LocateOptions): void {
    this.store.instance?.locate(options);
  }

  private getBaseDeflateLayer = () => {
    return L.deflate({
      minSize: 60,
      // @ts-ignore
      markerLayer: L.markerClusterGroup({
        removeOutsideVisibleBounds: false,
        singleMarkerMode: true,
        animate: false,
        maxClusterRadius: 110,
        iconCreateFunction: cluster => {
          return clusterIcon(cluster.getChildCount());
        },
      }),
    });
  };
}

export default MapCoreService;
