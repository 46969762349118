import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { MapDrawerStore } from '../../../../../../../shared/features/map/modules';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { FieldCulturesStore as CulturesStore } from '../../mobx';

import FieldSeasonsEditCultureZone from './components/EditCultureZone/EditCultureZone';
import FieldSeasonsList from './components/SeasonsList/SeasonsList';

const FieldSeasonsLeftPanel: FC = () => {
  // Сторы
  const culturesStore = useStore(CulturesStore);
  const mapDrawerStore = useStore(MapDrawerStore);

  if (culturesStore.editableCultureZone || mapDrawerStore.isDrawEnabled) {
    return <FieldSeasonsEditCultureZone />;
  }

  return <FieldSeasonsList />;
};

export default observer(FieldSeasonsLeftPanel);
