import { useState } from 'react';

import { usePermissionsBuilderPermissions as usePermissionsBuilder } from '../../../../shared/features/PermissionsBuilder';
import { CheckAccessStore } from '../../../../authorization/stores/checkAccess.store';
import { EFieldAccessAction } from '../../../../shared/constants/access-rules-action';
import { useStore } from '../../../../shared/utils/IoC';

const allPermissionsList = [
  EFieldAccessAction.DeleteField,
  EFieldAccessAction.EditField,
  EFieldAccessAction.DeleteSeason,
  EFieldAccessAction.EditSeason,
  EFieldAccessAction.CreateFieldOrSeason,
  EFieldAccessAction.EditCultureZone,
];

function useFieldsPermissions() {
  // Сторы
  const checkAccessStore = useStore(CheckAccessStore);

  const [isLoading, setIsLoading] = useState(true);

  const permissions = usePermissionsBuilder<EFieldAccessAction, 'fields'>('fields');

  /**
   * Обновляет список всех прав для переданной организации
   */
  const update = (orgId: string) => {
    setIsLoading(true);

    if (orgId === 'my') {
      permissions.addPermissionList(allPermissionsList, null, { isClearPreviousList: true });
      setIsLoading(false);

      return;
    }

    checkAccessStore
      .getAccessRulesByCodePrefix(orgId, 'field')
      .then(res => {
        const codesList = res
          .filter(item => item.available)
          .map(item => item.code as EFieldAccessAction);

        permissions.addPermissionList(codesList, null, { isClearPreviousList: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    hasAccess: permissions.getPermission,
    update,
  };
}

export default useFieldsPermissions;
