import { FC } from 'react';

import { OperationField } from '../../../../../../api/models/operations/operation.field.model';
import { OperationFieldsListItem } from '../OperationFieldsListItem';
import { useOperationsParams } from '../../hooks';

import { FieldsList } from './style';

type Props = {
  fields: Array<OperationField>;
};

export const OperationFieldsList: FC<Props> = ({ fields }) => {
  const params = useOperationsParams();

  return (
    <FieldsList data-test-id={'operation-item-fields-list'}>
      {fields.map(field => (
        <OperationFieldsListItem field={field} isActive={field.fieldId === params.fieldId} />
      ))}
    </FieldsList>
  );
};
