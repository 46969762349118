import { useMemo } from 'react';

import { Field } from '../../../../../api/models/field.model';
import { BasePolygon } from '../../../../shared/features/map/utils/MapElements';

import { useFieldMapPolygon } from './index';

function useFieldPolygonErrors(fieldOrPolygon: Field | BasePolygon) {
  const polygon = useFieldMapPolygon(fieldOrPolygon);

  return useMemo(() => {
    return polygon?.errors.list ?? [];
  }, [polygon?.errors.list]);
}

export default useFieldPolygonErrors;
