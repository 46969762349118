import { FC } from 'react';

import { ConfigurableSlider } from '../../../../../../../operations/components/ConfigurableSlider';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './CardList.styles';

const CardList: FC = ({ children }) => {
  const getDataTestId = useDataTestIdV2('checklists__points-cards');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <ConfigurableSlider dataTestId={getDataTestId()['data-test-id']}>
        {children}
      </ConfigurableSlider>
    </Styled.Wrapper>
  );
};

export default CardList;
