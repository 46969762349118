import styled, { css, keyframes } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import { FontSizes, BorderRadius } from '../../../shared/constans/sizes';
import { ESidebarWidth } from '../../stores/ui.store';

const showWrapper = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${Colors.secondaryGray};
  display: grid;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1006;
  /* animation: ${showWrapper} 0.3s ease-in; */
  grid-template-columns: minmax(1px, 1fr);
  grid-template-rows: auto minmax(1px, 1fr);
`;

export const StyledFullscreenWrapper = styled.div<{ $isShort: boolean }>`
  /* min-width: ${({ $isShort }) =>
    $isShort ? `calc(100% - ${ESidebarWidth.SHORT})` : `calc(100% - ${ESidebarWidth.LARGE})`};
  max-width: ${({ $isShort }) =>
    $isShort ? `calc(100% - ${ESidebarWidth.SHORT})` : `calc(100% - ${ESidebarWidth.LARGE})`}; */
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  background: ${Colors.secondaryGray};
  display: grid;
  flex-direction: column;
  /* left: ${({ $isShort }) => ($isShort ? ESidebarWidth.SHORT : ESidebarWidth.LARGE)}; */
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  grid-template-columns: minmax(1px, 1fr);
  grid-template-rows: auto minmax(1px, 1fr);
  transition: 1s;
  &.header_hidden {
    transform: translateY(-72px);
    height: calc(100vh + 72px);
    padding-bottom: 96px;
  }
`;

export const Footer = styled.div`
  padding: 20px 0 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > div {
    width: 100%;
  }

  margin: 0 24px;
  scrollbar-gutter: stable;
`;

export const StyledFullscreenFooter = styled.div`
  padding: 20px 0 32px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > div {
    width: 100%;
  }

  margin: 0 24px;
  scrollbar-gutter: stable;
`;

export const OperationsListWrapper = styled.div`
  width: 400px;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  transition: 1s;
  &.header_hidden {
    transform: translateY(-100%);
  }
  width: 100%;
  min-height: 72px;
  padding: 10px 24px 16px 24px;
  border-bottom: 0.5px solid ${Colors.borderGray};
  background: ${Colors.generalWhite};
  z-index: 10002;
`;

export const FullScreenHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 72px;
  padding: 14px 24px;
  border-bottom: 0.5px solid ${Colors.borderGray};
  background: ${Colors.generalWhite};
  z-index: 10002;
`;

export const LabelWrapper = styled.div<{ $isNeedMarginBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ $isNeedMarginBottom }) =>
    $isNeedMarginBottom &&
    css`
      margin-bottom: 16px;
    `}
`;
export const Label = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  color: #151f32;
`;

export const StyledHeaderGroup = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
