import { lazyInject, provide } from '../../../../../../../../../../shared/utils/IoC';
import { StoStore } from '../../../../../mobx';
import { StoOperationsCoreStore } from '../../stores';

@provide.transient()
class StoOperationsChangeService {
  @lazyInject(StoStore)
  protected stoStore: StoStore;

  @lazyInject(StoOperationsCoreStore)
  protected stoOperationsCoreStore: StoOperationsCoreStore;

  public toggleOperationSelection = (id: string, value: boolean): void => {
    const { setItemList } = this.stoOperationsCoreStore;

    setItemList(prevValue => {
      return prevValue.map(item => {
        if (item.techOperationTypeId === id) {
          return { ...item, isSelected: value };
        }

        return item;
      });
    });

    if (value) this.selectOperation(id);
    else this.removeOperation(id);
  };

  protected selectOperation = (id: string): void => {
    const { setUpdatedSto, setHasChanges } = this.stoStore;

    setUpdatedSto(prevValue => ({
      ...prevValue,
      stoOperations: [
        ...(prevValue.stoOperations ?? []),
        {
          techOperationTypeId: id,
        },
      ],
    }));

    setHasChanges(true);
  };

  protected removeOperation = (id: string): void => {
    const { setUpdatedSto, setHasChanges } = this.stoStore;

    setUpdatedSto(prevValue => ({
      ...prevValue,
      stoOperations: [...(prevValue.stoOperations ?? [])].filter(o => o.techOperationTypeId !== id),
    }));

    setHasChanges(true);
  };
}

export default StoOperationsChangeService;
