import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $isWithoutTextAlign?: boolean }>`
  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  /* bodySubAccent: semi14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.15px;

  padding: 12px 0;

  ${({ $isWithoutTextAlign }) =>
    !$isWithoutTextAlign &&
    css`
      text-align: center;
    `};
`;

const StyledStoChecklistsColumn = {
  Wrapper,
};

export default StyledStoChecklistsColumn;
