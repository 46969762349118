import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useModal } from '@farmlink/farmik-ui';

import { useTaskEditBackButtonData, useTasksParams, useTasksRouteActions } from '../../hooks';
import TaskFullscreen from '../../components/TaskFullscreen/TaskFullscreen';
import { LEAVE_BEFORE_SAVE_MODAL_ID } from '../../modals/leaveBeforeSaveModal';
import { useStore } from '../../../../../shared/utils/IoC';
import { TaskStore } from '../../mobx/stores';
import { IFullscreenProps } from '../../containers/fullscreen';
import { ETaskTabs } from '../../models';
import InstanceListActualityService from '../../../operationsAndTasks/services/instanceList/instanceListActuality.service';
import { InspectionPointsStore } from '../../components/InspectionPoints/mobx/stores';
import { ChecklistInstancesStore } from '../../../operationsAndTasks/stores/checklist.instances.store';
import { ChecklistsStore } from '../Checklists/mobx/stores';

const TaskEditPage: FC = () => {
  const params = useTasksParams();

  const { goToTask, goToMap, goToChecklist } = useTasksRouteActions();
  const { openModalByModalId } = useModal();

  const checklistsStore = useStore(ChecklistsStore);
  const { isTaskChanged, setMode, taskTab } = useStore(TaskStore);
  const { readyToRemoveInstanceIdList } = useStore(InstanceListActualityService);
  const { isInspectionPointsChanged, setIsInspectionPointsChanged } = useStore(
    InspectionPointsStore
  );
  const { isFormChanged } = useStore(ChecklistInstancesStore);

  const backButtonData = useTaskEditBackButtonData();

  const isDisplayModalBeforeLeave = useMemo(() => {
    if (
      isTaskChanged ||
      readyToRemoveInstanceIdList?.length ||
      isInspectionPointsChanged ||
      checklistsStore.lastEditedAttrId ||
      isFormChanged
    ) {
      return true;
    }

    return false;
  }, [
    isTaskChanged,
    readyToRemoveInstanceIdList,
    isInspectionPointsChanged,
    isFormChanged,
    checklistsStore.lastEditedAttrId,
  ]);

  useEffect(() => {
    setMode('edit');
  }, []);

  const goBackHandler = useCallback(() => {
    setMode('view');
    setIsInspectionPointsChanged(false);

    if (taskTab === ETaskTabs.Map) {
      goToMap();
      return;
    }

    if (taskTab === ETaskTabs.Checklists) {
      goToChecklist(params.checklistInstanceId, params.taskId);
      return;
    }

    goToTask(params.taskId, 'edit');
  }, [taskTab, params.taskId]);

  const taskFullScreenConfig = useMemo<IFullscreenProps>(
    () => ({
      backButtonHandler: () => {
        if (isDisplayModalBeforeLeave) {
          const handleCancel = (): void => {
            if (taskTab !== ETaskTabs.Checklists) return;

            const attrWithErrorNode = document.querySelector(
              `[id='${checklistsStore.lastEditedAttrId}']`
            );

            if (!attrWithErrorNode) return;

            attrWithErrorNode.scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'center',
            });
          };

          openModalByModalId(LEAVE_BEFORE_SAVE_MODAL_ID, null, goBackHandler, handleCancel);
        } else {
          goBackHandler();
        }
      },
      backButtonText: backButtonData.backButtonText,
      arrowText: 'К просмотру задачи',
      mode: 'edit',
    }),
    [params.taskId, taskTab, goBackHandler, isDisplayModalBeforeLeave]
  );

  return <TaskFullscreen fullscreenProps={taskFullScreenConfig} mode={'edit'} />;
};

TaskEditPage.displayName = 'TaskEditPage';

export default observer(TaskEditPage);
