import { FC, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { generatePath, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/order
import TechIcon from '@farmlink/farmik-ui/src/Icon/icons/assets/technology.svg';
import AuditIcon from '@farmlink/farmik-ui/src/Icon/icons/assets/mapNavigation.svg';
import DocsIcon from '@farmlink/farmik-ui/src/Icon/icons/assets/docs.svg';
// eslint-disable-next-line import/order
import {
  Sidebar as FarmikSidebar,
  ISidebarProps,
  useSidebar,
  MenuItemContactIcon,
  MenuItemContact,
  Icon,
  ISidebarMenuItem,
} from '@farmlink/farmik-ui';
// eslint-disable-next-line import/order
import { LocationDescriptor } from 'history';

import { DashboardRoutes, EDashboardExternalRoutes } from '../../dashboard.routes';
import { useStore } from '../../../shared/utils/IoC';
import { OrganizationsStore } from '../../stores/organizations.store';
import { LeaveUnitModalStore } from '../../stores/leave.unit.modal.store';
import {
  EStoAccessAction,
  SCOUTING_ACCESS_ACTIONS,
} from '../../../shared/constants/access-rules-action';
import { GeneralDataStore } from '../../stores/general.data.store';
import { AppVersion } from '../../../../constants/version';
import { editSeasonModalConfig } from '../../modules/seasons/modals/EditSeasonModal/EditSeasonModal.config';
import { createSeasonModalConfig } from '../../modules/seasons/modals/CreateSeasonModal/CreateSeasonModal.config';

import { SidebarController } from './sidebar.controller';
import { OrganizationsAccordeon } from './components/OrganizationsAccordeon/OrganizationsAccordeon';
import { SeasonsAccordeon } from './components';
import { unsavedResultModal } from './modals/unsavedResultModal';
import { MenuIcon } from './style';

type SidebarProps = {
  isAdaptive?: boolean;
};

const ACTION_LIST_TO_DISPLAY_EXTERNAL = [
  'experiment.workWithExperiment',
  'experiments.addOperations',
  'experiments.editContracts',
  'experiments.editExperiments',
  'experiments.includeCultureZones',
  'experiments.viewAllContracts',
  'experiments.viewAllExperiments',
];

export const Sidebar: FC<SidebarProps> = observer(() => {
  const navigate = useNavigate();
  const {
    clearStatesOnMenuItemClick,
    getUsername,
    onProfileClick,
    onLogoutClick,
    getUserPic,
    setActiveDropdown,
    accessRules: sidebarActions,
    isSidebarDisabled,
    clearStateOnModalClick,
    isShowLeaveUnitModal,
    getUserExperimentAccess,
  } = useStore(SidebarController);
  const { selectedOrganizationId, currentOrganization } = useStore(OrganizationsStore);
  const { selectMenuItem, setAccessRules, setSidebarDisabled, registerUntilModals } = useSidebar();

  const { debouncedDataLoading } = useStore(GeneralDataStore);
  const leaveUnitModal = useStore(LeaveUnitModalStore);

  const globalUserActionList = [];

  const userExperimentAccess = useMemo(() => {
    return getUserExperimentAccess();
  }, [selectedOrganizationId]);

  const onSidebarMenuItemClick = (onMenuClick: () => void) => {
    if (isShowLeaveUnitModal) {
      leaveUnitModal.setDialogSettings({
        actionHandler: () => {
          clearStateOnModalClick();
          onMenuClick();
          leaveUnitModal.setIsShow(false);
        },
        isShow: true,
      });
    } else {
      onMenuClick();
    }
  };

  useEffect(() => {
    registerUntilModals([unsavedResultModal, editSeasonModalConfig, createSeasonModalConfig]);
  }, []);

  useEffect(() => {
    setAccessRules('user', sidebarActions);
  }, [sidebarActions]);

  useEffect(() => {
    setSidebarDisabled(isSidebarDisabled);
  }, [isSidebarDisabled]);

  useEffect(() => {
    selectMenuItem(window.location.pathname.split('/')[2]);
  }, [window.location.pathname]);

  const handleSidebarMenuItemClick = (
    itemLink: LocationDescriptor,
    menuId: string,
    forceCallback?: boolean
  ) => {
    if (!forceCallback && menuId === window.location.pathname.split('/')[2]) {
      return;
    }

    onSidebarMenuItemClick(() => {
      clearStatesOnMenuItemClick();
      selectMenuItem(menuId);
      navigate(itemLink);
    });
  };

  const isOrgMy = selectedOrganizationId === 'my';

  const fieldsIcon = useCallback(() => <Icon icon="sidebarFields" size="sidebar" />, []);
  const operationsIcon = useCallback(() => <Icon icon="sidebarOperation" size="sidebar" />, []);
  const tasksIcon = useCallback(() => <Icon icon="sidebarTasks" size="sidebar" />, []);
  const stoIcon = useCallback(() => <Icon icon="sidebarSto" size="sidebar" />, []);

  const isHideSto = useMemo(() => isOrgMy || Boolean(currentOrganization?.isSTOEnabled) === false, [
    currentOrganization?.isSTOEnabled,
  ]);

  const handleActiveItemId = () => {
    return window.location.pathname.split('/')[2];
  };

  const externalProductRedirectLink = useMemo(() => {
    const currentStage = window._env_.CURRENT_STAGE;

    const expBaseUrl = window._env_[`EXPERIMENTS_${currentStage}_URL`] ?? 'http://localhost:3000/';

    return {
      exp: new URL(EDashboardExternalRoutes.Experiments, expBaseUrl).href,
      audit: new URL(EDashboardExternalRoutes.Audits, expBaseUrl).href,
      docs: new URL(EDashboardExternalRoutes.Contracts, expBaseUrl).href,
    };
  }, []);

  const initialConfig: ISidebarProps = {
    accessRules: sidebarActions,
    globalAccessRules: globalUserActionList,
    activeItemId: handleActiveItemId,
    dataIsLoading: debouncedDataLoading,

    headerConfig: {
      logoTitleText: AppVersion,
      onHeaderClick: () => {
        handleSidebarMenuItemClick(
          generatePath(DashboardRoutes.Fields, {
            orgId: selectedOrganizationId,
          }),
          'fields',
          true
        );
      },
      onSidebarShrink() {
        setActiveDropdown(null);
      },
    },

    bodyConfig: {
      bodyTop: [
        {
          id: 'organizations',
          type: 'custom',
          customElement: OrganizationsAccordeon,
          alias: 'organizations',
          customDataTestId: 'organizations-selector',
          styles: {
            expanded: {
              padding: '0px 16px 6px',
            },
            shrink: {
              padding: '0',
            },
          },
        },
        {
          id: 'seasons',
          type: 'custom',
          customElement: SeasonsAccordeon,
          alias: 'seasons',
          customDataTestId: 'seasons-selector',
          styles: {
            expanded: {
              padding: '2px 16px 8px',
            },
            shrink: {
              padding: '0',
            },
          },
        },
        {
          iconElement: fieldsIcon,
          title: 'Поля',
          type: 'navigation',
          id: 'fields',
          alias: 'field',
          href: selectedOrganizationId,
          tooltip: {
            short: {
              content: 'Поля',
              position: 'right',
            },
          },
          onSidebarMenuItemClick() {
            handleSidebarMenuItemClick(
              generatePath(DashboardRoutes.Fields, {
                orgId: selectedOrganizationId,
              }),
              'fields',
              true
            );
          },
        },
        {
          iconElement: stoIcon,
          title: 'СТО',
          type: 'navigation',
          accessRules: isHideSto
            ? { oneOf: ['HIDDEN_FROM_USER'] }
            : {
                oneOf: [EStoAccessAction.View],
              },
          alias: 'sto',

          href: selectedOrganizationId,
          tooltip: {
            short: {
              content: 'СТО',
              position: 'right',
            },
          },
          onSidebarMenuItemClick() {
            handleSidebarMenuItemClick(
              generatePath(DashboardRoutes.Sto, {
                orgId: selectedOrganizationId,
              }),
              'sto'
            );
          },
          id: 'sto',
        },
        {
          iconElement: operationsIcon,
          title: 'Операции',
          type: 'navigation',
          accessRules: isOrgMy ? null : { oneOf: [SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS] },
          alias: 'operation',
          href: selectedOrganizationId,
          tooltip: {
            short: {
              content: 'Операции',
              position: 'right',
            },
          },
          onSidebarMenuItemClick() {
            handleSidebarMenuItemClick(
              generatePath(DashboardRoutes.Operations, {
                orgId: selectedOrganizationId,
              }),
              'operations'
            );
          },
          id: 'operations',
        },
        {
          iconElement: tasksIcon,
          title: 'Задачи',
          type: 'navigation',
          accessRules: isOrgMy
            ? null
            : {
                oneOf: [
                  SCOUTING_ACCESS_ACTIONS.WORK_WITH_TASKS,
                  SCOUTING_ACCESS_ACTIONS.MANAGE_TASKS,
                ],
              },
          alias: 'task',
          href: selectedOrganizationId,
          tooltip: {
            short: {
              content: 'Задачи',
              position: 'right',
            },
          },
          onSidebarMenuItemClick() {
            handleSidebarMenuItemClick(
              generatePath(DashboardRoutes.Tasks, {
                orgId: selectedOrganizationId,
              }),
              'tasks'
            );
          },
          id: 'tasks',
        },
      ],
      bodyBottom: [
        {
          iconElement: 'question',
          title: 'Руководство',
          id: 'manual',
          type: 'link',
          href: 'https://guide.digitalagro.ru/',
          ignoreDisable: true,
          alias: 'manual',
          tooltip: {
            short: {
              content: 'Руководство',
              position: 'right',
            },
          },
        },
        {
          iconElement: 'support',
          title: 'Служба поддержки',
          id: 'support',
          type: 'link',
          href: 'https://digitalagrodev.atlassian.net/servicedesk/customer/portal/1',
          ignoreDisable: true,
          alias: 'support',
          isAllowOverflow: true,
          tooltip: {
            short: {
              content: 'Служба поддержки',
              position: 'right',
            },
          },
        },
        {
          iconElement: MenuItemContactIcon,
          title: MenuItemContact,
          id: 'mobile',
          type: 'expand',
          ignoreDisable: true,
          alias: 'mobile',
          isAllowOverflow: true,
          tooltip: {
            short: {
              content: 'Контакт',
              position: 'right',
            },
          },
        },
      ],
    },

    footerConfig: {
      name: getUsername(),
      avatar: getUserPic,
      handleProfileClick: () => {
        onSidebarMenuItemClick(onProfileClick);
      },
      isDisplayShortStateNameTooltip: true,
      handleLogoutClick: () => {
        onSidebarMenuItemClick(onLogoutClick);
      },
    },
  };

  const sidebarConfig = useMemo(() => {
    const experimentsSidebarBodyConfig: ISidebarMenuItem[] = [
      {
        iconElement: () => <MenuIcon $iconSvg={TechIcon} />,
        title: 'Опыты',
        type: 'navigation',
        id: 'experiments',
        href: externalProductRedirectLink.exp,
        isExternal: true,
        accessRules: userExperimentAccess
          ? null
          : {
              oneOf: ACTION_LIST_TO_DISPLAY_EXTERNAL,
            },
        isDisabled: !userExperimentAccess,
        tooltip: {
          short: {
            content: 'Опыты',
            position: 'right',
          },
        },
      },
      {
        iconElement: () => <MenuIcon $iconSvg={AuditIcon} />,
        title: 'Наблюдение',
        type: 'navigation',
        id: 'audits',
        href: externalProductRedirectLink.audit,
        isExternal: true,
        accessRules: userExperimentAccess
          ? null
          : {
              oneOf: ACTION_LIST_TO_DISPLAY_EXTERNAL,
            },
        isDisabled: !userExperimentAccess,
        tooltip: {
          short: {
            content: 'Наблюдение',
            position: 'right',
          },
        },
      },
      {
        iconElement: () => <MenuIcon $iconSvg={DocsIcon} />,
        title: 'Договоры',
        type: 'navigation',
        id: 'contracts',
        href: externalProductRedirectLink.docs,
        isExternal: true,
        accessRules: userExperimentAccess
          ? null
          : {
              oneOf: ACTION_LIST_TO_DISPLAY_EXTERNAL,
            },
        isDisabled: !userExperimentAccess,
        tooltip: {
          short: {
            content: 'Договоры',
            position: 'right',
          },
        },
      },
    ];

    if (userExperimentAccess) {
      return {
        ...initialConfig,
        bodyConfig: {
          ...initialConfig.bodyConfig,
          bodyTop: initialConfig.bodyConfig.bodyTop.concat(experimentsSidebarBodyConfig),
        },
      };
    } else {
      return initialConfig;
    }
  }, [userExperimentAccess, selectedOrganizationId]);

  return <FarmikSidebar {...sidebarConfig} data-is-loading={debouncedDataLoading} />;
});
