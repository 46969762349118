import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  CalendarComponent,
  EDateFormat,
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';

import { ModalComponent } from '../../../../../../components/Modal/Modal';
import { useStore } from '../../../../../shared/utils/IoC';
import { OperationsStore } from '../../stores/operations.store';
import { CreateOperationFormController } from '../../controllers/create.operation.form.controller';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { TDropdownConfig } from '../../../../../shared/components/inputs/Dropdown/Dropdown.types';
import { Dropdown } from '../../../../../shared/components/inputs/Dropdown';
import { useOperationsParams } from '../../hooks';

import {
  Title,
  Line,
  SeasonStubSelectorWrapper,
  CalendarWrapper,
  CalendarRow,
  BottomLine,
  ButtonsWrapper,
  CalendarSplitter,
} from './style';
import { getEndDateDefault, getStartDateDefault } from './helpers/helpers';

type CreateSeasonModalProps = {
  closeModal: () => void;
};

export const CreateOperationModal: FC<CreateSeasonModalProps> = observer(({ closeModal }) => {
  const seassonStore = useStore(SeasonsStore);
  const operationsStore = useStore(OperationsStore);
  const createOperationFormController = useStore(CreateOperationFormController);

  const [operationTypeId, setOperationTypeId] = useState('');
  const [isFormSending, setIsFormSending] = useState(false);
  const [operationTitle, setOperationTitle] = useState(null);

  const notificatorActions = useNotificator();

  const params = useOperationsParams();

  const startDateDefault = useMemo(
    () =>
      getStartDateDefault(
        seassonStore.selectedSeassonData.startDate,
        seassonStore.selectedSeassonData.endDate
      ),
    [seassonStore.selectedSeassonData.startDate, seassonStore.selectedSeassonData.endDate]
  );

  const endDateDefault = useMemo(
    () =>
      getEndDateDefault(
        startDateDefault,
        seassonStore.selectedSeassonData.startDate,
        seassonStore.selectedSeassonData.endDate
      ),
    [seassonStore.selectedSeassonData.startDate, seassonStore.selectedSeassonData.endDate]
  );

  const [startDate, setStartDate] = useState<Moment>(startDateDefault);
  const [endDate, setEndDate] = useState<Moment>(endDateDefault);

  useEffect(() => {
    const culture = operationsStore.OperationCulturesInFields.filter(
      item => item?.culture.id === params.cultureId
    )[0]?.culture.name;

    setOperationTitle(culture ? culture : 'поля без культуры');
    operationsStore.fetchOperationTypeDictionaryByCultureId(params.cultureId, false);
  }, []);

  const isSaveButtonAvailable =
    endDate && startDate.isSameOrBefore(endDate) && Boolean(operationTypeId) && !isFormSending;

  const handleCreateOperation = async () => {
    setIsFormSending(true);
    const { label } = operationsStore.getOperationsTypeSelectAdapter.find(
      option => option.value === operationTypeId
    );

    try {
      await createOperationFormController.handleCreateOperation(
        {
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
          name: label,
          operationTypeId,
        },
        params.cultureId
      );

      notificatorActions.setNotification({
        message: 'Операция успешно добавлена',
        style: {
          type: ENotificationType.Success,
          height: ENotificationHeight.BIG,
          placement: 'top-center',
          autoClose: 10000,
          hideProgressBar: false,
          theme: ENotificatorTheme.Dark,
        },
      });

      setIsFormSending(false);

      closeModal();
    } catch (e) {
      notificatorActions.setNotification({
        message: `Ошибка добавления операции`,
        style: {
          type: ENotificationType.Warning,
          height: ENotificationHeight.BIG,
          placement: 'top-center',
          autoClose: 10000,
          hideProgressBar: false,
          theme: ENotificatorTheme.Dark,
        },
      });

      setIsFormSending(false);
    }
  };

  const handleStartDateChange = useCallback(
    (date: Date) => {
      const momentDate = moment(date);

      setStartDate(momentDate);
      if (momentDate.isAfter(endDate)) {
        setEndDate(momentDate);
      }
    },
    [endDate, setStartDate, setEndDate]
  );

  const handleEndDateChange = useCallback(
    (date: Date) => {
      const momentDate = moment(date);

      if (momentDate.isBefore(startDate)) {
        setStartDate(momentDate);
        setEndDate(momentDate);
      } else {
        setEndDate(momentDate);
      }
    },
    [startDate, setStartDate, setEndDate]
  );

  const startDateValue = useMemo(() => {
    const result = (startDate as Moment)?.format('DD.MM.YYYY') || '';
    return result;
  }, [startDate]);

  const endDateValue = useMemo(() => {
    const result = (endDate as Moment)?.format('DD.MM.YYYY') || '';
    return result;
  }, [endDate]);

  const datePickerOptions = useMemo(
    () => ({
      dateRange: {
        minDate: moment(seassonStore.selectedSeassonData.startDate).toDate(),
        maxDate: moment(seassonStore.selectedSeassonData.endDate).toDate(),
      },
    }),
    [seassonStore.selectedSeassonData.startDate, seassonStore.selectedSeassonData.endDate]
  );

  const calendarStartDateMemo = useMemo(() => {
    return (
      <CalendarComponent
        label={'Начало выполнения'}
        value={startDateValue}
        defaultValue={startDateValue}
        onChange={handleStartDateChange}
        placeholder={'Укажите дату'}
        datePickerOptions={datePickerOptions}
        dataTestId={'operation-start-date'}
        dateFormat={EDateFormat.DayAndMonthOnly}
        isDisabled={false}
        isBlocked={false}
        readOnly={false}
        error={''}
        isCloseOnChange={true}
      />
    );
  }, [startDate, endDate, startDateValue, datePickerOptions, handleStartDateChange]);

  const calendarEndDateMemo = useMemo(() => {
    return (
      <CalendarComponent
        label={'Окончание выполнения'}
        value={endDateValue}
        defaultValue={endDateValue}
        onChange={handleEndDateChange}
        placeholder={'Укажите дату'}
        datePickerOptions={datePickerOptions}
        dataTestId={'operation-end-date'}
        dateFormat={EDateFormat.DayAndMonthOnly}
        readOnly={false}
        isBlocked={false}
        isDisabled={false}
        error={''}
        isCloseOnChange={true}
      />
    );
  }, [startDate, endDate, endDateValue, datePickerOptions, handleEndDateChange]);

  const dropdownConfig: TDropdownConfig = {
    body: { optionList: operationsStore.getOperationsTypeSelectAdapter },
    field: {
      onChange: setOperationTypeId,
      placeholder: 'Не выбрано',
      type: {
        search: {},
      },
    },
    visual: {
      label: 'Тип операции',
    },
    other: {
      dataTestId: 'operation-stub-select',
    },
  };

  return (
    <ModalComponent size={'medium'} paddings={'seasons'} data-test-id={'operation-add-modal-new'}>
      <Title data-test-id={'operation-add-modal-new-title'}>Операция для {operationTitle}</Title>
      <Line />
      <SeasonStubSelectorWrapper>
        <Dropdown config={dropdownConfig} />
      </SeasonStubSelectorWrapper>
      <CalendarRow>
        <CalendarWrapper>{calendarStartDateMemo}</CalendarWrapper>
        <CalendarSplitter />
        <CalendarWrapper>{calendarEndDateMemo}</CalendarWrapper>
      </CalendarRow>
      <BottomLine />
      <ButtonsWrapper>
        <Button
          color={'default'}
          type={'button'}
          onClick={() => closeModal()}
          dataTestId={'operation-cancel-btn'}
        >
          Отменить
        </Button>
        <Button
          color={'primary'}
          type={'button'}
          disabled={!isSaveButtonAvailable}
          onClick={handleCreateOperation}
          dataTestId={'operation-save-btn'}
        >
          Добавить операцию
        </Button>
      </ButtonsWrapper>
    </ModalComponent>
  );
});
