import { FC } from 'react';
import { AutoTooltip, Typography, useShowTooltip } from '@farmlink/farmik-ui';

import Styled from './CommentCell.styles';

const CommentCell: FC<{ value: string }> = ({ value }) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>(value);

  return (
    <AutoTooltip content={value} disabled={!isShowTooltip}>
      <Typography>
        <Styled.CellWrapper ref={ref}>{value}</Styled.CellWrapper>
      </Typography>
    </AutoTooltip>
  );
};

export default CommentCell;
