import { LatLngExpression } from 'leaflet';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';

import {
  ECheckListInstanceType,
  IDrawChecklistInstanceWithType,
  IGetChecklistInstanceByTaskId,
} from '../../../../../../api/models/checklist/instance/checklist.instance.model';
import { Map } from '../../../../../shared/features/map';
import { useMapEvents } from '../../../../../shared/features/map/hooks';
import { MapMarkerController } from '../../../../../shared/features/map/modules';
import { BaseMarker } from '../../../../../shared/features/map/utils/MapElements';
import { useStore } from '../../../../../shared/utils/IoC';
import Styled from '../InspectionPoints/InspectionPoints.styles';
import { InspectionPointsStore } from '../InspectionPoints/mobx/stores';
import {
  ActualInspectionPointMarker,
  PlanInspectionPointMarker,
} from '../InspectionPoints/utils/helpers/mapElementHelpers/markers';

interface IProps {
  selectedPoint: IDrawChecklistInstanceWithType;
}

/**
 * @module EditInspectionPoint
 */
const EditInspectionPoint: FC<IProps> = ({ selectedPoint }) => {
  // Сторы
  const { setTempLatLngOfEditablePoint } = useStore(InspectionPointsStore);

  const mapEvents = useMapEvents();
  const mapMarkerController = useStore(MapMarkerController);

  const [tempMarker, setTempMarker] = useState<BaseMarker>(null);

  const getNewMarker = (latLng: LatLngExpression) => {
    const { positionNumber, type, instance } = selectedPoint;
    const isComplete = (instance as IGetChecklistInstanceByTaskId)?.isComplete;

    if (type === ECheckListInstanceType.ActualPoint) {
      return new ActualInspectionPointMarker(latLng, positionNumber, isComplete, false);
    }

    return new PlanInspectionPointMarker(latLng, positionNumber, isComplete, false);
  };

  useEffect(() => {
    mapEvents.onCreateMarkerByClick(marker => {
      if (tempMarker) {
        mapMarkerController.remove(tempMarker.id);
      }

      setTempMarker(marker);
      setTempLatLngOfEditablePoint(marker.getLatLng());
    });
  }, [tempMarker]);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.TitleWrapper>
          <Styled.Title>Точка {selectedPoint?.positionNumber}</Styled.Title>
        </Styled.TitleWrapper>
      </Styled.Header>

      <Styled.MapWrapper $marginTop={'24px'}>
        <Map
          globalConfig={{
            mapKey: 'taskEditInspectionPointMap',
            markersConfig: {
              getNewMarkerOnClick: getNewMarker,
            },
          }}
          style={{ borderRadius: '16px' }}
        />
      </Styled.MapWrapper>
    </Styled.Wrapper>
  );
};

export default observer(EditInspectionPoint);
