import styled, { css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Name = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  font-feature-settings: 'clig' off, 'liga' off;
  /* bodySubAccent: semi14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.15px;

  color: #151f32;
`;

const Type = styled.div`
  color: #7e8b9d;
  font-feature-settings: 'clig' off, 'liga' off;
  /* note: reg12 */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

const Content = styled.div`
  flex: 1 1 calc(100% - 16px - 8px);

  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

const IconWrapper = styled.div`
  flex: 0 0 16px;
`;

const Wrapper = styled.div<{ $isActive: boolean; $isSelected: boolean }>`
  width: 222px;

  display: flex;
  justify-content: space-between;

  gap: 8px;

  padding: 10px;
  border-radius: 12px;

  cursor: pointer;

  background-color: ${Colors.generalWhite};

  ${({ $isActive }) =>
    $isActive
      ? css`
          border: 1px solid #3fb65f;
        `
      : css`
          border: 1px solid #e9e6f0;
        `};

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.6;
        `};
`;

const StyledStoChecklistsCard = {
  Name,
  Type,
  Content,
  IconWrapper,
  Wrapper,
};

export default StyledStoChecklistsCard;
