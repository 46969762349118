import {
  EChecklistAttributeType,
  IGetChecklistAttribute,
} from '../../../../checklist/attribute/checklist.attribute.model';

export interface IStoAttribute {
  id: string;
  stoAttributeId: string;
  checklistId: string;
  stageId: string;
  attribute: IGetChecklistAttribute['attribute'];
  isRequired: boolean;
  isLinkedPhotoAvailable: boolean;
  isLinkedPhotoEnabled: boolean;
  isLinkedPhotoRequired: boolean;
  isToolTipEnabled: boolean;
  toolTip: string | null;
  userDictionaryValues: IIStoAttributeUserDictionaryValue[];
  stoODZFormType: IStoAttributeStoOdzFormType;
  isPrintable: boolean;
  valuesPrintMode: EStoAttributeValuesPrintMode | null;
}

export enum EStoAttributeValuesPrintMode {
  NotPrinted = 'NOT_PRINTED',
  Body = 'BODY',
  Attachments = 'ATTACHMENTS',
}

export interface IIStoAttributeUserDictionaryValue {
  id: string;
  value: string;
  isActive: boolean;
}

export interface IStoAttributeStoOdzFormType {
  type: EChecklistAttributeType;
  izODZEnabled: boolean;
  isComplexODZ: boolean;
  odzGroups: IStoAttributeOdzGroup[];
}

export interface IStoAttributeOdzGroup {
  dependencyAttributes: IStoAttributeOdzDependency[] | null;
  odzCriteria: IStoAttributeOdzCriteria;
}

export interface IStoAttributeOdzDependency {
  id: string;
  type: EChecklistAttributeType;
  dictionaryLink: string;
  name: string;
  numericValues: {
    from: number | null;
    to: number | null;
  };
  dictionaryValues: IStoAttributeOdzDictionaryValue[];
}

export interface IStoAttributeOdzCriteria {
  precision: number | null;
  numericValues: {
    redLow: number | null;
    yellowLow: number | null;
    yellowHigh: number | null;
    redHigh: number | null;
  };
  dictionaryValues: {
    red: IStoAttributeOdzDictionaryValue[];
    yellow: IStoAttributeOdzDictionaryValue[];
    green: IStoAttributeOdzDictionaryValue[];
  };
}

export interface IStoAttributeOdzDictionaryValue {
  id: string;
  name: string;
}
