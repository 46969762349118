import { RadioButton } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { EFieldsListSort } from '../../../../../../../../interfaces/Filter.interface';
import FilterSectionHeader from '../../components/SectionHeader/SectionHeader';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { FieldsViewStore as Store } from '../../../../../../mobx';

import Styled from './Sort.styles';

const FiltersSortSection: FC = () => {
  // Сторы
  const store = useStore(Store);
  const sorter = store.filterManager.sorter;

  const getDataTestId = useDataTestIdV2('field-sorter');

  const isSelected = (type: EFieldsListSort) => sorter.value === type;

  const changeSortValue = (type: EFieldsListSort) => {
    sorter.setValue(type);
  };

  return (
    <Styled.Wrapper>
      <FilterSectionHeader
        title={'Сортировка'}
        showResetBtn={false}
        getDataTestId={getDataTestId}
      />

      <Styled.ListContainer>
        <Styled.ListItem onClick={() => changeSortValue(EFieldsListSort.NAME_ASC)}>
          <Styled.Name>
            <RadioButton value={isSelected(EFieldsListSort.NAME_ASC)} />
            <span>Название поля</span>
          </Styled.Name>

          <Styled.InfoLabel isSelected={isSelected(EFieldsListSort.NAME_ASC)}>
            А - Я
          </Styled.InfoLabel>
        </Styled.ListItem>

        <Styled.ListItem onClick={() => changeSortValue(EFieldsListSort.NAME_DESC)}>
          <Styled.Name>
            <RadioButton value={isSelected(EFieldsListSort.NAME_DESC)} />
            <span>Название поля</span>
          </Styled.Name>

          <Styled.InfoLabel isSelected={isSelected(EFieldsListSort.NAME_DESC)}>
            Я - А
          </Styled.InfoLabel>
        </Styled.ListItem>

        <Styled.ListItem onClick={() => changeSortValue(EFieldsListSort.AREA_ASC)}>
          <Styled.Name>
            <RadioButton value={isSelected(EFieldsListSort.AREA_ASC)} />
            <span>Площадь</span>
          </Styled.Name>

          <Styled.InfoLabel isSelected={isSelected(EFieldsListSort.AREA_ASC)}>
            макс. - мин.
          </Styled.InfoLabel>
        </Styled.ListItem>

        <Styled.ListItem onClick={() => changeSortValue(EFieldsListSort.AREA_DESC)}>
          <Styled.Name>
            <RadioButton value={isSelected(EFieldsListSort.AREA_DESC)} />
            <span>Площадь</span>
          </Styled.Name>

          <Styled.InfoLabel isSelected={isSelected(EFieldsListSort.AREA_DESC)}>
            мин. - макс.
          </Styled.InfoLabel>
        </Styled.ListItem>

        <Styled.ListItem onClick={() => changeSortValue(EFieldsListSort.NEW)}>
          <Styled.Name>
            <RadioButton value={isSelected(EFieldsListSort.NEW)} />
            <span>Сначала новые поля</span>
          </Styled.Name>
        </Styled.ListItem>
      </Styled.ListContainer>
    </Styled.Wrapper>
  );
};

export default observer(FiltersSortSection);
