import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { EFieldAccessAction } from '../../../../../../../../../../../../../../../shared/constants/access-rules-action';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { CultureZone } from '../../../../../../../../../../../../../../../../api/models/field.model';
import { RightBlockWithAreaAndMenu } from '../../../../../../../../../../../../components/ListCard';
import { FieldCulturesStore as CulturesStore } from '../../../../../../../../../../mobx';
import { useStore } from '../../../../../../../../../../../../../../../shared/utils/IoC';
import ListCard from '../../../../../../../../../../../../components/ListCard/ListCard';
import { useFieldsPermissions } from '../../../../../../../../../../../../hooks';

import DropdownMenu from './components/DropdownMenu/DropdownMenu';
import ExpIcon from './components/ExpIcon/ExpIcon';
import Styled from './CultureZoneCard.styles';

interface IProps {
  cultureZone: CultureZone;
  onClick: () => void;
}

const CultureZoneCard: FC<IProps> = props => {
  // Сторы
  const culturesStore = useStore(CulturesStore);

  const permissions = useFieldsPermissions();
  const getDataTestId = useDataTestIdV2('field-seasons-culture-zone-card');

  const isSelectedCard = props.cultureZone.id === culturesStore.selectedCultureZone?.id;

  const getZoneName = () => {
    return props.cultureZone?.name || 'Участок';
  };

  const getCultureName = () => {
    return props.cultureZone.culture?.name || 'Нет культуры';
  };

  const getCultureArea = () => {
    return `${props.cultureZone.area.toFixed(1)} га`;
  };

  const getLeftBlock = () => (
    <Styled.IconWrapper>
      <ExpIcon cultureZone={props.cultureZone} />
    </Styled.IconWrapper>
  );

  const getRightBlock = () => (
    <RightBlockWithAreaAndMenu
      area={getCultureArea()}
      getDataTestId={getDataTestId}
      dropdownMenu={
        permissions.hasAccess(EFieldAccessAction.EditCultureZone) ? (
          <DropdownMenu cultureZone={props.cultureZone} onOpen={props.onClick} />
        ) : null
      }
    />
  );

  return (
    <ListCard
      title={getZoneName()}
      subtitle={getCultureName()}
      isSelected={isSelectedCard}
      leftBlock={getLeftBlock}
      rightBlock={getRightBlock}
      onClick={props.onClick}
      dataTestId={getDataTestId()['data-test-id']}
    />
  );
};

export default observer(CultureZoneCard);
