import { observer } from 'mobx-react';
import { Colors, Icon } from '@farmlink/farmik-ui';

import { FieldsMapCoreStore } from '../../../../../dashboard/modules/fields/mobx';
import {
  FieldsCreateController,
  FieldEditController,
  FieldCulturesController,
  FieldsImportController,
} from '../../../../../dashboard/modules/fields/modules';
import { BasePolygon } from '../../utils/MapElements';
import { useStore } from '../../../../utils/IoC';

import { usePointCoordinatesUpdate } from './hooks';
import Styled from './PointCoordinates.styles';

const PointCoordinatesControls = () => {
  const sharedFieldsStore = useStore(FieldsMapCoreStore);

  const fieldsCreateController = useStore(FieldsCreateController);
  const fieldEditController = useStore(FieldEditController);
  const fieldCulturesController = useStore(FieldCulturesController);
  const fieldsImportController = useStore(FieldsImportController);

  /**
   *  При изменении координаты нужно отправлять эвент об изменении геометрии и каждый из режимов выполнит ту логику которая в нем прописан
   */
  const handleEditPolygon = (polygon: BasePolygon) => {
    if (sharedFieldsStore.isCreateMode) {
      return fieldsCreateController.handleEditPolygon(polygon);
    }

    if (sharedFieldsStore.isEditMode) {
      return fieldEditController.handleEditPolygon(polygon);
    }

    if (sharedFieldsStore.isImportMode) {
      return fieldsImportController.handlePolygonEdit(polygon);
    }

    if (sharedFieldsStore.isDisplaySeasonsMode) {
      return fieldCulturesController.handleEditPolygon(polygon as any);
    }
  };

  const {
    expandChangeCord,
    isExpanded,
    newCoords,
    shrinkChangeCord,
    coordinates,
    updateCord,
    onChangeCoordinates,
    error,
    isExpandDisabled,
  } = usePointCoordinatesUpdate({ handleEditPolygon });

  if (isExpandDisabled) {
    return null;
  }

  return (
    <Styled.BottomBackground>
      <Styled.Container onSubmit={updateCord}>
        <Styled.CoordinateDisplay value={coordinates} blocked />
        {isExpanded ? (
          <>
            <Styled.CoordinateDisplay
              value={newCoords}
              onChange={onChangeCoordinates}
              $isError={Boolean(error?.length)}
            />

            <Styled.IconButton
              onClick={shrinkChangeCord}
              size={40}
              $padding={12}
              type="button"
              customIcon={
                <Styled.IconWrapper $hoverColor={Colors.lightPink}>
                  <Icon icon="close" size={16} fill={Colors.accentPink} />
                </Styled.IconWrapper>
              }
            />
            <Styled.IconButton
              size={40}
              $padding={12}
              type="submit"
              customIcon={
                <Styled.IconWrapper $hoverColor={Colors.lightGreen}>
                  <Styled.CheckIcon />
                </Styled.IconWrapper>
              }
            />
          </>
        ) : (
          <Styled.IconButton
            onClick={expandChangeCord}
            size={40}
            $padding={12}
            type="button"
            $isDisabled={isExpandDisabled}
            customIcon={
              <Styled.IconWrapper>
                <Icon icon="coordinates" size={16} />
              </Styled.IconWrapper>
            }
          />
        )}
      </Styled.Container>
    </Styled.BottomBackground>
  );
};

export default observer(PointCoordinatesControls);
