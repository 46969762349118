import { FC, useState, useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Icon } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { WeatherStore } from '../../mobx';

import { InfoBlock } from './components/InfoBlock';
import infoImg from './assets/info.svg';
import {
  PlantProtectionContent,
  PlantProtectionContentDescription,
  PlantProtectionContentTimeLine,
  PlantProtectionHeader,
  PlantProtectionSection,
  PlantProtectionTimePart,
  PlantProtectionLeftControl,
  PlantProtectionRightControl,
  PlantProtectionContentTimeLineWrapper,
} from './style';

const processResp = (
  resp
): Array<{ type: 'common' | 'green' | 'yellow' | 'red'; time: string }> => {
  if (!resp || !resp.forecasts) {
    return [];
  }
  return resp.forecasts[0].hours.map((item: any) => {
    const hourTitle = item.hour < 10 ? `0${item.hour}:00` : `${item.hour}:00`;
    if (
      item.temp >= 12 &&
      item.temp <= 25 &&
      item.humidity >= 60 &&
      item.humidity <= 80 &&
      item.windSpeed >= 3 &&
      item.windSpeed <= 4
    ) {
      return {
        type: 'green',
        time: hourTitle,
      };
    }
    if (
      item.temp >= 12 &&
      item.temp <= 25 &&
      item.humidity >= 60 &&
      item.humidity <= 80 &&
      item.windSpeed > 4 &&
      item.windSpeed < 5
    ) {
      return {
        type: 'yellow',
        time: hourTitle,
      };
    }
    if (item.temp >= 25 && (item.humidity < 60 || item.humidity > 80) && item.windSpeed >= 5) {
      return {
        type: 'red',
        time: hourTitle,
      };
    }
    return {
      type: 'common',
      time: hourTitle,
    };
  });
};

export const PlantProtection: FC = observer(() => {
  const weatherStore = useStore(WeatherStore);
  const plProtection = processResp(weatherStore.fieldWeather);

  const scroll = useRef<HTMLDivElement>();
  const [blockedLeftArr, setBlockedLeftArr] = useState<boolean>(true);
  const [blockedRightArr, setBlockedRightArr] = useState<boolean>(false);

  const scrollHandle = e => {
    if (e.target.scrollLeft + e.target.clientWidth >= e.target.scrollWidth) {
      setBlockedLeftArr(false);
      setBlockedRightArr(true);
    } else if (e.target.scrollLeft === 0) {
      setBlockedLeftArr(true);
      setBlockedRightArr(false);
    } else if (
      e.target.scrollLeft > 0 &&
      e.target.scrollLeft + e.target.clientWidth < e.target.scrollWidth
    ) {
      setBlockedLeftArr(false);
      setBlockedRightArr(false);
    }
  };

  const clickHandle = useCallback((direction: 'left' | 'right') => {
    if (direction === 'left') {
      scroll.current.scrollLeft -= 250;
    } else {
      scroll.current.scrollLeft += 250;
    }
  }, []);

  return (
    <PlantProtectionSection data-test-id="plant-protection-component">
      <PlantProtectionHeader data-test-id="plant-protection-component-header">
        <p data-test-id="plant-protection-component-header-label">Окна внесения СЗР</p>
        <span>
          <InfoBlock imgUrl={infoImg} data-test-id="plant-protection-component-header-info">
            Представленные выше окна указывают на временные отрезки, в которые рекомендовано вносить
            средства защиты растений (СЗР), основанные на текущем прогнозе погоды. Обращаем ваше
            внимание на то, что данные сведения носят рекомендательный характер. Зеленый цвет –
            означает благоприятную погоду для внесения СЗР.
          </InfoBlock>
        </span>
      </PlantProtectionHeader>
      <PlantProtectionContent data-test-id="plant-protection-component-content">
        <PlantProtectionLeftControl
          disabled={blockedLeftArr}
          onClick={() => clickHandle('left')}
          data-test-id="plant-protection-component-slider-left-arrow"
        >
          <Icon
            icon={'arrow_left'}
            size={'small'}
            dataTestId="plant-protection-component-slider-left-arrow-icon"
          />
        </PlantProtectionLeftControl>
        <PlantProtectionContentTimeLineWrapper data-test-id="plant-protection-component-slider">
          <PlantProtectionContentTimeLine
            ref={scroll}
            onScroll={scrollHandle}
            data-test-id="plant-protection-component-slider-body"
          >
            {plProtection &&
              plProtection.map((item, idx) => (
                <PlantProtectionTimePart
                  type={item.type}
                  key={idx}
                  data-test-id="plant-protection-component-slider-item"
                >
                  <p>{item.time}</p>
                  <div />
                </PlantProtectionTimePart>
              ))}
          </PlantProtectionContentTimeLine>
        </PlantProtectionContentTimeLineWrapper>
        <PlantProtectionContentDescription data-test-id="plant-protection-component-description">
          Представленные выше окна указывают на временные отрезки, в которые рекомендовано вносить
          средства защиты растений (СЗР), основанные на текущем прогнозе погоды. Обращаем ваше
          внимание на то, что данные сведения носят рекомендательный характер. Зеленый цвет –
          означает благоприятную погоду для внесения СЗР.
        </PlantProtectionContentDescription>
        <PlantProtectionRightControl
          disabled={blockedRightArr}
          onClick={() => clickHandle('right')}
          data-test-id="plant-protection-component-slider-right-arrow"
        >
          <Icon
            icon={'arrow_right'}
            size={'small'}
            data-test-id="plant-protection-component-slider-left-arrow-icon"
          />
        </PlantProtectionRightControl>
      </PlantProtectionContent>
    </PlantProtectionSection>
  );
});
