import styled, { css } from 'styled-components';

const Title = styled.div`
  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  /* body: reg14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  align-items: center;

  gap: 12px;

  overflow: hidden;

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          pointer-events: none;
        `
      : css`
          pointer-events: all !important;
        `};
`;

const StyledStoChecklistsAttrCell = {
  Title,
  Wrapper,
};

export default StyledStoChecklistsAttrCell;
