import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding-bottom: 24px;
  border-bottom: 1px solid ${Colors.technicalBorderGrey};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledFieldsAddStoModal = {
  Header,
  Wrapper,
};

export default StyledFieldsAddStoModal;
