import { Checkbox } from '@farmlink/farmik-ui';
import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { MultiselectStore } from '../../../../mobx';
import FieldsMultiselectController from '../../../../mobx/controllers/FieldsMultiselect.controller';

import Styled from './SelectAllCheckbox.styles';

const SelectAllCheckbox: FC = () => {
  // Сторы
  const store = useStore(MultiselectStore);

  // Контроллеры
  const controller = useStore(FieldsMultiselectController);

  const { selectedFieldsCount, activeFieldsList } = store;
  const isIntermediate = selectedFieldsCount && selectedFieldsCount !== activeFieldsList.length;

  const isAllChecked = useMemo(() => {
    if (!activeFieldsList) {
      return false;
    }

    return activeFieldsList.length === selectedFieldsCount;
  }, [activeFieldsList, selectedFieldsCount]);

  const handleChange = (value: boolean) => {
    controller.toggleAllFields(value);
  };

  return (
    <Styled.Wrapper>
      <Checkbox
        label={'Выбрать все'}
        value={isAllChecked}
        intermediate={isIntermediate}
        onChange={handleChange}
      />
    </Styled.Wrapper>
  );
};

export default observer(SelectAllCheckbox);
