import { Button } from '@farmlink/farmik-ui';

import warningSvg from './assets/warningSvg.svg';
import { ActionButtonWrapper, WarningBlockWrapper, WarningIcon, WarningTitle1, WarningTitle2 } from './style';

export const WarningBlock = (
    {
        title1,
        title2,
        buttonAction
    }: {
        title1: string;
        title2: string;
        buttonAction: any;
    }
    ) => {

    return (
        <WarningBlockWrapper>
            <WarningIcon src={warningSvg}/>
            <WarningTitle1>
                {title1}
            </WarningTitle1>
            <WarningTitle2>
                {title2}
            </WarningTitle2>
            <ActionButtonWrapper>
                <Button
                    color={'primary'}
                    type={'button'}
                    onClick={buttonAction}
                >
                    Вернуться
                </Button>
            </ActionButtonWrapper>
        </WarningBlockWrapper>
    );
};