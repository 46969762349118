import { makeAutoObservable } from 'mobx';

import { FieldsStore } from '../../../../shared/mobx/stores';
import { Axios } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { OperationsStore } from '../../operations/stores/operations.store';
import { OrganizationUsersStore } from '../../organization/pages/organizationUsersList/stores/organization.users.store';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { taskLabels, taskPrioreteeLabels } from '../../operations/stores/tasks.store';
import { SeasonsStore } from '../../../stores/seasons.store';
import { TasksStore } from '../stores/tasks.store';

const culturesListAdapter = (list): { label: string; value: string }[] => {
  const tmp = {};

  list.forEach(item => {
    tmp[item.culture.id] = {
      label: item.culture.name,
      value: item.culture.id,
    };
    // if (!tmp.includes(item.culture.id))
    //   return {
    //     name: item.culture.name,
    //     value: item.culture.id,
    //   };
  });
  return Object.values(tmp);
};

const usersListAdapter = (list): { label: string; value: string }[] => {
  let counter = 0;

  return list.map(item => ({
    label: item.userName || `Без имени ${++counter}`,
    value: item.userId,
  }));
};

const taskStatusesListAdapter = (obj): { label: string; value: string }[] => {
  const entries = Object.entries(obj) as any;

  return entries.map(item => ({
    label: item[1],
    value: item[0],
  }));
};

const operationsTypesListAdapter = (list): { label: string; value: string }[] => {
  return list.map(item => ({
    label: item.name,
    value: item.id,
  }));
};

@provide.singleton()
export class TasksController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(OperationsStore)
  protected operationsStore: OperationsStore;

  @lazyInject(OrganizationUsersStore)
  protected organizationUsersStore: OrganizationUsersStore;
  @lazyInject(OrganizationsStore)
  protected organizations: OrganizationsStore;
  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(TasksStore)
  protected tasksStore: TasksStore;

  constructor() {
    makeAutoObservable(this);

    this.initRequest();
  }

  get organizationUsersList() {
    return usersListAdapter(this.organizationUsersStore.usersList);
  }

  get culturesList() {
    return culturesListAdapter(this.operationsStore.OperationCulturesInFields);
  }

  get taskStatuses() {
    return taskStatusesListAdapter(taskLabels);
  }

  get taskPrioretees() {
    return taskStatusesListAdapter(taskPrioreteeLabels);
  }

  get operationsTypes() {
    return operationsTypesListAdapter(this.operationsStore.OperationsType);
  }

  get operationsTypesForFilter() {
    return operationsTypesListAdapter(this.operationsStore.OperationsTypeForFilter);
  }

  get fieldsList() {
    return operationsTypesListAdapter(this.fieldsStore.fieldsList);
  }

  initRequest() {
    if (this.organizations.selectedOrganizationId !== 'my') {
      this.organizationUsersStore.getOrganizationUsersList(
        this.organizations.selectedOrganizationId
      );
    }
    if (this.seasonsStore.selectedSeason) {
      this.operationsStore.getCulturesList();
    }
    // this.fields.fetchFieldsList();
  }
}
