import { FC, useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { Colors } from '@farmlink/farmik-ui';

import Styled from './YearSelector.styles';

interface IProps {
  options: number[];
  defaultValue?: number;
  onYearChange?: (newYear: number) => void;
}

const arrowColorScheme = {
  default: { background: 'transparent', icon: Colors.generalDark },
  hover: { background: 'transparent', icon: Colors.green },
  disabled: { background: 'transparent', icon: Colors.darkGrey },
};

const YearSelector: FC<IProps> = ({ options, defaultValue, onYearChange }) => {
  const [currentYearIndex, setCurrentYearIndex] = useState<number>(0);
  const [sortedOptions, setSortedOptions] = useState<number[]>([]);

  useEffect(() => setSortedOptions(sortBy(options)), [options]);

  useEffect(() => {
    setCurrentYearIndex(sortedOptions.findIndex(value => defaultValue === value));
  }, [defaultValue, sortedOptions]);

  const onChangeCurrentIndex = (index: number) => {
    setCurrentYearIndex(index);
    onYearChange?.(sortedOptions[index]);
  };

  const extremes = useMemo<{ min: number; max: number }>(() => {
    const sortedList = sortBy(options);
    return { min: sortedList[0], max: sortedList[sortedList.length - 1] };
  }, [sortedOptions]);

  const currentYear = sortedOptions[currentYearIndex];

  return (
    <Styled.Wrapper>
      <Styled.ArrowButton
        icon="arrow_left"
        colorScheme={arrowColorScheme}
        size={16}
        onClick={() => onChangeCurrentIndex(currentYearIndex - 1)}
        $isDisabled={currentYear === extremes.min}
      />

      <Styled.Value align="center" variant="bodySubAccent">
        {currentYear}
      </Styled.Value>

      <Styled.ArrowButton
        icon="arrow_right"
        colorScheme={arrowColorScheme}
        size={16}
        onClick={() => onChangeCurrentIndex(currentYearIndex + 1)}
        $isDisabled={currentYear === extremes.max}
      />
    </Styled.Wrapper>
  );
};

export default YearSelector;
