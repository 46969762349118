import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/IoC';

@provide.singleton()
class MapDrawerStore {
  private _isDrawEnabled = false;

  private _isEditEnabled = false;

  constructor() {
    makeAutoObservable(this);
  }

  public get isDrawEnabled() {
    return this._isDrawEnabled;
  }

  public set isDrawEnabled(value) {
    this._isDrawEnabled = value;
  }

  public get isEditEnabled() {
    return this._isEditEnabled;
  }

  public set isEditEnabled(value) {
    this._isEditEnabled = value;
  }
}

export default MapDrawerStore;
