import styled, { css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Wrapper = styled.div<{ $isLast: boolean; $isHighlighted: boolean }>`
  transition: all 0.3s ease-in-out;

  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  grid-gap: 12px;

  padding: 12px 8px;

  border-bottom: 1px solid #e9e6f0;

  ${({ $isLast }) => {
    if (!$isLast) return;

    return css`
      padding-bottom: 8px;
      border-bottom: none;
    `;
  }};

  ${({ $isHighlighted }) => {
    if (!$isHighlighted) return;

    return css`
      background-color: ${Colors.lightSky};
      border-radius: 12px;
      border-bottom: none;
    `;
  }};
`;

const StyledStoRegistryListItem = {
  Wrapper,
};

export default StyledStoRegistryListItem;
