import styled from 'styled-components';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
});

const Styles = {
  Wrapper,
};

export default Styles;
