import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Field } from '../../../../../../../../api/models/field.model';
import { useWarningBeforeDeleting } from '../../../../../../../shared/hooks';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ListWrapper } from '../../../../components';
import EditableListItem from '../../../../components/ListItems/EditableListItem/EditableListItem';
import useFieldRoutes from '../../../../hooks/useFieldsRoutes';
import { FieldEditController as Controller } from '../../mobx';

import FieldEditListFooter from './components/Footer/Footer';

interface IProps {
  field: Field | null;
}

const FieldEditList: FC<IProps> = ({ field }) => {
  // Навигация
  const navigate = useNavigate();
  const routes = useFieldRoutes();

  // Контроллеры
  const controller = useStore(Controller);

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

  const handleInputChange = (value: string) => {
    controller.changeFieldName(value);
  };

  const handleDelete = () => {
    showWarningBeforeDeleting(
      'Вы уверены, что хотите удалить поле? Данные поля будут потеряны',
      () => {
        controller.deleteField(field, null).then(() => {
          navigate(routes.fieldsViewRoute);
        });
      }
    );
  };

  return (
    <ListWrapper
      title="Редактирование поля"
      footer={<FieldEditListFooter />}
      dataTestId="fields-edit-section"
    >
      {field && (
        <EditableListItem
          field={field}
          onInputChange={handleInputChange}
          onDeleteClick={handleDelete}
        />
      )}
    </ListWrapper>
  );
};

export default FieldEditList;
