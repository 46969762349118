import { observer } from 'mobx-react';

import { Widget } from '../../../../../../../shared/components';
import { TableBuilder } from '../../../../../../../shared/features/TableBuilder';

import { useHistoryTab } from './hooks';
import Styled from './HistoryTab.style';
import { StoHistoryLoader } from './components';

const HistoryTab = () => {
  const { isLoading } = useHistoryTab();

  return (
    <Widget style={{ padding: '0 0 24px' }}>
      <Styled.TableWrapper>
        <TableBuilder stylePreset="cleared" builderId="sto-history-tab" />
        {isLoading && <StoHistoryLoader />}
      </Styled.TableWrapper>
    </Widget>
  );
};

HistoryTab.displayName = 'HistoryTab';

export default observer(HistoryTab);
