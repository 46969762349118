import { debounce, isEqual } from 'lodash';
import { useCallback } from 'react';

import { FieldAreaFilter } from '../../../../../../../../../utils';
import { IMinMaxRange } from '../../../../../../../../../utils/filters/FieldArea.filter';

type TUpdateInputFn = (transformedValue: IMinMaxRange) => void;

/**
 * Возвращает debounced функцию которая валидирует значение площади и сетает валидное значение в фильтр
 */
function useChangeFilterFn(filter: FieldAreaFilter, debounceMs?: number) {
  const toNumber = (value: IMinMaxRange<string | number>) => {
    return {
      min: Number(value.min || 0),
      max: Number(value.max || 0),
    };
  };

  /**
   * Приводим значения к допустимому диапазону
   * @param value
   * @param minMaxRange - допустимый диапазон из фильтра
   */
  const transformValue = (value: IMinMaxRange<string | number>, minMaxRange: IMinMaxRange) => {
    const { min, max } = toNumber(value);

    return {
      min: min < minMaxRange.min ? minMaxRange.min : min,
      max: max > minMaxRange.max ? minMaxRange.max : max || minMaxRange.max,
    };
  };

  const validateValue = ({ min, max }: IMinMaxRange): IMinMaxRange => {
    if (min > max) {
      return null;
    }

    if (max < min) {
      return null;
    }

    return { min, max };
  };

  return useCallback(
    debounce((value: IMinMaxRange<string | number>, inputValueUpdater: TUpdateInputFn) => {
      const transformedValue = transformValue(value, filter?.minMaxRange);
      const validatedValue = validateValue(transformedValue);

      // Меняем значение инпута в случае если трансформированное значение отличается от текущего
      if (!isEqual(toNumber(value), transformedValue)) {
        inputValueUpdater(transformedValue);
      }

      // пропускаем фильтрацию если значение невалидно
      if (!filter || !validatedValue) {
        return;
      }

      filter.setValue(validatedValue);
    }, debounceMs ?? 500),
    [filter, filter?.minMaxRange, debounceMs]
  );
}

export default useChangeFilterFn;
