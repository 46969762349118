import { FC, PropsWithChildren } from 'react';

import Styled from './TableElementDrawerBuilder.styles';

interface IProps {
  onClose: () => void;
  header?: JSX.Element;
  content?: JSX.Element;
  footer?: JSX.Element;
}

const TableElementDrawerBuilder: FC<PropsWithChildren<IProps>> = ({
  header,
  content,
  footer,
  children,
  onClose,
}) => {
  if (!children) {
    return (
      <Styled.Overlay onClick={onClose}>
        <Styled.Wrapper onClick={e => e.stopPropagation()}>
          {header}
          {content}
          {footer}
        </Styled.Wrapper>
      </Styled.Overlay>
    );
  }

  return (
    <Styled.Overlay onClick={onClose}>
      <Styled.Wrapper onClick={e => e.stopPropagation()}>{children}</Styled.Wrapper>
    </Styled.Overlay>
  );
};

TableElementDrawerBuilder.displayName = 'TableElementDrawerBuilder';

export default TableElementDrawerBuilder;
