import { Checkbox } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  margin-top: 24px;
  justify-content: center;
`;

const FooterWrapper = styled('div')`
  margin-top: 24px;
`;

export default {
  FooterWrapper,
  Checkbox: StyledCheckbox,
};
