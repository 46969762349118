import { FC } from 'react';
import { observer } from 'mobx-react';

import { useComparisonTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { ComparisonTableBuilderStore as Store } from '../../../mobx/stores';
import { useComparisonTableBuilderContext as useContext } from '../../../context';
import { ComparisonTableBuilderRowContainer as RowContainer } from '../ComparisonTableBuilderRowContainer';
import { useStore } from '../../../../../utils/IoC';
import { useSortBy } from '../../../../utils/hooks/sort';

import Styled from './ComparisonTableBuilderRowsContainer.styles';

interface IProps {
  rowsGroupId: string;
  rowIdList: string[];
}

const ComparisonTableBuilderRowsContainer: FC<IProps> = ({ rowsGroupId }) => {
  const store = useStore(Store);

  const context = useContext();

  const rowList = store.getRowList(context.builderId, { byRowsGroupId: rowsGroupId });
  const orderedRowList = useSortBy(rowList);

  const getDataTestId = useDataTestId({ componentName: 'rows' });

  return (
    <Styled.Wrapper {...getDataTestId()}>
      {orderedRowList.map(row => (
        <RowContainer key={row.id} row={row} />
      ))}
    </Styled.Wrapper>
  );
};

ComparisonTableBuilderRowsContainer.displayName = 'ComparisonTableBuilderRowsContainer';

export default observer(ComparisonTableBuilderRowsContainer);
