import L from 'leaflet';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { IRemoveManyOptions } from '../../../models';
import { BaseImageOverlay } from '../../../utils/MapElements';
import MapCoreStore from '../../MapCore/stores/MapCore.store';
import MapImageOverlayStore from '../stores/MapImageOverlay.store';

@provide.transient()
class MapImageOverlayService {
  @lazyInject(MapCoreStore)
  private coreStore: MapCoreStore;

  @lazyInject(MapImageOverlayStore)
  private mapImageOverlayStore: MapImageOverlayStore;

  public create(...args: Parameters<typeof L.imageOverlay>): BaseImageOverlay {
    const layer = new BaseImageOverlay(...args);
    layer.addTo(this.coreStore.instance);

    this.mapImageOverlayStore.setImageOverlay(layer);

    return layer;
  }

  removeById = (overlayId: number) => {
    const imageOverlay = this.mapImageOverlayStore.getImageOverlay(overlayId);
    if (!imageOverlay) {
      return;
    }

    imageOverlay.remove();
    this.mapImageOverlayStore.deleteImageOverlay(overlayId);
  };

  removeManyByIds = (idList: number[], options?: IRemoveManyOptions): void => {
    if (options?.isRemoveAll) {
      this.mapImageOverlayStore.imageOverlaysList.forEach(({ id }) => this.removeById(id));
      return;
    }

    idList.forEach(id => this.removeById(id));
  };
}

export default MapImageOverlayService;
