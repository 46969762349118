import { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown } from '@farmlink/farmik-ui';
import {
  ISelectOption,
  TNewDropdownConfig,
} from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoController, StoStore } from '../../../../mobx';

const StoCultureSelect: FC = () => {
  const store = useStore(StoStore);
  const controller = useStore(StoController);

  const [defaultValue] = useState<ISelectOption>(
    store.selectedSto?.culture
      ? { label: store.selectedSto?.culture.name, value: store.selectedSto?.culture.id }
      : null
  );

  const isBlocked = store.isCreateMode === false || store.isViewOnly;

  const handleChange = useCallback((cultureId: string) => {
    if (store?.isCreateMode) {
      controller.updateCrateStoCulture(cultureId ?? '');
    }
  }, []);

  const dropdownConfig: TNewDropdownConfig = {
    field: {
      onChange: handleChange,
      placeholder: 'Выберите культуру',
      defaultValue,
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
          },
        },
      },
    },
    body: {
      optionList: store.cultureOptionList,
    },
    visual: {
      label: 'Культура',
      isBlocked,
    },
    validation: {
      error: {
        errorList: [],
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
    other: {
      dataTestId: 'sto-culture-select',
    },
  };

  return <NewDropdown config={dropdownConfig} />;
};

StoCultureSelect.displayName = 'CultureSelect';

export default observer(StoCultureSelect);
