// @ts-nocheck
/* eslint-disable */

import L from 'leaflet';

import 'Leaflet.Deflate';

import defaultMapOptions from '../constants/map-options.constant';
import { isInViewport } from '../helpers';
import OptimizedLayerGroup from '../MapElements/layerGroups/OptimizedLayerGroup';

interface IDeflatedLayer extends OptimizedLayerGroup {
  // Инстанс маркера который будет установлен вместо слоя
  marker: L.Marker;
  /**
   * Уровень зума при котором полигон долежен быть заменен на маркер.
   * {@link https://github.com/oliverroick/Leaflet.Deflate/blob/master/src/L.Deflate.js#L160 Вычисляется на основе размера полигона}
   */
  zoomThreshold: number;
  zoomState: number;
  /**
   * Указывает на то, является ли слой маркером в данный момент.
   * Если слой является маркером, значит на карте отображен только маркер вместо полигона
   */
  _isMarker: boolean;
}

/**
 * Данное расширение над плагином {@link https://github.com/oliverroick/Leaflet.Deflate L.Deflate}
 * позволяет использовать кластеризацию слоев а также рендер по viewport.
 *
 * Для того чтобы можно было воспользоваться данным слоем, необходимо добавлять в него !только слои которые наследуются от класса OptimizedLayerGroup.
 * OptimizedLayerGroup содержит в себе опции, которые позволяют отключать некоторые функции оптимизации.
 *
 * Запуск механизма оптимизации происходит после срабатывания евента 'moveend' на карте.
 * После срабаывания евента каждый слой рендерится согласно логике viewport(если включено),
 * также слой проверяется на возможность преобразования в маркер с последющей кластеризацией.
 *
 * Все методы представленные в данном классе переопределяют методы класса L.Deflate,
 * исключением являются методы "_optimize", "_optimizeAll", "_toggleLayerToMarker", "_renderByViewport"
 */
L.Deflate.include({
  /**
   * Слой который хранит НЕ кластеризованные слои.
   */
  _conditionalLayer: L.featureGroup([], { pmIgnore: true }),

  /**
   * Данный слой содержит маркеры полигонов/cлоев после их скрытия.
   * Можно передать слой кластеризации (L.markerCluster()) чтобы получить кластеризацию маркеров.
   * Слой инициализируется самим L.Deflate плагином.
   */
  // _featureLayer: L.markerCuster()

  addLayer(layer: IDeflatedLayer) {
    if (!(layer instanceof OptimizedLayerGroup)) {
      console.warn('Слой не наследуется от "OptimizedLayerGroup". Оптимизация невозможна');
      return;
    }

    if (this._map) {
      this.prepLayer(layer);

      this._optimize(layer, this._map.getZoom());
    } else {
      this._needsPrepping.push(layer);
    }
    this._layers[this.getLayerId(layer)] = layer;
  },

  removeLayer(layer: IDeflatedLayer) {
    const layerId = layer in this._layers ? layer : this.getLayerId(layer);

    this._conditionalLayer.removeLayer(this._layers[layerId]);
    if (this._layers[layerId].marker) {
      this._featureLayer.removeLayer(this._layers[layerId].marker);
    }

    delete this._layers[layerId];

    const layerIndex = this._needsPrepping.indexOf(this._layers[layerId]);
    if (layerIndex !== -1) {
      this._needsPrepping.splice(layerIndex, 1);
    }
  },

  clearLayers() {
    this._conditionalLayer.clearLayers();
    this._featureLayer.clearLayers();
    this._layers = [];
  },

  onAdd(map: L.Map) {
    this._conditionalLayer.addTo(map);
    this._featureLayer.addTo(map);

    this._map.on('moveend', this._optimizeAll, this);

    for (let i = 0, len = this._needsPrepping.length; i < len; i += 1) {
      this.addLayer(this._needsPrepping[i]);
    }
    this._needsPrepping = [];
  },

  onRemove(map: L.Map) {
    map.removeLayer(this._conditionalLayer);
    map.removeLayer(this._featureLayer);

    this._map.off('moveend', this._optimizeAll, this);
  },

  /**
   * Основная функция оптимизации
   */
  _optimize(layer: IDeflatedLayer, endZoom: number) {
    if (!layer || !endZoom) {
      return;
    }

    // Рендерим слой если все оптимизации вылючены. Пропускаем остальную логику
    if (!layer.isClusterable && !layer.isRenderViewport) {
      this._conditionalLayer.addLayer(layer);

      return;
    }

    /**
     * Если опция включена, производим рендер по viewport.
     * Если после рендера полигон был удален, то пропускаем дальнейшую кластеризацию
     */
    const isRendered = this._renderByViewport(layer);
    if (!isRendered) {
      return;
    }

    this._toggleLayerToMarker(layer, endZoom);
  },

  _optimizeAll() {
    const currZoom = this._map.getZoom();

    this.eachLayer(function (layer: IDeflatedLayer) {
      this._optimize(layer, currZoom);
    }, this);
  },

  _toggleLayerToMarker(layer: IDeflatedLayer, currZoom: number): void {
    if (!layer.isClusterable || !layer.marker || currZoom === layer.zoomState) {
      return;
    }

    // При максимальном зуме убираем кластер. https://digitalagrodev.atlassian.net/browse/H15-5326
    if (currZoom === defaultMapOptions.maxZoom && layer._isMarker) {
      this._featureLayer.removeLayer(layer.marker);
      this._conditionalLayer.addLayer(layer);
      layer._isMarker = false;

      layer.zoomState = currZoom;

      return;
    }

    const showMarker = currZoom <= layer.zoomThreshold;

    if (showMarker && !layer._isMarker && !layer.skipClustering) {
      this._conditionalLayer.removeLayer(layer);
      this._featureLayer.addLayer(layer.marker);
      layer._isMarker = true;
    }

    if (!showMarker && layer._isMarker) {
      this._featureLayer.removeLayer(layer.marker);
      this._conditionalLayer.addLayer(layer);
      layer._isMarker = false;
    }

    layer.zoomState = currZoom;
  },

  _renderByViewport(layer: IDeflatedLayer): boolean {
    if (!layer.isRenderViewport) {
      return true;
    }

    const isLayerInViewport = isInViewport(this._map, layer);

    // Если элемент вне viewport карты, то просто удаляем его
    if (!isLayerInViewport) {
      this._conditionalLayer.removeLayer(layer);
    }

    // Если элемент внутри viewport, и при этом он не является маркером
    if (isLayerInViewport && !layer._isMarker) {
      this._conditionalLayer.addLayer(layer);
    }

    return isLayerInViewport;
  },
});
