import { ReactNode } from 'react';

enum ETaskTabs {
  Summary = 'Summary',
  Map = 'Map',
  Checklists = 'Checklists',
  Report = 'Report',
  History = 'History',
  EditPoint = 'EditPoint',
}

interface ITaskTab {
  id: string;
  route: string;
  tabHeaderContent: ReactNode;
  content: ReactNode;

  isDisabled?: boolean;
  isHided?: boolean;

  onSelectTab?: (id: string, tab?: ITaskTab) => void;
}
export type { ITaskTab };

export { ETaskTabs };
