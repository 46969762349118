import { Layer } from 'leaflet';
import moment from 'moment';

import { MapImageOverlayController } from '../../../../../../../../../../../shared/features/map/modules';
import { IndexModel } from '../../../../../../../../../../../../api/models/indices.model';
import { IFillStrategy } from '../../../../interfaces/FillStrategy.model';
import findNearestIndexByMonth from '../../utils/helpers/findNearestIndexByMonth';
import { lazyInject } from '../../../../../../../../../../utils/IoC';
import ControlsService from '../services/Controls.service';
import Service from '../services/IndicesAPI.service';
import IndicesStore from '../stores/Indices.store';
import type {
  TIndexType,
  TPayload,
  TLayerWithBounds,
} from '../../interfaces/IndicesFill.interface';

class IndicesFillStrategy implements IFillStrategy {
  @lazyInject(IndicesStore)
  private store: IndicesStore;

  @lazyInject(ControlsService)
  private controlsService: ControlsService;

  @lazyInject(MapImageOverlayController)
  private mapImageOverlayController: MapImageOverlayController;

  @lazyInject(Service)
  private APIService: Service;

  private readonly layer: Layer;

  // Используется для предотвращения заливки индекса когда мы завершили стратегию раньше чем выполнился запрос на бэк
  private skipFill = false;

  public isInvalid = false;

  constructor(indexName: TIndexType, payload: TPayload, layer: Layer) {
    this.layer = layer;

    this.store.fetchArgs = { indexName, fieldId: payload?.id, year: payload?.seasonYear };
  }

  public async initialize() {
    if (!this.layer) {
      this.isInvalid = true;
      return;
    }

    this.layer?.closeTooltip();

    // Добавляем легенду на карту
    this.controlsService.addControls();

    // Фетчим список годов с бэка
    const years = await this.fetchYears();

    if (years.length) {
      const lastYear = years[years.length - 1];

      this.store.selectedYear = lastYear;
      await this.fetchIndices(lastYear);
    }
  }

  public reset(): void {
    this.skipFill = true;
    this.removePrevIndex();

    // Удаляем легенду с карты
    this.controlsService.removeControls();

    this.store.clear();
  }

  public fetchYears() {
    const response = this.APIService.fetchIndicesYears(this.store.fetchArgs);

    return response.then(years => {
      this.store.years = years;

      return years;
    });
  }

  public async fetchIndices(currentYear: number): Promise<void> {
    this.store.isLoading = true;

    const response = this.APIService.fetchIndices({
      ...this.store.fetchArgs,
      fromDate: `${currentYear}-01-01`,
      toDate: `${currentYear}-12-31`,
    });

    await response
      .then(indices => {
        if (this.skipFill) {
          return;
        }

        this.store.indices = indices;

        const nearestIndex = findNearestIndexByMonth(
          indices,
          moment(this.store.selectedIndex?.toDate)
        );

        this.fillLayer(nearestIndex);
      })
      .finally(() => {
        this.store.isLoading = false;
      });
  }

  /**
   * Заливает выбранный полигон(this.layer) новым индексом.
   * Перед заливкой удаляет с карты предыдущий индекс
   * @param index - индекс для заливки
   */
  public fillLayer(index: IndexModel): void {
    this.removePrevIndex();

    if (!index || !isLayerValid(this.layer)) {
      return;
    }

    const imageOverlay = this.mapImageOverlayController.display(
      this.APIService.buildVisImageUrl(index.visImage),
      this.layer.initialBounds
    );

    this.store.displayedOverlay = imageOverlay;
    this.store.selectedIndex = index;
  }

  private removePrevIndex(): void {
    if (!this.store.displayedOverlay) {
      return;
    }

    this.mapImageOverlayController.remove(this.store.displayedOverlay.id);
    this.store.displayedOverlay = null;
  }
}

const isLayerValid = (layer: any): layer is TLayerWithBounds => layer?.initialBounds;

export default IndicesFillStrategy;
