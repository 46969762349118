import { lazyInject, provide } from '../../../../utils/IoC';
import { Axios, TypeApiRequest, TypeApiResponse } from '../../../../utils/axios2';

@provide.singleton()
class SeasonsService {
  @lazyInject(Axios)
  protected axiosService: Axios;

  public getCultureList = async (
    payload: TypeApiRequest<'getSeasonCulture'>
  ): Promise<TypeApiResponse<'getSeasonCulture'>> => {
    try {
      const seasonCulture = await this.axiosService.api.getSeasonCulture(payload);

      return seasonCulture;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default SeasonsService;
