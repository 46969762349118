import { ReactNode } from 'react';

import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TaskStatuses } from '../../../../../models/TaskStatuses/TaskStatuses.enum';
import {
  TasksFiltersConfigsService as ConfigsService,
  TasksFiltersOptionsService as OptionsService,
} from '../../services';
import { TableFiltersBuilderController as FiltersBuilderController } from '../../../../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { taskLabels } from '../../../../../stores/tasks.store';
import { ITasksFilters } from '../../../models';
import { ETaskType } from '../../../../../../../../../api/models/as-fields/task/task.model';

@provide.transient()
class TasksFiltersController {
  @lazyInject(ConfigsService)
  protected configsService: ConfigsService;

  @lazyInject(OptionsService)
  protected optionsService: OptionsService;

  @lazyInject(FiltersBuilderController)
  protected filtersBuilderController: FiltersBuilderController<ITasksFilters>;

  initiateFilters = (ContextualHelpIcon: ReactNode): void => {
    const config = this.configsService.createConfig(ContextualHelpIcon);

    this.filtersBuilderController.initiateConfig(config);
  };

  addStatusSelectOptionList = () => {
    const statusOptionList: ISelectOption[] = [
      {
        value: TaskStatuses.New,
        label: taskLabels[TaskStatuses.New],
      },
      {
        value: TaskStatuses.InWork,
        label: taskLabels[TaskStatuses.InWork],
      },
      {
        value: TaskStatuses.Completed,
        label: taskLabels[TaskStatuses.Completed],
      },
      {
        value: TaskStatuses.Canceled,
        label: taskLabels[TaskStatuses.Canceled],
      },
    ];

    this.filtersBuilderController.addSelectOptionList('tasks', 'status', statusOptionList);
  };

  public addTypesSelectOptionList = () => {
    const typeOptionList: ISelectOption[] = [
      {
        value: ETaskType.Monitoring,
        label: 'Осмотр',
      },
      {
        value: ETaskType.Operation,
        label: 'Регистрация',
      },
    ];

    this.filtersBuilderController.addSelectOptionList('tasks', 'typeList', typeOptionList);
  };

  createAssigneeSelectOptionList: OptionsService['createAssigneeOptionList'] = async organizationId => {
    const optionList = await this.optionsService.createAssigneeOptionList(organizationId);

    return optionList;
  };
}

export default TasksFiltersController;
