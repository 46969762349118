import { TypeApiRoute } from '../../../models/type.api.request';
import { CultureZone } from '../../../models/field.model';
import { IResponseList } from '../../../models/common/response';

interface IRequest {
  seasonYear: number;
  organizationId?: string;
  operationId?: string;
  cultureId?: string;
  sort?: string;
  size?: number;
}

type TResponse = IResponseList<CultureZone>;

export const getCultureZoneListBySto: TypeApiRoute & {
  request: IRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/fields/cultureZones/listBySto`,
  method: 'GET',
  headers: {},
  request: {} as IRequest,
  response: {} as TResponse,
};
