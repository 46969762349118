import React, { FC } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Icon } from '@farmlink/farmik-ui';

import { WeatherConditionToIcon } from '../../utils/helpers/WeatherConditionToIcon.helper';
import { WindDirectionEngToRus } from '../../utils/helpers/WindDirectionEngToRus.helper';
import getCurrentGeoPoint from '../../utils/helpers/GetCurrentGeoPoint.helper';
import WeatherEngToRus from '../../utils/helpers/WeatherEngToRus.helper';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { WeatherStore } from '../../mobx';

import BackButton from './components/BackButton/BackButton';
import winddirection_img from './assets/winddirection.svg';
import windspeed_img from './assets/windspeed.svg';
import humidity_img from './assets/humidity.svg';
import pressure_img from './assets/pressure.svg';
import thunder_img from './assets/thunder.svg';
import sunrise_img from './assets/sunrise.svg';
import wind_gust from './assets/windgust.svg';
import sunset_img from './assets/sunset.svg';
import Styled from './FieldWeather.styles';

const FieldWeather: FC = () => {
  const store = useStore(WeatherStore);

  const today = store.fieldWeather;

  const precTypeMap = (id: number) => {
    return ['без осадков', 'дождь', 'дождь со снегом', 'снег', 'град'][Number(id)];
  };

  const nowIsDayTime = (sr: string, ss: string): boolean => {
    const now = moment({
      h: new Date().getHours(),
      m: new Date().getMinutes(),
    });
    if (!/^\d{2}:\d{2}$/.test(sr) || !/^\d{2}:\d{2}$/.test(ss)) return;
    const _sunrise = moment({
      h: Number(sr.split(':')[0]),
      m: Number(sr.split(':')[1]),
    });
    const _sunset = moment({
      h: Number(ss.split(':')[0]),
      m: Number(ss.split(':')[1]),
    });
    return now.isBetween(_sunrise, _sunset);
  };

  return (
    <Styled.Wrapper data-test-id={'weather-widget-full'}>
      <Styled.Header>
        <Styled.Title>{WeatherEngToRus(today?.fact?.condition)}</Styled.Title>

        <BackButton />
      </Styled.Header>

      <Styled.Content>
        <Styled.Panel className="left_panel">
          <div className="geopoint">
            <Icon icon={'mapNavigation'} size={'medium'} />
            <Styled.GeoPointLabel>{getCurrentGeoPoint(today?.geoObject)}</Styled.GeoPointLabel>
          </div>
          <div className="sunset_sunrise">
            <div className="sunrise">
              <img src={sunrise_img} alt="" />
              {today?.forecasts[0]?.sunrise ? today?.forecasts[0]?.sunrise : '–'}
            </div>
            <div className="sunset">
              <img src={sunset_img} alt="" />
              {today?.forecasts[0]?.sunset ? today?.forecasts[0]?.sunset : '–'}
            </div>
          </div>
          <div className="condition">
            <img
              src={WeatherConditionToIcon(
                today?.fact?.condition,
                nowIsDayTime(today?.forecasts[0]?.sunrise, today?.forecasts[0]?.sunset) ? 12 : 0
              )}
              alt=""
            />
            <div className="temperature">
              <div className="temperature_current">{today?.fact?.temp}°С</div>
              <div className="temperature_feelslike">Ощущается как {today?.fact?.feelsLike}°С</div>
            </div>
          </div>
        </Styled.Panel>

        <Styled.Panel className={'right_panel for-weather-page'}>
          <div className="current_state">
            <div className="label">
              <img src={humidity_img} alt="" />
              <span>Влажность</span>
            </div>
            <div className="value">{today?.fact?.humidity} %</div>
          </div>
          <div className="current_state">
            <div className="label">
              <img src={thunder_img} alt="" />
              <span>Осадки</span>
            </div>
            <div className="value">{precTypeMap(Number(today?.fact?.precType))}</div>
          </div>
          <div className="current_state">
            <div className="label">
              <img src={pressure_img} alt="" />
              <span>Давление</span>
            </div>
            <div className="value">{today?.fact?.pressureMm} мм рт.ст.</div>
          </div>
          <div className="current_state">
            <div className="label">
              <img src={windspeed_img} alt="" />
              <span>Скорость ветра</span>
            </div>
            <div className="value">{today?.fact?.windSpeed} м/с</div>
          </div>
          <div className="current_state">
            <div className="label">
              <img src={winddirection_img} alt="" />
              <span>Направление ветра</span>
            </div>
            <div className="value">{WindDirectionEngToRus(today?.fact?.windDirection)}</div>
          </div>
          <div className="current_state">
            <div className="label">
              <img src={wind_gust} alt="" />
              <span>Порывы ветра</span>
            </div>
            <div className="value">{today?.fact?.windGust} м/с</div>
          </div>
        </Styled.Panel>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default observer(FieldWeather);
