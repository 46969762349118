import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { DropdownButton, TButtonMultiAction } from '@farmlink/farmik-ui';
import { useLocation } from 'react-router-dom';

import {
  useTasksRouteActions,
  useTasksTaskOperationRoutes as useTaskOperationRoutes,
} from '../../../../../tasks/hooks';
import { ETasksContextualReturnBackButtonText } from '../../../../../tasks/constants';

const OperationsFiltersActionButton: FC = () => {
  const location = useLocation();
  const routeActions = useTasksRouteActions();
  const taskOperationRoutes = useTaskOperationRoutes();

  const btnActionForCreateOneTask = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Одну',
      onClick: () => {
        routeActions.goToTaskCreateOne({
          backButtonLabel: ETasksContextualReturnBackButtonText.OperationsListing,
          url: location.pathname,
        });
      },
    };
  }, [routeActions.goToTaskCreateOne, location.pathname]);

  const btnActionForCreateMultipleTask = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Группу',
      onClick: () => {
        routeActions.goToTaskCreateGroup({
          backButtonLabel: ETasksContextualReturnBackButtonText.OperationsListing,
          url: location.pathname,
        });
      },
    };
  }, [routeActions.goToTaskCreateGroup, location.pathname]);

  const btnActionForTaskOperationCreate = useMemo<TButtonMultiAction>(() => {
    return {
      title: 'Регистрация работ',
      onClick: () => {
        taskOperationRoutes.goToCommonInfo({
          backButtonLabel: ETasksContextualReturnBackButtonText.OperationsListing,
          url: location.pathname,
        });
      },
    };
  }, [location.pathname, taskOperationRoutes.goToCommonInfo]);

  return (
    <DropdownButton
      dataTestId={'operations__create-tasks'}
      title={'Создать задачу'}
      multiActionList={[
        btnActionForCreateOneTask,
        btnActionForCreateMultipleTask,
        btnActionForTaskOperationCreate,
      ]}
      style={{
        color: 'primary',
        size: 'sm',
        placement: 'bottom',
        width: '170px',
      }}
    />
  );
};

OperationsFiltersActionButton.displayName = 'TasksFiltersActionButton';

export default observer(OperationsFiltersActionButton);
