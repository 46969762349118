import { TypeApiRoute } from '../../../models/type.api.request';
import { IStoChecklistsParamsTable } from '../../../models/as-fields/new/sto';

type TRequest = {
  id: string;
  operationTypeId: string;
  versionId?: string;
};

type TResponse = IStoChecklistsParamsTable;

export const getStoChecklistsParamsTable: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id, operationTypeId }) =>
    `/api/as-fields/sto/${id}/techOperationType/${operationTypeId}/checklists`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
