import React, { FC } from 'react';
import { Typography } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { Container } from '../../../../../../../../../../../../../../../../../../../shared/features/UI/new/Container';
import { TablePlugBuilder } from '../../../../../../../../../../../../../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import Styled from '../../../../../../../../../../../../../../../../containers/StoListContainer/components/StoListNoDataPlug/StoListNoDataPlug.styled';
import { EContainerHeaderType } from '../../../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';

interface IProps {
  isDisabled: boolean;
  dataTestId: string;
  title: string;
  onActionButtonClick: () => void;
}

const StoAttributeUserDictionaryRegistryNoDataPlug: FC<IProps> = ({
  isDisabled,
  dataTestId,
  title,
  onActionButtonClick,
}) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Container
      dataTestId={getDataTestId()['data-test-id']}
      containerStyles={{
        gap: '0px',
      }}
      bodyStyles={{
        margin: '28px 0 8px 0',
      }}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: title,
        },
      }}
    >
      <TablePlugBuilder
        autoRenderConfig={{
          header: { icon: 'app' },
          content: {
            title: (
              <Typography
                dataTestId={getDataTestId('title')['data-test-id']}
                variant={'bodySubAccent'}
                align={'center'}
              >
                В реестре пока нет записей. <br /> Вы можете добавить новую запись самостоятельно
                или обратиться к администратору
              </Typography>
            ),
          },
          footer: {
            renderButton: () => (
              <Styled.Button
                disabled={isDisabled}
                color={'primary'}
                styleType={'outlined'}
                onClick={onActionButtonClick}
                alignment={'center'}
                dataTestId={getDataTestId('add-button')['data-test-id']}
              >
                Добавить
              </Styled.Button>
            ),
          },
        }}
        dataTestId={getDataTestId()['data-test-id']}
      />
    </Container>
  );
};

StoAttributeUserDictionaryRegistryNoDataPlug.displayName =
  'StoAttributeUserDictionaryRegistryNoDataPlug';

export default StoAttributeUserDictionaryRegistryNoDataPlug;
