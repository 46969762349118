import { FC, memo, useCallback, useRef } from 'react';
import { Switcher, Typography } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { ISelectOption } from '../../../../../../../../../../../../types/selectOption';
import { OperationField } from '../../../../../../../../../../../../api/models/operations/operation.field.model';

import Styled from './TaskOperationCreateFieldsListItem.styles';

type TProps = {
  dataTestId: string;
  onSelect: (option: ISelectOption<OperationField>) => void;
  isLast: boolean;
  isSelected: boolean;
  isViewOnly: boolean;
  option: ISelectOption<OperationField>;
};

const TaskOperationCreateFieldsListItem: FC<TProps> = ({
  dataTestId,
  onSelect,
  isLast,
  isSelected,
  isViewOnly,
  option,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleSelect = useCallback((): void => {
    onSelect(option);
  }, [onSelect]);

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()} ref={wrapperRef} $isLast={isLast} $isHighlighted={false}>
      <Switcher
        onChange={handleSelect}
        value={isSelected}
        isBlocked={isViewOnly}
        dataTestId={getDataTestId('switcher')['data-test-id']}
      />

      <Typography
        dataTestId={getDataTestId('name')['data-test-id']}
        variant={'title'}
        color={isSelected ? 'generalDark' : 'secondaryDark'}
      >
        {option.label}
      </Typography>
    </Styled.Wrapper>
  );
};

TaskOperationCreateFieldsListItem.displayName = 'TaskOperationCreateFields';

export default memo(TaskOperationCreateFieldsListItem);
