import React, { FC } from 'react';
import { AutoTooltip, Switcher } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { StoChecklistsCoreController as Controller } from '../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../hooks';
import { StoStore } from '../../../../../../../../mobx';

import Styled from './StoChecklistsChecklistSwitcher.styles';

const StoChecklistsChecklistSwitcher: FC = () => {
  const stoStore = useStore(StoStore);
  const controller = useStore(Controller);

  const params = useStoParams();

  const isChecklistSelected = controller.checkIfChecklistIsSelected(params.checklistId);
  const isSwitcherDisabled = controller.checkIfChecklistSwitcherIsDisabled(params.checklistId);

  const getFormattedStatus = () => {
    if (isChecklistSelected) return 'Включен';
    else return 'Выключен';
  };

  const checkIfTooltipIsDisabled = (): boolean => {
    if (!isSwitcherDisabled) return true;

    return !isChecklistSelected;
  };

  const checkIfSwitcherIsDisabled = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;

    if (isSwitcherDisabled) return true;

    return false;
  };

  const handleChange = (value: boolean): void => {
    controller.changeChecklist(params.checklistId, { id: params.checklistId, isSelected: value });
  };

  return (
    <AutoTooltip
      content={
        'В операции должен быть как минимум один активный чек-лист. Чтобы выключить чек-лист, предварительно включите другой.'
      }
      disabled={checkIfTooltipIsDisabled()}
    >
      <Styled.Wrapper $isSelected={isChecklistSelected} $isDisabled={checkIfSwitcherIsDisabled()}>
        <Styled.Status>{getFormattedStatus()}</Styled.Status>

        <Switcher
          isBlocked={checkIfSwitcherIsDisabled()}
          value={isChecklistSelected}
          onChange={handleChange}
        />
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

StoChecklistsChecklistSwitcher.displayName = 'StoChecklistsChecklistSwitcher';

export default observer(StoChecklistsChecklistSwitcher);
