import { Moment } from 'moment';

import { Axios, TypeApiRequest } from '../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../stores/seasons.store';
import { Task, TasksStore, TaskStatuses, TaskPrioretees } from '../stores/tasks.store';
import { ProfileStore } from '../../profile/stores/ProfileStore';
import { CheckAccessStore } from '../../../../authorization/stores/checkAccess.store';
import { AccessRule } from '../../../../../api/endpoints/user/checkAccessByCodePrefix';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { ITask } from '../../../../../api/models/as-fields/task/task.model';

const taskListPageSize = 20;

@provide.singleton()
export class TasksController {
  @lazyInject(TasksStore)
  protected tasksStore: TasksStore;

  @lazyInject(ProfileStore)
  protected profileStore: ProfileStore;

  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  @lazyInject(CheckAccessStore)
  protected checkAccessStore: CheckAccessStore;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(Axios)
  protected axios: Axios;

  clear() {
    this.tasksStore.selectedTaskId = '';
    this.tasksStore.tasksMap.clear();
    this.tasksStore.pageNumber = 0;
    this.tasksStore.totalPages = 0;
    this.tasksStore.tasksLoaded = false;
    this.tasksStore.tasksLoadedByFilter = false;
    this.tasksStore.clearHighlightedTaskId();
  }

  isTaskAvailableForEdit = (task: Partial<Task>) => {
    const result =
      this.organizationsStore.selectedOrganizationId === 'my' ||
      this.checkAccessStore.accessRules.find(
        (accessRule: AccessRule) => accessRule.code === 'scouting.manageTasks'
      ) ||
      (this.checkAccessStore.accessRules.find(
        (accessRule: AccessRule) => accessRule.code === 'scouting.workWithContractors'
      ) &&
        (this.profileStore.user?.id === task.createdBy.fullName ||
          this.profileStore.user?.id === (task.assigneeId || task.assignee?.id)));
    return result;
  };

  resetPageSettings = () => {
    this.tasksStore.pageNumber = 0;
    this.tasksStore.totalPages = 0;
  };

  async fetchTasks(
    {
      organizationId,
      seassonCultureId,
      operationId,
      fieldId,
      noCulture,
      assigneeId,
      operationTypeId,
      planDateFrom,
      planDateTo,
      statusIn,
      priorityIn,
    }: {
      seassonCultureId: string;
      organizationId?: string;
      operationId?: string;
      fieldId?: string;
      noCulture?: boolean;
      assigneeId?: string;
      operationTypeId?: string;
      planDateFrom?: Moment;
      planDateTo?: Moment;
      statusIn?: TaskStatuses[];
      priorityIn?: Array<TaskPrioretees>;
    },
    concatList?: boolean,
    isDontNeedPageIncreasing?: boolean
  ) {
    try {
      this.tasksStore.loading = true;

      const requestObj: TypeApiRequest<'getTasks'> = {
        organizationId: organizationId === 'my' ? undefined : organizationId,
        cultureId: seassonCultureId
          ? seassonCultureId !== 'emptyCulture'
            ? seassonCultureId
            : undefined
          : undefined,
        operationId: operationId ? operationId : undefined,
        operationTypeId,
        planDateFrom: planDateFrom ? planDateFrom.format('YYYY-MM-DD') : undefined,
        planDateTo: planDateTo ? planDateTo.format('YYYY-MM-DD') : undefined,
        // @ts-ignore
        statusIn: statusIn ? statusIn.join() : undefined,
        fieldId: fieldId ? fieldId : undefined,
        seasonYear: this.seasonsStore.selectedSeason ? this.seasonsStore.selectedSeason : undefined,
        noCulture,
        assigneeId,
        page: this.tasksStore.pageNumber ? this.tasksStore.pageNumber : 0,
        size: taskListPageSize,
        // @ts-ignore
        priorityIn: priorityIn ? priorityIn.join() : undefined,
      };

      const response = await this.axios.api.getTasks(requestObj);

      if (!concatList) {
        this.tasksStore.tasksMap.clear();
      }

      this.tasksStore.totalPages = response.totalPages;

      if (!isDontNeedPageIncreasing) {
        this.tasksStore.pageNumber = concatList ? this.tasksStore.pageNumber + 1 : 1;
      }

      response.content.forEach(task => {
        this.tasksStore.tasksMap.set(task.id, {
          ...task,
        });
      });
      this.tasksStore.loading = false;
      this.tasksStore.tasksLoaded = true;
    } catch (error) {
      this.tasksStore.loading = false;
    }
  }

  public increaseCurrentPage = (): void => {
    this.tasksStore.pageNumber += 1;
  };

  async setStatus(taskId: string, status: TaskStatuses, cancellationReason?: string) {
    try {
      await this.axios.api.setStatus({ taskId, status, cancellationReason });
      const task = this.tasksStore.tasksMap.get(taskId);

      /**
       * Данный костыль создан с той целью, чтобы, после рефакторинга, где используются
       * новые методы, не крашились задачи.
       */
      if (!task) {
        return;
      }

      const newMap = new Map();

      this.tasksStore.tasksMap = this.tasksStore.tasksMap.set(taskId, {
        ...task,
        status,
        cancellationReason,
      });

      [...this.tasksStore.tasksMap].forEach(([key, value]) => {
        newMap.set(key, value);
      });

      this.tasksStore.tasksMap = newMap;
    } finally {
      console.log('status had been updated');
    }
  }

  // Костылированный метод дял поддержания консистентности обновления статусов задач из операций
  updateTaskInList = (task: ITask | Task) => {
    const newMap = new Map();

    this.tasksStore.tasksMap = this.tasksStore.tasksMap.set(task.id, task as Task);

    [...this.tasksStore.tasksMap].forEach(([key, value]) => {
      newMap.set(key, value);
    });

    this.tasksStore.tasksMap = newMap;
  };
}
