import { makeAutoObservable } from 'mobx';
import { sortBy } from 'lodash';

import { provide } from '../../../../../../../../../../../../../../shared/utils/IoC';
import {
  IStoChecklistsAttrsTableAttrUpdate,
  IStoChecklistsAttrsTableChecklistUpdate,
  IStoChecklistsAttrsTableStageUpdate,
  IStoChecklistsParamsTableChecklist,
} from '../../../../../../../../../../../../../../../api/models/as-fields/new/sto';

@provide.singleton()
class StoChecklistsCoreStore {
  private _isLoading = true;

  private _hasChanges = false;

  private _checklistsById: Map<string, IStoChecklistsParamsTableChecklist> = new Map<
    string,
    IStoChecklistsParamsTableChecklist
  >();

  private _checklistsUpdateById: Map<string, IStoChecklistsAttrsTableChecklistUpdate> = new Map<
    string,
    IStoChecklistsAttrsTableChecklistUpdate
  >();

  private _stagesUpdateByIdByChecklistId: Map<
    string,
    Map<string, IStoChecklistsAttrsTableStageUpdate>
  > = new Map<string, Map<string, IStoChecklistsAttrsTableStageUpdate>>();

  private _attrsUpdateByIdByStageIdByChecklistId: Map<
    string,
    Map<string, Map<string, IStoChecklistsAttrsTableAttrUpdate>>
  > = new Map<string, Map<string, Map<string, IStoChecklistsAttrsTableAttrUpdate>>>();

  constructor() {
    makeAutoObservable(this);
  }

  public get stageList() {
    return [...this._stagesUpdateByIdByChecklistId.values()];
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get hasChanges(): boolean {
    return this._hasChanges;
  }

  public get checklistList(): IStoChecklistsParamsTableChecklist[] {
    return sortBy([...this._checklistsById.values()], 'name');
  }

  public getChecklist = (id: string): IStoChecklistsParamsTableChecklist => {
    return this._checklistsById.get(id);
  };

  public setHasChanges = (value: boolean): void => {
    this._hasChanges = value;
  };
  public getChecklistUpdate = (checklistId: string): IStoChecklistsAttrsTableChecklistUpdate => {
    return this._checklistsUpdateById.get(checklistId);
  };

  public getAttrUpdate = (
    checklistId: string,
    stageId: string,
    attrId: string
  ): IStoChecklistsAttrsTableAttrUpdate => {
    return this._attrsUpdateByIdByStageIdByChecklistId.get(checklistId)?.get(stageId)?.get(attrId);
  };

  public get checklistUpdateList(): IStoChecklistsAttrsTableChecklistUpdate[] {
    return [...this._checklistsUpdateById.values()];
  }

  public getStageUpdateList = (checklistId: string): IStoChecklistsAttrsTableStageUpdate[] => {
    const stages = this._stagesUpdateByIdByChecklistId.get(checklistId);

    if (!stages) {
      return [];
    }

    return [...stages.values()];
  };

  public getAttrUpdateList = (
    checklistId: string,
    stageId: string
  ): IStoChecklistsAttrsTableAttrUpdate[] => {
    const attrs = this._attrsUpdateByIdByStageIdByChecklistId.get(checklistId)?.get?.(stageId);

    if (!attrs) {
      return [];
    }

    return [...attrs.values()];
  };

  public hasChecklistUpdate = (checklistId: string): boolean => {
    return this._checklistsUpdateById.has(checklistId);
  };

  public hasStageUpdate = (checklistId: string, stageId: string): boolean => {
    return this._stagesUpdateByIdByChecklistId.get(checklistId)?.has(stageId);
  };

  public hasStageUpdateList = (checklistId: string): boolean => {
    return this._stagesUpdateByIdByChecklistId.has(checklistId);
  };

  public hasAttrUpdateList = (checklistId: string, stageId: string): boolean => {
    return this._attrsUpdateByIdByStageIdByChecklistId.get(checklistId)?.has?.(stageId);
  };

  public setIsLoading = (value: boolean): void => {
    this._isLoading = value;
  };

  public setChecklistList = (checklistList: IStoChecklistsParamsTableChecklist[]): void => {
    checklistList.forEach(checklist => {
      this._checklistsById.set(checklist.id, checklist);
    });
  };

  setChecklistUpdate = (
    checklistUpdate: IStoChecklistsAttrsTableChecklistUpdate,
    options?: {
      isUpdate?: boolean;
    }
  ): void => {
    if (options?.isUpdate) {
      const previouslyAdded = this._checklistsUpdateById.get(checklistUpdate.id);

      if (!previouslyAdded) {
        this._checklistsUpdateById.set(checklistUpdate.id, checklistUpdate);
      } else {
        this._checklistsUpdateById.set(checklistUpdate.id, {
          ...previouslyAdded,
          ...checklistUpdate,
        });
      }

      return;
    }

    this._checklistsUpdateById.set(checklistUpdate.id, checklistUpdate);
  };

  setStageUpdate = (
    checklistId: string,
    stageUpdate: IStoChecklistsAttrsTableStageUpdate,
    options?: {
      isUpdate?: boolean;
    }
  ): void => {
    const stages = this._stagesUpdateByIdByChecklistId.get(checklistId);

    if (!stages) {
      const collection: Map<string, IStoChecklistsAttrsTableStageUpdate> = new Map();
      collection.set(stageUpdate.id, stageUpdate);

      this._stagesUpdateByIdByChecklistId.set(checklistId, collection);

      return;
    }

    if (options?.isUpdate) {
      const previouslyAddedStep = stages.get(stageUpdate.id);

      if (!previouslyAddedStep) {
        this._stagesUpdateByIdByChecklistId.get(checklistId).set(stageUpdate.id, stageUpdate);
      } else {
        this._stagesUpdateByIdByChecklistId.get(checklistId).set(stageUpdate.id, {
          ...previouslyAddedStep,
          ...stageUpdate,
        });
      }

      return;
    }

    this._stagesUpdateByIdByChecklistId.get(checklistId).set(stageUpdate.id, stageUpdate);
  };

  setAttrUpdate = (
    checklistId: string,
    stageId: string,
    attrUpdate: IStoChecklistsAttrsTableAttrUpdate,
    options?: {
      isUpdate?: boolean;
    }
  ): void => {
    const stages = this._attrsUpdateByIdByStageIdByChecklistId.get(checklistId);

    if (!stages) {
      const attrCollection: Map<string, IStoChecklistsAttrsTableAttrUpdate> = new Map();
      attrCollection.set(attrUpdate.id, attrUpdate);

      const stageCollection: Map<
        string,
        Map<string, IStoChecklistsAttrsTableAttrUpdate>
      > = new Map();
      stageCollection.set(stageId, attrCollection);

      this._attrsUpdateByIdByStageIdByChecklistId.set(checklistId, stageCollection);

      return;
    }

    const attrs = this._attrsUpdateByIdByStageIdByChecklistId.get(checklistId).get(stageId);

    if (!attrs) {
      const attrCollection: Map<string, IStoChecklistsAttrsTableAttrUpdate> = new Map();
      attrCollection.set(attrUpdate.id, attrUpdate);

      this._attrsUpdateByIdByStageIdByChecklistId.get(checklistId).set(stageId, attrCollection);

      return;
    }

    if (options?.isUpdate) {
      const previouslyAddedItem = attrs.get(attrUpdate.id);

      if (!previouslyAddedItem) {
        this._attrsUpdateByIdByStageIdByChecklistId
          .get(checklistId)
          .get(stageId)
          .set(attrUpdate.id, attrUpdate);
      } else {
        this._attrsUpdateByIdByStageIdByChecklistId
          .get(checklistId)
          .get(stageId)
          .set(attrUpdate.id, { ...previouslyAddedItem, ...attrUpdate });
      }

      return;
    }

    this._attrsUpdateByIdByStageIdByChecklistId
      .get(checklistId)
      .get(stageId)
      .set(attrUpdate.id, attrUpdate);
  };

  public clearIsLoading = (): void => {
    this._isLoading = true;
  };

  public clearHasChanges = (): void => {
    this._hasChanges = false;
  };

  public clearChecklistsById = (): void => {
    this._checklistsById.clear();
  };

  public clearChecklistsUpdateById = (): void => {
    this._checklistsUpdateById.clear();
  };

  public clearStagesUpdateByIdByChecklistId = (): void => {
    this._stagesUpdateByIdByChecklistId.clear();
  };

  public clearAttrsUpdateByIdByStageIdByChecklistId = (): void => {
    this._attrsUpdateByIdByStageIdByChecklistId.clear();
  };
}

export default StoChecklistsCoreStore;
