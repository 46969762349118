import styled from 'styled-components';

const Title = styled.div`
  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
`;

const Header = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #151f32; ;
`;

const Wrapper = styled.div``;

const StyledStoChecklistsRowsGroup = {
  Title,
  Header,
  Wrapper,
};

export default StyledStoChecklistsRowsGroup;
