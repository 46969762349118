import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('section')({});

const InputsContainer = styled('div')({
  display: 'flex',
  gap: ESpaceSize.S,
});

const Styles = {
  Wrapper,
  InputsContainer,
};

export default Styles;
