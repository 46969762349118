import { createContext } from 'react';

export interface IContainerContext {
  dataTestId: string;
}

const defaultValue: IContainerContext = {
  dataTestId: '',
};

const ContainerContext = createContext<IContainerContext>(defaultValue);

export default ContainerContext;
