import styled, { css } from 'styled-components';

import { IWidgetProps } from './Widget';

const Wrapper = styled.section<IWidgetProps>`
  background-color: #ffffff;
  border-radius: 16px;
  width: 100%;
  max-width: 912px;
  max-height: 100%;
  margin: 0;
  padding: 24px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  ${({ $displayCentered }) =>
    $displayCentered &&
    css`
      margin: 0 auto;
    `}
`;

const Header = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e6f0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span<{ $smallTitle: boolean }>`
  font-weight: 600;
  font-size: ${({ $smallTitle }) => ($smallTitle ? '16px' : '24px')};
  line-height: 32px;
`;

const Styled = {
  Wrapper,
  Header,
  Title,
};

export default Styled;
