import { FC } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

interface IProps {
  dataTestId: string;
  value: string;
}

const TaskOperationViewAssignee: FC<IProps> = ({ dataTestId, value }) => {
  if (!value) return null;

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      defaultValue: { label: value, value: 'assignee' },
      onChange: null,
    },
    body: {
      optionList: [],
    },
    visual: {
      isBlocked: true,
      label: 'Исполнитель',
      isLoading: false,
    },

    other: {
      dataTestId,
    },
  };

  return <NewDropdown config={dropdownConfig} />;
};

TaskOperationViewAssignee.displayName = 'TaskOperationViewAssignee';

export default observer(TaskOperationViewAssignee);
