import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';
import { FieldSeasonsModel } from '../../models/field.seasons.model';

type TypeRequest = {
  fieldId: string;
  includeAllSeasons?: boolean;
  includeStoInfo?: boolean;
};

type TypeResponse = {
  content: Array<FieldSeasonsModel>;
} & TypeResponsePageable;

export const getFieldSeasonsById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: request => `/api/as-fields/fields/seasonInfo/${request.fieldId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
