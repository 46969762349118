import { AutoTooltip } from '@farmlink/farmik-ui';
import React, { FC } from 'react';

import { CultureZone } from '../../../../../../../../../../../../../../../../../../api/models/field.model';

import Styled from './ExpIcon.styles';

interface IProps {
  cultureZone: CultureZone;
}

const ExpIcon: FC<IProps> = ({ cultureZone }) => {
  const experiment = cultureZone.experiment;
  const experimentReady = cultureZone.experimentReady;

  if (!experimentReady) {
    return (
      <AutoTooltip content={'Не доступен для опыта'}>
        <Styled.NotInExpIcon />
      </AutoTooltip>
    );
  }

  if (!experiment?.experimentId) {
    return (
      <AutoTooltip content={'Тип участка: не определен \n Доступен для опыта'}>
        <Styled.InExpIcon />
      </AutoTooltip>
    );
  }

  if (experiment?.experimentId && experiment?.cultureZoneExperimentType) {
    const expType = experiment.cultureZoneExperimentType;

    const caption = expType === 'EXPERIMENT' ? 'опытный' : 'контрольный';
    const icon = expType === 'EXPERIMENT' ? <Styled.InExpRedIcon /> : <Styled.InExpBlueIcon />;

    return (
      <AutoTooltip content={`Тип участка: ${caption} \n Опыт: ${experiment?.experimentName}`}>
        {icon}
      </AutoTooltip>
    );
  }

  return null;
};

export default ExpIcon;
