import { TypeApiRoute } from '../../../models/type.api.request';

interface IGetStoReportBySeasonPayload {
  organizationId: string;
  includeDrafts: boolean;
  includeDictionaries: boolean;
  signatories: {
    developedBy: IGetStoReportBySeasonPayloadEmployee[];
    approvedBy: IGetStoReportBySeasonPayloadEmployee[];
  };
}
export interface IGetStoReportBySeasonPayloadEmployee {
  title: string;
  name: string;
}

type TRequest = {
  seasonYear: number;
} & IGetStoReportBySeasonPayload;

type TResponse = Blob;

export const getStoReportBySeason: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ seasonYear }) => `/api/as-fields/sto/reportBySeason/${seasonYear}`,
  method: 'POST',
  headers: {},
  responseType: 'blob',
  request: {} as TRequest,
  response: {} as TResponse,
};
