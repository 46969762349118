import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import { IndexModel } from '../../../../../../../../../../../../api/models/indices.model';
import { arrayToMap } from '../../../../../../../../../../utils/helpers/map/arrayToMap';
import { useStore } from '../../../../../../../../../../utils/IoC';
import { IndicesFillStrategy, IndicesStore } from '../../mobx';
import { TimelineSkeleton } from '../TimeLineSkeleton';
import { LayerFillStore } from '../../../../mobx';

import ArrowControls from './components/ArrowControls/ArrowControls';
import YearSelector from './components/YearSelector/YearSelector';
import Calendar from './components/Calendar/Calendar';
import TimelinePlug from './components/Plug/Plug';
import TimelineList from './components/List/List';
import Styled from './Timeline.styles';

const IndicesTimeline: FC = () => {
  // Сторы
  const store = useStore(IndicesStore);
  const layerFillStore = useStore(LayerFillStore);

  const strategy = layerFillStore.strategy as IndicesFillStrategy;

  const dateToIndex = useMemo<Map<string, IndexModel>>(() => {
    return arrayToMap(store.indices, 'fromDate');
  }, [store.indices]);

  const handleItemClick = (index: IndexModel) => {
    strategy.fillLayer(index);
  };

  const handleArrowClick = (type: 'next' | 'prev') => {
    const { indices, selectedIndexPosition: arrayIndex } = store;
    const newArrayIndex = type === 'next' ? arrayIndex - 1 : arrayIndex + 1; // Логика реверснута

    strategy.fillLayer(indices[newArrayIndex]);
  };

  const handleYearChange = (newYear: number) => {
    store.selectedYear = newYear;

    strategy.fetchIndices(newYear);
  };

  const handleRefreshClick = () => {
    strategy.fetchIndices(store.selectedYear);
  };

  if (store.isLoading) {
    return <TimelineSkeleton />;
  }

  if (!store.hasIndices) {
    return <TimelinePlug onRefreshClick={handleRefreshClick} />;
  }

  return (
    <Styled.Wrapper>
      <Styled.TimelineWrapper data-test-id="map-timeline-wrapper">
        {store.years.length > 0 && (
          <YearSelector
            options={store.years}
            defaultValue={store.selectedYear}
            onYearChange={handleYearChange}
          />
        )}

        <TimelineList onItemClick={handleItemClick} />

        <ArrowControls
          indicesList={store.indices}
          selectedIndex={store.selectedIndex}
          onLeftClick={() => handleArrowClick('prev')}
          onRightClick={() => handleArrowClick('next')}
        />
      </Styled.TimelineWrapper>

      {store.years.length > 0 && (
        <Calendar
          value={store.selectedIndex?.fromDate}
          dateMap={dateToIndex}
          selectDay={handleItemClick}
          onYearChange={handleYearChange}
          availableYearList={store.years}
        />
      )}
    </Styled.Wrapper>
  );
};

export default observer(IndicesTimeline);
