import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-end;
`;

const Styled = {
  Wrapper,
};

export default Styled;
