import styled, { css } from 'styled-components';

type TColor = 'green' | 'yellow' | 'red';

const getColor = (color: TColor): string => {
  switch (color) {
    case 'green':
      return '#00945D';

    case 'yellow':
      return '#FBAD3A';

    case 'red':
      return '#DF2E22';

    default:
  }
};

const Error = styled.div`
  color: #df2e22;
  font-feature-settings: 'clig' off, 'liga' off;
  /* body: reg14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
`;

const Errors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CentralWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const LineWrapper = styled.div`
  padding: 10px 4px;
`;

const Line = styled.div<{ $color: TColor }>`
  height: 2px;

  ${({ $color }) =>
    css`
      background-color: ${getColor($color)};
    `};
`;

const Container = styled.div<{ $isCentral?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ $isCentral }) =>
    $isCentral &&
    css`
      padding: 0 4px;
    `}
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 138px 138px 1fr 138px 138px;
  grid-gap: 8px;
`;

const Wrapper = styled.div``;

const StyledStoAttributeNumericOdz = {
  Error,
  Errors,
  CentralWrapper,
  Line,
  LineWrapper,
  Container,
  Body,
  Wrapper,
};

export default StyledStoAttributeNumericOdz;
