import { Filter, TFilterMemento } from '../../../../../shared/features/FilterManager';
import { Field } from '../../../../../../api/models/field.model';

export enum EFieldInExp {
  ALL = 'ALL',
  IN_EXP = 'IN_EXP',
  NOT_IN_EXP = 'NOT_IN_EXP',
}

class FieldInExpFilter extends Filter<'inExp', EFieldInExp, Field> {
  constructor() {
    super('inExp', EFieldInExp.ALL);
  }

  public get isEmptyValue(): boolean {
    return this.value === EFieldInExp.ALL;
  }

  public resetValue(skipFiltering?) {
    this.setValue(this._defaultValue, skipFiltering);
  }

  public createMemento(): TFilterMemento {
    const { value } = this;

    return {
      restore: skipFiltering => {
        this.setValue(value, skipFiltering);
      },
    };
  }

  public _getFilterRule(el: Field): boolean {
    if (this.value === EFieldInExp.IN_EXP) {
      return el.cultureZones.length && el.cultureZones.some(zone => zone.experiment);
    }

    if (this.value === EFieldInExp.NOT_IN_EXP) {
      return el.cultureZones.every(zone => !zone.experiment);
    }

    return true;
  }
}

export default FieldInExpFilter;
