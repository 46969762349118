import { ReactElement } from 'react';

export enum EMapControlPlace {
  TOP = 'TOP',
  LEFT = 'LEFT',
  BOTTOM = 'BOTTOM',
  RIGHT = 'RIGHT',
}

export type TMapControl<ID = string> = {
  id: ID;
  place: EMapControlPlace;
  component: ReactElement;
  hidden?: boolean;
};
