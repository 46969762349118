import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

import { ReactComponent as GreenDotSvg } from '../../../../assets/greenDot.svg';
import { ReactComponent as InfoDotSvg } from '../../../../assets/infoDot.svg';

const ViewingPoint = styled.div`
  width: 100%;
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.borderGray};
`;

const ViewingPointDescription = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ViewingPointName = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  display: block;
  margin-left: 16px;
  flex-shrink: 0;
`;

const ViewingPointType = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const GreenDot = styled(GreenDotSvg)`
  flex-shrink: 0;
`;

const InfoDot = styled(InfoDotSvg)`
  flex-shrink: 0;
`;

const ViewingPointDate = styled.span`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  flex-shrink: 0;
`;

const StyledViewingPoint = {
  ViewingPoint,
  GreenDot,
  InfoDot,
  ViewingPointDescription,
  ViewingPointName,
  ViewingPointType,
  ViewingPointDate,
};

export default StyledViewingPoint;
