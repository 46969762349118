import { TypeApiRoute } from '../../models/type.api.request';
import { TypeResponsePageable } from '../../models/type.response.pageable';
import { EIndexType, IndexModel } from '../../models/indices.model';
import { EFieldFill } from '../../../modules/dashboard/constants/FieldFill.enum';

type TypeRequest = {
  fieldId: string;
  indexName?: EFieldFill;

  /**
   * Год сезона поля. Используется для определения версии поля. Геометрия этой версии поля будет использоваться для поиска индексов в агроапи.
   * Если не указать этот параметр, то возьмет год текущего сезона.
   *
   * У каждой версии поля может быть свой набор индексов.
   * Пример:
   *  Поле в сезоне 2022 имеет круглую геомтерию и в аагроапи для него имеется 2 индакса
   *  Поле с тем же id в сезоне 2023 имеет квадратную геометрию и в агроапи для него имеется 5 индексов
   */
  year?: number;

  /**
   * Формат: `YYYY-MM-DD`
   * Используется для фильтрации индексов найденных по геометрии поля.
   * Если не передавать fromDate и toDate, то вернет все индексы для переданной версии поля (согласно fieldId и year)
   */
  fromDate?: string;
  /**
   * Формат: `YYYY-MM-DD`
   * Используется для фильтрации индексов найденных по геометрии поля.
   * Если не передавать fromDate и toDate, то вернет все индексы для переданной версии поля (согласно fieldId и year)
   */
  toDate?: string;

  status?: EIndexType[];
};

type TypeResponse = {
  content: Array<IndexModel>;
} & TypeResponsePageable;

export const getIndicesList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-fields/agro/indices/list`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
