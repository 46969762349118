import { useComparisonTableBuilderContext as useContext } from '../../context';
import { useDataTestIdV2 } from '../../../utils/hooks/locators';

interface IConfig {
  tableName?: string;
  componentName?: string;
}

const useComparisonTableBuilderDataTestId = (config?: IConfig) => {
  const UIContext = useContext();

  const tableName = config?.tableName ?? UIContext.tableName;

  const getDataTestId = useDataTestIdV2(
    config?.componentName
      ? `${tableName}-comparison-table__${config.componentName}`
      : `${tableName}-comparison-table`
  );

  return getDataTestId;
};

export default useComparisonTableBuilderDataTestId;
