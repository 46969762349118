import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { EFieldAccessAction } from '../../../../../../../../../../../../../shared/constants/access-rules-action';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { CultureZone } from '../../../../../../../../../../../../../../api/models/field.model';
import useFieldsPermissions from '../../../../../../../../../../hooks/useFieldsPermissions';
import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import {
  FieldCulturesController as CulturesController,
  FieldCulturesStore as CulturesStore,
} from '../../../../../../../../mobx';

import CultureZoneCard from './component/CultureZoneCard/CultureZoneCard';
import AddNewButton from './component/AddNewButton/AddNewButton';
import Styled from './CulturesListContent.styles';

const CulturesListContent: FC = () => {
  // Сторы
  const culturesStore = useStore(CulturesStore);

  // Контроллеры
  const culturesController = useStore(CulturesController);

  const permissions = useFieldsPermissions();
  const getDataTestId = useDataTestIdV2('field-season-card-cultures');

  const handleClick = (zone: CultureZone) => {
    culturesController.highlightCultureZone(zone);
  };

  return (
    <React.Fragment>
      <Styled.ListWrapper {...getDataTestId('wrapper')}>
        {culturesStore.culturesZonesList.map(zone => (
          <CultureZoneCard key={zone.id} cultureZone={zone} onClick={() => handleClick(zone)} />
        ))}
      </Styled.ListWrapper>

      {permissions.hasAccess(EFieldAccessAction.EditCultureZone) && (
        <AddNewButton dataTestId={getDataTestId('add-new-button')} />
      )}
    </React.Fragment>
  );
};

export default observer(CulturesListContent);
