import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { TransferFieldsModal } from './components';

const TRANSFER_FIELD_MODAL_ID = 'transfer-field-modal';

const transferFieldModalConfig: TModalConfig = {
  id: TRANSFER_FIELD_MODAL_ID,
  name: TRANSFER_FIELD_MODAL_ID,
  title: null,
  type: EModalType.Custom,
  styledProps: { $modalPaddings: '60px' },
  children: TransferFieldsModal,
  dataTestId: 'transfer-field-modal',
  denyButton: {
    title: 'Отменить',
    color: 'default',
    dataTestId: 'cancel-sto-field-transfer',
  },
  successButton: {
    title: 'Переназначить',
    color: 'secondary',
    dataTestId: 'accept-sto-field-transfer',
  },
};

export { TRANSFER_FIELD_MODAL_ID, transferFieldModalConfig };
