import { Field } from '../../../../../../../../api/models/field.model';
import { BasePolygon } from '../../../../../../../shared/features/map/utils/MapElements';
import { FieldsApiService } from '../../../../../../../shared/mobx/services/as-fields';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import Store from '../stores/EditFields.store';

@provide.transient()
class EditFieldsApiService extends FieldsApiService {
  @lazyInject(Store)
  private store: Store;

  // Сохраняет редактируемое поле
  public submitField(field: Field) {
    this.store.isLoading = true;

    const promise = this.axios.api.saveFieldById(
      {
        id: field.id,
        name: field.name,
        geoJson: {
          type: 'Feature',
          geometry: field.geometry,
        },
        seasonYear: this.seasonsStore.selectedSeason,
      },
      { omit: ['id', 'seasonYear'] }
    );

    return promise
      .then(() => this.submitCultureZones(field))
      .catch(err => {
        throw new Error(err.response.data.error);
      })
      .finally(() => {
        this.store.isLoading = false;
      });
  }

  // Сохраняет культурные зоны редактируемого поля
  public submitCultureZones(field: Field) {
    return this.axios.api.updateCultureZones({
      fieldId: field.id,
      seasonYear: Number(this.seasonsStore.selectedSeason),
      zones: field.cultureZones.map(zone => {
        return {
          name: zone.name,
          cultureZoneId: zone.id,
          variety: zone.variety,
          cultureId: zone.culture.id,
          geoJson: {
            type: 'Feature',
            geometry: zone.geometry,
          },
          experimentReady: zone.experimentReady,
        };
      }),
    });
  }
}

export default EditFieldsApiService;
