import styled, { CSSProperties } from 'styled-components';

const HeaderWrapper = styled.div`
  padding: 24px 24px 0 24px;
`;

const Body = styled.div<{ $bodyStyles?: CSSProperties }>(({ $bodyStyles }) => ({
  padding: '0 24px 24px 24px',
  ...($bodyStyles ?? {}),
}));

const Wrapper = styled.div<{ $containerStyles?: CSSProperties }>(({ $containerStyles }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  gap: '4px',
  backgroundColor: 'white',
  ...($containerStyles ?? {}),
}));

const StyledContainer = {
  HeaderWrapper,
  Body,
  Wrapper
};

export default StyledContainer;
