import React, { FC, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, useModal } from '@farmlink/farmik-ui';

import { Container } from '../../../../../../../../../shared/features/UI/new/Container';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { EStoDictionaryName as EDictionaryName } from '../../../../../../../../../../api/models/as-fields/new/sto';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoRegistriesStore as Store } from '../../mobx/stores';
import { StoRegistriesController as Controller } from '../../mobx/controllers';
import { ButtonDownload } from '../../../../../../../../../shared/features/UI/new/ButtonDownload';
import { StoStore } from '../../../../mobx';
import { EStoAction } from '../../../../../../../../../../api/models/as-fields/STO/STO.model';
import { useStoParams } from '../../../../../../hooks';
import { SimpleLoader } from '../../../../../../../../../shared/features/UI/new/SimpleLoader';
import { ButtonAdd } from '../../../../../../../../../shared/features/UI/new/ButtonAdd';
import { StoRegistriesAddEntityFromDictionaryModal as AddEntityFromDictionaryModal } from '../../modals';
import { IStoRegistriesAddEntityFromDictionaryModalPayload as IModalPayload } from '../../interfaces';
import { EContainerHeaderType } from '../../../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';
import { ButtonUpload } from '../../../../../../../../../shared/features/UI/new/ButtonUpload';

import {
  StoRegistryList as List,
  StoRegistryLoader as Loader,
  StoRegistryNoDataPlug as NoDataPlug,
  StoRegistrySearch as Search,
} from './components';
import Styled from './StoRegistry.styles';
import { useStoRegistryFromExcelUploader as useFromExcelUploader } from './utils/hooks';

interface IProps {
  title: string;
  dictionaryName: EDictionaryName;
}

const StoRegistry: FC<IProps> = ({ title, dictionaryName }) => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);

  const controller = useStore(Controller);

  const params = useStoParams();
  const modal = useModal();
  const fromExcelUploader = useFromExcelUploader();

  const [isDownloadPending, setIsDownloadPending] = useState<boolean>(false);

  const list = controller.getEntityList(dictionaryName);
  const isLoading = controller.isDictionaryLoading(dictionaryName);

  const isShowDownloadButton = stoStore.selectedSto.availableActions.includes(
    EStoAction.StoDictionaryDownload
  );

  const handleAddFromDictionaryClick = useCallback(() => {
    modal.openModalByModalId(AddEntityFromDictionaryModal.id, {
      dictionaryName,
    } as IModalPayload);
  }, []);

  const getDataTestId = useDataTestIdV2(`sto__${dictionaryName}-registry`);

  const ButtonUploadFromExcel = useMemo(() => {
    if (stoStore.isViewOnly) return null;

    if (fromExcelUploader.isUploadPending) {
      return (
        <Styled.LoaderWrapper {...getDataTestId('upload-loader-wrapper')}>
          <SimpleLoader
            dataTestId={getDataTestId('upload-dictionary_from-excel')['data-test-id']}
            size={24}
          />
        </Styled.LoaderWrapper>
      );
    }

    return (
      <AutoTooltip content={'Загрузить реестр'}>
        <div>
          <Styled.Input
            {...getDataTestId('upload-dictionary_from-excel__input')}
            type="file"
            name="file"
            ref={fromExcelUploader.fileUploaderRef}
            onChange={fromExcelUploader.onChange}
            accept=".xlsx"
          />

          <ButtonUpload
            dataTestId={getDataTestId('upload-dictionary_from-excel')['data-test-id']}
            size={'s32'}
            onClick={fromExcelUploader.onUploaderClick}
          />
        </div>
      </AutoTooltip>
    );
  }, [stoStore.isViewOnly, fromExcelUploader]);

  const ButtonDownloadDictionary = useMemo(() => {
    if (!isShowDownloadButton) return null;

    if (isDownloadPending) {
      return (
        <Styled.LoaderWrapper {...getDataTestId('download-dictionary-loader-wrapper')}>
          <SimpleLoader dataTestId={getDataTestId('')['data-test-id']} size={24} />
        </Styled.LoaderWrapper>
      );
    }

    return (
      <AutoTooltip content={'Скачать реестр'}>
        <div>
          <ButtonDownload
            dataTestId={getDataTestId('download-dictionary')['data-test-id']}
            onClick={() => {
              controller.downloadDictionary(params, dictionaryName, {
                handleLoading: setIsDownloadPending,
              });
            }}
          />
        </div>
      </AutoTooltip>
    );
  }, [isShowDownloadButton, isDownloadPending]);

  const ButtonAddFromDictionary = useMemo(() => {
    if (stoStore.isViewOnly) return null;

    return (
      <AutoTooltip content={'Добавить из справочника'}>
        <div>
          <ButtonAdd
            dataTestId={getDataTestId()['data-test-id']}
            onClick={handleAddFromDictionaryClick}
          />
        </div>
      </AutoTooltip>
    );
  }, [stoStore.isViewOnly, handleAddFromDictionaryClick]);

  if (store.isInitialLoading || isLoading) {
    return <Loader dataTestId={getDataTestId('loader')['data-test-id']} />;
  }

  if (!list.length) {
    return (
      <NoDataPlug
        dataTestId={getDataTestId('no-data-plug')['data-test-id']}
        title={title}
        onAddFromDictionaryClick={handleAddFromDictionaryClick}
      />
    );
  }

  return (
    <Container
      dataTestId={getDataTestId()['data-test-id']}
      containerStyles={{ gap: '16px' }}
      bodyStyles={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        paddingLeft: '16px',
        paddingRight: '16px',
      }}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: title,
        },
        additionalContent: (
          <Styled.ActionList {...getDataTestId('actions')}>
            {ButtonUploadFromExcel}
            {ButtonDownloadDictionary}
            {ButtonAddFromDictionary}
          </Styled.ActionList>
        ),
      }}
    >
      <Search
        dataTestId={getDataTestId('search')['data-test-id']}
        dictionaryName={dictionaryName}
      />

      <List dataTestId={getDataTestId('list')['data-test-id']} dictionaryName={dictionaryName} />
    </Container>
  );
};

export default observer(StoRegistry);
