import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { useTasksParams } from '../../../../hooks';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ProfileStore } from '../../../../../profile/stores/ProfileStore';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { EContainerHeaderType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';
import { Container } from '../../../../../../../shared/features/UI/new/Container';
import { TaskOperationCreateStore as Store } from '../../mobx/stores';
import { TaskOperationCreateController as Controller } from '../../mobx/controllers';

import {
  TaskOperationCreateVerificationCultureName as CultureName,
  TaskOperationCreateVerificationAssigneeName as AssigneeName,
  TaskOperationCreateVerificationDate as Date,
  TaskOperationCreateVerificationOperationName as OperationName,
  TaskOperationCreateVerificationSprayingType as SprayingType,
  TaskOperationCreateVerificationFieldsCount as FieldsCount,
  TaskOperationCreateVerificationTotalArea as TotalArea,
} from './common/components';
import useTaskAssigneeName from './hooks/useTaskAssigneeName';
const TaskOperationCreateVerification: FC = () => {
  const store = useStore(Store);
  const profileStore = useStore(ProfileStore);
  const controller = useStore(Controller);

  const list = store.fieldOptionListToDisplay;
  const selectedIdList = store.selectedFieldIdList;

  const selectedFieldsCount: number = selectedIdList.size;
  const totalFieldsCount: number = list.length;

  const params = useTasksParams();

  const getDataTestId = useDataTestIdV2(`tasks__task-operation-create__verification`);

  const assigneeName = useTaskAssigneeName(
    controller.assigneeDefaultValue.label,
    profileStore.fullName,
    params.orgId
  );

  return (
    <Container
      dataTestId={getDataTestId()['data-test-id']}
      containerStyles={{ gap: '16px' }}
      bodyStyles={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: `Будет создано регистраций: ${selectedFieldsCount}`,
        },
      }}
    >
      <CultureName
        taskCultureName={controller.activeCultureName}
        dataTestId={getDataTestId()['data-test-id']}
      />

      <OperationName
        taskOperationName={controller.activeOperationName}
        dataTestId={getDataTestId()['data-test-id']}
      />

      {store?.taskCreate?.operationValues?.length ? (
        <SprayingType
          taskCreateOperationValues={store?.taskCreate?.operationValues}
          dataTestId={getDataTestId()['data-test-id']}
        />
      ) : null}

      <Date date={store.taskCreate?.planStartDate} dataTestId={getDataTestId()['data-test-id']} />

      <AssigneeName assigneeName={assigneeName} dataTestId={getDataTestId()['data-test-id']} />

      <FieldsCount
        selectedCount={selectedFieldsCount}
        totalCount={totalFieldsCount}
        dataTestId={getDataTestId()['data-test-id']}
      />

      <TotalArea area={controller.totalAreaValue} dataTestId={getDataTestId()['data-test-id']} />
    </Container>
  );
};

TaskOperationCreateVerification.displayName = 'TasksTaskMonitoring';

export default observer(TaskOperationCreateVerification);
