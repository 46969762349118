import { useModal } from '@farmlink/farmik-ui';
import { sortBy } from 'lodash';
import { useParams } from 'react-router';

import { FieldSeasonsModel } from '../../../../../../../api/models/field.seasons.model';
import { ISelectOption } from '../../../../../../../types/selectOption';
import { useStore } from '../../../../../../shared/utils/IoC';
import FieldSeasonsAPIService from '../mobx/services/FieldSeasonsAPI.service';
import { EFieldSeasonsModalName } from '../modals';
import { IFieldsAddStoModalPayload, IFieldsAddStoModalPayloadZoneData } from '../modals/interfaces';

function useStoInfoModal(season: FieldSeasonsModel) {
  // Навигация
  const { fieldId } = useParams();

  // Сторы
  const service = useStore(FieldSeasonsAPIService);

  const modal = useModal();

  const createModalPayload = (modelList: FieldSeasonsModel[]): IFieldsAddStoModalPayload | null => {
    if (!modelList?.length) {
      return null;
    }

    const changedModel = modelList.find(el => el.season.year === season.season.year);
    const changedCultureZonesList = changedModel?.fieldVersion?.cultureZones ?? [];

    if (!changedCultureZonesList?.length) {
      return;
    }

    const zoneDataList = changedCultureZonesList.reduce((list, zone) => {
      if (!zone.availableStoList?.length) {
        return list;
      }

      const optionList: ISelectOption[] = zone.availableStoList.map(sto => ({
        value: sto.stoId,
        label: sto.stoName,
      }));

      const data = {
        zoneId: zone.id,
        stoOptionList: sortBy(optionList, 'label'),
      };

      return [...list, data];
    }, [] as IFieldsAddStoModalPayloadZoneData[]);

    if (!zoneDataList.length) {
      return null;
    }

    return {
      currentZoneId: zoneDataList[0].zoneId,
      zoneDataList,
    };
  };

  const open = () => {
    service
      .fetchAllFieldSeasons(fieldId, true)
      .then(createModalPayload)
      .then(payload => {
        if (payload) {
          modal.openModalByModalId(EFieldSeasonsModalName.fieldAddSto, payload);
        }
      });
  };

  return {
    open,
  };
}

export default useStoInfoModal;
