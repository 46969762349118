import { TypeApiRoute } from '../../../models/type.api.request';
import { ITaskOperationConfig } from '../../../models/as-fields/taskOperationConfig';

interface IRequest {
  operationDictionaryValueId?: string;
}

type TResponse = ITaskOperationConfig[];

export const getTaskOperationConfigList: TypeApiRoute & {
  request: IRequest;
  response: TResponse;
} = {
  url: () => `/api/as-fields/taskOperationConfig`,
  method: 'GET',
  headers: {},
  request: {} as IRequest,
  response: {} as TResponse,
};
