import {
  Colors,
  DropdownMenu,
  ENotificationType,
  Icon,
  TDropdownMenuItem,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { EDashboardExternalRoutes } from '../../../../../../../../../../../../../../../../dashboard.routes';
import CulturesAPIService from '../../../../../../../../../../../../mobx/services/FieldCulturesAPI.service';
import { CultureZone } from '../../../../../../../../../../../../../../../../../../api/models/field.model';
import { EFieldSeasonsModalName as EModalName } from '../../../../../../../../../../../../modals';
import { useStore } from '../../../../../../../../../../../../../../../../../shared/utils/IoC';
import {
  FieldCulturesController as CulturesController,
  FieldSeasonsViewStore as Store,
  FieldCulturesStore as CulturesStore,
} from '../../../../../../../../../../../../mobx';

interface IProps {
  cultureZone: CultureZone;
  onOpen: () => void;
}

const CultureZoneCardDropdownMenu: FC<IProps> = props => {
  const { cultureZone, onOpen } = props;

  // Сторы
  const store = useStore(Store);
  const culturesStore = useStore(CulturesStore);

  // Контроллеры
  const culturesController = useStore(CulturesController);

  // Сервисы
  const culturesAPIService = useStore(CulturesAPIService);

  const modal = useModal();
  const notificator = useNotificator();

  // Указывает что это единственная культурная зона у поля
  const isInitial = culturesStore.culturesZonesList.length === 1;

  const hideContextDeleteButton = () => {
    const [firstCultureZone] = culturesStore.culturesZonesList;

    if (firstCultureZone?.culture?.name || firstCultureZone?.experimentReady) {
      return false;
    }

    return culturesStore.activeField?.area === firstCultureZone?.area;
  };

  const handleEditClick = (closePopupFn: () => void) => {
    culturesController.enableEditCultureZone(cultureZone);
    closePopupFn();
  };

  const handleGoToExperimentClick = (closePopupFn: () => void) => {
    const currentStage = window._env_.CURRENT_STAGE;

    const expBaseUrl = window._env_[`EXPERIMENTS_${currentStage}_URL`] ?? 'http://localhost:3000/';
    const expPath = `${EDashboardExternalRoutes.Experiments}/${cultureZone.experiment?.experimentId}/execution`;

    const link = new URL(expPath, expBaseUrl).href;
    window.open(link, '_self');

    closePopupFn();
  };

  const handleDeleteClick = (closePopupFn: () => void) => {
    const modalId = isInitial ? EModalName.resetField : EModalName.deleteField;

    modal.openModalByModalId(modalId, null, () => {
      culturesStore.makeCultureZonesSnapshot();
      culturesController.deleteCultureZone(cultureZone);

      culturesAPIService
        .saveCultureZones(store.selectedSeason)
        .then(() => {
          notificator.setNotification({
            message: `Данные ${isInitial ? 'Сброшены' : 'Удалены'}`,
            style: {
              type: ENotificationType.Warning,
              placement: 'top-right',
            },
          });
        })
        .catch(() => {
          culturesController.restoreSnapshot();
        });
    });

    closePopupFn();
  };

  const menuItems: TDropdownMenuItem[] = [
    {
      type: 'config',
      text: 'Редактировать',
      icon: <Icon icon={'edit'} size={'medium'} />,
      dataTestId: 'edit-item',
      onClick: handleEditClick,
    },
    {
      type: 'config',
      text: 'Перейти в опыт',
      icon: <Icon icon={'sidebarExperiences'} size={'medium'} />,
      dataTestId: 'go-to-exp-item',
      onClick: handleGoToExperimentClick,
      hide: !cultureZone.experiment?.experimentId,
    },
    {
      type: 'config',
      text: {
        value: isInitial ? 'Cбросить данные' : 'Удалить участок',
        style: { color: Colors.red },
      },
      icon: <Icon icon={'bin'} size={'medium'} fill={Colors.red} />,
      dataTestId: 'remove-item',
      onClick: handleDeleteClick,
      hide: hideContextDeleteButton(),
    },
  ];

  return (
    <DropdownMenu
      popupProps={{
        position: 'right top',
        arrow: false,
        onOpen,
      }}
      menuItems={menuItems}
      dataTestId={'field-season-culture-zone-card-menu'}
    />
  );
};

export default observer(CultureZoneCardDropdownMenu);
