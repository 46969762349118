import Filter from './Filter';

/**
 * Абстрактный класс фильтра с несколькими value, наследуется от базового фильтра.
 * Используется для создания фильтров с мультивыбором фильтруемого значения
 */
abstract class MultiValueFilter<ID extends string, V, E> extends Filter<ID, V[], E> {
  protected constructor(id: ID, defaultValue: V[] = []) {
    super(id, defaultValue);
  }

  public get isEmptyValue(): boolean {
    return !this.value.length;
  }

  public resetValue(skipFiltering?) {
    if (!this.value.length) {
      return;
    }

    this.setValue([], skipFiltering);
  }

  public toggleValue(value: V, skipFiltering = false) {
    if (this.value.includes(value)) {
      this.removeValue(value, skipFiltering);

      return;
    }

    this.addValue(value, skipFiltering);
  }

  public addValue(value: V, skipFiltering = false) {
    this.setValue([...this.value, value], skipFiltering);
  }

  public removeValue(value: V, skipFiltering = false) {
    const newValue = this.value.filter(v => v !== value);
    this.setValue(newValue, skipFiltering);
  }

  /**
   * Для мультифильтра подсчитывается кол-во выбранных значений.
   * В то время как обычный фильтр считает только наличие выбранного значения
   */
  public get selectedCount(): number {
    return this.value.length;
  }
}

export default MultiValueFilter;
