import { Button, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonLoader } from '../../../../../../../../../shared/components/ButtonLoader/ButtonLoader';
import { useWarningBeforeLeavingThePage } from '../../../../../../../../../shared/hooks';
import { FieldsStore } from '../../../../../../../../../shared/mobx/stores';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { useFieldPolygonErrors } from '../../../../../../hooks';
import useFieldRoutes from '../../../../../../hooks/useFieldsRoutes';
import { EFieldsCommonModalName } from '../../../../../../modals';
import { EFieldsErrors } from '../../../../../../utils';
import { FieldEditController as Controller, FieldEditStore as Store } from '../../../../mobx';

import Styled from './Footer.styles';

const FieldEditListFooter = () => {
  // Навигация
  const navigate = useNavigate();
  const routes = useFieldRoutes();

  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const store = useStore(Store);
  const fieldsStore = useStore(FieldsStore);

  const modal = useModal();
  const fieldErrors = useFieldPolygonErrors(store.editableField);
  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();

  const handleClose = () => {
    if (store.isFieldChanged) {
      showWarningBeforeLeavingThePage(() => {
        navigate(routes.fieldsViewRoute);
      });

      return;
    }

    navigate(routes.fieldsViewRoute);
  };

  const handleSave = () => {
    controller
      .submitField()
      .then(() => navigate(routes.fieldsViewRoute))
      .catch(error => {
        if (error.message === EFieldsErrors.GeometryIntersection) {
          modal.openModalByModalId(EFieldsCommonModalName.IntersectionWarning);
        }
      });
  };

  const canSave = !fieldErrors.length && !store.isCultureZoneOutOfField;

  return (
    <React.Fragment>
      <Styled.ButtonWrapper>
        <Button
          type={'button'}
          color={'secondary'}
          onClick={handleClose}
          dataTestId={'fields-edit-cancel'}
        >
          Отменить
        </Button>
      </Styled.ButtonWrapper>

      <Styled.ButtonWrapper>
        <ButtonLoader
          isLoading={store.isLoading || fieldsStore.isLoading}
          onClick={handleSave}
          disabled={!canSave}
          data-test-id={'fields-edit-save'}
        >
          Сохранить
        </ButtonLoader>
      </Styled.ButtonWrapper>
    </React.Fragment>
  );
};

export default observer(FieldEditListFooter);
