import { CreateFieldModel } from '../../../../../../../../api/models/create.field.model';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import Store from '../stores/FieldsCreate.store';
import FieldsApiService from '../../../../../../../shared/mobx/services/as-fields/FieldsService/FieldsApi.service';
import { TCreatedField } from '../../types';

@provide.transient()
class FieldsCreateService extends FieldsApiService {
  @lazyInject(Store)
  private store: Store;

  // Сохраняет созданные поля
  public submitFields(createdFields: TCreatedField[]) {
    const fieldsForSave = this.getCreatedFieldsModels(createdFields);

    this.store.isLoading = true;

    const promise = this.axios.api.createFields({
      organizationId: this.organizationStore.selectedOrganizationId,
      seasonYear: Number(this.seasonsStore.selectedSeason),
      fields: fieldsForSave,
    });

    return promise
      .catch(err => {
        throw new Error(err.response.data.error);
      })
      .finally(() => {
        this.store.isLoading = false;
      });
  }

  private getCreatedFieldsModels(createdFields: TCreatedField[]): CreateFieldModel[] {
    return createdFields.map(field => ({
      name: field.name,
      geoJson: field.polygon.toGeoJSON(),
    }));
  }
}

export default FieldsCreateService;
