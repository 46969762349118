import { Listbox } from '@headlessui/react/dist';
import { Icon } from '@farmlink/farmik-ui';
import React, { ForwardedRef } from 'react';

import Styled from './ExpandedButton.styles';

interface IProps {
  label: string;
  open: boolean;
}

const ExpandedButton = React.forwardRef((props: IProps, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <Listbox.Button ref={ref} as={Styled.Button}>
      {props.label}
      <Icon icon={props.open ? 'arrow_top' : 'arrow_down'} size={'small'} />
    </Listbox.Button>
  );
});

export default ExpandedButton;
