import { ModalFooter, TModalButton } from '@farmlink/farmik-ui';
import { FC, memo, useMemo } from 'react';

import Styled from './FieldsImportWrongFormatModal.styles';
import { TFieldsImportWrongFormatModalProps } from './FieldsImportFileImportModal.types';

const FieldsImportWrongFormatModal: FC<TFieldsImportWrongFormatModalProps> = ({
  onFileUploaderClick,
  closeModal,
}) => {
  const successButton = useMemo<TModalButton>(() => {
    return {
      title: 'Выбрать файлы',
      color: 'primary',
      handler: onFileUploaderClick,
    };
  }, []);

  const denyButton = useMemo<TModalButton>(() => {
    return {
      title: 'Отменить',
      color: 'default',
      handler: () => closeModal(),
    };
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.IconWrapper>
        <Styled.Icon />
      </Styled.IconWrapper>

      <Styled.Title>Неверный формат: можно загрузить файлы KML, geoJSON или shape</Styled.Title>

      <ModalFooter successButton={successButton} denyButton={denyButton} />
    </Styled.Wrapper>
  );
};

FieldsImportWrongFormatModal.displayName = 'FieldsImportWrongFormatModal';

export default memo(FieldsImportWrongFormatModal);
