import { FC } from 'react';

import { Field } from '../../../../../../../../../../api/models/field.model';

import Styled from './ErrorsLabel.styles';

interface IProps {
  list: Field[];
}

const ErrorsLabel: FC<IProps> = ({ list }) => {
  const errorsCount = list.filter(field => field.error).length;

  if (!errorsCount) {
    return null;
  }

  return (
    <Styled.Wrapper>
      <Styled.LabelWrapper>
        <Styled.Icon />
        <Styled.Title>{`Найдено ошибок: ${errorsCount}`}</Styled.Title>
      </Styled.LabelWrapper>
    </Styled.Wrapper>
  );
};

export default ErrorsLabel;
