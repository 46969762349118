import styled from 'styled-components';

const Title = styled.div`
  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  /* h2: semi24 */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.5px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledContainerTitleDefault = {
  Title,
  Wrapper,
};

export default StyledContainerTitleDefault;
