import { FC, ReactNode, PropsWithChildren, memo, useMemo } from 'react';

import { useDataTestIdV2 } from '../../utils/hooks/locators';

import Styled from './Container.styles';

interface IProps {
  /**
   * Шапка контейнера.
   */
  header?: {
    /**
     * Заглавный текст.
     */
    title?: string;
    /**
     * Иконка контекстной справки.
     */
    ContextualHelpIcon?: ReactNode;
    /**
     * Описание, что находится под заглавным текстом.
     */
    description?: string;
    /**
     * Контент, что отображается под заглавием.
     */
    headerChildren?: ReactNode;
    /**
     * Скрывает разделительную линию контента и хэдера.
     */
    isHideDividingLine?: boolean;
  };
  /**
   * Основной локатор.
   */
  dataTestId?: string;
}

const Container: FC<PropsWithChildren<IProps>> = ({ header, children, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(dataTestId ?? 'container');

  const HeaderContent = useMemo(() => {
    return (
      <>
        {header?.title ? (
          <Styled.Title {...getDataTestId('title')}>{header.title}</Styled.Title>
        ) : null}

        {header?.description ? (
          <Styled.Description {...getDataTestId('description')}>
            {header.description}
          </Styled.Description>
        ) : null}
      </>
    );
  }, [header?.title, header?.description]);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      {header ? (
        <Styled.Header
          {...getDataTestId('header')}
          $isHideHeaderBorder={header?.isHideDividingLine}
        >
          {header.headerChildren ? header.headerChildren : HeaderContent}
        </Styled.Header>
      ) : null}

      <Styled.Content {...getDataTestId('content')}>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

Container.displayName = 'Container';

export default memo(Container);
