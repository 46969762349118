import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  maxWidth: 240,
  minWidth: 240,

  backgroundColor: Colors.generalWhite,
});

const Styles = {
  Wrapper,
};

export default Styles;
