import React, { FC, useCallback, useMemo } from 'react';
import { Typography } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../../../../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../../../../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../../../../../../../hooks';
import { IStoAttributeOdzGroupPut as IOdzGroupPut } from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import {
  StoAttributeOdzGroupContainer as Group,
  StoAttributeOdzDependencyContainer as Dependency,
} from '../../containers';
import { ButtonRemove } from '../../../../../../../../../../../../../../../../../../../../../shared/features/UI/new/ButtonRemove';

import Styled from './StoAttributeOdzDependencyGroup.styles';
interface IProps {
  group: IOdzGroupPut;
  isShowButtonRemove: boolean;
}

const StoAttributeOdzDependencyGroup: FC<IProps> = ({ isShowButtonRemove, group }) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const params = useStoParams();

  const dependenciesTitle =
    group.dependencyAttributes.length > 1
      ? 'При показателях определяющих параметров:'
      : 'При показателях определяющего параметра:';

  const dependenciesNames = useMemo<string>(() => {
    if (store.dependencySelectList.length === 1) {
      return store.dependencySelectList[0].attr.attribute.name;
    }

    return store.dependencySelectList.map<string>(dep => dep.attr.attribute.name).join(', ');
  }, []);

  const dependencyWidthIfAlone = useMemo<string | null>(() => {
    if (group.dependencyAttributes.length > 1) return null;

    const [firstDep] = group.dependencyAttributes;

    if (firstDep?.dictionaryValues) return '1fr';

    if (controller.isDictionaryOdz) return '268.66px';

    return '284px';
  }, []);

  const handleClickOnButtonRemove = useCallback((): void => {
    controller.removeOdzGroup(group.id, params);
  }, []);

  const getDataTestId = useDataTestIdV2('sto__attribute__odz__dependency__group');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Container {...getDataTestId('dependencies-container')}>
        <Styled.Header {...getDataTestId('dependencies-header')}>
          <Styled.TitleWrapper {...getDataTestId('dependencies-title-wrapper')}>
            <Typography
              dataTestId={getDataTestId('dependencies-title')['data-test-id']}
              variant={'bodyAccent'}
            >
              {dependenciesTitle}
            </Typography>

            {isShowButtonRemove ? (
              <ButtonRemove
                dataTestId={getDataTestId()['data-test-id']}
                onClick={handleClickOnButtonRemove}
              />
            ) : null}
          </Styled.TitleWrapper>

          <Typography
            dataTestId={getDataTestId('dependencies-names')['data-test-id']}
            variant={'body'}
          >
            {dependenciesNames}
          </Typography>
        </Styled.Header>

        <Styled.Dependencies
          {...getDataTestId('dependencies')}
          $widthIfAlone={dependencyWidthIfAlone}
          $quantity={group.dependencyAttributes.length}
        >
          {group.dependencyAttributes.map(dep => (
            <Dependency
              key={group.id}
              groupId={group.id}
              hasGroupSeveralDeps={group.dependencyAttributes.length > 1}
              dependency={dep}
            />
          ))}
        </Styled.Dependencies>
      </Styled.Container>

      <Styled.Container {...getDataTestId('attribute-container')}>
        <Styled.Header {...getDataTestId('attribute-header')}>
          <Typography
            dataTestId={getDataTestId('attribute-title')['data-test-id']}
            variant={'bodyAccent'}
          >
            Область допустимых значений составит:
          </Typography>

          <Typography dataTestId={getDataTestId('attribute-name')['data-test-id']} variant={'body'}>
            {store.attribute.attribute.name}
          </Typography>
        </Styled.Header>

        <Group group={group} />
      </Styled.Container>
    </Styled.Wrapper>
  );
};

StoAttributeOdzDependencyGroup.displayName = 'StoAttributeOdzDependencyGroup';

export default observer(StoAttributeOdzDependencyGroup);
