import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const CellWrapper = styled.div`
  padding-right: ${ESpaceSize.M}px;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Количество отображаемых строк */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const Styled = { CellWrapper };

export default Styled;
