import { makeAutoObservable, runInAction } from 'mobx';
import { cloneDeep } from 'lodash';

import { EMapControlPlace, TMapControl } from '../../interfaces/Controls.interface';
import { provide } from '../../../../../../utils/IoC';

type TControls = {
  [key in EMapControlPlace]: TMapControl[];
};

type TOrder = number | 'last' | 'first';

const defaultControls = {
  [EMapControlPlace.TOP]: [],
  [EMapControlPlace.BOTTOM]: [],
  [EMapControlPlace.LEFT]: [],
  [EMapControlPlace.RIGHT]: [],
};

@provide.singleton()
class MapControlsStore {
  private _controls: TControls = cloneDeep(defaultControls);

  constructor() {
    makeAutoObservable(this);
  }

  public getControlsBy(place: EMapControlPlace) {
    return this._controls[place].filter(control => !control.hidden);
  }

  /**
   * Регистрирует список конроллов на карте
   * Контроллы можно зарегестрировать при инициализаци и в процессе работы через метод addControl()
   */
  public registerControls(controls: TMapControl[] = []) {
    runInAction(() => {
      this.clear();

      controls.forEach(control => {
        this._controls[control.place].push(control);
      });
    });
  }

  /**
   * Добавляет контрол на карту. Order начинается с нуля.
   */
  public addControl(control: TMapControl & { order: TOrder }) {
    const { order, ...rest } = control;

    if (this.hasControl(control.id)) {
      return;
    }

    if (order === 'first' || order === 'last') {
      const arrKey = order === 'first' ? 'unshift' : 'push';
      this._controls[control.place][arrKey](control);
      return;
    }

    this._controls[control.place].splice(order, 0, rest);
  }

  /**
   * Удаляет контрол с карты
   */
  public removeControl(id: string) {
    const control = this.getControlById(id);

    if (!control) {
      return;
    }

    const place = control.place;
    this._controls[place] = this._controls[place].filter(el => el.id !== id);
  }

  /**
   * Скрывает/показывает контрол на карте (если он зарегестрирован).
   * В отличие от метода removeControl() не удаляет контрол из массива
   * @param id - id контрола
   * @param value - true - элемент виден. false - скрыт
   */
  public toggleVisibility(id: string, value: boolean) {
    const controlById = this.getControlById(id);

    if (!controlById) {
      return;
    }

    controlById.hidden = !value;
  }

  public hasControl(id: string) {
    return Boolean(this.getControlById(id));
  }

  public clear() {
    this._controls = cloneDeep(defaultControls);
  }

  private getControlById(id: string): TMapControl {
    const allControls = Object.values(this._controls).flatMap(v => v);

    return allControls.find(control => control.id === id);
  }
}

export default MapControlsStore;
