import { Colors, IconButton } from '@farmlink/farmik-ui';
import { FC } from 'react';
import { observer } from 'mobx-react';

import { useStoActionButton } from '../../../../common/StoActionButton/hooks';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoStore } from '../../../../mobx';

import Styled from './ActionsCell.style';

const ActionsCell: FC<{ id: string; isModified: boolean }> = ({ id, isModified }) => {
  const { isViewOnly } = useStore(StoStore);

  const { discardDraft } = useStoActionButton();

  const isDisplayDiscardDraft = isModified && !isViewOnly;

  return (
    <Styled.Wrapper>
      {isDisplayDiscardDraft && (
        <IconButton
          colorScheme={{
            default: {
              background: 'transparent',
              icon: Colors.pink,
            },
            hover: {
              background: 'transparent',
              icon: Colors.hoverPink,
            },
          }}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();

            discardDraft();
          }}
          icon="bin"
        />
      )}

      {/* <IconButton
        colorScheme={{
          default: {
            background: 'transparent',
            icon: Colors.generalDark,
          },
          hover: {
            background: 'transparent',
            icon: Colors.generalDark,
          },
        }}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          console.log('download');
        }}
        icon="download"
      /> */}
    </Styled.Wrapper>
  );
};

export default observer(ActionsCell);
