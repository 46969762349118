import styled from 'styled-components';

const Wrapper = styled.svg`
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:hover {
    & path {
      fill: #b70c00;
    }
  }
`;

const StyledButtonRemove = {
  Wrapper,
};

export default StyledButtonRemove;
