import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';

import InfoIconSvg from './assets/images/info-icon-white-16x16px.svg';

const Wrapper = styled.div`
  padding: 0 16px;
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;
  background: url(${InfoIconSvg});
`;

const Title = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  padding: 8px 12px;
  gap: 10px;

  height: 36px;

  background-color: ${Colors.red};
  border-radius: 12px;
`;

export default {
  Wrapper,
  LabelWrapper,
  Icon,
  Title,
};
