import styled from 'styled-components';
import { ESpaceSize } from '@farmlink/farmik-ui';

import { Colors } from '../../../../../shared/constans/colors';

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */
  margin: 0;
  text-align: center;
  letter-spacing: -0.5px;

  /* general/black */

  color: ${Colors.black};
`;

export const Line = styled.div`
  width: 100%;
  height: 0.5px;
  background: ${Colors.grayBorder};
  margin-top: 24px;
  margin-bottom: 20px;
`;

export const BottomLine = styled.div`
  width: 100%;
  height: 0.5px;
  background: ${Colors.grayBorder};
  margin-top: 24px;
  margin-bottom: 20px;
`;

export const SeasonStubSelectorWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
export const CalendarRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${ESpaceSize.M}px;
`;

export const CalendarWrapper = styled.div`
  width: 154px;
`;

export const CopySeasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CopySeasonSelectWrapper = styled.div`
  margin-top: 16px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  & > button {
    display: inline-block;
  }
`;

export const MarkOkImg = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
`;

export const LabelTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.15px;
  margin: 0;
  margin-bottom: 36px;
  color: ${Colors.black};
`;

export const ButtonActionWrapper = styled.div`
  text-align: center;
  width: 100%;
  > button {
    display: inline-block;
  }
`;

export const DeleteText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  color: ${Colors.darkGray};
  margin: 20px 0 0;

  span {
    text-decoration: underline;
  }
`;
