import React, { FC, useMemo } from 'react';
import moment from 'moment';

import { EStoStatus, ISto } from '../../../../../../api/models/as-fields/STO/STO.model';
import { useDataTestIdV2 } from '../../../../../shared/features/utils/hooks/locators';

import Styled from './StoStatusChip.styles';

interface IProps {
  calculatedStatus: ISto['calculatedStatus'];
}

const StoStatusChip: FC<IProps> = ({ calculatedStatus }) => {
  const formattedText = useMemo(() => {
    if (!calculatedStatus) return '';

    const formattedCreatingDate = calculatedStatus.date
      ? moment(calculatedStatus.date).format('DD.MM.YYYY')
      : '—';

    switch (calculatedStatus.status) {
      case EStoStatus.Draft:
        return `Черновик от ${formattedCreatingDate}`;

      case EStoStatus.Active:
        return `Версия от ${formattedCreatingDate}`;

      case EStoStatus.Modified:
        return `Изменено ${formattedCreatingDate}`;

      case EStoStatus.Inactive:
        return `Неактивно с ${formattedCreatingDate}`;

      default:
        return '';
    }
  }, [calculatedStatus]);

  const getDataTestId = useDataTestIdV2('sto__chip-status');

  if (!calculatedStatus) return <></>;

  return (
    <Styled.Wrapper {...getDataTestId()} $status={calculatedStatus.status}>
      {formattedText}
    </Styled.Wrapper>
  );
};

StoStatusChip.displayName = 'StoStatusChip';

export default StoStatusChip;
