import { FieldSeasonsModel } from '../../../../../../../../api/models/field.seasons.model';
import { FieldsApiService } from '../../../../../../../shared/mobx/services/as-fields';
import { mapToArray } from '../../../../../../../shared/utils/helpers/map/mapToArray';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../../stores/seasons.store';
import SeasonsAPIService from '../services/FieldSeasonsAPI.service';
import { FieldsMapCoreStore } from '../../../../mobx';
import Store from '../stores/FieldSeasons.store';
import { EFieldsMode } from '../../../../utils';

import FieldCulturesController from './FieldCultures.controller';

/**
 * Данный контроллер отвечает за инициализацию режима с историей сезонов для конкретного поля
 */
@provide.transient()
class FieldSeasonsController {
  @lazyInject(Store)
  private store: Store;

  @lazyInject(FieldsMapCoreStore)
  private coreStore: FieldsMapCoreStore;

  @lazyInject(SeasonsAPIService)
  private seasonsAPIService: SeasonsAPIService;

  @lazyInject(FieldsApiService)
  private fieldsAPIService: FieldsApiService;

  @lazyInject(SeasonsStore)
  private seasonsStore: SeasonsStore;

  @lazyInject(FieldCulturesController)
  private culturesController: FieldCulturesController;

  /**
   * Инициализация сезонов для поля.
   * @param fieldId - айди поля для фетчинга сезонов
   */
  public async initialize(fieldId: string): Promise<boolean> {
    this.coreStore.fieldsMode = EFieldsMode.FIELD_SEASONS;

    const field = await this.fieldsAPIService.fetchField({ fieldId });

    if (!field) {
      return false;
    }

    // Загружаем в стор все сезоны выбранного поля
    await this.seasonsAPIService.fetchAllFieldSeasons(fieldId);

    const { selectedSeassonData } = this.seasonsStore;
    const season = this.store.getSeasonByYear(selectedSeassonData?.year);

    this.store.field = field;
    this.store.selectedSeason = season;

    await this.culturesController.renderField(season.fieldVersion);

    return true;
  }

  public destroy() {
    this.culturesController.destroy();
    this.store.clear();

    this.coreStore.selectedField = null;
  }

  /**
   * Копирует поле из сезона в текущий выбранный сезон
   */
  public async copyField(fieldId: string, fromSeason: FieldSeasonsModel) {
    if (!fieldId || !fromSeason) {
      return;
    }

    const toSeason = this.store.selectedSeason;
    await this.seasonsAPIService.copyField(fieldId, fromSeason, toSeason);

    await this.refetchSeasons();
  }

  /**
   * Удаляет поле из сезона
   */
  public async deleteFieldFromSeason(season: FieldSeasonsModel) {
    const fieldId = season?.fieldVersion.id;

    await this.fieldsAPIService.deleteField(fieldId, season.season.year);

    await this.refetchSeasons().catch(() => null);
  }

  /**
   * Возвращает option list с сезонами в которых есть поля
   */
  public getSeasonsOptionList(fieldId: string) {
    return this.seasonsAPIService
      .fetchNonEmptyFieldSeasons(fieldId)
      .then(mapToArray)
      .then(array =>
        array.map(el => ({
          value: el.season?.year.toString(),
          label: el.season.label,
        }))
      );
  }

  /**
   * Обновляет сезоны поля. Ререндерит выбранный сезон
   */
  public async refetchSeasons() {
    const activeFieldId = this.store.field?.id;
    const seasonYear = this.store.selectedSeason.season.year;

    await this.seasonsAPIService.fetchAllFieldSeasons(activeFieldId);

    const updatedSeason = this.store.getSeasonByYear(seasonYear);
    this.store.selectedSeason = updatedSeason;

    await this.culturesController.renderField(updatedSeason?.fieldVersion);
  }
}

export default FieldSeasonsController;
