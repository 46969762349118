import { LatLngExpression, MarkerOptions } from 'leaflet';

import { BaseMarker } from '../../../../../../../../../shared/features/map/utils/MapElements';

class BaseInspectionPointMarker extends BaseMarker {
  constructor(latlng: LatLngExpression, isDisabled = false, options?: MarkerOptions) {
    super(latlng, { pmIgnore: true, ...options });

    this.setTooltipWithCoords();

    if (isDisabled) {
      this.setOpacity(0.4);
    }
  }

  public setTooltipWithCoords() {
    const { lat, lng } = this.getLatLng();
    const content = `${lng.toFixed(7)} ${lat.toFixed(7)}`;

    this.bindTooltip(content, {
      direction: 'bottom',
      className: 'inspection-point_tooltip',
      offset: [50, 16],
      opacity: 1,
      permanent: false,
    });
  }
}

export default BaseInspectionPointMarker;
