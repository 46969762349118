import { getBoundsByGeometry } from '../../../../../shared/features/map/utils/helpers';
import { FieldsApiService } from '../../../../../shared/mobx/services/as-fields';
import { lazyInject, provide } from '../../../../../shared/utils/IoC';
import { Field } from '../../../../../../api/models/field.model';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { FIELD_POLYGON_OPTIONS } from '../../utils';
import { FieldsMapCoreStore as CoreStore } from '../stores';
import {
  CulturesLegendStore,
  LayerFillController,
  LayerTooltipController,
} from '../../../../../shared/features/map/components/Controls/components';
import {
  BaseLayerGroup,
  FieldLayerGroup,
} from '../../../../../shared/features/map/utils/MapElements';
import {
  MapCoreController,
  MapLayerGroupController,
  MapLayerGroupStore,
} from '../../../../../shared/features/map/modules';
import {
  IMapLayerGroupConfig,
  IMapLayerGroupSelectOptions,
} from '../../../../../shared/features/map/models';

type TGetLayerGroupConfigFn = (field: Field) => IMapLayerGroupConfig;

@provide.transient()
abstract class FieldsMapCoreController {
  @lazyInject(CoreStore)
  protected coreStore: CoreStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(CulturesLegendStore)
  protected culturesLegendStore: CulturesLegendStore;

  @lazyInject(MapLayerGroupStore)
  protected mapLayerGroupStore: MapLayerGroupStore;

  @lazyInject(MapCoreController)
  protected mapCoreController: MapCoreController;

  @lazyInject(MapLayerGroupController)
  protected mapLayerGroupController: MapLayerGroupController;

  @lazyInject(LayerFillController)
  protected layerFillController: LayerFillController;

  @lazyInject(LayerTooltipController)
  protected layerTooltipController: LayerTooltipController;

  @lazyInject(FieldsApiService)
  protected fieldsApiService: FieldsApiService;

  /**
   * Инициализирует карту. Состоит из след. шагов:
   * 1. Формирование списка с конфигурацией полигонов
   * 2. Очистка карты
   * 3. Добавление слоев! на карту
   * 4. Создание связи поле-слой. (id слоя сетается в модель поля)
   * 5. Регистрация событий карты
   */
  protected async buildMap(fieldsList: Field[], getLayerGroupConfigFn: TGetLayerGroupConfigFn) {
    console.time('Build Map');
    this.coreStore.isBuildingMap = true;
    const configsList = fieldsList.map(getLayerGroupConfigFn);

    this.mapCoreController.clear();

    const elements = await this.mapLayerGroupController.displayManyAsync(configsList);

    this.fieldsStore.attachMapElements(elements);

    this.coreStore.isBuildingMap = false;
    console.timeEnd('Build Map');

    return elements;
  }

  /**
   * Отписывается от событий карты. Удаляет выбранное поле
   */
  public destroy() {
    this.coreStore.mapEventListeners.forEach(listener => listener.off());
    this.coreStore.selectedField = null;
    this.culturesLegendStore.clearCultures();
  }

  /**
   * Базовый метод по выбору поля. Состоит из след. шагов:
   * 1. Выбор слоя на карте (автоматически центрирует)
   * 2. Запись поля в стор
   */
  public selectField(field: Field, options?: IMapLayerGroupSelectOptions) {
    if (this.coreStore.isSelectedField(field) && !options?.force) {
      return;
    }

    // Необходимо для того, чтобы не терялся polyid.
    const fieldWithPolyId = this.fieldsStore.getFieldById(field?.id);
    const layerGroup = this.getLayerGroupByField(fieldWithPolyId);

    this.mapLayerGroupController.select(layerGroup, options);
    this.coreStore.selectedField = fieldWithPolyId;
    this.coreStore.fieldToCenter = fieldWithPolyId;
  }

  /**
   * Базовый метод по отмене выделенного слоя
   */
  public deselectField() {
    this.mapLayerGroupController.deselect();
    this.coreStore.selectedField = null;
    this.coreStore.fieldToCenter = null;
  }

  /**
   * Удаляет поле. Удаляет полигон из карты.
   * Если был удален выбранный элемент, то обнуляет selectedField, fieldToCenter
   */
  public deleteField(field: Field, seasonYear: number): Promise<Field> {
    const isSelectedField = this.coreStore.isSelectedField(field);

    return this.fieldsApiService.deleteField(field.id, seasonYear).then(res => {
      // Удаляем элемент карты
      const layerGroup = this.getLayerGroupByField(field);
      this.mapLayerGroupController.remove(layerGroup?.id);

      // Удаляем поле из стора
      this.fieldsStore.deleteFieldById(field.id);

      if (isSelectedField) {
        this.coreStore.fieldToCenter = null;
        this.coreStore.selectedField = null;
      }

      return res;
    });
  }

  /**
   * Возвращает слой карты для переданной модели поля
   */
  public getLayerGroupByField(field: Field | null): BaseLayerGroup | undefined {
    // Необходимо для того чтобы не терялся polyid.
    const originalField = this.fieldsStore.getFieldById(field?.id);

    return this.mapLayerGroupStore.getLayerGroup(originalField?.polyId);
  }

  /**
   * Возвращает модель поля для переданного слоя карты
   */
  protected getFieldByLayerGroup(layerGroup: BaseLayerGroup): Field | null {
    return this.fieldsStore.fieldsList.find(({ polyId }) => polyId === layerGroup.id) ?? null;
  }

  /**
   * Центрирует карту на границы поля
   */
  protected centerMapToFieldBounds(field: Field) {
    const bounds = getBoundsByGeometry(field?.geometry);
    this.mapCoreController.centerOnBounds({ getBounds: () => bounds });
  }

  // Базовый метод получения конфигурации группы слоев
  protected getLayerGroupConfig(field: Field, ...args: unknown[]): IMapLayerGroupConfig {
    return {
      element: field,
      layerGroup: new FieldLayerGroup(field, {
        fieldOptions: FIELD_POLYGON_OPTIONS.display,
        selectedStyle: FIELD_POLYGON_OPTIONS.selected,
        hoverStyle: FIELD_POLYGON_OPTIONS.selected,
      }),
      options: { isAllowToClick: true },
    };
  }

  protected get fieldsPolygonsList() {
    return this.mapLayerGroupStore.layerGroupsMainPolygons;
  }

  /**
   * Возвращает поле для центрирования карты. Поле должно быть в списке загруженных с бэка полей
   */
  protected get fieldToCenter() {
    const { fieldToCenter } = this.coreStore;

    if (typeof fieldToCenter === 'string') {
      return this.fieldsStore.getFieldById(fieldToCenter);
    }

    return this.fieldsStore.getFieldById(fieldToCenter?.id);
  }
}

export default FieldsMapCoreController;
