import React, { FC } from 'react';
import { observer } from 'mobx-react';

import Container from '../../../../../../../shared/features/UI/new/Container/Container';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { EContainerHeaderType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskOperationViewStore as Store } from '../../mobx/stores';
import { TaskOperationViewController as Controller } from '../../mobx/controllers';

import Styled from './TaskOperationViewCommonInfo.styles';
import {
  TaskOperationViewAssignee as Assignee,
  TaskOperationViewCulture as Culture,
  TaskOperationViewDate as Date,
  TaskOperationViewField as Field,
  TaskOperationViewOperation as Operation,
  TaskOperationViewSprayingType as SprayingType,
} from './components';

const TaskOperationViewCommonInfo: FC = () => {
  const getDataTestId = useDataTestIdV2('tasks__task-operation-view__common-info');
  const controller = useStore(Controller);

  return (
    <Container
      dataTestId={getDataTestId()['data-test-id']}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: 'Общие данные',
        },
      }}
      containerStyles={{
        gap: '16px',
      }}
    >
      <Styled.Form {...getDataTestId('')}>
        <Culture
          value={controller.cultureName}
          dataTestId={getDataTestId('culture')['data-test-id']}
        />

        <Field value={controller.fieldName} dataTestId={getDataTestId('field')['data-test-id']} />

        <Operation
          value={controller.operationName}
          dataTestId={getDataTestId('operation')['data-test-id']}
        />

        <SprayingType
          value={controller.spayingType}
          dataTestId={getDataTestId('spraying-type')['data-test-id']}
        />

        <Assignee
          value={controller.assigneeName}
          dataTestId={getDataTestId('assignee')['data-test-id']}
        />

        <Date value={controller.date} dataTestId={getDataTestId('date')['data-test-id']} />
      </Styled.Form>
    </Container>
  );
};

TaskOperationViewCommonInfo.displayName = 'TaskOperationViewCommonInfo';

export default observer(TaskOperationViewCommonInfo);
