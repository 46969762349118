import { FC, memo, PropsWithChildren } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './ChecklistsContent.styles';

interface IProps {
  isOpen?: boolean;
  dataTestId?: string;
}

const ChecklistsContent: FC<PropsWithChildren<IProps>> = ({
  children,
  dataTestId,
  isOpen = true,
}) => {
  const getDataTestId = useDataTestIdV2(dataTestId ?? 'checklists__attributes');

  return (
    <Styled.Wrapper {...getDataTestId()} $isOpen={isOpen}>
      {children}
    </Styled.Wrapper>
  );
};

ChecklistsContent.displayName = 'ChecklistsContent';

export default memo(ChecklistsContent);
