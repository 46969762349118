import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, Checkbox, Switcher, Typography } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { useStoParams } from '../../../../../../../../../../../../../../../../hooks';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../../../../../../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../../../mobx/controllers';
import { StoStore } from '../../../../../../../../../../../../../../mobx';

import Styled from './StoAttributePhotosSelector.styles';

const StoAttributePhotosSelector: FC = () => {
  const stoStore = useStore(StoStore);
  const checklistsStore = useStore(ChecklistsStore);

  const controller = useStore(Controller);

  const params = useStoParams();

  const {
    isLinkedPhotoAvailable,
    isLinkedPhotoEnabled,
    isLinkedPhotoRequired,
  } = checklistsStore.getAttrUpdate(params.checklistId, params.stageId, params.attributeId);

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const checkIfSwitcherIsDisabled = (): boolean => {
    if (!isLinkedPhotoAvailable) return true;

    return false;
  };

  const checkIfSelectorIsDisabled = (): boolean => {
    if (!isLinkedPhotoAvailable) return true;
    if (!isLinkedPhotoEnabled) return true;

    return false;
  };

  const handleIsEnabledChange = useCallback((value: boolean): void => {
    controller.changeAttribute(params, { isLinkedPhotoEnabled: value });
  }, []);

  const handleIsRequiredChange = useCallback((value: boolean): void => {
    controller.changeAttribute(params, { isLinkedPhotoRequired: value });
  }, []);

  const getDataTestId = useDataTestIdV2('sto__attribute__photos-selector');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <AutoTooltip
        content={
          'Для прикрепления фотографии необходима дополнительная настройка чек-листа. Обратитесь к администратору'
        }
        disabled={!checkIfSwitcherIsDisabled()}
      >
        <div>
          <Styled.SwitcherWrapper
            $isDisabled={checkIfSwitcherIsDisabled()}
            {...getDataTestId('switcher-wrapper')}
          >
            <Switcher
              value={isLinkedPhotoEnabled}
              onChange={handleIsEnabledChange}
              dataTestId={'switcher'}
              isBlocked={checkIfIsBlockedByPermissions()}
            />
          </Styled.SwitcherWrapper>
        </div>
      </AutoTooltip>

      <Typography
        color={checkIfSelectorIsDisabled() ? 'secondaryDark' : 'generalDark'}
        variant={'title'}
        dataTestId={getDataTestId('title')['data-test-id']}
      >
        Добавить фотографии
      </Typography>

      {isLinkedPhotoEnabled ? (
        <Styled.RequiredWrapper>
          <Typography
            color={checkIfSelectorIsDisabled() ? 'secondaryDark' : 'generalDark'}
            dataTestId={getDataTestId('required-label')['data-test-id']}
          >
            Обязательно
          </Typography>

          <Checkbox
            value={Boolean(isLinkedPhotoRequired)}
            disable={checkIfIsBlockedByPermissions() || checkIfSelectorIsDisabled()}
            onChange={handleIsRequiredChange}
            dataTestId={getDataTestId('require-checkbox')['data-test-id']}
          />
        </Styled.RequiredWrapper>
      ) : null}
    </Styled.Wrapper>
  );
};

StoAttributePhotosSelector.displayName = 'StoAttributePhotosSelector';

export default observer(StoAttributePhotosSelector);
