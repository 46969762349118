import React, { FC, ReactNode } from 'react';

import { useDataTestIdV2 } from '../../utils/hooks/locators';

import Styled from './ListingPage.styles';

interface IProps {
  /**
   * Уникальный ключ для локаторов.
   */
  id: string;
  /**
   * Компонент с фильтрами.
   */
  filters: ReactNode;
  /**
   * Компонент с таблицей.
   */
  list: ReactNode;
}

const ListingPage: FC<IProps> = ({ id, filters, list }) => {
  const getDataTestId = useDataTestIdV2(id);

  return (
    <Styled.ListingPage {...getDataTestId()}>
      <Styled.FiltersWrapper {...getDataTestId('filters-wrapper')}>{filters}</Styled.FiltersWrapper>

      <Styled.ListWrapper {...getDataTestId('list-wrapper')}>{list}</Styled.ListWrapper>
    </Styled.ListingPage>
  );
};

ListingPage.displayName = 'ListingPage';

export default ListingPage;
