import { PolylineOptions } from 'leaflet';
import { Colors } from '@farmlink/farmik-ui';
import { Geometry } from '@turf/turf';
import { cloneDeep } from 'lodash';

import { BaseCulturePolygon } from '../../../../../../../../../shared/features/map/utils/MapElements/polygons';
import LayerHoverHandler from '../../../../../../../../../shared/features/map/utils/helpers/LayerHoverHandler';
import conditionalObjectProp from '../../../../../../../../../shared/utils/conditionalObjectProp';
import { CultureZone } from '../../../../../../../../../../api/models/field.model';

/**
 * КЗ-полигон для {@link CulturesLayerGroup}.
 * Наследуется от базового КЗ-полигона
 */
class CulturePolygon extends BaseCulturePolygon {
  private _optionsToReset: PolylineOptions;

  private _lastValidGeometry: Geometry;

  private _isSelected = false;

  private hoverHandler = new LayerHoverHandler(this, () => this._isSelected);

  constructor(geometry: Geometry, dataModel: CultureZone, isVisible = true) {
    super(geometry, dataModel);

    if (!isVisible) {
      this.setTransparentStyle();
    }

    this._lastValidGeometry = this.getInfo().geometry;

    this.hoverHandler.register({ color: '#780BBB' });
  }

  public getLastValidGeometry() {
    return this._lastValidGeometry;
  }

  public get isSelected() {
    return this._isSelected;
  }

  /**
   * Сохраняет текущие опции полигона и устанавливает прозрачную заливку
   */
  public setTransparentStyle(): void {
    this._optionsToReset = cloneDeep(this.options);

    this.setStyle({ fillColor: 'transparent' });

    this._prevOptions = this.options;
  }

  /**
   * Восстанавливает сохраненные опции
   */
  public restoreSavedOptions(): void {
    if (this._optionsToReset) {
      this._optionsToReset = {
        ...this._optionsToReset,
        // Если выбрано поле, то его цвет согласно выделению а не сохраненному значению
        ...conditionalObjectProp(this.isSelected, { color: '#780BBB' }),
      };

      this.setStyle(this._optionsToReset);
      this._prevOptions = this._optionsToReset;
    }
  }

  public toggleHighlight(enable: boolean) {
    const color = enable ? '#780BBB' : Colors.generalWhite;

    this.setStyle({ color });

    this._prevOptions.color = color;
    this.updateGeomanCachedColor(color);

    this._isSelected = enable;
  }

  /**
   * Обновляет последнюю валидную геометрию
   */
  public updateValidGeometry(geometry: Geometry) {
    this._lastValidGeometry = geometry;
  }
}

export default CulturePolygon;
