import { ComparisonTableBuilderStore as Store } from '../../stores';
import { IComparisonTableBuilderColumnConfig as IColumnConfig } from '../../../models/configs';
import { IComparisonTableBuilderColumn as IColumn } from '../../../models/data';
import { lazyInject, provide } from '../../../../../utils/IoC';

@provide.transient()
class ComparisonTableBuilderColumnsService {
  @lazyInject(Store)
  protected store: Store;

  addColumnList = (builderId: string, configList: IColumnConfig[]): void => {
    const columnList = this.createColumnList(builderId, configList);

    this.store.setColumnList(builderId, columnList, { isClearPreviousList: true });
  };

  protected createColumnList = (builderId: string, configList: IColumnConfig[]): IColumn[] => {
    const columnList = configList.map((config, i) => this.createColumn(builderId, config, i + 1));

    return columnList;
  };

  protected createColumn = (builderId: string, config: IColumnConfig, order: number): IColumn => {
    const column: IColumn = {
      ...config,
      builderId,
      order,
      renderType: null,
    };

    if (config?.autoRenderConfig) {
      column.renderType = 'auto';
    }

    if (config?.customRenderConfig) {
      column.renderType = 'custom';
    }

    return column;
  };
}

export default ComparisonTableBuilderColumnsService;
