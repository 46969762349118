import styled, { CSSObject, css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

export const Fullscreen = styled.div`
  padding: 20px;
  /* height: 200%; */
  max-width: 912px;
  box-sizing: content-box;
  margin: 0 auto;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const BackButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  margin-top: 7px;
  width: 100%;
  max-width: 934px;
  margin: 7px auto 0px;
  transform: translateX(14px);
`;

export const ActionButton = styled.div`
  width: 160px;
  height: 40px;
  border: 1px solid #3fb65f;
  border-radius: 12px;
  background: #ffffff;
  text-align: center;
  size: 14px;
  line-height: 40px;
  color: #3fb65f;
`;

export const FullscreenWrapper = styled.div<{ $isShort: boolean }>`
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  background: ${Colors.secondaryGray};
  display: grid;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1006;
  grid-template-columns: minmax(1px, 1fr);
  grid-template-rows: auto minmax(1px, 1fr);
  transition: 1s;
  &.header_hidden {
    transform: translateY(-72px);
    height: calc(100vh + 72px);
    padding-bottom: 96px;
  }
`;

export interface IFullscreenFooterProps {
  $paddings?: CSSObject['padding'];
}

export const FullscreenFooter = styled.div<IFullscreenFooterProps>`
  padding: ${({ $paddings }) => $paddings ?? '20px 0 32px'};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > div {
    width: 100%;
  }

  margin: 0 24px;
  scrollbar-gutter: stable;
`;

export const FullScreenHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 72px;
  padding: 14px 24px;
  border-bottom: 0.5px solid ${Colors.borderGray};
  background: ${Colors.generalWhite};
  z-index: 100;
`;

export const FullscreenHeaderUnderTitleWrapper = styled.div`
  padding-left: 22px;
  margin-top: 2px;
`;

const Styled = {
  Fullscreen,
  BackButton,
  ActionButton,
  FullscreenWrapper,
  FullscreenFooter,
  FullScreenHeader,
  FullscreenHeaderUnderTitleWrapper,
};

export default Styled;
