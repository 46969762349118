import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Input } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoController, StoStore } from '../../../../mobx';

const MAX_NAME_LENGTH = 50;

const StoName: FC = () => {
  const controller = useStore(StoController);
  const store = useStore(StoStore);

  const handleChange = useCallback((value: string): void => {
    if (store.isCreateMode) {
      controller.updateCreateStoName(value);
      return;
    }

    controller.updateStoName(value);
  }, []);

  const isBlocked = store.isViewOnly;

  const value =
    store?.createStoData?.name ?? store?.updatedStoData?.name ?? store?.selectedSto?.name ?? '';

  return (
    <Input
      label="Название СТО"
      placeholder="Введите название"
      value={value}
      onChange={handleChange}
      maxLength={MAX_NAME_LENGTH}
      isResizable={false}
      blocked={isBlocked}
      dataTestId="sto-name"
    />
  );
};

StoName.displayName = 'StoName';

export default observer(StoName);
