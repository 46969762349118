import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { Sidebar } from '../Sidebar/Sidebar';
import { useStore } from '../../../shared/utils/IoC';
import { UiStore } from '../../stores/ui.store';

import Styled from './AdaptiveSidebar.style';

const AdaptiveSidebar: FC = () => {
  const ui = useStore(UiStore);

  if (!ui.isAdaptiveSidebarVisible) {
    return null;
  }

  return (
    <Styled.Wrapper>
      <Styled.Blur onClick={() => ui.setIsSidebarShort(true)} />

      <Styled.SidebarWrapper>
        <Sidebar isAdaptive={true} />
      </Styled.SidebarWrapper>
    </Styled.Wrapper>
  );
};

export default observer(AdaptiveSidebar);
