import { lazyInject, provide } from '../../../../../../../../../../shared/utils/IoC';
import { StoRegistriesStore as Store } from '../../stores';
import {
  StoRegistriesApiService as ApiService,
  StoRegistriesChangesService as ChangesService,
} from '../../services';
import {
  EStoDictionaryName as EDictionaryName,
  IStoDictionary as IDictionary,
} from '../../../../../../../../../../../api/models/as-fields/new/sto';
import { IStoParams } from '../../../../../../../models';
import { StoStore } from '../../../../../mobx';
import { TStoRegistriesEntitySelectOption as TEntitySelectOption } from '../../../types';

@provide.transient()
class StoRegistriesController {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(StoStore)
  protected stoStore: StoStore;

  @lazyInject(ApiService)
  protected apiService: ApiService;

  @lazyInject(ChangesService)
  protected changesService: ChangesService;

  public isDictionaryLoading = (name: EDictionaryName): boolean => {
    return this.store.hasLoadingDictionary(name);
  };

  public getEntityList = (name: EDictionaryName): IDictionary[] => {
    switch (name) {
      case EDictionaryName.WeedPlants:
        return this.store.weedPlantList;

      case EDictionaryName.Vermin:
        return this.store.verminList;

      case EDictionaryName.Diseases:
        return this.store.diseaseList;

      default:
        return [];
    }
  };

  public getEntityListToDisplay = (name: EDictionaryName): IDictionary[] => {
    switch (name) {
      case EDictionaryName.WeedPlants:
        return this.store.weedPlantListToDisplay;

      case EDictionaryName.Vermin:
        return this.store.verminListToDisplay;

      case EDictionaryName.Diseases:
        return this.store.diseaseListToDisplay;

      default:
        return [];
    }
  };

  public getSelectedEntityIdList = (name: EDictionaryName): string[] => {
    switch (name) {
      case EDictionaryName.WeedPlants:
        return this.store.selectedWeedPlantIdList;

      case EDictionaryName.Vermin:
        return this.store.selectedVerminIdList;

      case EDictionaryName.Diseases:
        return this.store.selectedDiseaseIdList;

      default:
        return [];
    }
  };

  public isEntitySelected = (name: EDictionaryName, id: string): boolean => {
    switch (name) {
      case EDictionaryName.WeedPlants:
        return this.store.hasSelectedWeedPlant(id);

      case EDictionaryName.Vermin:
        return this.store.hasSelectedVermin(id);

      case EDictionaryName.Diseases:
        return this.store.hasSelectedDisease(id);

      default:
        return false;
    }
  };

  public selectEntity = (name: EDictionaryName, id: string): void => {
    this.stoStore.setHasChanges(true);

    switch (name) {
      case EDictionaryName.WeedPlants:
        return this.changesService.selectWeedPlant(id);

      case EDictionaryName.Vermin:
        return this.changesService.selectVermin(id);

      case EDictionaryName.Diseases:
        return this.changesService.selectDisease(id);

      default:
    }
  };

  public deselectEntity = (name: EDictionaryName, id: string): void => {
    this.stoStore.setHasChanges(true);

    switch (name) {
      case EDictionaryName.WeedPlants:
        return this.changesService.deselectedWeedPlant(id);

      case EDictionaryName.Vermin:
        return this.changesService.deselectedVermin(id);

      case EDictionaryName.Diseases:
        return this.changesService.deselectedDisease(id);

      default:
    }
  };

  public toggleAllEntities = (name: EDictionaryName, state: boolean): void => {
    this.stoStore.setHasChanges(true);

    this.changesService.toggleAllEntities(name, state);
  };

  public searchEntities = (name: EDictionaryName, searchedValue: string): void => {
    this.changesService.searchEntities(name, searchedValue);
  };

  public initiatePage = async (params: Pick<IStoParams, 'stoId' | 'versionId'>): Promise<void> => {
    if (!this.store.isInitialLoading) this.store.setIsInitialLoading(true);

    await this.apiService.fetchDictionaryList(params);

    this.store.setIsInitialLoading(false);
  };

  public saveDictionaries = async (stoId: string): Promise<void> => {
    await this.apiService.updateDictionaries(stoId);
  };

  public downloadDictionary: ApiService['downloadDictionary'] = (params, name, actions) => {
    return this.apiService.downloadDictionary(params, name, actions);
  };

  public downloadDictionaryList: ApiService['downloadDictionaryList'] = params => {
    return this.apiService.downloadDictionaryList(params);
  };

  public createEntityOptionList = async (name: EDictionaryName): Promise<TEntitySelectOption[]> => {
    const entityList = await this.apiService.fetchDictionaryEntityList(name);

    const optionList = entityList.map<TEntitySelectOption>(entity => ({
      value: entity.id,
      label: entity.name,
      initialModel: entity,
    }));

    switch (name) {
      case EDictionaryName.WeedPlants:
        return optionList.filter(o => !this.store.weedPlantList.some(d => o.value === d.id));

      case EDictionaryName.Vermin:
        return optionList.filter(o => !this.store.verminList.some(d => o.value === d.id));

      case EDictionaryName.Diseases:
        return optionList.filter(o => !this.store.diseaseList.some(d => o.value === d.id));

      default:
        return [];
    }
  };

  public addEntityList: ChangesService['addEntityList'] = (name, entityList) => {
    this.stoStore.setHasChanges(true);

    this.changesService.addEntityList(name, entityList);
  };

  public removeEntityHighlight: ChangesService['removeHighlight'] = (name, id) => {
    this.changesService.removeHighlight(name, id);
  };

  public uploadDictionary: ApiService['uploadDictionary'] = (payload, actions) => {
    return this.apiService.uploadDictionary(payload, actions);
  };

  public clearStore = (): void => {
    this.store.clearIsInitialLoading();
    this.store.clearLoadingDictionaries();

    this.store.clearWeedPlantList();
    this.store.clearVerminList();
    this.store.clearDiseaseList();

    this.store.clearWeedPlantListToDisplay();
    this.store.clearVerminListToDisplay();
    this.store.clearDiseaseListToDisplay();

    this.store.clearSelectedWeedPlantIds();
    this.store.clearSelectedVerminIds();
    this.store.clearSelectedDiseaseIds();
  };
}

export default StoRegistriesController;
