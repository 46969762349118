import { SeasonsService } from '../../../../../../../shared/mobx/services/as-fields';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { createOperationCultureSelectOptionList } from '../../../../../../../shared/utils/helpers/selectOptions';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../../stores/seasons.store';
import { StoStore } from '../stores';

@provide.transient()
class StoOptionService {
  @lazyInject(StoStore)
  protected store: StoStore;

  @lazyInject(SeasonsService)
  protected seasonsService: SeasonsService;

  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(OrganizationsStore)
  organizationStore: OrganizationsStore;

  fetchCultureList = async (orgId?: string) => {
    await this.seasonsStore.waitSeasons();

    const organizationId =
      this.organizationStore?.selectedOrganizationId === 'my'
        ? null
        : orgId ?? this.organizationStore?.selectedOrganizationId;

    this.seasonsService
      .getCultureList({
        organizationId,
        year: Number(this.seasonsStore.selectedSeason),
      })
      .then(response => {
        if (response?.cultureAndVarietyList) {
          this.store.setCultureOptionList(
            createOperationCultureSelectOptionList(response?.cultureAndVarietyList)
          );
        }
      });
  };
}

export default StoOptionService;
