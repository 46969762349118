import { makeAutoObservable } from 'mobx';
import { isFunction } from 'lodash';

import { provide } from '../../../../../../../../../../../../shared/utils/IoC';
import { IStoContent } from '../../../../../../../../../../../../../api/models/as-fields/STO/STO.model';

type TStoreSetter<V = undefined> = (value: V | ((prevValue: V) => V)) => void;

@provide.singleton()
class StoOperationCoreStore {
  private _isLoading = true;

  private _selectedSto: IStoContent | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get selectedSto(): IStoContent {
    return this._selectedSto;
  }

  setIsLoading: TStoreSetter<boolean> = value => {
    if (isFunction(value)) {
      this._isLoading = value(this._isLoading);
    } else {
      this._isLoading = value;
    }
  };

  setSelectedSto: TStoreSetter<IStoContent> = value => {
    if (isFunction(value)) {
      this._selectedSto = value(this._selectedSto);
    } else {
      this._selectedSto = value;
    }
  };

  clearIsLoading = (): void => {
    this._isLoading = true;
  };

  clearSelectedSto = (): void => {
    this._selectedSto = null;
  };
}

export default StoOperationCoreStore;
