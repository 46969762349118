import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  pointerEvents: 'auto',
});

const SmallButton = styled('div')({
  width: '40px',
  height: '40px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  cursor: 'pointer',

  backgroundColor: Colors.generalWhite,
  borderRadius: ESpaceSize.M,

  '&:hover': {
    color: Colors.accentGreen,

    '& path': {
      stroke: Colors.accentGreen,
    },
  },
});

const Styles = {
  Wrapper,
  SmallButton,
};

export default Styles;
