import React, { FC, useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { CSSObject } from 'styled-components';

import { useDataTestIdV2 } from '../../features/utils/hooks/locators';

import useTextHighlighter from './hooks/useTextHighlighter/useTextHighlighter';
import useDebouncedChange from './hooks/useThrottledChange/useDebouncedChange';
import OptionsList from './components/OptionsList/OptionsList';
import InputWithHint from './components/Input/InputWithHint';
import useInputHint from './hooks/useInputHint/useInputHint';
import Styled from './SearchInput.styles';

interface IProps {
  optionsList: Array<any>;
  placeholder?: string;
  debounceMs?: number;
  wrapperStyles?: CSSObject;
  height?: string;
  onChange: (value: string, listLength: number) => void;
  onSelectValue?: (value: string) => void;
  onClear?: () => void;
}

const SearchInput: FC<IProps> = props => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);

  const highlightTextMatch = useTextHighlighter(inputValue);
  const getAutocompleteHint = useInputHint(inputValue, filteredOptions);
  useDebouncedChange(inputValue, filteredOptions.length, props.onChange, props.debounceMs);
  const getDataTestId = useDataTestIdV2('search-input');

  const handleChangeInput = value => {
    setInputValue(value);
  };

  const handleOptionClick = (value: string) => {
    setInputValue(value);
    props.onSelectValue?.(value);
  };

  const handleClear = () => {
    setInputValue('');
    props.onClear?.();
  };

  useEffect(() => {
    if (!inputValue) {
      setFilteredOptions([]);
      return;
    }

    const byInput = createInputValueFilter(inputValue);
    const newOptions = (props.optionsList ?? []).filter(byInput);

    setFilteredOptions(newOptions);
  }, [props.optionsList, inputValue]);

  return (
    <Styled.Wrapper $styles={props.wrapperStyles} {...getDataTestId('wrapper')}>
      <Combobox>
        {({ open }) => {
          // При пустом инпуте закрываем меню с вариантами
          const isOpen = Boolean(inputValue) && open;

          return (
            <Styled.InnerContainer>
              <Combobox.Input
                as={InputWithHint}
                isOpen={isOpen}
                value={inputValue}
                hint={getAutocompleteHint()}
                placeholder={props.placeholder}
                startIcon={!inputValue ? 'search' : null}
                endIcon={inputValue ? 'close' : null}
                height={props.height}
                onEndIconClick={handleClear}
                onChange={handleChangeInput}
                dataTestId={getDataTestId('input')['data-test-id']}
              />

              {isOpen && <Styled.Divider />}

              {isOpen && (
                <OptionsList
                  list={filteredOptions}
                  textHighlighter={highlightTextMatch}
                  onOptionClick={handleOptionClick}
                  getDataTestId={getDataTestId}
                />
              )}
            </Styled.InnerContainer>
          );
        }}
      </Combobox>
    </Styled.Wrapper>
  );
};

const createInputValueFilter = (inputValue: string) => (option: string) => {
  return option.toLowerCase().includes(inputValue.toLowerCase());
};

export default SearchInput;
