import styled, { css } from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const List = styled.ul`
  display: flex;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  flex-direction: row-reverse;
  flex-grow: 1;

  overflow-x: scroll;

  position: relative;

  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.li<{ $width: number; $isSelected?: boolean }>`
  width: ${({ $width }) => $width}px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  transition-property: color, background;

  :hover {
    background-color: ${Colors.hoverGray};
  }

  :after {
    position: absolute;
    width: 1px;
    height: 20px;
    top: auto;
    right: 0;
    content: '';
    background-color: ${Colors.borderGray};
    border-radius: 1px;
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${Colors.green};

      :hover {
        background-color: ${Colors.hoverGreen};
      }

      :after {
        content: none;
      }

      & > span {
        color: ${Colors.generalWhite};
        font-width: 600;
      }
    `};
`;

const Styles = {
  List,
  Item,
};

export default Styles;
