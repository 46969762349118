import { IMultiListFilterOption } from '../../interfaces/Filter.interface';

/**
 * Функция сортировки для списка опций мультилиста
 * Сортирует в следующем порядке:
 *
 * 1) Выбранные и не задизейбленные элементы
 *
 * 2) Не выбранные и не задизейбленные элементы
 *
 * 3) Выбранные и задизейбленные элементы
 *
 * 4) Не выбранные и задизейбленные элементы
 *
 * Все секции сортируются по алфавиту
 */

const sortOptionList = (a: IMultiListFilterOption, b: IMultiListFilterOption) => {
  const aEnabledSelected = a.selected && !a.disabled;
  const bEnabledSelected = b.selected && !b.disabled;

  const aDisabledSelected = a.selected && a.disabled;
  const bDisabledSelected = b.selected && b.disabled;

  if (aEnabledSelected && !bEnabledSelected) {
    return -1;
  }

  if (!aEnabledSelected && bEnabledSelected) {
    return 1;
  }

  if (a.disabled && !b.disabled) {
    return 1;
  }

  if (!a.disabled && b.disabled) {
    return -1;
  }

  if (aDisabledSelected && !bDisabledSelected) {
    return -1;
  }

  if (!aDisabledSelected && bDisabledSelected) {
    return 1;
  }

  return a.label.localeCompare(b.label);
};

export default sortOptionList;
