import { Colors } from '@farmlink/farmik-ui';
import styled, { CSSObject } from 'styled-components';

const MenuIconWrapper = styled('div')({
  opacity: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  marginTop: '2px',
});

const RightBlock = styled('div')({
  minWidth: 50,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
});

interface IWrapperProps {
  active?: boolean;
  $styles?: CSSObject;
}

const Wrapper = styled('div')<IWrapperProps>(
  {
    display: 'flex',
    padding: '10px 12px',
    cursor: 'pointer',
    height: 60,

    borderBottom: `1px solid ${Colors.technicalBorderGrey}`,
    boxSizing: 'border-box',

    [`&:hover ${MenuIconWrapper}`]: {
      opacity: 1,
    },
  },
  ({ active, $styles }) => ({
    '&:hover': {
      backgroundColor: active ? Colors.lightGreen : Colors.hoverGray,
    },

    ...$styles,
    backgroundColor: active ? Colors.lightGreen : $styles?.backgroundColor ?? 'inherit',
  })
);

export default {
  Wrapper,
  RightBlock,
  MenuIconWrapper,
};
