import { useModal } from '@farmlink/farmik-ui';
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import {
  CulturesLegendMapControl,
  EMapControlPlace,
  GoogleSearchMapControl,
  LayerFillMapControl,
  LayerTooltipMapControl,
  LocationMapControl,
  ZoomMapControl,
} from '../../../../../shared/features/map/components/Controls';
import { EFieldFill } from '../../../../constants/FieldFill.enum';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import { MapDrawControls } from '../../components';
import FieldsLayout from '../../FieldsLayout';

import { FIELDS_CREATE_MODALS_UNIQUE_KEY, fieldsCreateModalConfigList } from './modals';
import { FieldsCreateController as Controller } from './mobx';
import FieldsCreateList from './components/List/List';

const FieldsCreatePage: FC = () => {
  // Сторы
  const fieldsStore = useStore(FieldsStore);

  // Контроллеры
  const controller = useStore(Controller);

  const modal = useModal();

  useEffect(() => {
    void controller.initialize();

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    modal.registerModalList(fieldsCreateModalConfigList, FIELDS_CREATE_MODALS_UNIQUE_KEY);
  }, []);

  return (
    <FieldsLayout
      id={'fields-create'}
      leftPanel={<FieldsCreateList />}
      map={{
        config: {
          controls: [
            { id: 'drawPanel', place: EMapControlPlace.TOP, component: <MapDrawControls /> },
            { id: 'search', place: EMapControlPlace.TOP, component: <GoogleSearchMapControl /> },
            {
              id: 'tooltip',
              place: EMapControlPlace.TOP,
              component: <LayerTooltipMapControl allFields={fieldsStore.fieldsList} />,
            },
            {
              id: 'fill',
              place: EMapControlPlace.TOP,
              component: <LayerFillMapControl skipOptions={[EFieldFill.Ndvi, EFieldFill.Msavi]} />,
            },
            {
              id: 'culturesLegend',
              place: EMapControlPlace.TOP,
              component: <CulturesLegendMapControl />,
              hidden: true,
            },

            { id: 'zoom', place: EMapControlPlace.RIGHT, component: <ZoomMapControl /> },
            { id: 'location', place: EMapControlPlace.RIGHT, component: <LocationMapControl /> },
          ],
          pointCoordinatesControl: true,
        },
      }}
    />
  );
};

export default observer(FieldsCreatePage);
