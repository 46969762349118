import { LatLngBounds } from 'leaflet';

/**
 * Проверяет что переданные границы находятся внутри viewport карты
 */
function isBoundsInViewport(map: L.Map, layer: { getBounds: () => LatLngBounds }): boolean {
  if (typeof layer.getBounds !== 'function') {
    return false;
  }

  if (typeof map?.getBounds !== 'function') {
    return false;
  }

  return map.getBounds().intersects(layer.getBounds());
}

export default isBoundsInViewport;
