import { Colors } from '../../../../../../../../shared/constans/colors';
import { IMapPolygonElementConfig as TPolygonElementConfig } from '../../../../../../../../shared/features/map/models';

const ZONE_STYLE_EDIT: TPolygonElementConfig['options']['style'] = {
  color: Colors.addSunny,
  fillColor: Colors.white,
  fillOpacity: 0.15,
  className: 'task-culture-zone',
};

const ZONE_STYLE_VIEW: TPolygonElementConfig['options']['style'] = {
  color: Colors.addSunny,
  fillColor: Colors.white,
  fillOpacity: 0.15,
};

const ZONE_STYLE_INDEX_NDVI: TPolygonElementConfig['options']['style'] = {
  color: Colors.sky,
  fillColor: Colors.white,
  fillOpacity: 0.15,
};

const InspectionPointsElementStyles = {
  zoneEdit: ZONE_STYLE_EDIT,
  zoneView: ZONE_STYLE_VIEW,
  zoneIndexNdvi: ZONE_STYLE_INDEX_NDVI,
};

export default InspectionPointsElementStyles;
