import { sortBy } from 'lodash';

import { lazyInject, provide } from '../../../../../../../../../../../../../../shared/utils/IoC';
import { StoService } from '../../../../../../../../../../../../../../shared/mobx/services/as-fields';
import { TypeApiRequest } from '../../../../../../../../../../../../../../shared/utils/axios2';
import {
  IStoChecklistsParamsTableAttribute,
  IStoChecklistsParamsTableChecklist as ITableChecklist,
  IStoChecklistsParamsTableStage,
  IStoChecklistsParamsTableValue,
} from '../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import {
  IComparisonTableBuilderCellConfig,
  IComparisonTableBuilderColumnConfig,
  IComparisonTableBuilderConfig,
  IComparisonTableBuilderRowConfig,
  IComparisonTableBuilderRowsGroupConfig,
} from '../../../../../../../../../../../../../../shared/features/ComparisonTableBuilder/models/configs';
import { ComparisonTableBuilderController } from '../../../../../../../../../../../../../../shared/features/ComparisonTableBuilder/mobx/controllers';
import { StoChecklistsCoreStore as CoreStore } from '../../stores';
import {
  StoChecklistsAttrCell,
  StoChecklistsBooleanCell,
  StoChecklistsCellContainer,
  StoChecklistsRowsGroup,
  StoChecklistsSettingsCell,
} from '../../../components';
import StoChecklistsColumn from '../../../components/StoChecklistsColumn/StoChecklistsColumn';
import { StoChecklistsTableChangesService as TableChangesService } from '../StoChecklistsTableChangesService';
import { EStoChecklistsTableColumnName as ETableColumnName } from '../../../constants';
import { StoChecklistsTableHelpers as TableHelpers } from '../../../helpers';

@provide.transient()
class StoChecklistsTableConfigsService {
  @lazyInject(CoreStore)
  protected coreStore: CoreStore;

  @lazyInject(StoService)
  protected stoService: StoService;

  @lazyInject(TableChangesService)
  protected tableChangesService: TableChangesService;

  @lazyInject(ComparisonTableBuilderController)
  protected comparisonTableController: ComparisonTableBuilderController;

  /**
   * Коллекция формата:
   * идентификатор ряда к списку моделей-конфигов ячеек.
   */
  protected cellConfigsByColumnIdByRowI: Map<
    string,
    IComparisonTableBuilderCellConfig[]
  > = new Map();

  protected getCellConfigs = (): IComparisonTableBuilderConfig['cellConfigs'] => {
    return Object.fromEntries([...this.cellConfigsByColumnIdByRowI.entries()]);
  };

  public initiateTables = async (
    payload: Parameters<typeof this.fetchTables>[0]
  ): Promise<IComparisonTableBuilderConfig[]> => {
    const fetchedTableList = await this.fetchTables(payload);
    const tableList = sortBy(fetchedTableList, 'name');

    this.coreStore.setChecklistList(tableList);

    const hasOnlyOneTable = tableList.length === 1;
    const hasAtLeastOneAlreadySelected = tableList.some(({ isSelected }) => isSelected);

    if (hasOnlyOneTable) {
      const [firstTable] = tableList;

      const config = this.createTableConfig(firstTable.id, firstTable.stages, true);
      this.comparisonTableController.initiateTable(config);

      return [config];
    }

    const configList = tableList.map((table, index) => {
      const isNeedToBeSelected = index === 0 && !hasAtLeastOneAlreadySelected;

      return this.createTableConfig(table.id, table.stages, isNeedToBeSelected || table.isSelected);
    });

    configList.forEach(config => {
      this.comparisonTableController.initiateTable(config);
    });

    return configList;
  };

  public createBooleanCellConfigForUpdate = ({
    stageId,
    attributeId,
    columnId,
    value,
  }: {
    stageId: string;
    attributeId: string;
    columnId: string;
    value: IStoChecklistsParamsTableValue;
  }): IComparisonTableBuilderCellConfig => {
    const rowId = TableHelpers.createRowId(stageId, attributeId);

    const valueConfig: IComparisonTableBuilderCellConfig = {
      columnId,
      rowId,
      customRenderConfig: {
        render: () => (
          <StoChecklistsCellContainer stageId={stageId} attrId={attributeId}>
            <StoChecklistsBooleanCell value={value.booleanValue} />
          </StoChecklistsCellContainer>
        ),
      },
    };

    return valueConfig;
  };

  protected createTableConfig = (
    builderId: string,
    stageList: IStoChecklistsParamsTableStage[],
    isNeedToSelectThisChecklist: boolean
  ): IComparisonTableBuilderConfig => {
    this.cellConfigsByColumnIdByRowI = new Map();

    const config: IComparisonTableBuilderConfig = {
      id: builderId,
      name: 'sto__checklists-parameters__comparison-table',
      headerConfig: {
        autoRenderConfig: {
          preset: 'cleared',
        },
      },
      columnConfigList: this.createColumnConfigList(),
      rowsGroupConfigList: this.createRowsGroupConfigList(stageList, builderId),
    };

    config.cellConfigs = this.getCellConfigs();

    this.tableChangesService.changeChecklist(
      builderId,
      {
        id: builderId,
        isSelected: isNeedToSelectThisChecklist,
      },
      { isWithoutChangeLogging: true }
    );

    return config;
  };

  protected createColumnConfigList = (): IComparisonTableBuilderColumnConfig[] => {
    return [
      this.createAttributeColumnConfig(),
      this.createRequiredColumnConfig(),
      this.createPhotosColumnConfig(),
      this.createHelpColumnConfig(),
      this.createOdzColumnConfig(),
      this.createSettingsColumnConfig(),
    ];
  };

  protected createAttributeColumnConfig = (): IComparisonTableBuilderColumnConfig => {
    return {
      id: 'attribute',
      width: '242px',
      isFixed: true,
      customRenderConfig: {
        render: () => <StoChecklistsColumn name={'Параметр'} isWithoutTextAlign />,
      },
    };
  };

  protected createRequiredColumnConfig = (): IComparisonTableBuilderColumnConfig => {
    return {
      id: 'required',
      width: '1fr',
      isFixed: true,
      customRenderConfig: {
        render: () => <StoChecklistsColumn name={'Обязательный'} />,
      },
    };
  };

  protected createPhotosColumnConfig = (): IComparisonTableBuilderColumnConfig => {
    return {
      id: 'photos',
      width: '1fr',
      isFixed: true,
      customRenderConfig: {
        render: () => <StoChecklistsColumn name={'Фотографии'} />,
      },
    };
  };

  protected createHelpColumnConfig = (): IComparisonTableBuilderColumnConfig => {
    return {
      id: 'help',
      width: '1fr',
      isFixed: true,
      customRenderConfig: {
        render: () => <StoChecklistsColumn name={'Подсказка'} />,
      },
    };
  };

  protected createOdzColumnConfig = (): IComparisonTableBuilderColumnConfig => {
    return {
      id: 'odz',
      width: '1fr',
      isFixed: true,
      customRenderConfig: {
        render: () => <StoChecklistsColumn name={'ОДЗ'} />,
      },
    };
  };

  protected createSettingsColumnConfig = (): IComparisonTableBuilderColumnConfig => {
    return {
      id: 'settings',
      width: '94px',
      isFixed: true,
      autoRenderConfig: {},
    };
  };

  protected createRowsGroupConfigList = (
    stageList: IStoChecklistsParamsTableStage[],
    checklistId: string
  ): IComparisonTableBuilderRowsGroupConfig[] => {
    return stageList.map(stage => this.createRowsGroupConfig(stage, checklistId));
  };

  protected createRowsGroupConfig = (
    { id, name, attributes }: IStoChecklistsParamsTableStage,
    checklistId: string
  ): IComparisonTableBuilderRowsGroupConfig => {
    return {
      id,
      rowConfigList: this.createRowConfigList(attributes, checklistId, id),
      customRenderConfig: {
        render: (_rowsGroup, rowsChildren) => (
          <StoChecklistsRowsGroup name={name} rowsChildren={rowsChildren} />
        ),
      },
    };
  };

  protected createRowConfigList = (
    attrList: IStoChecklistsParamsTableAttribute[],
    checklistId: string,
    stageId: string
  ): IComparisonTableBuilderRowConfig[] => {
    return attrList.map((attr, i) =>
      this.createRowConfig(attr, i === attrList.length - 1, checklistId, stageId)
    );
  };

  protected createRowConfig = (
    attribute: IStoChecklistsParamsTableAttribute,
    isTheLastElement: boolean,
    checklistId: string,
    stageId: string
  ): IComparisonTableBuilderRowConfig => {
    const { id, name, values, customProp } = attribute;

    const rowId = TableHelpers.createRowId(stageId, id);

    const rowConfig: IComparisonTableBuilderRowConfig = {
      id: rowId,
      customRenderConfig: {
        render: () => (
          <StoChecklistsCellContainer
            stageId={stageId}
            attrId={id}
            isWithoutAlign
            isWithoutBottomBorder={isTheLastElement}
          >
            <StoChecklistsAttrCell attrId={id} stageId={stageId} name={name} />
          </StoChecklistsCellContainer>
        ),
      },
    };

    const cellConfigList = [
      ...this.createCellConfigList(rowConfig, values, isTheLastElement),
      this.createSettingsCellConfig(rowConfig, isTheLastElement),
    ];

    this.cellConfigsByColumnIdByRowI.set(rowConfig.id, cellConfigList);

    this.tableChangesService.changeAttr(
      checklistId,
      stageId,
      {
        id,
        isSelected: customProp.isSelected,
        technicalProps: {
          isUnsaved: !customProp.isSelected,
        },
      },
      { isWithoutChangeLogging: true }
    );

    return rowConfig;
  };

  protected getColumnIdByOrder = (order: number): ETableColumnName => {
    switch (order) {
      case 0:
        return ETableColumnName.Required;

      case 1:
        return ETableColumnName.Photos;

      case 2:
        return ETableColumnName.Help;

      case 3:
        return ETableColumnName.Odz;

      default:
    }
  };

  protected createCellConfigList = (
    rowConfig: IComparisonTableBuilderRowConfig,
    values: IStoChecklistsParamsTableValue[],
    isTheLastElement: boolean
  ): IComparisonTableBuilderCellConfig[] => {
    return values.map((value, index) =>
      this.createBooleanCellConfig({
        rowConfig,
        value,
        order: index,
        isTheLastElement,
      })
    );
  };

  protected createBooleanCellConfig = ({
    rowConfig,
    value,
    order,
    isTheLastElement,
  }: {
    rowConfig: IComparisonTableBuilderRowConfig;
    value: IStoChecklistsParamsTableValue;
    order: number;
    isTheLastElement: boolean;
  }): IComparisonTableBuilderCellConfig => {
    const stageId = TableHelpers.getStageId(rowConfig.id);
    const attributeId = TableHelpers.getAttributeId(rowConfig.id);

    const valueConfig: IComparisonTableBuilderCellConfig = {
      columnId: this.getColumnIdByOrder(order),
      rowId: rowConfig.id,
      customRenderConfig: {
        render: () => (
          <StoChecklistsCellContainer
            stageId={stageId}
            attrId={attributeId}
            isWithoutBottomBorder={isTheLastElement}
          >
            <StoChecklistsBooleanCell value={value.booleanValue} />
          </StoChecklistsCellContainer>
        ),
      },
    };

    return valueConfig;
  };

  protected createSettingsCellConfig = (
    rowConfig: IComparisonTableBuilderRowConfig,
    isTheLastElement: boolean
  ): IComparisonTableBuilderCellConfig => {
    const stageId = TableHelpers.getStageId(rowConfig.id);
    const attributeId = TableHelpers.getAttributeId(rowConfig.id);

    const valueConfig: IComparisonTableBuilderCellConfig = {
      columnId: 'settings',
      rowId: rowConfig.id,
      customRenderConfig: {
        render: () => (
          <StoChecklistsCellContainer
            stageId={stageId}
            attrId={attributeId}
            isWithoutBottomBorder={isTheLastElement}
          >
            <StoChecklistsSettingsCell stageId={stageId} attributeId={attributeId} />
          </StoChecklistsCellContainer>
        ),
      },
    };

    return valueConfig;
  };

  protected fetchTables = async (
    payload: TypeApiRequest<'getStoChecklistsParamsTable'>
  ): Promise<ITableChecklist[]> => {
    const response = await this.stoService.getStoChecklistsParamsTable(payload);

    if (!response) {
      return [];
    }

    return response.checklists;
  };
}

export default StoChecklistsTableConfigsService;
