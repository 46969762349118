import { FC, useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../../../mobx/stores';
import { TaskController } from '../../../../../../mobx/controllers';
import { TDropdownConfig } from '../../../../../../../../../shared/components/inputs/Dropdown/Dropdown.types';
import { SELECT_ALL_OPTION } from '../../../../../../../../../shared/components/inputs/Dropdown/constants';
import { FullscreenContext } from '../../../../../../containers/fullscreen';

const FieldSelect: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const bodyForceCloseOptions = useContext(FullscreenContext);

  const isCreate = taskStore.isCreateMultiple || taskStore.isCreateOne;

  const hasEmptyOperationId = isCreate ? !taskStore.taskCreate?.operationId : false;

  const isBlocked = !isCreate || taskStore.isEditMode || hasEmptyOperationId;

  const handleOneChange = useCallback<TDropdownConfig['field']['onChange']>(
    (cultureZoneId: string): void => {
      taskController.changeField(cultureZoneId ?? '');
    },
    []
  );

  const handleMultipleChange = useCallback<TDropdownConfig['field']['onChange']>(
    (selectedValue, otherData): void => {
      if (selectedValue === SELECT_ALL_OPTION.value) {
        taskController.changeFieldList(otherData.optionList);
      } else if (otherData?.selectedValueList) {
        taskController.changeFieldList(otherData.selectedValueList);
      } else {
        taskController.changeFieldList([]);
      }
    },
    []
  );

  if (taskStore.isCreateMultiple) {
    return (
      <NewDropdown
        bodyForceCloseOptions={bodyForceCloseOptions}
        config={{
          field: {
            onChange: handleMultipleChange,
            placeholder: 'Выберите поле',
            defaultValueList: taskController.fieldDefaultValueList,
            type: {
              search: {
                options: {
                  isHideSearchFieldAutocomplete: true,
                },
              },
              multiselect: {
                options: {
                  itemStyle: 'checkbox',
                },
                counter: {
                  label: 'Выбрано:',
                },
                selectAll: true,
                isSelectedFirst: true,
              },
            },
            icons: {
              clear: {},
            },
          },
          body: {
            optionList: taskStore.fieldOptionList,
            isKeepSelectedOptions: true,
          },
          visual: {
            label: 'Поле',
            isBlocked,
            isPreventCloseAfterSelect: true,
          },
          other: {
            dataTestId: 'task-field-group-select',
          },
        }}
      />
    );
  }

  return (
    <NewDropdown
      bodyForceCloseOptions={bodyForceCloseOptions}
      config={{
        field: {
          onChange: handleOneChange,
          placeholder: 'Выберите поле',
          /**
           * Костыль. В связи с особенностью работы компонента, нужно передавать массив в качестве
           * дефолтного значения. В противном случае не будет сбрасываться выбранное значение.
           */
          defaultValueList: taskStore.isCreateOne
            ? [
                taskStore.fieldOptionList?.find(
                  ({ value }) => value === taskStore.taskCreate?.cultureZoneId
                ),
              ]
            : taskStore.operationFieldDefaultValue,
          type: {
            search: {
              options: {
                isHideSearchFieldAutocomplete: true,
              },
            },
          },
          icons: {
            clear: {},
          },
        },
        body: {
          optionList: taskStore.fieldOptionList,
        },
        visual: {
          label: 'Поле',
          isBlocked,
        },
        other: {
          dataTestId: 'task-field-select',
        },
      }}
    />
  );
};

FieldSelect.displayName = 'FieldSelect';

export default observer(FieldSelect);
