const getStageId = (rowId: string): string => {
  const [stageId] = rowId.split('--//--');

  return stageId;
};

const getAttributeId = (rowId: string): string => {
  const [, attrId] = rowId.split('--//--');

  return attrId;
};

const createRowId = (stageId: string, attrId: string): `${string}--//--${string}` => {
  return `${stageId}--//--${attrId}`;
};

export const StoChecklistsTableHelpers = {
  getStageId,
  getAttributeId,
  createRowId,
};
