import { ReactElement } from 'react';

export enum ESelectType {
  MULTISELECT,
  SINGLE,
}

export interface IOptionItem<T = unknown> {
  value: T;
  label: string;
}

type TValueByType<T extends IOptionItem[], TYPE> = TYPE extends ESelectType.SINGLE
  ? T[number]['value']
  : T[number]['value'][];

type TBaseProps<T extends IOptionItem[], TYPE> = {
  type: TYPE;

  optionList: T;
  label: string;

  renderButton: (selectedValue: TValueByType<T, TYPE>) => ReactElement;
  value?: TValueByType<T, TYPE>;
  defaultValue?: TValueByType<T, TYPE>;
  onChange?: (selectedValue: TValueByType<T, TYPE>) => void;
};

// Возможно добавить доп опции для мультиселекта. Они будут доступны только если type = ESelectType.MULTISELECT
export type TMultiselectProps<T extends IOptionItem[]> = TBaseProps<
  T,
  ESelectType.MULTISELECT
> & {};

export type TSelectWithCustomButtonProps<T extends IOptionItem[]> =
  | TBaseProps<T, ESelectType.SINGLE>
  | TMultiselectProps<T>;
