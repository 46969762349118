import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  margin-bottom: 12px;
`;

const StyledComparisonTableBuilderClearedHeader = {
  Wrapper,
};

export default StyledComparisonTableBuilderClearedHeader;
