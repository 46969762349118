import { FC } from 'react';

import { Colors } from '../../../../../../../../../shared/constans/colors';
import AddPointSvg from '../../../../../../assets/map-add-entity-mock.svg';

import Styled from './DrawInfoPlaceholder.styles';

interface IProps {
  fieldsCount: number;
}

const DrawInfoPlaceholder: FC<IProps> = ({ fieldsCount }) => {
  const hasFields = Boolean(fieldsCount);

  if (fieldsCount > 2) {
    return null;
  }

  if (!hasFields) {
    return (
      <Styled.Wrapper>
        <Styled.Image src={AddPointSvg} />

        <Styled.Title data-test-id={'fields-add-polygon-points'}>
          Проставьте точки контура поля на карте
        </Styled.Title>

        <Styled.Description data-test-id={'fields-add-info'}>
          После замыкания контура можно будет добавить еще поля
        </Styled.Description>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Description data-test-id={'fields-add-info'} $color={Colors.darkGray}>
        Продолжайте рисовать новые поля в нужном количестве
      </Styled.Description>
    </Styled.Wrapper>
  );
};

export default DrawInfoPlaceholder;
