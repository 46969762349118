import styled from 'styled-components';
import { Typography } from '@farmlink/farmik-ui';

import { Colors } from '../../../../../shared/constans/colors';

const TasksDrawer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 16px 24px 24px;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;

  background-color: #ffffff;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 18px;

  & .task-status-tooltip span {
    white-space: break-spaces;
    overflow-wrap: break-word;
  }
`;

const FixedTitle = styled.div`
  display: block;
  position: sticky;
`;


const DetailsButton = styled.button.attrs({ type: 'button' })`
  width: 160px;
  height: 40px;
  background: ${Colors.green};
  color: ${Colors.white};
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  transition: background 0.3s ease-in-out;

  :hover {
    background: ${Colors.greenHover};
  }
`;
const OperationInfoContent = styled.div`
  display: flex;
  align-items: center;
`;
const OperationTypeName = styled(Typography)`
  max-width: 406px;
  position: relative;
  margin-right: 12px;
`;

const SvgContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  svg {
    cursor: pointer;
    &:hover path {
      fill: ${Colors.green}!important;
    }

    &:hover rect {
      fill: ${Colors.lightGreen}!important;
    }
  }
`;

const FixedFooter = styled.div`
  display: block;
  position: sticky;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
`;

const StyledTasksDrawer = {
  TasksDrawer,
  Header,
  FixedTitle,
  FixedFooter,
  DetailsButton,
  Footer,
  OperationInfoContent,
  OperationTypeName,
  SvgContent
};

export default StyledTasksDrawer;
