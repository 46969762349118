import React, { FC } from 'react';

import { STOFiltersContainer as STOFilters } from '../STOFiltersContainer';
import { ETableFiltersBuilderId } from '../../../../constants/configs/TableFiltersBuilderId';
import { StoListContainer as StoList } from '../StoListContainer';
import { ListingPage } from '../../../../../shared/features/UI/ListingPage';

const STOListingContainer: FC = () => {
  return (
    <ListingPage id={ETableFiltersBuilderId.Sto} filters={<STOFilters />} list={<StoList />} />
  );
};

STOListingContainer.displayName = 'STOListingContainer';

export default STOListingContainer;
