import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC } from 'react';

import { useDataTestIdV2 } from '../../../features/utils/hooks/locators';
import warningIcon from '../../../../dashboard/static/warning-simple.svg';

import Styled from './PointOutOfFieldWarning.styles';

interface IProps {
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const PointOutOfFieldWarningIcon: FC<IProps> = ({ getDataTestId }) => {
  return (
    <AutoTooltip
      content={'Точка находится вне границ поля.\nОбратитесь к руководителю'}
      position={'bottom-right'}
    >
      <Styled.Icon $url={warningIcon} {...getDataTestId('warning-icon')} />
    </AutoTooltip>
  );
};

export default PointOutOfFieldWarningIcon;
