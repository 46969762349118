import { TypeApiRoute } from '../../models/type.api.request';
import { Field } from '../../models/field.model';

type TypeRequest = {
  fields: string[];
  allSeasons: boolean;
  seasonYear: number;
  organizationId?: string;
};

type TypeResponse = Field;

export const deleteFieldsById: TypeApiRoute & {
  request: TypeRequest | ((request: any) => TypeRequest);
  response: TypeResponse;
} = {
  url: () => `/api/as-fields/fields/byFieldIds`,
  method: 'DELETE',
  headers: {},
  request: req => req,
  response: {} as TypeResponse,
};
