import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { IStoAttributeOdzGroupPut as IOdzGroupPut } from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import {
  StoAttributeNumericOdz as NumericOdz,
  StoAttributeDictionaryOdz as DictionaryOdz,
} from '../../components';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreController as Controller } from '../../../../../../mobx/controllers';

interface IProps {
  group: IOdzGroupPut;
}

const StoAttributeOdzGroupContainer: FC<IProps> = ({ group }) => {
  const controller = useStore(Controller);

  if (controller.isNumericOdz) {
    return <NumericOdz {...group} />;
  }

  if (controller.isDictionaryOdz) {
    return <DictionaryOdz {...group} />;
  }

  return <></>;
};

StoAttributeOdzGroupContainer.displayName = 'StoAttributeOdzGroupContainer';

export default observer(StoAttributeOdzGroupContainer);
