import { Tabs, Typography } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useEffect, useMemo } from 'react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { useStoNavigation, useStoParams, useStoActionControl } from '../../../../hooks';
import { ERequestStatus } from '../../../../../../../shared/constants/requests';
import { SpinnerLoader } from '../../../../../../../shared/components/loaders';
import { useWarningBeforeLeavingThePage } from '../../../../../../../shared/hooks';
import { Fullscreen } from '../../../../../../../shared/containers';
import { EStoTab } from '../../models/StoCommon';
import { StoController, StoStore } from '../../mobx';
import { StoControlButtons } from '../../common/SummaryForm/components';
import { StoActionButton } from '../../common';
import { StoStatusChip as StatusChip } from '../../../../components';

import Styled from './StoContainer.styles';

const StoContainer: FC = () => {
  const store = useStore(StoStore);
  const controller = useStore(StoController);

  const {
    navigateToListing,
    isCreationPath,
    currentTabFromURL,
    navigateToURLByTab,
    navigateToNotFound,
    navigateToSto,
  } = useStoNavigation();
  const { stoId, versionId } = useStoParams();
  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage(store.hasChanges);
  const { isAllowToEdit } = useStoActionControl();

  useEffect(() => {
    controller
      .initiateSto({
        stoId,
        isCreation: isCreationPath,
        tabFromUrl: currentTabFromURL,
        ...(versionId && { versionId }),
      })
      .then(response => {
        store.setIsViewOnly(response?.isViewOnly || !isAllowToEdit || Boolean(versionId));
      })
      .catch(error => {
        if (error.message === 'STO_NOT_FOUND') navigateToNotFound();
      });

    return () => {
      store.clearStoStore();
    };
  }, [stoId, versionId]);

  const changeTab = (tabId: EStoTab) => {
    if (store.hasChanges) {
      showWarningBeforeLeavingThePage(() => {
        store.clearHasChanges();
        controller.changeStoTab(tabId);

        navigateToURLByTab(stoId, tabId, true, { query: { ...(versionId && { versionId }) } });
      });
    } else {
      controller.changeStoTab(tabId);
      navigateToURLByTab(stoId, tabId, true, { query: { ...(versionId && { versionId }) } });
    }
  };

  // const fieldsWordCase = useMemo<string>(() => {
  //   let text;
  //   const countMod10 = store?.selectedSto?.cultureZones?.length % 10;
  //   const countMod100 = store?.selectedSto?.cultureZones?.length % 100;
  //
  //   if (countMod10 === 1 && countMod100 !== 11) {
  //     text = 'поле';
  //   } else if (countMod10 >= 2 && countMod10 <= 4 && (countMod100 < 10 || countMod100 >= 20)) {
  //     text = 'поля';
  //   } else {
  //     text = 'полей';
  //   }
  //
  //   return text;
  // }, [store?.selectedSto?.cultureZones?.length]);

  const underTitleContent = useMemo(() => {
    const subtitleItemList = [];

    // Текстовка появляется только когда проваливаемся в настройку операций
    // if (store?.selectedSTO?.name) subtitleItemList.push(store?.selectedSTO?.name);

    if (store?.selectedSto?.culture?.name) subtitleItemList.push(store?.selectedSto?.culture?.name);

    if (store?.selectedSto?.cultureZones?.length > 0)
      subtitleItemList.push(`Поля: ${store?.selectedSto?.cultureZones?.length}`);

    if (!subtitleItemList.length) return null;

    return (
      <Styled.UnderTitleTypography data-test-id="sto-title-subtitle">
        {subtitleItemList.join(' • ')}
      </Styled.UnderTitleTypography>
    );
  }, [store.selectedSto]);

  const arrowText = Boolean(versionId)
    ? 'К текущей версии'
    : store?.StoFullscreenConfig?.goBackText;

  const goBackHandler = Boolean(versionId) ? () => navigateToSto(stoId) : () => navigateToListing();
  if (store.requestStoStatus === ERequestStatus.Pending) {
    return <SpinnerLoader needToHideContent />;
  }

  return (
    <Styled.Wrapper>
      <Fullscreen
        backButtonText={
          <Styled.TitlePaddingWrapper>
            <Typography className="header-title" variant="h4">
              <Styled.TitleWrapper>
                <span data-test-id="sto-title">
                  {versionId && 'Просмотр версий. '}
                  {store?.selectedSto?.name ??
                    store?.StoFullscreenConfig?.titleText ??
                    'Стандарт техоперации'}
                </span>

                <Styled.ChipWrapper>
                  <StatusChip calculatedStatus={store.selectedSto?.calculatedStatus} />
                </Styled.ChipWrapper>
              </Styled.TitleWrapper>
            </Typography>
          </Styled.TitlePaddingWrapper>
        }
        rightSideContent={<StoActionButton />}
        arrowText={arrowText}
        backButtonHandler={goBackHandler}
        underTitleContent={underTitleContent}
        contentStyle={{
          $paddings: '4px 0 32px',
        }}
      >
        <Tabs
          stylePreset="secondary"
          content={store?.availableTabList}
          selectedTabId={store.StoTab}
          onChangeTab={changeTab}
          dataTestId="sto-tab"
          size="small"
        />
        {store?.StoFullscreenConfig?.fullscreenContent}

        <StoControlButtons />
      </Fullscreen>
    </Styled.Wrapper>
  );
};

StoContainer.displayName = 'StoContainer';

export default observer(StoContainer);
