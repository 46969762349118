import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { StoPublishModalFrom } from './components';

const STO_PUBLISH_MODAL_ID = 'sto-publish-modal-id';

const StoPublishModalConfig: TModalConfig = {
  id: STO_PUBLISH_MODAL_ID,
  name: STO_PUBLISH_MODAL_ID,
  title: null,
  type: EModalType.Custom,
  styledProps: { $modalPaddings: '40px 60px', $isFullContentWidth: true, $size: '440px' },
  children: StoPublishModalFrom,
};

export { StoPublishModalConfig, STO_PUBLISH_MODAL_ID };
