import { makeAutoObservable } from 'mobx';

import { provide } from '../../shared/utils/IoC';

@provide.singleton()
export class GeneralDataStore {
  isDataLoading = false;

  debouncedDataLoading = false;

  intervalId;

  constructor() {
    makeAutoObservable(this);
  }

  setIsDataLoading = (value: boolean) => {
    this.isDataLoading = value;

    if (value) {
      if (!this.debouncedDataLoading) {
        this.debouncedDataLoading = true;
        this.intervalId = setInterval(() => {
          if (!this.isDataLoading) {
            this.setIsDataLoading(false);
            this.debouncedDataLoading = false;
            clearInterval(this.intervalId);
          }
        }, 1000);
      }
    }
  };
}
