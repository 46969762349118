import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizesObj } from '../../../../constans/sizes';

const Button = styled('div')({
  width: 160,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: '10px 12px',

  borderTopLeftRadius: ESpaceSize.M,
  borderTopRightRadius: ESpaceSize.M,

  backgroundColor: Colors.generalWhite,

  cursor: 'pointer',
  overflow: 'hidden',

  ...FontSizesObj.medium,
});

export default { Button };
