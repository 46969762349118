import { FC, memo, PropsWithChildren } from 'react';

import Styled from './ChecklistsButton.styles';

interface IProps {
  onClick: () => void;
  dataTestId: string;
}

const ChecklistsButton: FC<PropsWithChildren<IProps>> = ({ onClick, dataTestId, children }) => {
  return (
    <Styled.Wrapper
      type={'button'}
      color={'primary'}
      styleType={'outlined'}
      alignment={'center'}
      onClick={onClick}
      dataTestId={dataTestId}
    >
      {children}
    </Styled.Wrapper>
  );
};

ChecklistsButton.displayName = 'ChecklistsButton';

export default memo(ChecklistsButton);
