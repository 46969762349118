import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

import { CultureZone, Field } from '../../../../../../../../api/models/field.model';
import { IMapPolygonError, IMapPolygonInfo } from '../../../../../../../shared/features/map/models';
import { provide } from '../../../../../../../shared/utils/IoC';

const MAX_FIELD_AREA = 3000;

@provide.singleton()
class EditFieldsStore {
  private _editableField: Field | null = null;

  /**
   * Используется для проверки что поле изменилось в процессе редактирования
   */
  private _initialEditableField: Field | null = null;

  private _isCultureZoneOutOfField: boolean | null = null;

  private _hasError = false;

  private _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get editableField(): Field | null {
    return this._editableField;
  }

  get isFieldChanged() {
    const editableField = { ...this._editableField };
    const initialEditableField = { ...this._initialEditableField };

    delete editableField.areaInHectare;
    delete initialEditableField.areaInHectare;

    return !_.isEqual(initialEditableField, editableField);
  }

  get isEditableFieldHasCultures() {
    return Boolean(this.editableField?.cultureZones.length);
  }

  get isAreaTooBig() {
    return this._editableField?.areaInHectare > MAX_FIELD_AREA;
  }

  get hasError() {
    return this._hasError;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  get isCultureZoneOutOfField() {
    return this._isCultureZoneOutOfField;
  }

  set isCultureZoneOutOfField(value) {
    this._isCultureZoneOutOfField = value;
  }

  setEditableField(field: Field, polygonInfo: IMapPolygonInfo) {
    runInAction(() => {
      this.clear();

      this._editableField = { ...field };
      this._initialEditableField = { ...field };

      this._editableField.areaInHectare = polygonInfo.area;
    });
  }

  setErrorToField(error: IMapPolygonError | null) {
    this._editableField.error = Boolean(error);
    this._editableField.errorDescription = error?.message ?? null;

    this._hasError = Boolean(error);
  }

  changeEditableFieldName(name: string) {
    this._editableField.name = name;
  }

  updateEditableField(info: IMapPolygonInfo, cultureZones: CultureZone[]) {
    this._editableField.areaInHectare = info.area;
    this._editableField.geometry = info.geometry;

    if (Array.isArray(cultureZones) && cultureZones.length) {
      this._editableField.cultureZones = cultureZones;
    }
  }

  clear() {
    this._editableField = null;
    this._initialEditableField = null;
    this._isCultureZoneOutOfField = null;
  }
}

export default EditFieldsStore;
