import React, { FC, memo, ReactNode } from 'react';

import Styled from './StoChecklistsRowsGroup.styled';

interface IProps {
  name: string;
  rowsChildren: ReactNode;
}

const StoChecklistsRowsGroup: FC<IProps> = ({ name, rowsChildren }) => {
  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>{name}</Styled.Title>
      </Styled.Header>

      {rowsChildren}
    </Styled.Wrapper>
  );
};

StoChecklistsRowsGroup.displayName = 'StoChecklistsRowsGroup';

export default memo(StoChecklistsRowsGroup);
