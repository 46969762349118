import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import {
  TaskOperationViewApiService as ApiService,
  TaskOperationViewDefaultValuesService as DefaultValuesService,
} from '../../services';
import { TaskOperationViewStore as Store } from '../../stores';
import { TasksParams } from '../../../../../models';

@provide.transient()
class TaskOperationViewController {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ApiService)
  protected apiService: ApiService;

  @lazyInject(DefaultValuesService)
  protected defaultValuesService: DefaultValuesService;

  public deleteTask = (): void => {
    this.apiService.deleteTask();
  };

  public get cultureName() {
    return this.defaultValuesService.cultureName;
  }

  public get fieldName() {
    return this.defaultValuesService.fieldName;
  }

  public get operationName() {
    return this.defaultValuesService.operationName;
  }

  public get spayingType() {
    return this.defaultValuesService.spayingType;
  }

  public get assigneeName() {
    return this.defaultValuesService.assigneeName;
  }

  public get date() {
    return this.defaultValuesService.date;
  }

  public clearStore = (): void => {
    this.store.clearSelectedTask();
    this.store.clearStatusPillEventList();
  };
}

export default TaskOperationViewController;
