import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { StoAttributeCommonData as CommonData } from '../StoAttributeCommonData';
import { StoAttributeControlButtons as ControlButtons } from '../StoAttributeControlButtons';
import { useStore } from '../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../mobx/stores';
import { EChecklistAttributeType } from '../../../../../../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { StoAttributeUserDictionaryRegistry as UserDictionaryRegistry } from '../StoAttributeUserDictionaryRegistry';
import { StoAttributePrintSettings as PrintSettings } from '../StoAttributePrintSettings';

import Styled from './StoAttributeContent.styles';

const StoAttributeContent: FC = () => {
  const store = useStore(Store);

  const isNeedToShowUserDictionaryRegistry = useMemo<boolean>(() => {
    return store.attribute.attribute.type === EChecklistAttributeType.UserDictionaryLink;
  }, []);

  return (
    <Styled.Wrapper>
      <CommonData />

      {isNeedToShowUserDictionaryRegistry ? <UserDictionaryRegistry /> : null}

      <PrintSettings />

      <ControlButtons />
    </Styled.Wrapper>
  );
};

StoAttributeContent.displayName = 'StoAttributeContent';

export default observer(StoAttributeContent);
