import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

type TChangeFn = (value: string, optionsLength: number) => void;

function useDebouncedChange(value: string, optionsLength: number, onChange: TChangeFn, ms = 200) {
  const firstRender = useRef<boolean>(true);

  const debounceFn = useCallback(debounce(onChange, ms), [ms, onChange]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    debounceFn(value, optionsLength);
  }, [value, optionsLength]);
}

export default useDebouncedChange;
