import { TypeApiRoute } from '../../../models/type.api.request';
import { Operation } from '../../../models/operations/operation.model';

type TRequest = { id: string };

type TResponse = Operation;

export const getOperation: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/operation/${id}`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
