import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { ScrollableContainer } from '../../../../../../../../../shared/containers';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoFieldsTabStore } from '../../mobx/stores';
import { SkeletonFieldList } from '../SkeletonFieldList';

import { FieldsTabListingUsedItem } from './components';

const FieldsTabListingUsed: FC<{ isBlocked?: boolean }> = ({ isBlocked }) => {
  const store = useStore(StoFieldsTabStore);

  const displayList = useMemo(() => store.CZusedInAnotherStoList, [
    store.CZIdToPotentialCultureZonesUsedInAnotherSto,
  ]);
  const { isFieldsFetchingCompleted } = store;

  const getIsLast = (index: number) => displayList?.length - 1 === index;
  const maxHeight = displayList?.length >= 10 ? 469 : displayList?.length * 47;

  const renderRow = (fieldId: string, index: number) => (
    <FieldsTabListingUsedItem isBlocked={isBlocked} fieldId={fieldId} isLast={getIsLast(index)} />
  );

  if (!isFieldsFetchingCompleted) {
    return (
      <ScrollableContainer maxHeight="235px" dataTestId="sto-field-skeleton-list">
        <SkeletonFieldList listLength={5} />
      </ScrollableContainer>
    );
  }

  return (
    <ScrollableContainer
      maxHeight={`${maxHeight}px`}
      isDisabled={displayList?.length <= 10}
      dataTestId="sto-used-field-list"
    >
      {displayList.map((item, index) => renderRow(item.id, index))}
    </ScrollableContainer>
  );
};

export default observer(FieldsTabListingUsed);
