import { lazyInject, provide } from '../../../../../utils/IoC';
import {
  IMapMarkerElement as IMarkerElement,
  IMapMarkerElementConfig as IMarkerElementConfig,
  IMapSetListOptions as ISetListOptions,
} from '../../../models';
import MapMarkerStore from '../stores/MapMarker.store';
import MapMarkerService from '../services/MapMarker.service';

type TMarkerServiceArgs<K extends keyof MapMarkerService> = Parameters<MapMarkerService[K]>;

@provide.transient()
class MapMarkerController {
  @lazyInject(MapMarkerStore)
  private store: MapMarkerStore;

  @lazyInject(MapMarkerService)
  private service: MapMarkerService;

  public display<E>(config: IMarkerElementConfig<E>): IMarkerElement<E> | null {
    const marker = this.service.create(config.marker);

    if (!marker) {
      return null;
    }

    return { element: config.element, marker };
  }

  public displayMany<E>(
    configList: IMarkerElementConfig<E>[],
    options?: ISetListOptions
  ): IMarkerElement<E>[] {
    if (options?.isClearPreviousList) {
      this.service.removeManyByIds([], { isRemoveAll: true });
    }

    return configList.map(config => this.display(config)).filter(Boolean) as IMarkerElement<E>[];
  }

  public remove(...args: TMarkerServiceArgs<'removeById'>) {
    this.service.removeById(...args);
  }

  public removeMany(...args: TMarkerServiceArgs<'removeManyByIds'>) {
    this.service.removeManyByIds(...args);
  }
}

export default MapMarkerController;
