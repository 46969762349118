import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Text = styled('span')({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  color: '#3FB65F',
  marginLeft: ESpaceSize.S,
});

const IconWrapper = styled('div')({
  width: 16,
  height: 16,
  borderRadius: '50%',
  backgroundColor: Colors.lightGreen,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '8px 12px',

  '&:hover': {
    [Text]: {
      color: '#259A42',
    },
    [IconWrapper]: {
      backgroundColor: '#E2F4E7',
    },
  },
});

export default {
  Wrapper,
  Text,
  IconWrapper,
};
