import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { TaskOperationViewStore as Store } from '../../stores';
import { TasksService } from '../../../../../../../../shared/mobx/services/as-fields';

@provide.transient()
class TaskOperationViewApiService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  public deleteTask = async (): Promise<void> => {
    await this.tasksService.deleteTask({
      id: this.store.selectedTask.id,
    });
  };
}

export default TaskOperationViewApiService;
