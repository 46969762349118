import { PolylineOptions } from 'leaflet';
import * as turf from '@turf/turf';

import { CultureZone, Field } from '../../../../../../../api/models/field.model';
import { IFieldLayerOptions, IFillCultureLayer } from '../../../models';
import LayerHoverHandler from '../../helpers/LayerHoverHandler';
import BaseCulturePolygon from '../polygons/BaseCulturePolygon';
import FieldPolygon from '../polygons/FieldPolygon';

import OptimizedLayerGroup from './OptimizedLayerGroup';

/**
 * Данная группа слоев используется в следующих режимах:
 * 1. DisplayFields
 * 2. EditFields
 * 3. CreateFields
 * 4. ImportFields
 *
 * Группа состоит из двух типов полигонов.
 * 1. Полигон поля (главный полигон, представляет собой контур всего поля)
 * 2. КЗ-полигоны. Полигоны культруных зон (обычно находятся внутри главного полигона)
 *
 * Группа имеет возможность показывать/скрывать культурные зоны. Удаление группы удалит все ее дочерние полигоны.
 * При добавлении тултипа произойдет его добавление только на главный полигон
 * Группа наследуется от слоя оптимизации, что позволяет ей использовать кластеризацию и рендер внутри viewport.
 *
 * Группа имплементит интерфейс который позволяет скрывать/показывать КЗ-полигоны.
 * Управление видимостью КЗ-полигонов {@link FieldFillController осуществляется тут}
 */
class FieldLayerGroup extends OptimizedLayerGroup implements IFillCultureLayer {
  private fieldPolygon: FieldPolygon;
  private culturesPolygons: BaseCulturePolygon[];

  private selectedStyle: PolylineOptions;

  private hoverHandler: LayerHoverHandler;

  constructor(field: Field, options: IFieldLayerOptions) {
    // Создаем основной полигон
    const fieldPolygon = new FieldPolygon(field, options.fieldOptions);

    // Создаем полигоны культурных зон
    const culturesPolygons = (field.cultureZones ?? []).map(zone => {
      return new BaseCulturePolygon(zone.geometry, zone);
    });

    // Регистрируем на карте только основной полигон. Управлять видимостью КЗ необходимо извне
    super([fieldPolygon], { pmIgnore: true, ...options });

    this.fieldPolygon = fieldPolygon;
    this.culturesPolygons = culturesPolygons;

    this.selectedStyle = options.selectedStyle;

    this.hoverHandler = new LayerHoverHandler(fieldPolygon, () => this.isSelected);
    this.hoverHandler.register(options.hoverStyle);
  }

  public select(style?: PolylineOptions): void {
    const newStyle = style ?? this.selectedStyle;

    if (newStyle) {
      this.fieldPolygon.setStyle(newStyle);
    }

    this.isSelected = true;
    this.skipClustering = true;
  }

  public deselect(): void {
    if (!this.isSelected) {
      return;
    }

    this.fieldPolygon.setPrevStyle();
    this.isSelected = false;
    this.skipClustering = false;

    const tooltip = this.getTooltip();
    if (tooltip && !tooltip.isOpen()) {
      this.openTooltip();
    }
  }

  public getMainPolygon(): FieldPolygon {
    return this.fieldPolygon;
  }

  public getCulturesPolygons(): BaseCulturePolygon[] {
    return this.culturesPolygons;
  }

  /**
   * Обрезает КЗ-полигоны по границе поля
   */
  public cutCulturePolygonsAlongField(): CultureZone[] | null {
    const turfFieldPolygon = this.fieldPolygon.toTurf();

    const hasRemovedPolygons = this.culturesPolygons.some(
      polygon => !turf.intersect(polygon.toTurf(), turfFieldPolygon)
    );

    if (hasRemovedPolygons) {
      return null;
    }

    return this.culturesPolygons.map(polygon => {
      const intersection = turf.intersect(polygon.toTurf(), turfFieldPolygon);
      polygon.changeGeometry(intersection.geometry);

      return polygon.dataModel;
    });
  }

  /**
   * Добавляет КЗ-полигоны на карту
   */
  public showCulturesPolygons(): void {
    if (!this.hasCultureZones()) {
      return;
    }

    this.culturesPolygons.forEach(polygon => this.addLayer(polygon));

    this.bringCulturePolygonsBack();
  }

  /**
   * Удаляет КЗ-полигоны с карты
   */
  public hideCulturesPolygons(): void {
    if (!this.hasCultureZones()) {
      return;
    }

    this.culturesPolygons.forEach(polygon => this.removeLayer(polygon));
  }

  private hasCultureZones(): boolean {
    return Boolean(this.culturesPolygons.length);
  }

  private bringCulturePolygonsBack() {
    this.fieldPolygon.bringToFront();
    this.culturesPolygons.forEach(polygon => polygon.bringToBack());
  }

  onAdd(map): this {
    const res = super.onAdd(map);
    this.bringCulturePolygonsBack();

    const { beforeHoverOptions } = this.hoverHandler;

    if (beforeHoverOptions) {
      this.fieldPolygon.setStyle(beforeHoverOptions);
    }

    return res;
  }
}

export default FieldLayerGroup;
