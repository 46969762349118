import { ButtonLink } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './SectionHeader.styles';

interface IProps {
  title: string;
  showResetBtn: boolean;
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
  onResetClick?: () => void;
}

const FilterSectionHeader: FC<IProps> = props => {
  return (
    <Styled.Wrapper>
      <Styled.Title {...props.getDataTestId('title')}>{props.title}</Styled.Title>

      {props.showResetBtn && (
        <ButtonLink
          color={'secondary'}
          onClick={props.onResetClick}
          {...props.getDataTestId('reset-button')}
        >
          Сбросить
        </ButtonLink>
      )}
    </Styled.Wrapper>
  );
};

export default memo(FilterSectionHeader);
