import { makeAutoObservable } from 'mobx';
import { isFunction } from 'lodash';

import { provide } from '../../../../../../../../../../shared/utils/IoC';
import {
  EStoDictionaryName as EDictionaryName,
  IStoDictionary as IDictionary,
} from '../../../../../../../../../../../api/models/as-fields/new/sto';
import { TStoreSetter } from '../../../../../../../../../../shared/types/mobx';

@provide.singleton()
class StoRegistriesStore {
  private _isInitialLoading = true;
  private _loadingDictionaries: Set<EDictionaryName> = new Set<EDictionaryName>();

  private _weedPlantList: IDictionary[] = [];
  private _verminList: IDictionary[] = [];
  private _diseaseList: IDictionary[] = [];

  private _weedPlantListToDisplay: IDictionary[] = [];
  private _verminListToDisplay: IDictionary[] = [];
  private _diseaseListToDisplay: IDictionary[] = [];

  private _selectedWeedPlantIds: Set<string> = new Set<string>();
  private _selectedVerminIds: Set<string> = new Set<string>();
  private _selectedDiseaseIds: Set<string> = new Set<string>();

  constructor() {
    makeAutoObservable(this);
  }

  public get isInitialLoading(): boolean {
    return this._isInitialLoading;
  }

  public hasLoadingDictionary = (name: EDictionaryName): boolean => {
    return this._loadingDictionaries.has(name);
  };

  public get weedPlantList(): IDictionary[] {
    return this._weedPlantList;
  }

  public get verminList(): IDictionary[] {
    return this._verminList;
  }

  public get diseaseList(): IDictionary[] {
    return this._diseaseList;
  }

  public get weedPlantListToDisplay(): IDictionary[] {
    return this._weedPlantListToDisplay;
  }

  public get verminListToDisplay(): IDictionary[] {
    return this._verminListToDisplay;
  }

  public get diseaseListToDisplay(): IDictionary[] {
    return this._diseaseListToDisplay;
  }

  public get selectedWeedPlantIdList(): string[] {
    return [...this._selectedWeedPlantIds.values()];
  }

  public get selectedVerminIdList(): string[] {
    return [...this._selectedVerminIds.values()];
  }

  public get selectedDiseaseIdList(): string[] {
    return [...this._selectedDiseaseIds.values()];
  }

  public hasSelectedWeedPlant = (id: string): boolean => {
    return this._selectedWeedPlantIds.has(id);
  };

  public hasSelectedVermin = (id: string): boolean => {
    return this._selectedVerminIds.has(id);
  };

  public hasSelectedDisease = (id: string): boolean => {
    return this._selectedDiseaseIds.has(id);
  };

  public setIsInitialLoading = (value: boolean): void => {
    this._isInitialLoading = value;
  };

  public setLoadingDictionary = (name: EDictionaryName): void => {
    this._loadingDictionaries.add(name);
  };

  public setWeedPlantList: TStoreSetter<IDictionary[]> = value => {
    if (isFunction(value)) {
      this._weedPlantList = value(this._weedPlantList);
    } else {
      this._weedPlantList = value;
    }
  };

  public setVerminList: TStoreSetter<IDictionary[]> = value => {
    if (isFunction(value)) {
      this._verminList = value(this._verminList);
    } else {
      this._verminList = value;
    }
  };

  public setDiseaseList: TStoreSetter<IDictionary[]> = value => {
    if (isFunction(value)) {
      this._diseaseList = value(this._diseaseList);
    } else {
      this._diseaseList = value;
    }
  };

  public setWeedPlantListToDisplay: TStoreSetter<IDictionary[]> = value => {
    if (isFunction(value)) {
      this._weedPlantListToDisplay = value(this._weedPlantListToDisplay);
    } else {
      this._weedPlantListToDisplay = value;
    }
  };

  public setVerminListToDisplay: TStoreSetter<IDictionary[]> = value => {
    if (isFunction(value)) {
      this._verminListToDisplay = value(this._verminListToDisplay);
    } else {
      this._verminListToDisplay = value;
    }
  };

  public setDiseaseListToDisplay: TStoreSetter<IDictionary[]> = value => {
    if (isFunction(value)) {
      this._diseaseListToDisplay = value(this._diseaseListToDisplay);
    } else {
      this._diseaseListToDisplay = value;
    }
  };

  public setSelectedWeedPlantIdList: TStoreSetter<string[]> = value => {
    if (isFunction(value)) {
      this._selectedWeedPlantIds = new Set<string>(value(this.selectedWeedPlantIdList));
    } else {
      this._selectedWeedPlantIds = new Set<string>(value);
    }
  };

  public setSelectedVerminIdList: TStoreSetter<string[]> = value => {
    if (isFunction(value)) {
      this._selectedVerminIds = new Set<string>(value(this.selectedVerminIdList));
    } else {
      this._selectedVerminIds = new Set<string>(value);
    }
  };

  public setSelectedDiseaseIdList: TStoreSetter<string[]> = value => {
    if (isFunction(value)) {
      this._selectedDiseaseIds = new Set<string>(value(this.selectedDiseaseIdList));
    } else {
      this._selectedDiseaseIds = new Set<string>(value);
    }
  };

  public setSelectedWeedPlantId = (id: string) => {
    this._selectedWeedPlantIds.add(id);
  };

  public setSelectedVerminId = (id: string) => {
    this._selectedVerminIds.add(id);
  };

  public setSelectedDiseaseId = (id: string) => {
    this._selectedDiseaseIds.add(id);
  };

  public deleteLoadingDictionary = (name: EDictionaryName): void => {
    this._loadingDictionaries.delete(name);
  };

  public deleteSelectedWeedPlantId = (id: string) => {
    this._selectedWeedPlantIds.delete(id);
  };

  public deleteSelectedVerminId = (id: string) => {
    this._selectedVerminIds.delete(id);
  };

  public deleteSelectedDiseaseId = (id: string) => {
    this._selectedDiseaseIds.delete(id);
  };

  public clearIsInitialLoading = (): void => {
    this._isInitialLoading = true;
  };

  public clearLoadingDictionaries = (): void => {
    this._loadingDictionaries.clear();
  };

  public clearWeedPlantList = (): void => {
    this._weedPlantList = [];
  };

  public clearVerminList = (): void => {
    this._verminList = [];
  };

  public clearDiseaseList = (): void => {
    this._diseaseList = [];
  };

  public clearWeedPlantListToDisplay = (): void => {
    this._weedPlantListToDisplay = [];
  };

  public clearVerminListToDisplay = (): void => {
    this._verminListToDisplay = [];
  };

  public clearDiseaseListToDisplay = (): void => {
    this._diseaseListToDisplay = [];
  };

  public clearSelectedWeedPlantIds = (): void => {
    this._selectedWeedPlantIds.clear();
  };

  public clearSelectedVerminIds = (): void => {
    this._selectedVerminIds.clear();
  };

  public clearSelectedDiseaseIds = (): void => {
    this._selectedDiseaseIds.clear();
  };
}

export default StoRegistriesStore;
