import { FC, memo, useMemo } from 'react';
import { Chip, ChipProps, Colors } from '@farmlink/farmik-ui';

import { taskLabels } from '../../stores/tasks.store';
import { ETaskStatus } from '../../../../../../api/models/as-fields/task/task.model';
import { ITaskEvent } from '../../../../../../api/models/task/events/task.event.mode';
import { TaskHistoryTooltip } from '../TaskHistoryTooltip';

import Styled from './TaskStatusPill.styles';

interface ITaskStatusPillProps {
  status: ETaskStatus;
  isHide?: boolean;
  eventList?: ITaskEvent[];
  isRightTooltip?: boolean;
}

const TaskStatusPill: FC<ITaskStatusPillProps> = ({
  status,
  isHide,
  eventList,
  isRightTooltip,
}) => {
  const customChipColorScheme = useMemo<ChipProps['customChipColors']>(() => {
    switch (status) {
      case ETaskStatus.New:
        return {
          default: Colors.blue,
          hover: Colors.generalWhite,
          light: Colors.lightBlue,
          hoverBg: Colors.blue,
        };
      case ETaskStatus.InWork:
        return {
          default: Colors.green,
          hover: Colors.generalWhite,
          light: Colors.lightGreen,
          hoverBg: Colors.green,
        };
      case ETaskStatus.Completed:
        return {
          default: '#0B0E13' as Colors, // TODO Заменить при добавлении в палитру
          hover: Colors.generalWhite,
          light: Colors.secondaryGray,
          hoverBg: Colors.darkGrey,
        };

      case ETaskStatus.Canceled:
        return {
          default: Colors.hoverPink,
          hover: Colors.generalWhite,
          light: Colors.lightPink,
          hoverBg: Colors.pink,
        };
      case ETaskStatus.Impossible:
        return {
          default: Colors.hoverPink,
          hover: Colors.generalWhite,
          light: Colors.lightPink,
          hoverBg: Colors.pink,
        };
      default:
        return {
          default: Colors.blue,
          hover: Colors.generalWhite,
          light: Colors.lightBlue,
          hoverBg: Colors.blue,
        };
    }
  }, [status]);

  const statusText = useMemo<string>(() => {
    return taskLabels[status];
  }, [status]);

  const tooltipComponent = useMemo(() => <TaskHistoryTooltip eventList={eventList} />, [
    eventList,
    status,
  ]);

  if (isHide || !statusText) {
    return null;
  }

  return (
    <Styled.Chip
      color="default"
      size="medium"
      icon="info"
      dataTestId="task-status-pill"
      customChipColors={customChipColorScheme}
      iconTooltip={{
        content: tooltipComponent,
        position: 'bottom',
        $translate: { x: '20px' },
        disabled: !eventList?.length,
      }}
    >
      {statusText || 'Иной'}
    </Styled.Chip>
  );
};

export default memo(TaskStatusPill);
