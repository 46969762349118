import { Scrollbar, useSidebar } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { createContext, FC, memo, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { useStore } from '../../../../../shared/utils/IoC';
import { UiStore } from '../../../../stores/ui.store';
import { FullScreenHeader, StyledFullscreenFooter, StyledFullscreenWrapper } from '../../style';

import FullscreenBackButton, { IFullscreenBackButtonProps } from './FullscreenBackButton';
import * as Styled from './styles';

export type IFullscreenProps = React.PropsWithChildren<
  {
    className?: string;
    actionButton?: {
      label?: string;
      onClick?: () => void;
    };
    onClose?: () => void;
    mode?: 'edit' | 'view' | 'create';
  } & IFullscreenBackButtonProps
>;

export const FullscreenContext = createContext(null);

const Fullscreen: FC<IFullscreenProps> = observer(props => {
  const {
    children,
    backButtonHandler,
    backButtonText,
    actionButton,
    mode,
    status,
    rightSideContent,
    arrowText,
    titleAdditionalElement,
  } = props;
  const uiStore = useStore(UiStore);

  const [isNeedForceClosing, setIsNeedForceClosing] = useState(false);
  const [hideFullScreenHeader, setHideFullScreenHeader] = useState(false);

  let prevScroll = 0;
  let currentScroll = 0;

  const ref = useRef<HTMLDivElement>();

  const { setSidebarVisibility } = useSidebar();

  useEffect(() => {
    setSidebarVisibility(false);

    return () => {
      setSidebarVisibility(true);
    };
  }, []);

  const handleScroll = event => {
    prevScroll = currentScroll;
    currentScroll = ref.current.scrollTop;
    if (currentScroll < prevScroll) {
      console.log('show');
      setHideFullScreenHeader(false);
    } else if (currentScroll > prevScroll && currentScroll > 72) {
      console.log('hide');
      setHideFullScreenHeader(true);
    }
  };

  const onScroll = e => {
    if (isNeedForceClosing) return;

    setIsNeedForceClosing(true);
  };

  return (
    <FullscreenContext.Provider value={{ isNeedForceClosing, setIsNeedForceClosing }}>
      <StyledFullscreenWrapper
        $isShort={uiStore.isAdaptive || uiStore.isSidebarShort}
        className={`${props?.className || ''} fullscreen-header ${
          hideFullScreenHeader ? 'header_hidden' : ''
        }`}
      >
        <FullScreenHeader
          className={`fullscreen-header ${hideFullScreenHeader ? 'header_hidden' : ''}`}
        >
          <FullscreenBackButton
            backButtonText={backButtonText}
            backButtonHandler={backButtonHandler}
            status={status}
            mode={mode}
            rightSideContent={rightSideContent}
            arrowText={arrowText}
            titleAdditionalElement={titleAdditionalElement}
            actionButton={
              actionButton && (
                <Styled.ActionButton onClick={actionButton.onClick}>
                  {actionButton.label}
                </Styled.ActionButton>
              )
            }
          />
        </FullScreenHeader>
        <Scrollbar onScroll={onScroll}>
          <StyledFullscreenFooter
          // onScroll={handleScroll}
          // ref={ref}
          >
            <Styled.Fullscreen>{children}</Styled.Fullscreen>
          </StyledFullscreenFooter>
        </Scrollbar>
      </StyledFullscreenWrapper>
    </FullscreenContext.Provider>
  );
});

export default memo(Fullscreen);
