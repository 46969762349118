import { makeAutoObservable } from 'mobx';
import { isFunction } from 'lodash';
import { ITabsProps } from '@farmlink/farmik-ui';

import { provide } from '../../../../../../../../shared/utils/IoC';
import { TStoreSetter } from '../../../../../../../../shared/types/mobx';
import { ArrayElement } from '../../../../../../../../shared/utils/types/ArrayElement';
import { ITaskCreate } from '../../../../../../../../../api/models/as-fields/task/task.model';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { Operation } from '../../../../../../../../../api/models/operations/operation.model';
import { OperationField } from '../../../../../../../../../api/models/operations/operation.field.model';
import { IDictionaryEntity } from '../../../../../../../../../api/models/da-dictionary/DictionaryEntity';
import { ITaskOperationConfig } from '../../../../../../../../../api/models/as-fields/taskOperationConfig';

type TTab = ArrayElement<ITabsProps['content']>;

@provide.singleton()
class TaskOperationCreateStore {
  private _isPageLoading = true;

  private _hasChanges = false;

  private _tabList: TTab[] = [];

  private _taskCreate: ITaskCreate | null = null;

  private _cultureSelectOptionList: ISelectOption[] = [];

  private _operationSelectOptionList: ISelectOption<Operation>[] = [];

  private _fieldOptionList: ISelectOption<OperationField>[] = [];

  private _fieldOptionListToDisplay: ISelectOption<OperationField>[] = [];

  private _isFieldOptionsLoading = true;

  private _selectedFieldIdList: Set<string> = new Set<string>();

  private _configOptionList: ISelectOption<IDictionaryEntity>[] = [];

  private _config: ITaskOperationConfig | null = null;

  private _taskPropsWithErrors: Set<keyof ITaskCreate> = new Set<keyof ITaskCreate>();

  constructor() {
    makeAutoObservable(this);
  }

  public get isPageLoading(): boolean {
    return this._isPageLoading;
  }

  public get hasChanges(): boolean {
    return this._hasChanges;
  }

  public get tabList(): TTab[] {
    return this._tabList;
  }

  public get taskCreate(): ITaskCreate | null {
    return this._taskCreate;
  }

  public get cultureSelectOptionList(): ISelectOption[] {
    return this._cultureSelectOptionList;
  }

  public get operationSelectOptionList(): ISelectOption<Operation>[] {
    return this._operationSelectOptionList;
  }

  public get configSelectOptionList(): ISelectOption<IDictionaryEntity>[] {
    return this._configOptionList;
  }

  public get config(): ITaskOperationConfig | null {
    return this._config;
  }

  public get fieldOptionList(): ISelectOption<OperationField>[] {
    return this._fieldOptionList;
  }

  public get fieldOptionListToDisplay(): ISelectOption<OperationField>[] {
    return this._fieldOptionListToDisplay;
  }

  public get selectedFieldIdList(): Set<string> {
    return this._selectedFieldIdList;
  }

  public get isFieldOptionsLoading(): boolean {
    return this._isFieldOptionsLoading;
  }
  public checkIfTaskHasError = (key: keyof ITaskCreate): boolean => {
    return this._taskPropsWithErrors.has(key);
  };

  public setIsPageLoading: TStoreSetter<boolean> = value => {
    if (isFunction(value)) {
      this._isPageLoading = value(this._isPageLoading);
    } else {
      this._isPageLoading = value;
    }
  };

  public setHasChanges: TStoreSetter<boolean> = value => {
    if (isFunction(value)) {
      this._hasChanges = value(this._hasChanges);
    } else {
      this._hasChanges = value;
    }
  };

  public setTabList: TStoreSetter<TTab[]> = value => {
    if (isFunction(value)) {
      this._tabList = value(this._tabList);
    } else {
      this._tabList = value;
    }
  };

  public setTaskCreate: TStoreSetter<ITaskCreate> = value => {
    if (isFunction(value)) {
      this._taskCreate = value(this._taskCreate);
    } else {
      this._taskCreate = value;
    }
  };

  public setCultureOptionList: TStoreSetter<ISelectOption[]> = value => {
    if (isFunction(value)) {
      this._cultureSelectOptionList = value(this._cultureSelectOptionList);
    } else {
      this._cultureSelectOptionList = value;
    }
  };

  public setOperationOptionList: TStoreSetter<ISelectOption<Operation>[]> = value => {
    if (isFunction(value)) {
      this._operationSelectOptionList = value(this._operationSelectOptionList);
    } else {
      this._operationSelectOptionList = value;
    }
  };

  public setFieldOptionList: TStoreSetter<ISelectOption<OperationField>[]> = value => {
    if (isFunction(value)) {
      this._fieldOptionList = value(this._fieldOptionList);
    } else {
      this._fieldOptionList = value;
    }
  };

  public setFieldOptionListToDisplay: TStoreSetter<ISelectOption<OperationField>[]> = value => {
    if (isFunction(value)) {
      this._fieldOptionListToDisplay = value(this._fieldOptionListToDisplay);
    } else {
      this._fieldOptionListToDisplay = value;
    }
  };

  public setIsFieldOptionsLoading: TStoreSetter<boolean> = value => {
    if (isFunction(value)) {
      this._isFieldOptionsLoading = value(this._isFieldOptionsLoading);
    } else {
      this._isFieldOptionsLoading = value;
    }
  };

  public addSelectedFieldId = (id: string): void => {
    this._selectedFieldIdList.add(id);
  };

  public deleteSelectedFieldId = (id: string): void => {
    this._selectedFieldIdList.delete(id);
  };

  public hasSelectedFieldId = (id: string): boolean => {
    return this._selectedFieldIdList.has(id);
  };

  public setConfigOptionList: TStoreSetter<ISelectOption<IDictionaryEntity>[]> = value => {
    if (isFunction(value)) {
      this._configOptionList = value(this._configOptionList);
    } else {
      this._configOptionList = value;
    }
  };

  public setConfig: TStoreSetter<ITaskOperationConfig> = value => {
    if (isFunction(value)) {
      this._config = value(this._config);
    } else {
      this._config = value;
    }
  };

  public setTaskPropWithError = (key: keyof ITaskCreate): void => {
    this._taskPropsWithErrors.add(key);
  };

  public deleteTaskPropWithError = (key: keyof ITaskCreate): void => {
    this._taskPropsWithErrors.delete(key);
  };

  public clearFieldOptionList = (): void => {
    this._fieldOptionList = [];
  };

  public clearFieldOptionListToDisplay = (): void => {
    this._fieldOptionListToDisplay = [];
  };

  public clearSelectedFieldIdList = (): void => {
    this._selectedFieldIdList.clear();
  };

  public clearIsPageLoading = (): void => {
    this._isPageLoading = false;
  };

  public clearHasChanges = (): void => {
    this._hasChanges = false;
  };

  public clearTabList = (): void => {
    this._tabList = [];
  };

  public clearTaskCreate = (): void => {
    this._taskCreate = null;
  };

  public clearCultureSelectOptionList = (): void => {
    this._cultureSelectOptionList = [];
  };

  public clearOperationSelectOptionList = (): void => {
    this._operationSelectOptionList = [];
  };

  public clearConfigSelectOptionList = (): void => {
    this._configOptionList = [];
  };

  public clearConfig = (): void => {
    this._config = null;
  };

  public clearTaskPropsWithErrors = (): void => {
    this._taskPropsWithErrors.clear();
  };
}

export default TaskOperationCreateStore;
