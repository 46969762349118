import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizesObj } from '../../../../../../../../../../../shared/constans/sizes';

interface IInfoLabelProps {
  isSelected: boolean;
}

const Wrapper = styled('section')({});

const ListContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: ESpaceSize.M,
  marginTop: ESpaceSize.S,
});

const ListItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
});

const Name = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: ESpaceSize.S,
  ...FontSizesObj.medium,
});

const InfoLabel = styled('span')<IInfoLabelProps>(
  {
    ...FontSizesObj.medium,
  },
  ({ isSelected }) => ({
    color: isSelected ? Colors.generalBlack : Colors.darkGrey,
  })
);

const Styles = {
  Wrapper,
  ListContainer,
  ListItem,
  Name,
  InfoLabel,
};

export default Styles;
