import { Icon } from '@farmlink/farmik-ui';
import React, { CSSProperties, FC, useCallback, useMemo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { ListCard, RightBlockWithAreaAndMenu } from '../../../../../../../../components/ListCard';
import { Field } from '../../../../../../../../../../../../api/models/field.model';
import { FieldsMapCoreStore } from '../../../../../../../../mobx';
import { getUniqueCulturesNameList } from '../../../../../../../../utils';
import { FieldsViewController as Controller } from '../../../../../../mobx';

import RightBlockWithNewChip from './components/RightBlockWithNewChip/RightBlockWithNewChip';
import FieldCardDropdownMenu from './components/DropdownMenu/DropdownMenu';
import Styled from './FieldCard.styles';

interface IProps {
  field: Field;
  // Стили react-virtualized бибилотеки
  style: CSSProperties;
}

const FieldCard: FC<IProps> = props => {
  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const fieldsMapCoreStore = useStore(FieldsMapCoreStore);

  const getDataTestId = useDataTestIdV2('field-view-card');

  const isNew = fieldsMapCoreStore.isNewField(props.field);
  const isSelected = fieldsMapCoreStore.isSelectedField(props.field);

  const culturesLabel = useMemo(() => {
    return getUniqueCulturesNameList(props.field.cultureZones).join(', ') || 'Нет культуры';
  }, [props.field.cultureZones]);

  const handleClick = useCallback(() => {
    controller.selectField(props.field);
  }, [props.field]);

  const getMapIcon = useCallback(() => {
    const url = props.field?.icon?.downloadUrl;

    return (
      <Styled.MapImage imgUrl={url} {...getDataTestId('image')}>
        {!url && <Icon icon={'mapList'} size={'medium'} />}
      </Styled.MapImage>
    );
  }, [props.field?.icon?.downloadUrl]);

  const getRightBlock = useCallback(() => {
    const cultureArea = `${props.field.area.toFixed(1)} га`;

    if (isNew) {
      return (
        <RightBlockWithNewChip
          area={cultureArea}
          getDataTestId={getDataTestId}
          dropdownMenu={<FieldCardDropdownMenu field={props.field} onOpen={handleClick} />}
        />
      );
    }

    return (
      <RightBlockWithAreaAndMenu
        area={cultureArea}
        getDataTestId={getDataTestId}
        dropdownMenu={<FieldCardDropdownMenu field={props.field} onOpen={handleClick} />}
      />
    );
  }, [isNew, props.field]);

  const wrapperStyles = useMemo(() => {
    return isNew ? Styled.NewListCardStyles : {};
  }, [isNew]);

  return (
    <ListCard
      title={props.field.name}
      subtitle={culturesLabel}
      isSelected={isSelected}
      leftBlock={getMapIcon}
      rightBlock={getRightBlock}
      wrapperStyles={wrapperStyles}
      style={props.style}
      onClick={handleClick}
      dataTestId={getDataTestId()['data-test-id']}
    />
  );
};

export default FieldCard;
