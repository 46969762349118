import { Colors } from '@farmlink/farmik-ui';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{
  $hasClickEvent: boolean;
  $isHoverable?: boolean;
  $isHighlighted?: boolean;
}>`
  transition: all 0.3s ease-in-out;

  padding: 12px 8px;
  position: relative;
  z-index: 1;

  ${({ $isHoverable }) =>
    $isHoverable &&
    css`
      margin: 0 24px;

      &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: -8px;
        right: -8px;
        bottom: 0;
        background-color: ${Colors.secondaryPageBgGrey};
        z-index: -1;
        border-radius: 12px;
      }
    `};

  ${({ $hasClickEvent }) =>
    $hasClickEvent
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: auto;
        `};

  ${({ $isHighlighted }) => {
    if (!$isHighlighted) return;

    return css`
      background-color: ${Colors.lightSky};
      border-radius: 12px;
    `;
  }}
`;

const StyledTableBuilderRow = {
  Wrapper,
};

export default StyledTableBuilderRow;
