import { observer } from 'mobx-react';
import { FC } from 'react';

import { FieldSeasonsModel } from '../../../../../../../../../../../../api/models/field.seasons.model';
import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './SeasonCard.styles';
import SeasonCardHeader from './components/Header/Header';
import CopyFieldContent from './components/CopyFieldContent/CopyFieldContent';
import CulturesListContent from './components/CulturesListContent/CulturesListContent';

interface IProps {
  season: FieldSeasonsModel;
  opened: boolean;
}

const FieldSeasonCard: FC<IProps> = ({ season, opened }) => {
  const hasFieldVersion = Boolean(season.fieldVersion);

  const getDataTestId = useDataTestIdV2('field-season-card');

  const getContentBlock = () => {
    if (!hasFieldVersion) {
      return <CopyFieldContent />;
    }

    return <CulturesListContent />;
  };

  return (
    <Styled.Wrapper active={opened} {...getDataTestId('wrapper')}>
      <SeasonCardHeader season={season} opened={opened} />

      {opened && (
        <Styled.ContentWrapper {...getDataTestId('content-wrapper')}>
          {getContentBlock()}
        </Styled.ContentWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default observer(FieldSeasonCard);
