import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { TablePlugBuilder } from '../../../../../../../shared/features/UI/plugs/TablePlugBuilder';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { TableFiltersBuilderController as BuilderController } from '../../../../../../../shared/features/TableFiltersBuilder/mobx/controllers';
import { ETableFiltersBuilderId } from '../../../../../../constants/configs/TableFiltersBuilderId';

import Styled from './StoListNoFilteredDataPlug.styled';

const StoListNoFilteredDataPlug: FC = () => {
  const builderController = useStore(BuilderController);

  const handleButtonClick = useCallback(() => {
    builderController.removeAllValues(ETableFiltersBuilderId.Sto);
  }, []);

  const getDataTestId = useDataTestIdV2('sto__list__no-filtered-data-plug');

  return (
    <TablePlugBuilder
      autoRenderConfig={{
        header: { icon: 'field' },
        content: {
          title: 'СТО по заданным параметрам не найдены ',
          description: 'Измените настройки фильтров',
        },
        footer: {
          renderButton: () => (
            <Styled.Button
              color={'primary'}
              onClick={handleButtonClick}
              alignment={'center'}
              dataTestId={getDataTestId('reset-filters-btn')['data-test-id']}
            >
              Очистить фильтр
            </Styled.Button>
          ),
        },
      }}
      dataTestId={getDataTestId()['data-test-id']}
    />
  );
};

StoListNoFilteredDataPlug.displayName = 'StoListNoDataPlug';

export default observer(StoListNoFilteredDataPlug);
