import { makeObservable, observable } from 'mobx';

import { Filter, TFilterMemento } from '../../../../../shared/features/FilterManager';
import { Field } from '../../../../../../api/models/field.model';

class SearchByNameFilter extends Filter<'search', string, Field> {
  public optionList: string[] = [];

  constructor() {
    super('search', '');

    makeObservable(this, { optionList: observable });
  }

  public get isEmptyValue(): boolean {
    return this.value === '';
  }

  public resetValue(skipFiltering?): void {
    if (this.value) {
      this.setValue(this._defaultValue, skipFiltering);
    }
  }

  public createMemento(): TFilterMemento {
    const value = this.value;
    const optionList = [...this.optionList];

    return {
      restore: skipFiltering => {
        this.setValue(value, skipFiltering);
        this.optionList = optionList;
      },
    };
  }

  public _getFilterRule(item: Field): boolean {
    if (!item.name) {
      return false;
    }

    return item.name.toLowerCase().includes(this.value.toLowerCase());
  }

  public _updateState(): void {
    this.optionList = this._manager
      .getFilteredSourceList([this.id])
      .map((field: Field) => field.name);
  }
}

export default SearchByNameFilter;
