import { Switcher } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { EExperimentsAccessActions } from '../../../../../../../../../../../shared/constants/access-rules-action';
import { CheckAccessStore } from '../../../../../../../../../../../authorization/stores/checkAccess.store';
import { OrganizationsStore } from '../../../../../../../../../../stores/organizations.store';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { IInputProps } from '../../types/CommonProps.types';

import Styled from './ExperimentSwitch.styles';

const ExperimentSwitch: FC<IInputProps> = ({ cultureZone }) => {
  const { getAccessRuleValue } = useStore(CheckAccessStore);
  const { selectedOrganizationId } = useStore(OrganizationsStore);

  const isForbidden = !getAccessRuleValue(EExperimentsAccessActions.IncludeCultureZones);

  const handleChange = value => {
    if ('experimentReady' in cultureZone) {
      cultureZone.experimentReady = value;
    }
  };

  if (selectedOrganizationId !== 'my' && isForbidden) {
    return null;
  }

  return (
    <Styled.Wrapper>
      <Styled.Title>Опыт</Styled.Title>

      <Styled.ControlWrapper>
        <Styled.Label>Использовать в опыте</Styled.Label>

        <Switcher
          value={cultureZone?.experimentReady}
          isBlocked={!cultureZone}
          onChange={handleChange}
        />
      </Styled.ControlWrapper>
    </Styled.Wrapper>
  );
};

export default observer(ExperimentSwitch);
