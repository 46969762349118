import { lazyInject, provide } from '../../../../../../../../../../shared/utils/IoC';
import { StoStore } from '../../../../../mobx';
import { StoOperationsItemsService, StoOperationsChangeService } from '../../services';
import { StoOperationsCoreStore } from '../../stores';

@provide.transient()
class StoOperationsCoreController {
  @lazyInject(StoStore)
  protected stoStore: StoStore;

  @lazyInject(StoOperationsCoreStore)
  protected stoOperationsCoreStore: StoOperationsCoreStore;

  @lazyInject(StoOperationsItemsService)
  protected itemsService: StoOperationsItemsService;

  @lazyInject(StoOperationsChangeService)
  protected changeService: StoOperationsChangeService;

  public get title() {
    const { selectedSto } = this.stoStore;

    return `Операции: ${selectedSto ? selectedSto?.culture?.name?.toLowerCase?.() : 'культура'}`;
  }

  public toggleOperationSelection: StoOperationsChangeService['toggleOperationSelection'] = (
    id,
    value
  ) => {
    this.changeService.toggleOperationSelection(id, value);
  };

  public fetchItems = async (versionId?: string): Promise<void> => {
    await this.itemsService.addItems(versionId);
  };

  public clearUnnecessaryDataInStoStore = (): void => {
    const { setUpdatedSto } = this.stoStore;

    setUpdatedSto(prev => {
      const formattedData = { ...prev };
      delete formattedData?.stoOperations;

      return prev;
    });
  };

  public allowAvailAbleSettingsManagement: StoOperationsItemsService['allowAvailAbleSettingsManagement'] = () => {
    this.itemsService.allowAvailAbleSettingsManagement();
  };

  public clearStoOperationsStore = (): void => {
    this.clearUnnecessaryDataInStoStore();

    this.stoOperationsCoreStore.clearIsLoading();
    this.stoOperationsCoreStore.clearItemList();
  };
}

export default StoOperationsCoreController;
