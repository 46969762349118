import { FC, useCallback, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown as Dropdown, TNewDropdownProps } from '@farmlink/farmik-ui';
import { v4 as uuid } from 'uuid';

import { TChecklistsUserDictionaryAttrToDraw as TUserDictionaryAttrToDraw } from '../../../../models';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttributeUserDictionary,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { useTasksParams } from '../../../../../../hooks';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList as useAttrErrorList,
  useChecklistsAttrVisibility as useAttrVisibility,
} from '../../../../hooks';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { FullscreenContext } from '../../../../../../containers/fullscreen';

interface IProps {
  attrToDraw: TUserDictionaryAttrToDraw;
}

const ChecklistsUserDictionaryAttr: FC<IProps> = ({ attrToDraw }) => {
  const {
    id,
    groupId,
    stageId,
    initialModel,
    value,
    isBlocked,
    dependentFileAttrId,
    options,
  } = attrToDraw;

  const getDataTestId = useDataTestIdV2('checklists__user-dictionary-attribute');

  const bodyForceCloseOptions = useContext(FullscreenContext);

  const checklistsController = useStore(ChecklistsController);

  const params = useTasksParams();

  const isShowAttribute = useAttrVisibility(attrToDraw);
  const errorList = useAttrErrorList(attrToDraw);
  const placeholder = useAttrPlaceholder(initialModel.attribute);

  useEffect(() => {
    (async () => {
      await checklistsController.fetchUserDictionaryList(groupId, id, {
        attributeId: id,
        organizationId: params.orgId,
        taskId: params.taskId,
        stageId,
        size: 2000,
      });
    })();
  }, []);

  const handleChange = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (_, otherData): void => {
      if (initialModel.attribute?.isMultiselect) {
        checklistsController.changeAttrValue(EAttrType.UserDictionaryLink, groupId, {
          ...value,
          userDictionaryValues: !otherData?.selectedValueList
            ? []
            : otherData.selectedValueList.map<IGetChecklistAttributeUserDictionary>(option => {
                const isNewOption = option.value === option.label;

                return {
                  id: option.value,
                  value: option.label,
                  clientId: isNewOption ? uuid() : null,
                };
              }) || [],
        });

        checklistsController.addSelectedSelectOptionList(
          EAttrType.UserDictionaryLink,
          groupId,
          id,
          !otherData?.selectedValueList ? [] : otherData.selectedValueList
        );

        return;
      }

      const isNewOption = otherData?.option?.value === otherData?.option?.label;

      const newValue = {
        id: otherData?.option?.value,
        value: otherData?.option?.label,
        clientId: isNewOption ? uuid() : null,
      };

      checklistsController.changeAttrValue(EAttrType.UserDictionaryLink, groupId, {
        ...value,
        userDictionaryValues: !otherData ? [] : [newValue],
      });

      checklistsController.addSelectedSelectOptionList(
        EAttrType.UserDictionaryLink,
        groupId,
        id,
        !otherData
          ? []
          : [
              {
                value: newValue.id,
                label: newValue.value,
              },
            ]
      );
    },
    []
  );

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      onChange: handleChange,
      isRequired: initialModel.isRequired,
      defaultValueList: options.selectedSelectOptionList,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isAllowNewValueToBeAdded: true,
            isFullTextSearch: true,
          },
        },
        multiselect: initialModel.attribute?.isMultiselect ? {} : null,
      },
      placeholder,
    },
    body: {
      optionList: options.selectOptionList,
    },
    visual: {
      label: initialModel.attribute?.name,
      isBlocked,
      tooltip: initialModel.toolTip,
    },
    validation: {
      error: {
        errorList,
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
    other: {
      dataTestId: getDataTestId('dropdown')['data-test-id'],
    },
  };

  return (
    <>
      {isShowAttribute ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
          dataTestId={getDataTestId()['data-test-id']}
        >
          <Dropdown bodyForceCloseOptions={bodyForceCloseOptions} config={dropdownConfig} />

          <CSSContainer
            display={'flex'}
            justifyContent={'space-between'}
            dataTestId={getDataTestId('additional-info')['data-test-id']}
          >
            <CSSContainer dataTestId={getDataTestId('error-wrapper')['data-test-id']}>
              <InputFieldError error={{ errorList }} />
            </CSSContainer>

            <FileAttrContainer
              groupId={groupId}
              attrId={dependentFileAttrId}
              isNeedAdjustableContainer
            />
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsUserDictionaryAttr.displayName = 'ChecklistsUserDictionaryAttr';

export default observer(ChecklistsUserDictionaryAttr);
