import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../../../shared/utils/IoC';
import { IPotentialCultureZoneClientExtended } from '../../../../../../../../../../api/models/as-fields/STO/potentialCultureZones.model';
import { ERequestStatus } from '../../../../../../../../../shared/constants/requests';

@provide.singleton()
class StoFieldsTabStore {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Справочник идентификатора КЗ к неиспользуемым КЗ. Используется для быстрого доступа к сущности.
   *
   * @private
   * @memberof StoFieldsTabStore
   */
  private _CZIdToPotentialCultureZones: Map<
    string,
    IPotentialCultureZoneClientExtended
  > = new Map();
  /**
   * Список полей для вывода в листинг. Результат фильтрации попадает сюда же.
   *
   * @private
   * @memberof StoFieldsTabStore
   */
  private _potentialCultureZoneListToDisplay: IPotentialCultureZoneClientExtended[] = [];
  private _selectedFieldIdSet: Set<string> = new Set();
  private _transferredFiledIdSet: Set<string> = new Set();
  /**
   * Справочник идентификатора КЗ к используемым в другом СТО КЗ. Используется для быстрого доступа к сущности.
   *
   * @private
   * @memberof StoFieldsTabStore
   */
  private _CZIdToPotentialCultureZonesUsedInAnotherSto: Map<
    string,
    IPotentialCultureZoneClientExtended
  > = new Map();

  private _isSearchEnabled = false;
  private _lastSearchFieldValue = '';

  /**
   * Указатель загрузки хотя бы одного чанка полей.
   *
   * @private
   * @memberof StoFieldsTabStore
   */
  private _isFirstLoadReady = false;
  private _fieldFetchingStatus: ERequestStatus = null;

  get potentialCultureZoneList() {
    return Array.from(this._CZIdToPotentialCultureZones.values());
  }

  get CZusedInAnotherStoList() {
    return Array.from(this._CZIdToPotentialCultureZonesUsedInAnotherSto.values());
  }

  get CZIdToPotentialCultureZonesUsedInAnotherSto() {
    return this._CZIdToPotentialCultureZonesUsedInAnotherSto;
  }

  getPotentialCultureZone = (id: string) => {
    return this._CZIdToPotentialCultureZones.get(id);
  };

  getCZusedInAnotherSto = (id: string) => {
    return this._CZIdToPotentialCultureZonesUsedInAnotherSto.get(id);
  };

  get czIdToPotentialCultureZones() {
    return this._CZIdToPotentialCultureZones;
  }

  get potentialCultureZoneListToDisplay() {
    return this._potentialCultureZoneListToDisplay;
  }

  get selectedFieldIdSet() {
    return this._selectedFieldIdSet;
  }

  get transferredFiledIdSet() {
    return this._transferredFiledIdSet;
  }

  get isSearchEnabled() {
    return this._isSearchEnabled;
  }

  get lastSearchFieldValue() {
    return this._lastSearchFieldValue;
  }

  get isFirstLoadReady() {
    return this._isFirstLoadReady;
  }

  get fieldFetchingStatus() {
    return this._fieldFetchingStatus;
  }

  get isFieldsFetchingCompleted() {
    return (
      this._fieldFetchingStatus === ERequestStatus.Fulfilled ||
      this._fieldFetchingStatus === ERequestStatus.Rejected
    );
  }

  get hasFieldsInAnotherSto() {
    return this._CZIdToPotentialCultureZonesUsedInAnotherSto.size > 0;
  }

  get noFieldsToSelect() {
    return this._CZIdToPotentialCultureZones.size === 0;
  }

  setCZIdToPotentialCultureZones = (map: typeof this._CZIdToPotentialCultureZones) => {
    this._CZIdToPotentialCultureZones = map;
  };

  updateCZIdToPotentialCultureZones = (
    potentialZoneList: IPotentialCultureZoneClientExtended[]
  ) => {
    const list = [...this.potentialCultureZoneList, ...potentialZoneList];

    const map = new Map();

    list.forEach(item => map.set(item.id, item));

    this.setCZIdToPotentialCultureZones(map);
  };

  setCZIdToPotentialCultureZonesUsedInAnotherSto = (
    map: typeof this._CZIdToPotentialCultureZonesUsedInAnotherSto
  ) => {
    this._CZIdToPotentialCultureZonesUsedInAnotherSto = map;
  };

  updateCZIdToPotentialCultureZonesUsedInAnotherSto = (
    potentialZoneList: IPotentialCultureZoneClientExtended[]
  ) => {
    const list = [...this.potentialCultureZoneList, ...potentialZoneList];

    const map = new Map();

    list.forEach(item => map.set(item.id, item));

    this.setCZIdToPotentialCultureZonesUsedInAnotherSto(map);
  };

  partiallyUpdateCZInPotentialCultureZonesMap = (
    id: string,
    updateEntity: Partial<IPotentialCultureZoneClientExtended>
  ) => {
    const item = this.getPotentialCultureZone(id);

    this._CZIdToPotentialCultureZones.set(id, { ...item, ...updateEntity });
  };

  addCZIdToPotentialCultureZones = (potentialZone: IPotentialCultureZoneClientExtended) => {
    this._CZIdToPotentialCultureZones.set(potentialZone.id, potentialZone);
  };

  setPotentialCultureZoneListToDisplay = (list: typeof this._potentialCultureZoneListToDisplay) => {
    this._potentialCultureZoneListToDisplay = list;
  };

  setIsSearchEnabled = (state: boolean) => {
    this._isSearchEnabled = state;
  };

  setLastSearchFieldValue = (value: string) => {
    this._lastSearchFieldValue = value;
  };

  setIsFirstLoadReady = (state: boolean) => {
    this._isFirstLoadReady = state;
  };

  setFieldFetchingStatus = (status: ERequestStatus) => {
    this._fieldFetchingStatus = status;
  };

  clearCZIdToPotentialCultureZones = () => {
    this._CZIdToPotentialCultureZones = new Map();
  };

  clearPotentialCultureZoneListToDisplay = () => {
    this._potentialCultureZoneListToDisplay = [];
  };

  clearIsSearchEnabled = () => {
    this._isSearchEnabled = false;
  };

  clearLastSearchFieldValue = () => {
    this._lastSearchFieldValue = '';
  };

  clearIsFirstLoadReady = () => {
    this._isFirstLoadReady = false;
  };

  clearFieldFetchingStatus = () => {
    this._fieldFetchingStatus = null;
  };

  clearSelectedFields = () => {
    this._selectedFieldIdSet = new Set();
    this._transferredFiledIdSet = new Set();
  };

  clearSelectedFieldIdSet = () => {
    this._selectedFieldIdSet = new Set();
  };

  clearStoFieldsTabStore = () => {
    this.clearCZIdToPotentialCultureZones();
    this.clearPotentialCultureZoneListToDisplay();
    this.clearIsSearchEnabled();
    this.clearIsFirstLoadReady();
    this.clearFieldFetchingStatus();
    this.clearLastSearchFieldValue();
    this.clearSelectedFields();
  };
}

export default StoFieldsTabStore;
