import React, { FC } from 'react';

import { TContainerHeaderCleared as THeaderCleared } from '../../types';
import { useDataTestIdV2 } from '../../../../../../../utils/hooks/locators';
import { ContainerTitle as Title } from '../../../ContainerTitle';

import Styled from './ContainerHeaderCleared.styles';

interface IProps {
  dataTestId: string;
  header: THeaderCleared;
}

const ContainerHeaderCleared: FC<IProps> = ({ dataTestId, header }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
        <Title title={header.title} />

        {header?.additionalContent ? header.additionalContent : null}
      </Styled.TitleWrapper>

      {header?.additionalBottomContent}
    </Styled.Wrapper>
  );
};

ContainerHeaderCleared.displayName = 'ContainerHeaderCleared';

export default ContainerHeaderCleared;
