import React, { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown as Dropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreController as Controller } from '../../../../../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../../../../../../../hooks';
import {
  IStoAttributeOdzDependencyPut as IDependencyPut,
  IStoAttributeOdzDictionaryValue as IDictionaryValue,
} from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { StoStore } from '../../../../../../../../../../../../../../../../mobx';
import { TChecklistsDictionarySelectOption as TDictionarySelectOption } from '../../../../../../../../../../../../../../../../../../../tasks/modules/Checklists/types';
import { StoAttributeCoreStore as Store } from '../../../../../../mobx/stores';
import { EChecklistAttributeType as EAttributeType } from '../../../../../../../../../../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

import Styled from './StoAttributeOdzDependencyDictionary.styles';

const formatOptionList = (valueList: IDictionaryValue[]): TDictionarySelectOption[] => {
  return valueList.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

type TProps = { groupId: string; hasGroupSeveralDeps: boolean } & IDependencyPut;

const StoAttributeOdzDependencyDictionary: FC<TProps> = ({
  groupId,
  hasGroupSeveralDeps,
  id,
  dictionaryValues,
  systemData,
}) => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);

  const controller = useStore(Controller);

  const params = useStoParams();

  const errorList = store.getOdzDepErrorList(groupId, id);
  const optionList = store.getDepAttrOptionList(id);

  useEffect(() => {
    return () => {
      store.deleteOdzDepErrorList(groupId, id);
    };
  }, []);

  useEffect(() => {
    if (optionList.length) return;

    switch (systemData.attribute.attribute.type) {
      case EAttributeType.Enum:
        controller.addDepEnumOptionList(id);
        break;

      case EAttributeType.DictionaryLink:
        controller.addDepDictionaryOptionList(id, systemData.attribute.attribute.dictionaryLink);
        break;

      default:
    }
  }, []);

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const handleChange = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (_newValue, otherData) => {
      controller.changeOdzDependencyDictionaryValue(
        params,
        groupId,
        id,
        !otherData?.selectedValueList
          ? []
          : otherData.selectedValueList.map(o => ({ name: o.label, id: o.value }))
      );
    },
    []
  );

  const getDataTestId = useDataTestIdV2('sto__attribute__odz__dependency__dictionary');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Dropdown
        config={{
          field: {
            onChange: handleChange,
            defaultValueList: formatOptionList(dictionaryValues),
            icons: {
              clear: {},
            },
            type: {
              search: {
                options: {
                  isFullTextSearch: true,
                },
              },
              multiselect: {},
            },
            placeholder: 'Выберите или укажите значения',
          },
          body: {
            optionList,
          },
          visual: {
            isBlocked: checkIfIsBlockedByPermissions(),
            label: hasGroupSeveralDeps ? systemData.attribute.attribute.name : null,
          },
          other: {
            dataTestId: getDataTestId()['data-test-id'],
          },
        }}
      />

      {errorList.length ? (
        <Styled.Errors {...getDataTestId('errors')}>
          {errorList.map(error => (
            <Styled.Error key={error} {...getDataTestId('error')}>
              {error}
            </Styled.Error>
          ))}
        </Styled.Errors>
      ) : null}
    </Styled.Wrapper>
  );
};

StoAttributeOdzDependencyDictionary.displayName = 'StoAttributeOdzDependencyNumeric';

export default observer(StoAttributeOdzDependencyDictionary);
