import { AutoTooltip } from '@farmlink/farmik-ui';

import { ReactComponent as ArrowIcon } from '../../../../../../../shared/static/arrow.svg';
import { useStore } from '../../../../../../utils/IoC';
import { useDataTestIdV2 } from '../../../../../utils/hooks/locators';
import { MapCoreController } from '../../../../modules';

import Styled from './Location.styles';

const Location = () => {
  const mapCoreController = useStore(MapCoreController);

  const getDataTestId = useDataTestIdV2('map-control-locate');

  const setLocation = () => {
    mapCoreController.locate({ setView: true });
  };
  return (
    <Styled.Wrapper>
      <AutoTooltip content={'Моё местоположение'} position={'left'}>
        <Styled.Button onClick={setLocation} {...getDataTestId('button')}>
          <ArrowIcon />
        </Styled.Button>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

export default Location;
