import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../utils/IoC';
import { BaseImageOverlay } from '../../../utils/MapElements';

@provide.singleton()
class MapImageOverlayStore {
  private _imageOverlaysById: Map<number, BaseImageOverlay> = new Map<number, BaseImageOverlay>();

  constructor() {
    makeAutoObservable(this);
  }

  get imageOverlaysList() {
    return Array.from(this._imageOverlaysById.values());
  }

  getImageOverlay(id: number): BaseImageOverlay | undefined {
    return this._imageOverlaysById.get(id);
  }

  setImageOverlay = (imageLayer: BaseImageOverlay): void => {
    this._imageOverlaysById.set(imageLayer.id, imageLayer);
  };

  deleteImageOverlay = (id: number): void => {
    this._imageOverlaysById.delete(id);
  };
}

export default MapImageOverlayStore;
