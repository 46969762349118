import styled from 'styled-components';

const Input = styled.input`
  display: none;
`;

const LoaderWrapper = styled.div`
  height: 32px;
  width: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionList = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const StyledStoRegistry = {
  Input,
  ActionList,
  LoaderWrapper,
};

export default StyledStoRegistry;
