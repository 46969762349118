import { useContext } from 'react';

import ContainerContext, { IContainerContext } from '../../ContainerContext';

const useContainerContext = (): IContainerContext => {
  const context = useContext(ContainerContext);

  return context;
};

export default useContainerContext;
