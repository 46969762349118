import styled, { css, keyframes } from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';

import OutContainerImagesIcon from './assets/OutContainerImagesIcon.svg';
import { ReactComponent as DeleteIcon } from './assets/deleteIcon.svg';

type StyledAddFirstImgLabelProps = {
  $isRequired: boolean;
};

export const AddFirstImgLabel = styled.div<StyledAddFirstImgLabelProps>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3fb65f;
  cursor: pointer;

  &:after {
    color: red;
    visibility: ${({ $isRequired }) => ($isRequired ? 'visible' : 'hidden')};
    content: '*';
  }
`;

export const FileUploader = styled.input`
  display: none;
  maxfiles: 1;
`;

export const ImgsRowBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

export const PaperClipIconImg = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;

export const MiniImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  object-fit: unset;
  object-position: 50% 50%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10002;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(21, 31, 50, 0.3);
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
`;

export const ImageCarouselBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 20px;
  min-width: 400px;
  min-height: 400px;
  max-width: 600px;
  max-height: 600px;
  z-index: 9999;
`;

export const ArrowLeft = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 20px;
`;

export const ArrowRight = styled.img`
  height: 40px;
  width: 40px;
  transform: rotate(180deg);
  margin-left: 20px;
`;

export const ImageBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 100%;
  max-width: 100%;
  border-radius: 16px;
  background: #ffffff;
  padding: 8px;
`;

export const LargeImage = styled.img`
  width: auto;
  max-height: 80svh;
  max-width: 100%;
  border-radius: 12px;
`;

export const ActionsBlockWrapper = styled.div``;

export const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-end;
  position: absolute;
  // height: 0;
  // width: 0;
`;

export const ImgAction = styled.img`
  height: 32px;
  width: 32px;
  margin-top: 12px;
  margin-right: 12px;
  cursor: pointer;
`;

export const ActionClose = styled(ImgAction)``;

export const ActionDelete = styled(ImgAction)``;

export const ActionDownload = styled(ImgAction)``;

export const ImagesUploaderWrapper = styled.div<{ $isSeparate?: boolean; $isVisible: boolean }>`
  width: 100%;
  position: relative;
  margin-top: 5px;
  ${({ $isSeparate }) =>
    !$isSeparate &&
    css`
      margin-top: 5px;
    `}

  ${({ $isVisible }) =>
    $isVisible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

export const OutContainerPlaceholder = styled.div`
  background: url(${OutContainerImagesIcon});
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border-radius: 12px;
`;

export const MiniImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  width: 40px;
  margin-right: 8px;
`;

export const DeleteButton = styled.img`
  position: absolute;
  transform: translate(15px, -14px);
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const spinner = keyframes`
  to {transform: rotate(360deg);} 
`;

export const Spinner = styled.div`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  animation: ${spinner} 0.6s linear infinite;
  align-self: center;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  position: absolute;
  transform: translate(15px, -14px);
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:hover rect {
    fill: ${Colors.pinkHover};
  }
`;
