import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Title = styled('h3')({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '20px',
  margin: 0,
  marginTop: ESpaceSize.L,
  marginBottom: ESpaceSize.M,
});

const Description = styled('p')({
  textAlign: 'center',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  color: Colors.generalBlack,
  margin: 0,
  marginBottom: ESpaceSize['3XL'],
  whiteSpace: 'pre-wrap',
});

export default {
  Wrapper,
  Title,
  Description,
};
