import { makeAutoObservable, runInAction } from 'mobx';

import { Field } from '../../../../../../../../api/models/field.model';
import { FieldSeasonsModel } from '../../../../../../../../api/models/field.seasons.model';
import { mapToArray } from '../../../../../../../shared/utils/helpers/map/mapToArray';
import { provide } from '../../../../../../../shared/utils/IoC';

@provide.singleton()
class FieldSeasonsStore {
  private _field: Field | null = null;

  private _fieldSeasonsCollection = new Map<number, FieldSeasonsModel>();

  private _selectedSeason: FieldSeasonsModel | null = null;

  private _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  /**
   * Возвращает активную модель поля (согласно id в url)
   */
  get field() {
    return this._field;
  }

  set field(value) {
    this._field = value;
  }

  /**
   * Возвращает выбранный сезон и его версию поля
   */
  get selectedSeason() {
    return this._selectedSeason;
  }

  set selectedSeason(season) {
    this._selectedSeason = season;
  }

  /**
   * Возвращает список всех сезонов для поля
   */
  get fieldSeasonsList() {
    return mapToArray(this._fieldSeasonsCollection);
  }

  get isLastFieldSeasons() {
    return this.fieldSeasonsList.filter(season => season.fieldVersion).length <= 1;
  }

  getSeasonByYear = (year: number | string): FieldSeasonsModel => {
    const empty = { season: null, fieldVersion: null };

    return this._fieldSeasonsCollection.get(Number(year)) ?? empty;
  };

  setFieldSeasons = (models: FieldSeasonsModel[]) => {
    runInAction(() => {
      models.forEach(obj => {
        this._fieldSeasonsCollection.set(obj.season.year, obj);
      });
    });
  };

  clearFieldSeasons = () => {
    this._fieldSeasonsCollection.clear();
  };

  clear = () => {
    this.clearFieldSeasons();
    this._selectedSeason = null;
    this._isLoading = false;
    this._field = null;
  };
}

export default FieldSeasonsStore;
