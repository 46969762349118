import { makeAutoObservable } from 'mobx';
import { flattenDeep, isFunction } from 'lodash';

import { provide } from '../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import {
  IStoAttribute,
  IStoAttribute as IAttribute,
  IStoChecklistsAttrsTableAttrUpdate as IAttributeUpdate,
} from '../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { IStoAttributeUserDictionaryRegistryItem as IUserDictionaryRegistryItem } from '../../../containers/StoAttributeUserDictionaryRegistry/interfaces';
import { TChecklistsDictionarySelectOption as TDictionarySelectOption } from '../../../../../../../../../../../../../../../../tasks/modules/Checklists/types';
import { ISelectOption } from '../../../../../../../../../../../../../../../../../../../types/selectOption';
import { IStoAttributeOdzDependencySelect as IDependencySelect } from '../../../interfaces';
import { EStoOdzCommonError } from '../../../constants';

type TStoreSetter<V = undefined> = (value: V | ((prevValue: V) => V)) => void;

@provide.singleton()
class StoAttributeCoreStore {
  private _isLoading = true;

  private _isSaved = false;

  private _hasChanges = false;

  private _hasErrors = false;

  private _hasNoSelectedDependency: boolean;

  private _attribute: IAttribute | null = null;

  private _prevAttributeUpdate: IAttributeUpdate | null = null;

  private _changedAttrProps: Set<keyof IStoAttribute> = new Set<keyof IStoAttribute>();

  private _alreadyChangedAttrIds: Set<string> = new Set<string>();

  private _userDictionaryItemList: IUserDictionaryRegistryItem[] = [];

  private _dictionaryOptionList: TDictionarySelectOption[] = [];

  private _odzErrorListByGroupId: Map<string, string[]> = new Map<string, string[]>();

  private _depOdzErrorListByAttrIdByGroupId: Map<string, Map<string, string[]>> = new Map<
    string,
    Map<string, string[]>
  >();

  private _dependencyOptionList: ISelectOption<IAttribute>[] = [];

  private _depAttrOptionListByAttrId: Map<string, ISelectOption[]> = new Map<
    string,
    ISelectOption[]
  >();

  private _dependencySelectList: IDependencySelect[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }
  public get isSaved(): boolean {
    return this._isSaved;
  }
  public get hasChanges(): boolean {
    return this._hasChanges;
  }

  public get hasErrors(): boolean {
    return this._hasErrors;
  }

  public get hasNoSelectedDependency(): boolean {
    return this._hasNoSelectedDependency;
  }

  public get attribute(): IAttribute {
    return this._attribute;
  }

  public get prevAttributeUpdate(): IAttributeUpdate {
    return this._prevAttributeUpdate;
  }

  public get changedAttrPropList(): (keyof IStoAttribute)[] {
    return [...this._changedAttrProps.values()];
  }

  public hasAlreadyChangedAttrId = (id: string): boolean => {
    return this._alreadyChangedAttrIds.has(id);
  };

  public get userDictionaryItemList(): IUserDictionaryRegistryItem[] {
    return this._userDictionaryItemList;
  }

  public get dictionaryOptionList(): TDictionarySelectOption[] {
    return this._dictionaryOptionList;
  }

  public getOdzErrorList = (id: string): string[] => {
    return this._odzErrorListByGroupId.get(id) ?? [];
  };

  public hasOdzErrorsByGroup = (id: string): boolean => {
    return this._odzErrorListByGroupId.has(id);
  };

  public get hasOdzErrors(): boolean {
    const errorList = [...this._odzErrorListByGroupId.values()].flatMap(el => el);

    return Boolean(errorList.length);
  }

  public get hasDepOdzErrors(): boolean {
    const errorList = flattenDeep(
      [...this._depOdzErrorListByAttrIdByGroupId.values()].map(hasErrors => [...hasErrors.values()])
    );

    return Boolean(errorList.length);
  }

  public hasDepOdzError = (groupId: string, attrId: string, error: string): boolean => {
    const index = this._depOdzErrorListByAttrIdByGroupId
      .get(groupId)
      ?.get?.(attrId)
      ?.findIndex?.(e => e === error);

    if (index === undefined) return false;
    if (index !== -1) return true;

    return false;
  };

  public hasDepOdzErrorsInGroup = (groupId: string): boolean => {
    if (!this._depOdzErrorListByAttrIdByGroupId.has(groupId)) return false;

    const errorList = flattenDeep([
      ...this._depOdzErrorListByAttrIdByGroupId.get(groupId).values(),
    ]);

    return Boolean(errorList.length);
  };

  public getOdzDepErrorList = (groupId: string, attrId: string): string[] => {
    return this._depOdzErrorListByAttrIdByGroupId.get(groupId)?.get?.(attrId) ?? [];
  };

  public get dependencyOptionList(): ISelectOption<IAttribute>[] {
    return this._dependencyOptionList;
  }

  public getDepAttrOptionList = (id: string): ISelectOption[] => {
    return this._depAttrOptionListByAttrId.get(id) ?? [];
  };

  public get dependencySelectList(): IDependencySelect[] {
    return this._dependencySelectList;
  }

  public setIsLoading = (value: boolean): void => {
    this._isLoading = value;
  };

  public setIsSaved = (value: boolean): void => {
    this._isSaved = value;
  };

  public setHasChanges = (value: boolean): void => {
    this._hasChanges = value;
  };

  public setHasErrors = (value: boolean): void => {
    this._hasErrors = value;
  };

  public setHasNoSelectedDependency = (value: boolean): void => {
    this._hasNoSelectedDependency = value;
  };

  public setAttribute = (attribute: IAttribute): void => {
    this._attribute = attribute;
  };

  public setPrevAttributeUpdate = (attributeUpdate: IAttributeUpdate): void => {
    this._prevAttributeUpdate = attributeUpdate;
  };

  public setChangedAttrProp = (prop: keyof IStoAttribute): void => {
    this._changedAttrProps.add(prop);
  };

  public setAlreadyChangedAttrId = (id: string): void => {
    this._alreadyChangedAttrIds.add(id);
  };

  public deleteAlreadyChangedAttrId = (id: string): void => {
    this._alreadyChangedAttrIds.delete(id);
  };

  public setUserDictionaryItemList: TStoreSetter<IUserDictionaryRegistryItem[]> = value => {
    if (isFunction(value)) {
      this._userDictionaryItemList = value(this._userDictionaryItemList);
    } else {
      this._userDictionaryItemList = value;
    }
  };

  public setDictionaryOptionList = (dictionaryOptionList: TDictionarySelectOption[]): void => {
    this._dictionaryOptionList = dictionaryOptionList;
  };

  public setOdzError = (groupId: string, error: string): void => {
    const prevList = this._odzErrorListByGroupId.get(groupId) ?? [];

    prevList.push(error);

    this._odzErrorListByGroupId.set(groupId, prevList);
  };

  public setDependencyOptionList: TStoreSetter<ISelectOption<IAttribute>[]> = value => {
    if (isFunction(value)) {
      this._dependencyOptionList = value(this._dependencyOptionList);
    } else {
      this._dependencyOptionList = value;
    }
  };

  public setOdzDepError = (groupId: string, attrId: string, error: string): void => {
    const errorList = this._depOdzErrorListByAttrIdByGroupId.get(groupId)?.get?.(attrId);

    if (errorList) {
      this._depOdzErrorListByAttrIdByGroupId.get(groupId).set(attrId, [...errorList, error]);
      return;
    }

    if (this._depOdzErrorListByAttrIdByGroupId.has(groupId)) {
      this._depOdzErrorListByAttrIdByGroupId.get(groupId).set(attrId, [error]);
      return;
    }

    const col: Map<string, string[]> = new Map<string, string[]>();
    col.set(attrId, [error]);

    this._depOdzErrorListByAttrIdByGroupId.set(groupId, col);
  };

  public clearOdzDepErrorEmptyInGroup = (groupId: string): void => {
    if (!this._depOdzErrorListByAttrIdByGroupId.has(groupId)) return;

    const errorEntryList = [...this._depOdzErrorListByAttrIdByGroupId.get(groupId).entries()];

    errorEntryList.forEach(([depId, errList]) => {
      if (errList.includes(EStoOdzCommonError.DependencyEmpty)) {
        this.deleteOdzDepError(groupId, depId, EStoOdzCommonError.DependencyEmpty);
      }
    });
  };

  public setDepAttrOptionList = (attrId: string, optionList: ISelectOption[]): void => {
    this._depAttrOptionListByAttrId.set(attrId, optionList);
  };

  public deleteOdzDepErrorList = (groupId: string, attrId: string): void => {
    this._depOdzErrorListByAttrIdByGroupId.get(groupId)?.delete?.(attrId);
  };

  public deleteOdzDepError = (groupId: string, attrId: string, error: string): void => {
    const prevList = this._depOdzErrorListByAttrIdByGroupId.get(groupId)?.get?.(attrId) ?? [];

    if (!prevList.length) return;

    this._depOdzErrorListByAttrIdByGroupId.get(groupId).set(
      attrId,
      prevList.filter(el => el !== error)
    );
  };

  public deleteOdzDepErrorsByGroupId = (groupId: string): void => {
    this._depOdzErrorListByAttrIdByGroupId.delete(groupId);
  };

  public deleteOdzError = (groupId: string, error: string): void => {
    const prevList = this._odzErrorListByGroupId.get(groupId) ?? [];

    this._odzErrorListByGroupId.set(
      groupId,
      prevList.filter(el => el !== error)
    );
  };

  public deleteOdzErrorByGroupId = (groupId: string): void => {
    this._odzErrorListByGroupId.delete(groupId);
  };

  public setDependencySelectList: TStoreSetter<IDependencySelect[]> = value => {
    if (isFunction(value)) {
      this._dependencySelectList = value(this._dependencySelectList);
    } else {
      this._dependencySelectList = value;
    }
  };

  public clearIsLoading = (): void => {
    this._isLoading = true;
  };

  public clearIsSaved = (): void => {
    this._isSaved = false;
  };

  public clearHasChanges = (): void => {
    this._hasChanges = false;
  };

  public clearHasErrors = (): void => {
    this._hasChanges = false;
  };

  public clearHasNoSelectedDependency = (): void => {
    this._hasNoSelectedDependency = false;
  };

  public clearAttribute = (): void => {
    this._attribute = null;
  };

  public clearPrevAttributeUpdate = (): void => {
    this._attribute = null;
  };

  public clearChangedAttrProps = (): void => {
    this._changedAttrProps.clear();
  };
  public clearAlreadyChangedAttrIds = (): void => {
    this._alreadyChangedAttrIds.clear();
  };

  public clearUserDictionaryItemList = (): void => {
    this._userDictionaryItemList = [];
  };

  public clearDictionaryOptionList = (): void => {
    this._dictionaryOptionList = [];
  };

  public clearOdzErrorListByGroupId = (): void => {
    this._odzErrorListByGroupId.clear();
  };

  public clearOdzDepErrorListByAttrIdByGroupId = (): void => {
    this._depOdzErrorListByAttrIdByGroupId.clear();
  };

  public clearDependencyOptionList = (): void => {
    this._dependencyOptionList = [];
  };

  public clearDepAttrOptionListByAttrId = (): void => {
    this._depAttrOptionListByAttrId.clear();
  };

  public clearDependencySelectList = (): void => {
    this._dependencySelectList = [];
  };
}

export default StoAttributeCoreStore;
