import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

const STO_DELETE_DRAFT_MODAL_ID = 'sto-delete-draft-modal';

const stoDeleteDraftModalConfig: TModalConfig = {
  id: STO_DELETE_DRAFT_MODAL_ID,
  name: STO_DELETE_DRAFT_MODAL_ID,
  dataTestId: STO_DELETE_DRAFT_MODAL_ID,
  title: null,
  type: EModalType.Warning_Before_Living,
  description: {
    value: `Вы уверены, что хотите удалить
    черновик СТО`,
    $styles: {
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '-0.15px',
      marginBottom: '36px',
      whiteSpace: 'pre-line',
      wordBreak: 'unset !important' as any,
    },
  },
  styledProps: {
    $size: '440px',
  },

  successButton: {
    title: 'Удалить',
    color: 'secondary',
  },

  denyButton: {
    title: 'Не удалять',
    color: 'default',
  },
};

export { stoDeleteDraftModalConfig, STO_DELETE_DRAFT_MODAL_ID };
