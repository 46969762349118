import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  id: string;
  seasonYear: number;
};

type TypeResponse = Array<any>;

export const getWeatherById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: `/api/as-proxy/fields/weatherById`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
