import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`;

export const RightColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SideBarWrapper = styled.div`
  display: flex;
`;
