import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  organizationId?: string;
  year: number;
};

type TypeResponse = any;

export const deleteSeason: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => `/api/as-fields/seasons/${req.year}${req.organizationId ? 
    `?organizationId=${req.organizationId}` : ``}`,
  method: 'DELETE',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
