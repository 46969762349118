import { Colors } from '@farmlink/farmik-ui';

type TFieldStyleType = 'display' | 'edit' | 'selected';

type TProps = {
  [key in TFieldStyleType]: L.PolylineOptions;
};

const FIELD_POLYGON_OPTIONS: TProps = {
  display: {
    fillOpacity: 0,
    color: Colors.generalWhite,
  },
  edit: {
    fillColor: Colors.addSunny,
    color: Colors.addSunny,
    fillOpacity: 0.15,
  },
  selected: {
    fillOpacity: 0,
    color: '#780BBB',
  },
};

export { FIELD_POLYGON_OPTIONS };
