import { TypeApiRoute } from '../../../models/type.api.request';
import { IStoAttribute } from '../../../models/as-fields/new/sto';

type TRequest = {
  id: string;
  techOperationTypeId: string;
  checklistId: string;
  stageId: string;
  checklistAttributeId: string;
  versionId?: string;
};

type TResponse = IStoAttribute[];

export const getStoDependencyAttributeList: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id, techOperationTypeId, checklistId, stageId, checklistAttributeId }) =>
    `/api/as-fields/sto/${id}/techOperationType/${techOperationTypeId}/checklists/${checklistId}/stage/${stageId}/${checklistAttributeId}/attributesForFormula`,
  method: 'GET',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
