import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  min-height: 0;
`;

const MapWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const MapsControlsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
`;

export default {
  Wrapper,
  MapWrapper,
  MapsControlsWrapper,
};
