import _ from 'lodash';

import {
  lazyInject,
  provide,
} from '../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { IStoAttributeUserDictionaryRegistryItem as IUserDictionaryRegistryItem } from '../../../containers/StoAttributeUserDictionaryRegistry/interfaces';
import { StoAttributeRequestsService as RequestsService } from '../StoAttributeRequestsService';
import { StoAttributeCoreStore as Store } from '../../stores';
import { IStoChecklistsAttrsTableUserDictionaryValueUpdate as IUserDictionaryValueUpdate } from '../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto/StoChecklistsParamsTable/StoTable.model';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../../../../../../mobx/stores';

@provide.transient()
class StoAttributeUserDictionaryService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(RequestsService)
  protected requestsService: RequestsService;

  public initiateUserDictionaryRegistry = async ({
    organizationId,
    checklistId,
    stageId,
    attributeId,
  }: {
    organizationId: string;
    checklistId: string;
    stageId: string;
    attributeId: string;
  }): Promise<void> => {
    const formattedSavedList = this.getFormattedSavedItemList({
      checklistId,
      stageId,
      attributeId,
    });

    const formattedFetchedList = await this.getFormattedFetchedItemList({
      organizationId,
      attributeId,
      stageId,
      savedItemList: formattedSavedList,
    });

    const selectedList = formattedSavedList.filter(({ isSelected }) => isSelected);

    const notSelectedSavedList = formattedSavedList.filter(
      el => !selectedList.some(selectedEl => selectedEl.id === el.id)
    );

    const newList = selectedList.filter(({ isNew }) => isNew);
    const filteredSelectedList = selectedList.filter(
      el => !newList.some(newEl => newEl.id === el.id)
    );

    const notSelectedList = [...notSelectedSavedList, ...formattedFetchedList];

    const sortedSelectedList = _.sortBy(filteredSelectedList, 'value');
    const sortedNotSelectedList = _.sortBy(notSelectedList, 'value');

    const formattedList: IUserDictionaryRegistryItem[] = [
      ...newList,
      ...sortedSelectedList,
      ...sortedNotSelectedList,
    ];

    this.store.setUserDictionaryItemList(formattedList);
  };

  public toggleSelection = (itemId: string, isSelected: boolean): void => {
    const formattedItemList = this.store.userDictionaryItemList.map<IUserDictionaryRegistryItem>(
      item => {
        if (item.id !== itemId) return item;

        return {
          ...item,
          isSelected,
        };
      }
    );

    this.store.setUserDictionaryItemList(formattedItemList);
  };

  public removeItem = (itemId: string): void => {
    this.store.setUserDictionaryItemList(prevValue => prevValue.filter(({ id }) => id !== itemId));
  };

  public editValue = (itemId: string, value: string): void => {
    const formattedItemList = this.store.userDictionaryItemList.map<IUserDictionaryRegistryItem>(
      item => {
        if (item.id !== itemId) return item;

        return {
          ...item,
          value,
        };
      }
    );

    this.store.setUserDictionaryItemList(formattedItemList);
  };

  public addNewValue = (value: IUserDictionaryValueUpdate): void => {
    const item: IUserDictionaryRegistryItem = {
      ...value,
      isNew: true,
      isHighlighted: true,
      isSelected: true,
    };

    const formattedItemList: IUserDictionaryRegistryItem[] = this.store.userDictionaryItemList.map(
      el => ({ ...el, isHighlighted: false })
    );

    this.store.setUserDictionaryItemList([item, ...formattedItemList]);
  };

  public initiateUserDictionaryValues = (): IUserDictionaryValueUpdate[] => {
    return this.store.attribute.userDictionaryValues.reduce<IUserDictionaryValueUpdate[]>(
      (list, el) => {
        if (el.isActive) {
          list.push({
            id: el.id,
            value: el.value,
          });
        }

        return list;
      },
      []
    );
  };

  public removeItemHighlight = (itemId: string): void => {
    this.store.setUserDictionaryItemList(prevValue =>
      prevValue.map(item => {
        if (item.id !== itemId) return item;

        return {
          ...item,
          isHighlighted: false,
        };
      })
    );
  };

  protected getFormattedSavedItemList = ({
    checklistId,
    stageId,
    attributeId,
  }: {
    checklistId: string;
    stageId: string;
    attributeId: string;
  }): IUserDictionaryRegistryItem[] => {
    const attributeUpdate = this.checklistsStore.getAttrUpdate(checklistId, stageId, attributeId);

    if (this.store.hasAlreadyChangedAttrId(attributeId)) {
      return attributeUpdate.userDictionaryValues.map<IUserDictionaryRegistryItem>(el => ({
        id: el.id,
        value: el.value,
        isSelected: true,
        isNew: Boolean(el.clientId),
      }));
    } else {
      return this.store.attribute.userDictionaryValues.map<IUserDictionaryRegistryItem>(el => ({
        id: el.id,
        value: el.value,
        isSelected: el.isActive,
        isNew: false,
      }));
    }
  };

  protected getFormattedFetchedItemList = async ({
    organizationId,
    attributeId,
    stageId,
    savedItemList,
  }: {
    organizationId: string;
    attributeId: string;
    taskId?: string;
    stageId: string;
    savedItemList: IUserDictionaryRegistryItem[];
  }): Promise<IUserDictionaryRegistryItem[]> => {
    const fetchedList = await this.requestsService.fetchUserDictionaryList(
      organizationId,
      attributeId,
      stageId
    );

    const formattedFetchedItemList = fetchedList.reduce<IUserDictionaryRegistryItem[]>(
      (list, el) => {
        const isNotDuplicate = !savedItemList.some(item => item.id === el.id);

        if (isNotDuplicate) {
          list.push({
            id: el.id,
            value: el.value,
            isSelected: false,
            isNew: false,
          });
        }

        return list;
      },
      []
    );

    return formattedFetchedItemList;
  };
}

export default StoAttributeUserDictionaryService;
