import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import { ETaskAction } from '../../../../../../../../api/models/as-fields/task/task.model';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ChecklistInstancesStore } from '../../../../../operationsAndTasks/stores/checklist.instances.store';
import { useTaskActionControl, useTasksRouteActions } from '../../../../hooks';
import { TaskStore } from '../../../../mobx/stores';
import EditInspectionPoint from '../../../EditInspectionPoint/EditInspectionPoint';
import { useInspectionPointsActionsHook } from '../../../InspectionPoints/hooks';
import { InspectionPointsStore } from '../../../InspectionPoints/mobx/stores';

const MapEditPointContainer: FC = () => {
  // Сторы
  const { listOfDrawPointInst } = useStore(ChecklistInstancesStore);
  const { selectedTask, selectedField } = useStore(TaskStore);
  const { selectedPoint } = useStore(InspectionPointsStore);

  const { displayPointList, displayZone } = useInspectionPointsActionsHook();
  const routeActions = useTasksRouteActions();
  const controls = useTaskActionControl();
  const hasPermissionToEdit = controls.hasPermission(ETaskAction.TaskEditPoints);

  useEffect(() => {
    if (!hasPermissionToEdit || !selectedPoint) {
      routeActions.goToMap();
    }
  }, [selectedPoint, hasPermissionToEdit]);

  useEffect(() => {
    displayZone(selectedTask?.cultureZone || selectedField, {
      isAllowedToAddPoints: true,
    });

    displayPointList(listOfDrawPointInst, { isCleanDisplay: true, isDisabled: true });
  }, [selectedTask?.cultureZone, selectedField, listOfDrawPointInst]);

  return <EditInspectionPoint selectedPoint={selectedPoint} />;
};
export default observer(MapEditPointContainer);
