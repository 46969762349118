import { TypeApiRoute } from '../../models/type.api.request';

type TypeRequest = {
  year: number;
  organizationId?: string;
  startDate: string;
  endDate: string;
};

type TypeResponse = any;

export const saveSeason: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
} = {
  url: req => {
    if (req.organizationId) {
      return `/api/as-fields/seasons/${req.year}?organizationId=${req.organizationId}`;
    } else {
      return `/api/as-fields/seasons/${req.year}`;
    }
  },
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
};
