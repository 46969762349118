import { FC, useMemo, useState } from 'react';
import { CalendarComponent } from '@farmlink/farmik-ui';
import moment from 'moment';

import { IndexModel } from '../../../../../../../../../../../../../../api/models/indices.model';

import Styled from './Calendar.styles';

interface ICalendarProps {
  value: string;
  dateMap: Map<string, IndexModel>;
  availableYearList: number[];
  selectDay: (index: IndexModel) => void;
  onYearChange: (year: number) => void;
}

const Calendar: FC<ICalendarProps> = ({
  value,
  dateMap,
  availableYearList,
  selectDay,
  onYearChange,
}) => {
  const [monthInSelector, setMonthInSelector] = useState(moment(value).toDate().getMonth());

  const handleChange = (date: string) => {
    const index = dateMap.get(date);

    selectDay(index);
  };

  const yearChangeHandler = (date: Date) => {
    onYearChange(date.getFullYear());
  };

  const existedDateList = useMemo(
    () =>
      Array.from(dateMap.values())
        .flatMap(item => moment(item.fromDate).toDate())
        .sort((a, b) => a.getTime() - b.getTime()),
    [dateMap]
  );

  const handleMonthChange = (date: Date, month: number, isYearChanged: boolean) => {
    setMonthInSelector(month);

    if (isYearChanged) {
      yearChangeHandler(date);
    }
  };

  return (
    <Styled.Wrapper>
      {
        <CalendarComponent
          dataTestId={'operation-start-date'}
          onChange={(date: Date) => handleChange(moment(date).format('YYYY-MM-DD'))}
          isCloseOnChange={false}
          componentType="icon"
          value={moment(value).format('DD.MM.YYYY')}
          datePickerOptions={{
            yearToScroll: 0,
          }}
          headerConfig={{
            onMonthChange: (date, month, isYearChanged) => {
              handleMonthChange(date, month, isYearChanged);
              return {};
            },
            disableConfig: {
              monthLeft: monthInSelector === 0,
              monthRight: monthInSelector === 11,
            },
          }}
          yearOptionList={availableYearList}
          rootPropList={{
            includeDates: existedDateList,
            onYearChange: yearChangeHandler,
          }}
        />
      }
    </Styled.Wrapper>
  );
};

export default Calendar;
