import { Input } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, ReactElement, useCallback, useMemo, useState } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { IMultiListFilterOption } from '../../../../../../../../interfaces/Filter.interface';
import { FieldCultureFilter, FieldNameFilter } from '../../../../../../../../utils';
import FilterSectionHeader from '../SectionHeader/SectionHeader';

import OptionsList from './components/OptionsList/OptionsList';
import Styled from './MultiListSection.styles';

interface IProps {
  title: string;
  filter: FieldCultureFilter | FieldNameFilter;
  listItem: {
    render: (x: any) => ReactElement;
    height: number;
  };
  createSearchFilter: (searchValue: string) => (option: any) => boolean;
}

const MultiListSection: FC<IProps> = props => {
  const { filter } = props;

  const [expanded, setExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const getDataTestId = useDataTestIdV2(`fields-${filter.id}-filter`);

  const filteredOptionList = useMemo(() => {
    if (!searchValue) {
      return filter.optionList ?? [];
    }

    const bySearchValue = props.createSearchFilter(searchValue.toLowerCase());
    return filter.optionList.filter(bySearchValue) ?? [];
  }, [searchValue, filter.optionList, props.createSearchFilter]);

  const resetFilter = useCallback(() => {
    filter.resetValue();
    setExpanded(false);
  }, []);

  const handleSelectOption = (item: IMultiListFilterOption) => {
    if (item.disabled) {
      return;
    }

    filter.toggleValue(item.value);
  };

  return (
    <Styled.Wrapper>
      <FilterSectionHeader
        title={props.title}
        showResetBtn={!filter.isEmptyValue}
        onResetClick={resetFilter}
        getDataTestId={getDataTestId}
      />

      {expanded && filter?.optionList.length > 12 && (
        <Styled.InputContainer>
          <Input
            value={searchValue}
            placeholder={'Поиск'}
            startIcon={'search'}
            endIcon={searchValue ? 'close' : null}
            height={'32px'}
            onChange={setSearchValue}
            onEndIconClick={() => setSearchValue('')}
            {...getDataTestId('search-input')}
          />
        </Styled.InputContainer>
      )}

      <OptionsList
        list={filteredOptionList}
        expanded={expanded}
        listItem={props.listItem}
        onSelect={handleSelectOption}
        onToggleExpand={() => setExpanded(!expanded)}
        getDataTestId={getDataTestId}
      />
    </Styled.Wrapper>
  );
};

export default observer(MultiListSection);
