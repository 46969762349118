import { makeAutoObservable } from 'mobx';

import FilterManager from './FilterManager';
import Filter from './Filter';

/**
 * Определяет отдельный режим работы менеджера фильтров.
 * При включении данного режима сохраняется текущее состояние фильтров. Все последующие операции не будут применены к manager.filteredList.
 * Обновление filteredList произойдет только после вызова this.applyChanges()
 * Если выйти из данного режима через this.discardChanges() то будут применены предыдущие сохраненные значения фильтров
 */
class TempEditor<T extends Filter[] = any, E = unknown, S = unknown> {
  private _enabled = false;

  private _filteredList: Array<E> = [];

  private readonly _manager: FilterManager<T, E, S>;

  private sorterSnapshot = null;

  constructor(manager: FilterManager<T, E, S>) {
    this._manager = manager;

    makeAutoObservable(this);
  }

  public get enabled() {
    return this._enabled;
  }

  public get filteredList() {
    return this._filteredList;
  }

  public set filteredList(value) {
    this._filteredList = value;
  }

  /**
   * Включает режим временного редактирования фильтров.
   * Сохраняет текущие значения фильтров
   */
  public enable() {
    if (this._enabled) {
      return;
    }

    this._enabled = true;
    this._filteredList = [...this._manager.filteredList];

    this.createManagerSnapshot();
  }

  /**
   * Отменяет все сделанные изменения. Возвращает фильтрам предыдущие сохраненные значения
   */
  public discardChanges() {
    if (!this._enabled) {
      return;
    }

    this._enabled = false;

    this.restoreManagerSnapshot();
  }

  /**
   * Применяет внесенные в фильтры изменения.
   * Обновляет основной спискок (manager.filteredList)
   */
  public applyChanges() {
    this._enabled = false;
    this.filteredList.length = 0;

    this._manager.filterSourceList('tempEditor');
  }

  private createManagerSnapshot() {
    this.sorterSnapshot = this._manager.sorter.value;
    this._manager.filters.createSnapshot();
  }

  private restoreManagerSnapshot() {
    this._manager.sorter.setValue(this.sorterSnapshot, true);
    this._manager.filters.restoreSnapshot(true);
  }
}

export default TempEditor;
