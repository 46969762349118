import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import close_btn from '../../../../../../assets/images/closeGoogleSuggestions.svg';

const SearchWrapper = styled.div`
  display: flex;
  pointer-events: auto;
`;

const BtnSearchWrapper = styled.div`
  & * {
    fill: ${Colors.generalBlack};
  }
  &:not(.opened):hover * {
    fill: ${Colors.accentGreen};
  }
  & svg {
    width: 14px;
    height: 14px;
    transition: all 0.3s ease-in-out;
  }
  &.opened svg {
    width: 14px;
    height: 14px;
    * {
      fill: ${Colors.darkGrey};
    }
  }
  &.not-empty-text {
    width: 17px;
    height: 17px;
    svg {
      width: 0;
    }
  }
  width: 40px;
  height: 40px;
  transition: all 0.3s ease-in-out;
  background-color: ${Colors.generalWhite};
  border-radius: 12px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const InputSearchWrapper = styled.div`
  width: 400px;
  background-color: ${Colors.generalWhite};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  padding-right: 12px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  & small {
    font-size: 14px;
    line-height: 20px;
  }
  &.autocomplete {
    border-radius: 16px;
  }
  &.closed {
    width: 40px;
    padding: 0;
    /* margin: 0; */
    input {
      padding: 0;
    }
  }
  & .inner_wrapper {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }
  &.autocomplete .inner_wrapper,
  &.autocomplete_ZERO_RESULTS .inner_wrapper {
    position: relative;
    :after {
      content: '';
      display: block;
      border-bottom: 0.5px solid ${Colors.borderGray};
      position: absolute;
      left: 12px;
      right: 0;
      bottom: 0;
    }
  }
  & .inner_wrapper input {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.generalBlack};
    background: ${Colors.generalWhite};
    border-radius: 16px;
    border: none;
    outline: none;
    padding: 0;
  }
  & ul {
    width: 100%;
    background: #fff;
    margin: 0;
    padding: 8px 0 8px 12px;
    border-radius: 0 0 12px 12px;
    list-style: none;
    li {
      margin-left: -6px;
      margin-right: -6px;
      border-radius: 6px;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 16px;
      list-style: none;
      &:hover {
        background: #f2f1f4;
        cursor: pointer;
      }
    }
  }
  .ZERO_RESULTS {
    font-size: 12px;
    padding: 32px 0;
    color: ${Colors.darkGrey};
  }
  .close_btn {
    width: 18px;
    height: 16px;
    background: url(${close_btn});
    cursor: pointer;
  }
`;

const Styles = {
  SearchWrapper,
  BtnSearchWrapper,
  InputSearchWrapper,
};

export default Styles;
