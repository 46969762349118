import { FC, useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../../../mobx/stores';
import { TaskController } from '../../../../../../mobx/controllers';
import { useTaskActionControl, useTasksParams } from '../../../../../../hooks';
import { ETaskAction } from '../../../../../../../../../../api/models/as-fields/task/task.model';
import { FullscreenContext } from '../../../../../../containers/fullscreen';

import { useAssigneeSelect } from './hooks';

const AssigneeSelect: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const bodyForceCloseOptions = useContext(FullscreenContext);

  const params = useTasksParams();
  const controls = useTaskActionControl();

  const isMyOrganization = params.orgId === 'my';
  const isCreate = taskStore.isCreateOne || taskStore.isCreateMultiple;

  const hasPermissionToEdit = controls.hasPermission(ETaskAction.TaskEditCommonInfoAssignee);

  const hasPermissionToSelfAssignee = controls.hasPermission(
    ETaskAction.TaskEditCommonInfoSelfAssignee
  );

  const canEdit =
    isCreate || (taskStore.isEditMode && (hasPermissionToEdit || hasPermissionToSelfAssignee));

  const { defaultValue, optionList } = useAssigneeSelect();

  const isBlocked = !canEdit || isMyOrganization;

  const handleChange = useCallback((assigneeId: string): void => {
    taskController.changeAssignee(assigneeId ?? '');
  }, []);

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      onChange: handleChange,
      placeholder: isBlocked ? 'Не назначено' : 'Выберите исполнителя',
      defaultValue,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isAllowNewValueToBeAdded: false,
            isHideSearchFieldAutocomplete: true,
          },
        },
      },
    },
    body: {
      optionList,
    },
    visual: {
      label: 'Исполнитель',
      isBlocked,
    },
    validation: {
      error: {
        errorList: [],
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
    other: {
      dataTestId: 'task-assignee-select',
    },
  };

  return <NewDropdown bodyForceCloseOptions={bodyForceCloseOptions} config={dropdownConfig} />;
};

AssigneeSelect.displayName = 'AssigneeSelect';

export default observer(AssigneeSelect);
