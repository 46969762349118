import styled from 'styled-components';

const Plug = styled.div`
  width: 95px;
`;

const StyledTaskDaysLeft = {
  Plug,
};

export default  StyledTaskDaysLeft;
