import { FC, useState } from 'react';
import { AutoTooltip, ButtonLink, Ellipsis, Typography, useModal } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { StoFieldsTabStore } from '../../../../mobx/stores';
import { StoFieldsTabController } from '../../../../mobx/controllers';
import { TRANSFER_FIELD_MODAL_ID } from '../../../../modals';

import Styled from './FieldsTabListingUsedItem.styles';

const FieldsTabListingUsedItem: FC<{ fieldId: string; isBlocked?: boolean; isLast?: boolean }> = ({
  fieldId,
  isBlocked,
  isLast,
}) => {
  const store = useStore(StoFieldsTabStore);
  const controller = useStore(StoFieldsTabController);
  const [isTransferred, setIsTransferred] = useState(controller.hasFieldIdInTransfer(fieldId));

  const { openModalByModalId } = useModal();

  const fieldData = store.getCZusedInAnotherSto(fieldId);

  const value = Boolean(fieldData?.isSelected);

  const handleTransfer = () => {
    if (fieldData?.isAvailableForTransfer === false) {
      return;
    }

    if (isTransferred) {
      controller.cancelTransferField(fieldId);
      setIsTransferred(false);
    } else {
      openModalByModalId(TRANSFER_FIELD_MODAL_ID, { fieldName: fieldData?.clientFieldName }, () => {
        controller.transferField(fieldId);
        setIsTransferred(true);
      });
    }
  };

  const fieldName = `${fieldData.clientFieldName} (назначено: ${fieldData.selectedSTOName})`;

  if (!fieldData) {
    return null;
  }

  return (
    <Styled.Item
      key={fieldId}
      $isLast={isLast}
      data-test-id={`sto-used-field-${fieldId}`}
      data-field-selected={value}
    >
      <Styled.FieldRowWrapper isTransferred={isTransferred}>
        <Styled.FieldNameWrapper>
          <Typography dataTestId="sto-used-field-name" color="inherit">
            <Ellipsis>{fieldName}</Ellipsis>
          </Typography>
        </Styled.FieldNameWrapper>

        {fieldData.isAvailableForTransfer === false ? (
          <AutoTooltip
            disabled={false}
            content={`СТО ${fieldData.selectedSTOName} имеет неопубликованные изменения. Черновик должен быть опубликован или удален до трансфера`}
          >
            <div>
              <ButtonLink
                isDisabled
                onClick={handleTransfer}
                dataTestId="sto-used-field-transfer-button__notAvailableForTransfer"
                color={'secondary'}
              >
                Переназначить
              </ButtonLink>
            </div>
          </AutoTooltip>
        ) : (
          <ButtonLink
            isDisabled={isBlocked}
            onClick={handleTransfer}
            dataTestId="sto-used-field-transfer-button"
            color={isTransferred ? 'critical' : 'secondary'}
          >
            {isTransferred ? 'Отменить' : 'Переназначить'}
          </ButtonLink>
        )}
      </Styled.FieldRowWrapper>
    </Styled.Item>
  );
};

export default FieldsTabListingUsedItem;
