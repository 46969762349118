import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ButtonLink, ENotificationType, useModal, useNotificator } from '@farmlink/farmik-ui';
import { v4 } from 'uuid';

import Container from '../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/Container';
import { useStore } from '../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../mobx/stores';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { ScrollableContainer } from '../../../../../../../../../../../../../../../../../shared/containers';
import { StoAttributeCoreController as Controller } from '../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../../../hooks';
import getNotificationScheme, {
  ENotificationScheme,
} from '../../../../../../../../../../../../../../../../../shared/utils/helpers/getNotificationScheme/getNotificationScheme';
import { IStoChecklistsAttrsTableUserDictionaryValueUpdate as IUserDictionaryValueUpdate } from '../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto/StoChecklistsParamsTable/StoTable.model';
import { useWarningBeforeDeleting } from '../../../../../../../../../../../../../../../../../shared/hooks';
import { StoStore } from '../../../../../../../../../../../../mobx';
import { EContainerHeaderType } from '../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerHeader/constants';
import { EContainerTitleType } from '../../../../../../../../../../../../../../../../../shared/features/UI/new/Container/containers/ContainerTitle/constants';

import { StoAttributeUserDictionaryRegistryItem as Item } from './components';
import { StoAttributeAddUserDictionaryValueModal as AddUserDictionaryValueModal } from './modals';
import { IStoAttributeAddUserDictionaryValueModalPayload as AddUserDictionaryValueModalPayload } from './interfaces';
import { StoAttributeUserDictionaryRegistryNoDataPlug as NoDataPlug } from './containers';

const StoAttributeUserDictionaryRegistry: FC = () => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);

  const controller = useStore(Controller);

  const params = useStoParams();
  const modalActions = useModal();
  const notificatorActions = useNotificator();

  const deletingActions = useWarningBeforeDeleting();

  const maxHeight =
    store.userDictionaryItemList.length >= 10 ? 490 : store.userDictionaryItemList.length * 49;

  useEffect(() => {
    modalActions.registerModalList([AddUserDictionaryValueModal], AddUserDictionaryValueModal.id);
  }, []);

  const checkIfEverythingIsDisabled = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;

    return false;
  };

  const handleAddMoreButtonClick = () => {
    const modalPayload: AddUserDictionaryValueModalPayload = {
      name: store.attribute.attribute.name,
      defaultItemValue: '',
      addItemValue: value => {
        const newId = v4();

        controller.addNewUserDictionaryValue(params, {
          clientId: newId,
          id: newId,
          value,
        });

        notificatorActions.setNotification(
          getNotificationScheme(ENotificationScheme.Dark, 'Значение добавлено', {
            type: ENotificationType.Success,
          })
        );
      },
    };

    modalActions.openModalByModalId(AddUserDictionaryValueModal.id, modalPayload);
  };

  const handleSelectionToggling = (
    valueUpdate: IUserDictionaryValueUpdate,
    isSelected: boolean
  ): void => {
    controller.toggleSelectionUserDictionaryValue(params, valueUpdate, isSelected);
  };

  const handleItemRemoving = (valueUpdate: IUserDictionaryValueUpdate): void => {
    deletingActions.showWarningBeforeDeleting(
      'Вы уверены, что хотите удалить\n' + `${valueUpdate.value}?`,
      () => {
        controller.removeUserDictionaryValue(params, valueUpdate);

        notificatorActions.setNotification(
          getNotificationScheme(ENotificationScheme.Dark, 'Значение удалено', {
            type: ENotificationType.Warning,
          })
        );
      }
    );
  };

  const handleItemEditing = (valueUpdate: IUserDictionaryValueUpdate) => {
    const modalPayload: AddUserDictionaryValueModalPayload = {
      name: store.attribute.attribute.name,
      defaultItemValue: valueUpdate.value,
      isEditing: true,
      editItemValue: value => {
        controller.editUserDictionaryValue(params, {
          ...valueUpdate,
          value,
        });

        notificatorActions.setNotification(
          getNotificationScheme(ENotificationScheme.Dark, 'Название изменено', {
            type: ENotificationType.Success,
          })
        );
      },
    };

    modalActions.openModalByModalId(AddUserDictionaryValueModal.id, modalPayload);
  };

  const getDataTestId = useDataTestIdV2('sto__attribute__user-dictionary-registry');

  if (!store.userDictionaryItemList.length) {
    return (
      <NoDataPlug
        isDisabled={checkIfEverythingIsDisabled()}
        dataTestId={getDataTestId('no-data-plug')['data-test-id']}
        title={store.attribute?.attribute?.name}
        onActionButtonClick={handleAddMoreButtonClick}
      />
    );
  }

  return (
    <Container
      dataTestId={getDataTestId()['data-test-id']}
      containerStyles={{
        gap: '12px',
      }}
      header={{
        type: EContainerHeaderType.Default,
        title: {
          type: EContainerTitleType.Default,
          text: store.attribute.attribute.name,
        },
        additionalContent: (
          <ButtonLink
            dataTestId={getDataTestId('add-more-button')['data-test-id']}
            color={'accent'}
            onClick={handleAddMoreButtonClick}
            isDisabled={checkIfEverythingIsDisabled()}
          >
            + Добавить еще
          </ButtonLink>
        ),
      }}
    >
      <ScrollableContainer
        maxHeight={`${maxHeight}px`}
        isDisabled={store.userDictionaryItemList.length <= 10}
        dataTestId={getDataTestId('list')['data-test-id']}
      >
        {store.userDictionaryItemList.map((item, i) => (
          <Item
            key={item.id}
            dataTestId={getDataTestId('item')['data-test-id']}
            isLast={i + 1 === store.userDictionaryItemList.length}
            isDisabled={checkIfEverythingIsDisabled()}
            toggleSelection={handleSelectionToggling}
            removeItem={handleItemRemoving}
            editItem={handleItemEditing}
            removeItemHighlight={controller.removeUserDictionaryHighlight}
            {...item}
          />
        ))}
      </ScrollableContainer>
    </Container>
  );
};

StoAttributeUserDictionaryRegistry.displayName = 'StoAttributeCommonData';

export default observer(StoAttributeUserDictionaryRegistry);
