import styled, { css } from 'styled-components';

const Header = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid #e7f1fd;
`;

const Dependencies = styled.div<{ $quantity: number }>`
  display: grid;
  grid-gap: 12px;

  ${({ $quantity }) => {
    switch ($quantity) {
      case 2:
        return css`
          grid-template-columns: 1fr 1fr;
        `;

      default:
        return css`
          grid-template-columns: 1fr;
        `;
    }
  }}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 20px 16px 24px 16px;

  border-radius: 16px;

  background-color: #f9fcff;
`;

const StyledStoAttributeOdzDependency = {
  Header,
  Dependencies,
  Body,
  Wrapper,
};

export default StyledStoAttributeOdzDependency;
