import { EModalSize, EModalType, TModalConfig } from '@farmlink/farmik-ui';

export const TASK_DELETION_MODAL = 'TASK_DELETION_MODAL';

const deletionModalConfig: TModalConfig = {
  name: TASK_DELETION_MODAL,
  id: TASK_DELETION_MODAL,
  dataTestId: 'deletionModal',
  title: 'Удалить регистрацию?',
  type: EModalType.Warning,
  description: {
    value: 'Удаленные данные невозможно будет восстановить,',
    $styles: { marginBottom: '32px' },
  },
  styledProps: {
    $size: EModalSize.Large,
  },
  denyButton: {
    title: 'Отменить',
  },
  successButton: {
    title: 'Удалить',
    color: 'secondary',
  },
};

export default deletionModalConfig;
