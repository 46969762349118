import { EImportedFieldErrorType } from '../../../../../../../../../api/models/fields/getImportedField.model';
import { EImportedFieldErrorName } from '../../constants';

const getErrorNameByType = (type: EImportedFieldErrorType): EImportedFieldErrorName | string => {
  switch (type) {
    case EImportedFieldErrorType.InvalidGeometry:
      return EImportedFieldErrorName.InvalidGeometry;
    case EImportedFieldErrorType.Intersection:
      return EImportedFieldErrorName.Intersection;
    case EImportedFieldErrorType.SmallArea:
      return EImportedFieldErrorName.SmallArea;
    case EImportedFieldErrorType.LargeArea:
      return EImportedFieldErrorName.LargeArea;
    case EImportedFieldErrorType.ParseError:
      return EImportedFieldErrorName.ParseError;
    case EImportedFieldErrorType.SelfIntersection:
      return EImportedFieldErrorName.SelfIntersection;
    default:
      return '';
  }
};

export default getErrorNameByType;
