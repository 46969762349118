import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoOperationCoreController as StoOperationController } from '../../../../../../mobx/controllers';
import { useStoNavigation, useStoParams } from '../../../../../../../../../../../../hooks';
import Styled from '../../../../../../../../../../containers/StoContainer/StoContainer.styles';
import { Fullscreen } from '../../../../../../../../../../../../../../../shared/containers';
import { useWarningBeforeLeavingThePage } from '../../../../../../../../../../../../../../../shared/hooks';
import { ContentLoader } from '../../../../../../../../../../../../../../../shared/features/UI/loaders/ContentLoader';
import { Container } from '../../../../../../../../../../../../../../../shared/features/UI/new/Container';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';

import { StoAttributeCoreController as Controller } from './mobx/controllers';
import { StoAttributeCoreStore as Store } from './mobx/stores';
import { StoAttributeContent as Content } from './containers';

const StoAttribute: FC = () => {
  const store = useStore(Store);

  const controller = useStore(Controller);
  const operationController = useStore(StoOperationController);

  const params = useStoParams();
  const navigations = useStoNavigation();

  useWarningBeforeLeavingThePage(store.hasChanges, [], null, () => {
    controller.undoTheChanges(params);
  });

  useEffect(() => {
    controller.initiatePage(params);

    return () => {
      controller.clearStore();
    };
  }, []);

  useEffect(() => {
    if (!store.isSaved) return;

    navigations.navigateToChecklist(params, {
      query: { ...(params.versionId && { versionId: params.versionId }) },
    });
  }, [store.isSaved]);

  const handleBackButtonClick = (): void => {
    navigations.navigateToChecklist(params, {
      query: { ...(params.versionId && { versionId: params.versionId }) },
    });
  };

  const getDataTestId = useDataTestIdV2('sto__attribute__loader');

  return (
    <Fullscreen
      backButtonText={
        <Styled.TitlePaddingWrapper>
          <Typography className="header-title" variant="h4">
            Настройка параметра
          </Typography>
        </Styled.TitlePaddingWrapper>
      }
      arrowText={'Вернуться'}
      underTitleContent={
        <Styled.UnderTitleTypography data-test-id="sto-title-subtitle">
          {operationController.getHeaderSubtitle()}
        </Styled.UnderTitleTypography>
      }
      backButtonHandler={handleBackButtonClick}
      contentStyle={{
        $paddings: '4px 0 32px',
      }}
    >
      {store.isLoading ? (
        <Container dataTestId={getDataTestId()['data-test-id']}>
          <ContentLoader
            dataTestId={getDataTestId('loader')['data-test-id']}
            overlayStyles={{ height: '400px', width: '100%', position: 'relative' }}
          />
        </Container>
      ) : (
        <Content />
      )}
    </Fullscreen>
  );
};

StoAttribute.displayName = 'StoAttribute';

export default observer(StoAttribute);
