import { FC, useMemo } from 'react';

import { IChecklistFileItem } from '../../../../../../../operationsAndTasks/stores/checklist.fileUploader.store';

import { MiniImg, MiniImgWrapper, OutContainerPlaceholder, StyledDeleteIcon } from './style';

interface IImageMiniRowProps {
  imgsArr: IChecklistFileItem[];
  isBlocked: boolean;
  onDeleteByIndex: (number) => () => void;
  setSelectedImgIndex: (number) => void;
  parentElem?: null | Element;
  maxInRow?: number;
  isPaperClipButtonShow?: boolean;
}

export const ImageMiniRow: FC<IImageMiniRowProps> = ({
  imgsArr,
  isBlocked,
  onDeleteByIndex,
  setSelectedImgIndex,
  maxInRow,
  isPaperClipButtonShow
}) => {
  const formattedMaxInRow = useMemo(() => (isBlocked ? maxInRow : maxInRow - 1), [
    maxInRow,
    isBlocked,
  ]);

  const renderedImages = useMemo(() => {
    const result = [];

    for (let i = 0; i < imgsArr.length; i++) {
      if (i < formattedMaxInRow - 1) {
        const rowImageItem = (
          <MiniImgWrapper key={imgsArr[i].id}>
            {!isBlocked && <StyledDeleteIcon onClick={onDeleteByIndex(i)} />}
            <MiniImg
              src={imgsArr[i]?.downloadUrl ? imgsArr[i]?.downloadUrl : imgsArr[i]?.imagePreview}
              onClick={() => setSelectedImgIndex(i)}
            />
          </MiniImgWrapper>
        );

        result.push(rowImageItem);
      } else if (i === formattedMaxInRow - 1) {
        const rowImagePlaceholder = (
          <OutContainerPlaceholder key={i} onClick={() => setSelectedImgIndex(i)}>
            +{imgsArr.length - formattedMaxInRow + 1}
          </OutContainerPlaceholder>
        );

        if (!isBlocked && isPaperClipButtonShow) {
          result.pop();
        }

        result.push(rowImagePlaceholder);
      }
    }

    return result;
  }, [
    imgsArr,
    formattedMaxInRow,
    isBlocked,
    onDeleteByIndex,
    setSelectedImgIndex,
    isPaperClipButtonShow,
  ]);

  return <>{renderedImages}</>;
};
