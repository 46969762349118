import React, { FC, memo } from 'react';
import { Switcher, Typography } from '@farmlink/farmik-ui';

import { IStoAttributeUserDictionaryRegistryItem as UserDictionaryRegistryItem } from '../../interfaces';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { IStoChecklistsAttrsTableUserDictionaryValueUpdate as IUserDictionaryValueUpdate } from '../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto/StoChecklistsParamsTable/StoTable.model';

import Styled from './StoAttributeUserDictionaryRegistryItem.styled';
import {
  StoAttributeUserDictionaryRegistryItemRemoveIcon as RemoveIcon,
  StoAttributeUserDictionaryRegistryItemEditIcon as EditIcon,
} from './components';

type TProps = {
  dataTestId: string;
  isLast: boolean;
  isDisabled: boolean;
  toggleSelection: (value: IUserDictionaryValueUpdate, isSelected: boolean) => void;
  editItem: (value: IUserDictionaryValueUpdate) => void;
  removeItem: (value: IUserDictionaryValueUpdate) => void;
  removeItemHighlight: (itemId: string) => void;
} & UserDictionaryRegistryItem;

const StoAttributeUserDictionaryRegistryItem: FC<TProps> = ({
  dataTestId,
  isLast,
  isDisabled,
  id,
  value,
  isSelected,
  isHighlighted,
  isNew,
  toggleSelection,
  editItem,
  removeItem,
  removeItemHighlight,
}) => {
  const handleSwitcherChange = (switcherValue: boolean): void => {
    toggleSelection({ id, value }, switcherValue);
  };

  const handleEditClick = (): void => {
    editItem({ id, value });
  };

  const handleRemoveClick = (): void => {
    removeItem({ id, value });
  };

  const handleMouseOver = (): void => {
    if (isHighlighted) {
      removeItemHighlight(id);
    }
  };

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      $isLast={isLast}
      $isHighlighted={isHighlighted}
      onMouseOver={handleMouseOver}
    >
      <Switcher
        dataTestId={getDataTestId('switcher')['data-test-id']}
        value={isSelected}
        onChange={handleSwitcherChange}
        isBlocked={isDisabled || isNew}
      />

      <Typography
        dataTestId={getDataTestId('value')['data-test-id']}
        color={isSelected ? 'generalDark' : 'secondaryDark'}
        variant={'title'}
      >
        {value}
      </Typography>

      {isNew ? (
        <Styled.Actions {...getDataTestId('actions')}>
          <EditIcon dataTestId={getDataTestId('edit')['data-test-id']} onClick={handleEditClick} />

          <RemoveIcon
            dataTestId={getDataTestId('remove')['data-test-id']}
            onClick={handleRemoveClick}
          />
        </Styled.Actions>
      ) : null}
    </Styled.Wrapper>
  );
};

StoAttributeUserDictionaryRegistryItem.displayName = 'StoAttributeUserDictionaryRegistryItem';

export default memo(StoAttributeUserDictionaryRegistryItem);
