import { runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { IMapPolygonElement, IMapPolygonElementConfig, IMapSetListOptions } from '../../../models';
import { IMapPolygonNewElementConfig } from '../../../models/PolygonElementConfig/PolygonElementConfig.model';
import baseTooltipOptions from '../../../utils/constants/tooltip-options.contant';
import MapPolygonService from '../services/MapPolygon.service';
import MapPolygonEventsService from '../services/MapPolygonEvents.service';
import MapPolygonStore from '../stores/MapPolygon.store';

type TPolygonServiceArgs<K extends keyof MapPolygonService> = Parameters<MapPolygonService[K]>;

@provide.transient()
class MapPolygonController {
  @lazyInject(MapPolygonStore)
  private store: MapPolygonStore;

  @lazyInject(MapPolygonService)
  private service: MapPolygonService;

  @lazyInject(MapPolygonEventsService)
  private eventsService: MapPolygonEventsService;

  // Данный метод необходим для обратной совместимости с конфигами
  public display<E>(config: IMapPolygonElementConfig<E>): IMapPolygonElement<E> | null {
    const { element, geometry, options } = config;

    const polygon = this.service.create(geometry, options?.style);
    if (!polygon) {
      return null;
    }

    if (options?.isAllowToSelectPolygon) {
      this.eventsService.registerClickEvent(polygon);
    }

    if (options?.tooltip) {
      polygon.bindTooltip(options.tooltip.content, {
        ...baseTooltipOptions,
        ...options.tooltip.options,
      });
    }

    return { element, polygon };
  }

  public displayNew<E>(config: IMapPolygonNewElementConfig<E>): IMapPolygonElement<E> | null {
    const { element, options = {} } = config;

    const polygon = this.service.createNew(config.polygon);

    if (!polygon) {
      return { element, polygon: null };
    }

    if (options?.isAllowToSelectPolygon) {
      this.eventsService.registerClickEvent(polygon);
    }

    return { element, polygon };
  }

  public displayMany<E>(
    configList: IMapPolygonElementConfig<E>[],
    setOptions?: IMapSetListOptions
  ) {
    if (setOptions?.isClearPreviousList) {
      this.service.removeManyByIds([], { isRemoveAll: true });
    }

    let res: IMapPolygonElement<E>[];

    runInAction(() => {
      res = configList.map(config => this.display(config));
    });

    return res.filter(Boolean);
  }

  public async displayManyAsync<E>(
    configList: IMapPolygonElementConfig<E>[],
    setOptions?: IMapSetListOptions
  ) {
    if (setOptions?.isClearPreviousList) {
      this.service.removeManyByIds([], { isRemoveAll: true });
    }

    let result: Promise<IMapPolygonElement<E>>[];

    runInAction(() => {
      result = configList.map(config => {
        return new Promise(res => {
          setTimeout(() => {
            const polygonElement = this.display(config);
            res(polygonElement);
          }, 0);
        });
      });
    });

    const polygonElements = await Promise.all(result);

    return polygonElements.filter(Boolean);
  }

  public remove(...args: TPolygonServiceArgs<'removeById'>) {
    this.service.removeById(...args);
  }

  public removeMany(...args: TPolygonServiceArgs<'removeManyByIds'>) {
    this.service.removeManyByIds(...args);
  }

  public select(...args: TPolygonServiceArgs<'select'>) {
    this.service.select(...args);
  }

  public deselect(...args: TPolygonServiceArgs<'deselectSelected'>) {
    this.service.deselectSelected(...args);
  }
}

export default MapPolygonController;
