import { FC } from 'react';

import Styled from './DrawPanel.styles';

const DrawPanel: FC = () => {
  return (
    <Styled.Wrapper>
      <div>1</div>
      <div>2</div>
      <div>3</div>
    </Styled.Wrapper>
  );
};

export default DrawPanel;
