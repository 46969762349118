import { useStore } from '../../../../utils/IoC';
import {
  MapPolygonController,
  MapMarkerController,
  MapLayerGroupController,
  MapCoreController,
} from '../../modules';

const useMapActions = () => {
  const mapCoreController = useStore(MapCoreController);
  const mapLayerGroupController = useStore(MapLayerGroupController);
  const mapPolygonController = useStore(MapPolygonController);
  const mapMarkerController = useStore(MapMarkerController);

  return {
    // Функции для работы с инстансом карты
    mapActions: mapCoreController,
    // Функции для работы с группами слоев
    layerGroupActions: mapLayerGroupController,
    // Функции для работы с полигонами
    polygonActions: mapPolygonController,
    // Функции для работы с маркерами
    markerActions: mapMarkerController,
  };
};

export default useMapActions;
