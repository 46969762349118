import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';
import { List, ListRowProps } from 'react-virtualized';

import { Field } from '../../../../../../../../api/models/field.model';
import { useVirtualizedListScroll } from '../../../../../../../shared/hooks';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { ListWrapper } from '../../../../components';
import { FieldsImportController as Controller, FieldsImportStore as Store } from '../../mobx';

import ErrorsLabel from './components/ErrorsLabel/ErrorsLabel';
import FieldsImportListFooter from './components/Footer/Footer';
import FieldsImportListItem from './components/ListItem/ListItem';

interface IProps {
  list: Field[];
}

const FieldsImportList: FC<IProps> = ({ list }) => {
  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const store = useStore(Store);

  const scroll = useVirtualizedListScroll(list);

  const debounceChangeFieldName = useCallback(
    debounce((field: Field) => {
      void controller.changeImportedField({
        id: field.id,
        name: field.name,
        geoJson: {
          type: 'Feature',
          geometry: field.geometry,
        },
      });
    }, 500),
    []
  );

  const isSelectedField = useCallback(
    (field: Field) => {
      return store.editableField?.id === field.id;
    },
    [store.editableField?.id]
  );

  const handleSelectField = (field: Field) => {
    controller.selectField(field);
  };

  const handleDelete = (field: Field) => {
    return controller.deleteImportedField(field);
  };

  const handleInputChange = (field: Field, value: string) => {
    if (field.name === value) {
      return;
    }

    const updatedField = controller.changeFieldName(field, value);

    debounceChangeFieldName(updatedField);
  };

  const rowRender = ({ index, key, style }: ListRowProps) => {
    const field = list[index];

    return (
      <div style={style} key={key}>
        <FieldsImportListItem
          field={field}
          isSelected={isSelectedField(field)}
          onClick={() => handleSelectField(field)}
          onInputChange={value => handleInputChange(field, value)}
          onDelete={() => handleDelete(field)}
        />
      </div>
    );
  };

  useEffect(() => {
    scroll.to({ id: store.editableField?.id });
  }, [store.editableField?.id]);

  return (
    <ListWrapper
      title="Добавление полей"
      footer={<FieldsImportListFooter />}
      dataTestId="fields-import-section"
    >
      <ErrorsLabel list={list} />

      <List
        rowCount={list.length}
        width={284}
        height={window?.innerHeight - 203}
        rowHeight={92}
        row
        rowRenderer={rowRender}
        scrollToIndex={scroll.activeIndex}
        scrollToAlignment="center"
      />
    </ListWrapper>
  );
};

export default observer(FieldsImportList);
