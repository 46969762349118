import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@farmlink/farmik-ui';

import { ScrollableContainer } from '../../../../../../../../../../../shared/containers';
import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { StoStore } from '../../../../../../mobx';
import { EStoDictionaryName as EDictionaryName } from '../../../../../../../../../../../../api/models/as-fields/new/sto';
import { StoRegistriesController as Controller } from '../../../../mobx/controllers';

import { StoRegistryListItem as Item } from './components';

interface IProps {
  dataTestId: string;
  dictionaryName: EDictionaryName;
}

const StoRegistryList: FC<IProps> = ({ dataTestId, dictionaryName }) => {
  const stoStore = useStore(StoStore);
  const controller = useStore(Controller);

  const entityList = controller.getEntityList(dictionaryName);
  const entityToDisplayList = controller.getEntityListToDisplay(dictionaryName);

  const maxHeight = entityToDisplayList.length >= 10 ? 490 : entityToDisplayList.length * 49;
  const isDisplayNoFieldsPlaceholder = entityToDisplayList.length === 0 && entityList.length > 0;

  const handleSelect = useCallback((id: string, value: boolean): void => {
    if (value) controller.selectEntity(dictionaryName, id);
    else controller.deselectEntity(dictionaryName, id);
  }, []);

  const handleRemoveHighlight = useCallback((id: string): void => {
    controller.removeEntityHighlight(dictionaryName, id);
  }, []);

  const getDataTestId = useDataTestIdV2(dataTestId);

  if (isDisplayNoFieldsPlaceholder) {
    return (
      <Typography
        dataTestId={getDataTestId('no-data-text')['data-test-id']}
        variant="bodySubAccent"
      >
        Ничего не найдено по вашему запросу
      </Typography>
    );
  }

  return (
    <ScrollableContainer
      maxHeight={`${maxHeight}px`}
      isDisabled={entityToDisplayList.length <= 10}
      dataTestId={getDataTestId()['data-test-id']}
    >
      {entityToDisplayList.map((entity, i) => (
        <Item
          key={entity.id}
          dataTestId={getDataTestId('item')['data-test-id']}
          onSelect={handleSelect}
          isViewOnly={stoStore.isViewOnly}
          isLast={i === entityToDisplayList.length - 1}
          removeHighlight={handleRemoveHighlight}
          {...entity}
          isSelected={controller.isEntitySelected(dictionaryName, entity.id)}
        />
      ))}
    </ScrollableContainer>
  );
};

StoRegistryList.displayName = 'StoRegistryList';

export default observer(StoRegistryList);
