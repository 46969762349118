import { TypeApiRoute } from '../../../models/type.api.request';
import { IDictionaryEntity } from '../../../models/da-dictionary/DictionaryEntity';

export interface IGetDictionaryEntityListPayload {
  idIn?: string[];
  parentId?: string;
  nameFilter?: string;
  codeFilter?: string;
  descriptionFilter?: string;
  pathEquals?: string;
  pathPrefix?: string;
  originalOnly?: boolean;
  latestVersion?: boolean;
  level?: number;
  attrs?: {
    [key: string]: any;
  };
  status?: string;
  fetchAttributes?: boolean;
  remoteName?: string;
  dependencyName?: string;
  dependencyRecordId?: string;
  dependencyRecords?: IDependencyRecordForRequest[];
  hasChild?: boolean;
  sort?: string;
  size?: number;
}

export interface IDependencyRecordForRequest {
  dependencyName: string;
  dependencyRecordId: string;
  directDependencies?: boolean;
}

type TResponse = { content: IDictionaryEntity[] };

export const getDictionaryEntityList: TypeApiRoute & {
  request: IGetDictionaryEntityListPayload;
  response: TResponse;
} = {
  url: ({ remoteName }) => `/api/da-dictionary/dictionary/${remoteName}/findAll`,
  method: 'POST',
  request: {} as IGetDictionaryEntityListPayload,
  response: {} as TResponse,
  headers: {},
};
