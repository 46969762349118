import { Colors, Icon, IconButton } from '@farmlink/farmik-ui';
import { FC, MouseEvent } from 'react';
import styled from 'styled-components';

const SearchWrapper = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
`;

const SwitcherGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SwitcherLabel = styled.label`
  padding-left: 10px;
  ${({ theme }) => theme.Typography.schemas.bodySubAccent}
  cursor: pointer;
  color: ${Colors.secondaryDarkGray};
`;

const Delimiter = styled.span`
  ${({ theme }) => theme.Typography.schemas.bodySubAccent}
  color: ${Colors.secondaryDarkGray};
  padding: 0 12px;
`;

const FieldCounter = styled.span`
  ${({ theme }) => theme.Typography.schemas.bodySubAccent}
  color: ${Colors.secondaryDarkGray};
`;

const SearchIcon: FC<{
  isSearchActive: boolean;
  onClick: (event?: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
  isDisabled?: boolean;
}> = ({ isSearchActive, onClick, isDisabled }) => (
  <IconButton
    icon={isSearchActive ? 'close' : 'search'}
    size={40}
    $padding="12px"
    $isDisabled={isDisabled}
    colorScheme={{
      default: {
        background: 'transparent',
        icon: isSearchActive ? Colors.black : Colors.secondaryDarkGray,
      },
      hover: {
        background: 'transparent',
        icon: isSearchActive ? Colors.black : Colors.accentGreen,
      },
      disabled: {
        background: 'transparent',
        icon: Colors.secondaryDarkGray,
      },
    }}
    type="button"
    onClick={onClick}
  />
);

const SearchField = styled.input<{ $isDisplay: boolean; $isDisplaySearchIconInInput: boolean }>`
  position: absolute;
  width: calc(100% - 48px);
  height: 40px;
  display: flex;
  padding: 10px 12px 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid ${Colors.borderGray};
  outline: none;
  background: ${Colors.secondaryGray};
  display: ${({ $isDisplay }) => ($isDisplay ? 'block' : 'none')};
  text-indent: ${({ $isDisplaySearchIconInInput }) => ($isDisplaySearchIconInInput ? '28px' : '0')};

  ::placeholder {
    color: ${Colors.secondaryDarkGray};
  }
`;

const SearchInputIcon = styled.div<{ $isDisplay?: boolean }>`
  display: ${({ $isDisplay }) => ($isDisplay ? 'block' : 'none')};
  position: absolute;
  left: 16px;
  z-index: 1;
`;

const ClearInputIcon = styled.div<{ $isDisplay?: boolean }>`
  display: ${({ $isDisplay }) => ($isDisplay ? 'block' : 'none')};
  position: absolute;
  right: 62px;
  z-index: 1;
`;

const Styled = {
  SearchWrapper,
  SwitcherGroup,
  SwitcherLabel,
  Delimiter,
  FieldCounter,
  SearchIcon,
  SearchField,
  SearchInputIcon,
  ClearInputIcon,
};

export default Styled;
