import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import {
  EChecklistAttributeType as EAttrType,
  IGetChecklistAttribute as IAttribute,
  IGetChecklistAttributeValue as IAttributeValue,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { TChecklistsLongStingAttrToDraw as TLongStringAttrToDraw } from '../../../../models';
import { ChecklistsStore } from '../../../stores';
import { ChecklistsAttrsFormulasService } from '../../ChecklistsAttrsFormulasService';
import { EChecklistMode } from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { getChecklistsAttrRequiredText as getAttrRequiredText } from '../../../../helpers';

@provide.transient()
class ChecklistsLongStringAttrsService {
  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistsAttrsFormulasService)
  protected formulasService: ChecklistsAttrsFormulasService;

  createAttrToDraw = (
    groupId: string,
    attr: IAttribute,
    initialValueList: IAttributeValue[],
    options?: {
      stageId: string;
      isBlocked?: boolean;
      nestedInstanceId?: string;
      dependentFileAttrId?: string;
    }
  ): TLongStringAttrToDraw => {
    const attrToDraw: TLongStringAttrToDraw = {
      id: attr.id,
      groupId,
      stageId: options.stageId,
      isVisible: true,
      order: attr.order,
      initialModel: attr,
      value: {
        checkListAttributeId: attr.id,
        longStringValue: '',
      },
      options: null,
      isBlocked: options?.isBlocked || this.checklistsStore.mode === EChecklistMode.View,
      validationScheme: {
        isShowError: false,
        errorTitle: getAttrRequiredText(EAttrType.LongString),
      },
      isEdited: false,
      nestedInstanceId: options?.nestedInstanceId,
      dependentFileAttrId: options?.dependentFileAttrId,
    };

    const initialValueOfThisAttr = initialValueList.find(
      ({ checkListAttributeId }) => checkListAttributeId === attrToDraw.id
    );

    if (initialValueOfThisAttr) {
      attrToDraw.value = {
        ...attrToDraw.value,
        longStringValue: initialValueOfThisAttr.longStringValue,
      };
    }

    return attrToDraw;
  };

  changeValue = (groupId: string, value: TLongStringAttrToDraw['value']): void => {
    this.checklistsStore.updateAttrToDraw(groupId, value.checkListAttributeId, {
      value,
      isEdited: true,
    });

    this.checklistsStore.updateAttrToDrawValidationScheme<EAttrType.LongString>(
      groupId,
      value.checkListAttributeId,
      {
        isShowError: false,
      }
    );
  };
}

export default ChecklistsLongStringAttrsService;
