import { useModal, useSidebar } from '@farmlink/farmik-ui';
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import {
  CulturesLegendMapControl,
  EMapControlPlace,
  GoogleSearchMapControl,
  LayerFillMapControl,
  LayerTooltipMapControl,
  LocationMapControl,
  ZoomMapControl,
} from '../../../../../shared/features/map/components/Controls';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { EFieldFill } from '../../../../constants/FieldFill.enum';
import { useStore } from '../../../../../shared/utils/IoC';
import FieldsLayout from '../../FieldsLayout';

import Controller from './mobx/controllers/FieldsMultiselect.controller';
import LeftPanel from './components/LeftPanel/LeftPanel';
import {
  FIELDS_MULTISELECT_MODALS_UNIQUE_KEY,
  fieldsMultiselectModalConfigList,
} from './modals/config';

const FieldsMultiselectPage: FC = () => {
  // Сторы
  const fieldsStore = useStore(FieldsStore);

  // Контроллеры
  const controller = useStore(Controller);

  const modal = useModal();
  const sidebar = useSidebar();

  useEffect(() => {
    sidebar.setSidebarVisibility(false);

    void controller.initialize();

    modal.registerModalList(fieldsMultiselectModalConfigList, FIELDS_MULTISELECT_MODALS_UNIQUE_KEY);

    return () => {
      controller.destroy();
      sidebar.setSidebarVisibility(true);
    };
  }, []);

  return (
    <FieldsLayout
      id={'fields-multiselect-map'}
      leftPanel={<LeftPanel />}
      map={{
        config: {
          controls: [
            { id: 'search', place: EMapControlPlace.TOP, component: <GoogleSearchMapControl /> },
            {
              id: 'tooltip',
              place: EMapControlPlace.TOP,
              component: <LayerTooltipMapControl allFields={fieldsStore.fieldsList} />,
            },
            {
              id: 'fill',
              place: EMapControlPlace.TOP,
              component: <LayerFillMapControl skipOptions={[EFieldFill.Ndvi, EFieldFill.Msavi]} />,
            },
            {
              id: 'culturesLegend',
              place: EMapControlPlace.TOP,
              component: <CulturesLegendMapControl />,
              hidden: true,
            },

            { id: 'zoom', place: EMapControlPlace.RIGHT, component: <ZoomMapControl /> },
            { id: 'location', place: EMapControlPlace.RIGHT, component: <LocationMapControl /> },
          ],
        },
      }}
    />
  );
};

export default observer(FieldsMultiselectPage);
