import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import { Field } from '../../../../../../../../../../../../../../api/models/field.model';

import Styled from './ResultCounter.styles';

interface IProps {
  filteredList: Field[];
}

const ResultCounter: FC<IProps> = ({ filteredList }) => {
  const stats = useMemo(() => {
    if (!filteredList.length) {
      return { fieldsCount: 0, totalArea: 0 };
    }

    return {
      fieldsCount: filteredList.length,
      totalArea: filteredList.reduce((acc, curr) => acc + curr.area, 0),
    };
  }, [filteredList]);

  return (
    <Styled.Wrapper>
      <span>Найдено полей: {stats.fieldsCount}</span>
      <span>S: {stats.totalArea.toFixed(0)} га</span>
    </Styled.Wrapper>
  );
};

export default observer(ResultCounter);
