import { Typography } from '@farmlink/farmik-ui';

import Styled from './NoFieldsPlaceholder.styles';
const NoFieldsPlaceholder = () => {
  return (
    <Styled.Wrapper>
      <Typography variant="bodySubAccent">Ничего не найдено по вашему запросу</Typography>
    </Styled.Wrapper>
  );
};

export default NoFieldsPlaceholder;
