import { EIconButtonSize, IconButton } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import SearchInput from '../../../../../../../../../../../shared/components/SearchInput/SearchInput';
import SharedStyled from '../../../../../../../../components/shared/styles/Shared.styles';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { WeatherController } from '../../../../../WeatherSlider/mobx';
import { FieldsViewStore as Store } from '../../../../../../mobx';

import Styled from './SearchInput.styles';

interface IProps {
  onBackClick: () => void;
}

const HeaderSearchInput: FC<IProps> = props => {
  // Сторы
  const store = useStore(Store);

  // Контроллеры
  const weatherController = useStore(WeatherController);

  const getDataTestId = useDataTestIdV2('fields-view-header-search');

  const searchFilter = store.filterManager.filters.getOne('search');

  const handleSearchChange = (value: string, listLength: number) => {
    // Если есть результаты поиска то перестраиваем список
    if (value && listLength) {
      searchFilter.setValue(value);
    }

    if (!value) {
      searchFilter.setValue('');
    }
  };

  const handleSelectValue = () => {
    weatherController.close();
  };

  const handleBackClick = () => {
    searchFilter.resetValue();
    props.onBackClick();
  };

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <IconButton
        icon={'arrow_left'}
        size={EIconButtonSize.x12}
        colorScheme={SharedStyled.hoverIconColorScheme}
        onClick={handleBackClick}
        dataTestId={getDataTestId('back-icon')['data-test-id']}
      />

      <SearchInput
        placeholder={'Название поля'}
        optionsList={searchFilter?.optionList ?? []}
        debounceMs={600}
        onChange={handleSearchChange}
        onSelectValue={handleSelectValue}
      />
    </Styled.Wrapper>
  );
};

export default observer(HeaderSearchInput);
