import { observer } from 'mobx-react';
import { FC, useEffect, useMemo } from 'react';

import { useTaskEditBackButtonData, useTasksParams, useTasksRouteActions } from '../../hooks';
import TaskFullscreen from '../../components/TaskFullscreen/TaskFullscreen';
import { useStore } from '../../../../../shared/utils/IoC';
import { TaskStore } from '../../mobx/stores';
import { IFullscreenProps } from '../../containers/fullscreen';

const TaskEditPointPage: FC = () => {
  const params = useTasksParams();

  const taskStore = useStore(TaskStore);

  const { goToMapCreatePoint } = useTasksRouteActions();
  const backButtonData = useTaskEditBackButtonData();

  useEffect(() => {
    taskStore.setMode('edit-point');
  }, []);

  const taskFullScreenConfig = useMemo<IFullscreenProps>(
    () => ({
      backButtonHandler: () => goToMapCreatePoint(params.taskId, true),
      backButtonText: backButtonData.backButtonText,
      arrowText: 'Вернуться назад',
      mode: 'edit',
    }),
    [params.taskId, backButtonData.backButtonText]
  );

  return <TaskFullscreen fullscreenProps={taskFullScreenConfig} mode={'edit-point'} />;
};

TaskEditPointPage.displayName = 'TaskEditPointPage';

export default observer(TaskEditPointPage);
