import { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { SpinnerLoader } from '../../../shared/components/loaders';

import useFieldsPermissions from './hooks/useFieldsPermissions';

const FieldsRouter: FC = () => {
  // Навигация
  const { orgId } = useParams();

  const permissions = useFieldsPermissions();

  useEffect(() => {
    permissions.update(orgId);
  }, [orgId]);

  if (permissions.isLoading) {
    return <SpinnerLoader />;
  }

  return <Outlet />;
};

export default FieldsRouter;
