import styled from 'styled-components';
import { Colors } from '@farmlink/farmik-ui';

const Body = styled.div`
  margin: 24px 0;
`;

const Header = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${Colors.technicalBorderGrey};
`;

const TitleWrapper = styled.div`
  margin-bottom: 12px;
`;

const Wrapper = styled.div`
  width: 360px;

  display: flex;
  flex-direction: column;
`;

const StyledStoAttributeAddUserDictionaryValueModal = {
  Body,
  Header,
  TitleWrapper,
  Wrapper,
};

export default StyledStoAttributeAddUserDictionaryValueModal;
