import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import moment from 'moment';
import { NewDropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../../../mobx/stores';
import { TaskController } from '../../../../../../mobx/controllers';
import { useTasksParams } from '../../../../../../hooks';
import { checkIfDateIsInTheRange } from '../../../../../../../../../shared/utils/helpers/checkIfDateIsInTheRange';
import { FullscreenContext } from '../../../../../../containers/fullscreen';

const OperationSelect: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const bodyForceCloseOptions = useContext(FullscreenContext);

  const params = useTasksParams();

  const isCreate = taskStore.isCreateMultiple || taskStore.isCreateOne;

  const hasEmptyCultureId = isCreate ? !taskStore.taskCreate?.cultureId : false;

  const isBlocked = !isCreate || taskStore.isEditMode || hasEmptyCultureId;

  useEffect(() => {
    (async () => {
      if (isCreate && taskStore.taskCreate?.operationId) {
        await taskController.createListOfZoneExtendedByFieldAfterOperationChange(params.orgId);
      }
    })();
  }, [taskStore.taskCreate?.operationId, isCreate]);

  const optionList = useMemo(() => {
    const bindingDate = taskStore.taskCreate?.bindingDate;

    if (bindingDate) {
      return taskStore.operationOptionList.filter(({ initialModel }) => {
        return checkIfDateIsInTheRange(bindingDate, initialModel.startDate, initialModel.endDate);
      });
    } else {
      return taskStore.operationOptionList;
    }
  }, [taskStore.operationOptionList, taskStore.taskCreate?.bindingDate]);

  const handleChange = useCallback((operationId: string) => {
    taskController.changeOperation(operationId ?? '');
  }, []);

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      onChange: handleChange,
      placeholder: 'Выберите операцию',
      /**
       * Костыль. В связи с особенностью работы компонента, нужно передавать массив в качестве
       * дефолтного значения. В противном случае не будет сбрасываться выбранное значение.
       */
      defaultValueList: taskStore.operationDefaultValue,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
          },
        },
      },
    },
    body: {
      optionList,
    },
    visual: {
      isBlocked,
    },
    validation: {
      error: {
        errorList: [],
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
    other: {
      dataTestId: 'task-operation-select',
    },
  };

  return (
    <OperationSelectWrapper>
      <OperationSelectLabel>
        Операция
        {taskStore.taskCreate?.bindingDate ? (
          <b>
            &nbsp;
            {`(для ${moment(taskStore.taskCreate?.bindingDate).format('D MMMM')})`}
          </b>
        ) : null}
      </OperationSelectLabel>
      <NewDropdown bodyForceCloseOptions={bodyForceCloseOptions} config={dropdownConfig} />
    </OperationSelectWrapper>
  );
};

const OperationSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & > div {
    width: 100%;
  }
`;

const OperationSelectLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  margin: 0px 0px 4px;
  color: rgb(154, 160, 173);
  & > b {
    color: #000;
  }
`;

OperationSelect.displayName = 'OperationSelect';

export default observer(OperationSelect);
