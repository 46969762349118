import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Typography } from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { useStoParams } from '../../../../../../../../../../../../../../../../hooks';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../../../../../../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../../../mobx/controllers';
import { StoStore } from '../../../../../../../../../../../../../../mobx';

import Styled from './StoAttributeRequiredSelector.styles';

const StoAttributeRequiredSelector: FC = () => {
  const stoStore = useStore(StoStore);
  const checklistsStore = useStore(ChecklistsStore);

  const controller = useStore(Controller);

  const params = useStoParams();

  const attributeUpdate = checklistsStore.getAttrUpdate(
    params.checklistId,
    params.stageId,
    params.attributeId
  );

  const checkIfSelectorIsDisabled = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;

    return false;
  };

  const handleIsRequiredChange = useCallback((value: boolean): void => {
    controller.changeAttribute(params, { isRequired: value });
  }, []);

  const getDataTestId = useDataTestIdV2('sto__attribute__required-selector');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Typography dataTestId={getDataTestId('label')['data-test-id']}>
        Обязательный параметр
      </Typography>

      <Checkbox
        disable={checkIfSelectorIsDisabled()}
        value={Boolean(attributeUpdate.isRequired)}
        onChange={handleIsRequiredChange}
        dataTestId={getDataTestId('checkbox')['data-test-id']}
      />
    </Styled.Wrapper>
  );
};

StoAttributeRequiredSelector.displayName = 'StoAttributeRequiredSelector';

export default observer(StoAttributeRequiredSelector);
