import { Axios } from '../../../../../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';

@provide.transient()
class WeatherAPIService {
  @lazyInject(Axios)
  axios: Axios;

  public fetchWeather(fieldId: string, seasonYear: number | string) {
    return this.axios.api
      .getWeatherById({
        id: fieldId,
        seasonYear: Number(seasonYear),
      })
      .catch(() => null);
  }

  public fetchWeatherForecasts(fieldId: string, seasonYear: number | string) {
    return this.axios.api
      .getWeatherForecastsById({
        id: fieldId,
        seasonYear: Number(seasonYear),
      })
      .catch(() => null);
  }
}

export default WeatherAPIService;
