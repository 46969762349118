import { ComparisonTableBuilderStore as Store } from '../../stores';
import {
  IComparisonTableBuilderCell as ICell,
  IComparisonTableBuilderColumn as IColumn,
} from '../../../models/data';
import { lazyInject, provide } from '../../../../../utils/IoC';

@provide.transient()
class ComparisonTableBuilderGridsService {
  @lazyInject(Store)
  protected store: Store;

  getFixedWidth = (builderId: string): string => {
    const columnList = this.store.getColumnList(builderId, { isSortByOrder: true });

    return columnList
      .reduce((widthList, { isFixed, width }) => {
        if (isFixed) {
          widthList.push(width);
        }

        return widthList;
      }, [])
      .join(' ');
  };

  getScrollableColumnsData = (
    builderId: string
  ): {
    columnWidth: number;
    totalColumns: number;
  } => {
    const columnList = this.store.getColumnList(builderId, { isSortByOrder: true });

    const initialData: ReturnType<typeof this.getScrollableColumnsData> = {
      columnWidth: 0,
      totalColumns: 0,
    };

    return columnList.reduce((data, { isFixed, width }) => {
      if (!isFixed) {
        const formattedWidth = Number(width.split('px')[0]);

        data.totalColumns += 1;
        data.columnWidth = formattedWidth;
      }

      return data;
    }, initialData);
  };

  getColumnList = (
    builderId: string,
    options?: {
      isOnlyFixed?: boolean;
    }
  ): IColumn[] => {
    const columnList = this.store.getColumnList(builderId, { isSortByOrder: true });

    return columnList.reduce((list, column) => {
      if (options?.isOnlyFixed && column.isFixed) {
        list.push(column);
      }

      if (!options?.isOnlyFixed && !column.isFixed) {
        list.push(column);
      }

      return list;
    }, []);
  };

  getColumnIdList = (
    builderId: string,
    options?: {
      isOnlyFixed?: boolean;
    }
  ): string[] => {
    const columnList = this.store.getColumnList(builderId, { isSortByOrder: true });

    return columnList.reduce((idList, { isFixed, id }) => {
      if (options?.isOnlyFixed && isFixed) {
        idList.push(id);
      }

      if (!options?.isOnlyFixed && !isFixed) {
        idList.push(id);
      }

      return idList;
    }, []);
  };

  getOrderedCellList = (
    builderId: string,
    rowId: string
  ): {
    fixedCellList: ICell[];
    scrollableCellList: ICell[];
  } => {
    const cellList = this.store.getCellList(builderId, rowId);

    const [, ...fixedColumnIdList] = this.getColumnIdList(builderId, { isOnlyFixed: true });
    const scrollableColumnIdList = this.getColumnIdList(builderId);

    const fixedCellList = this.getListFromCellOfColumn(
      builderId,
      rowId,
      fixedColumnIdList,
      cellList
    );

    const scrollableCellList = this.getListFromCellOfColumn(
      builderId,
      rowId,
      scrollableColumnIdList,
      cellList
    );

    return {
      fixedCellList,
      scrollableCellList,
    };
  };

  protected getListFromCellOfColumn = (
    builderId: string,
    rowId: string,
    columnIdList: string[],
    cellList: ICell[]
  ): ICell[] => {
    return columnIdList.reduce<ICell[]>((acc, columnId) => {
      const foundCell = cellList.find(cell => columnId === cell.columnId);

      if (foundCell) {
        acc.push(foundCell);
      } else {
        acc.push(this.createTemplateCell(builderId, columnId, rowId));
      }

      return acc;
    }, []);
  };

  protected createTemplateCell = (builderId: string, columnId: string, rowId: string): ICell => ({
    rowId,
    builderId,
    columnId,
    renderType: 'auto',
    autoRenderConfig: { preset: 'default', value: '—' },
  });
}

export default ComparisonTableBuilderGridsService;
