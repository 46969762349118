import { useModal } from '@farmlink/farmik-ui';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { useFieldsRoutes } from '../../../../../../../../hooks';
import FieldsMultiselectController from '../../../../../../mobx/controllers/FieldsMultiselect.controller';
import { EFieldsMultiselectModalName } from '../../../../../../modals/config';
import FooterStyled from '../../Footer.styles';

import Styled from './ApplyResetButtons.styles';

interface IProps {
  getDataTestId: ReturnType<typeof useDataTestIdV2>;
}

const ApplyResetButtons: FC<IProps> = props => {
  // Навигация
  const navigate = useNavigate();
  const fieldsRoutes = useFieldsRoutes();

  // Контроллеры
  const controller = useStore(FieldsMultiselectController);

  const modal = useModal();

  const reset = () => {
    const modalId = EFieldsMultiselectModalName.resetChanges;
    modal.openModalByModalId(modalId, null, () => {
      controller.reset();
    });
  };

  const apply = () => {
    controller.saveAll().then(() => {
      navigate(fieldsRoutes.fieldsViewRoute);
    });
  };

  return (
    <Styled.Wrapper>
      <FooterStyled.CustomButton
        color={'default'}
        onClick={reset}
        {...props.getDataTestId('reset-button')}
      >
        Не сохранять
      </FooterStyled.CustomButton>

      <FooterStyled.CustomButton
        color={'primary'}
        onClick={apply}
        {...props.getDataTestId('apply-button')}
      >
        Сохранить
      </FooterStyled.CustomButton>
    </Styled.Wrapper>
  );
};

export default ApplyResetButtons;
