import { Colors, IconButton, Typography } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 10px 8px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

  position: relative;
`;

const Value = styled(Typography)`
  width: 40px;
  flex-shrink: 0;
`;

const ArrowButton = IconButton;

const Styled = {
  Wrapper,
  Value,
  ArrowButton,
};

export default Styled;
