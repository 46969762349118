import { IGetIntensity } from '../../intensity/intensity.model';
import {
  IChecklistAttributeWithValue,
  IGetChecklistAttribute,
  IGetChecklistAttributeValue,
  IPutChecklistAttributeValue,
} from '../attribute/checklist.attribute.model';

export interface IGetChecklistInstanceByTaskId {
  name: string;
  type: ECheckListInstanceType;
  machineryName?: string;
  isActive: boolean;
  intensity?: IGetIntensity;
  id: string;
  checkListId?: string;
  planCoords: ICheckListInstancePlanCoordinates;
  actualCoords?: ICheckListInstanceActualCoordinates;
  creationDate: string;
  createdBy: string;
  createOrUpdateDate: string;
  isComplete?: boolean;
  pointOutOfField: boolean;
}

export interface IGetChecklistInstance extends IGetChecklistInstanceByTaskId {
  attributeValues: IGetChecklistAttributeValue[];
  values?: IPutChecklistAttributeValue[];
  intensity?: IGetIntensity;
}

export interface IPutChecklistInstance {
  taskId?: string;
  type: ECheckListInstanceType;
  isActive: boolean;
  machineryName?: string;
  intensity?: string;
  createOrUpdateDate?: string;
  planCoords?: ICheckListInstancePlanCoordinates;
  actualCoords?: ICheckListInstanceActualCoordinates;
  name?: string;
  clientOnlyId?: string;
  clientLeafletId?: number;
}

export interface IPutNestedChecklistInstance {
  checkListId: string;
  values: IPutChecklistAttributeValue[];
}

export interface IDrawingNestedInstance {
  id: string;
  rootChecklistAttribute: IGetChecklistAttribute;
  nestedInstance: IPutNestedChecklistInstance;
  attributeWithValueList: IChecklistAttributeWithValue[];
  isTemplate?: boolean;
  isRootAttributeHidden?: boolean;
  isCollapsed: boolean;
}

interface ICheckListInstancePlanCoordinates {
  type: string;
  coordinates?: number[];
  geometry: {
    type: string;
    coordinates: number[];
  };
}

interface ICheckListInstanceActualCoordinates {
  type: string;
  coordinates?: number[];
  geometry: {
    type: string;
    coordinates: number[];
  };
}

export enum ECheckListInstanceType {
  FromWeb = 'FROM_WEB',
  Machinery = 'MACHINERY',
  PlannedPoint = 'PLANNED_POINT',
  ActualPoint = 'ACTUAL_POINT',
  Nested = 'NESTED ',
  Field = 'FIELD',
}

interface IDrawChecklistInstance {
  id: string;
  positionNumber: number;
  instance: IGetChecklistInstanceByTaskId | IPutChecklistInstance;
  isMachinery?: boolean;
  isField?: boolean;
  isUnsaved?: boolean;
  isDisabled?: boolean;
}

interface IDrawChecklistInstanceWithType extends IDrawChecklistInstance {
  type: ECheckListInstanceType;
}

export type { IDrawChecklistInstance, IDrawChecklistInstanceWithType };
