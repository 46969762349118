import { ENotificationHeight, ENotificationType, useNotificator } from '@farmlink/farmik-ui';
import { useEffect } from 'react';

import { useStore } from '../../../../../../shared/utils/IoC';
import { FieldEditStore as Store } from '../mobx';

function useNotifications() {
  const notificator = useNotificator();

  const store = useStore(Store);

  useEffect(() => {
    notificator.clearAll();

    const zoneError = store.isCultureZoneOutOfField;

    if (zoneError === null) {
      return;
    }

    notificator.setNotification({
      message: zoneError
        ? 'Культурная зона выходит за пределы контура поля'
        : 'Культурные зоны в пределах контура поля',
      style: {
        type: zoneError ? ENotificationType.Warning : ENotificationType.Success,
        height: ENotificationHeight.BIG,
        hideProgressBar: true,
        autoClose: 10000,
        placement: 'top-right',
      },
    });
  }, [store.isCultureZoneOutOfField]);

  useEffect(() => {
    return () => notificator.clearAll();
  }, []);
}

export default useNotifications;
