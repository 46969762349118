import { Colors, DropdownButton, TButtonMultiAction } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { StoStore } from '../../mobx';
import { EStoAction } from '../../../../../../../../api/models/as-fields/STO/STO.model';
import { useStoParams } from '../../../../hooks';

import { useStoActionButton } from './hooks';
import Styled from './StoActionButton.styles';

const StoActionButton = () => {
  const store = useStore(StoStore);

  const { selectedSto } = store;

  const actions = useStoActionButton(selectedSto?.id);
  const params = useStoParams();

  const getActionList = () => {
    const buttonList: TButtonMultiAction[] = [];

    (selectedSto?.availableActions ?? []).forEach(action => {
      if (action === EStoAction.StoPublish)
        buttonList.push({ title: 'Опубликовать', onClick: actions.publishSto });

      if (action === EStoAction.StoDownload)
        buttonList.push({
          title: 'Скачать',
          onClick: () =>
            actions.downloadReport({
              stoId: params.stoId,
              stoName: selectedSto?.name,
              versionId: params.versionId,
            }),
        });

      if (action === EStoAction.StoDelete)
        buttonList.push({ title: 'Удалить', onClick: actions.deleteSto });

      if (action === EStoAction.StoDiscardCard)
        buttonList.push({ title: 'Удалить изменения', onClick: actions.discardDraft });

      if (action === EStoAction.StoActivate)
        buttonList.push({ title: 'Включить', onClick: actions.activateSto });

      if (action === EStoAction.StoDictionaryDownload)
        buttonList.push({
          title: 'Скачать реестры',
          onClick: () => actions.downloadDictionaryList(params),
        });

      if (action === EStoAction.StoDeactivate)
        buttonList.push({
          title: 'Отключить',
          textColor: Colors.pink,
          onClick: actions.deactivateSto,
        });
    });

    return buttonList;
  };

  if (!getActionList()?.length) {
    return null;
  }

  if (getActionList()?.length === 1) {
    return (
      <div>
        <Styled.SingleButton
          dataTestId="sto-action-button"
          color="primary"
          onClick={() => getActionList()[0]?.onClick()}
        >
          {getActionList()[0].title}
        </Styled.SingleButton>
      </div>
    );
  }

  return (
    <div>
      <DropdownButton
        dataTestId={'sto__actions'}
        title="Действия"
        multiActionList={getActionList()}
        style={{
          color: 'primary',
          size: 'sm',
          placement: 'bottom',
          width: '164px',
        }}
      />
    </div>
  );
};

export default observer(StoActionButton);
