import styled from 'styled-components';

const ButtonWrapper = styled.div`
  width: 120px;

  text-align: center;

  & > button {
    display: inline-block;
  }

  &:first-child {
    margin-right: 12px;
  }
`;

export default {
  ButtonWrapper,
};
