import { CSSProperties, FC, useEffect } from 'react';

import { useDataTestIdV2 } from '../utils/hooks/locators';
import { useStore } from '../../utils/IoC';

import { IMapGlobalConfig as IGlobalConfig } from './models';
import MapControls from './components/Controls/Controls';
import { PointCoordinatesControls } from './components';
import { MapCoreController } from './modules';
import Styled from './Map.styles';
import './style.css';

interface IProps {
  globalConfig: IGlobalConfig;
  style?: CSSProperties;
}

const Map: FC<IProps> = ({ globalConfig, style }) => {
  const mapCoreController = useStore(MapCoreController);

  const getDataTestId = useDataTestIdV2(globalConfig.mapKey);

  useEffect(() => {
    mapCoreController.initialize(globalConfig);

    return () => {
      mapCoreController.destroy();
    };
  }, []);

  return (
    <Styled.Composition>
      <Styled.Wrapper id={globalConfig.mapKey} {...getDataTestId()} $style={style} />
      <MapControls controls={globalConfig.controls} />

      {globalConfig.pointCoordinatesControl && <PointCoordinatesControls />}
    </Styled.Composition>
  );
};

Map.displayName = 'Map';

export default Map;
