import { Listener } from 'eventemitter2';
import { LeafletMouseEvent } from 'leaflet';
import { useEffect, useRef } from 'react';

import { MapEventBus } from '../../modules/MapCore';
import { TMapEvents } from '../../modules/MapCore/services/MapEventBus.service';
import { IMapVertexInfo as IVertexInfo } from '../../models';
import { BaseMarker, BasePolygon } from '../../utils/MapElements';

interface IListener {
  event: string;
  listener: Listener;
}

type CallbackFn<T = void> = (value: T) => void;

const useMapEvents = () => {
  const ref = useRef<IListener[]>([]);

  /**
   * Внутрення функция для очищения уже существующего листенера в случае ререндера
   */
  const removeListener = (event: keyof TMapEvents) => {
    const foundListener = ref.current.find(element => element.event === event);

    if (!foundListener) {
      return;
    }

    foundListener.listener.off();
    ref.current = ref.current.filter(element => element.event !== event);
  };

  /**
   * Внутренняя функция для добавления нового листенера
   */
  const attachListener = <K extends keyof TMapEvents>(event: K, callback: TMapEvents[K]) => {
    removeListener(event);

    const listener = MapEventBus.on(event, callback, { objectify: true });
    ref.current.push({ event, listener });
  };

  const onClickMap = (callback: CallbackFn<LeafletMouseEvent>) => {
    attachListener('map.click', callback);
  };

  /**
   * Вызывается после после завершения рисования фигуры
   */
  const onCreatePolygon = (callback: CallbackFn<BasePolygon>) => {
    attachListener('draw.polygon.create', callback);
  };

  /**
   * Вызывается после добавления маркера на карту
   */
  const onCreateMarkerByClick = (callback: CallbackFn<BaseMarker>) => {
    attachListener('map.marker.createOnClick', callback);
  };

  /**
   * Вызывается при клике на полигон
   */
  const onClickPolygon = (callback: CallbackFn<BasePolygon>) => {
    attachListener('polygon.click', callback);
  };

  /**
   * Вызывается в режиме редактирования полигона после отжатия кнопки мыши на vertex element
   */
  const onEditPolygon = (callback: CallbackFn<BasePolygon>) => {
    attachListener('draw.polygon.edit', callback);
  };

  /**
   * Вызывается при клике на область карты без слоев
   */
  const onCancelSelectedPolygon = (callback: CallbackFn) => {
    attachListener('polygon.cancelSelected', callback);
  };

  /**
   * Вызывается после добавления нового Vertex
   */
  const onVertexAdded = (callback: CallbackFn<L.Marker>) => {
    attachListener('draw.polygon.vertex.add', callback);
  };

  /**
   * Вызывается после клика на Vertex
   */
  const onVertexClick = (callback: CallbackFn<IVertexInfo>) => {
    attachListener('draw.polygon.vertex.click', callback);
  };

  /**
   * Вызывается на движение маркера
   */
  const onMarkerDrag = (callback: CallbackFn<IVertexInfo>) => {
    attachListener('draw.polygon.marker.drag', callback);
  };

  /**
   * Вызывается после включения редактирования любого полигона
   */
  const onEnableEdit = (callback: CallbackFn) => {
    attachListener('draw.enableEdit', callback);
  };

  /**
   * Вызывается после выключения редактирования любого полигона
   */
  const onDisableEdit = (callback: CallbackFn) => {
    attachListener('draw.disableEdit', callback);
  };

  const unsubscribeAll = () => {
    ref.current.forEach(({ listener }) => listener.off());
    ref.current = [];
  };

  useEffect(() => {
    return () => {
      unsubscribeAll();
    };
  }, []);

  return {
    onClickMap,
    onClickPolygon,
    onCreatePolygon,
    onEditPolygon,
    onCreateMarkerByClick,
    onCancelSelectedPolygon,
    onVertexAdded,
    onVertexClick,
    unsubscribeAll,
    onMarkerDrag,
    onEnableEdit,
    onDisableEdit,
    /**
     * Возможно стоит отказаться от оборачивания каждого эвента в отдельную функцию. Метод attachListener выполняет ту же логику
     */
    on: attachListener,
  };
};

export default useMapEvents;
