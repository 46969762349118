import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import { ChecklistsFileAttr } from '../ChecklistsFileAttr';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsStore } from '../../../../mobx/stores';
import {
  EChecklistAttributeType as EAttrType,
  EChecklistAttributeWidth,
} from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { ChecklistsCSSContainer as CSSContainer } from '../../../../components/elements';

interface IProps {
  groupId: string;
  attrId: string;
  /**
   * Специальное свойство, что добавляет CSS-контейнер, который изменяет верстку в зависимости от того,
   * выбраны ли фотографии или же нет. Действует для всех атрибутов, кроме среднего значения.
   */
  isNeedAdjustableContainer?: boolean;
}

const ChecklistsFileAttrContainer: FC<IProps> = ({
  groupId,
  attrId,
  isNeedAdjustableContainer,
}) => {
  const checklistsStore = useStore(ChecklistsStore);

  const attrToDraw = checklistsStore.getAttrToDraw<EAttrType.FileLink>(groupId, attrId);
  const hasEmptyValue = !attrToDraw?.value?.fileValue?.length;
  const attrWidth = attrToDraw?.initialModel?.position?.width;

  const formattedMaxInRow = useMemo(() => {
    switch (attrWidth) {
      case EChecklistAttributeWidth.Full:
        return 18;
      default:
        return 9;
    }
  }, [attrWidth]);

  if (!attrId) return <></>;

  if (isNeedAdjustableContainer) {
    return (
      <CSSContainer {...(hasEmptyValue ? {} : { flex: '1 1 auto' })}>
        <ChecklistsFileAttr maxInRow={formattedMaxInRow} attrToDraw={attrToDraw} />
      </CSSContainer>
    );
  }

  return <ChecklistsFileAttr attrToDraw={attrToDraw} />;
};

ChecklistsFileAttrContainer.displayName = 'ChecklistsFileAttrContainer';

export default observer(ChecklistsFileAttrContainer);
