import React, { FC } from 'react';

import { Container } from '../../../../../../../../../shared/features/UI/new/Container';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { TablePlugBuilder } from '../../../../../../../../../shared/features/UI/plugs/TablePlugBuilder';

const StoOperationsListNoDataPlug: FC = () => {
  const getDataTestId = useDataTestIdV2('sto__operations__list__no-data-plug');
  const dataTestId = getDataTestId()['data-test-id'];

  return (
    <Container dataTestId={dataTestId}>
      <div style={{ height: '400px' }}>
        <TablePlugBuilder
          autoRenderConfig={{
            header: { icon: 'file' },
            content: {
              title: 'Для выбранной культуры нет доступных операций. Обратитесь к руководителю',
            },
          }}
          dataTestId={dataTestId}
        />
      </div>
    </Container>
  );
};

StoOperationsListNoDataPlug.displayName = 'StoOperationsListLoader';

export default StoOperationsListNoDataPlug;
