import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 64px;
  flex-shrink: 0;
  padding: 10px 8px;
  gap: 8px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

  position: relative;
`;

const Styles = {
  Wrapper,
};

export default Styles;
