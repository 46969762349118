import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { TChecklistsIntegerAttrToDraw as TIntegerAttrToDraw } from '../../../../models';
import { Input } from '../../../../../../../operationsAndTasks/modules/fullscreen/checklist/components/shared/Input';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { formatEnteredIntValue } from '../../../../../../../../../shared/utils/helpers/formatEnteredValue';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList as useAttrErrorList,
  useChecklistsAttrVisibility as useAttrVisibility,
} from '../../../../hooks';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';

interface IProps {
  attrToDraw: TIntegerAttrToDraw;
}

const ChecklistsIntegerAttr: FC<IProps> = ({ attrToDraw }) => {
  const { id, groupId, initialModel, value, isBlocked, dependentFileAttrId } = attrToDraw;

  const getDataTestId = useDataTestIdV2('checklists__integer-attribute');

  const { changeAttrValue } = useStore(ChecklistsController);

  const [enteredValue, setEnteredValue] = useState(value.integerValue);

  const isShowAttribute = useAttrVisibility(attrToDraw);
  const errorList = useAttrErrorList(attrToDraw);
  const placeholder = useAttrPlaceholder(initialModel.attribute);

  useEffect(() => {
    setEnteredValue(value.integerValue);
  }, [value.integerValue]);

  const debounceOnChange = useRef(
    _.debounce((newValue: string | number): void => {
      changeAttrValue(EAttrType.Int, groupId, {
        ...value,
        integerValue: newValue,
      });
    }, 500)
  );

  const handleInputChange = useCallback((newValue: string): void => {
    const formattedValue = formatEnteredIntValue(newValue, { allowNegativeNumber: true });

    if (!_.isUndefined(formattedValue)) {
      setEnteredValue(formattedValue);
      debounceOnChange.current(formattedValue);
    }
  }, []);

  return (
    <>
      {isShowAttribute ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
          dataTestId={getDataTestId()['data-test-id']}
        >
          <Input
            isBlocked={isBlocked}
            value={enteredValue}
            onChange={handleInputChange}
            isRequired={initialModel.isRequired}
            label={initialModel.attribute?.name}
            placeholder={placeholder}
            maxLength={16}
            tooltip={initialModel.toolTip}
            isWithoutErrorText
            dataTestId={getDataTestId('input')['data-test-id']}
          />

          <CSSContainer
            display={'flex'}
            justifyContent={'space-between'}
            dataTestId={getDataTestId('additional-info')['data-test-id']}
          >
            <CSSContainer dataTestId={getDataTestId('error-wrapper')['data-test-id']}>
              <InputFieldError error={{ errorList }} />
            </CSSContainer>

            <FileAttrContainer
              groupId={groupId}
              attrId={dependentFileAttrId}
              isNeedAdjustableContainer
            />
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsIntegerAttr.displayName = 'ChecklistsIntegerAttr';

export default observer(ChecklistsIntegerAttr);
