import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 320px;
`;

const CommentWrapper = styled.div<{ $blocked?: boolean; $isError?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  & textarea {
    background: ${({ $blocked }) => ($blocked ? '#fffffff' : '#faf9fd')};
    color: #151f32;
    border: 1px solid;
    border-radius: 14px;
    font-size: 14px;
    width: 100%;
    min-height: 100px;
    padding: 12px 16px;
    box-sizing: border-box;
    outline: none;
    border-color: #e9e6f0;
    color: #151f32;
    resize: none;
    pointer-events: ${({ $blocked }) => ($blocked ? 'none' : 'auto')};
    scrollbar-width: none;
    ${({ $isError }) =>
      $isError &&
      css`
        border-color: ${Colors.red};
      `}

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const CommentLabel = styled.div`
  margin-top: ${ESpaceSize.M}px;
  ${({ theme }) => theme.Typography.schemas.body}
`;

const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${ESpaceSize.XL}px;
`;

const TypographyButton = styled.button`
  margin: 0;
  width: max-content;
  color: ${Colors.secondaryDarkGray};
  background: none;
  border: none;
  text-align: center;
  cursor: pointer;
  ${({ theme }) => theme.Typography.schemas.bodySubAccent}
  transition: color 0.3s ease-in-out;

  :hover {
    color: ${Colors.hoverDarkGrey};
  }
`;

const Title = styled.h5`
  ${({ theme }) => theme.Typography.schemas.h5}
  color: ${Colors.generalBlack};
  margin: 0;
`;

const Delimiter = styled.div`
  margin: 16px 0 24px 0;
  width: 100%;
  height: 1px;
  background-color: ${Colors.borderGray};
`;

const Error = styled.p`
  ${({ theme }) => theme.Typography.schemas.body}
  color: ${Colors.hoverPink};
  margin-top: 4px;
`;

const Styled = {
  Wrapper,
  CommentWrapper,
  CommentLabel,
  ButtonsWrapper,
  Title,
  Delimiter,
  Error,
  TypographyButton,
};

export default Styled;
