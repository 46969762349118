import { TypeApiRoute } from '../../../models/type.api.request';
import { IStoContent, IUpdateSto } from '../../../models/as-fields/STO/STO.model';

interface IQueryPayload {
  id: string;
}

type TRequest = IQueryPayload & Partial<IUpdateSto>;

type TResponse = IStoContent;

export const updateSto: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }: TRequest) => `/api/as-fields/sto/${id}`,
  method: 'PUT',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
