import { runInAction } from 'mobx';

import { lazyInject, provide } from '../../../../../utils/IoC';
import { IMapLayerGroupConfig, IMapLayerGroupElement, IMapSetListOptions } from '../../../models';
import { BaseTooltipOptions } from '../../../utils/constants';
import { MapCoreStore } from '../../MapCore';
import MapLayerGroupStore from '../stores/MapLayerGroup.store';
import MapLayerGroupService from '../services/MapLayerGroup.service';
import MapLayerGroupEventsService from '../services/MapLayerGroupEvents.service';

type TLayerGroupServiceArgs<K extends keyof MapLayerGroupService> = Parameters<
  MapLayerGroupService[K]
>;

@provide.transient()
class MapLayerGroupController {
  @lazyInject(MapCoreStore)
  private mapCoreStore: MapCoreStore;

  @lazyInject(MapLayerGroupStore)
  private store: MapLayerGroupStore;

  @lazyInject(MapLayerGroupService)
  private service: MapLayerGroupService;

  @lazyInject(MapLayerGroupEventsService)
  private eventsService: MapLayerGroupEventsService;

  public display<E>(config: IMapLayerGroupConfig<E>): IMapLayerGroupElement<E> {
    const { element, options = {} } = config;

    const layerGroup = this.service.create(config.layerGroup);

    if (!layerGroup) {
      return { element, layerGroup: null };
    }

    if (options?.isAllowToClick) {
      this.eventsService.registerClickEvent(layerGroup);
    }

    if (options?.tooltip) {
      layerGroup.bindTooltip(options.tooltip.content, {
        ...BaseTooltipOptions,
        ...options.tooltip.options,
      });
    }

    return { element, layerGroup };
  }

  public displayMany<E>(
    configList: IMapLayerGroupConfig<E>[],
    setOptions?: IMapSetListOptions
  ): IMapLayerGroupElement<E>[] {
    if (setOptions?.isClearPreviousList) {
      this.service.removeManyByIds([], { isRemoveAll: true });
    }

    let res: IMapLayerGroupElement<E>[];

    runInAction(() => {
      res = configList.map(config => this.display(config)).filter(Boolean);
    });

    return res;
  }

  public async displayManyAsync<E>(
    configList: IMapLayerGroupConfig<E>[],
    setOptions?: IMapSetListOptions
  ): Promise<IMapLayerGroupElement<E>[]> {
    if (setOptions?.isClearPreviousList) {
      this.service.removeManyByIds([], { isRemoveAll: true });
    }

    let result: Promise<IMapLayerGroupElement<E>>[];

    runInAction(() => {
      result = configList.map(config => {
        return new Promise(res => {
          setTimeout(() => {
            const polygonElement = this.display(config);
            res(polygonElement);
          }, 0);
        });
      });
    });

    const polygonElements = await Promise.all(result);

    return polygonElements.filter(Boolean);
  }

  public remove(...args: TLayerGroupServiceArgs<'removeById'>) {
    this.service.removeById(...args);
  }

  public removeMany(...args: TLayerGroupServiceArgs<'removeManyByIds'>) {
    this.service.removeManyByIds(...args);
  }

  /**
   * Выбор слоя. Состоит из след. шагов
   * 1. Возвращение стандартных стилей предыдущему выбранному слою
   * 2. Установка новых стилей для слоя
   * 3. Закрытие тултипа
   */
  public select(...args: TLayerGroupServiceArgs<'select'>) {
    this.service.select(...args);
  }

  public deselect(...args: TLayerGroupServiceArgs<'deselectSelected'>) {
    this.service.deselectSelected(...args);
  }
}

export default MapLayerGroupController;
