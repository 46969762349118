import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { FontSizesObj } from '../../../../../../../../../../../shared/constans/sizes';

const ListItem = styled('div')({
  display: 'flex',
  gap: ESpaceSize.S,

  '& span': {
    ...FontSizesObj.medium,
  },
});

const Styles = {
  ListItem,
};

export default Styles;
