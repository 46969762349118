import { Colors, Icon, Input } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { memo, FC, useState } from 'react';

import { Field } from '../../../../../../../../../../api/models/field.model';
import { toFixedWithCeilBackEnd } from '../../../../../../../../../shared/utils/toFixedWithCeil';
import { getErrorNameByType } from '../../../../utils/helpers';

import Styled from './ListItem.styles';

interface IProps {
  field: Field;
  isSelected: boolean;
  onInputChange: (value) => void;
  onDelete: () => Promise<any>;
  onClick: () => void;
}

const FieldsImportListItem: FC<IProps> = props => {
  const [isProcessing, setIsProcessing] = useState(false);

  const { field, isSelected } = props;

  const showStatus = field.error || isSelected;

  const getError = () => {
    if (field.error) {
      return getErrorNameByType(field.errorType);
    }

    return `Площадь: ${toFixedWithCeilBackEnd(field.area)} га`;
  };

  const handleDelete = () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    props.onDelete()?.finally(() => {
      setIsProcessing(false);
    });
  };

  return (
    <Styled.Wrapper onClick={props.onClick} $isSelected={isSelected}>
      {showStatus && <Styled.Status $isError={field.error} />}

      <Styled.ContentWrapper>
        <Styled.MapIcon>
          <Icon
            icon={'mapList'}
            size={'medium'}
            fill={isSelected ? Colors.grey : Colors.generalBlack}
          />
        </Styled.MapIcon>

        <Styled.RightColumn>
          <Input value={field.name} onChange={props.onInputChange} />

          <Styled.BottomSectionWrapper>
            <Styled.AreaLabel $isError={field.error}>{getError()}</Styled.AreaLabel>

            <Styled.Delete
              onClick={handleDelete}
              $isSelectedWithoutError={isSelected && !field.error}
            >
              Удалить
            </Styled.Delete>
          </Styled.BottomSectionWrapper>
        </Styled.RightColumn>
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};
FieldsImportListItem.displayName = 'FieldsImportListItem';

export default memo(observer(FieldsImportListItem));
