import React, { FC, useEffect, useState } from 'react';
import {
  EModalType,
  Input,
  ModalFooter,
  TModalButton,
  TModalConfig,
  Typography,
  useModal,
} from '@farmlink/farmik-ui';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { IStoAttributeAddUserDictionaryValueModalPayload as IModalPayload } from '../../interfaces';

import Styled from './StoAttributeAddUserDictionaryValueModal.styles';

const StoAttributeAddUserDictionaryValueModal: FC = () => {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const modalActions = useModal();

  const modalPayload =
    (modalActions.getModalPayload() as IModalPayload) ??
    ({
      name: '',
    } as IModalPayload);

  useEffect(() => {
    if (modalPayload?.isEditing) {
      setIsEditing(true);
      setValue(modalPayload.defaultItemValue);
    }
  }, [modalPayload]);

  const handleInputChange = (newValue: string): void => {
    setIsSaveButtonDisabled(false);
    setValue(newValue);
  };

  const handleSuccessClick = (): void => {
    if (!value) {
      setIsSaveButtonDisabled(true);

      return;
    }

    const formattedValue = value.trim();

    if (isEditing) modalPayload.editItemValue(formattedValue);
    else modalPayload.addItemValue(formattedValue);

    modalActions.closeModal();
  };

  const getDataTestId = useDataTestIdV2('sto__attribute__user-dictionary-registry__modal');

  const denyButton: TModalButton = {
    title: 'Отменить',
    dataTestId: getDataTestId('deny-button')['data-test-id'],
    color: 'default',
    handler: modalActions.closeModal,
  };

  const successButton: TModalButton = {
    title: isEditing ? 'Сохранить' : 'Добавить',
    dataTestId: getDataTestId('success-button')['data-test-id'],
    color: 'primary',
    handler: handleSuccessClick,
  };

  return (
    <Styled.Wrapper {...getDataTestId('')}>
      <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
        <Typography dataTestId={getDataTestId('title')['data-test-id']} variant={'h5'}>
          {modalPayload.name}
        </Typography>
      </Styled.TitleWrapper>

      <Styled.Header {...getDataTestId('header')}>
        <Typography dataTestId={getDataTestId('subtitle')['data-test-id']}>
          {isEditing ? 'Редактировать запись' : 'Добавить запись'}
        </Typography>
      </Styled.Header>

      <Styled.Body {...getDataTestId('body')}>
        <Input
          dataTestId={getDataTestId('input')['data-test-id']}
          value={value}
          onChange={handleInputChange}
          label={modalPayload.name}
          placeholder={'Введите название'}
          error={isSaveButtonDisabled ? 'Это поле не может быть пустым' : ''}
        />
      </Styled.Body>

      <ModalFooter
        dataTestId={getDataTestId('footer')['data-test-id']}
        denyButton={denyButton}
        successButton={successButton}
      />
    </Styled.Wrapper>
  );
};

StoAttributeAddUserDictionaryValueModal.displayName = 'StoAttributeAddUserDictionaryValueModal';

const StoAttributeAddUserDictionaryValueModalConfig: TModalConfig = {
  name: 'stoAttributeAddUserDictionaryValue',
  title: '',
  type: EModalType.Custom,
  id: 'stoAttributeAddUserDictionaryValue',
  children: StoAttributeAddUserDictionaryValueModal,
  dataTestId: 'sto__attribute__user-dictionary-registry__modal-container',
  styledProps: {
    $modalPaddings: '40px',
    $size: '440px',
  },
};

export default StoAttributeAddUserDictionaryValueModalConfig;
