import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { ENotificationType, useNotificator } from '@farmlink/farmik-ui';

import { getFileExtension } from '../../../../../../../../../../fields/modules/FieldsImport/utils/helpers';
import getNotificationScheme, {
  ENotificationScheme,
} from '../../../../../../../../../../../../shared/utils/helpers/getNotificationScheme/getNotificationScheme';
import { useStore } from '../../../../../../../../../../../../shared/utils/IoC';
import { StoRegistriesController as Controller } from '../../../../../mobx/controllers';
import { EStoDictionaryName as EDictionaryName } from '../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { useStoParams } from '../../../../../../../../../hooks';

const getFormattedDictionaryName = (dictionaryName: EDictionaryName): string => {
  switch (dictionaryName) {
    case EDictionaryName.WeedPlants:
      return 'сорнякам';

    case EDictionaryName.Vermin:
      return 'вредителям';

    case EDictionaryName.Diseases:
      return 'болезням';

    default:
      return '';
  }
};

const getNotificatorSuccessText = (dictionaryNameList: EDictionaryName[]): string => {
  if (dictionaryNameList.length === 1) {
    const [name] = dictionaryNameList;

    return `Реестр по ${getFormattedDictionaryName(name)} загружен. 
Сохраните и опубликуйте изменения`;
  }

  return `Реестры загружены. 
Сохраните и опубликуйте изменения`;
};

const useStoRegistryFromExcelUploader = () => {
  const controller = useStore(Controller);

  const [isUploadPending, setIsUploadPending] = useState<boolean>(false);
  const fileUploaderRef = useRef<HTMLInputElement | null>(null);

  const params = useStoParams();
  const notificator = useNotificator();

  const handleFileUploaderChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const [file] = event.target.files;

    if (!file) return;

    const fileExtension = getFileExtension(file);

    if (!fileExtension.includes('xlsx')) return;

    const formData = new FormData();
    formData.append('source', file);

    setIsUploadPending(true);

    void controller.uploadDictionary(
      { stoId: params.stoId, source: formData },
      {
        handleSuccess: ({ updatedStoDictionaries }) => {
          const text = getNotificatorSuccessText(
            updatedStoDictionaries.map(data => data.dictionaryName)
          );

          notificator.setNotification(
            getNotificationScheme(ENotificationScheme.Dark, text, {
              type: ENotificationType.Success,
            })
          );
        },
        handleFailure: () => {
          notificator.setNotification(
            getNotificationScheme(
              ENotificationScheme.Dark,
              'Ошибка при загрузке EXL: проверьте данные',
              {
                type: ENotificationType.Warning,
              }
            )
          );
        },
        handleLoading: setIsUploadPending,
      }
    );
  }, []);

  const handleFileUploaderClick = useCallback((): void => {
    fileUploaderRef?.current?.click();
  }, [fileUploaderRef?.current]);

  return {
    isUploadPending,
    fileUploaderRef,
    onChange: handleFileUploaderChange,
    onUploaderClick: handleFileUploaderClick,
  };
};

export default useStoRegistryFromExcelUploader;
