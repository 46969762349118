import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { SpinnerLoader } from '../../../../../../../shared/components/loaders';
import { useStore } from '../../../../../../../shared/utils/IoC';

import FieldWeather from './components/FieldWeather/FieldWeather';
import { PlantProtection } from './components/PlantProtection';
import { Forecast } from './components/Forecast';
import { WeatherStore as Store } from './mobx';
import Styled from './WeatherSlider.styles';

const WeatherSlider: FC = () => {
  // Сторы
  const store = useStore(Store);

  const handleScroll = e => {
    store.sliderScrollTop = e.target.scrollTop;
  };

  if (!store.isOpen) {
    return null;
  }

  return (
    <Styled.Slider
      data-visible={store.isOpen}
      visibility={store.isFullWidth}
      onScroll={handleScroll}
    >
      {store.isLoading && <SpinnerLoader needToHideContent={false} fixed={false} />}

      <Styled.Container>
        <Styled.Content>
          <FieldWeather />
        </Styled.Content>

        <PlantProtection />
        <Forecast />
      </Styled.Container>
    </Styled.Slider>
  );
};

export default observer(WeatherSlider);
