import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown as Dropdown, TNewDropdownProps } from '@farmlink/farmik-ui';
import { sortBy } from 'lodash';

import {
  IStoAttributeOdzDictionaryValue as IDictionaryValue,
  IStoAttributeOdzGroupPut as IOdzGroupPut,
} from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreController as Controller } from '../../../../../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../../../../../../../hooks';
import { StoAttributeCoreStore as Store } from '../../../../../../mobx/stores';
import { TChecklistsDictionarySelectOption as TDictionarySelectOption } from '../../../../../../../../../../../../../../../../../../../tasks/modules/Checklists/types';
import { StoStore } from '../../../../../../../../../../../../../../../../mobx';

import Styled from './StoAttributeDictionaryOdz.styles';

const formatOptionList = (valueList: IDictionaryValue[]): TDictionarySelectOption[] => {
  return valueList.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

type TProps = {} & IOdzGroupPut;

const StoAttributeDictionaryOdz: FC<TProps> = ({ id, odzCriteria: { dictionaryValues } }) => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);

  const controller = useStore(Controller);

  const params = useStoParams();

  const errorList = store.getOdzErrorList(id);

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const getAvailableOptionList = (): TDictionarySelectOption[] => {
    const usedValueList = [
      ...dictionaryValues.green,
      ...dictionaryValues.yellow,
      ...dictionaryValues.red,
    ];

    const filteredList = store.dictionaryOptionList.filter(
      o => !usedValueList.some(v => v.id === o.value)
    );

    return filteredList;
  };

  const handleChangeGreen = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (newValue, otherData) => {
      controller.changeOdzDictionaryValue(params, id, {
        green: !otherData?.selectedValueList
          ? []
          : otherData.selectedValueList.map(o => ({ name: o.label, id: o.value })),
      });
    },
    []
  );

  const handleChangeYellow = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (newValue, otherData) => {
      controller.changeOdzDictionaryValue(params, id, {
        yellow: !otherData?.selectedValueList
          ? []
          : otherData.selectedValueList.map(o => ({ name: o.label, id: o.value })),
      });
    },
    []
  );

  const handleChangeRed = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (newValue, otherData) => {
      controller.changeOdzDictionaryValue(params, id, {
        red: !otherData?.selectedValueList
          ? []
          : otherData.selectedValueList.map(o => ({ name: o.label, id: o.value })),
      });
    },
    []
  );

  return (
    <Styled.Wrapper>
      <Styled.Body>
        <Styled.Container>
          <Dropdown
            config={{
              field: {
                onChange: handleChangeGreen,
                defaultValueList: formatOptionList(dictionaryValues.green),
                icons: {
                  clear: {},
                },
                type: {
                  search: {
                    options: {
                      isFullTextSearch: true,
                    },
                  },
                  multiselect: {},
                },
                placeholder: 'Выберите или укажите значения',
              },
              body: {
                optionList: getAvailableOptionList(),
              },
              visual: {
                label: 'Нормальные значения',
                isBlocked: checkIfIsBlockedByPermissions(),
              },
            }}
          />

          <Styled.LineWrapper>
            <Styled.Line $color={'green'} />
          </Styled.LineWrapper>
        </Styled.Container>

        <Styled.Container>
          <Dropdown
            config={{
              field: {
                onChange: handleChangeYellow,
                defaultValueList: formatOptionList(dictionaryValues.yellow),
                icons: {
                  clear: {},
                },
                type: {
                  search: {
                    options: {
                      isFullTextSearch: true,
                    },
                  },
                  multiselect: {},
                },
                placeholder: 'Выберите или укажите значения',
              },
              body: {
                optionList: getAvailableOptionList(),
              },
              visual: {
                label: 'Допустимые отклонения',
                isBlocked: checkIfIsBlockedByPermissions(),
              },
            }}
          />

          <Styled.LineWrapper>
            <Styled.Line $color={'yellow'} />
          </Styled.LineWrapper>
        </Styled.Container>

        <Styled.Container>
          <Dropdown
            config={{
              field: {
                onChange: handleChangeRed,
                defaultValueList: formatOptionList(dictionaryValues.red),
                icons: {
                  clear: {},
                },
                type: {
                  search: {
                    options: {
                      isFullTextSearch: true,
                    },
                  },
                  multiselect: {},
                },
                placeholder: 'Выберите или укажите значения',
              },
              body: {
                optionList: getAvailableOptionList(),
              },
              visual: {
                label: 'Критические отклонения',
                isBlocked: checkIfIsBlockedByPermissions(),
              },
            }}
          />

          <Styled.LineWrapper>
            <Styled.Line $color={'red'} />
          </Styled.LineWrapper>
        </Styled.Container>
      </Styled.Body>

      {errorList.length ? (
        <Styled.Errors>
          {errorList.map(error => (
            <Styled.Error key={error}>{error}</Styled.Error>
          ))}
        </Styled.Errors>
      ) : null}
    </Styled.Wrapper>
  );
};

StoAttributeDictionaryOdz.displayName = 'StoAttributeNumericOdz';

export default observer(StoAttributeDictionaryOdz);
