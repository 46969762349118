import styled from 'styled-components';

const FiltersWrapper = styled.header`
  width: 100%;

  flex: 0 0 auto;
`;

const ListWrapper = styled.div`
  width: 100%;

  flex: 1 1 auto;

  padding: 20px 24px;

  overflow: hidden;
`;

const ListingPage = styled.div`
  height: 100svh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledListingPage = {
  FiltersWrapper,
  ListWrapper,
  ListingPage,
};

export default StyledListingPage;
