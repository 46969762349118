import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import { Switcher, Typography } from '@farmlink/farmik-ui';

import { IStoDictionary as IDictionary } from '../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';

import Styled from './StoRegistryListItem.styles';

type TProps = {
  dataTestId: string;
  onSelect: (id: string, value: boolean) => void;
  isViewOnly: boolean;
  isLast: boolean;
  removeHighlight: (id: string) => void;
} & IDictionary;

const StoRegistryListItem: FC<TProps> = ({
  dataTestId,
  onSelect,
  isViewOnly,
  isLast,
  isSelected,
  id,
  name,
  systemData,
  removeHighlight,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!systemData?.isHighlighted) return;

    setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }
    }, 300);
  }, [systemData?.isHighlighted]);

  const handleSelect = useCallback(
    (value: boolean): void => {
      onSelect(id, value);
    },
    [onSelect]
  );

  const handleMouseLeave = (): void => {
    if (!systemData?.isHighlighted) return;

    removeHighlight(id);
  };

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper
      {...getDataTestId()}
      onMouseLeave={handleMouseLeave}
      ref={wrapperRef}
      $isLast={isLast}
      $isHighlighted={Boolean(systemData?.isHighlighted)}
    >
      <Switcher
        onChange={handleSelect}
        value={isSelected}
        isBlocked={isViewOnly}
        dataTestId={getDataTestId('switcher')['data-test-id']}
      />

      <Typography
        dataTestId={getDataTestId('name')['data-test-id']}
        variant={'title'}
        color={isSelected ? 'generalDark' : 'secondaryDark'}
      >
        {name}
      </Typography>
    </Styled.Wrapper>
  );
};

StoRegistryListItem.displayName = 'StoRegistryListItem';

export default memo(StoRegistryListItem);
