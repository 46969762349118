import { TypeApiRoute } from '../../../models/type.api.request';
import { IStoContent } from '../../../models/as-fields/STO/STO.model';

type TRequest = { id: string; versionName?: string; versionComment?: string };

type TResponse = IStoContent;

export const publishSto: TypeApiRoute & {
  request: TRequest;
  response: TResponse;
} = {
  url: ({ id }) => `/api/as-fields/sto/${id}/publish`,
  method: 'POST',
  headers: {},
  request: {} as TRequest,
  response: {} as TResponse,
};
