import { FC, memo, useEffect, useMemo } from 'react';
import { Colors, IconButton, useContextualHelpActions } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import TaskCommonData from '../../components/TaskCommonData/TaskCommonData';
import { Widget } from '../../../Widgets';
import {
  useTaskActionControl,
  useTaskPageGlobalControl,
  useTasksParams,
  useTasksRouteActions,
} from '../../../../hooks';
import { ETaskAction } from '../../../../../../../../api/models/as-fields/task/task.model';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { TaskController } from '../../../../mobx/controllers';
import { TaskStore } from '../../../../mobx/stores';
import { TaskStatuses } from '../../../../models';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../shared/constants/contextualPath';
import { usePageContextualHelp } from '../../../../../../../shared/hooks';

import Styled from './SummeryContainer.styles';

export interface ITaskSummaryContainer {
  mode?: TaskStore['mode'];
}

const SummaryContainer: FC<ITaskSummaryContainer> = ({ mode }) => {
  const { taskId } = useTasksParams();
  const { goToTaskEdit } = useTasksRouteActions();
  const { clearTaskStore } = useStore(TaskController);
  const taskStore = useStore(TaskStore);
  const status = taskStore?.selectedTask?.status;

  const { fetchTaskData } = useTaskPageGlobalControl(mode, {
    isIgnoreOnMountFetch: { taskData: true },
    isIgnoreOnUnmountClear: true,
  });

  const controls = useTaskActionControl();

  const hasPermissionToEdit = controls.hasPermission(ETaskAction.TaskEditCommonInfo);
  const canEdit = mode === 'view' && hasPermissionToEdit;

  useEffect(() => {
    return () => {
      if (mode === 'edit') {
        clearTaskStore();
        fetchTaskData();
      }
    };
  }, []);

  usePageContextualHelp(EContextualParentPath.TasksTaskSummary);
  const helpActions = useContextualHelpActions();

  const formattedTaskTip = useMemo(() => {
    const { New, InWork, Completed, Canceled } = TaskStatuses;

    switch (status as string) {
      case New:
        return ContextualPaths.TasksTaskSummaryTitleNew;
      case InWork:
        return ContextualPaths.TasksTaskSummaryTitleInWork;
      case Completed:
        return ContextualPaths.TasksTaskSummaryTitleCompleted;
      case Canceled:
        return ContextualPaths.TasksTaskSummaryTitleCanceled;
      default:
        break;
    }
  }, [status]);

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.TasksTaskSummary,
    formattedTaskTip
  );

  return (
    <Widget
      sideContent={
        canEdit ? (
          <>
            <IconButton
              icon="edit"
              $padding="8px"
              $borderRadius="12px"
              size={32}
              type="button"
              dataTestId="task-edit-button"
              onClick={() => goToTaskEdit(taskId)}
              colorScheme={{
                default: {
                  background: Colors.secondaryGray,
                  icon: Colors.black,
                },
                hover: {
                  background: Colors.lightGreen,
                  icon: Colors.green,
                },
              }}
            />
          </>
        ) : null
      }
      displayTitle
      title={
        <Styled.TitleWrapper>
          {'Общие данные'}
          {ContextualHelpIcon}
        </Styled.TitleWrapper>
      }
      dataTestId="new-task-general-data-title"
    >
      <TaskCommonData />
    </Widget>
  );
};

export default observer(SummaryContainer);
